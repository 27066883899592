export enum LyftEnvironment {
  Sandbox = 'sandbox',
  Production = 'production',
}

export interface ILyftProgramPatchBody {
  programId?: string
  environment?: LyftEnvironment
  rideLimit?: number | null
  rideLimitRangeSeconds?: number | null
  costLimit?: number | null
}

export interface ILyftProgramResponse {
  organizationId: string
  programId: string
  environment: LyftEnvironment
  rideLimit: number | null
  rideLimitRangeSeconds: number | null
  costLimit: number | null
  createdAt: number
  updatedAt: number
}
