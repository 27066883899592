export type IdMap<T> = Map<string, T>

export const buildIdMap = <T extends { id?: string }>(values: T[]): IdMap<T> => buildMapByKey(values, 'id')

export const buildMapByKey = <K, V>(values: V[], key: keyof V): Map<K, V> => {
  const valueMap = new Map<K, V>()
  for (const value of values) {
    valueMap.set(value[key] as unknown as K, value)
  }
  return valueMap
}

export const extractIds = (modelInstances: Array<{ id: string }>): string[] =>
  modelInstances.map((instance) => instance.id)
