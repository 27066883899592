import { PermissionSetType, RequestPermissionSet, RiderOnboardingFlowPermissionSet } from '../sets'
import { AdminUserPermissionSet } from '../sets/AdminUserPermissions'
import { LaunchPermissionSet } from '../sets/LaunchPermissions'
import { OrganizationPermissionSet } from '../sets/OrganizationPermissions'
import { UserOwnPermissionSet } from '../sets/UserOwnPermissions'

/**
 * Virtual role of basic operators who can use the admin panel but cannot do
 * anything other than minimal operations.
 */
export const AdminOperatorRole: PermissionSetType[] = [
  LaunchPermissionSet.UsePortal,

  OrganizationPermissionSet.ViewOwn,
  OrganizationPermissionSet.ViewConfiguration,
  OrganizationPermissionSet.ViewCustomSchemas,
  OrganizationPermissionSet.ViewBillingAccountStatus,

  UserOwnPermissionSet.User,
  UserOwnPermissionSet.Admin,

  AdminUserPermissionSet.View,

  RequestPermissionSet.ViewDailyCounts,

  RiderOnboardingFlowPermissionSet.View,
]
