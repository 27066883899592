export enum ZoneType {
  StaticStops = 'staticStops',
  OptimizedStops = 'optimizedStops',
  DoorToDoor = 'doorToDoor',
}

export enum ScheduledRequestFlexWindowType {
  AutomaticUsingSearchFlex = 'automaticUsingSearchFlex',
  ManualWithNegotiation = 'manualWithNegotiation',
}

export const usesGuaranteedWindows = (service: { scheduledRequestFlexWindowType: ScheduledRequestFlexWindowType }) =>
  service.scheduledRequestFlexWindowType === ScheduledRequestFlexWindowType.ManualWithNegotiation

export enum ArriveByPickupConstraintType {
  Detour = 'detour',
  Window = 'window',
}

export enum ZoneDirection {
  Pickup = 'pickup',
  Dropoff = 'dropoff',
  Any = 'any',
}

export enum WalkingDirectionsStrategy {
  StraightLine = 'straightLine',
  RouteBased = 'routeBased',
}
