import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum EngageReportingPermissionSet {
  View = 'engage_reporting_view',
  Write = 'engage_reporting_write',
  Delete = 'engage_reporting_delete',
  Export = 'engage_reporting_export',
}

export const engageReportingPermissionSetGrouping: IPermissionSetGrouping<EngageReportingPermissionSet> = {
  key: PermissionSetGroupingKey.EngageReporting,
  active: {
    [EngageReportingPermissionSet.View]: [
      PermissionType.GET_ANY_REPORT_TEMPLATE,
      PermissionType.GET_ANY_CASE,
      PermissionType.LIST_ANY_CUSTOM_FIELD,
      PermissionType.GET_ANY_FILE,
      PermissionType.LIST_ANY_CASE_STATUS,
      PermissionType.GET_ANY_CASE_FORM,
      PermissionType.GET_ANY_CASE_TYPE,
      PermissionType.LIST_ANY_CASE_TYPE,
      PermissionType.GET_ANY_CASE_LETTER,
      PermissionType.GET_ANY_FORM,
      PermissionType.GET_ANY_FORM_CONTENT,
      PermissionType.LIST_ANY_FORM_CONTENT,
      PermissionType.GET_ANY_LETTER,
    ],
    [EngageReportingPermissionSet.Write]: [PermissionType.WRITE_ANY_REPORT_TEMPLATE],
    [EngageReportingPermissionSet.Delete]: [PermissionType.DELETE_ANY_REPORT_TEMPLATE],
    [EngageReportingPermissionSet.Export]: [
      PermissionType.EXPORT_ANY_REPORT_TEMPLATE,
      PermissionType.EXPORT_ANY_ALL_CASES_REPORT,
      PermissionType.EXPORT_ANY_CASE_ACTIVITY_LOG_REPORT,
      PermissionType.EXPORT_ANY_DATA_RETENTION_REPORT,
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
