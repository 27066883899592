import { IGeometry } from './GeographyTypes'

export class SequelizeGeometryGetter {
  // As of sequelize6, Geometry types return a csr property which causes issues in the downstream services,
  // this getter strips the csr property and maintains the same geometry format as sequelize5
  public static getGeometryValue<T extends IGeometry>(dataValue: T): T {
    const { type, coordinates } = dataValue
    return { type, coordinates } as T
  }

  // A nullable friendly version of the getGeometryValue where returning null is expected and supported
  public static getNullableGeometryValue<T extends IGeometry>(dataValue: T): T | null {
    return dataValue ? ({ type: dataValue.type, coordinates: dataValue.coordinates } as T) : null
  }
}
