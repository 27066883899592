import { AxiosInstance } from 'axios'
import {
  IBillingAccountActiveDutyMetricsQueryParams,
  IBillingAccountActiveDutyMetricsResponse,
  IBillingAccountUsageMetricsQueryParams,
  IBillingAccountUsageMetricsResponse,
  Resources,
} from '../types'

export class BillingAccountMetricsResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async getActiveDutyMetrics(
    id: string,
    params: IBillingAccountActiveDutyMetricsQueryParams
  ): Promise<IBillingAccountActiveDutyMetricsResponse> {
    const response = await this.agent.get<IBillingAccountActiveDutyMetricsResponse>(
      `${Resources.BillingAccount}/${id}/activeDutyMetrics`,
      { params }
    )
    return response.data
  }

  public async getUsageMetrics(
    id: string,
    params: IBillingAccountUsageMetricsQueryParams
  ): Promise<IBillingAccountUsageMetricsResponse> {
    const response = await this.agent.get<IBillingAccountUsageMetricsResponse>(
      `${Resources.BillingAccount}/${id}/usageMetrics`,
      { params }
    )
    return response.data
  }
}
