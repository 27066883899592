import { IListModelQueryParamValues } from '@sparelabs/http-client-utils'
import { ICustomFieldResponse } from './CustomFieldTypes'

// Vehicle Inspection Template

export enum VehicleInspectionTemplateStatus {
  Active = 'active',
  Archived = 'archived',
}

export interface IVehicleInspectionTemplateResponse {
  id: string
  createdAt: number
  updatedAt: number
  name: string
  description: string
  key: string
  status: VehicleInspectionTemplateStatus
  fieldGroupId: string
  activeFieldsCount: number
  fields?: ICustomFieldResponse[] // We return the fields for GET but not LIST
}

export type IVehicleInspectionTemplatePostBody = Pick<
  IVehicleInspectionTemplateResponse,
  'name' | 'description' | 'key'
>

export type IVehicleInspectionTemplatePatchBody = Partial<
  Pick<IVehicleInspectionTemplateResponse, 'name' | 'description' | 'status'>
>

export type IVehicleInspectionTemplateOrderBy = Extract<
  keyof IVehicleInspectionTemplateResponse,
  'createdAt' | 'updatedAt' | 'name' | 'key'
>
export interface IVehicleInspectionTemplateQueryParams extends IListModelQueryParamValues {
  status?: VehicleInspectionTemplateStatus
  search?: string
}

// Vehicle Inspection

export enum VehicleInspectionType {
  PreDuty = 'preDuty',
  PostDuty = 'postDuty',
}

export interface IVehicleInspectionResponse {
  id: string
  createdAt: number
  updatedAt: number
  templateId: string
  templateName: string
  fieldGroupId: string
  driverId: string
  driverName: string
  vehicleId: string
  vehicleIdentifier: string
  dutyId: string
  dutyIdentifier: string | null
  type: VehicleInspectionType
  isCompleted: boolean
  metadata: Record<string, unknown>
}

export type IVehicleInspectionPostBody = Pick<
  IVehicleInspectionResponse,
  'templateId' | 'driverId' | 'vehicleId' | 'dutyId' | 'type' | 'isCompleted' | 'metadata'
>

export type IVehicleInspectionPatchBody = Partial<Pick<IVehicleInspectionPostBody, 'isCompleted' | 'metadata'>>

export type IVehicleInspectionOrderBy = Extract<keyof IVehicleInspectionResponse, 'createdAt' | 'updatedAt'>

export interface IVehicleInspectionQueryParams
  extends IListModelQueryParamValues,
    Partial<Pick<IVehicleInspectionResponse, 'templateId' | 'driverId' | 'vehicleId' | 'dutyId' | 'isCompleted'>> {
  type?: VehicleInspectionType[] | VehicleInspectionType
}
