import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum AiAgentPermissionSet {
  View = 'ai_agent_view',
  Write = 'ai_agent_write',
}

export const aiAgentPermissionSetGrouping: IPermissionSetGrouping<AiAgentPermissionSet> = {
  key: PermissionSetGroupingKey.AiAgent,
  active: {
    [AiAgentPermissionSet.View]: [PermissionType.GET_ANY_AI_AGENT],
    [AiAgentPermissionSet.Write]: [PermissionType.WRITE_ANY_AI_AGENT],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
