import { IPoint } from '@sparelabs/geography'
import { SlotType } from '../types'
import { SlotBinCalculator } from './SlotBinCalculator'
import { SlotListLocationExtractor } from './SlotListLocationExtractor'

export interface IOptionalLocationBinnableSlot {
  scheduledLocation?: IPoint | null
  type: SlotType
}

/**
 * Slots of type startLocation, endLocation, or driverBreak can have a null scheduledLocation.
 * We use this class to calculate bins in spite of these potentially null locations.
 * Returns an empty array if no locations can be inferred from slots.
 */
export class LocationInferringSlotBinCalculator {
  public static binByGeohash<T extends IOptionalLocationBinnableSlot>(slots: T[]) {
    if (slots.length === 0) {
      return []
    }

    const slotsWithLocations = SlotListLocationExtractor.populateLocations(slots)
    return SlotBinCalculator.binByGeohash(slotsWithLocations)
  }
}
