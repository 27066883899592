import { Nullable } from '@sparelabs/core'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { Currency } from './CurrencyTypes'
import { CreatedInterface } from './EstimateTypes'
import { RiderType } from './SharedTypes'

export enum FarePassStatus {
  Draft = 'draft',
  Launched = 'launched',
  Archived = 'archived',
}

export enum FarePassType {
  /**
   * Gives the rider unlimited free rides for as long as the pass is valid
   * (Based on a set number of days)
   */
  TimeBased = 'timeBased',
  /**
   * Gives the rider unlimited free rides for as long as the pass is valid
   * (Based on a calendar period)
   */
  CalendarBased = 'calendarBased',
  /**
   * Gives the rider a fixed amount of credit to spend on trips
   */
  StoredValue = 'storedValue',
}

export enum FarePassCalendarPeriod {
  Monthly = 'monthly',
}

export interface IFarePassCommonBody {
  name: string
  description: string
  cost: number
  type: FarePassType
  availableToInterfaces: CreatedInterface[]
  color: string
  simpleTermsOfUse: string
  termsOfUseUrl: string
}

export interface IFarePassStoredValueBody {
  amount: number
}

export interface IFarePassTimeBasedBody {
  validDaysCount: number
  // null values indicate that the fare pass should have no rider type restriction.
  riderTypeRestriction: RiderType | null
}

export interface IFarePassCalendarBasedBody {
  calendarPeriod: FarePassCalendarPeriod
  expiryTime: number
  purchaseRolloverDay: number
  // null values indicate that the fare pass should have no rider type restriction.
  riderTypeRestriction: RiderType | null
}

export interface IFarePassPostBody
  extends IFarePassCommonBody,
    Partial<Nullable<IFarePassStoredValueBody>>,
    Partial<Nullable<IFarePassTimeBasedBody>>,
    Partial<Nullable<IFarePassCalendarBasedBody>> {}

export interface IFarePassResponse
  extends IModelJsonValues,
    IFarePassCommonBody,
    Nullable<IFarePassStoredValueBody>,
    Nullable<IFarePassTimeBasedBody>,
    Nullable<IFarePassCalendarBasedBody> {
  currency: Currency
  status: FarePassStatus
  activeAllocationCount: number
}

// Prevent changing the type once created
export interface IFarePassPatchBody extends Partial<IFarePassPostBody> {
  status?: FarePassStatus
}

export type AllFarePassFields = IFarePassPostBody & IFarePassResponse & IFarePassPatchBody

export type FarePassTypeFieldMap = {
  [P in FarePassType]: Set<keyof AllFarePassFields>
}

const storedValueOnlyFields: Array<keyof AllFarePassFields> = ['amount']
const timeBasedOnlyFields: Array<keyof AllFarePassFields> = ['validDaysCount']
const calendarBasedOnlyFields: Array<keyof AllFarePassFields> = ['calendarPeriod', 'purchaseRolloverDay']
export const farePassForbiddenFieldsMap: FarePassTypeFieldMap = {
  [FarePassType.TimeBased]: new Set([...storedValueOnlyFields, ...calendarBasedOnlyFields]),
  [FarePassType.StoredValue]: new Set([
    ...timeBasedOnlyFields,
    ...calendarBasedOnlyFields,
    'riderTypeRestriction',
    'expiryTime',
  ]),
  [FarePassType.CalendarBased]: new Set([...storedValueOnlyFields, ...timeBasedOnlyFields]),
}

export interface IFarePassStubCommon {
  id: string
  name: string
  description: string
  cost: number
  type: FarePassType
  color: string
  currency: Currency
  status: FarePassStatus
  simpleTermsOfUse: string
  termsOfUseUrl: string
}

export interface IFarePassStub
  extends IFarePassStubCommon,
    Nullable<IFarePassStoredValueBody>,
    Nullable<IFarePassTimeBasedBody>,
    Nullable<IFarePassCalendarBasedBody> {}

export interface IFarePassListQueryParams extends IListModelQueryParamValues {
  availableToInterfaces?: CreatedInterface[]
  status?: FarePassStatus
}
