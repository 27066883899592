import { v4 as uuid } from 'uuid'
import { MISSING_CONTEXT } from './constants'
import { CrossServiceContextPropagator, ICrossServiceContext } from './CrossServiceContextPropagator'

export const CORRELATION_ID_HTTP_HEADER: string = 'spare-correlation-id'
export const SPAN_ID_HTTP_HEADER: string = 'spare-span-id'
export const ROOT_SPAN_LABEL_HTTP_HEADER: string = 'spare-root-span-label'
export const WORKFLOW_ID_HTTP_HEADER: string = 'spare-workflow-id'
export const WORKFLOW_RUN_ID_HTTP_HEADER: string = 'spare-workflow-run-id'
export const PARENT_WORKFLOW_RUN_ID_HTTP_HEADER: string = 'spare-parent-workflow-ids'
/**
 * [ORG_ROUTE_METRICS]: Temporary
 */
export const ORGANIZATION_ID_HTTP_HEADER: string = 'spare-organization-id'

/**
 * This class captures a few concerns related to context propagation for cross-service tracing:
 *  - initializing a context object
 *  - encoding/decoding a context object transmitted via a service-to-service call over HTTP
 *
 * Notably, this class does NOT do anything with the created context object, it is left up to the
 * caller to pass the created object to {@link CrossServiceContextPropagator} to actually set the
 * context globally. We figured it would be cleaner to keep this logic in its own class
 * so that the semantics are clearer.
 */
export class CrossServiceContextBuilder {
  /**
   * Use this method to build a new context object when none exists yet.
   */
  public static buildContextForNewRootSpan(rootSpanLabel: string): ICrossServiceContext {
    return {
      correlationId: uuid(),
      spanId: uuid(),
      rootSpanLabel,
      parentSpanId: undefined,
      workflowId: undefined,
      workflowRunId: undefined,
      parentWorkflowIds: undefined,
      organizationId: undefined,
    }
  }

  /**
   * If Service A transmits context to Service B via HTTP headers set by
   * {@link CrossServiceContextBuilder.buildOutboundHttpHeaders}, Service B should use this method
   * to decode that context.
   */
  public static buildContextFromInboundHttpRequest({
    headers,
    method,
    route,
  }: {
    headers: Record<string, string>
    method: string
    // The HTTP path, but parameterized - e.g. for GET http://example.com/users/123, route should be something like /users/:id
    route: string
  }): ICrossServiceContext {
    return this.buildContextFromInboundHttpHeaders({ headers, rootSpanLabel: `${method.toUpperCase()} ${route}` })
  }

  public static buildContextFromInboundHttpHeaders({
    headers,
    rootSpanLabel,
  }: {
    headers: Record<string, string>
    rootSpanLabel: string
  }): ICrossServiceContext {
    return {
      correlationId: headers[CORRELATION_ID_HTTP_HEADER] ?? uuid(),
      spanId: uuid(),
      parentSpanId: headers[SPAN_ID_HTTP_HEADER],
      rootSpanLabel: headers[ROOT_SPAN_LABEL_HTTP_HEADER] ?? rootSpanLabel,
      workflowId: headers[WORKFLOW_ID_HTTP_HEADER],
      workflowRunId: headers[WORKFLOW_RUN_ID_HTTP_HEADER],
      parentWorkflowIds: headers[PARENT_WORKFLOW_RUN_ID_HTTP_HEADER]?.split(',').filter(Boolean),
      organizationId: headers[ORGANIZATION_ID_HTTP_HEADER],
    }
  }

  /**
   * If Service A wants to transmit context to Service B as part of a service-to-service HTTP call,
   * Service A should use this method to encode that context via special HTTP headers that it can
   * include in the request to Service B.
   */
  public static buildOutboundHttpHeaders(): Record<string, string> {
    const { correlationId, spanId, rootSpanLabel, workflowId, workflowRunId, parentWorkflowIds, organizationId } =
      CrossServiceContextPropagator.getContext() ?? {}
    return {
      ...(correlationId && { [CORRELATION_ID_HTTP_HEADER]: correlationId }),
      ...(spanId && { [SPAN_ID_HTTP_HEADER]: spanId }),
      ...(rootSpanLabel && { [ROOT_SPAN_LABEL_HTTP_HEADER]: rootSpanLabel }),
      ...(workflowId && { [WORKFLOW_ID_HTTP_HEADER]: workflowId }),
      ...(workflowRunId && { [WORKFLOW_RUN_ID_HTTP_HEADER]: workflowRunId }),
      ...(parentWorkflowIds && { [PARENT_WORKFLOW_RUN_ID_HTTP_HEADER]: parentWorkflowIds.join(',') }),
      ...(organizationId && { [ORGANIZATION_ID_HTTP_HEADER]: organizationId }),
    }
  }

  /**
   * Create a new child context that is related to, but separate from, the context of the current span.
   *
   * Should always be called within the scope of a parent span, though it should also gracefully handle
   * violations of this assumption.
   */
  public static buildContextForNewSpan(): ICrossServiceContext {
    const parentContext = CrossServiceContextPropagator.getContext()
    return {
      ...(parentContext ?? this.getFallbackValues()),
      spanId: uuid(),
      parentSpanId: parentContext?.spanId,
    }
  }

  /**
   * Returns a context object that is identical to the current context, except for specific fields
   * as specified by the caller.
   */
  public static applyToCurrentContext(overrides: Partial<ICrossServiceContext>): ICrossServiceContext {
    const parentContext = CrossServiceContextPropagator.getContext()
    return {
      ...(parentContext ?? this.getFallbackValues()),
      ...overrides,
    }
  }

  private static getFallbackValues(): ICrossServiceContext {
    // Define values to use in case parent context is undefined. In principle this shouldn't happen
    // because this method should always be called within the scope of a parent context,
    // but there is no way to guarantee that at compile time.
    return {
      correlationId: uuid(),
      rootSpanLabel: MISSING_CONTEXT,
      spanId: uuid(),
      parentSpanId: undefined,
      workflowId: undefined,
      workflowRunId: undefined,
      parentWorkflowIds: undefined,
      organizationId: undefined,
    }
  }
}
