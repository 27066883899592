import { BadArgumentsError, BoundsError } from '../errors'

/**
 * Captures the concept of a list of items that are separated from one another by intervals of some kind.
 *
 * Intervals are defined between pairs of consecutive items.
 * Guarantees that there will always be n-1 intervals for n items.
 */

export interface IJsonIntervalList<T, U> {
  itemList: T[]
  intervalList: U[]
}

export class IntervalList<ItemType, IntervalType> {
  private itemList: ItemType[] = []
  private readonly intervalList: IntervalType[] = []

  constructor(items: ItemType[], intervals: IntervalType[]) {
    if (items.length === 0) {
      throw new BadArgumentsError('must supply at least 1 item to build an interval list', { items, intervals })
    }
    if (items.length !== intervals.length + 1) {
      throw new BadArgumentsError(
        `interval array must contain exactly 1 interval per consecutive
        pair of items in the given item array`,
        { items, intervals }
      )
    }
    this.itemList.push(items[0])
    for (const [index, interval] of intervals.entries()) {
      this.push(items[index + 1], interval)
    }
  }

  /**
   * First value is distinct from the
   * other items in that it has no interval preceding it.
   * We don't allow completely "empty" interval lists, because those
   * would be undefined until this first "head" element were set.
   */
  public get first(): ItemType {
    return this.itemList[0]
  }

  public set first(newHead: ItemType) {
    this.itemList[0] = newHead
  }

  /**
   * Append an item, along with the interval preceding it, to
   * the end of this interval list.
   */
  public push(item: ItemType, prevInterval: IntervalType): void {
    this.itemList.push(item)
    this.intervalList.push(prevInterval)
  }

  /**
   * Remove the last item, along with the interval preceding it,
   * from the end of this interval list, and return them.
   */
  public pop(): { item: ItemType; prevInterval: IntervalType } {
    if (this.intervalList.length > 0) {
      // These `as` statements should be safe here because the length check should
      // enforce bounds constraints; unfortunately the TS compiler is not currently
      // smart enough to recognize this.
      const item = this.itemList.pop() as ItemType
      const prevInterval = this.intervalList.pop() as IntervalType
      return { item, prevInterval }
    }
    throw new BoundsError('cannot pop from list when there are no remaining intervals')
  }

  /**
   * Returns an array of all the items in this interval list.
   * (Includes the first one.)
   */
  public items(): ItemType[] {
    return this.itemList
  }

  /**
   * Returns an array of all the intervals between the items
   * in this interval list. If there are n items, there will be
   * n - 1 intervals.
   */
  public intervals(): IntervalType[] {
    return this.intervalList
  }

  /**
   * Returns a list of pairs of items and intervals, where each pair
   * consists of an item and the interval immediately preceding it
   * in this list.
   * NOTE: this never includes the first item, which has its own
   * special accessor.
   */
  public pairs(): Array<{ item: ItemType; prevInterval: IntervalType }> {
    return this.intervalList.map((interval, index) => ({ item: this.itemList[index + 1], prevInterval: interval }))
  }

  public toJson(): IJsonIntervalList<ItemType, IntervalType> {
    return {
      itemList: this.itemList,
      intervalList: this.intervalList,
    }
  }

  public static fromJson<T, U>(json: IJsonIntervalList<T, U>): IntervalList<T, U> {
    return new IntervalList(json.itemList, json.intervalList)
  }
}
