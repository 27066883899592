import { IRetryDelayStrategy } from './RetryDelayStrategy'

export interface ILinearBackoffStrategyParams {
  /**
   * Amount of time to wait before the first retry attempt, in milliseconds. Next delay will be 2x this, then 3x, etc.
   */
  firstRetryDelayMs: number
}

const DEFAULT_PARAMS: ILinearBackoffStrategyParams = {
  firstRetryDelayMs: 500,
}

export class LinearBackoffStrategy implements IRetryDelayStrategy {
  private readonly params: ILinearBackoffStrategyParams

  constructor(params: Partial<ILinearBackoffStrategyParams> = {}) {
    this.params = {
      ...DEFAULT_PARAMS,
      ...params,
    }
  }

  public calculateRetryDelay(attemptNumber: number): number {
    return this.params.firstRetryDelayMs * attemptNumber
  }
}
