import { MetadataError } from '@sparelabs/core'
import { toString } from 'lodash'

/**
 * TODO: These error types are specific to client-server communications. Accordingly they should probably be
 * moved into something like `@sparelabs/server-utils`.
 *
 * At that point this lib (`@sparelabs/error-types`) probably no longer has any reason to exist.
 */

/**
 * We keep a separate list of error names in an enum
 * because relying on class names is done always
 * (ex. webpack likes to mangle class name so the names get lost in production)
 */
export enum ErrorName {
  NotFoundError = 'NotFoundError',
  UserBannedError = 'UserBannedError',
  UnauthorizedError = 'UnauthorizedError',
  ValidationError = 'ValidationError',
  InvalidTokenError = 'InvalidTokenError',
  UnexpectedError = 'UnexpectedError',
  IntegrationError = 'IntegrationError',
  BadRequestError = 'BadRequestError',
  BadResponseError = 'BadResponseError',
  InternalServerError = 'InternalServerError',
  InsufficientChargeError = 'InsufficientChargeError',
  PaymentMethodError = 'PaymentMethodError',
  NoDriversAvailableError = 'NoDriversAvailableError',
  InfeasibleInputDutyError = 'InfeasibleInputDutyError',
  OrganizationUnavailableError = 'OrganizationUnavailableError',
  ForbiddenError = 'ForbiddenError',
  RateLimitError = 'RateLimitError',
  NotImplementedError = 'NotImplementedError',
  TemporarilyUnavailableError = 'TemporarilyUnavailableError',
  PaymentFlowError = 'PaymentFlowError',
  MissingOrganizationError = 'MissingOrganizationError',
  MissingUserError = 'MissingUserError',
  ModelVersionError = 'ModelVersionError',
  InvariantViolationError = 'InvariantViolationError',
  ServerTimeoutError = 'ServerTimeoutError',
  UnmappedApiError = 'UnmappedApiError',
  NoResponseError = 'NoResponseError',
  GatewayTimeoutError = 'GatewayTimeoutError',
  OpenFleetError = 'OpenFleetError',
  OpenFleetNoEstimateError = 'OpenFleetNoEstimateError',
  OpenFleetProviderError = 'OpenFleetProviderError',
  OpenFleetImplementationError = 'OpenFleetImplementationError',
  OpenFleetLimitViolationError = 'OpenFleetLimitViolationError',
  OpenTripPlannerError = 'OpenTripPlannerError',
  IllegalDutyModificationError = 'IllegalDutyModificationError',
  WorkflowError = 'WorkflowError',
  WorkflowValidationError = 'WorkflowValidationError',
  DataConsistencyError = 'DataConsistencyError',
  ServiceUnavailableError = 'ServiceUnavailableError',
  RequestNotInServiceHoursError = 'RequestNotInServiceHoursError',
  TimeRuleConstraintError = 'TimeRuleConstraintError',
  ConflictError = 'ConflictError',
}

export enum NotFoundResource {
  URL = 'url',
  User = 'user',
  Organization = 'organization',
  Charge = 'charge',
  ConnectedAccount = 'connectedAccount',
  Duty = 'duty',
  DutyHistory = 'dutyHistory',
  Driver = 'driver',
  Service = 'service',
  Estimate = 'estimate',
  PaymentMethod = 'paymentMethod',
  PaymentMethodType = 'paymentMethodType',
  PaymentProvider = 'paymentProvider',
  FarePass = 'farePass',
  FarePassAllocation = 'farePassAllocation',
  Group = 'group',
  Vehicle = 'vehicle',
  Request = 'request',
  RequestCancellation = 'requestCancellation',
  Slot = 'slot',
  Promo = 'promo',
  DutyRequestOffer = 'dutyRequestOffer',
  NotificationSetting = 'notificationSetting',
  ServiceFleet = 'serviceFleet',
  Fleet = 'fleet',
  AccessRule = 'accessRule',
  PayCollectionCall = 'payCollectionCall',
  Report = 'report',
  Other = 'other',
  MobileApp = 'mobileApp',
  MobileAppRelease = 'mobileAppRelease',
  RiderOnboardingFlow = 'riderOnboardingFlow',
  Journey = 'journey',
  GroupMembership = 'groupMembership',
  Rider = 'rider',
  Tip = 'tip',
  TipPolicy = 'tipPolicy',
  Dataset = 'dataset',
  Project = 'project',
  RealizeFixedRoute = 'realizeFixedRoute',
  RealizeFixedRouteStop = 'realizeFixedRouteStop',
  RealizeFixedRouteStopConnection = 'realizeFixedRouteStopConnection',
  RealizeFleet = 'realizeFleet',
  RealizeService = 'realizeService',
  RealizeZone = 'realizeZone',
  RealizeStop = 'realizeStop',
  DemandSegment = 'demandSegment',
  RequestInsights = 'requestInsights',
  Gtfs = 'gtfs',
  UzurvRide = 'uzurvRide',
  HistoricTripDataset = 'historicTripDataset',
  AppInstallation = 'appInstallation',
  App = 'app',
  AppWebhook = 'appWebhook',
  SuperAdmin = 'superAdmin',
  LyftProgram = 'lyftProgram',
  LyftRide = 'lyftRide',
  OrganizationSuperAdmin = 'organizationSuperAdmin',
  BreakPolicy = 'breakPolicy',
  UberTrip = 'uberTrip',
  UberOrganization = 'uberOrganization',
  UserFleetAgreement = 'userFleetAgreement',
  WalletAutoTopUp = 'walletAutoTopUp',
  DriverBreak = 'driverBreak',
  AnalyzeReportJobs = 'analyzeReportJobs',
  Event = 'event',
  MtiOrganization = 'mtiOrganization',
  MtiBooking = 'mtiBooking',
  Case = 'case',
  CaseType = 'caseType',
  CaseStatus = 'caseStatus',
  Form = 'form',
  FormContent = 'formContent',
  CaseForm = 'caseForm',
  Letter = 'letter',
  CaseLetter = 'caseLetter',
  OpenFleetConfiguration = 'openFleetConfiguration',
  OpenFleetLimit = 'openFleetLimit',
  OpenFleetRequest = 'openFleetRequest',
  BulkNotification = 'bulkNotification',
  NotificationAnalytics = 'notificationAnalytics',
  OpenFleetReceipt = 'openFleetReceipt',
  RiderDriverRestriction = 'riderDriverRestriction',
  ConstraintOverrideAction = 'constraintOverrideAction',
  RequestConstraintOverride = 'requestConstraintOverride',
  SimcitySimulation = 'simcitySimulation',
  VehicleInspection = 'vehicleInspection',
  VehicleInspectionTemplate = 'vehicleInspectionTemplate',
  VehicleType = 'vehicleType',
  DataRetentionSchedule = 'dataRetentionSchedule',
  OrganizationDriverLogin = 'organizationDriverLogin',
  IvrMetrics = 'ivrMetrics',
  Workflow = 'workflow',
  WorkflowRun = 'workflowRun',
  DutyConversation = 'dutyConversation',
  Agent = 'agent',
  Announcement = 'announcement',
  AuthorizationGroup = 'authorizationGroup',
  AuthorizationSuperAdminGroup = 'authorizationSuperAdminGroup',
  ZoneArea = 'zoneArea',
  NylasOrganizationGrant = 'nylasOrganizationGrant',
  NylasAdminGrant = 'nylasAdminGrant',
  TicketInstance = 'ticketInstance',
}

export type LocalizeFunction<T = undefined> = (options: T | undefined, locale: string) => string
export const DEFAULT_LOCALE = 'en'

export interface IMetadataObject {
  [key: string]: any
  /**
   * Context in a metadata object is used to provide information to an
   * error parser in a consistent expected format. Context is generally used
   * to communicate user/agent information for better error traceability
   */
  context?: object
}

/**
 * TODO: Move to `@sparelabs/translate`?
 */
export class LocalizableError<T = undefined> extends MetadataError {
  constructor(
    public readonly messageInput: string | LocalizeFunction<T>,
    public readonly metadata: IMetadataObject = {},
    public readonly localizeOptions?: T
  ) {
    super('', metadata)
    /**
     * Set the message field right away, even though we may not know the locale at the place where the error is thrown
     */
    this.localize(DEFAULT_LOCALE)
  }

  public localize(locale: string) {
    if (typeof this.messageInput === 'string') {
      this.message = this.messageInput
    } else if (typeof this.messageInput === 'function') {
      this.message = this.messageInput(this.localizeOptions, locale)
    } else {
      throw new Error('Localization string missing')
    }
  }
}

/**
 * If you add a new subclass of ApiError, make sure to add the respective error
 * to the 'ErrorName' enum on top of this file.
 * Also add an error mapping for the new error to 'lib/server-utils/src/errors/AxiosErrorMapper.ts',
 * so that our axios api clients know how to convert an Axios error back to your new error.
 */
export abstract class ApiError<T = undefined> extends LocalizableError<T> {
  /**
   * Construct an instance of an ApiError
   *
   * @param status The HTTP status code
   * @param messageInput A message describing the error (should be a LocalizeFunction if the message is meant to be user facing)
   * @param metadata Metadata associated with the error
   * @param localizeOptions Options controlling how the error message is localized
   * @param serializeMetadata When serializing the error (e.g. returning from a server to a client), should we serialize the metadata?
   *                          Defaults to false, which means only the property metadata.errors is serialized (if true, the full
   *                          metadata object is serialized)
   */
  constructor(
    public readonly status: number,
    messageInput: string | LocalizeFunction<T>,
    metadata: object = {},
    localizeOptions?: T,
    public readonly serializeMetadata?: boolean
  ) {
    super(messageInput, metadata, localizeOptions)
  }
}

/**
 * This error should be localized for any errors that are meant to be shown to
 * end-users otherwise it should not be localized
 * @note NotFoundErrors are usually reserved for cases when we know a user is
 * trying to access a specific resource (usually by ID) that either doesn't
 * exist or they don't have access to.
 */
export class NotFoundError<T = undefined> extends ApiError<T> {
  public resource: NotFoundResource

  constructor(
    resource: NotFoundResource,
    options: { metadata?: object; localizeOptions?: T; serializeMetadata?: boolean } = {},
    customMessageInput?: LocalizeFunction<T> | string
  ) {
    const friendlyResourceName = resource.charAt(0).toUpperCase() + resource.slice(1)
    const genericMessage = `${friendlyResourceName} was not found`
    super(
      404,
      customMessageInput ?? genericMessage,
      options.metadata,
      options.localizeOptions,
      options.serializeMetadata
    )
    this.resource = resource
  }
}

export class ValidationError<T = undefined> extends ApiError<T> {
  constructor(messageInput: LocalizeFunction<T>, metadata?: object, localizeOptions?: T, serializeMetadata?: boolean) {
    super(400, messageInput, metadata, localizeOptions, serializeMetadata)
  }
}
/**
 * Signals to the client of our API that there was something wrong with the input that they provided. These are expected to be thrown in the normal course of the operation of our API.
 * The error messages should be written with the expectation that they might be read directly by an end user (hence why they should be localized), so they should be as helpful, concise, and non-technical as possible.
 */
export class BadRequestError<T = undefined> extends ApiError<T> {
  constructor(messageInput: LocalizeFunction<T>, metadata?: object, localizeOptions?: T, serializeMetadata?: boolean) {
    super(400, messageInput, metadata, localizeOptions, serializeMetadata)
  }
}

/**
 * This error should be localized for any errors that are meant to be shown to end-users
 * otherwise it should not be localized
 *
 * A user that hits this error will get logged out by the client, so only throw this if
 * there is no valid state in which the user can remain logged in
 */
export class UnauthorizedError<T = undefined> extends ApiError<T> {
  public static status: number = 401

  constructor(
    messageInput: LocalizeFunction<T> | string,
    metadata?: object,
    localizeOptions?: T,
    serializeMetadata?: boolean
  ) {
    super(UnauthorizedError.status, messageInput, metadata, localizeOptions, serializeMetadata)
  }
}

/**
 * Non-localized authentication error for various issues that
 * there may be with tokens.
 */
export class InvalidTokenError<T = undefined> extends ApiError<T> {
  constructor(messageInput: string, metadata?: object, localizeOptions?: T, serializeMetadata?: boolean) {
    super(401, messageInput, metadata, localizeOptions, serializeMetadata)
  }
}

export class ForbiddenError<T = undefined> extends ApiError<T> {
  constructor(
    messageInput: LocalizeFunction<T> | string = 'Forbidden',
    metadata?: object,
    serializeMetadata?: boolean
  ) {
    super(403, messageInput, metadata, undefined, serializeMetadata)
  }
}

export class UserBannedError<T = undefined> extends ApiError<T> {
  constructor(
    messageInput: LocalizeFunction<T> | string = 'User Banned',
    metadata?: object,
    serializeMetadata?: boolean
  ) {
    super(403, messageInput, metadata, undefined, serializeMetadata)
  }
}

/**
 * This error is never localized because it is only intended to be sent
 * to our integration partners (they should have enough data from previous API calls to avoid hitting this error)
 *
 * If our integration partners cannot avoid hitting an error then a different localized error should be used
 */
export class IntegrationError extends ApiError {
  constructor(message: string, metadata?: any, serializeMetadata?: boolean) {
    super(400, message, metadata, undefined, serializeMetadata)
  }
}

/**
 * Sent by our servers when they are overloaded by incoming requests, to force backpressure on clients.
 *
 * If a client receives this error but still requires a response, they should retry with jitter and
 * exponential backoff.
 */
export class RateLimitError<T = undefined> extends ApiError<T> {
  public static status: number = 429

  constructor(messageInput?: LocalizeFunction<T>, metadata?: object, localizeOptions?: T, serializeMetadata?: boolean) {
    super(RateLimitError.status, messageInput ?? 'Too many requests', metadata, localizeOptions, serializeMetadata)
  }
}

export class ConflictError<T = undefined> extends ApiError<T> {
  public static status: number = 409

  constructor(messageInput?: LocalizeFunction<T>, metadata?: object, localizeOptions?: T, serializeMetadata?: boolean) {
    super(
      ConflictError.status,
      messageInput ?? 'The requested action conflicts with the state of data on the server',
      metadata,
      localizeOptions,
      serializeMetadata
    )
  }
}

export class NotImplementedError extends ApiError {
  constructor(message?: string, metadata?: any, serializeMetadata?: boolean) {
    super(501, 'Not Implemented Error' + (message ? `: ${message}` : ''), metadata, undefined, serializeMetadata)
  }
}

export class TemporarilyUnavailableError extends ApiError {
  constructor(message?: string, metadata?: any) {
    super(503, 'Temporarily unavailable' + (message ? `: ${message}` : ''), metadata)
  }
}

export class InternalServerError<T = undefined> extends ApiError<T> {
  constructor(messageInput: string | LocalizeFunction<T>, metadata?: any, serializeMetadata?: boolean) {
    super(
      500,
      typeof messageInput === 'string' ? `Internal Server Error: ${messageInput}` : messageInput,
      metadata,
      undefined,
      serializeMetadata
    )
  }
}

export class BadResponseError<T = undefined> extends ApiError<T> {
  constructor(metadata?: object, localizeOptions?: T, serializeMetadata?: boolean) {
    super(500, 'There is a problem validating the response', metadata, localizeOptions, serializeMetadata)
  }
}

export class DataConsistencyError<T = undefined> extends ApiError<T> {
  constructor(
    messageInput: string | LocalizeFunction<T>,
    metadata?: object,
    localizeOptions?: T,
    serializeMetadata?: boolean
  ) {
    super(500, messageInput, metadata, localizeOptions, serializeMetadata)
  }
}

/**
 * This error is not localized because the client should handle this error
 * in specific way. The client should re-fetch the cost of the item (trip/fare pass)
 * and present the new price to the customer.
 */
export class InsufficientChargeError<T = undefined> extends ApiError<T> {
  constructor(metadata?: any, serializeMetadata?: boolean) {
    super(
      400,
      'Charge amount is not sufficient to cover the cost of the purchase',
      metadata,
      undefined,
      serializeMetadata
    )
  }
}

/**
 * This error is thrown when the API runs into issues using the provided
 * payment method for a purchase. The client should ask the user to select a different
 * payment method and retry the purchase
 */
export class PaymentMethodError<T = undefined> extends ApiError<T> {
  constructor(messageInput: LocalizeFunction<T>, metadata?: any, localizeOptions?: T, serializeMetadata?: boolean) {
    super(400, messageInput, metadata, localizeOptions, serializeMetadata)
  }
}

/**
 * This error is thrown when the API runs into issues using the chosen
 * payment flow for the purchase, if this error is encountered client should attempt
 * an alternative payment flow if possible.
 *
 * Currently this happens when the ServerBased payment flow is chosen due to organization
 * preference, but the credit card only supports the ClientBased flow because the financial
 * institution requires actions to be completed, such a 2FA verification.
 */
export class PaymentFlowError extends ApiError<undefined> {
  constructor(metadata?: any, serializeMetadata?: boolean) {
    super(
      400,
      'Chosen payment flow was not supported, attempt operation again with an alternative flow',
      metadata,
      undefined,
      serializeMetadata
    )
  }
}

export class MissingOrganizationError extends ApiError {
  constructor(metadata?: any, serializeMetadata?: boolean) {
    super(
      400,
      'This API call requires scoping it down to a specific organization',
      metadata,
      undefined,
      serializeMetadata
    )
  }
}

export class MissingUserError extends ApiError {
  constructor(metadata?: any, serializeMetadata?: boolean) {
    super(400, 'This API call can only be called by a user', metadata, undefined, serializeMetadata)
  }
}

/**
 * Should be thrown ONLY to indicate when a sanity check fails or when the program is in a bad state that is probably the result of a bug in the code.
 */
export class UnexpectedError extends InternalServerError {}

export class ModelVersionError extends InternalServerError {}

export class InvariantViolationError extends InternalServerError {}

export class ServerTimeoutError extends InternalServerError {
  constructor(metadata?: any, serializeMetadata?: boolean) {
    super('Sorry, our server seems busy right now! Please try again in a moment.', metadata, serializeMetadata)
  }
}

export class ServiceUnavailableError<T = undefined> extends ApiError<T> {
  constructor(messageInput: LocalizeFunction<T>, metadata?: object, localizeOptions?: T, serializeMetadata?: boolean) {
    super(400, messageInput, metadata, localizeOptions, serializeMetadata)
  }
}

export class OrganizationUnavailableError extends ApiError {
  constructor(metadata?: any) {
    super(400, 'Organization is not available at the moment', metadata)
  }
}

export class InfeasibleInputDutyError extends ApiError {
  constructor(messageInput: string) {
    super(400, messageInput)
  }
}

export class IllegalDutyModificationError extends ApiError {
  constructor(messageInput: string) {
    super(400, messageInput)
  }
}
export class TimeRuleConstraintError extends ApiError {
  constructor(messageInput: string | LocalizeFunction, public readonly metadata: { estimateId: string }) {
    super(400, messageInput, metadata)
  }
}

export class OpenTripPlannerError<T = undefined> extends ApiError<T> {
  constructor(openTripPlannerError?: object, localizeOptions?: T, serializeMetadata?: boolean) {
    super(500, 'OpenTripPlanner returned an error', openTripPlannerError, localizeOptions, serializeMetadata)
  }
}

export class UnmappedApiError extends ApiError {}

/**
 * This error is thrown when your axios api client sends a request but receives no response from the server.
 */
export class NoResponseError extends MetadataError {
  constructor(cause: Error) {
    super(cause.message, { cause: toString(cause) })
  }
}

/**
 * This error is thrown when the server (or a gateway's upstream server) does not respond in time.
 */
export class GatewayTimeoutError extends ApiError {
  public static status: number = 504

  constructor(message: string) {
    super(GatewayTimeoutError.status, message)
  }
}

export const errorNameIs = (error: unknown, errorName: ErrorName): boolean =>
  ((error as Error)?.name as ErrorName) === errorName

export class RequestNotInServiceHoursError<T = undefined> extends ApiError<T> {
  constructor(messageInput: LocalizeFunction<T>, metadata?: object, localizeOptions?: T, serializeMetadata?: boolean) {
    super(400, messageInput, metadata, localizeOptions, serializeMetadata)
  }
}
