import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WebColors } from '@sparelabs/colors'
import React, { useState } from 'react'
import styled from 'styled-components'
import { st } from '../locales/TranslationHelper'
import { borderRadius, boxShadow } from '../util'

const FadeAway = styled.div`
  height: 120px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), ${WebColors.backgroundPrimary});
`

const CollapsedContainer = styled.div`
  max-height: 350px;
  position: relative;
  overflow: hidden;
`

const ExpandedContainer = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
`

const ShowMoreButton = styled.button`
  display: flex;
  justify-content: center;
  position: absolute;
  text-align: center;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  bottom: 0;
  color: ${WebColors.interactivePrimary};
  gap: 4px;
  background: transparent;
  border: none;

  :focus {
    outline: none;
  }

  :hover {
    color: ${WebColors.interactivePrimaryHover};
    cursor: pointer;
  }

  :hover,
  :focus {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), ${WebColors.backgroundSecondary});
  }
`

const ShowLessButton = styled(ShowMoreButton)`
  position: unset;

  :hover,
  :focus {
    background: inherit;
  }
`

const CutoffIcon = styled(FontAwesomeIcon)`
  width: 15px;
  height: 15px;
  align-self: center;
`

interface IProps {
  children?: React.ReactNode
  showLessButton?: boolean
  isInitiallyExpanded?: boolean
}

/**
 * A container that will cut off its content after a certain height and show a "Show more" button
 */
export const CutoffContainer = ({
  children,
  showLessButton = false,
  isInitiallyExpanded = false,
  ...rest
}: IProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded)

  const toggle = () => setIsExpanded(!isExpanded)

  if (isExpanded) {
    return (
      <ExpandedContainer>
        {children}
        {showLessButton && (
          <ShowLessButton onClick={toggle}>
            <span>{st.cutoffContainer.showLess()}</span>
            <CutoffIcon icon='chevron-up' aria-hidden />
          </ShowLessButton>
        )}
      </ExpandedContainer>
    )
  }

  return (
    <CollapsedContainer {...rest}>
      <FadeAway />
      {children}(
      <ShowMoreButton onClick={toggle}>
        <span>{st.cutoffContainer.showMore()}</span>
        <CutoffIcon icon='chevron-down' aria-hidden />
      </ShowMoreButton>
      )
    </CollapsedContainer>
  )
}

/**
 * A wrapper around CutoffContent that adds a shadow and border radius so that
 * the bottom of Card components don't look weird when collapsed
 */
export const CutoffCard = styled(CutoffContainer)`
  border-radius: ${borderRadius};
  box-shadow: ${boxShadow};
`
