import { IAbsoluteTimeConstraint, TimeConstraintType } from '.'
import { IRelativeTimeConstraint, ITimeConstraint } from './TimeConstraintTypes'

export class LatenessConstraintCalculator {
  public static calculateForAbsolute(constraint: IAbsoluteTimeConstraint, initialEta: number): IAbsoluteTimeConstraint {
    return initialEta <= constraint.max ? constraint : { ...constraint, max: initialEta }
  }

  public static calculateForLeaveAtDropoff(
    pickupConstraint: IAbsoluteTimeConstraint,
    dropoffConstraint: IRelativeTimeConstraint,
    pickupEtaTs: number,
    dropoffEtaTs: number
  ): ITimeConstraint {
    const earliestPossiblePickupTs = Math.max(pickupConstraint.min, pickupEtaTs)
    const estimatedTravelDuration = dropoffEtaTs - earliestPossiblePickupTs
    return estimatedTravelDuration > dropoffConstraint.maxOffset
      ? // we still want the relative constraint to be scheduled as early as possible,
        // but we want to place an upper bound on how much later it can be
        {
          type: TimeConstraintType.Absolute,
          min: pickupConstraint.min,
          desired: pickupConstraint.min,
          max: Math.max(pickupEtaTs + dropoffConstraint.maxOffset, dropoffEtaTs),
        }
      : dropoffConstraint
  }
}
