import { VerificationMethod } from '@sparelabs/api-client'
import React, { useEffect, useState } from 'react'
import { ApiClientBuilder, EngageClientBuilder } from 'src/api/ApiClientBuilders'
import { LoadingView } from 'src/components'
import { ErrorHandler, FormValidator, ValidateConstraintType, withErrorHandler } from 'src/helpers'
import { AuthenticationHelper } from 'src/helpers/AuthenticationHelper'
import { st } from 'src/locales/TranslationHelper'
import { IFormContext, useFormContext } from '../FormContext'
import { FormSubmitted } from '../FormStepTypes'
import { RiderAuthPhoneForm } from './RiderAuth/RiderAuthPhoneForm'
import { RiderAuthVerifyForm } from './RiderAuth/RiderAuthVerifyForm'

const validatePhoneNumber = (value?: string) =>
  FormValidator.validate(value, st.forms.auth.phoneNumberLabel(), {
    required: true,
    type: ValidateConstraintType.PhoneNumber,
  })

const validateCode = (value?: string) => FormValidator.validate(value, st.forms.auth.codeLabel(), { required: true })

/**
 * Grab previous case form if it exists
 */
const postAuthUpdate = async (
  setCaseForm: IFormContext['setCaseForm'],
  formId: string,
  caseId?: string,
  abortSignal?: AbortSignal
) => {
  if (!caseId) {
    return
  }

  const client = EngageClientBuilder.build()

  // Validate caseId
  const userCase = await client.cases.get(caseId)
  if (!userCase) {
    throw new Error(st.errors.noPermission())
  }

  // Fetch previous case form
  const caseForm = (await client.caseForms.list({ caseId, formId })).data[0]
  if (caseForm && !abortSignal?.aborted) {
    setCaseForm(caseForm, caseForm ? FormSubmitted.Previously : FormSubmitted.Never)
  }
}

export const RiderAuthStep = (): JSX.Element => {
  const { caseId, form, organization, onNextStep, setCaseForm } = useFormContext()
  const [showVerify, setShowVerify] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const controller = new AbortController()
    async function checkAuth() {
      if (AuthenticationHelper.isAuthenticated()) {
        await postAuthUpdate(setCaseForm, form.id, caseId, controller.signal)
        onNextStep()
      } else {
        setLoading(false)
      }
    }

    checkAuth().catch(ErrorHandler.handleError)
    return () => controller.abort()
  }, [form, caseId]) // eslint-disable-line react-hooks/exhaustive-deps

  const sendPhoneLoginRequest = async (
    phoneNumber: string | undefined,
    verificationMethod: VerificationMethod = VerificationMethod.Sms
  ) => {
    if (!phoneNumber) {
      setShowVerify(false)
      setError(st.validation.invalidPhoneNumber())
      return
    }
    const client = ApiClientBuilder.build()
    await client.auth.phoneLoginRequest({ phoneNumber, verificationMethod, organizationId: organization.id })
  }

  const handlePhoneNumberSubmit = withErrorHandler(async (phoneNumber: string) => {
    const phoneNumberError = validatePhoneNumber(phoneNumber)
    if (phoneNumberError) {
      setError(phoneNumberError)
      return
    }
    setError(undefined)

    await sendPhoneLoginRequest(phoneNumber)
    setShowVerify(true)
    setPhoneNumber(phoneNumber)
  })

  const handleVerifySubmit = withErrorHandler(async (code: string) => {
    const codeError = validateCode(code)
    if (codeError) {
      setError(codeError)
      return
    } else if (!phoneNumber) {
      setShowVerify(false)
      setError(st.validation.invalidPhoneNumber())
      return
    }
    setError(undefined)

    const client = ApiClientBuilder.build()
    const result = await client.auth.phoneLoginVerify({
      phoneNumber,
      code,
      organizationId: organization.id,
    })

    AuthenticationHelper.setAuth(result)
    await postAuthUpdate(setCaseForm, form.id, caseId)
    onNextStep()
  })

  if (loading) {
    return <LoadingView />
  }

  const handleResendClick = withErrorHandler((method: VerificationMethod) => sendPhoneLoginRequest(phoneNumber, method))

  return showVerify ? (
    <RiderAuthVerifyForm
      error={error}
      onResendClick={handleResendClick}
      onBackClick={() => setShowVerify(false)}
      onSubmit={handleVerifySubmit}
    />
  ) : (
    <RiderAuthPhoneForm
      error={error}
      initialValue={phoneNumber}
      onSubmit={handlePhoneNumberSubmit}
      terms={form.organizationTerms}
    />
  )
}
