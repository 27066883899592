import { z } from '@sparelabs/zod'

export enum AddressFieldName {
  FirstName = 'firstName',
  LastName = 'lastName',
  Country = 'country',
  City = 'city',
  PostalCode = 'zip',
  Zone = 'province',
  Address1 = 'address1',
  Address2 = 'address2',
  Phone = 'phone',
  Company = 'company',
}

export type AddressFieldNameNoCompanyAndPhone = Exclude<
  AddressFieldName,
  AddressFieldName.Company | AddressFieldName.Phone
>

export type ZoneKey =
  | 'COUNTY'
  | 'EMIRATE'
  | 'GOVERNORATE'
  | 'PREFECTURE'
  | 'PROVINCE'
  | 'REGION'
  | 'STATE_AND_TERRITORY'
  | 'STATE'

export const IAddressSchema = z.object({
  version: z.number(),
  company: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  address1: z.string(),
  address2: z.string(),
  city: z.string(),
  province: z.string().optional(),
  zip: z.string(),
  // Country code ISO 3166-1 alpha-2
  country: z.string(),
  phone: z.string().optional(),
})

export type IAddress = z.infer<typeof IAddressSchema>

export interface IZone {
  code: string
  name: string
}

export interface ILoadCountriesResponse {
  data: { countries: ICountry[] }
  errors?: IGraphqlError[]
}

export interface ILoadCountryResponse {
  data: { country: ICountry }
  errors?: IGraphqlError[]
}

export interface ICountry {
  name: string
  code: string
  continent: string
  phoneNumberPrefix: number
  autocompletionField: string
  provinceKey: ZoneKey
  labels: {
    address1: string
    address2: string
    city: string
    company: string
    country: string
    firstName: string
    lastName: string
    phone: string
    postalCode: string
    zone: string
  }
  formatting: {
    edit: string
    show: string
  }
  zones: IZone[]
}

export interface IResponseError {
  errors: IGraphqlError[]
}

export interface IGraphqlError {
  message: string
  locations?: Array<{
    column: number
    line: number
  }>
  path?: any[]
  extensions?: object
}

export enum GraphqlOperationName {
  Countries = 'countries',
  Country = 'country',
}
