import { PaymentMethodBrand } from '@sparelabs/domain-logic'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { PaymentMethodStripeData } from '@sparelabs/stripe'
import { ConnectedAccountType } from './ConnectedAccountTypes'
import { PaymentFlow } from './PaymentFlowTypes'
import { IWalletAutoTopUpResponse } from './WalletTypes'
/**
 * @deprecated use from @sparelabs/domain-logic instead
 */
export { PaymentMethodBrand }

export type PaymentMethodExternalData = PaymentMethodStripeData | {}

export interface IPaymentMethodResponse extends IModelJsonValues {
  status: PaymentMethodStatus
  connectedAccountId: string
  connectedAccountType: ConnectedAccountType
  paymentMethodTypeId: string
  default: boolean
  externalId: string
  userId: string
  // Brand of payment method
  brand: PaymentMethodBrand
  // The last four digits of the card / payment method if this information is known.
  last4Digits: string | null
  // Two-digit number representing the card’s expiration month if one exists
  expiryMonth: number | null
  // Four-digit number representing the card’s expiration year if one exists
  expiryYear: number | null
  /**
   * Payment flows supported by the payment method. This largely depends on the brand and connected
   * account provider.
   */
  supportedPaymentFlows: PaymentFlow[]
  balance: number | null
  /**
   * @deprecated
   * This data should not be used on the client
   * Any data that is necessary to expose to the client, should be exposed using the a standardized field
   */
  externalData: any

  walletAutoTopUp: IWalletAutoTopUpResponse | null
}

export interface IPaymentMethodQueryParams extends IListModelQueryParamValues {
  status?: PaymentMethodStatus
  /**
   * @deprecated use paymentMethodTypeId instead
   */
  connectedAccountType?: ConnectedAccountType
  /**
   * Filter by payment method type
   */
  paymentMethodTypeId?: string
  userId?: string
}

export interface IPaymentProvider {
  connectedAccountType: ConnectedAccountType
  clientSafe: any
}

export enum PaymentMethodStatus {
  Valid = 'valid',
  Archived = 'archived',
}
