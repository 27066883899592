import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum EventPermissionSet {
  View = 'event_view',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const eventPermissionSetGrouping: IPermissionSetGrouping<EventPermissionSet> = {
  key: PermissionSetGroupingKey.Event,
  active: {
    [EventPermissionSet.View]: [
      PermissionType.GET_ANY_EVENT,
      PermissionType.LIST_ANY_EVENT,
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
