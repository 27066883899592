import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum ConnectedAccountPermissionSet {
  View = 'connected_account_view',
  Delete = 'connected_account_delete',
}

export const connectedAccountPermissionSetGrouping: IPermissionSetGrouping<ConnectedAccountPermissionSet> = {
  key: PermissionSetGroupingKey.ConnectedAccount,
  active: {
    [ConnectedAccountPermissionSet.View]: [PermissionType.GET_ANY_CONNECTED_ACCOUNT],
    [ConnectedAccountPermissionSet.Delete]: [PermissionType.DELETE_ANY_CONNECTED_ACCOUNT],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
