import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IDeviceDeleteQuery,
  IDeviceListQueryParams,
  IDevicePatchBody,
  IDevicePostBody,
  IDeviceResponse,
  Resources,
} from '../types'

export class DeviceResource extends GenericResource<
  IDeviceResponse,
  IDeviceListQueryParams,
  IDevicePatchBody,
  IDevicePostBody
> {
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()

  constructor(agent: AxiosInstance) {
    super(Resources.Devices, agent)
  }

  public async del(params: IDeviceDeleteQuery): Promise<void> {
    await this.agent.delete(this.resource, { params })
  }
}
