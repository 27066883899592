import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'

export interface IGroupResponse extends IModelJsonValues, IGroupPostBody {
  status?: GroupStatus
}

export interface IGroupPostBody {
  name: string
  secretCode: string
  description: string | null
}

export enum GroupStatus {
  Active = 'active',
  Archived = 'archived',
}

export type IGroupPatchBody = Partial<IGroupPostBody> & { status?: GroupStatus }

export interface IGroupQueryParams extends IListModelQueryParamValues {
  status?: GroupStatus
}
