import { IListModelQueryParamValues, IModelJsonValuesSchema } from '@sparelabs/http-client-utils'
import { z } from '@sparelabs/zod'
import { CaseStatusStage, CaseStatusTheme } from './CaseStatusTypes'

export enum CaseTimerEventType {
  Start = 'start',
  Stop = 'stop',
}

export interface ICaseTimerEvent {
  type: CaseTimerEventType
  eventTs: number
}

export interface ICaseTimer {
  limitTs: number
  events: ICaseTimerEvent[]
}

export interface ICasePatchBody {
  userId?: string
  statusId?: string
  assignedTo?: string | null
  note?: string | null
  timerEvents?: ICaseTimerEvent[] | null
  isArchived?: boolean
}

export const ICaseTimerEventSchema = z.object({
  type: z.nativeEnum(CaseTimerEventType).describe('The type of the timer event'),
  eventTs: z.number().describe('The timestamp that the event occurred at, in Unix seconds'),
})

export const ICasePostBodySchema = z.object({
  userId: z
    .string()
    .describe(
      'The id of the user the case is about (i.e. for example, the potential rider applying for paratransit eligibility)'
    ),
  caseTypeId: z.string().describe('The id of the case type'),
  note: z.string().nullable().describe('Notes about the case'),
})

export type ICasePostBody = z.infer<typeof ICasePostBodySchema>

export const CaseResponseSchema = IModelJsonValuesSchema.extend(ICasePostBodySchema.shape).extend({
  statusId: z.string().describe("The id of case's status"),
  assignedTo: z.string().nullable().optional().describe('The id of the user assigned to the case'),
  timerLimitTs: z.number().nullable().describe('Total time limit in Unix seconds'),
  timerEvents: z
    .array(ICaseTimerEventSchema)
    .nullable()
    .describe('List of all the timer events. Each element contains a Start/Stop type and an eventTs in Unix seconds.'),
  timerDeadlineTs: z
    .number()
    .nullable()
    .describe(
      'Deadline in Unix seconds. It will only be calculated / displayed when the current case timer type is Start.'
    ),
  isArchived: z.boolean().describe('Whether the case is archived'),
})

export type ICaseResponse = z.infer<typeof CaseResponseSchema>

export interface IListCaseQueryParams extends IListModelQueryParamValues {
  statusId?: string
  stages?: CaseStatusStage[]
  userId?: string
  caseTypeId?: string
  assignedTo?: string
  riderInterfaceVisibility?: boolean
  fromTimerDeadlineTs?: number
  toTimerDeadlineTs?: number
  isArchived?: boolean
  formIds?: string[]
}

export interface ICaseSearchResponseItem {
  id: string
  createdAt: number
  updatedAt: number
  user: {
    id: string
    name: string
  }
  assignedTo: {
    id: string | null
    name: string | null
  }
  caseTypeKey: string
  caseTypeName: string
  caseStatusLabel: string
  caseStatusTheme: CaseStatusTheme
}

export interface ICaseSearchResponse {
  data: ICaseSearchResponseItem[]
}

export interface ICaseSearchQueryParams {
  ids?: string[]
  userIds?: string[]
  search?: string
  limit?: number
  fromCreatedAtTs?: number
  toCreatedAtTs?: number
}
