import styled from 'styled-components'

interface IFieldGapProps {
  gap?: string
}

export const FieldGap = styled.div<IFieldGapProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : '8px')};
`
