import { AllReportsModels } from './AnalyzeReportModelTypes'

export enum DriverReportDriverColumns {
  DriverName = 'driverName',
}

export enum DriverReportDutyColumns {
  DutyId = 'dutyId',
  DutyIdentifier = 'dutyIdentifier',
  CreatedAtTime = 'createdAtTime',
  StartRequestedTime = 'startRequestedTime',
  StartCompletedTime = 'startCompletedTime',
  EndRequestedTime = 'endRequestedTime',
  EndCompletedTime = 'endCompletedTime',
  CancelledTime = 'cancelledTime',
  Status = 'status',
  FirstSeenInServiceAreaTime = 'firstSeenInServiceAreaTime',
  LastSeenInServiceAreaTime = 'lastSeenInServiceAreaTime',
  TotalDurationHours = 'totalDurationHours',
  TotalScheduledHours = 'totalScheduledHours',
  TotalTravelDistanceM = 'totalTravelDistanceM',
  TotalRevenueDistanceM = 'totalRevenueDistanceM',
  DeadheadTravelDistanceM = 'deadheadTravelDistanceM',
  TotalDurationNoRidersOnboardS = 'totalDurationNoRidersOnboardS',
  TotalInProgressPauseLengthS = 'totalInProgressPauseLengthS',
}

export enum DriverReportVehicleColumns {
  VehicleIdentifier = 'vehicleIdentifier',
  VehicleLicensePlate = 'vehicleLicensePlate',
}

export enum DriverReportFleetColumns {
  FleetName = 'fleetName',
  FleetType = 'fleetType',
}

export enum DriverReportBoardingColumns {
  TotalBoardings = 'totalBoardings',
  RiderInterfaceBoardings = 'riderInterfaceBoardings',
  RiderWebInterfaceBoardings = 'riderWebInterfaceBoardings',
  DriverInterfaceBoardings = 'driverInterfaceBoardings',
  AdminInterfaceBoardings = 'adminInterfaceBoardings',
  IvrInterfaceBoardings = 'ivrInterfaceBoardings',
}

export enum DriverReportBreakColumns {
  TotalBreakLengthS = 'totalBreakLengthS',
  TotalNumberOfBreaks = 'totalNumberOfBreaks',
  TotalScheduledBreakLengthS = 'totalScheduledBreakLengthS',
  TotalBreakDistanceM = 'totalBreakDistanceM',
  Breaks = 'breaks',
}

/**
 * Models that are included in the Ridership Report
 *
 * The order here controls the order of models on the 'Edit Columns' modal
 */
export const DriverReportModelNamesArray = [
  AllReportsModels.Driver,
  AllReportsModels.Duty,
  AllReportsModels.Break,
  AllReportsModels.Vehicle,
  AllReportsModels.Fleet,
  AllReportsModels.Boarding,
]

export type IDriverReportColumns =
  | DriverReportDriverColumns
  | DriverReportDutyColumns
  | DriverReportBreakColumns
  | DriverReportVehicleColumns
  | DriverReportFleetColumns
  | DriverReportBoardingColumns

export const DriverReportModelAndColumns = {
  [AllReportsModels.Driver]: DriverReportDriverColumns,
  [AllReportsModels.Duty]: DriverReportDutyColumns,
  [AllReportsModels.Break]: DriverReportBreakColumns,
  [AllReportsModels.Vehicle]: DriverReportVehicleColumns,
  [AllReportsModels.Fleet]: DriverReportFleetColumns,
  [AllReportsModels.Boarding]: DriverReportBoardingColumns,
}

export const DriverReportColumnNames = Object.values<IDriverReportColumns>(DriverReportDriverColumns)
  .concat(Object.values(DriverReportDutyColumns))
  .concat(Object.values(DriverReportVehicleColumns))
  .concat(Object.values(DriverReportFleetColumns))
  .concat(Object.values(DriverReportBoardingColumns))
  .concat(Object.values(DriverReportBreakColumns))
