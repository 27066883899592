import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IAppDeveloperOnlyResponse,
  IAppPatchBody,
  IAppPostBody,
  IAppResponse,
  IListAppQueryParams,
  Resources,
} from '../types'

export class AppResource extends GenericResource<IAppResponse, IListAppQueryParams, IAppPatchBody, IAppPostBody> {
  constructor(agent: AxiosInstance) {
    super(Resources.App, agent)
  }

  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  public del = this.buildDel()

  public async patchClientSecret(id: string): Promise<IAppDeveloperOnlyResponse & { clientSecret: string }> {
    const res = await this.agent.patch<IAppDeveloperOnlyResponse & { clientSecret: string }>(
      `${this.resource}/${id}/clientSecret`
    )
    return res.data
  }
}
