/**
 * Generic Utils - Handlebars Helpers
 */

// Internal Helpers
const isArray = (thing: any[]): boolean => Object.prototype.toString.call(thing) === '[object Array]'
const nullSafeString = (value: string): string => value ?? ''

// Logical comparisons
export const eq = (value1: any, value2: any): boolean => value1 === value2

export const uneq = (value1: any, value2: any): boolean => value1 !== value2

export const lt = (value1: any, value2: any): boolean => value1 < value2

export const lte = (value1: any, value2: any): boolean => value1 <= value2

export const gt = (value1: any, value2: any): boolean => value1 > value2

export const gte = (value1: any, value2: any): boolean => value1 >= value2

export const and = (...values: unknown[]): boolean => values.every(Boolean)

export const or = (...values: unknown[]): boolean => values.some(Boolean)

// Array utils
export const length = (array: unknown[]): string => {
  if (!isArray(array)) {
    return ''
  }

  return array.length.toString()
}

export const includes = (array: unknown[], value: unknown): boolean => {
  if (!isArray(array) || array.length === 0) {
    return false
  }

  for (const item of array) {
    if (item === value) {
      return true
    }
  }

  return false
}

// String utils
// These functions need to check for undefined/null values to avoid errors
// when called only during runtime (eg. as a Handlebars helper)
export const lowercase = (value: string): string => nullSafeString(value).toLowerCase()

export const uppercase = (value: string): string => nullSafeString(value).toUpperCase()

export const contains = (string: string, substring: string): boolean => nullSafeString(string).includes(substring)

// Math utils
export const add = (...values: unknown[]): number => values.reduce((acc: number, value) => acc + Number(value), 0)

export const subtract = (...values: unknown[]): number =>
  values.length === 0 ? 0 : Number(values[0]) - add(values.slice(1))

export const multiply = (...values: unknown[]): number => values.reduce((acc: number, value) => acc * Number(value), 1)

export const divide = (...values: unknown[]): number => {
  if (values.length === 0 || Number(values[0]) === 0) {
    return 0
  }

  const denominator = multiply(values.slice(1))
  return denominator === 0 ? 0 : Number(values[0]) / denominator
}

export const round = (value: unknown, precision?: unknown) => Number(value).toFixed(Number(precision) || 0)

export const modulo = (value: unknown, divisor: unknown): number => Number(value) % Number(divisor)

export const percent = (value: unknown, total: unknown): number => (Number(value) / Number(total)) * 100
