import { ICustomFieldResponse } from './CustomFieldTypes'

export enum ImportResultType {
  Success = 'success',
  Fail = 'fail',
}

export enum ImportErrorCategory {
  NumberFormatting = 'numberFormatting',
  DateFormatting = 'dateFormatting',
  TimeFormatting = 'timeFormatting',
  PhoneNumberFormatting = 'phoneNumberFormatting',
  UUIDFormatting = 'uuidFormatting',
  BooleanFormatting = 'booleanFormatting',
  RiderIdFormatting = 'riderIdFormatting',
  UnknownEnumValue = 'unknownEnumValue', // Value given is not in the list of choices.
  ValueOutsideLimit = 'valueOutsideLimit', // Value is outside a limit. E.g. endTime before startTime, number not finite
  MissingRequiredValue = 'missingRequiredValue', // A required value is not present.
  FeatureDisabled = 'featureDisabled', // Value is not enabled for this entity. E.g. Feature flag disabled, language not enabled
  EntityNotFound = 'entityNotFound', // Entity is not in the db. E.g. uuid does not match id, name search returns no results
  EntityValidation = 'entityValidation', // Entity precondition not met. E.g. Request validation failed
  GeocodeConversion = 'geocodeConversion', // Geocode conversion failed. E.g. Address not found, multiple locations found
  IncompatibleWithExistingData = 'incompatibleWithExistingData', // Value would be allowed, but cannot be saved because of existing data. E.g. Unique key conflict on name
  UnknownPersistenceError = 'unknownPersistenceError', // Shared code used for entity saving threw an error
  FileMissingFromTemporaryBucket = 'fileMissingFromTemporaryBucket', // A file column expected a file to exist in the temporary bucket
  FileColumnValidation = 'fileColumnValidation',
  FileColumnUnexpectedError = 'fileColumnUnexpectedError',
  AddressColumnValidation = 'addressColumnValidation',
}

export enum ImportFatalErrorCategory {
  NoColumns = 'noColumns',
  NoRows = 'noRows',
  MissingRequiredColumn = 'missingRequiredColumn', // Missing required column (and non-update row exists)
  InvalidMetadataColumn = 'invalidMetadataColumn', // Metadata does not have a given property
  InactiveMetadataColumn = 'inactiveMetadataColumn', // Metadata has a property, but it is inactive
  InvalidCsv = 'invalidCsv', // Error thrown by our csv parsing library (e.g. wrong file format, header/data column count mismatch)
}

export type DataImportResult = IImportSuccessfulResponse | IImportFatalErrorResponse

export interface ICsvFatalError {
  message: string
  category: ImportFatalErrorCategory
  metadata?: Record<string, unknown>
}

export interface ICsvRowError {
  message: string
  rowIndex: number
  columnName: string | undefined
  itemFieldName?: string // Set automatically in the CsvParser
  category: ImportErrorCategory
  metadata?: Record<string, unknown>
}

export interface IImportSuccessfulResponse {
  type: ImportResultType.Success
  errorCsvString: string
  errors: ICsvRowError[]
  successCount: number
  failedCount: number
}
export interface IImportFatalErrorResponse {
  type: ImportResultType.Fail
  fatalErrors: ICsvFatalError[]
}

export type ImportResponse = IImportSuccessfulResponse | IImportFatalErrorResponse

export enum ImportColumnType {
  Text = 'text',
  Number = 'number',
  Integer = 'integer',
  UUID = 'uuid',
  Date = 'date',
  Time = 'time',
  Phone = 'phone',
  Boolean = 'boolean',
}

export type ImportColumnDetails =
  | { type: ImportColumnType | ImportColumnType[]; isArray?: boolean; additionalInfo?: string }
  | { type: 'enum'; choices: string[]; isArray?: boolean; additionalInfo?: string }

export interface IImportDetails<RequiredColumn extends string, OptionalColumn extends string> {
  columns: {
    required: Record<RequiredColumn, ImportColumnDetails>
    optional: Record<OptionalColumn, ImportColumnDetails>

    /** Column names created with a string literal template will be recreated based on this information */
    template?: IImportTemplateColumn[]
  }
  exampleData: Array<Partial<Record<RequiredColumn | OptionalColumn, string>>>
}

export interface IImportTemplateColumn {
  name: string
  prefixes: string[]
  postfixes: Array<{ value: string; detail: ImportColumnDetails }>
}

export interface IImportDetailsResponseCustomFields {
  prefix: string
  fields: Array<Pick<ICustomFieldResponse, 'key' | 'type' | 'isRequired' | 'choices'>>
}

export type ImportDetailsResponse<
  RequiredColumn extends string = string,
  OptionalColumn extends string = string
> = IImportDetails<RequiredColumn, OptionalColumn> & { customFields: IImportDetailsResponseCustomFields[] | null }
