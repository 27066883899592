import moment from 'moment-timezone'

export class TimestampUtils {
  /**
   * Rounds a timestamp to the start of a minute.
   *
   * Example: roundToZerothSecond(30) = 0
   */
  public static roundToZerothSecond = (ts: number): number => {
    const remainder = ts % 60
    return ts - remainder
  }

  /**
   * Rounds a timestamp to the end of a minute, eg. the last second of that minute.
   *
   * Example: roundToFiftyNinthSecond(30) = 59
   */
  public static roundToFiftyNinthSecond = (ts: number): number => {
    const remainder = ts % 60
    const toAdd = 59 - remainder
    return ts + toAdd
  }

  /**
   * Difference in "hours of day" between 2 timestamps
   * @param unixTsA
   * @param unixTsB
   */
  public static absoluteHourOfDayDifference(unixTsA: number, unixTsB: number, timezone: string): number {
    const momentA = moment.unix(unixTsA).tz(timezone)
    const momentB = moment.unix(unixTsB).tz(timezone)
    const hourDiff = Math.abs(momentA.hour() - momentB.hour()) % 24
    return Math.min(hourDiff, 24 - hourDiff)
  }
}
