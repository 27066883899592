import { WebColors } from '@sparelabs/colors'
import React from 'react'
import styled, { css } from 'styled-components'
import { borderRadius } from '../util'
import { DifferenceType } from './DifferenceHelpers'

const LINE_BORDER_RADIUS = '4px'

interface IDifferenceTheme {
  lineBackground: string
  itemBackground: string
  text: string
}

const DifferenceThemeMap: Record<DifferenceType, IDifferenceTheme> = {
  [DifferenceType.Previous]: {
    lineBackground: WebColors.negativeLowContrast,
    itemBackground: WebColors.negativeLowContrastActive,
    text: WebColors.negativeHighContrast,
  },
  [DifferenceType.Current]: {
    lineBackground: WebColors.positiveLowContrast,
    itemBackground: WebColors.positiveLowContrastActive,
    text: WebColors.positiveHighContrast,
  },
}

const DifferenceFontSize = css`
  min-height: 20px;
  line-height: 20px;
  font-size: 15px;
`

export const DifferenceContainer = styled.div`
  border-radius: ${borderRadius};
  padding: 16px 8px;
  border: 1px solid ${WebColors.borderPrimary};
  background-color: ${WebColors.backgroundSecondary};
`

export const DifferenceLabel = styled.label`
  flex: 50%;
  color: ${WebColors.contentSecondary};
  padding: 0px 8px;
  ${DifferenceFontSize}
`

export const DifferenceLine = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  ${DifferenceFontSize}
`

export const DifferenceHalf = styled.div<{
  different: boolean
  type: DifferenceType
  isTop: boolean
  isBottom: boolean
}>`
  flex: 50%;
  background-color: ${({ different, type }) => (different ? DifferenceThemeMap[type].lineBackground : '')};
  color: ${({ different, type }) => (different ? DifferenceThemeMap[type].text : '')};
  padding: 0px 8px;
  border-top-left-radius: ${({ isTop }) => (isTop ? LINE_BORDER_RADIUS : '')};
  border-top-right-radius: ${({ isTop }) => (isTop ? LINE_BORDER_RADIUS : '')};
  border-bottom-left-radius: ${({ isBottom }) => (isBottom ? LINE_BORDER_RADIUS : '')};
  border-bottom-right-radius: ${({ isBottom }) => (isBottom ? LINE_BORDER_RADIUS : '')};
`

export const DifferenceItem = styled.span<{ different: boolean; type: DifferenceType }>`
  background-color: ${({ different, type }) => (different ? DifferenceThemeMap[type].itemBackground : '')};
  white-space: pre-wrap;
  border-radius: ${LINE_BORDER_RADIUS};
`

export const DifferenceHeader = () => (
  <DifferenceLine>
    <DifferenceLabel>Previous Value</DifferenceLabel>
    <DifferenceLabel>Current Value</DifferenceLabel>
  </DifferenceLine>
)
