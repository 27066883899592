import { PermissionSetType, RiderPermissionSet } from '../sets'
import { AnalyticsPermissionSet } from '../sets/AnalyticsPermissions'
import { AdminOperatorRole } from './AdminOperatorRole'

export const AccessToAnalyticsRole: PermissionSetType[] = [
  ...AdminOperatorRole,

  // TODO: Replace rider permission set with more limited permission sets
  RiderPermissionSet.Rider,

  AnalyticsPermissionSet.Access,
]
