export type Role = VirtualRole | AdminRole | SuperAdminRole

/**
 * These roles are "virtual" because they are not stored in the database explicitly - rather, they
 * are inferred from other data.
 */
export enum VirtualRole {
  /**
   * Can book rides for oneself, but not for others. Can manage their own profile. Generally
   * has read access to all data that they produce through their interactions with Spare Platform.
   * Any user that has an OrganizationRider with an organization automatically has the Rider role
   * for that org.
   */
  Rider = 'rider',
  /**
   * Can start/end duties, pick up and drop off riders, cancel other riders trips in the case of
   * a no show, etc.
   * Any user that has an OrganizationDriver with an organization automatically has the Driver role
   * for that org.
   */
  Driver = 'driver',
  UserOnlyToken = 'userOnlyToken',
}

/**
 * These roles are stored in the OrganizationAdmin object.
 */
export enum AdminRole {
  /**
   * An Administrator of an organization has pretty much full access to do anything within that org.
   */
  Administrator = 'administrator',
  /**
   * Manager of day-to-day operations. Tends to deal with all sorts of problems on day-of operations (stranded riders,
   * vehicle breakdowns, eliminating lateness, etc.). Can do everything booking agents and schedulers can do, and more.
   */
  OperationsManager = 'operationsManager',
  /**
   * Usually call center agents who book trips on behalf of other riders.
   */
  BookingAgent = 'bookingAgent',
  /**
   * People who schedule duties for drivers.
   */
  Scheduler = 'scheduler',
  /**
   * People who control high level configuration (setting up/modifying services, zones, groups, etc.).
   */
  ServicePlanner = 'servicePlanner',
  FinanceManager = 'financeManager',
  AccessToLaunchReports = 'accessToLaunchReports',
  AccessToEngageReports = 'accessToEngageReports',
  AccessToAnalytics = 'accessToAnalytics',
  AccessToRealize = 'accessToRealize',
  AccessToEngage = 'accessToEngage',
  Developer = 'developer',

  /**
   * These roles are only available to the CapMetro organization, via the capMetroCustomRoles feature flag
   */
  CapMetroOffice = 'capMetroOffice',
  CapMetroSuperUser = 'capMetroSuperUser',
  CapMetroServiceCoordinator = 'capMetroServiceCoordinator',
  CapMetroServiceProvider = 'capMetroServiceProvider',
  CapMetroCallCenter = 'capMetroCallCenter',
  CapMetroReadOnly = 'capMetroReadOnly',
}

export enum SuperAdminRole {
  Root = 'rootSuperAdmin',
  SiteReliabilityEngineer = 'siteReliabilityEngineer',
  EngineeringLead = 'engineeringLead',
  PartnerSuccess = 'partnerSuccess',
  Default = 'defaultSuperAdmin',
  Testing = 'testingSuperAdmin',
}
