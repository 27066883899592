import {
  CasePermissionSet,
  CaseTypePermissionSet,
  EngageReportingPermissionSet,
  FormPermissionSet,
  LetterPermissionSet,
  NylasAdminGrantPermissionSet,
  PermissionSetType,
  RiderPermissionSet,
} from '../sets'
import { EventPermissionSet } from '../sets/EventPermissions'
import { GroupPermissionSet } from '../sets/GroupPermissions'
import { RiderUserPermissionSet } from '../sets/RiderUserPermissions'
import { AdminOperatorRole } from './AdminOperatorRole'

export const AccessToEngageRole: PermissionSetType[] = [
  ...AdminOperatorRole,

  // TODO: Replace rider permission set with more limited permission sets
  RiderPermissionSet.Rider,

  FormPermissionSet.Write,
  FormPermissionSet.WriteContents,
  FormPermissionSet.View,
  FormPermissionSet.ViewContents,

  LetterPermissionSet.Write,
  LetterPermissionSet.View,

  CaseTypePermissionSet.Write,
  CaseTypePermissionSet.View,

  CasePermissionSet.View,
  CasePermissionSet.ViewForms,
  CasePermissionSet.ViewLetters,
  CasePermissionSet.Write,
  CasePermissionSet.WriteForms,
  CasePermissionSet.WriteLetters,
  CasePermissionSet.WriteStatus,

  EngageReportingPermissionSet.View,
  EngageReportingPermissionSet.Write,
  EngageReportingPermissionSet.Delete,
  EngageReportingPermissionSet.Export,

  GroupPermissionSet.View,
  GroupPermissionSet.ViewMembership,
  GroupPermissionSet.WriteMembership,
  GroupPermissionSet.ViewCondition,
  GroupPermissionSet.ImportMembership,
  GroupPermissionSet.ExportMembership,

  EventPermissionSet.View,

  /* Rider user management */
  RiderUserPermissionSet.View,
  RiderUserPermissionSet.New,
  RiderUserPermissionSet.Edit,
  RiderUserPermissionSet.Delete,
  RiderUserPermissionSet.SetPhonePin,

  NylasAdminGrantPermissionSet.Auth,
  NylasAdminGrantPermissionSet.View,
  NylasAdminGrantPermissionSet.ViewOwn,
  NylasAdminGrantPermissionSet.WriteOwn,
  NylasAdminGrantPermissionSet.View,
]
