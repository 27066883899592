import { WebColors } from '@sparelabs/colors'
import {
  INPUT_BREAKPOINT_WIDTH_PX,
  INPUT_MAX_WIDTH_PX,
  LABEL_COLUMN_WIDTH_PX,
  LABEL_MARGIN_RIGHT_PX,
} from '@sparelabs/web-ui-components'
import styled, { css } from 'styled-components'

export const FORM_PADDING_PX = 24
export const FORM_WIDTH_PX = LABEL_COLUMN_WIDTH_PX + LABEL_MARGIN_RIGHT_PX + INPUT_MAX_WIDTH_PX + FORM_PADDING_PX * 2

// "-1" because you need to be 1 pixel under the total width to break
export const WRAPPING_BREAKPOINT_WIDTH_PX =
  LABEL_COLUMN_WIDTH_PX + LABEL_MARGIN_RIGHT_PX + INPUT_BREAKPOINT_WIDTH_PX + FORM_PADDING_PX * 2 - 1

export const FullWidthContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const containerStyle = css`
  padding: 16px ${FORM_PADDING_PX}px;
  max-width: ${FORM_WIDTH_PX}px;
  width: 100%;
`

export const Container = styled.div`
  ${containerStyle}
`
export const FormContainer = styled.form`
  ${containerStyle}
`

export const InfoPrompt = styled.span`
  font-size: 15px;

  @media (max-width: ${WRAPPING_BREAKPOINT_WIDTH_PX}px) {
    display: block;
  }
`

export const InfoParagraph = styled.p`
  font-size: 15px;
  line-height: 20px;
  word-wrap: break-word;
`

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  gap: 8px;
  margin-top: 48px;

  @media (max-width: ${WRAPPING_BREAKPOINT_WIDTH_PX}px) {
    flex-direction: column-reverse;
    align-items: unset;
  }
`

export const BackgroundContainer = styled(FullWidthContainer)`
  background-color: ${WebColors.backgroundSecondary};
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const BannerContainer = styled.div`
  margin-bottom: 16px;
`
