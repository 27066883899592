import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum OrganizationPermissionSet {
  ViewOwn = 'organization_view_own',
  EditOwn = 'organization_edit_own',
  ViewConfiguration = 'organization_view_configuration',
  ViewCustomSchemas = 'organization_view_custom_schemas',
  WriteCustomSchemas = 'organization_write_custom_schemas',
  ViewBillingAccountStatus = 'organization_view_billing_account_status',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const organizationPermissionSetGrouping: IPermissionSetGrouping<OrganizationPermissionSet> = {
  key: PermissionSetGroupingKey.Organization,
  active: {
    [OrganizationPermissionSet.ViewOwn]: [PermissionType.GET_OWN_ORGANIZATION, PermissionType.GET_OWN_NYLAS_ORGANIZATION_GRANT],
    [OrganizationPermissionSet.EditOwn]: [
      PermissionType.PATCH_OWN_ORGANIZATION,
      PermissionType.PATCH_OWN_ORGANIZATION_CONFIGURATION,
      PermissionType.PATCH_OWN_ORGANIZATION_NOTIFICATION_PROVIDERS,
      PermissionType.POST_UPLOAD_PHOTO /* Used for uploading a profile photos to users accounts */,
      PermissionType.POST_ANY_FILE /* Used for uploading files to users profiles */,
    ],
    [OrganizationPermissionSet.ViewConfiguration]: [
      PermissionType.GET_OWN_ORGANIZATION_CONFIGURATION
    ],
    [OrganizationPermissionSet.ViewCustomSchemas]: [
      PermissionType.GET_NATIVE_OBJECT_EXTENSIONS
    ],
    [OrganizationPermissionSet.WriteCustomSchemas]: [
      PermissionType.WRITE_ANY_CUSTOM_FIELD,
    ],
    [OrganizationPermissionSet.ViewBillingAccountStatus]: [PermissionType.GET_OWN_BILLING_ACCOUNT_ALERT_STATUS]
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
