import { PermissionType } from '../PermissionType'
import { SuperAdminOrganizationPermissions, SuperAdminPermissions } from '../sets/SuperAdminPermissions'
import { DefaultSuperAdminRole } from './DefaultSuperAdminRole'

export const RootSuperAdminRole: PermissionType[] = [
  ...DefaultSuperAdminRole,
  ...SuperAdminOrganizationPermissions.manageAny,
  ...SuperAdminPermissions.manage,

  PermissionType.DELETE_ORGANIZATION_RIDERS,
  PermissionType.PATCH_ANY_ORGANIZATION_NOTIFICATION_PROVIDERS,
  PermissionType.POST_SPARE_MOBILE_APP_ORGANIZATION,
]
