import { InternalServerError } from '@sparelabs/error-types'
import { Logger } from '@sparelabs/logging'
import { MINUTE } from '@sparelabs/time'
import { isNumber } from 'lodash'
import { BoardingTimeCalculator } from '../boardingTime'
import { FlagDownConstants } from '../flagDown'
import {
  IAbsoluteTimeConstraint,
  IRelativeTimeConstraint,
  ITimeConstraint,
  OffsetDirection,
  TimeConstraintType,
} from '../scheduling'
import {
  ArriveByPickupConstraintType,
  IRequestAccessibilityFeature,
  IServiceAccessibilityFeature,
  MatchingEndpoint,
  RequestIntentType,
  ScheduledRequestFlexWindowType,
  SlotStatus,
} from '../types'
import { RequestSemantics } from './RequestSemantics'

/**
 * fixed constraints can perform poorly under changing traffic or other weird conditions
 * always add a buffer of 1 minute to ensure these issues don't happen
 */
export const FIXED_TIME_CONSTRAINT_BUFFER = 1 * MINUTE

export interface IPreMatchConstraintInput {
  /**
   * Maximum walking a rider would need to do based on the potential pickup locations
   */
  maxPickupWalkingDuration: number
  /**
   * Maximum walking a rider would need to do based on the potential dropoff locations
   */
  maxDropoffWalkingDuration: number
  estimateCreatedTs: number
}

export interface IConstraintUnmatchedRequestInput {
  /**
   * We read travelDuration and travelDurationFlexibility from requests because:
   *  1) these values are sensitive to traffic calculations, which is sensitive to time
   *     of day, which can allow for non-trivial discrepancies between requests and estimates
   *  2) [SUBOPTIMAL] we currently modify travelDurationFlexibility on flagdown requests
   *     after they are matched, so the request would be the most up-to-date source of truth
   */
  travelDuration: number
  travelDurationFlexibility: number
  /**
   * Note: Currently this is being read from requests because we don't currently persist this field
   * on Estimates. However, there is no reason that this field could not be persisted on Estimates instead.
   */
  flagDownDutyId: string | null
  serviceId: string
  accessibilityFeatures: IRequestAccessibilityFeature[]
}

export interface IConstraintMatchedRequestInput extends IConstraintUnmatchedRequestInput {
  id: string
  acceptedFlexForward: number
  acceptedFlexBackward: number
  recurrenceId: string | null
  arriveByPickupConstraintType: ArriveByPickupConstraintType | null
  arriveByPickupConstraintFlex: number | null
  initialScheduledPickupTs?: number
  initialScheduledDropoffTs?: number
}

export interface IConstraintSlotInput {
  status: SlotStatus
  initialScheduledTs: number
  scheduledTs: number
  completedTs: number | null
}

export interface IConstraintUnmatchedEstimateInput {
  requestedPickupTs: number
  requestedDropoffTs: number
  /**
   * Note that an Estimate's search flex should already be the APPROPRIATE search flex. This means that the search
   * flexibilities are:
   *  - Based on the Service's arrive by or leave at flex, depending on the Estimate intent type
   *  - Based on the Service's search or accepted flex, depending on the Service's flex window type
   *
   * In practice, we calculate these flexibilities once when the Estimate is created, and save them on the Estimate,
   * where they are later used for things like these constraint calculations.
   */
  searchFlexForward: number
  searchFlexBackward: number
  intentType: RequestIntentType
  arriveByPickupConstraintType: ArriveByPickupConstraintType | null
  arriveByPickupConstraintFlex: number | null
}

export type IConstraintMatchedEstimateInput = Pick<
  IConstraintUnmatchedEstimateInput,
  'requestedPickupTs' | 'requestedDropoffTs' | 'intentType'
> & {
  endpoint: MatchingEndpoint
}

export interface IConstraintServiceInput {
  scheduledRequestFlexWindowType: ScheduledRequestFlexWindowType
  buildNextAvailableRequestsForNow: boolean
  id: string
  baseBoardingTime: number
  accessibilityFeatures: IServiceAccessibilityFeature[]
}

/**
 * Before pickup, LeaveAt requests have an absolute pickup constraint and a relative dropoff constraint
 */
export interface ILeaveAtConstraints {
  type: RequestIntentType.LeaveAt
  pickupConstraint: IAbsoluteTimeConstraint
  dropoffConstraint: IRelativeTimeConstraint
}

/**
 * Before pickup, ArriveBy requests have an absolute dropoff constraint and a relative or absolute pickup constraint.
 */
export interface IArriveByConstraints {
  type: RequestIntentType.ArriveBy
  pickupConstraint: ITimeConstraint
  dropoffConstraint: IAbsoluteTimeConstraint
}

export type IRequestConstraints = ILeaveAtConstraints | IArriveByConstraints

export class RequestConstraints {
  private static readonly logger = Logger.build(RequestConstraints.name)
  public static AUTO_WINDOW_ARRIVE_BY_BUFFER = 5 * MINUTE
  public static MANUAL_WINDOW_ARRIVE_BY_BUFFER = 30 * MINUTE

  public static calculateFlagdownPostMatchFlexibility(
    scheduledPickupTs: number,
    scheduledDropoffTs: number,
    minTravelDuration: number
  ): number {
    const actualTravelDuration = scheduledDropoffTs - scheduledPickupTs
    return actualTravelDuration - minTravelDuration + FlagDownConstants.FLAG_DOWN_POST_MATCH_TRAVEL_DURATION_FLEXIBILITY
  }

  // TODO excludeWalkingTimeInDesiredTs: once we verify the safety of the change in buildLeaveAtUnmatched
  // the feature flag should be removed and this extra variable removed
  public static buildForUnmatched(
    estimate: IConstraintUnmatchedEstimateInput,
    request: IConstraintUnmatchedRequestInput,
    service: IConstraintServiceInput,
    pickupConstraintInput: IPreMatchConstraintInput,
    excludeWalkingTimeInDesiredTs: boolean,
    scheduledRequestFlexWindowType: ScheduledRequestFlexWindowType
  ): IRequestConstraints {
    switch (estimate.intentType) {
      case RequestIntentType.LeaveAt:
        return this.buildLeaveAtUnmatched(
          estimate,
          request,
          pickupConstraintInput,
          excludeWalkingTimeInDesiredTs,
          scheduledRequestFlexWindowType
        )
      case RequestIntentType.ArriveBy:
        return this.buildArriveByUnmatched(
          estimate,
          request,
          service,
          pickupConstraintInput.maxDropoffWalkingDuration,
          scheduledRequestFlexWindowType
        )
    }
  }

  public static buildForMatched(
    estimate: IConstraintMatchedEstimateInput,
    request: IConstraintMatchedRequestInput,
    service: IConstraintServiceInput
  ): IRequestConstraints {
    switch (estimate.intentType) {
      case RequestIntentType.LeaveAt:
        return this.buildLeaveAtMatched(estimate, request, service)
      case RequestIntentType.ArriveBy:
        return this.buildArriveByMatched(estimate, request, service)
    }
  }

  private static buildLeaveAtUnmatched(
    estimate: IConstraintUnmatchedEstimateInput,
    request: IConstraintUnmatchedRequestInput,
    pickupConstraintInput: Pick<IPreMatchConstraintInput, 'maxPickupWalkingDuration' | 'estimateCreatedTs'>,
    excludeWalkingTimeInDesiredTs: boolean,
    scheduledRequestFlexWindowType: ScheduledRequestFlexWindowType
  ): ILeaveAtConstraints {
    const { estimateCreatedTs, maxPickupWalkingDuration } = pickupConstraintInput
    /**
     * We make two adjustments to the desired pickup time:
     *  1. Offset by the time it takes to walk from the rider's origin to their pickup location. This is
     *     done because we assume that the LeaveAt time refers to the time they want to leave from their
     *     point of origin, rather than the time they want to be boarding the vehicle.
     *  2. If it is in the past, assume that the rider wants a trip as soon as possible. Such a discrepancy
     *     may arise due to rematching (e.g. on duty cancellation), or simply due to delay between the
     *     time the estimate was created, and the first time the request gets matched. We make this change for variable window
     *     services only, since most users of consistent windows care to preserve the OTP window and can use overrides to make these trips fit.
     */
    const timeToBumpTo =
      scheduledRequestFlexWindowType === ScheduledRequestFlexWindowType.ManualWithNegotiation ? 0 : estimateCreatedTs
    const desiredPickupTs = excludeWalkingTimeInDesiredTs
      ? Math.max(timeToBumpTo + maxPickupWalkingDuration, estimate.requestedPickupTs)
      : Math.max(timeToBumpTo, estimate.requestedPickupTs) + maxPickupWalkingDuration

    /**
     * Flagdown requests get an extra high detour flexibility when they are initially matched,
     * just to ensure a high match rate for flagdowns.
     */
    const detourFlexibility = RequestSemantics.isFlagDownRequest(request)
      ? FlagDownConstants.FLAG_DOWN_PRE_MATCH_TRAVEL_DURATION_FLEXIBILITY
      : request.travelDurationFlexibility

    return {
      type: RequestIntentType.LeaveAt,
      pickupConstraint: {
        type: TimeConstraintType.Absolute,
        min: desiredPickupTs - estimate.searchFlexBackward,
        desired: desiredPickupTs,
        max: desiredPickupTs + estimate.searchFlexForward,
      },
      dropoffConstraint: {
        type: TimeConstraintType.Relative,
        maxOffset: request.travelDuration + detourFlexibility,
        direction: OffsetDirection.After,
      },
    }
  }

  // For details on Arrive by constraint types, see: https://www.notion.so/sparelabs/Absolute-Pickup-Window-For-Arrive-By-Trips-f0b16eaeedd04961a647a209f7b6d76e
  private static buildArriveByUnmatched(
    estimate: IConstraintUnmatchedEstimateInput,
    request: IConstraintUnmatchedRequestInput,
    service: IConstraintServiceInput,
    maxDropoffWalkingDuration: number,
    scheduledRequestFlexWindowType: ScheduledRequestFlexWindowType
  ): IArriveByConstraints {
    const isManualWindow = scheduledRequestFlexWindowType === ScheduledRequestFlexWindowType.ManualWithNegotiation
    const desiredBuffer = Math.min(
      isManualWindow ? this.MANUAL_WINDOW_ARRIVE_BY_BUFFER : this.AUTO_WINDOW_ARRIVE_BY_BUFFER,
      estimate.searchFlexBackward
    )
    /**
     * Offset the entire absolute dropoff range by the walking duration from their dropoff location to their final destination.
     * This assumes that estimate.requestedDropoffTs corresponds to when the rider wants to arrive at their final destination.
     */
    const anchorDropoffTs = estimate.requestedDropoffTs - maxDropoffWalkingDuration
    const dropoffConstraint: IAbsoluteTimeConstraint = {
      type: TimeConstraintType.Absolute,
      min: anchorDropoffTs - estimate.searchFlexBackward,
      desired: anchorDropoffTs - desiredBuffer,
      max: anchorDropoffTs + estimate.searchFlexForward,
    }

    switch (estimate.arriveByPickupConstraintType ?? ArriveByPickupConstraintType.Detour) {
      case ArriveByPickupConstraintType.Window: {
        const boardingTime = BoardingTimeCalculator.evaluateRequestBoardingTime(service, request, this.logger)
        const anchorPickupTs = anchorDropoffTs - request.travelDuration - 2 * boardingTime
        const backwardsFlex = Math.min(estimate.arriveByPickupConstraintFlex ?? Infinity, estimate.searchFlexBackward)
        const min = anchorPickupTs - backwardsFlex
        return {
          type: RequestIntentType.ArriveBy,
          // Set pickup desired to the start of the window to promote pooling
          pickupConstraint: {
            type: TimeConstraintType.Absolute,
            min,
            desired: min,
            max: anchorPickupTs,
          },
          // Set dropoff desired to the middle of the window to promote pooling
          dropoffConstraint: {
            ...dropoffConstraint,
            desired: (dropoffConstraint.min + dropoffConstraint.max) / 2,
          },
        }
      }
      case ArriveByPickupConstraintType.Detour: {
        return {
          type: RequestIntentType.ArriveBy,
          pickupConstraint: {
            type: TimeConstraintType.Relative,
            direction: OffsetDirection.Before,
            maxOffset: request.travelDuration + request.travelDurationFlexibility,
          },
          dropoffConstraint,
        }
      }
    }
  }

  private static buildLeaveAtMatched(
    estimate: Pick<IConstraintMatchedEstimateInput, 'endpoint' | 'requestedPickupTs'>,
    request: IConstraintMatchedRequestInput,
    service: IConstraintServiceInput
  ): ILeaveAtConstraints {
    const isNextAvailableForNow =
      service.buildNextAvailableRequestsForNow && estimate.endpoint === MatchingEndpoint.NextAvailable
    const isManualWindow =
      estimate.endpoint === MatchingEndpoint.Scheduled &&
      service.scheduledRequestFlexWindowType === ScheduledRequestFlexWindowType.ManualWithNegotiation
    const isRecurring = RequestSemantics.isRecurringRequest(request)

    const desiredPickupTs =
      isNextAvailableForNow || isManualWindow || isRecurring
        ? estimate.requestedPickupTs
        : this.assertInitialScheduledTs(request.initialScheduledPickupTs, request.id)
    const maxPickupTs =
      isManualWindow || isRecurring
        ? estimate.requestedPickupTs + request.acceptedFlexForward
        : this.assertInitialScheduledTs(request.initialScheduledPickupTs, request.id) + request.acceptedFlexForward

    const pickupConstraint: IAbsoluteTimeConstraint = {
      type: TimeConstraintType.Absolute,
      min: desiredPickupTs - request.acceptedFlexBackward,
      desired: desiredPickupTs,
      max: maxPickupTs,
    }

    return {
      type: RequestIntentType.LeaveAt,
      pickupConstraint,
      dropoffConstraint: {
        type: TimeConstraintType.Relative,
        direction: OffsetDirection.After,
        maxOffset: request.travelDuration + request.travelDurationFlexibility,
      },
    }
  }

  // For details on Arrive by constraint types, see: https://www.notion.so/sparelabs/Absolute-Pickup-Window-For-Arrive-By-Trips-f0b16eaeedd04961a647a209f7b6d76e
  private static buildArriveByMatched(
    estimate: Pick<IConstraintMatchedEstimateInput, 'requestedDropoffTs'>,
    request: IConstraintMatchedRequestInput,
    service: Pick<
      IConstraintServiceInput,
      'scheduledRequestFlexWindowType' | 'id' | 'baseBoardingTime' | 'accessibilityFeatures'
    >
  ): IArriveByConstraints {
    const isManualWindow =
      service.scheduledRequestFlexWindowType === ScheduledRequestFlexWindowType.ManualWithNegotiation
    const isRecurring = RequestSemantics.isRecurringRequest(request)
    const desiredBuffer = Math.min(
      isManualWindow ? this.MANUAL_WINDOW_ARRIVE_BY_BUFFER : this.AUTO_WINDOW_ARRIVE_BY_BUFFER,
      request.acceptedFlexBackward
    )

    const anchorDropoffTs =
      isManualWindow || isRecurring
        ? estimate.requestedDropoffTs
        : this.assertInitialScheduledTs(request.initialScheduledDropoffTs, request.id)
    const desiredDropoffTs = isManualWindow || isRecurring ? anchorDropoffTs - desiredBuffer : anchorDropoffTs

    const dropoffConstraint: IAbsoluteTimeConstraint = {
      type: TimeConstraintType.Absolute,
      min: anchorDropoffTs - request.acceptedFlexBackward,
      desired: desiredDropoffTs,
      max: anchorDropoffTs + request.acceptedFlexForward,
    }

    switch (request.arriveByPickupConstraintType ?? ArriveByPickupConstraintType.Detour) {
      case ArriveByPickupConstraintType.Window: {
        const boardingTime = BoardingTimeCalculator.evaluateRequestBoardingTime(service, request, this.logger)
        const anchorPickupTs = anchorDropoffTs - request.travelDuration - 2 * boardingTime
        const backwardsFlex = Math.min(request.arriveByPickupConstraintFlex ?? Infinity, request.acceptedFlexBackward)
        const min = anchorPickupTs - backwardsFlex
        return {
          type: RequestIntentType.ArriveBy,
          // Set pickup desired to the start of the window to promote pooling
          pickupConstraint: {
            type: TimeConstraintType.Absolute,
            min,
            desired: min,
            max: anchorPickupTs,
          },
          // Set dropoff desired to the middle of the window to promote pooling
          dropoffConstraint: {
            ...dropoffConstraint,
            desired: (dropoffConstraint.min + dropoffConstraint.max) / 2,
          },
        }
      }
      case ArriveByPickupConstraintType.Detour:
        return {
          type: RequestIntentType.ArriveBy,
          pickupConstraint: {
            type: TimeConstraintType.Relative,
            direction: OffsetDirection.Before,
            maxOffset: request.travelDuration + request.travelDurationFlexibility,
          },
          dropoffConstraint,
        }
    }
  }

  private static assertInitialScheduledTs(desiredTs: number | null | undefined, requestId: string): number {
    if (!isNumber(desiredTs)) {
      throw new InternalServerError('Building matched constraints without initial scheduled timestamp', { requestId })
    }
    return desiredTs
  }
}
