import { LoaderSpinner } from '@sparelabs/web-ui-components'
import React from 'react'
import styled from 'styled-components'
import { Container } from './Layout'

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px;
`

export const LoadingView = (): JSX.Element => (
  <Container>
    <SpinnerWrapper>
      <LoaderSpinner />
    </SpinnerWrapper>
  </Container>
)
