import { z } from '@sparelabs/zod'

export const IFileResponseSchema = z.object({
  /**
   * ID of the file. This is used to compute a temporary url
   */
  id: z.string(),
  name: z.string(),
  size: z.number(),
  format: z.string(),
})

export type IFileResponse = z.infer<typeof IFileResponseSchema>

export interface IFileTempUrlResponse {
  tempUrl: string
}

export interface IPublicFileResponse {
  /**
   * publicly hosted URL of the file
   */
  url: string
  name: string
  size: number
  format: string
}

export const ACCEPTED_FILE_UPLOAD_MIME_TYPES = [
  'application/pdf',
  'text/csv',
  'image/jpeg',
  'image/png',
  // cspell: disable
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // cspell: enable
]
export const ACCEPTED_FILE_UPLOAD_MIME_TYPES_FOR_RIDERS_DRIVERS = ['application/pdf', 'image/jpeg', 'image/png']

export const ACCEPTED_FILE_EXTENSION_TYPES = ['.pdf', '.csv', '.jpg', '.jpeg', '.png', '.xls', '.xlsx']

export const ACCEPTED_FILE_EXTENSION_TYPES_FOR_RIDERS_DRIVERS = ['.pdf', '.jpg', '.jpeg', '.png']

export const MEGABYTE = 1000000
