import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IGetReverseGeocodeQueryParams, IReverseGeocodeResponse, Resources } from '../types'

export class ReverseGeocodeResource extends GenericResource<
  IReverseGeocodeResponse,
  IGetReverseGeocodeQueryParams,
  never,
  never
> {
  constructor(agent: AxiosInstance) {
    super(Resources.ReverseGeocode, agent)
  }

  public async get(params: IGetReverseGeocodeQueryParams): Promise<IReverseGeocodeResponse> {
    const res = await this.agent.get<IReverseGeocodeResponse>(this.resource, { params })
    return res.data
  }
}
