import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trigger } from '@radix-ui/react-popover'
import { WebColors } from '@sparelabs/colors'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { ButtonIcon } from '../icons'
import { borderRadius, boxShadow, boxShadowStrong, ButtonFocusTheme } from '../util'

interface IPopoverTrigger extends React.RefAttributes<HTMLButtonElement> {
  isOpen?: boolean
  disabled?: boolean
  width?: string
  customIcon?: JSX.Element
  icon?: IconProp
  toggleTitle?: ReactNode
  children?: React.ReactNode | React.ReactNode[]
  className?: string
  hideToggle?: boolean
  ariaLabel?: string
}

const ContentContainer = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
`

const Icon = styled(FontAwesomeIcon)`
  align-self: center;
`

// Using ReactStrap buttons for the toggle breaks Radix-Popover functionality
export const ToggleButton = styled(Trigger)`
  border: 0px;
  background-color: ${WebColors.backgroundPrimary};
  cursor: pointer;
  color: ${WebColors.contentPrimary};
  font-weight: 300;
  font-size: 0.94rem;
  padding: 7px 12px;
  line-height: 22.5px;
  min-height: 36.5px;
  display: flex;
  width: auto;
  align-items: center;
  ${ButtonFocusTheme}
  border-radius: ${borderRadius};
  box-shadow: ${boxShadow};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: box-shadow 150ms ease;
  &:hover,
  &:active,
  &:disabled {
    box-shadow: ${boxShadowStrong};
  }
  &:focus {
    outline: none;
  }
`

const ButtonText = styled.span<IPopoverTrigger>`
  margin-right: ${({ hideToggle }) => (hideToggle ? '0px' : '16px')};
`

const StyledTrigger = styled(Trigger)`
  align-items: center;
  background: none;
  padding: 0;
  border: none;
  ${ButtonFocusTheme}
  box-shadow: ${({ isOpen }: { isOpen?: boolean }) =>
    isOpen ? '0 0 0 2px rgb(204 204 204 / 50%)' : '0 0 0 0 transparent'};
  &:focus {
    outline: none;
  }
`
const TriggerContainer = styled.div`
  display: flex;
`

export const PopoverTrigger = ({
  isOpen,
  disabled,
  width,
  customIcon,
  icon,
  toggleTitle,
  children,
  className,
  hideToggle,
  ...rest
}: IPopoverTrigger): JSX.Element => (
  <TriggerContainer>
    {children ? (
      <StyledTrigger className={className} disabled={disabled} {...rest}>
        {children}
      </StyledTrigger>
    ) : (
      <ToggleButton className={className} disabled={disabled} {...rest}>
        <ContentContainer>
          {customIcon ? customIcon : icon && <ButtonIcon icon={icon} marginRight={toggleTitle ? '8px' : '0px'} />}
          {toggleTitle && <ButtonText hideToggle={hideToggle}>{toggleTitle}</ButtonText>}
        </ContentContainer>
        {toggleTitle && !hideToggle && <Icon icon={isOpen ? 'caret-up' : 'caret-down'} />}
      </ToggleButton>
    )}
  </TriggerContainer>
)
