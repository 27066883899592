import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { Resources } from '../types'
import { IMatchingSimulationPostBody, IMatchingSimulationResponse } from '../types/MatchingSimulationTypes'

export class MatchingSimulationResource extends GenericResource<
  IMatchingSimulationResponse,
  never,
  null,
  IMatchingSimulationPostBody
> {
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.MatchingSimulations, agent)
  }
}
