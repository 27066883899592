import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum SimulationPermissionSet {
  View = 'simulation_view',
  DeleteResults = 'simulation_delete_results',
  New = 'simulation_new',
}

export const simulationPermissionSetGrouping: IPermissionSetGrouping<SimulationPermissionSet> = {
  key: PermissionSetGroupingKey.Simulation,
  active: {
    [SimulationPermissionSet.View]: [PermissionType.GET_ANY_SIMULATION_RESULT],
    [SimulationPermissionSet.DeleteResults]: [PermissionType.DELETE_ANY_SIMULATION_RESULT],
    [SimulationPermissionSet.New]: [
      PermissionType.PREVIEW_ANY_SIMULATION,
      PermissionType.POST_ANY_SIMULATION,
      PermissionType.WRITE_ANY_SIMULATION_RESULT,
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
