import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum FavoriteLocationPermissionSet {
  View = 'favorite_location_view',
  Edit = 'favorite_location_edit',
  Delete = 'favorite_location_delete',
  Import = 'favorite_location_import',
}

export const favoriteLocationPermissionSetGrouping: IPermissionSetGrouping<FavoriteLocationPermissionSet> = {
  key: PermissionSetGroupingKey.FavoriteLocation,
  active: {
    [FavoriteLocationPermissionSet.View]: [PermissionType.GET_ANY_FAVORITE_LOCATION],
    [FavoriteLocationPermissionSet.Edit]: [PermissionType.WRITE_ANY_FAVORITE_LOCATION],
    [FavoriteLocationPermissionSet.Delete]: [PermissionType.DELETE_ANY_FAVORITE_LOCATION],
    [FavoriteLocationPermissionSet.Import]: [PermissionType.IMPORT_ANY_FAVORITE_LOCATION],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
