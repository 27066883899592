import { FeatureFlag } from '@sparelabs/feature-flags'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { PermissionType } from '@sparelabs/permissions'
import { Currency } from './CurrencyTypes'
import { INotificationProvider } from './NotificationTypes'
import { ITimeRange, Language, UnitsOfDistance } from './SharedTypes'

export enum ProductType {
  Launch = 'launch',
  Analyze = 'analyze',
  Realize = 'realize',
  Admin = 'admin',
  Engage = 'engage',
}

export enum OrganizationDriverLoginType {
  Email = 'email',
  Username = 'username',
  EmailAndUsername = 'emailAndUsername',
}

export interface ILaunchBrand {
  name: string
  photoUrl: string
  aboutUrl: string | null
}

/* ------------ USER AUTHENTICATION INTERFACES ---------------*/
export enum AuthProviderType {
  Phone = 'phone',
  Email = 'email',
}

export interface IAuthProvider {
  type: AuthProviderType
  metadata: unknown
}
/* ------------ USER AUTHENTICATION INTERFACES ---------------*/

export interface ITokenOrganizationIdPair {
  token: string
  organizationId: string
}

export type ITokenOrganizationIdPairs = ITokenOrganizationIdPair[]

export interface ILaunchService {
  enabled: boolean
  brand: ILaunchBrand
}

export interface ILaunchSettings {
  ondemand: ILaunchService
}

export interface ICallCenterConfig {
  ivrWelcomeMessage?: string
}

export interface IOrganizationPostBody {
  defaultLocale: string
  faqUrl: string | null
  termsUrl: string | null
  privacyPolicyUrl: string | null
  featureFlags: FeatureFlag[]
  isTestOnly: boolean
  isVisibleToRiderApp: boolean
  timezone: string
  metadata: Record<string, unknown>
  name: string
  logoUrl: string | null
  unitsOfDistance: UnitsOfDistance
  currency: Currency
  isExternalNumericRiderIdEnabled: boolean
  drivingSpeedMultiplier?: number
  blackoutWindow: ITimeRange | null
  defaultLanguage: Language
  additionalLanguages: Language[]
  organizationKey: string | null
  openTripPlannerHostname: string | null
  driversLoginField: OrganizationDriverLoginType | null
}

export interface IOrganizationPatchBody extends Partial<IOrganizationPostBody> {
  callCenterConfig?: ICallCenterConfig
  otpBasis?: OTPBasis
  notificationProviders?: INotificationProvider[] // This allows setting the fields 'twilioConfig' and 'messageBirdConfig' on Organization
}

export interface IOrganizationResponse extends IOrganizationPostBody, IModelJsonValues {
  /**
   *  @deprecated
   */
  paymentProviders: unknown[]
  /**
   *  @deprecated
   */
  payoutProviders: unknown[]
  /**
   *  @deprecated TODO: Remove this. Currently blocked by carpooling
   */
  products: ILaunchSettings
  /**
   *  @deprecated
   */
  launchSettings: ILaunchSettings
  isMaintenanceEnabled: boolean
  otpBasis: OTPBasis
  callCenterConfig: ICallCenterConfig
}

export enum OTPBasis {
  Pickup = 'pickup',
  Relevant = 'relevant',
}

export interface IOrganizationConfigurationPostBody {
  enableTripAnonymization: boolean
  enableUserAnonymization: boolean
  enableEventAuthorAnonymization: boolean
  enabledProducts: ProductType[]
  useOrganizationTerms: boolean
  isDemoEnabled: boolean
  weeklyReportsEnabled: boolean
  dailyReportsEnabled: boolean
  isMaintenanceEnabled: boolean
  silenceAlerts: boolean
  blackoutWindow: ITimeRange | null
  defaultLanguage: Language
  otpBasis: OTPBasis
  additionalLanguages: Language[]
  callCenterConfig: ICallCenterConfig
}

export interface IOrganizationConfigurationPatchBody extends Partial<IOrganizationConfigurationPostBody> {}

export interface IOrganizationConfigurationResponse extends IOrganizationConfigurationPostBody {
  superAdmin: boolean
  userAuthenticationProviders: IAuthProvider[]
}

export interface IOrganizationListFilterParams {
  isTestOnly?: boolean
  isVisibleToRiderApp?: boolean
  isMember?: boolean
  isDriver?: boolean
  isAdmin?: boolean
  mobileAppId?: string
}

export interface IOrganizationListQueryParams extends IListModelQueryParamValues, IOrganizationListFilterParams {
  /**
   *  @deprecated
   */
  requiredPermission?: PermissionType.CAN_USE_ADMIN_PORTAL | PermissionType.CAN_USE_DRIVER_APP
}

export interface INearbyOrganizationListQueryParams
  extends Pick<IListModelQueryParamValues, 'limit' | 'skip'>,
    IOrganizationListFilterParams {
  longitude: number
  latitude: number
}

export interface INearbyOrganizationResponse {
  organization: IOrganizationResponse
  distance: number
}

export interface IGetPublicOrganizationQueryParams {
  organizationId: string
}

export interface IPublicOrganizationResponse {
  id: string
  name: string
  logoUrl: string | null
  isMaintenanceEnabled: boolean
  organizationKey: string | null
}
