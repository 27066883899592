export enum TimeConstraintType {
  Absolute = 'absolute',
  Relative = 'relative',
}

export enum OffsetDirection {
  Before = 'before',
  After = 'after',
}

/**
 * Represents a fixed window within which a time may be scheduled.
 */
export interface IAbsoluteTimeConstraint {
  type: TimeConstraintType.Absolute

  /**
   * Earliest allowed time, inclusive
   */
  min: number

  /**
   * Time that we should aim for when scheduling the variable bound by this constraint.
   * Need not necessarily fall between min and max!
   * Though in practice, desired < min behaves the same way as desired == min
   * (and same for max)
   */
  desired: number

  /**
   * Latest allowed time, inclusive
   */
  max: number
}

/**
 * Represents a time constraint of the following form:
 *  abs(t_a - t_b) <= maxOffset
 *
 * One of t_a or t_b must be associated with an absolute constraint; therefore,
 * we consider the other to be a constraint that is "relative" to that "base" absolute constraint.
 *
 * The implicit desired time for the variable bound by this constraint is as close
 * as possible to whatever time we choose for the base variable (the one that has the absolute constraint).
 * Therefore it does not make sense for this constraint to have a "min" value -- the ideal minimum offset
 * will always be 0.
 */
export interface IRelativeTimeConstraint {
  type: TimeConstraintType.Relative

  /**
   * The max difference between the time assigned to this relative constraint, and that assigned to
   * its "base" absolute constraint.
   */
  maxOffset: number

  /**
   * Before means that the constraint has a minimum value of base - maxOffset
   * After means it has a max value of base + maxOffset
   */
  direction: OffsetDirection
}

export type ITimeConstraint = IAbsoluteTimeConstraint | IRelativeTimeConstraint

export type Window = Omit<IAbsoluteTimeConstraint, 'type'>
