export interface IDataMigrationPostOrganizationBody {
  sourceOrganizationId: string // The source organization ID to copy from
  destinationOrganizationId: string // The destination organization ID to copy to
}
export interface IDataMigrationPostBody extends IDataMigrationPostOrganizationBody {
  paymentMethodTypeIds?: string[]
  farePassIds?: string[]
  vehicleIds?: string[]
  vehicleTypeIds?: string[]
  driverUserIds?: string[]
  notificationSettingIds?: string[]
  announcementIds?: string[]
  serviceIds?: string[]
}

export interface IEngageDataMigrationPostBody extends IDataMigrationPostOrganizationBody {
  caseTypeIds?: string[]
}

export enum DataMigrationModel {
  Vehicle = 'vehicles',
  VehicleType = 'vehicleTypes',
  Driver = 'driverUsers',
  FarePass = 'farePasses',
  PaymentMethodType = 'paymentMethodTypes',
  NotificationSetting = 'notificationSettings',
  Announcement = 'announcements',
  Service = 'services',
  CaseType = 'caseTypes',
}

export interface IDataMigrationError {
  name?: string
  message: string
}
export interface IDataMigrationResultResponse {
  modelName: DataMigrationModel
  successCount: number
  existingCount: number
  failedCount: number
  errors?: IDataMigrationError[]
}

interface IModelItem {
  id: string
  name: string
}
export interface IDataMigrationModelsResponse {
  paymentMethodTypes: IModelItem[]
  farePasses: IModelItem[]
  vehicles: IModelItem[]
  vehicleTypes: IModelItem[]
  driverUsers: IModelItem[]
  notificationSettings: IModelItem[]
  announcements: IModelItem[]
  services: IModelItem[]
}

export interface IEngageDataMigrationModelsResponse {
  caseTypes: IModelItem[]
}
