import { GenericResource, IListResponse } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IRiderAddressSuggestion,
  IRiderListQueryParams,
  IRiderPatchBody,
  IRiderPhonePinResponse,
  IRiderPostBody,
  IRiderResponse,
  IRiderSummaryQueryParams,
  IRiderSummaryResponse,
  Resources,
} from '../types'

export class RiderResource extends GenericResource<
  IRiderResponse,
  IRiderListQueryParams,
  IRiderPatchBody,
  IRiderPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public del = this.buildDel()
  public post = this.buildPost()
  constructor(agent: AxiosInstance) {
    super(Resources.Riders, agent)
  }

  public async getAddressSuggestions(riderId: string): Promise<IListResponse<IRiderAddressSuggestion>> {
    const { data } = await this.agent.get<IListResponse<IRiderAddressSuggestion>>(
      `${this.resource}/${riderId}/addressSuggestions`
    )
    return data
  }

  public async getSummary(riderId: string, params?: IRiderSummaryQueryParams): Promise<IRiderSummaryResponse> {
    const { data } = await this.agent.get<IRiderSummaryResponse>(`${this.resource}/${riderId}/summary`, {
      params,
    })
    return data
  }

  public async getPhonePin(riderId: string): Promise<IRiderPhonePinResponse> {
    const { data } = await this.agent.get<IRiderPhonePinResponse>(`${this.resource}/${riderId}/phonePin`)
    return data
  }

  public async postPhonePin(riderId: string, phonePin: string): Promise<{}> {
    const res = await this.agent.post<{}>(`${this.resource}/${riderId}/phonePin`, { phonePin })
    return res.data
  }
}
