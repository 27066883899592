import { AdminUserPermissionSet, PermissionSetType } from '../../sets'
import { AdministratorRole } from '../AdministratorRole'

const excludePermissionSets: Set<PermissionSetType> = new Set([
  AdminUserPermissionSet.New,
  AdminUserPermissionSet.Delete,
  AdminUserPermissionSet.Import,
])

/**
 * CapMetroSuperUserRole is a role that has all the permissions of AdministratorRole except for the ability to create/delete platform users.
 */
export const CapMetroSuperUserRole: PermissionSetType[] = [...AdministratorRole].filter(
  (permissionSet: PermissionSetType) => !excludePermissionSets.has(permissionSet)
)
