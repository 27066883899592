// POST Authorize Types
export interface IOAuthAuthorizePostBody {
  response_type: OAuthAuthorizeResponseType
  client_id: string
  redirect_uri: string
  scope: string
  state?: string
  cancel?: boolean
}

export interface IOAuthRedirectResponse {
  code: string
  state?: string
}

export interface IOAuthRedirectErrorResponse {
  error: OAuthError
  error_description?: string
  error_uri?: string
  state?: string
}

export interface IOAuthAuthorizeResponse {
  callbackUri: string
}

export enum OAuthAuthorizeResponseType {
  Code = 'code',
}

export enum OAuthError {
  InvalidRequest = 'invalid_request',
  UnauthorizedClient = 'unauthorized_client',
  AccessDenied = 'access_denied',
  UnsupportedResponseType = 'unsupported_response_type',
  InvalidScope = 'invalid_scope',
  ServerError = 'server_error',
  TemporarilyUnavailable = 'temporarily_unavailable',
}

// POST Token Types
export type IOAuthTokenPostBody = IOAuthTokenPostBodyCode | IOAuthTokenPostBodyRefresh

export interface IOAuthTokenPostBodyCode {
  grant_type: OAuthGrantType.AuthorizationCode
  client_id: string
  client_secret?: string
  code: string
  redirect_uri: string
}

export interface IOAuthTokenPostBodyRefresh {
  grant_type: OAuthGrantType.RefreshToken
  client_id: string
  client_secret?: string
  refresh_token: string
}

export interface IOAuthTokenPostResponse {
  access_token: string
  refresh_token: string
  token_type: OAuthTokenType
  expires: number
}

export enum OAuthGrantType {
  AuthorizationCode = 'authorization_code',
  RefreshToken = 'refresh_token',
}

export enum OAuthTokenType {
  Bearer = 'bearer',
}

// POST Authenticate Types
export interface IOAuthAuthenticatePostBody {
  token: string
}
