import styled, { css } from 'styled-components'
import { borderRadius, boxShadow } from '../util'

export const cardStyle = css`
  box-shadow: ${boxShadow};
  border-radius: ${borderRadius};
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const Card = styled.div`
  ${cardStyle};
`

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
