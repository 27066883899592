import { HOUR, MINUTE, Time } from '@sparelabs/time'
import { RequestDispatchStatus, RequestStatus } from '../types'
import { RequestSemantics } from './RequestSemantics'

export class ManualIntervention {
  public static REQUEST_REVERSE_ALLOWED = 1 * HOUR
  /**
   * To prevent a race condition when a payment reversal happens at the same time
   * as the cancelled request with this payment is reverted from cancelled to be matched again
   */
  public static PAYMENT_CANCEL_DELAY = ManualIntervention.REQUEST_REVERSE_ALLOWED + 5 * MINUTE

  // set request to on hold for 5 minutes to avoid optimization modifying while manual action occurs
  public static ON_HOLD_AFTER_UNMATCHING_TIME = 5 * MINUTE

  /**
   * Duties that overlap over any portion of this time window will be considered
   * potential candidates for force matching, provided they meet other criteria as well.
   */
  public static getForceMatchCandidateDutyWindow(nowTs: number) {
    return {
      // Avoid force-matching to duties scheduled to end soon
      fromTs: nowTs + 5 * MINUTE,
      // Avoid duties that start too far in the future
      toTs: nowTs + HOUR,
    }
  }

  public static canRequestBeForceMatched(
    status: RequestStatus,
    dispatchStatus: RequestDispatchStatus | null,
    earliestPossiblePickupTs: number
  ): boolean {
    return (
      RequestSemantics.PROCESSING_REQUEST_STATUSES.includes(status) &&
      (dispatchStatus === RequestDispatchStatus.Unscheduled || dispatchStatus === RequestDispatchStatus.OnHold) &&
      Time.getNowTs() >= earliestPossiblePickupTs - HOUR
    )
  }
}
