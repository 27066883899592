import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum DataRetentionSchedulePermissionSet {
  View = 'data_retention_schedule_view',
  Write = 'data_retention_schedule_write',
}

export const dataRetentionSchedulePermissionSetGrouping: IPermissionSetGrouping<DataRetentionSchedulePermissionSet> = {
  key: PermissionSetGroupingKey.DataRetentionSchedule,
  active: {
    [DataRetentionSchedulePermissionSet.View]: [
      PermissionType.GET_ANY_DATA_RETENTION_SCHEDULE,
      PermissionType.LIST_ANY_DATA_RETENTION_SCHEDULE,
    ],
    [DataRetentionSchedulePermissionSet.Write]: [PermissionType.WRITE_ANY_DATA_RETENTION_SCHEDULE],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
