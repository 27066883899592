import { AxiosInstance } from 'axios'
import {
  IAuthEmailBody,
  IAuthenticationMetadataResponse,
  IAuthPhonePinBody,
  IAuthPhoneRequestBody,
  IAuthPhoneVerifyBody,
  IAuthTestCredentialsBody,
  IAuthTokenPostBody,
  IAuthUsernameBody,
  IResetPasswordConfirmBody,
  IResetPasswordRequestBody,
  IUserOnlyTokenResponse,
  IUserOrgTokenResponse,
  RE_CAPTCHA_TOKEN_HEADER,
} from '../types'

export class AuthResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async requestPasswordReset(body: IResetPasswordRequestBody): Promise<void> {
    await this.agent.post('auth/email/reset/request', body)
  }

  public async verifyPasswordReset(body: IResetPasswordConfirmBody): Promise<void> {
    await this.agent.post('/auth/email/reset/verify', body)
  }

  public async emailLogin(body: IAuthEmailBody): Promise<IUserOnlyTokenResponse> {
    const res = await this.agent.post<IUserOnlyTokenResponse>('auth2/email/login', body)
    return res.data
  }

  public async driverLogin(body: IAuthEmailBody | IAuthUsernameBody): Promise<IUserOnlyTokenResponse> {
    const res = await this.agent.post<IUserOnlyTokenResponse>('/auth/driver/login', body)
    return res.data
  }

  public async testCredentialsLogin(body: IAuthTestCredentialsBody): Promise<IUserOnlyTokenResponse> {
    const res = await this.agent.post<IUserOnlyTokenResponse>('auth/rider/test/login', body)
    return res.data
  }

  public async phoneLoginRequest(body: IAuthPhoneRequestBody, reCaptchaToken?: string): Promise<void> {
    await this.agent.post('/auth/rider/phone/request', body, {
      headers: reCaptchaToken ? { [RE_CAPTCHA_TOKEN_HEADER]: reCaptchaToken } : {},
    })
  }

  public async phoneLoginVerify(body: IAuthPhoneVerifyBody): Promise<IUserOnlyTokenResponse> {
    const res = await this.agent.post<IUserOnlyTokenResponse>('/auth/rider/phone/verify', body)
    return res.data
  }

  public async buildOrganizationToken(body: IAuthTokenPostBody): Promise<IUserOrgTokenResponse> {
    const res = await this.agent.post<IUserOrgTokenResponse>('auth/token', body)
    return res.data
  }

  public async getMetadata(): Promise<IAuthenticationMetadataResponse> {
    const { data } = await this.agent.get<IAuthenticationMetadataResponse>('auth/metadata')
    return data
  }

  public async buildOrganizationTokenFromPhonePin(body: IAuthPhonePinBody): Promise<IUserOrgTokenResponse> {
    const { data } = await this.agent.post<IUserOrgTokenResponse>('auth/rider/phonePin', body)
    return data
  }
}
