import { Currency } from '@sparelabs/api-client'
import { WebColors } from '@sparelabs/colors'
import { isNumber } from '@sparelabs/core'
import { CurrencyHelper } from '@sparelabs/currency'
import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import { INPUT_MAX_WIDTH } from '../../util'
import { IInputProps } from './InputTypes'
import { StyledInput } from './styled'

const InputContainer = styled.div`
  position: relative;
  max-width: ${INPUT_MAX_WIDTH};
`

const Preview = styled.div`
  color: ${({ valid }: { valid: boolean }) => (valid ? WebColors.interactivePrimary : WebColors.contentTertiary)};
  position: absolute;
  top: 50%;
  right: 2em;
  transform: translateY(-50%);
`

const getPreviewValue = (value: number | undefined, placeholder: number | undefined, currency: Currency): string => {
  if (isNumber(value)) {
    return CurrencyHelper.format(value, currency)
  } else if (isNumber(placeholder)) {
    return CurrencyHelper.format(placeholder, currency)
  }

  return '-'
}

interface IPriceInputProps extends Omit<IInputProps<number>, 'placeholder'> {
  currency: Currency
  onFocus?: () => void
  value?: number
  placeholder?: number
}

export const PriceInput = ({
  value,
  currency,
  invalid,
  onChange,
  placeholder,
  onFocus,
  id,
}: IPriceInputProps): JSX.Element => (
  <InputContainer>
    <StyledInput
      role='textbox'
      type='number'
      value={isNumber(value) ? value : ''}
      placeholder={placeholder?.toString()}
      step={1}
      invalid={invalid}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        const price = parseInt(event.target.value, 10)
        onChange(isNumber(price) ? price : undefined)
      }}
      onFocus={onFocus}
      id={id}
    />
    <Preview valid={isNumber(value)}>{getPreviewValue(value, placeholder, currency)}</Preview>
  </InputContainer>
)
