import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum PaymentMethodPermissionSet {
  View = 'payment_method_view',
  Delete = 'payment_method_delete',
  Export = 'payment_method_export',

  /**
   * For collecting payment method information directly from riders.
   */
  CollectDirectly = 'payment_method_collect_directly',

  /**
   * For triggering payment method collection process over the phone
   */
  InitiatePhoneCollection = 'payment_method_initiate_phone_collection',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const paymentMethodPermissionSetGrouping: IPermissionSetGrouping<PaymentMethodPermissionSet> = {
  key: PermissionSetGroupingKey.PaymentMethod,
  active: {
    [PaymentMethodPermissionSet.View]: [
      PermissionType.GET_ANY_PAYMENT_METHOD,
      PermissionType.LIST_ANY_WALLET_TRANSACTION,
    ],
    [PaymentMethodPermissionSet.Delete]: [PermissionType.DELETE_ANY_PAYMENT_METHOD],
    [PaymentMethodPermissionSet.CollectDirectly]: [
      PermissionType.POST_ANY_GOPASS_PAYMENT_METHOD,
      PermissionType.POST_ANY_STRIPE_SESSION,
      PermissionType.POST_ANY_STRIPE_SETUP_INTENT,
      PermissionType.POST_ANY_WALLET,
      PermissionType.WRITE_ANY_WALLET_AUTO_TOP_UP,
      PermissionType.DELETE_ANY_WALLET_AUTO_TOP_UP,
      PermissionType.IMPORT_ANY_WALLET,
    ],
    [PaymentMethodPermissionSet.InitiatePhoneCollection]: [
      PermissionType.GET_ANY_PAY_COLLECTION_CALL,
      PermissionType.POST_ANY_PAY_COLLECTION_CALL,
    ],
    [PaymentMethodPermissionSet.Export]: [
      PermissionType.EXPORT_ANY_PAYMENT_METHOD,
      PermissionType.EXPORT_ANY_WALLET_TRANSACTION,
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
