import { PaymentMethodTypePermissionSet, PermissionSetType, RiderPermissionSet } from '../sets'
import { AnnouncementPermissionSet } from '../sets/AnnouncementPermissions'
import { ChargePermissionSet } from '../sets/ChargePermissions'
import { ConnectedAccountPermissionSet } from '../sets/ConnectedAccountPermissions'
import { DevicePermissionSet } from '../sets/DevicePermission'
import { DriverUserPermissionSet } from '../sets/DriverUserPermissions'
import { DutyPermissionSet } from '../sets/DutyPermissions'
import { EventPermissionSet } from '../sets/EventPermissions'
import { FarePassPermissionSet } from '../sets/FarePassPermissions'
import { FavoriteLocationPermissionSet } from '../sets/FavoriteLocationPermissions'
import { GroupPermissionSet } from '../sets/GroupPermissions'
import { IvrPermissionSet } from '../sets/IvrPermissions'
import { JourneyPermissionSet } from '../sets/JourneyPermissions'
import { OperationalAlertsPermissionSet } from '../sets/OperationalAlertsPermissions'
import { PaymentMethodPermissionSet } from '../sets/PaymentMethodPermissions'
import { PromoPermissionSet } from '../sets/PromoPermissions'
import { RequestPermissionSet } from '../sets/RequestPermissions'
import { ReviewPermissionSet } from '../sets/ReviewPermissions'
import { RiderDriverRestrictionPermissionSet } from '../sets/RiderDriverRestrictionPermissions'
import { RiderUserPermissionSet } from '../sets/RiderUserPermissions'
import { RiderVehicleTypeRestrictionPermissionSet } from '../sets/RiderVehicleTypeRestrictionPermissions'
import { ServicePermissionSet } from '../sets/ServicePermissions'
import { UserBanPermissionSet } from '../sets/UserBanPermissions'
import { UserFleetAgreementPermissionSet } from '../sets/UserFleetAgreementPermissions'
import { VehiclePermissionSet } from '../sets/VehiclePermissions'
import { VehicleTypePermissionSet } from '../sets/VehicleTypePermissions'
import { AdminOperatorRole } from './AdminOperatorRole'

export const BookingAgentRole: PermissionSetType[] = [
  ...AdminOperatorRole,

  // TODO: Replace rider permission set with more limited permission sets
  RiderPermissionSet.Rider,

  /* View drivers of the system */
  DriverUserPermissionSet.View,

  /* Rider user management */
  RiderUserPermissionSet.View,
  RiderUserPermissionSet.New,
  RiderUserPermissionSet.Edit,
  RiderUserPermissionSet.Delete,
  RiderUserPermissionSet.SetPhonePin,

  /* Ability to see devices registered to users */
  DevicePermissionSet.View,

  /* Ability to see user ban status */
  UserBanPermissionSet.View,

  /* Ability to see user ivr calls */
  IvrPermissionSet.ViewMetrics,

  /* Ability to see and manage favorite locations */
  FavoriteLocationPermissionSet.View,
  FavoriteLocationPermissionSet.Edit,
  FavoriteLocationPermissionSet.Delete,

  /* Ability to see vehicles */
  VehiclePermissionSet.View,

  /* Ability to see duties & see vehicle locations */
  DutyPermissionSet.View,
  DutyPermissionSet.ViewVehicleLocation,
  DutyPermissionSet.ViewDriverConversation,

  /* Ability to View Service/Fleet/Zone/Stop/TimeRule */
  ServicePermissionSet.View,

  /* Ability to see announcement information */
  AnnouncementPermissionSet.View,

  /* Ability to see and apply promo to user */
  PromoPermissionSet.View,
  PromoPermissionSet.ApplyToUser,

  /* Ability to see fare passes and associated allocation */
  FarePassPermissionSet.View,

  RequestPermissionSet.Estimate,
  RequestPermissionSet.CreateUnmatchedRequest,
  RequestPermissionSet.View,
  RequestPermissionSet.ViewDiscounts,
  RequestPermissionSet.Write,
  RequestPermissionSet.Rematch,
  RequestPermissionSet.ViewRequestPredictions,
  RequestPermissionSet.ViewConstraintOverrides,

  PaymentMethodTypePermissionSet.View,

  GroupPermissionSet.View,
  GroupPermissionSet.ViewMembership,
  GroupPermissionSet.WriteMembership,
  GroupPermissionSet.ViewCondition,

  /* Ability to view reviews */
  ReviewPermissionSet.View,

  /* Ability to view charges */
  ChargePermissionSet.View,

  /* Ability to view payment methods, collect over phone and delete */
  PaymentMethodPermissionSet.View,
  PaymentMethodPermissionSet.CollectDirectly,
  PaymentMethodPermissionSet.InitiatePhoneCollection,
  PaymentMethodPermissionSet.Delete,

  /* Ability to view connected accounts */
  ConnectedAccountPermissionSet.View,

  /* Ability to subscribe to operational alerts and see them */
  OperationalAlertsPermissionSet.Subscribe,

  /* Ability to create fleet agreements for a rider */
  UserFleetAgreementPermissionSet.View,
  UserFleetAgreementPermissionSet.New,
  UserFleetAgreementPermissionSet.Delete,

  /* Ability to view the 'activity log' */
  EventPermissionSet.View,

  RiderDriverRestrictionPermissionSet.View,
  RiderDriverRestrictionPermissionSet.Create,
  RiderDriverRestrictionPermissionSet.Delete,

  RiderVehicleTypeRestrictionPermissionSet.View,
  RiderVehicleTypeRestrictionPermissionSet.Write,
  RiderVehicleTypeRestrictionPermissionSet.Delete,

  VehicleTypePermissionSet.View,
  VehicleTypePermissionSet.Write,
  VehicleTypePermissionSet.Delete,

  /* Ability to apply fare passes */
  FarePassPermissionSet.NewAllocation,

  /* Ability to see and manage a journey */
  JourneyPermissionSet.View,
  JourneyPermissionSet.Write,
]
