import { WebColors } from '@sparelabs/colors'

export interface IButtonTheme {
  backgroundColor: string
  textColor: string
}

export const DefaultButtonTheme: IButtonTheme = {
  backgroundColor: WebColors.backgroundPrimary,
  textColor: WebColors.contentPrimary,
}

export const SubmitButtonTheme: IButtonTheme = {
  textColor: WebColors.contentInvertedPrimary,
  backgroundColor: WebColors.interactivePrimary,
}

export const BrandButtonTheme: IButtonTheme = {
  textColor: WebColors.contentInvertedPrimary,
  backgroundColor: WebColors.brandPrimary,
}

export const LoadingButtonTheme: IButtonTheme = {
  backgroundColor: WebColors.backgroundSecondary,
  textColor: WebColors.contentSecondary,
}

export const SelectedButtonTheme: IButtonTheme = {
  textColor: WebColors.contentInvertedPrimary,
  backgroundColor: WebColors.interactivePrimary,
}

export const DangerButtonTheme: IButtonTheme = {
  textColor: WebColors.contentInvertedPrimary,
  backgroundColor: WebColors.negativePrimary,
}

export const DisabledButtonTheme: IButtonTheme = {
  backgroundColor: WebColors.interactivePrimaryDisabled,
  textColor: WebColors.contentInvertedPrimary,
}
