import { WebColors } from '@sparelabs/colors'
import React, { CSSProperties, forwardRef, ReactNode, Ref, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useAsync } from 'react-use'
import styled, { css } from 'styled-components'
import { st } from '../../locales/TranslationHelper'
import { shadowHigh } from '../../util'

interface ICircleProps {
  value?: string
  loading: boolean
  children?: ReactNode
}

const whiteBackground = css`
  background-color: ${WebColors.backgroundPrimary};
`

const backgroundImage = (url: string) =>
  css`
    background-image: url(${url});
  `

const hoverShadow = css`
  &:hover {
    box-shadow: ${shadowHigh};
  }
`

const hoverOverlay = css`
  cursor: pointer;
  &:hover {
    background-color: ${WebColors.interactivePrimaryHover};
    color: ${WebColors.contentInvertedPrimary};
  }
`

// Dropzone uses refs on the elements
const CircleDiv = forwardRef(({ value, loading, ...rest }: ICircleProps, ref: Ref<HTMLDivElement>) => (
  <div ref={ref} {...rest} />
))
CircleDiv.displayName = 'CircleDiv'

const BackgroundPhotoCircle = styled(CircleDiv)`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: solid 1px ${WebColors.borderPrimary};
  outline: none;
  background-position: center;
  background-size: cover;
  transition: all 0.3s ease;
  ${({ value, loading }: ICircleProps) => (value && !loading ? backgroundImage(value) : whiteBackground)}
  ${({ loading }: ICircleProps) => (loading ? '' : hoverShadow)}
`

const OverlayCircle = styled(CircleDiv)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  padding: 20px;
  background-color: 'transparent';
  transition: all 0.3s ease;
  color: ${({ value, loading }: ICircleProps) => (value && !loading ? 'transparent' : WebColors.contentSecondary)};
  ${({ loading }: ICircleProps) => (loading ? '' : hoverOverlay)}
`

const activeStyle: CSSProperties = {
  outlineColor: WebColors.interactivePrimary,
  outlineStyle: 'solid',
  outlineWidth: '1.5px',
}

interface IPhotoInputProps {
  upload(photoData: File): Promise<{ url: string }>
  onChange: (value?: string) => void
  onError: (error: Error) => void
  value?: string
  id?: string
}

export const PhotoInput = ({ value, onChange, onError, upload, id }: IPhotoInputProps) => {
  const [loading, setLoading] = useState<boolean>(false)

  const { acceptedFiles, getRootProps, isFileDialogActive, isFocused, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      maxFiles: 1,
      disabled: loading,
    })

  const style: CSSProperties = useMemo(
    () => (isDragActive || isDragAccept || isFileDialogActive || isFocused ? activeStyle : {}),
    [isDragActive, isDragAccept, isFileDialogActive, isFocused]
  )

  useAsync(async () => {
    if (acceptedFiles.length === 1) {
      setLoading(true)
      try {
        const { url } = await upload(acceptedFiles[0])
        onChange(url)
      } catch (error) {
        onError(error as Error)
      }
      setLoading(false)
    }
  }, [acceptedFiles])

  return (
    <BackgroundPhotoCircle value={value} loading={loading} {...getRootProps({ style })}>
      <input {...getInputProps()} id={id} />
      <OverlayCircle value={value} loading={loading}>
        {loading ? st.inputs.shared.uploading() : st.inputs.photo.prompt()}
      </OverlayCircle>
    </BackgroundPhotoCircle>
  )
}
