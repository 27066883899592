import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'

export enum DeviceOS {
  iOS = 'ios',
  Android = 'android',
}

export enum AppType {
  Rider = 'rider',
  Driver = 'driver',
}

export enum DeviceStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export interface IDevicePostBody {
  // This is a client side generate ID for a device that we will rely on to uniquely identity installations
  // It is generated by Spare and stored in local storage and will remain unique until the app is re-installed
  clientId?: string | null
  // This is the push notification token for Expo apps. If this token exists we will use it to send push notifications
  // Expo is being used on our latest Rider app
  expoToken?: string | null
  // This is client side ID generated by firebase library. It is used to uniquely identify device installations
  // Firebase ids and tokens are provided by our Driver app and our older Rider apps
  firebaseIid?: string | null
  // This is the firebase token, it is used to send push notifications to the apps
  firebaseToken?: string | null
  deviceOS: DeviceOS
  deviceVersion: string
  appType: AppType
  appName?: string | null
  appVersion?: string | null
  mobileAppId: string
}

export interface IDevicePatchBody {
  expoToken?: string | null
  firebaseToken?: string | null
  deviceVersion?: string
  appVersion?: string
  status?: DeviceStatus
}

export interface IDeviceResponse extends IModelJsonValues, Omit<Required<IDevicePostBody>, 'mobileAppId'> {
  userId: string
  organizationId: string
  mobileAppId: string | null
}

export interface IDeviceListQueryParams extends IListModelQueryParamValues {
  userId?: string
  firebaseIid?: string
  status?: DeviceStatus
  clientId?: string
}

/**
 * @deprecated Use PATCH operation to archive devices.
 */
export interface IDeviceDeleteQuery {
  firebaseIid: string
}
