import {
  IFareStub,
  IRequestStatusChange,
  RequestDispatchStatus,
  RequestIntentType,
  RequestStatus,
  Window,
} from '@sparelabs/domain-logic'
import { ILineString, IPoint } from '@sparelabs/geography'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { IRequestAccessibilityFeature } from './AccessibilityTypes'
import { AccessRole } from './AccessRuleTypes'
import { Currency } from './CurrencyTypes'
import { CreatedInterface } from './EstimateTypes'
import { IFareRedemptionStub } from './FareRedemptionTypes'
import { IFleetStub } from './FleetTypes'
import { PaymentFlow } from './PaymentFlowTypes'
import {
  IInlineRequestCancellationDetails,
  RequestCancellationFault,
  RequestCancelledBy,
} from './RequestCancellationTypes'
import { IRequestRiderType } from './RequestRiderTypes'
import { IReviewStubResponse } from './ReviewTypes'
import { IServiceBrand, ServiceType } from './ServiceTypes'
import { IEstimatedRange, MatchingEndpoint } from './SharedTypes'
import { IUserStub } from './UserTypes'

/**
 * @deprecated use from @sparelabs/domain-logic instead
 */
export { RequestIntentType, RequestStatus, IRequestStatusChange }

export interface IEstimateStub {
  id: string
  createdAt: number
  endpoint: MatchingEndpoint
}

/**
 * @deprecated for new fare object
 */
export interface IRequestRiderPriceInformation {
  /**
   * @deprecated for new fare object
   */
  priceAmount: number
  /**
   * @deprecated for new fare object
   */
  priceTax: number
  /**
   * @deprecated for new fare object
   */
  priceCurrency: Currency
}

export interface IRequestDriverEarningInformation {
  earningAmount: number
  earningCurrency: Currency
}

export interface IRequestTravelDuration {
  // Should really be "min travel duration"
  travelDuration: number
  // Should really be "max travel delay"
  travelDurationFlexibility: number
}

export interface IRequestWalkingPolylines {
  pickupWalkingPolyline: ILineString | null
  dropoffWalkingPolyline: ILineString | null
}

export interface IRequestWalkingPolylineDurations {
  pickupWalkingDuration: number
  dropoffWalkingDuration: number
}

export interface IRequestLateness {
  pickupLateness: number
  dropoffLateness: number
  relevantLateness: number
}

interface IRequestPostBodyBase {
  estimateId: string
  requestedPickupAddress: string
  requestedDropoffAddress: string
  notes?: string | null
  paymentMethodId?: string | null
  paymentMethodTypeId?: string | null
  chargeId?: string
  metadata?: Record<string, unknown>
  originalRequestedTs?: number | null
  pickupNotes?: string | null
  dropoffNotes?: string | null
}

export interface IRequestPostBody extends IRequestPostBodyBase {
  riderId?: string
  numRiders?: number
  riders?: IRequestRiderType[]
  requestedPickupLocation: IPoint
  requestedDropoffLocation: IPoint
  accessibilityFeatures?: IRequestAccessibilityFeature[]
  lockedToDutyId: string | null
}

export interface IRematchPostBody extends IRequestPostBodyBase {}

export interface IRequestCancellationPostBody {
  reason: string
  fault?: RequestCancellationFault
  cancelledBy?: RequestCancelledBy
  notes?: string | null
  metadata?: Record<string, unknown>
}

export type IRequestPatchBody = Partial<
  Pick<
    IRequestPostBody,
    | 'accessibilityFeatures'
    | 'riders'
    | 'chargeId'
    | 'metadata'
    | 'notes'
    | 'pickupNotes'
    | 'dropoffNotes'
    | 'paymentMethodId'
    | 'paymentMethodTypeId'
  >
> &
  Partial<IRequestDataReconciliationFields>

export interface IRequestDataReconciliationFields {
  completedPickupAddress: string
  completedPickupLocation: IPoint

  completedDropoffAddress: string
  completedDropoffLocation: IPoint

  completedPickupTs: number
  completedDropoffTs: number
  riders: IRequestRiderType[]
  paymentMethodTypeId: string | null
}

export interface IRequestScheduledTripInfo {
  scheduledPickupTs: number
  scheduledPickupAddress: string
  scheduledPickupLocation: IPoint

  scheduledDropoffTs: number
  scheduledDropoffAddress: string
  scheduledDropoffLocation: IPoint
}

export interface IRequestOutputScheduledInformation
  extends IRequestScheduledTripInfo,
    IRequestWalkingPolylines,
    IRequestWalkingPolylineDurations {}

export interface IRequestResponse
  extends IModelJsonValues,
    IRequestWalkingPolylines,
    IRequestWalkingPolylineDurations,
    IRequestRiderPriceInformation,
    IRequestDriverEarningInformation {
  id: string

  estimateId: string
  estimate: IEstimateStub

  requestedPickupLocation: IPoint
  requestedDropoffLocation: IPoint
  requestedPickupTs: number
  requestedDropoffTs: number
  numRiders?: number
  riders?: IRequestRiderType[]
  accessibilityFeatures: IRequestAccessibilityFeature[]
  riderId: string | null
  creatorId: string | null
  recurrenceId: string | null
  requestedFlexibility: number
  requestedPickupAddress: string
  requestedDropoffAddress: string
  serviceType: ServiceType
  serviceId: string
  serviceBrand: IServiceBrand
  serviceProviderOrganizationId: string
  notes: string | null
  metadata: Record<string, unknown>
  createdInterface: CreatedInterface
  travelDistance: number
  travelDuration: number
  travelDurationFlexibility: number
  matchCutoffTs: number

  pickupEta: number | null
  dropoffEta: number | null
  dropoffEtaLatest: number | null

  status: RequestStatus
  dispatchStatus?: RequestDispatchStatus
  statusChanges: IRequestStatusChange[]
  anonymized: boolean

  dutyId: string | null
  driverId: string | null
  vehicleId: string | null
  dutyIdentifier: string | null

  rider: IUserStub | null
  driver: IUserStub | null

  fleet: IFleetStub | null

  initialScheduledPickupTs: number | null
  scheduledPickupTs: number | null
  scheduledPickupAddress: string | null
  scheduledPickupLocation: IPoint | null

  initialScheduledDropoffTs: number | null
  scheduledDropoffTs: number | null
  scheduledDropoffAddress: string | null
  scheduledDropoffLocation: IPoint | null

  cancellationDetails: IInlineRequestCancellationDetails | null

  shouldRiderReview: boolean
  shouldDriverReview: boolean

  reviewByRider: IReviewStubResponse | null
  reviewByDriver: IReviewStubResponse | null

  paymentMethodId: string | null
  paymentMethodTypeId: string | null
  fare: IFareStub
  fareRedemptions: IFareRedemptionStub[]
  prepaid: boolean
  access: AccessRole

  intentType: RequestIntentType
  pickupCompletedTs: number | null
  estimatedPickupTime: IEstimatedRange
  dropoffCompletedTs: number | null
  estimatedDropoffTime: IEstimatedRange
  isExternallyDispatched: boolean
  externalUrl: string | null
  lockedToDutyId: string | null
  showPickupDropoffWindows: boolean
  flagDownDutyId: string | null

  lateness: IRequestLateness
  pickupArrivedTs: number | null
  dropoffArrivedTs: number | null
  metrics: IRequestPrecomputedMetricsData | null
  matchedPickupWindow: Window | null
  matchedDropoffWindow: Window | null
  originalRequestedTs: number | null

  completedPickupLocation: IPoint | null
  completedPickupAddress: string | null

  completedDropoffLocation: IPoint | null
  completedDropoffAddress: string | null
  isManuallyReconciled: boolean

  pickupNotes: string | null
  dropoffNotes: string | null

  scheduledPickupStopId: string | null
  scheduledDropoffStopId: string | null
}

export interface IRequestPrecomputedMetricsData {
  inVehicleTripDistance: number
  travelDuration: number | null
  waitTime: number | null
  driverLocationOnMatch: IPoint | null
  acceptedTs: number | null
  deadheadToPickupM: number | null
}

/**
 * We keep this in-sync with what the backend accepts (defined in sparelabs-api/src/features/request/RequestPagination.ts`)
 */
export type RequestOrderBy = Extract<
  keyof IRequestResponse | keyof IRequestLateness,
  | 'requestedPickupTs'
  | 'createdAt'
  | 'updatedAt'
  | 'pickupLateness'
  | 'dropoffLateness'
  | 'relevantLateness'
  | 'initialScheduledPickupTs'
>

export interface IRequestListQueryParams extends Omit<IListModelQueryParamValues, 'orderBy'> {
  riderId?: string
  driverId?: string
  fleetId?: string
  status?: RequestStatus[] | RequestStatus
  dispatchStatus?: RequestDispatchStatus[] | RequestDispatchStatus
  fromRequestedPickupTs?: number
  toRequestedPickupTs?: number
  requestCancellationReason?: string
  serviceId?: string
  serviceIds?: string[]
  dutyId?: string
  createdInterface?: CreatedInterface
  recurrenceId?: string
  creatorId?: string
  isStranded?: true
  hasAnyLateness?: boolean
  hasBeenOverridden?: boolean
  orderBy?: RequestOrderBy
  requestedPickupLatitude?: number
  requestedPickupLongitude?: number
  requestedDropoffLatitude?: number
  requestedDropoffLongitude?: number
  requestedPickupRadius?: number
  requestedDropoffRadius?: number
  isPartOfJourney?: boolean
}

export interface IPostRequestFareEstimateBody extends IRequestPatchBody {}

export interface IPostRequestFareEstimateResponse {
  fare: IFareStub
  /**
   * Indicates to client if this change to the request will require creating a new charge.
   */
  requiresNewCharge: boolean
  /**
   * Payment flows supported by the payment method. This largely depends on the brand and connected
   * account provider.
   */
  supportedPaymentFlows: PaymentFlow[]
  fareRedemptions: IFareRedemptionStub[]
}

export interface IRequestEditDropoffPostBody {
  requestedDropoffAddress: string
  requestedDropoffLocation: IPoint
}

export interface IRequestForceMatchPostBody {
  dutyId?: string
}

export type IRequestSearchQueryParams = IRequestSearchStringQueryParams | IRequestSearchIdQueryParams

export interface IRequestSearchStringQueryParams {
  search: string
  fromRequestedPickupTs: number
  toRequestedPickupTs: number
  limit?: number
  pickupNearLatitude?: number
  pickupNearLongitude?: number
  pickupRadiusMeters?: number
  dropoffNearLatitude?: number
  dropoffNearLongitude?: number
  dropoffRadiusMeters?: number
  nearLatitude?: number
  nearLongitude?: number
  radiusMeters?: number
}

export interface IRequestSearchIdQueryParams {
  ids: string[]
}

export interface IRequestSearchResponseItem {
  id: string
  status: RequestStatus
  requestedPickupTs: number
  requestedPickupAddress: string | null
  requestedDropoffAddress: string | null
  requestedPickupLocation: IPoint
  scheduledPickupTs: number | null
  riderFirstName: string | null
  riderLastName: string | null
  riderPhoneNumber: string | null
  riderEmail: string | null
  riderExternalNumericId: number | null
  serviceName: string
}

export interface IRequestSearchResponse {
  data: IRequestSearchResponseItem[]
}

export enum RequestPredictionType {
  SameDayReturn = 'sameDayReturn',
  RecentlyBooked = 'recentlyBooked',
  FrequentByDayTime = 'frequentByDayTime',
  FrequentlyBooked = 'frequentlyBooked',
}

export interface IRequestPredictionQueryParams {
  requestedPickupAddress?: string
  requestedDropoffAddress?: string
  nowTs?: number
}

export interface IRequestPredictionResponse {
  serviceId: string
  intentType?: RequestIntentType
  endpoint: MatchingEndpoint
  requestedPickupTs?: number
  requestedPickupAddress: string
  requestedDropoffTs?: number
  requestedDropoffAddress: string
  requestedPickupLocation: IPoint
  requestedDropoffLocation: IPoint
  accessibilityFeatures: IRequestAccessibilityFeature[]
  riders: IRequestRiderType[]
  notes?: string
  paymentMethodId?: string | null
  paymentMethodTypeId?: string | null
  predictionType: RequestPredictionType
}
