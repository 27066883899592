import { IOrganizationResponse, IUserOnlyTokenResponse } from '@sparelabs/api-client'
import { constants } from 'src/env/Environment'

class AuthenticatorHelperClass {
  private host: string = constants.apiUrl
  private auth?: IUserOnlyTokenResponse
  private organizationId?: string
  private readonly userOrganization?: IOrganizationResponse

  public setRegionalHost(host: string): void {
    this.host = host
  }

  public setOrganizationId(organizationId: string) {
    this.organizationId = organizationId
  }

  public setAuth(auth: IUserOnlyTokenResponse): void {
    this.auth = auth
  }

  public getRegionalHost(): string {
    return this.host
  }

  public getUserToken(): string | undefined {
    return this.auth?.token
  }

  public getUserId(): string | undefined {
    return this.auth?.userId
  }

  public getOrganizationId(): string | undefined {
    return this.organizationId
  }

  public isAuthenticated(): boolean {
    return Boolean(this.auth)
  }

  public clear(): void {
    this.auth = undefined
  }
}

export const AuthenticationHelper = new AuthenticatorHelperClass()
