import { IRequestAccessibilityFeature, IRequestRiderType, RequestIntentType } from '@sparelabs/domain-logic'
import { IPoint } from '@sparelabs/geography'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { CreatedInterface } from './EstimateTypes'
import { IJourneyStop, LegMode } from './JourneyEstimateTypes'
import { RequestCancelledBy } from './RequestCancellationTypes'
import { IRequestPostBody, IRequestResponse } from './RequestTypes'

export enum LegType {
  OnDemand = 'onDemand',
  PublicTransit = 'publicTransit',
  Walking = 'walking',
}

export interface IJourneyFixedLeg {
  mode: LegMode.Bus | LegMode.Train | LegMode.Tram
  steps: IJourneyStop[]
  routeColor: string | null
  routeTextColor: string | null
  routeLongName: string | null
  routeShortName: string | null
  polyline: string
  headsign: string | null
  interlineWithPreviousLeg: boolean | null
  pickupAddress: string
  dropoffAddress: string
  pickupLocation: IPoint
  dropoffLocation: IPoint
  startTs: number
  startMinTs: number
  startMaxTs: number
  initialStartTs: number
  initialStartMinTs: number
  initialStartMaxTs: number
  endTs: number
  endMinTs: number
  endMaxTs: number
  initialEndTs: number
  initialEndMinTs: number
  initialEndMaxTs: number
  nextDepartures: IFixedLegNextDeparture[]
}

export type IJourneyFixedRouteLegResponse = IJourneyFixedLeg & IModelJsonValues

export interface IJourneyWalkingLegBase {
  travelDistanceM: number
  duration: number
  polyline: string
}

export type IJourneyWalkingRouteLegResponse = IJourneyWalkingLegBase & IModelJsonValues

export interface IJourneyWalkingLeg extends IJourneyWalkingLegBase {
  mode: LegMode.Walk
}

export interface IJourneyOnDemandLeg extends IRequestResponse {
  mode: LegMode.OnDemand
}

export interface IJourneyResponse extends IModelJsonValues {
  riderId: string
  requestedTs: number
  intentType: RequestIntentType
  requestedStartAddress: string
  requestedStartLocationName: string
  requestedStartLocation: IPoint
  requestedEndAddress: string
  requestedEndLocationName: string
  requestedEndLocation: IPoint
  createdInterface: CreatedInterface
  riders: IRequestRiderType[]
  accessibilityFeatures: IRequestAccessibilityFeature[] | null
  initialScheduledStartTs: number
  initialScheduledMinStartTs: number
  initialScheduledMaxStartTs: number
  scheduledMinStartTs: number
  scheduledStartTs: number
  scheduledMaxStartTs: number
  initialScheduledEndTs: number
  initialScheduledMinEndTs: number
  initialScheduledMaxEndTs: number
  scheduledMinEndTs: number
  scheduledEndTs: number
  scheduledMaxEndTs: number
  cancelledTs: number | null
  cancelledBy: RequestCancelledBy | null
  cancellationReason: string | null
}

export interface IJourneyLeg {
  placement: number
  legType: LegType
  journeyId: string
  requestId: string | null
  fixedRouteLegId: string | null
  walkingLegId: string | null
}

export interface IJourneyLegResponse extends IJourneyLeg, IModelJsonValues {}

// Journey post Types

export interface IJourneyOnDemandLegPostBody extends IRequestPostBody {
  mode: LegMode.OnDemand
}

export interface IFixedLegNextDeparture {
  ts: number
  frequencyInSec?: number
  isLast?: boolean
}

export interface IJourneyFixedLegPostBody {
  mode: LegMode.Bus | LegMode.Train | LegMode.Tram
  steps: IJourneyStop[]
  routeColor?: string
  routeTextColor?: string
  routeLongName?: string
  routeShortName?: string
  polyline: string
  headsign?: string
  interlineWithPreviousLeg?: boolean
  pickupAddress: string
  dropoffAddress: string
  pickupLocation: IPoint
  dropoffLocation: IPoint
  startTs: number
  startMinTs: number
  startMaxTs: number
  initialStartTs: number
  initialStartMinTs: number
  initialStartMaxTs: number
  endTs: number
  endMinTs: number
  endMaxTs: number
  initialEndTs: number
  initialEndMinTs: number
  initialEndMaxTs: number
  nextDepartures: IFixedLegNextDeparture[]
}

export interface IJourneyWalkingLegPostBody {
  travelDistanceM: number
  duration: number
  polyline: string
  mode: LegMode.Walk
}

export type IJourneyLegItemPostBody =
  | IJourneyFixedLegPostBody
  | IJourneyWalkingLegPostBody
  | IJourneyOnDemandLegPostBody

export interface IJourneyPostBody {
  riderId: string
  requestedTs: number
  intentType: RequestIntentType
  requestedStartAddress: string
  requestedStartLocationName: string
  requestedStartLocation: IPoint
  requestedEndAddress: string
  requestedEndLocationName: string
  requestedEndLocation: IPoint
  createdInterface: CreatedInterface
  riders: IRequestRiderType[]
  accessibilityFeatures?: IRequestAccessibilityFeature[]
  initialScheduledStartTs: number
  initialScheduledMinStartTs: number
  initialScheduledMaxStartTs: number
  scheduledMinStartTs: number
  scheduledStartTs: number
  scheduledMaxStartTs: number
  initialScheduledEndTs: number
  initialScheduledMinEndTs: number
  initialScheduledMaxEndTs: number
  scheduledMinEndTs: number
  scheduledEndTs: number
  scheduledMaxEndTs: number
  legs: IJourneyLegItemPostBody[]
}

export enum ListJourneyType {
  PAST = 'past',
  SCHEDULED = 'scheduled',
}
export interface IListJourneyQueryParams extends IListModelQueryParamValues {
  listType?: ListJourneyType
  riderId?: string
}

export interface IJourneyPatchBody {
  cancelledBy: RequestCancelledBy
  cancellationReason: string
}

// Journey GET Types
export type IJourneyLegItemResponse = IJourneyFixedLeg | IJourneyWalkingLeg | IJourneyOnDemandLeg
export interface IJourneyGetResponse extends IJourneyResponse {
  legs: IJourneyLegItemResponse[]
}

export interface IListActiveJourneyResponse {
  data: IJourneyGetResponse[]
}

export interface IListJourneyResponse extends IJourneyResponse {
  requestIds: string[]
}
