import { MetadataError } from '@sparelabs/error-types'
import { Geography, IPoint } from '@sparelabs/geography'
import { last } from 'lodash'
import { SlotType } from '../types'

export interface IBinnableSlot {
  scheduledLocation: IPoint
  type: SlotType
}

export interface ISlotBin<T> {
  location: IPoint
  slots: T[]
}

export class SlotBinCalculator {
  /**
   * WARNING: If you are going to use this function you need to make sure your list of slots is sorted
   * The notion of bins doesn't makes sense unless the slots are in order
   * And this has led to bugs in the past!
   */
  public static binByGeohash<T extends IBinnableSlot>(slots: T[]): Array<ISlotBin<T>> {
    if (slots.length === 0) {
      return []
    }
    const firstSlot = slots[0]
    this.validateScheduledLocation(firstSlot)

    const slotBins: Array<ISlotBin<T>> = [{ location: firstSlot.scheduledLocation, slots: [firstSlot] }]

    let previousSlot = firstSlot
    for (const slot of slots.slice(1)) {
      const previousBin = last(slotBins) as ISlotBin<T>
      // break slots should go in their own bin
      if (
        this.geohashSlot(slot) === Geography.geohash(previousBin.location) &&
        slot.type !== SlotType.DriverBreak &&
        previousSlot.type !== SlotType.DriverBreak
      ) {
        previousBin.slots.push(slot)
      } else {
        slotBins.push({ location: slot.scheduledLocation, slots: [slot] })
      }
      previousSlot = slot
    }

    return slotBins
  }

  private static geohashSlot(slot: IBinnableSlot): string {
    this.validateScheduledLocation(slot)
    return Geography.geohash(slot.scheduledLocation)
  }

  private static validateScheduledLocation<T extends IBinnableSlot>(slot: T): void {
    if (!slot.scheduledLocation) {
      throw new MetadataError('every slot should have a scheduled location', { slot })
    }
  }
}
