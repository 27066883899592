import { IPoint } from '@sparelabs/geography'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { IRequestAccessibilityFeature } from './AccessibilityTypes'
import { CustomFieldFindOptionQuery } from './CustomFieldTypes'
import { NotificationDeliveryMethod, RiderNotificationPreference } from './NotificationSettingTypes'
import { IRequestRiderType } from './RequestRiderTypes'
import { Language } from './SharedTypes'
import { IPartialUserDataPostBody, IPartialUserDataResponse } from './UserTypes'

export interface IOrganizationRiderResponse {
  metadata: Record<string, unknown>
  defaultRequestMetadata: Record<string, unknown>
  defaultPaymentMethodId: string | null
  defaultRiders: IRequestRiderType[] | null
  defaultAccessibilityFeatures: IRequestAccessibilityFeature[] | null
  defaultNotes: string | null
  externalNumericId: number | null
  channelPreference: NotificationDeliveryMethod | null
  languagePreference: Language | null
  notificationPreference: RiderNotificationPreference[]
}

export interface IRiderMeResponse {
  id: string
  firstName: string | null
  lastName: string | null
  photoUrl: string | null
  phoneNumber: string | null
  email: string | null
  defaultPaymentMethodId: string | null
  defaultRiders: IRequestRiderType[] | null
  defaultAccessibilityFeatures: IRequestAccessibilityFeature[] | null
  defaultNotes: string | null
  defaultRequestMetadata: Record<string, unknown>
  externalNumericId: number | null
  channelPreference: NotificationDeliveryMethod | null
  languagePreference: Language | null
  notificationPreference: RiderNotificationPreference[]
  metadata: Record<string, unknown>
}

export interface IRiderResponse extends IModelJsonValues, IPartialUserDataResponse, IOrganizationRiderResponse {
  isClaimed: boolean
  lastSeenTs: number | null
  fraudLevel: RiderFraudLevel
}

export interface IRiderListQueryParams extends IListModelQueryParamValues {
  email?: string
  phoneNumber?: string
  search?: string
  groupId?: string
  fromLastSeenTs?: number
  toLastSeenTs?: number
  fraudLevel?: RiderFraudLevel
  customFields?: CustomFieldFindOptionQuery
}

export interface IRiderPostBody
  extends IPartialUserDataPostBody,
    Partial<Omit<IOrganizationRiderResponse, 'defaultPaymentMethodId' | 'groupId'>> {}

export interface IRiderPatchBody
  extends IPartialUserDataPostBody,
    Partial<Omit<IOrganizationRiderResponse, 'groupId'>> {}

export interface IRiderMePatchBody {
  firstName?: string | null
  lastName?: string | null
  photoUrl?: string | null
  phoneNumber?: string | null
  email?: string | null
  defaultPaymentMethodId?: string | null
  defaultRiders?: IRequestRiderType[] | null
  defaultAccessibilityFeatures?: IRequestAccessibilityFeature[] | null
  defaultNotes?: string | null
  defaultRequestMetadata?: Record<string, unknown> | null
  externalNumericId?: number | null
  channelPreference?: NotificationDeliveryMethod | null
  languagePreference?: Language | null
  notificationPreference?: RiderNotificationPreference[]
  metadata?: Record<string, unknown> | null
}

export interface IRiderMeJoinGroupPostBody {
  secretCode: string
}

export enum ExternalPlatform {
  Routematch = 'Routematch',
  Trapeze = 'Trapeze',
}

export interface IRiderAddressSuggestion {
  address: string
  location: IPoint
}

export enum RiderFraudLevel {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export interface IRiderPhonePinPostBody {
  phonePin: string
}

export interface IRiderPhonePinResponse {
  isPhonePinSet: boolean
}

export interface IRiderSummaryQueryParams {
  fromTs?: number
  toTs?: number
}

export interface IRiderSummaryResponse {
  summary?: string[]
}
