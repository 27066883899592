import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IDutyRequestOfferListQueryParams, IDutyRequestOfferResponse, Resources } from '../types'

export class DutyRequestOfferResource extends GenericResource<
  IDutyRequestOfferResponse,
  IDutyRequestOfferListQueryParams,
  never,
  never
> {
  public get = this.buildGet()
  public list = this.buildList()

  constructor(agent: AxiosInstance) {
    super(Resources.DutyRequestOffers, agent)
  }
}
