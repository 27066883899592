import { z } from '@sparelabs/zod'

export const IUploadPhotoOptionsSchema = z.object({
  resizePhoto: z.boolean().optional(),
})

export enum UploadBucket {
  Import = 'import',
  File = 'file',
}

export interface IUploadQueryParams {
  bucket?: UploadBucket
  importColumnFileOrganizationId?: string
}

export type IUploadPhotoOptions = z.infer<typeof IUploadPhotoOptionsSchema>

export interface IPublicUploadFilePostBody {
  organizationId: string
}
