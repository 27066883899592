import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { WebColors } from '@sparelabs/colors'
import React from 'react'
import styled from 'styled-components'
import { AbstractButton } from '../controls/AbstractButton'
import { PopoverWithDescription } from './PopoverDescription'

export interface IPopoverItemProps {
  onClick: () => void
  title: string
  disabled?: boolean
  icon?: IconProp
  iconColor?: string
  description?: string
  validateActionText?: string
  width?: string
  backgroundColor?: string
}

export const DefaultPopoverItem = styled(AbstractButton)<{ width?: string; backgroundColor?: string }>`
  padding: 0.5rem 1rem;
  text-align: start;
  white-space: pre-line;
  justify-content: start;
  width: ${({ width }) => width || 'auto'};
  box-shadow: none !important;
  &:hover {
    background-color: ${WebColors.backgroundSecondary} !important;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:focus-visible {
    outline-offset: -2px;
    border-radius: 8px;
  }
  border-radius: unset;
  background-color: ${({ backgroundColor }) => backgroundColor || 'unset'};
`

export const PopoverItem = ({
  onClick,
  title,
  icon,
  description,
  width,
  disabled,
  iconColor,
  validateActionText,
  backgroundColor,
}: IPopoverItemProps): JSX.Element => (
  <DefaultPopoverItem
    iconColor={iconColor}
    disabled={disabled}
    width={width}
    onClick={() => {
      onClick()
    }}
    icon={icon}
    validateActionText={validateActionText}
    backgroundColor={backgroundColor}
  >
    {description ? <PopoverWithDescription title={title} description={description} /> : title}
  </DefaultPopoverItem>
)
