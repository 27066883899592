import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IFareRedemptionListQueryParams, IFareRedemptionResponse, Resources } from '../types'

export class FareRedemptionResource extends GenericResource<
  IFareRedemptionResponse,
  IFareRedemptionListQueryParams,
  never,
  never
> {
  public list = this.buildList()

  constructor(agent: AxiosInstance) {
    super(Resources.FareRedemptions, agent)
  }
}
