/**
 * @deprecated use from @sparelabs/domain-logic instead
 */
export {
  ArriveByPickupConstraintType,
  AccessibilityFeature,
  IVehicleAccessibilityFeature,
  IRequestAccessibilityFeature,
  IServiceAccessibilityFeature,
  AccessibilitySetting,
} from '@sparelabs/domain-logic'
