import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faExternalLink,
  faFileAlt,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faUpload,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Root as VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { IPublicFileResponse } from '@sparelabs/http-client-utils'
import { round } from 'lodash'
import React, { useState } from 'react'
import { AsyncRoundedButton, LinkButton } from '../../controls'
import { RoundedButton } from '../../controls/RoundedButton'
import { st } from '../../locales/TranslationHelper'
import { EmptyValue } from '../ViewField'
import {
  FileUrlValue,
  getIconFromFileFormat,
  isFile,
  isFileResponse,
  isPublicFileResponse,
  openTempUrl,
  TempUrlType,
} from './FileViewHelpers'
import {
  FileViewContainer,
  FileViewFileName,
  FileViewFileNameContainer,
  FileViewFileSize,
  FileViewIcon,
} from './FileViewStyles'

library.add(faFilePdf, faFileImage, faFileCsv, faFileExcel, faFileAlt, faExternalLink, faUpload)

export interface IFileViewProps {
  id?: string
  value: FileUrlValue | File
  /**
   * Only needs to provided when the file is private
   */
  tempUrl?: TempUrlType
  onError?: (error: Error) => void
  // Provide the following props to enable a remove button
  onRemove?: () => void
  disableRemove?: boolean
  hideButtons?: boolean
}

const MEGABYTE = 1000000

const FileLinkButton = ({ value, tempUrl, onError }: Pick<IFileViewProps, 'value' | 'tempUrl' | 'onError'>) => {
  const [loadingFileUrl, setLoadingFileUrl] = useState<boolean>(false)
  const isAsync: boolean = Boolean(value) && !isFile(value) && !isPublicFileResponse(value) && isFileResponse(value)

  const handleViewClick = async () => {
    setLoadingFileUrl(true)
    await openTempUrl(value as FileUrlValue, tempUrl).catch(onError)
    setLoadingFileUrl(false)
  }

  if (isAsync) {
    return (
      <AsyncRoundedButton onClick={handleViewClick} loading={loadingFileUrl} tabIndex={0}>
        {!loadingFileUrl && <FontAwesomeIcon icon='external-link' />}
        <VisuallyHidden>{st.inputs.dropzone.viewButton()}</VisuallyHidden>
      </AsyncRoundedButton>
    )
  }

  return (
    <LinkButton
      isExternal={true}
      to={(value as IPublicFileResponse).url}
      icon='external-link'
      target='_blank'
      tabIndex={0}
    >
      <VisuallyHidden>{st.inputs.dropzone.viewButton()}</VisuallyHidden>
    </LinkButton>
  )
}

export const FileView = ({
  id,
  value,
  onRemove,
  disableRemove,
  tempUrl,
  onError,
  hideButtons,
}: IFileViewProps): JSX.Element => {
  if (!value) {
    return <EmptyValue>{st.common.none()}</EmptyValue>
  }

  const fileFormat = isFile(value) ? value.type : value.format

  return (
    <FileViewContainer id={id}>
      <FileViewIcon icon={getIconFromFileFormat(fileFormat)} />
      <FileViewFileNameContainer>
        <FileViewFileName>
          <VisuallyHidden>{st.inputs.dropzone.fileNameLabel()}</VisuallyHidden> {value.name}
        </FileViewFileName>
        <FileViewFileSize>
          <VisuallyHidden>{st.inputs.dropzone.fileSizeLabel()}</VisuallyHidden>
          {st.inputs.file.sizeMb({ size: round(value.size / MEGABYTE, 2) })}
        </FileViewFileSize>
      </FileViewFileNameContainer>

      <FileButtons
        value={value}
        onRemove={onRemove}
        onError={onError}
        disableRemove={disableRemove}
        tempUrl={tempUrl}
        hideButtons={hideButtons}
      />
    </FileViewContainer>
  )
}

const FileButtons = ({
  value,
  onRemove,
  onError,
  tempUrl,
  hideButtons,
  disableRemove,
}: Pick<
  IFileViewProps,
  'value' | 'tempUrl' | 'onError' | 'onRemove' | 'disableRemove' | 'hideButtons'
>): JSX.Element | null => {
  if (hideButtons) {
    return null
  }
  return (
    <>
      {!isFile(value) && <FileLinkButton value={value} tempUrl={tempUrl} onError={onError} />}
      {onRemove && (
        <RoundedButton disabled={disableRemove} icon='trash-alt' onClick={onRemove}>
          <VisuallyHidden>{st.inputs.dropzone.deleteButton()}</VisuallyHidden>
        </RoundedButton>
      )}
    </>
  )
}
