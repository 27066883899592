import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React, { CSSProperties, ReactNode } from 'react'
import { Button, ButtonProps } from 'reactstrap'
import styled, { css } from 'styled-components'
import { ButtonIcon } from '../icons/ButtonIcon'
import { ButtonFocusTheme } from '../util'
import { DefaultButtonTheme, IButtonTheme } from './ButtonThemes'

interface IButtonStyleProps {
  theme: IButtonTheme
  width?: string
}

export const AbstractButtonStyles = css`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  width: ${({ width }: IButtonStyleProps) => width || 'auto'};
  padding: 8px 12px;
  border: 0px;

  background-color: ${({ theme }: IButtonStyleProps) => theme.backgroundColor};
  color: ${({ theme }: IButtonStyleProps) => theme.textColor};
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: box-shadow 150ms ease;
  ${ButtonFocusTheme}
  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    color: ${({ theme }: IButtonStyleProps) => theme.textColor} !important;
    background-color: ${({ theme }: IButtonStyleProps) => theme.backgroundColor} !important;
    border: 0px !important;
  }

  /* Duplication of styles is needed here to override Reactstrap styling to get our desired disabled state
    there is a spike ticket for removing Reactstrap which would prevent us needing to do this
    https://sparelabs.atlassian.net/browse/FIN-1093
  */
  &:disabled {
    box-shadow: none !important;
    color: ${({ theme }: IButtonStyleProps) => theme.textColor} !important;
    background-color: ${({ theme }: IButtonStyleProps) => theme.backgroundColor} !important;
    border: 0px !important;
  }
  // removing ReactStrap's default styling
  &:active:focus {
    box-shadow: none !important;
  }
`

const StyledButton = styled(Button)`
  ${AbstractButtonStyles}
`

export interface IButtonProps extends ButtonProps {
  onClick: () => void
  validateActionText?: string
  icon?: IconProp
  iconColor?: string
  theme?: IButtonTheme
  width?: string
  disabled?: boolean
  style?: CSSProperties
  children?: ReactNode
  className?: string
}

export const AbstractButton = ({
  onClick,
  validateActionText,
  icon,
  iconColor,
  children,
  theme = DefaultButtonTheme,
  ...rest
}: IButtonProps): JSX.Element => (
  <StyledButton
    {...rest}
    theme={theme}
    onClick={() => {
      if (!validateActionText || confirm(validateActionText)) {
        onClick()
      }
    }}
  >
    {icon && <ButtonIcon icon={icon} color={iconColor ?? theme.textColor} />}
    {children}
  </StyledButton>
)
