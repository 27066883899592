import moment from 'moment-timezone'

export class DateTimeHelper {
  public static readonly timezone = moment.tz.guess()

  public static formatDate(value: string | number, timezone: string): string {
    return moment.tz(value, timezone).format('ddd, ll')
  }

  public static formatDateTime(value: number, timezone: string): string {
    return moment.unix(value).tz(timezone).format('llll')
  }
}
