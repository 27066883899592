import { AllReportsModels } from './AnalyzeReportModelTypes'

export enum RidershipReportRequestColumns {
  Id = 'id',
  CreatedBy = 'createdBy',
  RecurrenceId = 'recurrenceId',
  CreatedAtTime = 'createdAtTime',
  OriginalRequestedPickupTime = 'originalRequestedPickupTime',
  RequestedPickupTime = 'requestedPickupTime',
  OriginalScheduledPickupTime = 'originalScheduledPickupTime',
  OriginalScheduledDropoffTime = 'originalScheduledDropoffTime',
  ScheduledPickupTime = 'scheduledPickupTime',
  RequestedPickupAddress = 'requestedPickupAddress',
  RequestedPickupLat = 'requestedPickupLat',
  RequestedPickupLong = 'requestedPickupLong',
  ScheduledPickupAddress = 'scheduledPickupAddress',
  ScheduledPickupLat = 'scheduledPickupLat',
  ScheduledPickupLong = 'scheduledPickupLong',
  PickupWalkingDurationS = 'pickupWalkingDurationS',
  OriginalRequestedDropoffTime = 'originalRequestedDropoffTime',
  RequestedDropoffTime = 'requestedDropoffTime',
  ScheduledDropoffTime = 'scheduledDropoffTime',
  RequestedDropoffAddress = 'requestedDropoffAddress',
  RequestedDropoffLat = 'requestedDropoffLat',
  RequestedDropoffLong = 'requestedDropoffLong',
  ScheduledDropoffAddress = 'scheduledDropoffAddress',
  ScheduledDropoffLat = 'scheduledDropoffLat',
  ScheduledDropoffLong = 'scheduledDropoffLong',
  DropoffWalkingDurationS = 'dropoffWalkingDurationS',
  Notes = 'notes',
  PickupNotes = 'pickupNotes',
  DropoffNotes = 'dropoffNotes',
  AcceptedTime = 'acceptedTime',
  EstimatedTravelDistanceM = 'estimatedTravelDistanceM',
  InVehicleTripDistanceM = 'inVehicleTripDistanceM',
  EstimatedTravelDuration = 'estimatedTravelDuration',
  FixedRouteTravelDuration = 'fixedRouteTravelDuration',
  TripDurationSeconds = 'tripDurationSeconds',
  TripWaitSeconds = 'tripWaitSeconds',
  PickupLatenessSeconds = 'pickupLatenessSeconds',
  DropoffLatenessSeconds = 'dropoffLatenessSeconds',
  RelevantLatenessSeconds = 'relevantLatenessSeconds',
  Status = 'status',
  StatusChangeArrivingTime = 'statusChangeArrivingTime',
  PickupArrivedTime = 'pickupArrivedTime',
  StatusChangeInProgressTime = 'statusChangeInProgressTime',
  DropoffArrivedTime = 'dropoffArrivedTime',
  StatusChangeCompletedTime = 'statusChangeCompletedTime',
  StatusChangeCancelledTime = 'statusChangeCancelledTime',
  CancellationCancelledBy = 'cancellationCancelledBy',
  CancellationFault = 'cancellationFault',
  CancellationReason = 'cancellationReason',
  CancellationNotes = 'cancellationNotes',
  DeadheadToPickupM = 'deadheadToPickupM',
}

export enum RidershipReportRiderColumns {
  RiderId = 'riderId',
  RiderExternalNumericId = 'riderExternalNumericId',
  RiderName = 'riderName',
  NumRidersAdult = 'numRidersAdult',
  NumRidersInfant = 'numRidersInfant',
  NumRidersChild = 'numRidersChild',
  NumRidersYouth = 'numRidersYouth',
  NumRidersSenior = 'numRidersSenior',
  NumRidersStudent = 'numRidersStudent',
  NumRidersDisabled = 'numRidersDisabled',
  NumRidersVeteran = 'numRidersVeteran',
  NumRidersReduced = 'numRidersReduced',
  NumRidersPersonalCareAttendant = 'numRidersPersonalCareAttendant',
  NumRidersOther = 'numRidersOther',
}

export enum RidershipReportAccessibilityFeaturesColumns {
  AccessibilityBikeCount = 'accessibilityBikeCount',
  AccessibilityActualDoorToDoorCount = 'accessibilityActualDoorToDoorCount',
  AccessibilityChildSeatBabyCount = 'accessibilityChildSeatBabyCount',
  AccessibilityChildSeatYoungCount = 'accessibilityChildSeatYoungCount',
  AccessibilityLiftCount = 'accessibilityLiftCount',
  AccessibilityServiceAnimalCount = 'accessibilityServiceAnimalCount',
  AccessibilityStretcherCount = 'accessibilityStretcherCount',
  AccessibilityStrollerCount = 'accessibilityStrollerCount',
  AccessibilityWheelchairCount = 'accessibilityWheelchairCount',
  AccessibilityFoldingWheelchairCount = 'accessibilityFoldingWheelchairCount',
  AccessibilityOversizedWheelchairCount = 'accessibilityOversizedWheelchairCount',
  AccessibilityWalkerCount = 'accessibilityWalkerCount',
  AccessibilityWhiteCaneCount = 'accessibilityWhiteCaneCount',
  AccessibilityOnlyCarRiderCount = 'accessibilityOnlyCarRiderCount',
  AccessibilityExtraSpaceAmbulantCount = 'accessibilityExtraSpaceAmbulantCount',
  AccessibilityOversizedItemCount = 'accessibilityOversizedItemCount',
  AccessibilityOxygenTankCount = 'accessibilityOxygenTankCount',
  AccessibilityPoweredWheelchairCount = 'accessibilityPoweredWheelchairCount',
  AccessibilityOversizedScooterCount = 'accessibilityOversizedScooterCount',
  AccessibilityScooterCount = 'accessibilityScooterCount',
  AccessibilityOnlyCarWalkerCount = 'accessibilityOnlyCarWalkerCount',
  AccessibilityOversizedWalkerCount = 'accessibilityOversizedWalkerCount',
}

export enum RidershipReportDutyColumns {
  DutyId = 'dutyId',
  DutyIdentifier = 'dutyIdentifier',
}

export enum RidershipReportDriverColumns {
  DriverId = 'driverId',
  DriverName = 'driverName',
  DriverLocationOnMatchLat = 'driverLocationOnMatchLat',
  DriverLocationOnMatchLong = 'driverLocationOnMatchLong',
}

export enum RidershipReportVehicleColumns {
  VehicleLicensePlate = 'vehicleLicensePlate',
  VehicleIdentifier = 'vehicleIdentifier',
}

export enum RidershipReportServiceColumns {
  ServiceName = 'serviceName',
}

export enum RidershipReportFleetColumns {
  FleetId = 'fleetId',
  FleetName = 'fleetName',
  FleetType = 'fleetType',
}

export enum RidershipReportEstimateColumns {
  CreatedInterface = 'createdInterface',
  EstimateCreatedAtTime = 'estimateCreatedAtTime',
  EstimateIntentType = 'estimateIntentType',
  EstimateEndpoint = 'estimateEndpoint',
}

export enum RidershipReportZoneColumns {
  PickupZoneName = 'pickupZoneName',
  DropoffZoneName = 'dropoffZoneName',
}

export enum RidershipReportSlotColumns {
  PickupCompletedDistanceDeviationM = 'pickupCompletedDistanceDeviationM',
  DropoffCompletedDistanceDeviationM = 'dropoffCompletedDistanceDeviationM',
}

export enum RidershipReportFareColumns {
  FareCost = 'fareCost',
  FareDiscount = 'fareDiscount',
}

export enum RidershipReportFarePassColumns {
  FarePassNames = 'farePassNames',
  FarePassDiscounts = 'farePassDiscounts',
}

export enum RidershipReportPromotionColumns {
  PromoNames = 'promoNames',
  PromoDiscounts = 'promoDiscounts',
}

export enum RidershipReportTipColumns {
  Tip = 'tip',
  TipCreatedTime = 'tipCreatedTime',
}

export enum RidershipReportPaymentMethodColumns {
  PaymentMethodId = 'paymentMethodId',
  PaymentMethodBrand = 'paymentMethodBrand',
  paymentMethodLast4Digits = 'paymentMethodLast4Digits',
  PaymentMethodType = 'paymentMethodType',
}

export enum RidershipReportChargeColumns {
  ChargeExternalIds = 'chargeExternalIds',
}

export enum RidershipReportReviewColumns {
  RiderRating = 'riderRating',
  RiderReviewTags = 'riderReviewTags',
  RiderComment = 'riderComment',
}

/**
 * Models that are included in the Ridership Report
 *
 * The order here controls the order of models on the 'Edit Columns' modal
 */
export const RidershipReportModelNamesArray = [
  AllReportsModels.Request,
  AllReportsModels.Rider,
  AllReportsModels.AccessibilityFeatures,
  AllReportsModels.Duty,
  AllReportsModels.Driver,
  AllReportsModels.Vehicle,
  AllReportsModels.Fleet,
  AllReportsModels.Estimate,
  AllReportsModels.Service,
  AllReportsModels.Zone,
  AllReportsModels.Slot,
  AllReportsModels.Fare,
  AllReportsModels.FarePass,
  AllReportsModels.Promotion,
  AllReportsModels.Tip,
  AllReportsModels.PaymentMethod,
  AllReportsModels.Review,
  AllReportsModels.Charge,
]

export type IRidershipReportColumns =
  | RidershipReportRequestColumns
  | RidershipReportRiderColumns
  | RidershipReportAccessibilityFeaturesColumns
  | RidershipReportDutyColumns
  | RidershipReportDriverColumns
  | RidershipReportVehicleColumns
  | RidershipReportFleetColumns
  | RidershipReportEstimateColumns
  | RidershipReportServiceColumns
  | RidershipReportZoneColumns
  | RidershipReportSlotColumns
  | RidershipReportFareColumns
  | RidershipReportFarePassColumns
  | RidershipReportPromotionColumns
  | RidershipReportTipColumns
  | RidershipReportPaymentMethodColumns
  | RidershipReportReviewColumns
  | RidershipReportChargeColumns

export const RidershipReportModelAndColumns = {
  [AllReportsModels.Request]: RidershipReportRequestColumns,
  [AllReportsModels.Rider]: RidershipReportRiderColumns,
  [AllReportsModels.AccessibilityFeatures]: RidershipReportAccessibilityFeaturesColumns,
  [AllReportsModels.Duty]: RidershipReportDutyColumns,
  [AllReportsModels.Driver]: RidershipReportDriverColumns,
  [AllReportsModels.Vehicle]: RidershipReportVehicleColumns,
  [AllReportsModels.Fleet]: RidershipReportFleetColumns,
  [AllReportsModels.Estimate]: RidershipReportEstimateColumns,
  [AllReportsModels.Service]: RidershipReportServiceColumns,
  [AllReportsModels.Zone]: RidershipReportZoneColumns,
  [AllReportsModels.Slot]: RidershipReportSlotColumns,
  [AllReportsModels.Fare]: RidershipReportFareColumns,
  [AllReportsModels.FarePass]: RidershipReportFarePassColumns,
  [AllReportsModels.Promotion]: RidershipReportPromotionColumns,
  [AllReportsModels.Tip]: RidershipReportTipColumns,
  [AllReportsModels.PaymentMethod]: RidershipReportPaymentMethodColumns,
  [AllReportsModels.Review]: RidershipReportReviewColumns,
  [AllReportsModels.Charge]: RidershipReportChargeColumns,
}

export const RidershipReportColumnNames = Object.values<IRidershipReportColumns>(RidershipReportRequestColumns)
  .concat(Object.values(RidershipReportRiderColumns))
  .concat(Object.values(RidershipReportAccessibilityFeaturesColumns))
  .concat(Object.values(RidershipReportDutyColumns))
  .concat(Object.values(RidershipReportDriverColumns))
  .concat(Object.values(RidershipReportVehicleColumns))
  .concat(Object.values(RidershipReportFleetColumns))
  .concat(Object.values(RidershipReportEstimateColumns))
  .concat(Object.values(RidershipReportServiceColumns))
  .concat(Object.values(RidershipReportZoneColumns))
  .concat(Object.values(RidershipReportSlotColumns))
  .concat(Object.values(RidershipReportFareColumns))
  .concat(Object.values(RidershipReportFarePassColumns))
  .concat(Object.values(RidershipReportPromotionColumns))
  .concat(Object.values(RidershipReportTipColumns))
  .concat(Object.values(RidershipReportPaymentMethodColumns))
  .concat(Object.values(RidershipReportReviewColumns))
  .concat(Object.values(RidershipReportChargeColumns))
