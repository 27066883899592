import { HOUR, MINUTE } from '@sparelabs/time'

export class BumpingConstraintCalculator {
  /**
   * If a rider is scheduled to be picked up in less than 7 minutes,
   * prevent that pickup time from being rescheduled to be earlier
   */
  public static PICKUP_TIME_LOCKED_INTO_PAST_MARGIN_S: number = 7 * MINUTE

  /**
   * If a rider is scheduled to be picked up in less than 2 minutes,
   * prevent that pickup time from being rescheduled into the future
   */
  public static PICKUP_TIME_LOCKED_INTO_FUTURE_MARGIN_S: number = 2 * MINUTE

  public static earliestSafeReschedulablePickupTs(scheduledTs: number, nowTs: number): number {
    const timeTillScheduled = scheduledTs - nowTs

    if (timeTillScheduled < this.PICKUP_TIME_LOCKED_INTO_PAST_MARGIN_S) {
      return scheduledTs
    } else if (timeTillScheduled < 2 * HOUR) {
      // If a scheduled pickup time is within 2 hours, restrict the amount by which
      // it can be moved earlier (in proportion to how soon it is scheduled for)
      return scheduledTs - Math.round(timeTillScheduled / 6)
    }

    // If none of the conditions above is hit, then there is no restriction on
    // moving scheduled times to be earlier
    return -Infinity
  }

  public static latestSafeReschedulablePickupTs(scheduledTs: number, nowTs: number): number {
    const timeTillScheduled = scheduledTs - nowTs

    if (timeTillScheduled < this.PICKUP_TIME_LOCKED_INTO_FUTURE_MARGIN_S) {
      return scheduledTs
    }

    // If none of the conditions above is hit, then there is no restriction on
    // moving scheduled times to be later
    return Infinity
  }
}
