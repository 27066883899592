import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IPleoLeadCreationBody, Resources } from '../types'

export class PleoResource extends GenericResource<never, never, IPleoLeadCreationBody, never> {
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.Pleo, agent)
  }

  public async createRecord(body: IPleoLeadCreationBody): Promise<void> {
    await this.agent.post<IPleoLeadCreationBody>('/pleo/createLead', body)
  }
}
