import { BadRequestError } from '@sparelabs/error-types'
import { RandomClass } from '@sparelabs/random'
import turfArea from '@turf/area'
import turfBearing from '@turf/bearing'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import buffer from '@turf/buffer'
// @ts-ignore
import centroid from '@turf/centroid'
// @ts-ignore
import circle from '@turf/circle'
import turfDestination from '@turf/destination'
// @ts-ignore
import turfDistance from '@turf/distance'
import flatten from '@turf/flatten'
import { BBox, MultiPolygon, Polygon, polygon } from '@turf/helpers'
import length from '@turf/length'
import lineSlice from '@turf/line-slice'
// @ts-ignore
import nearestPointOnLine from '@turf/nearest-point-on-line'
// @ts-ignore
import pointToLineDistance from '@turf/point-to-line-distance'
import { polygonToLine } from '@turf/polygon-to-line'
import { randomPoint } from '@turf/random'
import simplify from '@turf/simplify'
import truncate from '@turf/truncate'
import * as turf from '@turf/turf'
import union from '@turf/union'
import deepEqual from 'deep-equal'
import { isEqual } from 'lodash'
import ngeohash from 'ngeohash'
// @ts-ignore
import extent from 'turf-extent'
import {
  GeoJsonCoordinate,
  GeometryType,
  IGeometry,
  ILineString,
  IMultiPoint,
  IPoint,
  IPolygon,
} from './GeographyTypes'
// eslint-disable-next-line import/no-cycle
import { buildPoint } from './GeometryBuilder'

const TURF_UNITS = 'meters'
const MINIMUM_SIMPLIFIED_GEOMETRY_SIZE: number = 1024
const SIMPLIFICATION_TOLERANCE = 0.0001

// We use 4326 which is the SRID for the WGS84 spatial reference system, a global standard for geography.
// This is especially useful when dealing with geo data and operations such as calculating
// distances between points or determining whether a point lies within a certain area.
export const GEOMETRY_SRID: number = 4326

interface INearestPointResult {
  closestPoint: IPoint
  index: number
  distance: number
}

export interface IRandomPointOptions {
  finenessInMeters: number
  random: RandomClass
}

export class Geography {
  public static area(polygon: IPolygon): number {
    return turfArea(polygon)
  }

  // Crow flies distance, in meters, between 2 points
  public static distance(point1: IPoint, point2: IPoint): number {
    return Math.round(turfDistance(point1, point2, { units: TURF_UNITS }))
  }

  public static distanceToPolygon(point: IPoint, polygon: IPolygon): number {
    return this.inside(point, polygon)
      ? 0
      : Math.min(
          ...flatten(polygonToLine(polygon).geometry).features.map((feature) =>
            pointToLineDistance(point, feature, { units: TURF_UNITS })
          )
        )
  }

  public static inside(point: IPoint, polygon: IPolygon) {
    return booleanPointInPolygon(point, polygon)
  }

  public static isPointValid(point: IPoint): boolean {
    return this.areCoordinatesValid(point.coordinates)
  }

  public static isMultiPointValid(multiPoint: IMultiPoint): boolean {
    return multiPoint.coordinates.every((coordinates) => this.areCoordinatesValid(coordinates))
  }

  public static isLineStringValid(lineString: ILineString): boolean {
    return lineString.coordinates.every((coordinates) => this.areCoordinatesValid(coordinates))
  }

  public static multiPointToPointArray(multiPoint: IMultiPoint): IPoint[] {
    return multiPoint.coordinates.map((coordinates) => buildPoint(...coordinates))
  }

  public static pointArrayToMultiPoint(points: IPoint[]): IMultiPoint {
    return {
      type: GeometryType.MultiPoint,
      coordinates: points.map((point) => point.coordinates),
    }
  }

  public static isPolygonValid(polygon: IPolygon): boolean {
    return polygon.coordinates.every((points) => points.every((coordinates) => this.areCoordinatesValid(coordinates)))
  }

  public static isPolygonComplete(polygon: IPolygon): boolean {
    return Boolean(
      polygon &&
        polygon.coordinates[0] &&
        polygon.coordinates[0].length >= 3 &&
        deepEqual(polygon.coordinates[0][0], polygon.coordinates[0][polygon.coordinates[0].length - 1])
    )
  }

  // tolerance is allowed distance (in TURF_UNITS) between point1 and point2 to still be considered equal.
  public static arePointsEqual(point1: IPoint, point2: IPoint, tolerance?: number): boolean {
    if (tolerance) {
      return this.distance(point1, point2) <= tolerance
    }
    return this.geohash(point1) === this.geohash(point2)
  }

  public static geohash(point: IPoint): string {
    return ngeohash.encode(point.coordinates[1], point.coordinates[0])
  }

  public static generateRandPointInside(polygon: IPolygon): IPoint {
    const bbox = extent(polygon) as BBox
    let point = randomPoint(1, { bbox })
    while (!this.inside(point.features[0].geometry as IPoint, polygon)) {
      point = randomPoint(1, { bbox })
    }
    return point.features[0].geometry as IPoint
  }

  public static simplify<T extends IGeometry>(geometry: T): T {
    if (JSON.stringify(geometry).length < MINIMUM_SIMPLIFIED_GEOMETRY_SIZE) {
      return geometry
    }

    return simplify(geometry, { tolerance: SIMPLIFICATION_TOLERANCE, highQuality: true })
  }

  public static truncate<T extends IGeometry>(geometry: T, options: { decimalPlaces?: number } = {}): T {
    const turfOptions = {
      precision: options.decimalPlaces,
    }
    const defaultTurfOptions = { precision: 6 }
    return truncate(geometry, { ...defaultTurfOptions, ...turfOptions })
  }

  public static centroid(polygon: IPolygon): IPoint {
    return centroid(polygon).geometry
  }

  /**
   * Calculates the centroid and radius of a list of polygons
   */
  public static centroidOfPolygons(polygons: IPolygon[]): { centroid: IPoint; radius: number } {
    const multiPolygon = turf.multiPolygon(polygons.map((polygon) => polygon.coordinates))
    const bbox = turf.bbox(multiPolygon)
    const centroid = turf.centroid(multiPolygon).geometry as IPoint

    const corners = [
      [bbox[0], bbox[1]], // Bottom left
      [bbox[0], bbox[3]], // Top left
      [bbox[2], bbox[1]], // Bottom right
      [bbox[2], bbox[3]], // Top right
    ]
    let maxDistance = 0
    for (const corner of corners) {
      const distance = this.distance(centroid, buildPoint(corner[0], corner[1]))
      if (distance > maxDistance) {
        maxDistance = distance
      }
    }
    return { centroid, radius: maxDistance }
  }

  public static lineSlice(polyline: ILineString, start?: IPoint, end?: IPoint): ILineString {
    if (polyline.coordinates.length === 0) {
      return polyline
    }
    if (!start) {
      start = {
        type: GeometryType.Point,
        coordinates: polyline.coordinates[0],
      }
    }
    if (!end) {
      end = {
        type: GeometryType.Point,
        coordinates: polyline.coordinates[polyline.coordinates.length - 1],
      }
    }
    return lineSlice(start, end, polyline).geometry as ILineString
  }

  public static polylineLength(polyline: ILineString): number {
    return Math.round(length({ type: 'Feature', geometry: polyline } as any, { units: TURF_UNITS }))
  }

  public static nearestPointOnLine(polyline: ILineString, point: IPoint): INearestPointResult {
    const res = nearestPointOnLine(polyline, point, { units: TURF_UNITS })
    return {
      closestPoint: res.geometry,
      index: res.properties.index,
      distance: res.properties.dist,
    }
  }

  public static bearing(point1: IPoint, point2: IPoint): number | null {
    return isEqual(point1, point2) ? null : turfBearing(point1, point2)
  }

  public static destination(start: IPoint, distanceM: number, bearing: number): IPoint {
    const feature = turfDestination(start, distanceM, bearing, { units: TURF_UNITS })
    return feature.geometry as IPoint
  }

  public static circle(center: IPoint, radiusM: number): IPolygon {
    return circle(center, radiusM / 1000, { units: 'kilometers' }).geometry
  }

  /**
   * Calculates a buffer for input features for a given radius
   */
  public static buffer<T extends ILineString | IPolygon | IPoint>(
    feature: T,
    radius: number,
    units: 'meters' | 'kilometers' | 'miles' | 'feet'
  ): IPolygon {
    return buffer(feature, radius, { units }).geometry as IPolygon
  }

  public static union(polygons: IPolygon[]): IPolygon[] {
    if (polygons.length === 0) {
      return []
    }

    const result = polygons.reduce(
      (mergedPolygon, singlePolygon) =>
        truncate(union(mergedPolygon, truncate(singlePolygon))?.geometry ?? mergedPolygon),
      polygon([]).geometry as Polygon | MultiPolygon
    )

    return (
      result.type === GeometryType.Polygon ? [result] : result.coordinates.map((coordinate) => polygon(coordinate))
    ) as IPolygon[]
  }

  public static buildBoundaryFromCoordinates(coordinates: Array<{ lat: number; lon: number }>): IPolygon {
    const feature = turf.convex(
      turf.featureCollection(coordinates.map((coordinate) => turf.feature(buildPoint(coordinate.lon, coordinate.lat))))
    )

    if (!feature) {
      throw new BadRequestError(() => 'Failed to calculate boundary from the provided list of stops', { coordinates })
    }

    return feature.geometry as IPolygon
  }

  private static areCoordinatesValid(coordinates: GeoJsonCoordinate): boolean {
    const [longitude, latitude] = coordinates
    return -180 <= longitude && longitude <= 180 && -90 <= latitude && latitude <= 90
  }
}
