import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { NotificationDeliveryMethod } from './NotificationSettingTypes'

export enum NotificationAnalyticsStatus {
  Sending = 'sending',
  Failed = 'failed',
  Delivered = 'delivered',
}

export interface INotificationAnalyticsData {
  errorCode?: string
  errorMessage?: string
  info?: string
}
export interface INotificationAnalyticsResponse extends IModelJsonValues {
  bulkNotificationId: string
  riderId: string
  type: NotificationDeliveryMethod
  externalId: string | null
  status: NotificationAnalyticsStatus
  externalData: INotificationAnalyticsData
}

export interface INotificationAnalyticsListQueryParams extends IListModelQueryParamValues {
  bulkNotificationId?: string
  status?: NotificationAnalyticsStatus
  type?: NotificationDeliveryMethod
}

export interface IGetBulkNotificationStatusCountsQueryParams {
  type?: NotificationDeliveryMethod
}

export interface IBulkNotificationStatusCounts {
  all: number
  sendingCount: number
  deliveredCount: number
  failedCount: number
}

export interface IBulkNotificationChannelStatusCountsResponse {
  total: IBulkNotificationStatusCounts
  sms: IBulkNotificationStatusCounts
  phoneCall: IBulkNotificationStatusCounts
  email: IBulkNotificationStatusCounts
  push: IBulkNotificationStatusCounts
}
