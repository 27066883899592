import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum RequestPermissionSet {
  Estimate = 'request_estimate',
  EstimateWithOverrides = 'request_estimate_with_overrides',
  CreateUnmatchedRequest = 'request_create_unmatched',
  View = 'request_view',
  ViewDiscounts = 'request_view_discounts',
  ViewRequestPredictions = 'request_view_request_predictions',
  ViewConstraintOverrides = 'request_view_constraint_overrides',
  ViewDailyCounts = 'request_view_daily_counts',
  Write = 'request_write',
  Rematch = 'request_rematch',
  ManualIntervention = 'request_manual_intervention',
  ManualInterventionSalesInfo = 'request_manual_intervention_sales_info', // "Sales info" aka upsell.
  ExportRequestRecurrences = 'request_export_request_recurrences',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const requestPermissionSetGrouping: IPermissionSetGrouping<RequestPermissionSet> = {
  key: PermissionSetGroupingKey.Request,
  active: {
    [RequestPermissionSet.Estimate]: [
      PermissionType.GET_ESTIMATE_SERVICES,
      PermissionType.GET_ESTIMATE_SERVICES_FROM,
      PermissionType.GET_ESTIMATE_SERVICES_TO,
      PermissionType.GET_SERVICE_AVAILABILITY,
      PermissionType.GET_ANY_ESTIMATE_REQUEST,
    ],
    [RequestPermissionSet.EstimateWithOverrides]: [
      PermissionType.GET_ANY_ESTIMATE_REQUEST_WITH_OVERRIDES
    ],
    [RequestPermissionSet.CreateUnmatchedRequest]: [
      PermissionType.CAN_CREATE_ANY_UNMATCHED_REQUEST
    ],
    [RequestPermissionSet.View]: [
      PermissionType.LIST_ANY_REQUEST,
      PermissionType.GET_ANY_REQUEST,
      PermissionType.GET_ANY_REQUEST_VEHICLE,
      PermissionType.GET_ANY_REQUEST_SLOTS,
      PermissionType.GET_ANY_LAST_REQUEST_VL,
      PermissionType.GET_ANY_REQUEST_RECURRENCE,
      PermissionType.GET_ANY_REQUEST_INSIGHTS,
      PermissionType.LIST_ANY_OPEN_FLEET_RECEIPT,
      PermissionType.GET_ANY_OPEN_FLEET_RECEIPT,
      PermissionType.GET_ANY_REQUEST_PAYMENT_OPTIONS,
    ],
    [RequestPermissionSet.ViewDiscounts]: [
      PermissionType.LIST_ANY_FARE_REDEMPTION
    ],
    [RequestPermissionSet.Write]: [
      PermissionType.POST_ANY_REQUEST,
      PermissionType.PATCH_ANY_REQUEST,
      PermissionType.IMPORT_ANY_REQUEST,
      PermissionType.POST_ANY_REQUEST_CANCELLATION,
      PermissionType.WRITE_ANY_REQUEST_RECURRENCE,
      PermissionType.IMPORT_ANY_REQUEST_RECURRENCE,
      PermissionType.POST_ANY_REQUEST_FARE_ESTIMATE,
      PermissionType.GET_GEOCODE,
    ],
    [RequestPermissionSet.Rematch]: [
      PermissionType.POST_ANY_REQUEST_REMATCH
    ],
    [RequestPermissionSet.ManualIntervention]: [
      PermissionType.POST_ANY_REQUEST_NO_SHOW_REVERSAL,
      PermissionType.POST_ANY_REQUEST_EDIT_DROPOFF,
      PermissionType.POST_ANY_REQUEST_UNMATCH,
      PermissionType.POST_ANY_REQUEST_REVERSE_PICKUP,
      PermissionType.POST_ANY_REQUEST_REVERSE_DROPOFF,
      PermissionType.POST_ANY_REQUEST_FORCE_MATCH,
      PermissionType.COMPLETE_ANY_SLOT,
      PermissionType.POST_ANY_REQUEST_REVERSE_CANCELLATION,
    ],
    [RequestPermissionSet.ManualInterventionSalesInfo]: [
      PermissionType.POST_ANY_PLEO_CREATE_LEAD_SCHEDULER,
    ],
    [RequestPermissionSet.ExportRequestRecurrences]: [
      PermissionType.EXPORT_ANY_REQUEST_RECURRENCE
    ],
    [RequestPermissionSet.ViewRequestPredictions]: [
      PermissionType.GET_ANY_REQUEST_PREDICTIONS
    ],
    [RequestPermissionSet.ViewConstraintOverrides]: [
      PermissionType.GET_ANY_CONSTRAINT_OVERRIDE_ACTION,
      PermissionType.GET_ANY_REQUEST_CONSTRAINT_OVERRIDE,
    ],
    [RequestPermissionSet.ViewDailyCounts]: [PermissionType.GET_ANY_DAILY_REQUEST_COUNTS]
  },
  deprecated: [],
  unAssignablePermissionSets: [
    RequestPermissionSet.ManualInterventionSalesInfo
  ],
}
