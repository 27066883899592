import { PermissionType } from './PermissionType'
import { AccessToAnalyticsRole } from './roles/AccessToAnalyticsRole'
import { AccessToEngageReportsRole } from './roles/AccessToEngageReportsRole'
import { AccessToEngageRole } from './roles/AccessToEngageRole'
import { AccessToLaunchReportsRole } from './roles/AccessToLaunchReportsRole'
import { AccessToRealizeRole } from './roles/AccessToRealizeRole'
import { AdministratorRole } from './roles/AdministratorRole'
import { BookingAgentRole } from './roles/BookingAgentRole'
import {
  CapMetroCallCenterRole,
  CapMetroOfficeRole,
  CapMetroReadOnlyRole,
  CapMetroServiceCoordinatorRole,
  CapMetroServiceProviderRole,
  CapMetroSuperUserRole,
} from './roles/CapMetro'
import { DefaultSuperAdminRole } from './roles/DefaultSuperAdminRole'
import { DeveloperRole } from './roles/DeveloperRole'
import { DriverRole } from './roles/DriverRole'
import { EngineeringLeadRole } from './roles/EngineeringLeadRole'
import { FinanceManagerRole } from './roles/FinanceManagerRole'
import { OperationsManagerRole } from './roles/OperationsManagerRole'
import { PartnerSuccessRole } from './roles/PartnerSuccessRole'
import { RiderRole } from './roles/RiderRole'
import { RootSuperAdminRole } from './roles/RootSuperAdminRole'
import { SchedulerRole } from './roles/SchedulerRole'
import { ServicePlannerRole } from './roles/ServicePlannerRole'
import { SiteReliabilityEngineerRole } from './roles/SiteReliabilityEngineerRole'
import { TestSuperAdminRole } from './roles/TestSuperAdminRole'
import { UserOnlyTokenRole } from './roles/UserOnlyTokenRole'
import { AdminRole, Role, SuperAdminRole, VirtualRole } from './RoleTypes'
import { PermissionSetType } from './sets'
import { convertRBACRoleToPermissionType } from './utils'

export type PermissionMapType = Record<Role, PermissionType[]>
export type RBACPermissionMapType = Record<Role, Array<PermissionType | PermissionSetType>>

export const PermissionMap: PermissionMapType = {
  [AdminRole.Administrator]: convertRBACRoleToPermissionType(AdministratorRole),
  [AdminRole.Scheduler]: convertRBACRoleToPermissionType(SchedulerRole),
  [AdminRole.BookingAgent]: convertRBACRoleToPermissionType(BookingAgentRole),
  [AdminRole.OperationsManager]: convertRBACRoleToPermissionType(OperationsManagerRole),
  [AdminRole.FinanceManager]: convertRBACRoleToPermissionType(FinanceManagerRole),
  [AdminRole.ServicePlanner]: convertRBACRoleToPermissionType(ServicePlannerRole),
  [AdminRole.AccessToLaunchReports]: convertRBACRoleToPermissionType(AccessToLaunchReportsRole),
  [AdminRole.AccessToEngageReports]: convertRBACRoleToPermissionType(AccessToEngageReportsRole),
  [AdminRole.AccessToAnalytics]: convertRBACRoleToPermissionType(AccessToAnalyticsRole),
  [AdminRole.AccessToRealize]: convertRBACRoleToPermissionType(AccessToRealizeRole),
  [AdminRole.AccessToEngage]: convertRBACRoleToPermissionType(AccessToEngageRole),
  [AdminRole.Developer]: convertRBACRoleToPermissionType(DeveloperRole),
  [SuperAdminRole.Root]: convertRBACRoleToPermissionType(RootSuperAdminRole),
  [SuperAdminRole.SiteReliabilityEngineer]: convertRBACRoleToPermissionType(SiteReliabilityEngineerRole),
  [SuperAdminRole.EngineeringLead]: convertRBACRoleToPermissionType(EngineeringLeadRole),
  [SuperAdminRole.PartnerSuccess]: convertRBACRoleToPermissionType(PartnerSuccessRole),
  [SuperAdminRole.Default]: convertRBACRoleToPermissionType(DefaultSuperAdminRole),
  [SuperAdminRole.Testing]: convertRBACRoleToPermissionType(TestSuperAdminRole),
  [VirtualRole.Driver]: convertRBACRoleToPermissionType(DriverRole),
  [VirtualRole.Rider]: convertRBACRoleToPermissionType(RiderRole),
  [VirtualRole.UserOnlyToken]: convertRBACRoleToPermissionType(UserOnlyTokenRole),
  /**
   * Roles specific to the organization CapMetro
   */
  [AdminRole.CapMetroCallCenter]: convertRBACRoleToPermissionType(CapMetroCallCenterRole),
  [AdminRole.CapMetroOffice]: convertRBACRoleToPermissionType(CapMetroOfficeRole),
  [AdminRole.CapMetroReadOnly]: convertRBACRoleToPermissionType(CapMetroReadOnlyRole),
  [AdminRole.CapMetroServiceCoordinator]: convertRBACRoleToPermissionType(CapMetroServiceCoordinatorRole),
  [AdminRole.CapMetroServiceProvider]: convertRBACRoleToPermissionType(CapMetroServiceProviderRole),
  [AdminRole.CapMetroSuperUser]: convertRBACRoleToPermissionType(CapMetroSuperUserRole),
}

export const RBACPermissionMap: RBACPermissionMapType = {
  [AdminRole.Administrator]: AdministratorRole,
  [AdminRole.Scheduler]: SchedulerRole,
  [AdminRole.BookingAgent]: BookingAgentRole,
  [AdminRole.OperationsManager]: OperationsManagerRole,
  [AdminRole.FinanceManager]: FinanceManagerRole,
  [AdminRole.ServicePlanner]: ServicePlannerRole,
  [AdminRole.AccessToLaunchReports]: AccessToLaunchReportsRole,
  [AdminRole.AccessToEngageReports]: AccessToEngageReportsRole,
  [AdminRole.AccessToAnalytics]: AccessToAnalyticsRole,
  [AdminRole.AccessToRealize]: AccessToRealizeRole,
  [AdminRole.AccessToEngage]: AccessToEngageRole,
  [AdminRole.Developer]: DeveloperRole,
  [SuperAdminRole.Root]: RootSuperAdminRole,
  [SuperAdminRole.SiteReliabilityEngineer]: SiteReliabilityEngineerRole,
  [SuperAdminRole.EngineeringLead]: EngineeringLeadRole,
  [SuperAdminRole.PartnerSuccess]: PartnerSuccessRole,
  [SuperAdminRole.Default]: DefaultSuperAdminRole,
  [SuperAdminRole.Testing]: TestSuperAdminRole,
  [VirtualRole.Driver]: DriverRole,
  [VirtualRole.Rider]: RiderRole,
  [VirtualRole.UserOnlyToken]: UserOnlyTokenRole,
  /**
   * Roles specific to the organization CapMetro
   */
  [AdminRole.CapMetroCallCenter]: CapMetroCallCenterRole,
  [AdminRole.CapMetroOffice]: CapMetroOfficeRole,
  [AdminRole.CapMetroReadOnly]: CapMetroReadOnlyRole,
  [AdminRole.CapMetroServiceCoordinator]: CapMetroServiceCoordinatorRole,
  [AdminRole.CapMetroServiceProvider]: CapMetroServiceProviderRole,
  [AdminRole.CapMetroSuperUser]: CapMetroSuperUserRole,
}
