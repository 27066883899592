export class NumericMap<T = string> {
  private readonly map = new Map<T, number>()
  constructor(initializerList?: Array<[T, number]>) {
    if (initializerList) {
      for (const [key, value] of initializerList) {
        this.add(key, value)
      }
    }
  }

  public add(key: T, value: number): NumericMap<T> {
    const previousValue = this.getValueOrZero(key)
    const newValue = previousValue + value
    this.map.set(key, newValue)
    return this
  }

  public subtract(key: T, value: number): NumericMap<T> {
    const previousValue = this.getValueOrZero(key)
    const newValue = previousValue - value
    this.map.set(key, newValue)
    return this
  }

  public increment(key: T): NumericMap<T> {
    return this.add(key, 1)
  }

  public decrement(key: T): NumericMap<T> {
    return this.add(key, -1)
  }

  public getValueOrZero(key: T): number {
    const value = this.map.get(key)
    return value || 0
  }

  public asMap(): Map<T, number> {
    return this.map
  }
}
