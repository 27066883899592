import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { ICaseFormPatchBody, ICaseFormPostBody, ICaseFormResponse, IListCaseFormQueryParams } from '../types'
import { Resources } from './ResourcesType'

export class CaseFormResource extends GenericResource<
  ICaseFormResponse,
  IListCaseFormQueryParams,
  ICaseFormPatchBody,
  ICaseFormPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  constructor(agent: AxiosInstance) {
    super(Resources.CaseForms, agent)
  }
}
