import { DutyStatus } from '@sparelabs/domain-logic'
import { ILineString, IPoint } from '@sparelabs/geography'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { DeviceOS } from './DeviceTypes'
import { IDutyScheduleLeg } from './RouteOptimizationTypes'
import { IUserStub } from './UserTypes'
import { IVehicleStub } from './VehicleTypes'
/**
 * @deprecated use from @sparelabs/domain-logic instead
 */
export { DutyStatus }

export interface IRouteAssignmentInput {
  routeId: string
  startTs: number
}

export interface IDutiesPostBody {
  data: IDutyPostBody[]
}

export interface IDutyPostBody {
  fleetId: string
  identifier?: string
  driverId?: string
  vehicleId: string
  startRequestedTs?: number
  startFlexibility?: number
  startLocation?: IPoint
  startAddress?: string
  endRequestedTs?: number
  endFlexibility?: number
  endLocation?: IPoint
  endAddress?: string
  matchingLogic?: DutyMatchingLogic
  status?: DutyStatus
  ownerType?: DutyOwnerType
  routeAssignments?: IRouteAssignmentInput[]
  metadata?: Record<string, unknown>
}

export interface IDutyPatchBodySentToRos {
  vehicleId?: string
  startRequestedTs?: number
  startLocation?: IPoint
  startAddress?: string
  endRequestedTs?: number
  endAddress?: string
  endLocation?: IPoint
}

export interface IDutyPatchBody extends IDutyPatchBodySentToRos {
  identifier?: string | null
  driverId?: string | null
  isMatchingEnabled?: boolean
  matchingDisabledEndTs?: number | null
  metadata?: Record<string, unknown>
}

export enum DutyOwnerType {
  Organization = 'organization',
  User = 'user',
}

export enum DutyMatchingLogic {
  AutoAccept = 'autoAccept',
  AcceptReject = 'acceptReject',
}

export interface IDutyExternalDriverData {
  firstName: string | null
  photoUrl: string | null
  phoneNumber: string | null
}

export interface IDutyResponse extends IModelJsonValues {
  identifier?: string | null
  vehicleId: string
  driverId: string | null
  fleetId: string
  startRequestedTs: number
  endRequestedTs?: number
  startLocation: IPoint | null
  endLocation: IPoint | null
  startAddress: string | null
  endAddress: string | null
  startFlexibility: number
  endFlexibility: number
  status: DutyStatus
  matchingLogic: DutyMatchingLogic
  isDeletable: boolean
  isMatchingEnabled: boolean
  matchingDisabledEndTs: number | null
  lateness: number | null
  ownerType: DutyOwnerType
  externalDriverData: IDutyExternalDriverData | null
  appVersion: string | null
  deviceOS: DeviceOS | null
  metadata: Record<string, unknown>
  metrics: IDutyPrecomputedMetricsData | null
  scheduleLegs: IDutyScheduleLeg[]
}

export interface IDutyPrecomputedMetricsData {
  firstSeenInServiceAreaTs: number | null
  lastSeenInServiceAreaTs: number | null
  totalRevenueHoursS: number
  totalBreakLengthS: number
  totalInProgressPauseLengthS: number
  totalTravelDistanceM: number
  totalDistanceWithoutRidersM: number
  totalRevenueDistanceM: number
  totalBreakDistanceM: number
}

export interface IScheduledDutyResponse extends IDutyResponse {
  endRequestedTs: number
}

export interface IDutyQueryParams extends IListModelQueryParamValues {
  fromStartRequestedTs?: number
  toStartRequestedTs?: number
  fromEndRequestedTs?: number
  toEndRequestedTs?: number
  withinRangeFromTs?: number
  withinRangeToTs?: number
  driverId?: string
  vehicleId?: string
  status?: DutyStatus
  fleetId?: string
  external?: boolean
  metadata?: Record<string, unknown>
}

export interface IDutyLatenessResponse {
  id: string
  createdAt: number
  dutyId: string
  lateness: number
}

export interface IDutyStubResponse
  extends Pick<
    IDutyResponse,
    'fleetId' | 'startRequestedTs' | 'endRequestedTs' | 'isMatchingEnabled' | 'matchingDisabledEndTs' | 'identifier'
  > {
  driverStub: IUserStub | null
  vehicleStub: IVehicleStub
  dutyId: string
  externalDriverData: IDutyExternalDriverData | null
}

export interface IDutyStubQueryParams extends IListModelQueryParamValues {
  status?: DutyStatus
}

export interface IDutyPolylineResponse {
  polyline: ILineString | null
}

export interface IDutyPolylineQueryParams {
  nextSlotOnly?: boolean
}

export type IDutiesSearchQueryParams = IDutiesSearchStringQueryParams | IDutiesSearchIdQueryParams

export interface IDutiesSearchStringQueryParams {
  search: string
  fromStartRequestedTs: number
  toStartRequestedTs: number
  limit?: number
}
export interface IDutiesSearchIdQueryParams {
  ids: string[]
  limit?: number
}

export interface IDutySearchResponseItem {
  id: string
  identifier?: string | null
  driverId: string | null
  vehicleId: string
  fleetId: string
  status: DutyStatus
  startLocation: IPoint | null
  startAddress: string | null
  startRequestedTs: number
  endLocation: IPoint | null
  endAddress: string | null
  endRequestedTs: number | null
  driverFirstName: string | null
  driverLastName: string | null
  driverPhoneNumber: string | null
  vehicleIdentifier: string
  vehicleMake: string
  vehicleModel: string
  vehicleColor: string
  vehicleLicensePlate: string
  fleetName: string
}

export interface IDutiesSearchResponse {
  data: IDutySearchResponseItem[]
}

export enum DriverActivity {
  Boarding = 'boarding',
  Transporting = 'transporting',
  Deadheading = 'deadheading',
  OnBreak = 'onBreak',
  Idle = 'idle',
}
