import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IStopListQueryParams, IStopPostBody, IStopResponse, Resources } from '../types'

export class StopResource extends GenericResource<
  IStopResponse,
  IStopListQueryParams,
  Partial<IStopPostBody>,
  IStopPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  public del = this.buildDel()
  constructor(agent: AxiosInstance) {
    super(Resources.Stops, agent)
  }
}
