import { IGlobalOrganizationResponse } from '@sparelabs/api-client'
import { WebColors } from '@sparelabs/colors'
import React from 'react'
import styled from 'styled-components'
import { Container, FullWidthContainer } from '../Layout'

const HeaderContainer = styled(FullWidthContainer)`
  background-color: ${WebColors.backgroundPrimary};
  border-bottom: 1px solid ${WebColors.borderPrimary};
`

const HeaderContent = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  color: ${WebColors.contentSecondary};
  font-size: 15px;
`

const HeaderTitle = styled.div`
  color: ${WebColors.contentPrimary};
  font-size: 21px;
`

const HeaderLogo = styled.img`
  max-height: 40px;
`

interface IHeaderProps {
  title: string
  subtitle: string
  organization?: IGlobalOrganizationResponse
}

export const Header = ({ title, subtitle, organization }: IHeaderProps): JSX.Element => (
  <HeaderContainer>
    <HeaderContent>
      <div>
        <HeaderTitle>{title}</HeaderTitle>
        <div>{subtitle}</div>
      </div>

      {organization?.logoUrl && <HeaderLogo src={organization.logoUrl} alt={organization.name} />}
    </HeaderContent>
  </HeaderContainer>
)
