import { Root } from '@radix-ui/react-popover'
import React, { ReactNode } from 'react'

interface IPopoverRoot {
  isOpen: boolean
  toggle: () => void
  children: ReactNode
}

export const PopoverRoot = ({ isOpen, toggle, children }: IPopoverRoot): JSX.Element => (
  <Root open={isOpen} onOpenChange={toggle}>
    {children}
  </Root>
)
