import { IModelJsonValues } from '@sparelabs/http-client-utils'

export enum RequestRecurrenceInstanceErrorReason {
  RiderNotFound = 'riderNotFound',
  RecurrenceDoesNotSpanDate = 'recurrenceDoesNotSpanDate',
  UserBanned = 'userBanned',
  OtherDirectionNotPossible = 'otherDirectionNotPossible',
  InternalError = 'internalError',
  IndividualRequestModified = 'individualRequestModified',
  ConflictingRequest = 'conflictingRequest',
  RequestTooSoon = 'requestTooSoon',
  DirectionDisabled = 'directionDisabled',
  RecurrenceDisabled = 'recurrenceDisabled',
  RequestCreationFailed = 'requestCreationFailed',
  RequestUpdateFailed = 'requestUpdateFailed',
}

export enum RequestRecurrenceInstanceDirection {
  Outbound = 'outbound',
  Return = 'return',
}

export interface IRequestRecurrenceInstanceResponse extends IModelJsonValues {
  recurrenceId: string
  date: string
  direction: RequestRecurrenceInstanceDirection
  errorReason: RequestRecurrenceInstanceErrorReason | null
}
