import { IRetryDelayStrategy } from './RetryDelayStrategy'

export interface IFixedDelayStrategyParams {
  /**
   * Amount of time to wait between retry attempts
   */
  delayMs: number
}

const DEFAULT_PARAMS: IFixedDelayStrategyParams = {
  delayMs: 500,
}

export class FixedDelayStrategy implements IRetryDelayStrategy {
  private readonly params: IFixedDelayStrategyParams

  constructor(params: Partial<IFixedDelayStrategyParams> = {}) {
    this.params = {
      ...DEFAULT_PARAMS,
      ...params,
    }
  }

  public calculateRetryDelay(_attemptNumber: number): number {
    return this.params.delayMs
  }
}
