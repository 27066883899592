import { colors } from './SharedColors'

/**
 * This file contains light-themed colors for the `sparelabs-admin` project.
 * There is currently no dark theme, but there may be in the future.
 *
 * We should avoid having to create new tokens instead of using the existing tokens available.
 * These tokens should be comprehensive enough to cover all our cases. If any of these tokens
 * are updated or added, the design team should be notified of any changes.
 */
export const WebColors = {
  // BACKGROUNDS
  backgroundPrimary: colors.white,
  backgroundPrimaryHover: colors.coolGray05,
  backgroundPrimaryActive: colors.coolGray10,

  backgroundSecondary: colors.coolGray05,
  backgroundSecondaryHover: colors.coolGray10,
  backgroundSecondaryActive: colors.coolGray20,

  backgroundTertiary: colors.coolGray10,
  backgroundTertiaryHover: colors.coolGray20,
  backgroundTertiaryActive: colors.coolGray25,

  backgroundInvertedPrimary: colors.gray90,
  backgroundInvertedPrimaryHover: colors.gray80,
  backgroundInvertedPrimaryActive: colors.gray70,

  backgroundInvertedSecondary: colors.black,
  backgroundInvertedSecondaryHover: colors.gray95,
  backgroundInvertedSecondaryActive: colors.gray90,

  backgroundInvertedTertiary: colors.gray70,
  backgroundInvertedTertiaryHover: colors.gray60,
  backgroundInvertedTertiaryActive: colors.gray55,

  // TEXT
  contentPrimary: colors.coolGray90,
  contentSecondary: colors.coolGray55,
  contentTertiary: colors.gray50,

  contentInvertedPrimary: colors.white,
  contentInvertedSecondary: colors.coolGray30,
  contentInvertedTertiary: colors.coolGray40,

  // INTERACTIVE ELEMENTS (BLUES)
  interactivePrimary: colors.blue50,
  interactivePrimaryHover: colors.blue55,
  interactivePrimaryActive: colors.blue60,
  interactivePrimaryDisabled: colors.gray30,

  interactiveLowContrast: colors.blue10,
  interactiveLowContrastHover: colors.blue20,
  interactiveLowContrastActive: colors.blue25,
  interactiveLowContrastDisabled: colors.gray10,

  interactiveHighContrast: colors.blue70,
  interactiveHighContrastHover: colors.blue80,
  interactiveHighContrastActive: colors.blue90,
  interactiveHighContrastDisabled: colors.gray50,

  // BRAND (PURPLES)
  brandPrimary: colors.purple50,
  brandPrimaryHover: colors.purple55,
  brandPrimaryActive: colors.purple60,
  brandPrimaryDisabled: colors.gray30,

  brandLowContrast: colors.purple10,
  brandLowContrastHover: colors.purple20,
  brandLowContrastActive: colors.purple25,
  brandLowContrastDisabled: colors.gray10,

  brandHighContrast: colors.purple70,
  brandHighContrastHover: colors.purple80,
  brandHighContrastActive: colors.purple90,
  brandHighContrastDisabled: colors.gray50,

  // POSITIVE (GREENS)
  positivePrimary: colors.green50,
  positivePrimaryHover: colors.green55,
  positivePrimaryActive: colors.green60,
  positivePrimaryDisabled: colors.gray30,

  positiveLowContrast: colors.green10,
  positiveLowContrastHover: colors.green20,
  positiveLowContrastActive: colors.green25,
  positiveLowContrastDisabled: colors.gray10,

  positiveHighContrast: colors.green70,
  positiveHighContrastHover: colors.green80,
  positiveHighContrastActive: colors.green90,
  positiveHighContrastDisabled: colors.gray50,

  positiveSecondary: colors.green40,
  positiveSecondaryHover: colors.green50,
  positiveSecondaryActive: colors.green55,
  positiveSecondaryDisabled: colors.gray30,

  // NEGATIVE (REDS)
  negativePrimary: colors.red50,
  negativePrimaryHover: colors.red55,
  negativePrimaryActive: colors.red60,
  negativePrimaryDisabled: colors.gray30,

  negativeLowContrast: colors.red10,
  negativeLowContrastHover: colors.red20,
  negativeLowContrastActive: colors.red25,
  negativeLowContrastDisabled: colors.gray10,

  negativeHighContrast: colors.red70,
  negativeHighContrastHover: colors.red80,
  negativeHighContrastActive: colors.red90,
  negativeHighContrastDisabled: colors.gray50,

  // WARNING (YELLOWS)
  warningPrimary: colors.yellow50,
  warningPrimaryHover: colors.yellow55,
  warningPrimaryActive: colors.yellow60,
  warningPrimaryDisabled: colors.gray30,

  warningLowContrast: colors.yellow10,
  warningLowContrastHover: colors.yellow20,
  warningLowContrastActive: colors.yellow25,
  warningLowContrastDisabled: colors.gray10,

  warningHighContrast: colors.yellow70,
  warningHighContrastHover: colors.yellow80,
  warningHighContrastActive: colors.yellow90,
  warningHighContrastDisabled: colors.gray50,

  warningSecondary: colors.yellow30,
  warningSecondaryHover: colors.yellow40,
  warningSecondaryActive: colors.yellow50,
  warningSecondaryDisabled: colors.gray30,

  // MISC
  borderPrimary: colors.coolGray10,
  borderSecondary: colors.coolGray30,

  // FOCUS OUTLINE
  focusOutline: colors.coolGray90,
  focusOutlineInverted: colors.white,

  // STATUS COLORS
  // BLUE
  accentBluePrimary: colors.blue50,
  accentBluePrimaryHover: colors.blue55,
  accentBluePrimaryActive: colors.blue60,
  accentBluePrimaryDisabled: colors.gray30,

  accentBlueLowContrast: colors.blue10,
  accentBlueLowContrastHover: colors.blue20,
  accentBlueLowContrastActive: colors.blue25,
  accentBlueLowContrastDisabled: colors.gray10,

  accentBlueHighContrast: colors.blue70,
  accentBlueHighContrastHover: colors.blue80,
  accentBlueHighContrastActive: colors.blue90,
  accentBlueHighContrastDisabled: colors.gray50,

  // GREEN
  accentGreenPrimary: colors.green50,
  accentGreenPrimaryHover: colors.green55,
  accentGreenPrimaryActive: colors.green60,
  accentGreenPrimaryDisabled: colors.gray30,

  accentGreenLowContrast: colors.green10,
  accentGreenLowContrastHover: colors.green20,
  accentGreenLowContrastActive: colors.green25,
  accentGreenLowContrastDisabled: colors.gray10,

  accentGreenHighContrast: colors.green70,
  accentGreenHighContrastHover: colors.green80,
  accentGreenHighContrastActive: colors.green90,
  accentGreenHighContrastDisabled: colors.gray50,

  // RED
  accentRedPrimary: colors.red50,
  accentRedPrimaryHover: colors.red55,
  accentRedPrimaryActive: colors.red60,
  accentRedPrimaryDisabled: colors.gray30,

  accentRedLowContrast: colors.red10,
  accentRedLowContrastHover: colors.red20,
  accentRedLowContrastActive: colors.red25,
  accentRedLowContrastDisabled: colors.gray10,

  accentRedHighContrast: colors.red70,
  accentRedHighContrastHover: colors.red80,
  accentRedHighContrastActive: colors.red90,
  accentRedHighContrastDisabled: colors.gray50,

  // YELLOW
  accentYellowPrimary: colors.yellow50,
  accentYellowPrimaryHover: colors.yellow55,
  accentYellowPrimaryActive: colors.yellow60,
  accentYellowPrimaryDisabled: colors.gray30,

  accentYellowLowContrast: colors.yellow10,
  accentYellowLowContrastHover: colors.yellow20,
  accentYellowLowContrastActive: colors.yellow25,
  accentYellowLowContrastDisabled: colors.gray10,

  accentYellowHighContrast: colors.yellow70,
  accentYellowHighContrastHover: colors.yellow80,
  accentYellowHighContrastActive: colors.yellow90,
  accentYellowHighContrastDisabled: colors.gray50,

  accentYellowSecondary: colors.yellow30,
  accentYellowSecondaryHover: colors.yellow40,
  accentYellowSecondaryActive: colors.yellow50,
  accentYellowSecondaryDisabled: colors.gray30,

  // PURPLE
  accentPurplePrimary: colors.purple50,
  accentPurplePrimaryHover: colors.purple55,
  accentPurplePrimaryActive: colors.purple60,
  accentPurplePrimaryDisabled: colors.gray30,

  accentPurpleLowContrast: colors.purple10,
  accentPurpleLowContrastHover: colors.purple20,
  accentPurpleLowContrastActive: colors.purple25,
  accentPurpleLowContrastDisabled: colors.gray10,

  accentPurpleHighContrast: colors.purple70,
  accentPurpleHighContrastHover: colors.purple80,
  accentPurpleHighContrastActive: colors.purple90,
  accentPurpleHighContrastDisabled: colors.gray50,

  // COOL GRAY
  accentCoolGrayPrimary: colors.coolGray50,
  accentCoolGrayPrimaryHover: colors.coolGray55,
  accentCoolGrayPrimaryActive: colors.coolGray60,
  accentCoolGrayPrimaryDisabled: colors.gray30,

  accentCoolGrayLowContrast: colors.coolGray10,
  accentCoolGrayLowContrastHover: colors.coolGray20,
  accentCoolGrayLowContrastActive: colors.coolGray25,
  accentCoolGrayLowContrastDisabled: colors.gray10,

  accentCoolGrayHighContrast: colors.coolGray70,
  accentCoolGrayHighContrastHover: colors.coolGray80,
  accentCoolGrayHighContrastActive: colors.coolGray90,
  accentCoolGrayHighContrastDisabled: colors.gray50,

  // GRAY
  accentGrayPrimary: colors.gray50,
  accentGrayPrimaryHover: colors.gray55,
  accentGrayPrimaryActive: colors.gray60,
  accentGrayPrimaryDisabled: colors.gray30,

  accentGrayLowContrast: colors.gray10,
  accentGrayLowContrastHover: colors.gray20,
  accentGrayLowContrastActive: colors.gray25,
  accentGrayLowContrastDisabled: colors.gray10,

  accentGrayHighContrast: colors.gray70,
  accentGrayHighContrastHover: colors.gray80,
  accentGrayHighContrastActive: colors.gray90,
  accentGrayHighContrastDisabled: colors.gray50,

  accentGraySecondary: colors.gray40,
  accentGraySecondaryHover: colors.gray50,
  accentGraySecondaryActive: colors.gray55,
  accentGraySecondaryDisabled: colors.gray25,
}

export const WebGradients = {
  ai: 'linear-gradient(90deg, #e1e0ff 50%, #ffccd4 75%, #ffc66b 100%)',
}
