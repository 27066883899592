import { MINUTE, Time } from '@sparelabs/time'
import { isNumber } from 'lodash'
import { Window } from '../scheduling'
import { IRequestStatusChange, RequestDispatchStatus, RequestIntentType, RequestStatus } from '../types'

export const VERY_LATE_THRESHOLD = 10 * MINUTE

export interface IFullRequestedTimes {
  intentType: RequestIntentType
  requestedPickupTs: number
  requestedDropoffTs: number
}

export type IRequestedTripTime =
  | {
      intentType: RequestIntentType.LeaveAt
      requestedPickupTs: number
    }
  | {
      intentType: RequestIntentType.ArriveBy
      requestedDropoffTs: number
    }

type IExternallyDispatchedInput = { isExternallyDispatched: boolean } | { externalDispatchFleetId: string | null }

type IRematchingAllowedInput = {
  status: RequestStatus
  dispatchStatus?: RequestDispatchStatus | null
  statusChanges: IRequestStatusChange[]
} & IExternallyDispatchedInput

export class RequestSemantics {
  public static readonly MATCHED_REQUEST_STATUSES = [
    RequestStatus.Accepted,
    RequestStatus.Arriving,
    RequestStatus.InProgress,
    RequestStatus.Completed,
  ]

  public static readonly PROCESSING_REQUEST_STATUSES: RequestStatus[] = [
    RequestStatus.Processing,
    RequestStatus.ServiceDisruption,
  ]

  public static readonly IN_PROGRESS_REQUEST_STATUSES: RequestStatus[] = [
    RequestStatus.Accepted,
    RequestStatus.Arriving,
    RequestStatus.InProgress,
  ]

  public static readonly TERMINAL_STATUSES: RequestStatus[] = [
    RequestStatus.Completed,
    RequestStatus.Cancelled,
    RequestStatus.NoDriversAvailable,
  ]

  public static readonly ALLOWED_DISPATCH_STATUSES_FOR_REQUEST_REMATCH = new Set([
    RequestDispatchStatus.Unscheduled,
    RequestDispatchStatus.OnHold,
    RequestDispatchStatus.OfferedToFleet,
  ])

  public static isFlagDownRequest(request: { flagDownDutyId: string | null }): boolean {
    return Boolean(request.flagDownDutyId)
  }

  public static isRecurringRequest(request: { recurrenceId?: string | null }): boolean {
    return Boolean(request.recurrenceId)
  }

  public static isMatched(status: RequestStatus): boolean {
    return this.MATCHED_REQUEST_STATUSES.some((matchedStatus) => status === matchedStatus)
  }

  public static isProcessing(status: RequestStatus): boolean {
    return this.PROCESSING_REQUEST_STATUSES.some((processingStatus) => status === processingStatus)
  }

  public static isRematchingAllowed(request: IRematchingAllowedInput): boolean {
    const { status, dispatchStatus } = request
    const isExternallyDispatched = this.isExternallyDispatched(request)

    if (status === RequestStatus.Accepted) {
      return true
    }

    if (status === RequestStatus.Arriving && !isExternallyDispatched) {
      return true
    }

    if (
      this.PROCESSING_REQUEST_STATUSES.includes(status) &&
      dispatchStatus !== null &&
      dispatchStatus !== undefined &&
      // temporarily allowing rematching for requests that have been in awaiting dispatch for too long
      // https://sparelabs.slack.com/archives/C04TUMFPTPT/p1715619091476329
      (this.ALLOWED_DISPATCH_STATUSES_FOR_REQUEST_REMATCH.has(dispatchStatus) ||
        this.hasBeenInAwaitingDispatchForTooLong(request))
    ) {
      return true
    }

    return false
  }

  public static isKeepingOriginalFlexWindow(request: {
    status?: RequestStatus
    matchedPickupWindow: Window | null
    matchedDropoffWindow: Window | null
    initialScheduledPickupTs: number | null
    initialScheduledDropoffTs: number | null
  }): boolean {
    return (
      (request.status ? this.isProcessing(request.status) : false) &&
      (Boolean(request.matchedPickupWindow) || Boolean(request.matchedDropoffWindow)) &&
      isNumber(request.initialScheduledPickupTs) &&
      isNumber(request.initialScheduledDropoffTs)
    )
  }

  public static wrapDesiredTime(desiredTs: number, intentType: RequestIntentType): IRequestedTripTime {
    switch (intentType) {
      case RequestIntentType.LeaveAt:
        return { requestedPickupTs: desiredTs, intentType }
      case RequestIntentType.ArriveBy:
        return { requestedDropoffTs: desiredTs, intentType }
    }
  }

  public static getDesiredTs(request: IRequestedTripTime): number {
    switch (request.intentType) {
      case RequestIntentType.LeaveAt:
        return request.requestedPickupTs
      case RequestIntentType.ArriveBy:
        return request.requestedDropoffTs
    }
  }

  public static buildRequestedTimes(request: IRequestedTripTime, minTravelDuration: number): IFullRequestedTimes {
    switch (request.intentType) {
      case RequestIntentType.LeaveAt:
        return {
          intentType: request.intentType,
          requestedPickupTs: request.requestedPickupTs,
          requestedDropoffTs: request.requestedPickupTs + minTravelDuration,
        }
      case RequestIntentType.ArriveBy:
        return {
          intentType: request.intentType,
          requestedPickupTs: request.requestedDropoffTs - minTravelDuration,
          requestedDropoffTs: request.requestedDropoffTs,
        }
    }
  }

  public static isExternallyDispatched(request: IExternallyDispatchedInput): boolean {
    if ('isExternallyDispatched' in request) {
      return request.isExternallyDispatched
    }

    return Boolean(request.externalDispatchFleetId)
  }

  private static hasBeenInAwaitingDispatchForTooLong({
    statusChanges,
  }: {
    statusChanges: IRequestStatusChange[]
  }): boolean {
    return (
      statusChanges.length > 0 &&
      statusChanges[statusChanges.length - 1].dispatchStatus === RequestDispatchStatus.AwaitingDispatch &&
      statusChanges[statusChanges.length - 1].ts < Time.getNowTs() - 15 * MINUTE
    )
  }
}
