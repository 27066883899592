import { AxiosInstance } from 'axios'
import { IGetPublicEngageFormQuery, IPublicEngageCaseFormPostBody, IPublicEngageFormResponse } from '../types'
import { Resources } from './ResourcesType'

export class PublicResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async getForm(params: IGetPublicEngageFormQuery): Promise<IPublicEngageFormResponse> {
    const res = await this.agent.get<IPublicEngageFormResponse>(Resources.PublicForm, { params })
    return res.data
  }

  public async postCaseForm(body: IPublicEngageCaseFormPostBody): Promise<void> {
    await this.agent.post(Resources.PublicCaseForms, body)
  }
}
