export class ParamSerialization {
  /**
   * This method takes an object and properly manipulates each key to follow a `prefix[key]` format. This can
   * be used to properly serialize objects for endpoints that expect `deepObject` serialization.
   *
   * Learn more about `deepObject` query serialization here: https://swagger.io/docs/specification/serialization/
   */
  public static deepenObject(data: Record<string, unknown>, prefix: string): Record<string, unknown> {
    const result: Record<string, unknown> = {}
    for (const [key, value] of Object.entries(data)) {
      result[`${prefix}[${key}]`] = value
    }
    return result
  }
}
