import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

export class ErrorHandler {
  public static handleError(error: Error | AxiosError<{ message: string }>): void {
    toast(this.extractMessage(error))
    // eslint-disable-next-line no-console
    window?.console?.error?.(error)
  }

  private static extractMessage(error: Error): string {
    const axiosError = error as AxiosError<{ message: string }>
    if (axiosError.response) {
      return axiosError.response.data.message
    }
    return error.message
  }
}

/**
 * Wraps an async function with error handling and loading state management. Also avoids
 * `async-await` so that it doesn't a promise.
 * Note: Does NOT clear loading state on success to prevent unmounted-component errors.
 *
 * @param fn Async business logic function (ex form submission)
 * @param setLoadingState Optional setState hook to set loading state
 */
export const withErrorHandler =
  <T>(fn: (...args: T[]) => Promise<void>, setLoadingState?: (val: boolean) => void) =>
  (...args: T[]): void => {
    if (setLoadingState) {
      setLoadingState(true)
    }

    fn(...args).catch((error) => {
      if (setLoadingState) {
        setLoadingState(false)
      }
      ErrorHandler.handleError(error as Error)
    })
  }
