import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { WebColors } from '@sparelabs/colors'
import { IFileResponse, IFileTempUrlResponse, IPublicFileResponse } from '@sparelabs/http-client-utils'
import { range } from 'lodash'
import React, { useEffect } from 'react'
import { useCounter, useList } from 'react-use'
import styled from 'styled-components'
import { LoaderSpinner } from '../../../animations'
import { st } from '../../../locales/TranslationHelper'
import { INPUT_MAX_WIDTH } from '../../../util'
import { FileUploadError, FileUploadingView, FileView } from '../../displays'
import { StyledDropzone } from '../styled'
import { IStyledDropzoneProps } from './FileInputTypes'

library.add(faTrashAlt)

export interface IMultipleFileInputProps<T extends IFileResponse | IPublicFileResponse>
  extends Omit<IStyledDropzoneProps, 'onDrop'> {
  value: T[]
  onChange: (value: T[]) => void
  disableRemove?: boolean
  upload: (fileData: File) => Promise<T>
  maxFiles?: number | 'unlimited'
  tempUrl?: (id: string) => Promise<IFileTempUrlResponse>
  showCount?: boolean
  hideButtons?: boolean
}

const Container = styled.div`
  max-width: ${INPUT_MAX_WIDTH};
  background-color: ${WebColors.backgroundPrimary};
  border-radius: 8px;
`

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-top: 0px;
  gap: 16px;
`

const FileCountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 160x;
`

const CountItem = styled.div`
  margin-left: 10px;
`

const Divider = styled.hr`
  border: '1px solid ${WebColors.borderPrimary}';
  margin: 0px;
`
export function MultipleFileInput<T extends IFileResponse | IPublicFileResponse>({
  value,
  onChange,
  disableRemove,
  hideButtons,
  upload,
  tempUrl,
  onError,
  label,
  error,
  accept,
  maxSizeInBytes,
  maxFiles,
  showCount = false,
  id,
}: IMultipleFileInputProps<T>): JSX.Element {
  const [files, { push, removeAt }] = useList<T>(value ?? [])

  const [failedFiles, { push: pushFailed, clear: clearFailed }] = useList<File>([])
  const [uploadingCount, { inc: incUploading, dec: decUploading }] = useCounter(0)
  const [totalCount, { inc: incTotal, dec: decTotal }] = useCounter(0)

  // onChange is not included as a dependency as it would cause infinite loops
  useEffect(() => {
    onChange([...files])
  }, [files])

  const onDrop = async (file: File) => {
    incUploading()
    try {
      const res = await upload(file)
      push(res)
    } catch (error) {
      decUploading()
      pushFailed(file)
      throw error
    }
    decUploading()
  }

  return (
    <Container>
      <StyledDropzone
        multiple={true}
        label={label}
        width={INPUT_MAX_WIDTH}
        error={error}
        accept={accept}
        maxSizeInBytes={maxSizeInBytes}
        maxFiles={maxFiles}
        onDrop={onDrop}
        onError={onError}
        id={id}
        onStartUploading={({ totalAccepted }) => {
          incTotal(totalAccepted)
          decTotal(failedFiles.length)
          clearFailed()
        }}
      >
        {totalCount > 0 && (
          <FileContainer>
            {showCount && (
              <FileCount totalCount={totalCount} completedCount={files.length} failedCount={failedFiles.length} />
            )}
            <Files
              uploadingCount={uploadingCount}
              files={files}
              failedFiles={failedFiles}
              removeAt={removeAt}
              disableRemove={disableRemove}
              hideButtons={hideButtons}
              tempUrl={tempUrl}
              onError={onError}
            />
          </FileContainer>
        )}
      </StyledDropzone>
    </Container>
  )
}

function Files<T extends IFileResponse | IPublicFileResponse>({
  files,
  uploadingCount,
  failedFiles,
  disableRemove,
  hideButtons,
  removeAt,
  tempUrl,
  onError,
}: {
  files: T[]
  uploadingCount: number
  failedFiles: File[]
  removeAt: (index: number) => void
} & Pick<IMultipleFileInputProps<T>, 'disableRemove' | 'tempUrl' | 'onError' | 'hideButtons'>): JSX.Element {
  return (
    <>
      <Divider />
      {files.map((file, index) => (
        <FileView
          key={index}
          value={file}
          disableRemove={disableRemove}
          hideButtons={hideButtons}
          onRemove={() => removeAt(index)}
          tempUrl={tempUrl}
          onError={onError}
        />
      ))}
      {failedFiles.map((file, index) => (
        <FileUploadError name={file.name} key={index} />
      ))}
      {range(uploadingCount).map((key) => (
        <FileUploadingView key={key} />
      ))}
    </>
  )
}

function FileCount({
  completedCount,
  failedCount,
  totalCount,
}: {
  completedCount: number
  failedCount: number
  totalCount: number
}): JSX.Element {
  const inProgressCount = totalCount - completedCount - failedCount
  const hasInProgress = inProgressCount > 0
  const hasCompleted = completedCount > 0
  const hasFailed = failedCount > 0

  return (
    <>
      <Divider />
      <FileCountContainer>
        {hasInProgress ? (
          <LoaderSpinner />
        ) : (
          <Icon icon='check-circle' size='lg' color={WebColors.accentCoolGrayPrimary} />
        )}
        {hasInProgress && (
          <CountItem key='in-progress-count'>{st.inputs.multipleFile.inProgress({ count: inProgressCount })}</CountItem>
        )}
        {hasCompleted && (
          <CountItem key='completed-count'>{st.inputs.multipleFile.completed({ count: completedCount })}</CountItem>
        )}
        {hasFailed && <CountItem key='failed-count'>{st.inputs.multipleFile.failed({ count: failedCount })}</CountItem>}
      </FileCountContainer>
    </>
  )
}
