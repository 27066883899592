import { buildPolygon } from './GeometryBuilder'

export class GeographyConstants {
  // These are off by 1 to avoid edge cases with GPS coordinates
  public static GLOBAL_POLYGON = buildPolygon([
    [
      [-179, 89],
      [179, 89],
      [179, -89],
      [-179, -89],
      [-179, 89],
    ],
  ])
}
