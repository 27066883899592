export enum RequestIntentType {
  LeaveAt = 'leaveAt',
  ArriveBy = 'arriveBy',
}

/* Consult the 'Request lifecycle' document for more information on the meaning of these statuses.
 * https://whimsical.com/request-lifecycle-QzCDVh6KSS8NYHu4WrN4jd
 */
export enum RequestStatus {
  /**
   * Every request is initially created with this status.
   * Indicates that we are still trying to match the request,
   * and that no matching outcome has yet been communicated to the rider.
   */
  Processing = 'processing',
  /**
   * We have tried and failed to match this request, and communicated
   * this outcome to the rider.
   */
  NoDriversAvailable = 'noDriversAvailable',
  /**
   * This request was previously matched and the match was communicated to the rider,
   * but then the request became unmatched due to some event outside of our control,
   * such as its duty being cancelled.
   * @deprecated this duplicates the processing status, it will be removed soon
   */
  ServiceDisruption = 'serviceDisruption',
  /**
   * The request has been matched to a duty, and this outcome has been communicated to the
   * rider.
   */
  Accepted = 'accepted',
  /**
   * After a request is accepted, it transitions to this status when the driver
   * is close to picking up the rider.
   */
  Arriving = 'arriving',
  /**
   * The rider has been picked up by a driver, and is on their way to their dropoff location.
   */
  InProgress = 'inProgress',
  /**
   * The request has been cancelled by a user. This can be triggered by the rider if they no longer want this trip,
   * or by a driver or service administrator if they believe that the rider is a no-show.
   */
  Cancelled = 'cancelled',
  /**
   * The rider has been dropped off at their dropoff location.
   */
  Completed = 'completed',
}

/* Consult the 'Request lifecycle' document for more information on the meaning of these statuses.
 * https://whimsical.com/request-lifecycle-QzCDVh6KSS8NYHu4WrN4jd
 */
export enum RequestDispatchStatus {
  /**
   * This request has not yet finished dispatching - e.g. the request has been created, but we either haven't yet
   * started the process of dispatching it to a fleet/duty, or the first dispatch attempt is in progress.
   */
  AwaitingDispatch = 'awaitingDispatch',
  /**
   * An offer has been sent out to a fleet where a driver can choose to accept it or not (like an Open Fleet or
   * Accept/Reject fleet). We have not yet heard back from them.
   */
  OfferedToFleet = 'offeredToFleet',
  /**
   * There isn't currently a spot on a duty for this request, but we're going to keep trying to find one.
   *
   * Examples of how requests could get into an unscheduled state:
   *  - Stayed processing/service disruption instead of going to NDA due to the no strand policy being enabled
   *  - Request was added even though we knew there was no room ("overbooking")
   *  - "Delayed matching" request that doesn't have a tentative match
   *  - It's too early to send an offer yet to an accept/reject fleet, but we'll try again later
   *  - Initial dispatch failed, but we've scheduled a follow-up dispatch attempt
   */
  Unscheduled = 'unscheduled',
  /**
   * The user has indicated that the system should not try to match this request at this time. Could be via
   * manual action on a specific request, or due to the "Hold Requests Until Time" service setting (a.k.a.
   * "Delayed Matching")
   */
  OnHold = 'onHold',
}

export interface IRequestStatusChange {
  status: RequestStatus
  dispatchStatus?: RequestDispatchStatus
  ts: number
}
