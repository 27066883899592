import { DependencyList } from 'react'
import { useAsync } from 'react-use'
import { AsyncState } from 'react-use/lib/useAsync'

export const useTryAsync = <T>(
  onError: (error: Error) => void,
  callback: () => Promise<T>,
  dependencies?: DependencyList
): AsyncState<T> =>
  useAsync(async () => {
    try {
      return await callback()
    } catch (error: any) {
      onError(error)
      throw error
    }
  }, dependencies)
