import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum DevicePermissionSet {
  View = 'device_view',
}

export const devicePermissionSetGrouping: IPermissionSetGrouping<DevicePermissionSet> = {
  key: PermissionSetGroupingKey.Device,
  active: {
    [DevicePermissionSet.View]: [PermissionType.LIST_ANY_DEVICE],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
