import { IPoint } from '@sparelabs/geography'
import { IRequestAccessibilityFeature } from './AccessibilityTypes'
import { Currency } from './CurrencyTypes'
import { RequestCancellationFault } from './RequestCancellationTypes'
import { IRequestRiderType } from './RequestRiderTypes'
import { RequestIntentType, RequestStatus } from './RequestTypes'
import { IPrice } from './SharedTypes'

export interface IExternalDispatchCreateRequestPostBody {
  // requestId from the core spare system
  requestId: string
  requestedPickupAddress: string
  requestedPickupLocation: IPoint
  requestedDropoffAddress: string
  requestedDropoffLocation: IPoint
  // external dispatch interface currently only supports leaveAt / nextAvailable trips
  requestedPickupTs: number
  requestedDropoffTs: number
  intentType: RequestIntentType
  rider: {
    id: string
    firstName: string
    lastName: string
    phoneNumber: string
  }
  riders: IRequestRiderType[]
  fare: {
    currency: Currency
    total: number
    // TODO: make required
    prepaid?: boolean
  }
  notes: string | null
  pickupNotes: string | null
  dropoffNotes: string | null
  accessibilityFeatures: IRequestAccessibilityFeature[]
}

// @deprecated
export enum ExternalDispatchCancelledReason {
  riderNoShow = 'riderNoShow',
  driverNoShow = 'driverNoShow',
  rideReassigned = 'rideReassigned',
  riderCancelled = 'riderCancelled',
  driverCancelled = 'driverCancelled',
  rejected = 'rejected',
  rejectedByProvider = 'rejectedByProvider',
  other = 'other',
}

export interface IExternalDispatchCancelRequestPostBody {
  // requestId inside of the spare core system
  requestId: string
  cancelledReason: ExternalDispatchCancelledReason
}

export interface IExternalDispatchEstimatePostBody {
  requestedPickupAddress: string
  requestedPickupLocation: IPoint
  requestedDropoffAddress: string
  requestedDropoffLocation: IPoint
  requestedPickupTs: number
  riders: IRequestRiderType[]
  accessibilityFeatures: IRequestAccessibilityFeature[]
}

export interface IExternalDispatchEstimateResponse {
  scheduledPickupAddress: string
  scheduledPickupLocation: IPoint
  scheduledDropoffAddress: string
  scheduledDropoffLocation: IPoint
  scheduledPickupTs: number
  price?: IPrice
}

export interface IExternalDispatchStatusPostBody {
  requestId: string // requestId from the core spare system
  status: RequestStatus
  scheduledPickupLocation: {
    address: string
    location: IPoint
  } | null
  scheduledDropoffLocation: {
    address: string
    location: IPoint
  } | null
  pickupEta: number | null
  dropoffEta: number | null
  vehicle: {
    color: string
    make: string
    model: string
    licensePlate: string
    photoUrl?: string
    passengerSeats?: number
  } | null
  driver: {
    firstName: string
    photoUrl: string | null
    phoneNumber: string | null
  } | null
  vehicleLocation: {
    location: IPoint
    bearing: number | null
  } | null
  cancelledReason?: ExternalDispatchCancelledReason // @deprecated
  externalUrl?: string | null
  cancellationDetails?: IExternalCancellationDetails
  configurationId?: string
}

export interface IExternalDispatchStatusResponse extends IExternalDispatchStatusPostBody {}

export interface IExternalCancellationDetails {
  fault: RequestCancellationFault
  reason: string
  notes: string | null
}
