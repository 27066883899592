import { Content } from '@radix-ui/react-popover'
import { WebColors } from '@sparelabs/colors'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { boxShadow } from '../util'

export const PopoverContentCSS = css`
  background-color: ${WebColors.backgroundPrimary};
  border: none;
  border-radius: 8px;
  padding: 0px;
  box-shadow: ${boxShadow};
  display: flex;
  flex-direction: column;
`

const StyledContent = styled(Content)`
  ${PopoverContentCSS}
`

interface IPopoverContent extends React.RefAttributes<HTMLDivElement> {
  children?: ReactNode
  alignRight?: boolean
  className?: string
  style?: React.CSSProperties
}

export const PopoverContent = ({ children, alignRight, className, style, ...rest }: IPopoverContent): JSX.Element => (
  <StyledContent
    className={className}
    align={alignRight ? 'end' : 'start'}
    portalled={false}
    style={style}
    {...rest}
    sideOffset={8}
  >
    {children}
  </StyledContent>
)
