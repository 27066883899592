import { DriverUserPermissionSet, PermissionSetType, RiderPermissionSet } from '../sets'
import { AnnouncementPermissionSet } from '../sets/AnnouncementPermissions'
import { ChargePermissionSet } from '../sets/ChargePermissions'
import { DevicePermissionSet } from '../sets/DevicePermission'
import { DutyPermissionSet } from '../sets/DutyPermissions'
import { DutyRequestOfferPermissionSet } from '../sets/DutyRequestOfferPermissions'
import { EventPermissionSet } from '../sets/EventPermissions'
import { GroupPermissionSet } from '../sets/GroupPermissions'
import { OperationalAlertsPermissionSet } from '../sets/OperationalAlertsPermissions'
import { RequestPermissionSet } from '../sets/RequestPermissions'
import { ReviewPermissionSet } from '../sets/ReviewPermissions'
import { RiderDriverRestrictionPermissionSet } from '../sets/RiderDriverRestrictionPermissions'
import { RiderUserPermissionSet } from '../sets/RiderUserPermissions'
import { RiderVehicleTypeRestrictionPermissionSet } from '../sets/RiderVehicleTypeRestrictionPermissions'
import { ServicePermissionSet } from '../sets/ServicePermissions'
import { VehiclePermissionSet } from '../sets/VehiclePermissions'
import { VehicleTypePermissionSet } from '../sets/VehicleTypePermissions'
import { AdminOperatorRole } from './AdminOperatorRole'

export const SchedulerRole: PermissionSetType[] = [
  ...AdminOperatorRole,

  // TODO: Replace rider permission set with more limited permission sets
  RiderPermissionSet.Rider,

  /* Can view riders */
  RiderUserPermissionSet.View,

  /* Driver user management */
  DriverUserPermissionSet.View,
  DriverUserPermissionSet.Edit,
  DriverUserPermissionSet.Delete,

  /* Ability to see devices registered to users */
  DevicePermissionSet.View,

  /* Ability to see and manage vehicles */
  VehiclePermissionSet.View,
  VehiclePermissionSet.New,
  VehiclePermissionSet.Edit,
  VehiclePermissionSet.Import,
  VehiclePermissionSet.Export,

  /* Ability to view and manage duties & see vehicle locations */
  DutyPermissionSet.View,
  DutyPermissionSet.Manage,
  DutyPermissionSet.ViewVehicleLocation,

  /* Ability to View Service/Fleet/Zone/Stop/TimeRule */
  ServicePermissionSet.View,

  /* Ability to see announcement information */
  AnnouncementPermissionSet.View,

  RequestPermissionSet.View,
  RequestPermissionSet.ViewConstraintOverrides,
  RequestPermissionSet.ManualInterventionSalesInfo,

  DutyRequestOfferPermissionSet.View,

  GroupPermissionSet.View,
  GroupPermissionSet.ViewMembership,

  /* Ability to view reviews */
  ReviewPermissionSet.View,

  /* Ability to view charges */
  ChargePermissionSet.View,

  /* Ability to subscribe to operational alerts and see them */
  OperationalAlertsPermissionSet.Subscribe,

  /* Ability to view the 'activity log' */
  EventPermissionSet.View,

  /** Ability to communicate with drivers in chat */
  DutyPermissionSet.ViewDriverConversation,
  DutyPermissionSet.StartDriverConversation,

  RiderDriverRestrictionPermissionSet.View,
  RiderDriverRestrictionPermissionSet.Create,
  RiderDriverRestrictionPermissionSet.Delete,

  RiderVehicleTypeRestrictionPermissionSet.View,
  RiderVehicleTypeRestrictionPermissionSet.Write,
  RiderVehicleTypeRestrictionPermissionSet.Delete,

  VehicleTypePermissionSet.View,
  VehicleTypePermissionSet.Write,
  VehicleTypePermissionSet.Delete,
]
