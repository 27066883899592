import { z } from '@sparelabs/zod'

export enum RiderType {
  Adult = 'adult',
  Infant = 'infant',
  Child = 'child',
  Youth = 'youth',
  Senior = 'senior',
  Student = 'student',
  Disabled = 'disabled',
  Veteran = 'veteran',
  Reduced = 'reduced',
  PersonalCareAttendant = 'personalCareAttendant',
  Other = 'other',
}

export const RiderTypeSchema = z.object({
  type: z.nativeEnum(RiderType),
  count: z.number(),
})

export type IRequestRiderType = z.infer<typeof RiderTypeSchema>
