import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IFormPatchBody, IFormPostBody, IFormResponse, IListFormQueryParams } from '../types'
import { Resources } from './ResourcesType'

export class FormResource extends GenericResource<IFormResponse, IListFormQueryParams, IFormPatchBody, IFormPostBody> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  constructor(agent: AxiosInstance) {
    super(Resources.Forms, agent)
  }
}
