import Handlebars from 'handlebars'
import { IMarkdownVariable, MarkdownVariableData, MarkdownVariableHelpers } from './MarkdownVariableTypes'

export class MarkdownVariableRenderer {
  private readonly handlebars = Handlebars.create()

  constructor(private readonly data: MarkdownVariableData, helpers?: MarkdownVariableHelpers) {
    if (helpers) {
      this.handlebars.registerHelper(helpers)
    }
  }

  public render(content: string): string {
    return this.handlebars.compile(content)(this.data)
  }

  public static async build<RenderData extends object>(
    variables: Array<IMarkdownVariable<never, RenderData>>,
    input: RenderData
  ) {
    const dataList: MarkdownVariableData[] = []
    const helpersList: MarkdownVariableHelpers[] = []

    for (const variable of variables) {
      if (variable.buildData) {
        const result = await variable.buildData(input)
        if (result) {
          dataList.push(result)
        }
      }
      if (variable.buildHelpers) {
        const result = await variable.buildHelpers(input)
        if (result) {
          helpersList.push(result)
        }
      }
    }

    const outputData = Object.assign({}, ...dataList) as MarkdownVariableData
    const outputHelpers = Object.assign({}, ...helpersList) as MarkdownVariableHelpers

    return new MarkdownVariableRenderer(outputData, outputHelpers)
  }
}
