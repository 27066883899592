import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum TipPermissionSet {
  View = 'tip_view',
}

export const tipPermissionSetGrouping: IPermissionSetGrouping<TipPermissionSet> = {
  key: PermissionSetGroupingKey.Tip,
  active: {
    [TipPermissionSet.View]: [PermissionType.GET_ANY_TIP],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
