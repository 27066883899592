import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum GroupPermissionSet {
  View = 'group_view',
  Write = 'group_write',
  ViewMembership = 'group_view_membership',
  WriteMembership = 'group_write_membership',
  ViewOwnMembership = 'group_view_own_membership',
  WriteOwnMembership = 'group_write_own_membership',
  ViewCondition = 'group_view_condition',
  WriteCondition = 'group_write_condition',
  ImportMembership = 'group_import_membership',
  ExportMembership = 'group_export_membership',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const groupPermissionSetGrouping: IPermissionSetGrouping<GroupPermissionSet> = {
  key: PermissionSetGroupingKey.Group,
  active: {
    [GroupPermissionSet.View]: [
      PermissionType.LIST_ANY_GROUP,
      PermissionType.GET_ANY_GROUP,
    ],
    [GroupPermissionSet.Write]: [
      PermissionType.WRITE_ANY_GROUP,
      PermissionType.DELETE_ANY_GROUP,
    ],
    [GroupPermissionSet.ViewMembership]: [
      PermissionType.GET_ANY_GROUP_MEMBERSHIP,
      PermissionType.GET_OWN_GROUP_MEMBERSHIP,
      PermissionType.LIST_OWN_GROUP_MEMBERSHIP,
    ],
    [GroupPermissionSet.WriteMembership]: [
      PermissionType.POST_ANY_GROUP_MEMBERSHIP,
      PermissionType.POST_ANY_GROUP_MEMBERSHIP,
      PermissionType.PATCH_ANY_GROUP_MEMBERSHIP,
      PermissionType.POST_OWN_GROUP_MEMBERSHIP,
      PermissionType.PATCH_OWN_GROUP_MEMBERSHIP,
      PermissionType.DELETE_OWN_GROUP_MEMBERSHIP,
    ],
    [GroupPermissionSet.ViewOwnMembership]: [
      PermissionType.GET_OWN_GROUP_MEMBERSHIP,
      PermissionType.LIST_OWN_GROUP_MEMBERSHIP,
    ],
    [GroupPermissionSet.WriteOwnMembership]: [
      PermissionType.POST_OWN_GROUP_MEMBERSHIP,
      PermissionType.PATCH_OWN_GROUP_MEMBERSHIP,
      PermissionType.DELETE_OWN_GROUP_MEMBERSHIP,
    ],
    [GroupPermissionSet.ViewCondition]: [PermissionType.GET_ANY_GROUP_CONDITION],
    [GroupPermissionSet.WriteCondition]: [
      PermissionType.WRITE_ANY_GROUP_CONDITION,
      PermissionType.DELETE_ANY_GROUP_CONDITION,
    ],
    [GroupPermissionSet.ImportMembership]: [PermissionType.IMPORT_ANY_GROUP_MEMBERSHIP],
    [GroupPermissionSet.ExportMembership]: [PermissionType.EXPORT_ANY_GROUP_MEMBERSHIP],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
