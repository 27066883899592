import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'

export enum ConstraintOverrideActionActorType {
  Engine = 'engine',
  User = 'user',
}

export interface IConstraintOverrideActionResponse extends IModelJsonValues {
  requestId: string
  authorId: string | null
  actorType: ConstraintOverrideActionActorType
}

export interface IConstraintOverrideActionListQueryParams extends IListModelQueryParamValues {
  requestId: string
  authorId: string
  actorType: ConstraintOverrideActionActorType
}
