import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { ICallCentreMetricsResponse, IListCallCentreMetricsQueryParams, Resources } from '../types'

export class CallCentreMetricsResource extends GenericResource<
  ICallCentreMetricsResponse,
  IListCallCentreMetricsQueryParams,
  never,
  never
> {
  // the return value of the /metrics/callCentre endpoint
  public fetchMetrics = async (params: IListCallCentreMetricsQueryParams): Promise<ICallCentreMetricsResponse> => {
    const response = await this.agent.get<ICallCentreMetricsResponse>(`${this.resource}`, { params })
    return response.data
  }

  constructor(agent: AxiosInstance) {
    super(Resources.CallCentreMetrics, agent)
  }
}
