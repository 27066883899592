import React from 'react'
import ReactMarkdown from 'react-markdown'

interface IProps {
  children: string
}

export const MarkdownSpan = ({ children }: IProps): JSX.Element => (
  <ReactMarkdown disallowedElements={['paragraph']} unwrapDisallowed={true}>
    {children}
  </ReactMarkdown>
)
