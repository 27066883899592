export enum PaymentMethodBrand {
  Unknown = 'unknown',
  Visa = 'visa',
  Amex = 'amex',
  MasterCard = 'mastercard',
  Discover = 'discover',
  Jcb = 'jcb',
  Diners = 'diners',
  Unionpay = 'unionpay',
  Sepa = 'sepa',
  Nimoca = 'nimoca',
  GoPass = 'gopass',
  Wallet = 'wallet',
}
