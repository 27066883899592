import { CrossServiceContextBuilder } from '@sparelabs/context-propagation'
import { MINUTE, TimeUnits, toMilliseconds } from '@sparelabs/time'
import axios, { AxiosError, AxiosHeaders, AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import { IApiClientConfiguration } from '../types/ApiClientConfiguration'

const DEFAULT_HOST = 'https://api.sparelabs.com'
const DEFAULT_TIMEOUT_SECONDS = 5 * MINUTE

export class AxiosAgentBuilder {
  public static build({
    host = DEFAULT_HOST,
    token,
    locale,
    headers,
    socketKeepAlive,
    timeoutSeconds = DEFAULT_TIMEOUT_SECONDS,
  }: Partial<IApiClientConfiguration>): AxiosInstance {
    const axiosAgent = axios.create({
      baseURL: `${host}/v1/`,
      headers: {
        ...headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        ...(locale ? { 'Accept-Language': locale } : {}),
      },
      timeout: toMilliseconds(timeoutSeconds, TimeUnits.Seconds),
      ...this.getAgents(socketKeepAlive ?? false),
    })

    axiosAgent.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
      config.headers = new AxiosHeaders({
        ...config.headers,
        ...CrossServiceContextBuilder.buildOutboundHttpHeaders(),
      })
      return config
    })

    axiosAgent.interceptors.response.use(
      (response) => response,
      (error) => {
        /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
        const axiosError = error
        axiosError.message = axiosError.response?.data?.message ?? axiosError.message
        axiosError.name = axiosError.response?.data?.name ?? axiosError.name
        axiosError.metadata = axiosError.response?.data?.metadata ?? axiosError.metadata
        throw axiosError as AxiosError<Error>
        /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
      }
    )

    return axiosAgent
  }

  private static getAgents(socketKeepAlive: boolean): { httpAgent?: any; httpsAgent?: any } {
    // Dynamically import http/https, only if we're on node.js, as these aren't supported in browsers
    if (socketKeepAlive && process?.versions?.node) {
      return {
        // eslint-disable-next-line
        httpAgent: new (require('http').Agent)({ keepAlive: true }),
        // eslint-disable-next-line
        httpsAgent: new (require('https').Agent)({ keepAlive: true }),
      }
    }

    return {}
  }
}
