import { ITimeRange } from '@sparelabs/api-client'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { StyledInput } from './styled'
import { fromHHMMtoTs, fromTsToHHMM, ITimeInput } from './TimeInput'

const RangeTextContainer = styled.span`
  width: 1500px;
  display: inline-flex;
  align-items: center;
`
const SpaceContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
`
const StyledRangeWrapper = styled.div`
  display: flex;
  width: 100%;
`

interface ITimeRangeInput extends Omit<ITimeInput, 'value' | 'onChange'> {
  onChange: (value?: ITimeRange) => void
  value?: ITimeRange
}

export const TimeRangeInput = ({ value, invalid, onChange, text }: ITimeRangeInput): JSX.Element => {
  const [start, setStart] = useState(value?.startTs)
  const [end, setEnd] = useState(value?.endTs)

  useEffect(() => {
    if (start !== undefined && end !== undefined) {
      onChange({ startTs: start, endTs: end })
    }
  }, [start, end])

  return (
    <StyledRangeWrapper>
      {text && <RangeTextContainer>{text}</RangeTextContainer>}
      <StyledInput
        aria-label='Time'
        role='textbox'
        type='time'
        invalid={invalid}
        value={start !== undefined ? fromTsToHHMM(start) : undefined}
        onChange={(event) => {
          if (event.target.value) {
            setStart(fromHHMMtoTs(event.target.value))
          }
        }}
      />
      <SpaceContainer>-</SpaceContainer>
      <StyledInput
        aria-label='Time'
        role='textbox'
        type='time'
        invalid={invalid}
        value={end !== undefined ? fromTsToHHMM(end) : undefined}
        onChange={(event) => {
          if (event.target.value) {
            setEnd(fromHHMMtoTs(event.target.value))
          }
        }}
      />
    </StyledRangeWrapper>
  )
}
