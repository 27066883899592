import styled from 'styled-components'

// Credits to https://support.cargo.site/Make-an-Image-Fly-Across-the-Screen
export const Flier = styled.div`
  > * {
    animation: fly ${({ speed = 100 }: { speed?: number }) => speed}s linear infinite;
    top: 0;
    left: 0;
    transform: translateX(-120%) translateY(-120%) rotateZ(0);
    position: fixed;
    animation-delay: 1s;
    z-index: 999999;
  }

  @keyframes fly {
    98.001%,
    0% {
      display: block;
      transform: translateX(-200%) translateY(100vh) rotateZ(0deg);
    }

    15% {
      transform: translateX(100vw) translateY(-100%) rotateZ(180deg);
    }

    15.001%,
    18% {
      transform: translateX(100vw) translateY(-30%) rotateZ(0deg);
    }

    40% {
      transform: translateX(-200%) translateY(3vh) rotateZ(-180deg);
    }

    40.001%,
    43% {
      transform: translateX(-200%) translateY(-100%) rotateZ(-180deg);
    }

    65% {
      transform: translateX(100vw) translateY(50vh) rotateZ(0deg);
    }

    65.001%,
    68% {
      transform: translateX(20vw) translateY(-200%) rotateZ(180deg);
    }

    95% {
      transform: translateX(10vw) translateY(100vh) rotateZ(0deg);
    }
  }
`
