import polyline from '@mapbox/polyline'
import { ILineString } from './GeographyTypes'

export class PolylineUtils {
  public static encodeLineString(lineString: ILineString): string {
    return polyline.fromGeoJSON(lineString)
  }

  public static decodePolyline(line: string, precision?: number): ILineString {
    return polyline.toGeoJSON(line, precision) as ILineString
  }
}
