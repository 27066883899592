import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IGeocodeResponse, IGetGeocodeQueryParams, Resources } from '../types'

export class GeocodeResource extends GenericResource<IGeocodeResponse, IGetGeocodeQueryParams, never, never> {
  constructor(agent: AxiosInstance) {
    super(Resources.Geocode, agent)
  }

  public async get(params: IGetGeocodeQueryParams): Promise<IGeocodeResponse> {
    const res = await this.agent.get<IGeocodeResponse>(this.resource, { params })
    return res.data
  }
}
