import { GenericResource, IListModelQueryParamValues } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IAnalyzeReportJobPostBody, IAnalyzeReportJobResponse, Resources } from '../types'

export class AnalyzeReportJobResource extends GenericResource<
  IAnalyzeReportJobResponse,
  IListModelQueryParamValues,
  null,
  IAnalyzeReportJobPostBody
> {
  constructor(agent: AxiosInstance) {
    super(Resources.AnalyzeReportJobs, agent)
  }

  public get = this.buildGet()
  public post = this.buildPost()
}
