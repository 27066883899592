export enum PaymentFlow {
  /**
   * The client will need to do up front work of creating a charge object and go through any
   * required actions, such as 2FA. Once those are complete, the purchase can be created by setting chargeId
   */
  ClientHandled = 'clientHandled',
  /**
   * The client just needs to set paymentMethodId during the purchase.
   * Cash is considered a server handled payment method, and it is indicated by setting paymentMethodId to NULL
   */
  ServerHandled = 'serverHandled',
}
