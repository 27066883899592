import { IRouteOptimizationSolutionMetrics } from './RouteOptimizationTypes'

export interface ITriggeredOptimizationRequest {
  window: IGlobalOptimizationWindow
  targetRuntimeSeconds?: number
  fleetIds?: string[]
}

export interface ITriggeredOptimizationResponse {
  initialMetrics: IRouteOptimizationSolutionMetrics
  solutionMetrics: IRouteOptimizationSolutionMetrics
}

/**
 * A time window specifying which duties to include in a run of Global Optimization.
 *
 * If both the start and end of the window are "soft", then the duty must overlap the window, but it can optionally
 * start before the window start, or end after the window end.
 *
 * Hard boundaries cannot be exceeded. For example:
 *  - hard start, soft end:
 *    - the duty cannot start before the window starts, but can end after the window ends
 *  - soft start, hard end:
 *    - the duty can start before the window starts, but cannot end after the window ends
 *  - hard start, hard end:
 *    - the duty must be fully contained within the window (start after the window starts, end before the window ends)
 */
export interface IGlobalOptimizationWindow {
  start: { timestamp: number; boundaryType: GlobalOptimizationWindowBoundaryType }
  end: { timestamp: number; boundaryType: GlobalOptimizationWindowBoundaryType }
}

export enum GlobalOptimizationWindowBoundaryType {
  Hard = 'hard',
  Soft = 'soft',
}
