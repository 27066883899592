export enum PleoModalFeatures {
  LiveMessages = 'Live Messages',
  ManualIntervention = 'Manual Intervention Tools',
  EngageAIScan = 'Engage AI Scan',
  OptimizationInsights = 'Optimization Insights',
  Workflows = 'Workflows',
}

export interface IPleoLeadCreationBody {
  sourceNote: PleoModalFeatures
}
