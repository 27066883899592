import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IFleetAccessResponse,
  IFleetListQueryParams,
  IFleetPatchBody,
  IFleetPostBody,
  IFleetResponse,
  IListFleetAccessRulesResponse,
  Resources,
} from '../types'

export class FleetResource extends GenericResource<
  IFleetResponse,
  IFleetListQueryParams,
  IFleetPatchBody,
  IFleetPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.Fleets, agent)
  }

  public async getAccess(id: string): Promise<IListFleetAccessRulesResponse> {
    const res = await this.agent.get<IListFleetAccessRulesResponse>(`${this.resource}/${id}/access`)
    return res.data
  }

  public async getProvider(id: string): Promise<IFleetAccessResponse> {
    const res = await this.agent.get<IFleetAccessResponse>(`${this.resource}/${id}/provider`)
    return res.data
  }
}
