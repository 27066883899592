import { IListModelQueryParamValues } from '@sparelabs/http-client-utils'
import { z } from '@sparelabs/zod'

export interface IEventListQueryParams extends Omit<IListModelQueryParamValues, 'ids'> {
  objectId: string
  types: string[]
}

export enum EventSource {
  RiderInterface = 'riderInterface',
  DriverInterface = 'driverInterface',
  AdminInterface = 'adminInterface',
  Ivr = 'ivrInterface',
  Engine = 'engine',
  EngageRiderInterface = 'engageRiderInterface',
}

export const RiderEventSourceSchema = z.enum([
  EventSource.RiderInterface,
  EventSource.Ivr,
  EventSource.EngageRiderInterface,
])

export enum EventAuthorType {
  Rider = 'rider',
  Driver = 'driver',
  Admin = 'admin',
  Engine = 'engine',
  Flow = 'flow',
  UnknownUser = 'unknownUser',
}

export const RiderAuthorSchema = z.object({
  type: z.literal(EventAuthorType.Rider),
  userId: z.string().describe('The id of the rider that triggered the event'),
  source: RiderEventSourceSchema.optional(),
})

export const DriverAuthorSchema = z.object({
  type: z.literal(EventAuthorType.Driver),
  userId: z.string().describe('The id of the driver that triggered the event'),
})

export const AdminAuthorSchema = z.object({
  type: z.literal(EventAuthorType.Admin),
  userId: z.string().describe('The id of the admin that triggered the event'),
})

export const EngineAuthorSchema = z.object({
  type: z.literal(EventAuthorType.Engine),
})

export const FlowAuthorSchema = z.object({
  type: z.literal(EventAuthorType.Flow),
  workflowRunId: z.string().describe('The id of the workflow that triggered the event'),
})

// For legacy data, where we don't know what kind of user it is
export const UnknownUserAuthorSchema = z.object({
  type: z.literal(EventAuthorType.UnknownUser),
  userId: z.string().describe('The id of the user that triggered the event'),
})

export const EventAuthorSchema = z.union([
  RiderAuthorSchema,
  DriverAuthorSchema,
  AdminAuthorSchema,
  EngineAuthorSchema,
  FlowAuthorSchema,
  UnknownUserAuthorSchema,
])

export const OrganizationEventSchema = z.object({
  objectId: z
    .string()
    .describe(
      'The id of the object the event is "about". For example, for a "rider created event", this would be the id of the rider'
    ),
  type: z
    .string()
    .describe(
      'The type of event. For example, for a "rider created event", this would be something like "riderCreated"'
    ),
  organizationId: z.string().describe('The id of the organization that the event is associated with'),
  author: EventAuthorSchema.nullable().optional().describe('The author of the event'),

  /**
   * Note: something confusing! When WRITING an event, e.g. EventPublisher.publish, or consuming an event,
   * e.g. EventSubscriptionManager, occurredAt and publishedAt are the number of MILLISECONDS since the epoch.
   * But when READING an event, e.g. LIST /events or GET /events/:id against event-service, occurredAt and
   * publishedAt are the number of SECONDS since the epoch.
   */
  occurredAt: z.number().describe('The time the event occurred'),
  publishedAt: z.number().describe('The time the event was published'),
})

export type IEventAuthor = z.infer<typeof EventAuthorSchema>

export type IRiderEventSource = z.infer<typeof RiderEventSourceSchema>

export type IEventResponse = z.infer<typeof OrganizationEventSchema> & {
  id: string
  payload: Record<string, unknown>
}

const riderSources: Set<EventSource> = new Set([
  EventSource.RiderInterface,
  EventSource.EngageRiderInterface,
  EventSource.Ivr,
])

export const isRiderSource = (source: EventSource): source is IRiderEventSource => riderSources.has(source)

export const getAuthorUserId = (author: IEventAuthor): string | undefined => {
  switch (author.type) {
    case EventAuthorType.Rider:
    case EventAuthorType.Driver:
    case EventAuthorType.Admin:
    case EventAuthorType.UnknownUser:
      return author.userId
    default:
      return undefined
  }
}

export const getAuthorSource = (author: IEventAuthor): EventSource | undefined => {
  switch (author.type) {
    case EventAuthorType.Rider:
      return author.source
    default:
      return undefined
  }
}

export const getAuthorWorkflowRunId = (author: IEventAuthor): string | undefined => {
  switch (author.type) {
    case EventAuthorType.Flow:
      return author.workflowRunId
    default:
      return undefined
  }
}
