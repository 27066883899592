import { PermissionType } from '../PermissionType'

// This role is used for E2E testing purposes only, specifically just for
// creating and tearing down test orgs. It should not be used in production.
// This role should always have the bare minium permissions required to fulfill
// E2E testing needs.
export const TestSuperAdminRole: PermissionType[] = [
  PermissionType.POST_ANY_TEST_ORGANIZATION,
  PermissionType.POST_ANY_TEST_MOBILE_APP_ORGANIZATION,
  PermissionType.DELETE_ANY_TEST_MOBILE_APP_ORGANIZATION,
  PermissionType.JOIN_OWN_ORGANIZATION,
  PermissionType.PATCH_OWN_TEST_ORGANIZATION,
  PermissionType.DELETE_OWN_TEST_ORGANIZATION,
  PermissionType.GENERATE_OWN_USER_ORG_TOKENS,
  PermissionType.POST_TEST_ORG_PAYMENT_PROVIDER,
  PermissionType.POST_TEST_ORG_PAYMENT_METHOD_TYPE,
  PermissionType.PATCH_OWN_TEST_ORGANIZATION_CONFIGURATION,
]
