import { PermissionType } from '../PermissionType'

export const UserOnlyTokenRole: PermissionType[] = [
  PermissionType.LIST_ANY_ORGANIZATION,
  PermissionType.GENERATE_OWN_USER_ORG_TOKENS,

  // These are required here as the rider app has no userOrgToken set prior to organization selection so the userToken is used
  PermissionType.GET_AUTOCOMPLETE_SUGGESTIONS,
  PermissionType.GET_REVERSE_GEOCODE,
  PermissionType.GET_PLACE_DETAILS,

  // Required for OAuth flow to GET /apps prior to selecting an organization
  PermissionType.GET_ANY_APP,
]
