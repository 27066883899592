import { IPolygon } from '@sparelabs/geography'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { ITimeRule } from './TimeRuleTypes'

export enum AnnouncementStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Archived = 'archived',
}

export interface IGeofence {
  name: string
  polygon: IPolygon
}

// TODO find out if there is a better way to
// have strong type safety here without repeating the values
export enum FontAwesomeIconStyle {
  Solid = 'fas',
  Light = 'fal',
  Brand = 'fab',
  Regular = 'regular',
}

export enum AnnouncementIcon {
  Snowflake = 'snowflake',
  Raindrops = 'raindrops',
  ExclamationTriangle = 'exclamationTriangle',
  ExclamationCircle = 'exclamationCircle',
  CarCrash = 'carCrash',
  Subway = 'subway',
  Bus = 'bus',
  Ship = 'ship',
  Taxi = 'taxi',
  Bicycle = 'bicycle',
  Trees = 'trees',
  Bullhorn = 'bullhorn',
  BadgePercent = 'badgePercent',
  Tag = 'tag',
  TrafficLight = 'trafficLight',
}

export const AnnouncementIconFontAwesomeMap: Record<AnnouncementIcon, [FontAwesomeIconStyle, string]> = {
  [AnnouncementIcon.Snowflake]: [FontAwesomeIconStyle.Solid, 'snowflake'],
  [AnnouncementIcon.Raindrops]: [FontAwesomeIconStyle.Solid, 'raindrops'],
  [AnnouncementIcon.ExclamationTriangle]: [FontAwesomeIconStyle.Solid, 'exclamation-triangle'],
  [AnnouncementIcon.ExclamationCircle]: [FontAwesomeIconStyle.Solid, 'exclamation-circle'],
  [AnnouncementIcon.CarCrash]: [FontAwesomeIconStyle.Solid, 'car-crash'],
  [AnnouncementIcon.Subway]: [FontAwesomeIconStyle.Solid, 'subway'],
  [AnnouncementIcon.Bus]: [FontAwesomeIconStyle.Solid, 'bus'],
  [AnnouncementIcon.Ship]: [FontAwesomeIconStyle.Solid, 'ship'],
  [AnnouncementIcon.Taxi]: [FontAwesomeIconStyle.Solid, 'taxi'],
  [AnnouncementIcon.Bicycle]: [FontAwesomeIconStyle.Solid, 'bicycle'],
  [AnnouncementIcon.Trees]: [FontAwesomeIconStyle.Solid, 'trees'],
  [AnnouncementIcon.Bullhorn]: [FontAwesomeIconStyle.Solid, 'bullhorn'],
  [AnnouncementIcon.BadgePercent]: [FontAwesomeIconStyle.Solid, 'badge-percent'],
  [AnnouncementIcon.Tag]: [FontAwesomeIconStyle.Solid, 'tag'],
  [AnnouncementIcon.TrafficLight]: [FontAwesomeIconStyle.Solid, 'traffic-light'],
}

export interface IAnnouncementResponse extends IModelJsonValues, IAnnouncementPostBody {}

export interface IAnnouncementActiveResponse {
  announcements: IAnnouncementResponse[]
}

export interface IAnnouncementQueryParams extends IListModelQueryParamValues {
  status?: AnnouncementStatus | AnnouncementStatus[]
}

export interface IAnnouncementActiveQueryParams {
  latitude: number
  longitude: number
}

export interface IAnnouncementPostBody {
  startDate: string | null
  endDate: string | null
  timeRules: ITimeRule[]
  geofences: IGeofence[]
  status: AnnouncementStatus
  title: string
  details: string
  openUrl: string | null
  color: string
  icon: AnnouncementIcon
  priority: number
  isAcknowledgementRequired: boolean
}

export type IAnnouncementPatchBody = Partial<IAnnouncementPostBody>
