import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IWalletAutoTopUpPatchBody, IWalletAutoTopUpPostBody, IWalletAutoTopUpResponse, Resources } from '../types'

export class WalletAutoTopUpResource extends GenericResource<
  IWalletAutoTopUpResponse,
  never,
  IWalletAutoTopUpPatchBody,
  IWalletAutoTopUpPostBody
> {
  public patch = this.buildPatch()
  public post = this.buildPost()
  public delete = this.buildDel()

  constructor(agent: AxiosInstance) {
    super(Resources.WalletAutoTopUp, agent)
  }
}
