import { useEffect } from 'react'
import { useMount } from 'react-use'
import { useStopwatch } from './useStopwatch'

interface ICountdownOptions {
  /** Time to countdown from in milliseconds */
  time: number
  /** Start timer on initial mount */
  start?: boolean
}

interface ICountdown {
  /** Time left in milliseconds, 0ms indicated the countdown is not running */
  timeRemaining: number
  /** Start timer */
  start: () => void
  /**  Stops timer */
  stop: () => void
}

export const useCountdown = ({ time, start = false }: ICountdownOptions): ICountdown => {
  const watch = useStopwatch()
  const isRunning = watch.time > 0

  const startCountdown = () => {
    watch.start()
  }

  const stopCountdown = () => {
    watch.stop()
  }

  useMount(() => {
    if (start) {
      startCountdown()
    }
  })

  useEffect(() => {
    if (watch.time >= time) {
      stopCountdown()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch.time])

  return {
    timeRemaining: isRunning ? Math.max(time - watch.time, 0) : 0,
    start: startCountdown,
    stop: stopCountdown,
  }
}
