import { ApiError, IMetadataObject } from './ErrorTypes'

// Common metadata interface for OpenFleetError-based classes
export interface IOpenFleetErrorMetadata<T = unknown> extends IMetadataObject {
  message?: string
  code?: string
  providerStatus?: number
  providerResponse?: T
}

/**
 * This is the super-case for all errors that are thrown by OpenFleet implementations.
 * Please use the subclasses below to indicate the specific error type.
 */
export class OpenFleetError extends ApiError {
  constructor(status: number, messageInput: string, public readonly metadata: IOpenFleetErrorMetadata = {}) {
    super(status, messageInput, metadata)
  }
}

/**
 * This error is thrown when the OpenFleet implementation is unable to provide
 * an estimate for a trip.
 */
export class OpenFleetNoEstimateError extends OpenFleetError {
  public static status = 400

  constructor(messageInput: string, metadata: IOpenFleetErrorMetadata = {}) {
    super(OpenFleetNoEstimateError.status, messageInput, metadata)
  }
}

/**
 * This error is thrown when the provider (e.g. Uber) does not respond in time
 * or returns 5xx errors. This error has a 400-level status code to avoid tripping
 * alerts that call pagerduty and interfere with the CI/CD pipeline.
 */
export class OpenFleetProviderError extends OpenFleetError {
  public static status = 424

  constructor(messageInput: string, metadata: IOpenFleetErrorMetadata = {}) {
    super(OpenFleetProviderError.status, messageInput, metadata)
  }
}

/**
 * This error is thrown when 4xx errors are returned by the provider (e.g. Uber)
 * or from configuration issues.
 */
export class OpenFleetImplementationError extends OpenFleetError {
  public static status = 500

  constructor(messageInput: string, metadata: IOpenFleetErrorMetadata = {}) {
    super(OpenFleetImplementationError.status, messageInput, metadata)
  }
}

export interface IOpenFleetLimitErrorMetadata<L = unknown, R = unknown> extends IMetadataObject {
  type: string
  limit?: L
  requested?: R
}

/**
 * This error is thrown when the OpenFleet implementation is unable to provide
 * an estimate or fulfill a request due to a limit violation.
 */
export class OpenFleetLimitViolationError extends OpenFleetError {
  public static status = 400

  constructor(messageInput: string, metadata: IOpenFleetLimitErrorMetadata) {
    super(OpenFleetLimitViolationError.status, messageInput, metadata)
  }
}
