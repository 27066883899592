import { IListModelQueryParamValues } from '@sparelabs/http-client-utils'
import { WebhookType } from './WebhookTypes'

export interface IAppWebhookPostBody {
  appId: string
  type: WebhookType | AppWebhookType
  url: string
}

export type IAppWebhookPatchBody = Omit<Partial<IAppWebhookPostBody>, 'appId'>

export interface IAppWebhookResponse extends Required<IAppWebhookPostBody> {
  id: string
  createdAt: number
  updatedAt: number
}

export interface IListAppWebhookQueryParams extends IListModelQueryParamValues {
  appId: string
}

/**
 * naming pattern: resource.subResource:event
 * possible events: created, updated, deleted, archived
 */
export enum AppWebhookType {
  AppInstallationDeleted = 'appInstallation:deleted',
}

export interface IAppInstallationDeletedWebhookBody {
  type: AppWebhookType.AppInstallationDeleted
  data: null
}

export type AppWebhookBody = IAppInstallationDeletedWebhookBody
