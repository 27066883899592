import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum ChargePermissionSet {
  View = 'charge_view',
  Refund = 'charge_refund',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const chargePermissionSetGrouping: IPermissionSetGrouping<ChargePermissionSet> = {
  key: PermissionSetGroupingKey.Charge,
  active: {
    [ChargePermissionSet.View]: [
      PermissionType.LIST_ANY_CHARGE,
      PermissionType.GET_ANY_CHARGE,
    ],
    [ChargePermissionSet.Refund]: [PermissionType.POST_ANY_CHARGE_REFUND],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
