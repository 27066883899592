import { WebColors } from '@sparelabs/colors'
import { isNumber } from '@sparelabs/core'
import { HOUR, TimezoneHelper } from '@sparelabs/time'
import React from 'react'
import styled from 'styled-components'
import { st } from '../../locales/TranslationHelper'

const Container = styled.div`
  color: ${WebColors.negativePrimary};
  font-size: 15px;
  line-height: 20px;
`

interface IProps {
  date: string | undefined
  time: number | undefined
  timezone: string
}

export const DstSprintForwardHint = ({ date, time, timezone }: IProps): JSX.Element | null => {
  if (!date || !isNumber(time)) {
    return null
  }

  const buildTs = (time: number) => TimezoneHelper.buildTimestamp(date, time, timezone)

  return buildTs(time) === buildTs(time + HOUR) ? (
    <Container role='alert'>{st.inputs.dateTime.dst.springForward.hint()}</Container>
  ) : null
}
