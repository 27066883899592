import { WebColors } from '@sparelabs/colors'
import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { borderRadius } from '../util'

export type TabBarItemComponent = React.ComponentType<React.HTMLAttributes<HTMLButtonElement> & { isActive: boolean }>

export const StyledTabBarItem: TabBarItemComponent = styled.button<{ isActive: boolean }>`
  all: initial;
  font: inherit;
  background-color: ${WebColors.backgroundPrimary};
  display: inline-block;
  padding: 16px;
  font-size: 15px;
  flex: 1;
  text-align: left;
  box-sizing: border-box;
  color: ${(props) => (props.isActive ? WebColors.interactivePrimary : WebColors.contentPrimary)};
  border-bottom: 4px solid ${(props) => (props.isActive ? WebColors.interactivePrimary : 'transparent')};

  &:not(:disabled) {
    cursor: pointer;
  }

  &:not(:last-of-type) {
    border-right: 1px solid ${WebColors.borderPrimary};
  }

  // Round corners of the first and last tab
  &:first-of-type {
    border-top-left-radius: ${borderRadius};
    border-bottom-left-radius: ${borderRadius};
  }
  &:last-of-type {
    border-top-right-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
  }

  // Ensure outline doesn't get covered by other tabs
  &:focus {
    position: relative;
    z-index: 1;
  }
`
StyledTabBarItem.displayName = 'TabBarItem'

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  StyledTabItem?: TabBarItemComponent
  isActive: boolean
  ref?: React.Ref<HTMLButtonElement>
  children?: React.ReactNode
}

export const TabBarItem = forwardRef(
  ({ StyledTabItem = StyledTabBarItem, children, isActive, ...rest }: IProps, ref) => (
    <StyledTabItem ref={ref} isActive={isActive} {...rest}>
      {children}
    </StyledTabItem>
  )
)

TabBarItem.displayName = 'TabBarItem'
