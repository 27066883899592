import { assertExists, IdMap } from '@sparelabs/core'
import {
  AccessibilityFeature,
  IRequestAccessibilityFeature,
  IRequestRiderType,
  IVehicleAccessibilityFeature,
  RiderSemantics,
  VehicleCapacityType,
} from '..'
import { IVehicleCapacityForAcceptReject } from './VehicleOccupancyTypes'

export interface IRequestOccupancy {
  riders: IRequestRiderType[]
  accessibilityFeatures: IRequestAccessibilityFeature[]
}

export class VehicleOccupancyState {
  // availableSeats and availableFeatures are made public only for tests.
  public availableSeats: number
  public availableFeatures: Map<AccessibilityFeature, number>
  private readonly featureMap: Map<AccessibilityFeature, IVehicleAccessibilityFeature>
  private readonly requestsOnBoard: IdMap<IRequestOccupancy> = new Map<string, IRequestOccupancy>()
  private isWellDefined: boolean = true

  constructor(vehicle: IVehicleCapacityForAcceptReject) {
    if (vehicle.capacityType === VehicleCapacityType.Legacy) {
      this.availableSeats = assertExists(vehicle.passengerSeats)
      /*
       * TODO: We do NOT currently support having multiple of the same feature type with different seat costs.
       * eg. Adding a wheelchair to a vehicle might require lifting up a 2 or 3-seat bench, so the seat cost varies.
       * In the current implementation, if the features array has duplicates, we take the last definition.
       */
      this.featureMap = new Map(
        assertExists(vehicle.accessibilityFeatures).map(
          (feature): [AccessibilityFeature, IVehicleAccessibilityFeature] => [feature.type, feature]
        )
      )
      this.availableFeatures = new Map(
        assertExists(vehicle.accessibilityFeatures).map((feature): [AccessibilityFeature, number] => [
          feature.type,
          feature.count,
        ])
      )
    } else {
      throw new Error('Unsupported vehicle type: ' + vehicle.capacityType)
    }
  }

  /**
   * @returns true if the operation succeeds, false otherwise
   */
  public attemptPickup(id: string, request: IRequestOccupancy): boolean {
    if (!this.isWellDefined) {
      throw new Error('Cannot complete any further operations once vehicle has entered an undefined state')
    }
    this.isWellDefined = this.attemptPickupHelper(id, request)
    return this.isWellDefined
  }

  /**
   * @returns true if the operation succeeds, false otherwise
   */
  private attemptPickupHelper(id: string, request: IRequestOccupancy): boolean {
    // First, subtract number of riders
    this.availableSeats -= RiderSemantics.getNumRiders(request.riders)
    // Then, calculate how many seats their accessibility features require
    for (const requestFeature of request.accessibilityFeatures) {
      const vehicleFeatureConfig = this.featureMap.get(requestFeature.type)
      if (!vehicleFeatureConfig) {
        // Request cannot be added because vehicle does not support accessibility features of this type
        return false
      }
      // Remove the additional seat cost for these features
      this.availableSeats -= vehicleFeatureConfig.seatCost * requestFeature.count
      // Record the usage of an available feature
      const currentFeatureAvailableCount = this.availableFeatures.get(vehicleFeatureConfig.type)
      if (currentFeatureAvailableCount === undefined) {
        throw new Error('featureMap is out of sync with availableFeatures')
      }
      const newFeatureAvailableCount = currentFeatureAvailableCount - requestFeature.count
      if (newFeatureAvailableCount < 0) {
        // There are no more ${requestFeature.type} allowances on this vehicle
        return false
      }
      this.availableFeatures.set(vehicleFeatureConfig.type, newFeatureAvailableCount)
    }
    if (this.availableSeats < 0) {
      // There are not enough seats on this vehicle for this request
      return false
    }
    this.requestsOnBoard.set(id, request)
    return true
  }
}
