import { EnumValues } from 'enum-values'
import { FeatureType, GeometryType, IFeature, IFeatureCollection, IGeometry } from './GeographyTypes'

const isObject = (json: any): boolean =>
  // watch out, null is an object!
  typeof json === 'object' && json !== null

export class GeoJsonUtils {
  public static isFeatureCollection(json: any): json is IFeatureCollection {
    return isObject(json) && json.type === FeatureType.FeatureCollection && Array.isArray(json.features)
  }

  public static isFeature(json: any): json is IFeature {
    return isObject(json) && json.type === FeatureType.Feature && json.geometry !== undefined
  }

  public static isValidGeometry(json: any): json is IGeometry {
    return isObject(json) && EnumValues.getValues(GeometryType).includes(json.type) && Array.isArray(json.coordinates)
  }
}
