import { ITranslationStrings } from '@sparelabs/translate'
import { ILocaleTranslationKeys } from './keys'

const en: ITranslationStrings<ILocaleTranslationKeys> = {
  inputs: {
    choice: {
      selectOptionPrompt: 'Please select an option',
      yes: 'Yes',
      no: 'No',
    },
    file: {
      sizeMb: '{{size}} MB',
    },
    multipleFile: {
      inProgress: 'Uploading: {{count}}',
      completed: 'Completed: {{count}}',
      failed: 'Failed: {{count}}',
    },
    dropzone: {
      selectFile: 'Select file',
      dragDropFile: 'or drag and drop file here',
      viewButton: 'View Uploaded File',
      chooseFileButton: 'Choose File',
      chooseFilesButton: 'Choose Files',
      deleteButton: 'Remove Uploaded File',
      fileNameLabel: 'Uploaded File:',
      fileSizeLabel: 'Uploaded File Size:',
      noFileSelected: 'No file selected:',
      invalidFileTypeMultipleFormats: 'File must be either {{fileTypes}}, please try again.',
      invalidFileTypeSingle: 'File must be a {{fileTypes}}, please try again.',
      invalidFileSize: 'File size is too large, must be under {{fileSize}} MB',
      tooManyFiles: 'Only one file may be uploaded at a time',
      genericError: 'Something went wrong please try again or contact support',
      error: 'Error:',
      description: 'File must be smaller than {{maxFileSizeMB}}MB and one of the following: {{fileTypes}}.',
    },
    photo: {
      prompt: 'Upload an image',
    },
    shared: {
      uploading: 'Uploading...',
      uploadFailed: 'Upload failed',
    },
    drawing: {
      clear: 'Clear',
    },
    dateTime: {
      dst: {
        springForward: {
          hint: 'This time is unavailable due to the Daylight Saving Time change. Please select another time.',
        },
        fallBack: {
          hint: 'Due to the Daylight Saving Time change, this time occurs twice. Please specify which one should be used.',
          beforeTimeSetBack: 'Before clocks are set back',
          afterTimeSetBack: 'After clocks are set back',
        },
      },
      accessibleTimeHint: '{{time}} in 24 hour format',
      selectDateLabel: 'Select Date',
      selectTimeLabel: 'Select Time',
    },
    markdown: {
      variables: 'Variables',
      variablesHint: 'Insert custom variables',
    },
  },
  foundations: {
    restrictedToRootAdmins: 'This functionality is currently restricted to Spare Super Admins.',
  },
  date: {
    dateInputMessage: 'Enter a date or use the arrow keys to change the date',
    dateRangePicker: {
      startDateMessage: 'Enter a start date or use the arrow keys to change the start date',
      endDateMessage: 'Enter an end date or use the arrow keys to change the end date',
    },
  },
  modal: {
    close: 'Close',
  },
  common: {
    none: 'None',
    required: '(required)',
  },
  errors: {
    loadingData: 'Error loading data from server. Please refresh the page.',
  },
  cutoffContainer: {
    showMore: 'Show more',
    showLess: 'Show less',
  },
}

// eslint-disable-next-line import/no-default-export
export default en
