import { z } from '@sparelabs/zod'

export enum AccessibilityFeature {
  Wheelchair = 'wheelchair',
  FoldingWheelchair = 'foldingWheelchair',
  PoweredWheelchair = 'poweredWheelchair',
  OversizedWheelchair = 'oversizedWheelchair',

  Scooter = 'scooter',
  OversizedScooter = 'oversizedScooter',

  Walker = 'walker',
  OversizedWalker = 'oversizedWalker',
  OnlyCarWalker = 'onlyCarWalker',

  OnlyCarRider = 'onlyCarRider',
  ExtraSpaceAmbulant = 'extraSpaceAmbulant',
  ActualDoorToDoor = 'actualDoorToDoor',

  Lift = 'lift',
  OversizedItem = 'oversizedItem',

  Stroller = 'stroller',
  ChildSeatBaby = 'childSeatBaby', // for 0-3 ages
  ChildSeatYoung = 'childSeatYoung', // for 3-10 ages

  Bike = 'bike',
  WhiteCane = 'whiteCane',
  ServiceAnimal = 'serviceAnimal',
  OxygenTank = 'oxygenTank',
  Stretcher = 'stretcher',
}

export interface IVehicleAccessibilityFeature {
  type: AccessibilityFeature
  count: number
  seatCost: number
  requireFirstInLastOut: boolean
}

export const RequestAccessibilityFeatureSchema = z.object({
  type: z.nativeEnum(AccessibilityFeature),
  count: z.number(),
})

export type IRequestAccessibilityFeature = z.infer<typeof RequestAccessibilityFeatureSchema>

export const ServiceAccessibilityFeatureSchema = z.object({
  type: z.nativeEnum(AccessibilityFeature),
  defaultExtraBoardingTime: z.number(),
})

export type IServiceAccessibilityFeature = z.infer<typeof ServiceAccessibilityFeatureSchema>

export enum AccessibilitySetting {
  Count = 'count',
  SeatCost = 'seatCost',
}
