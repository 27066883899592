import { WebColors } from '@sparelabs/colors'
import React from 'react'
import styled from 'styled-components'
import { AsyncAbstractButton } from '../controls/AsyncAbstractButton'
import { PopoverWithDescription } from './PopoverDescription'
import { IPopoverItemProps } from './PopoverItem'

export interface IAsyncPopoverItemProps extends IPopoverItemProps {
  onClick: () => Promise<void>
}

const DefaultAsyncPopoverItem = styled(AsyncAbstractButton)<{ width?: string }>`
  padding: 0.5rem 1rem;
  text-align: start;
  white-space: pre-line;
  width: ${({ width }) => width || 'auto'};
  justify-content: start;
  box-shadow: none !important;
  &:hover {
    background-color: ${WebColors.backgroundSecondary} !important;
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline-offset: -2px;
    border-radius: 8px;
  }
`

export const AsyncPopoverItem = ({
  onClick,
  title,
  icon,
  width,
  disabled,
  description,
  iconColor,
  validateActionText,
}: IAsyncPopoverItemProps): JSX.Element => (
  <DefaultAsyncPopoverItem
    iconColor={iconColor}
    disabled={disabled}
    width={width}
    onClick={async () => {
      await onClick()
    }}
    icon={icon}
    validateActionText={validateActionText}
  >
    {description ? <PopoverWithDescription title={title} description={description} /> : title}
  </DefaultAsyncPopoverItem>
)
