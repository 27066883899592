import { z } from '@sparelabs/zod'

export interface INylasAuthPostBody {
  returnUrl: string
}

export interface INylasAuthResponse {
  authorizationUrl: string
}

export interface INylasCallbackPostBody {
  code: string
  state: string
}

export const INylasStateSchema = z.object({
  userId: z.string().describe('The id of the spare user'),
  organizationId: z.string().describe('The id of the Spare organization'),
  returnUrl: z.string().describe('The URL to redirect to after the Nylas flow is completed'),
  expiryTs: z.number().describe('The Unix seconds timestamp when the state will expire'),
})

export type INylasState = z.infer<typeof INylasStateSchema>

export enum NylasProvider {
  Google = 'google',
  Imap = 'imap',
  Microsoft = 'microsoft',
  Icloud = 'icloud',
  VirtualCalendar = 'virtual-calendar',
}
