import { PermissionSetType, RiderPermissionSet } from '../sets'
import { AppPermissionSet } from '../sets/AppPermissions'
import { WebhookPermissionSet } from '../sets/WebhookPermissions'
import { WorkflowPermissionSet } from '../sets/WorkflowPermissions'
import { AdminOperatorRole } from './AdminOperatorRole'
import { OperationsManagerRole } from './OperationsManagerRole'
import { ServicePlannerRole } from './ServicePlannerRole'

export const DeveloperRole: PermissionSetType[] = [
  ...OperationsManagerRole,
  ...ServicePlannerRole,
  ...AdminOperatorRole,

  // TODO: Replace rider permission set with more limited permission sets
  RiderPermissionSet.Rider,

  AppPermissionSet.View,
  AppPermissionSet.Manage,
  AppPermissionSet.AppInstallationView,
  AppPermissionSet.AppInstallationManage,
  WorkflowPermissionSet.View,
  WorkflowPermissionSet.Write,
  WorkflowPermissionSet.Delete,
  WebhookPermissionSet.View,
  WebhookPermissionSet.Write,
]
