import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { CreatedInterface } from './EstimateTypes'
import { IPaymentProviderPatchBody, IPaymentProviderPostBody, IPaymentProviderResponse } from './PaymentProviderTypes'
import { ITicketOption, ITicketRiderOption, TicketExpiryOption } from './TicketTypes'

export enum PaymentMethodTypeStatus {
  Active = 'active',
  Archived = 'archived',
}

export interface IPaymentMethodTypePostBody {
  name: string
  description?: string
  purchaseButtonEnabled?: boolean
  purchaseButtonText?: string
  purchaseButtonLink?: string
  availableToInterfaces?: CreatedInterface[]
  paymentProvider?: IPaymentProviderPostBody
  configType?: PaymentMethodTypeConfigType
  config?: PaymentMethodTypeConfig
}

export interface IPaymentMethodTypePatchBody
  extends Partial<Omit<IPaymentMethodTypePostBody, 'paymentProvider' | 'configType'>> {
  status?: PaymentMethodTypeStatus
  paymentProvider?: IPaymentProviderPatchBody
}

export interface IPaymentMethodTypeResponse extends IModelJsonValues {
  name: string
  description: string
  status: PaymentMethodTypeStatus
  isEditable: boolean
  isDefault: boolean
  purchaseButtonEnabled: boolean
  purchaseButtonText: string
  purchaseButtonLink: string
  paymentProviderId: string | null
  paymentProvider: IPaymentProviderResponse | null
  availableToInterfaces: CreatedInterface[]
  configType: PaymentMethodTypeConfigType
  config: PaymentMethodTypeConfig
}

export interface IPaymentMethodTypeListQueryParams extends IListModelQueryParamValues {
  status?: PaymentMethodTypeStatus
  availableToInterface?: CreatedInterface
}

export interface IPaymentMethodTypeStub extends Pick<IPaymentMethodTypeResponse, 'id' | 'name' | 'description'> {
  paymentProvider: Pick<IPaymentProviderResponse, 'type'> | null
}

export enum PaymentMethodTypeConfigType {
  None = 'none',
  Ticket = 'ticket',
}

export type PaymentMethodTypeConfig = IPaymentMethodTypeNoneConfig | IPaymentMethodTypeTicketConfig

// enforces an empty object
export type IPaymentMethodTypeNoneConfig = Record<string, never>

export interface IPaymentMethodTypeTicketConfig {
  ticketOptions: ITicketOption[]
  riderOptions: ITicketRiderOption[]
  expiryOptions: TicketExpiryOption[]
  // ticket option key -> expiry option key -> rider option key -> cost
  cost: Record<string, Record<string, Record<string, number | null>>>
}
