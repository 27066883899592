import { IModelJsonValues } from '@sparelabs/http-client-utils'
import { PermissionType } from '@sparelabs/permissions'
import { MINUTE } from '@sparelabs/time'
import { RequestCancellationFault } from './RequestCancellationTypes'
import { WorkflowIssue } from './WorkflowIssueTypes'

export enum AlertType {
  NoScheduledDuties = 'noScheduledDuties',
  LateDuties = 'lateDuties',
  LateDutyStart = 'lateDutyStart',
  NeedVehicleLocationUpdate = 'needVehicleLocationUpdate',
  NegativeReviewPosted = 'negativeReviewPosted',
  PositiveCommentReviewPosted = 'positiveCommentReviewPosted',
  NoShowCancellation = 'noShowCancellation',
  SystemAtCapacity = 'systemAtCapacity',
  NoDriversAvailable = 'noDriversAvailable',
  IdleVehicle = 'idleVehicle',
  StrandedRequestsDetected = 'strandedRequestsDetected',
  SimultaneousDriverLogin = 'simultaneousDriverLogin',
  RecurringTripFailure = 'recurringTripFailure',
  CaseAssignment = 'caseAssignment',
  ImportErrors = 'importErrors',
  WorkflowAdminNotification = 'workflowAdminNotification',
  WorkflowIssues = 'workflowIssues',
}

export const alertTypeToLabel = (type: AlertType, customLabel?: string): string => {
  switch (type) {
    case AlertType.NoScheduledDuties:
      return 'No Scheduled Duties'
    case AlertType.LateDuties:
      return 'Late Duty'
    case AlertType.LateDutyStart:
      return 'Late Duty Start'
    case AlertType.NeedVehicleLocationUpdate:
      return 'Lost Vehicle Location'
    case AlertType.NegativeReviewPosted:
      return 'Negative Review Posted'
    case AlertType.PositiveCommentReviewPosted:
      return 'Positive Review Posted With Comment'
    case AlertType.NoShowCancellation:
      return 'No Show Cancellation'
    case AlertType.SystemAtCapacity:
      return 'System At Capacity'
    case AlertType.NoDriversAvailable:
      return 'No Drivers Available'
    case AlertType.IdleVehicle:
      return 'Idle Vehicle'
    case AlertType.StrandedRequestsDetected:
      return 'Stranded Requests Detected'
    case AlertType.SimultaneousDriverLogin:
      return 'Simultaneous Driver Login'
    case AlertType.RecurringTripFailure:
      return 'Recurring Trip Failure'
    case AlertType.CaseAssignment:
      return 'Case Assignment'
    case AlertType.ImportErrors:
      return 'Import Errors'
    case AlertType.WorkflowAdminNotification:
      return customLabel ? customLabel : 'Custom Workflow Notification'
    case AlertType.WorkflowIssues:
      return 'Workflow Issues'
  }
}

export const alertTypeToPermission = (type: AlertType): PermissionType => {
  switch (type) {
    case AlertType.LateDuties:
      return PermissionType.RECEIVE_LATE_DUTIES_ALERT
    case AlertType.LateDutyStart:
      return PermissionType.RECEIVE_LATE_DUTY_START_ALERT
    case AlertType.NeedVehicleLocationUpdate:
      return PermissionType.RECEIVE_NEED_VEHICLE_LOCATION_UPDATE_ALERT
    case AlertType.NoScheduledDuties:
      return PermissionType.RECEIVE_NO_SCHEDULED_DUTIES_ALERT
    case AlertType.PositiveCommentReviewPosted:
      return PermissionType.RECEIVE_POSITIVE_COMMENT_REVIEW_POSTED_ALERT
    case AlertType.NegativeReviewPosted:
      return PermissionType.RECEIVE_NEGATIVE_REVIEW_POSTED_ALERT
    case AlertType.NoShowCancellation:
      return PermissionType.RECEIVE_NO_SHOW_CANCELLATION_ALERT
    case AlertType.SystemAtCapacity:
      return PermissionType.RECEIVE_SYSTEM_AT_CAPACITY_ALERT
    case AlertType.NoDriversAvailable:
      return PermissionType.RECEIVE_NO_DRIVERS_AVAILABLE_ALERT
    case AlertType.IdleVehicle:
      return PermissionType.RECEIVE_IDLE_VEHICLE_ALERT
    case AlertType.StrandedRequestsDetected:
      return PermissionType.RECEIVE_STRANDED_REQUESTS_ALERT
    case AlertType.SimultaneousDriverLogin:
      return PermissionType.RECEIVE_SIMULTANEOUS_DRIVER_LOGIN_ALERT
    case AlertType.RecurringTripFailure:
      return PermissionType.RECEIVE_RECURRING_TRIP_FAILURE_ALERT
    case AlertType.CaseAssignment:
      return PermissionType.RECEIVE_CASE_ASSIGNMENT_ALERT
    default:
      return PermissionType.POST_OWN_ALERT_SUBSCRIPTION
  }
}

export const LATE_DUTIES_THRESHOLD_S = 10 * MINUTE
export const LATE_DUTY_START_THRESHOLD_S = 5 * MINUTE
export const NEED_VEHICLE_LOCATION_UPDATE_THRESHOLD_S = 5 * MINUTE
export const SYSTEM_AT_CAPACITY_THRESHOLD_S = 20 * MINUTE
export const IDLE_VEHICLE_THRESHOLD_S = 10 * MINUTE

// TODO: This mapping should live in the frontend instead of the API client
// https://github.com/sparelabs/spare/pull/10956#discussion_r1128584245
export const alertThresholdS: Record<AlertType, number | null> = {
  [AlertType.LateDuties]: LATE_DUTIES_THRESHOLD_S,
  [AlertType.LateDutyStart]: LATE_DUTY_START_THRESHOLD_S,
  [AlertType.NeedVehicleLocationUpdate]: NEED_VEHICLE_LOCATION_UPDATE_THRESHOLD_S,
  [AlertType.NoScheduledDuties]: null,
  [AlertType.PositiveCommentReviewPosted]: null,
  [AlertType.NegativeReviewPosted]: null,
  [AlertType.NoShowCancellation]: null,
  [AlertType.SystemAtCapacity]: SYSTEM_AT_CAPACITY_THRESHOLD_S,
  [AlertType.NoDriversAvailable]: null,
  [AlertType.IdleVehicle]: IDLE_VEHICLE_THRESHOLD_S,
  [AlertType.StrandedRequestsDetected]: null,
  [AlertType.SimultaneousDriverLogin]: null,
  [AlertType.RecurringTripFailure]: null,
  [AlertType.CaseAssignment]: null,
  [AlertType.ImportErrors]: null,
  [AlertType.WorkflowAdminNotification]: null,
  [AlertType.WorkflowIssues]: null,
}

export enum AlertDeliveryMethod {
  Admin = 'admin',
  Email = 'email',
}

export interface IAlertResponse extends IModelJsonValues {
  alertType: AlertType
  metadata: any
}

export interface IUserAlertResponse extends IModelJsonValues {
  alertId: string
  alert: IAlertResponse
  isRead: boolean
  deliveryMethod: AlertDeliveryMethod
  userId: string
}

export interface IUserAlertPatchBody {
  isRead: boolean
  userId: string
}

export interface IAlertSubscription {
  id: string
  organizationId: string
  userId: string
  deliveryMethod: AlertDeliveryMethod
  alertType: AlertType
}

export interface IAlertSubscriptionPostBody {
  userId: string
  deliveryMethod: AlertDeliveryMethod
  alertType: AlertType
}

export interface IAlertPostBody {
  organizationId: string
  metadata: MetadataType
  userIds?: string[]
}

export type MetadataType =
  | ILateDutyMeta
  | ILateDutyStartMeta
  | INeedVehicleLocationUpdateDutyMeta
  | INoScheduledDutiesMeta
  | IReviewPostedMeta
  | INoShowCancellationMeta
  | ISystemAtCapacityMeta
  | IIdleVehicleMeta
  | IStrandedRequestsDetectedMeta
  | ISimultaneousDriverLoginMeta
  | IRecurringTripFailureMeta
  | ICaseAssignmentMeta
  | INoDriversAvailableMeta
  | IImportErrorsMeta
  | IWorkflowAdminNotificationMeta
  | IWorkflowIssuesMeta

interface IEmailMeta<T extends AlertType> {
  type: T
}

export interface ILateDutyMeta extends IEmailMeta<AlertType.LateDuties> {
  fleetName: string
  dutyId: string
  dutyStartRequestedTs: number
  thresholdMin: number
}

export interface ILateDutyStartMeta extends IEmailMeta<AlertType.LateDutyStart> {
  fleetName: string
  dutyId: string
  dutyStartRequestedTs: number
  thresholdMin: number
  driverName: string
  dutyStartTime: string
}

export interface INeedVehicleLocationUpdateDutyMeta extends IEmailMeta<AlertType.NeedVehicleLocationUpdate> {
  fleetName: string
  dutyId: string
  dutyStartRequestedTs: number
  thresholdMin: number
}

export interface INoScheduledDutiesMeta extends IEmailMeta<AlertType.NoScheduledDuties> {
  serviceName: string
  serviceId: string
}

type ReviewAlertType = AlertType.PositiveCommentReviewPosted | AlertType.NegativeReviewPosted

export interface IReviewPostedMeta extends IEmailMeta<ReviewAlertType> {
  reviewId: string
  rating: number
  comment: string | null
}

export interface INoShowCancellationMeta extends IEmailMeta<AlertType.NoShowCancellation> {
  serviceName: string
  requestId: string
  fault: RequestCancellationFault
}

export interface ISystemAtCapacityMeta extends IEmailMeta<AlertType.SystemAtCapacity> {
  serviceId: string
  serviceName: string
  waitTimeMin: number
}

export interface IIdleVehicleMeta extends IEmailMeta<AlertType.IdleVehicle> {
  dutyId: string
  fleetName: string
  notMovedMin: number
}

export interface IStrandedRequestsDetectedMeta extends IEmailMeta<AlertType.StrandedRequestsDetected> {
  requestId: string
  strandedForMin: number
  serviceName: string
}

export interface ISimultaneousDriverLoginMeta extends IEmailMeta<AlertType.SimultaneousDriverLogin> {
  dutyId: string
}

export interface IRecurringTripFailureMeta extends IEmailMeta<AlertType.RecurringTripFailure> {
  recurrenceId: string
  serviceName: string
  failureReason: string
}

export interface ICaseAssignmentMeta extends IEmailMeta<AlertType.CaseAssignment> {
  caseId: string
  caseType: string
  caseTypeKey: string
  assignedByName: string
}

export interface INoDriversAvailableMeta extends IEmailMeta<AlertType.NoDriversAvailable> {
  serviceId: string
  serviceName: string
  requestId: string
}

export interface IImportErrorsMeta extends IEmailMeta<AlertType.ImportErrors> {
  model: string
  csvUrl: string
  modelPath: string
}

export interface IWorkflowAdminNotificationMeta extends IEmailMeta<AlertType.WorkflowAdminNotification> {
  alertContent: {
    message: string
    link?: string
  }
  emailContent: {
    subject: string
    message: string
    link?: string
  }
}

export interface IWorkflowIssuesMeta extends IEmailMeta<AlertType.WorkflowIssues> {
  workflowId: string
  workflowName: string
  issue: WorkflowIssue
}
