import type { AsyncLocalStorage } from 'async_hooks'

export class ContextPropagator<C> {
  private readonly storage: AsyncLocalStorage<C> | null

  constructor() {
    this.storage = this.createStorage()
  }

  public runWithContext<T>(context: C, callback: () => T): T {
    if (!this.storage) {
      return callback()
    }

    return this.storage.run(context, () => callback())
  }

  public getContext(): C | null {
    return this.storage?.getStore() ?? null
  }

  private createStorage(): AsyncLocalStorage<C> | null {
    // Do not import CLS on platforms other than Node.js.
    if (!process?.versions?.node) {
      return null
    }

    // Import continuation-local-storage dynamically to make this class compatible on platforms other than Node.js.
    // eslint-disable-next-line
    return new (require('async_hooks').AsyncLocalStorage)() as AsyncLocalStorage<C>
  }
}
