import { findCurrency, format } from 'currency-formatter'
import { StripeCurrencyHelper } from './StripeCurrencyHelper'
import { Currency } from './types/CurrencyTypes'

// TODO ideally we would be using Intl.NumberFormat here but the support is
// inconsistent across different browsers / node / react-native.

export class CurrencyHelper {
  public static format(amount: number, currency: Currency): string {
    if (!Number.isInteger(amount)) {
      throw new Error(
        'Current amount is expected to be an integer because it should always be in the base unit of the supplied currency'
      )
    }
    return this.localizeAmount(this.normalizeAmount(amount, currency), currency)
  }

  public static getCurrencySymbol(currency: Currency): string {
    return findCurrency(currency.toUpperCase()).symbol
  }

  public static normalizeAmount(amount: number, currency: Currency): number {
    if (StripeCurrencyHelper.isZeroDecimal(currency)) {
      return amount
    }
    return amount / 100
  }

  private static localizeAmount(amount: number, currency: Currency): string {
    return format(amount, {
      code: currency.toUpperCase(),
      precision: StripeCurrencyHelper.isZeroDecimal(currency) ? 0 : undefined,
    })
  }

  public static convertAmountToBaseValue(amount: string, currency: Currency): number {
    if (StripeCurrencyHelper.isZeroDecimal(currency)) {
      return parseInt(amount)
    }
    return Math.round(100 * parseFloat(amount))
  }

  public static isCurrency(currency: string): currency is Currency {
    return (Object.values(Currency) as string[]).includes(currency)
  }
}
