import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { IInputProps } from '../../InputTypes'
import { ISingleChoice } from '../SingleChoice'
import { SingleChoiceCustomInput } from '../SingleChoiceCustomInput'

export interface ISingleChoiceIconInput extends Omit<IInputProps<string>, 'onChange'> {
  iconMap: { [key: string]: IconProp }
  onChange: (value: string) => void
  id?: string
  value?: string
  disabled?: boolean
}

interface IIconChoice extends ISingleChoice {
  icon: IconProp
}

export const SingleChoiceIconInput = ({
  iconMap,
  onChange,
  value,
  disabled,
  id,
  ...inputAttributes
}: ISingleChoiceIconInput): JSX.Element => {
  const renderButtonLabel = (icon: IconProp) => <FontAwesomeIcon icon={icon} />

  return (
    <SingleChoiceCustomInput<IIconChoice>
      {...inputAttributes}
      choices={Object.entries(iconMap).map(([value, icon]) => ({
        value,
        icon,
      }))}
      renderOption={(choice: IIconChoice) => renderButtonLabel(choice.icon)}
      onChange={(choice: IIconChoice) => onChange(choice.value)}
      value={value}
      disabled={disabled}
      id={id}
    />
  )
}
