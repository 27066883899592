import { AxiosInstance } from 'axios'
import { IMetabaseStaticEmbedBody, IMetabaseStaticEmbedResponse } from '../types/MetabaseTypes'

export class MetabaseResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async staticEmbed(body: IMetabaseStaticEmbedBody): Promise<IMetabaseStaticEmbedResponse> {
    const res = await this.agent.post<IMetabaseStaticEmbedResponse>('metabase/staticEmbed', body)
    return res.data
  }
}
