import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import { IInputProps } from '../../InputTypes'
import { LARGE_CONTAINER_SIZE_PX } from '../constants'
import { ISingleChoice } from '../SingleChoice'
import { SingleChoiceCustomInput } from '../SingleChoiceCustomInput'

const LargeIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin-bottom: 15px;
`

interface IFancyChoice extends ISingleChoice {
  label: string
  icon: IconProp
}

export interface ISingleChoiceFancyInput extends Omit<IInputProps<string>, 'onChange'> {
  iconMap: { [key: string]: IconProp }
  choices: Array<{ label: string; value: string }>
  onChange: (value: string) => void
  disabled?: boolean
  value?: string
  id?: string
}

export const SingleChoiceFancyInput = ({
  iconMap,
  choices,
  onChange,
  disabled,
  value,
  id,
  ...inputAttributes
}: ISingleChoiceFancyInput): JSX.Element => {
  const renderButtonLabel = (choiceLabel: string, icon: IconProp) => (
    <>
      <LargeIcon icon={icon} />
      {choiceLabel}
    </>
  )

  return (
    <SingleChoiceCustomInput<IFancyChoice>
      {...inputAttributes}
      choices={choices.map((choice) => ({
        value: choice.value,
        label: choice.label,
        icon: iconMap[choice.value],
      }))}
      renderOption={(choice: IFancyChoice) => renderButtonLabel(choice.label, choice.icon)}
      disabled={disabled}
      onChange={(choice: IFancyChoice) => onChange(choice.value)}
      containerSize={LARGE_CONTAINER_SIZE_PX}
      value={value}
      id={id}
    />
  )
}
