import { IPoint } from '@sparelabs/geography'

export enum DutyLegType {
  Boarding = 'boarding',
  Transporting = 'transporting',
  Moving = 'moving',
  Break = 'break',
  Idle = 'idle',
  Offline = 'offline',
}

export interface IBaseDutyBreakdownLeg {
  startTs: number
  endTs: number | null
  startLocation: IPoint
  endLocation: IPoint
}

export interface IBoardingLeg extends IBaseDutyBreakdownLeg {
  type: DutyLegType.Boarding
  requestIds: string[]
  slotIds: string[]
}

export interface ITransportingLeg extends IBaseDutyBreakdownLeg {
  type: DutyLegType.Transporting
  requestIds: string[]
}

export interface IMovingLeg extends IBaseDutyBreakdownLeg {
  type: DutyLegType.Moving
}

export interface IBreakLeg extends IBaseDutyBreakdownLeg {
  type: DutyLegType.Break
  slotId: string
}

export interface IIdleLeg extends IBaseDutyBreakdownLeg {
  type: DutyLegType.Idle
}

export interface IOfflineLeg extends IBaseDutyBreakdownLeg {
  type: DutyLegType.Offline
}

export type IHistoricalDutyBreakDownLeg =
  | IBoardingLeg
  | ITransportingLeg
  | IMovingLeg
  | IBreakLeg
  | IIdleLeg
  | IOfflineLeg

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDutyBreakdownLegResponse {}
