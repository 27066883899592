import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IUserFleetAgreementListQueryParams,
  IUserFleetAgreementPostBody,
  IUserFleetAgreementResponse,
  IUserFleetAgreementSendBody,
  Resources,
} from '../types'

export class UserFleetAgreementResource extends GenericResource<
  IUserFleetAgreementResponse,
  IUserFleetAgreementListQueryParams,
  null,
  IUserFleetAgreementPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public del = this.buildDel()

  constructor(agent: AxiosInstance) {
    super(Resources.UserFleetAgreements, agent)
  }

  public async deleteAll(fleetId: string): Promise<void> {
    await this.agent.delete<void>(`fleets/${fleetId}/${this.resource}`)
  }

  public async send(body: IUserFleetAgreementSendBody): Promise<void> {
    await this.agent.post<void>(`${this.resource}/send`, body)
  }
}
