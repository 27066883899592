export * from './DarkModeColors'
export * from './LightModeColors'
export * from './WebColors'

export enum ColorTheme {
  Light = 'light',
  Dark = 'dark',
}

// Do not change values of this.
// Order reflects settings in native code
export enum ThemeSetting {
  Light = 0,
  Dark = 1,
  Auto = 2,
}

export const MagmaGradient = [
  '#FED396',
  '#FFA873',
  '#F97D60',
  '#E95662',
  '#C93F73',
  '#A3317E',
  '#7E2481',
  '#59167E',
  '#341067',
  '#130C33',
]

export const getRandomColor = (): string => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let counter = 0; counter < 6; counter++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
