import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { ICaseStatusPatchBody, ICaseStatusPostBody, ICaseStatusResponse, IListCaseStatusQueryParams } from '../types'
import { Resources } from './ResourcesType'

export class CaseStatusResource extends GenericResource<
  ICaseStatusResponse,
  IListCaseStatusQueryParams,
  ICaseStatusPatchBody,
  ICaseStatusPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  constructor(agent: AxiosInstance) {
    super(Resources.CaseStatuses, agent)
  }
}
