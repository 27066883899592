import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum OperationalAlertsPermissionSet {
  Subscribe = 'operational_alerts_subscribe',
}

export const operationalAlertsPermissionSetGrouping: IPermissionSetGrouping<OperationalAlertsPermissionSet> = {
  key: PermissionSetGroupingKey.OperationalAlerts,
  active: {
    [OperationalAlertsPermissionSet.Subscribe]: [
      /* Ability to see alerts and mark them as seen */
      PermissionType.LIST_OWN_ALERT,
      PermissionType.PATCH_OWN_ALERT,

      /* Ability to subscribe to alerts */
      PermissionType.LIST_OWN_ALERT_SUBSCRIPTION,
      PermissionType.POST_OWN_ALERT_SUBSCRIPTION,
      PermissionType.DELETE_OWN_ALERT_SUBSCRIPTION,

      PermissionType.RECEIVE_LATE_DUTIES_ALERT,
      PermissionType.RECEIVE_LATE_DUTY_START_ALERT,
      PermissionType.RECEIVE_NO_SCHEDULED_DUTIES_ALERT,
      PermissionType.RECEIVE_NEED_VEHICLE_LOCATION_UPDATE_ALERT,
      PermissionType.RECEIVE_NEGATIVE_REVIEW_POSTED_ALERT,
      PermissionType.RECEIVE_POSITIVE_COMMENT_REVIEW_POSTED_ALERT,
      PermissionType.RECEIVE_NO_SHOW_CANCELLATION_ALERT,
      PermissionType.RECEIVE_SYSTEM_AT_CAPACITY_ALERT,
      PermissionType.RECEIVE_IDLE_VEHICLE_ALERT,
      PermissionType.RECEIVE_STRANDED_REQUESTS_ALERT,
      PermissionType.RECEIVE_SIMULTANEOUS_DRIVER_LOGIN_ALERT,
      PermissionType.RECEIVE_RECURRING_TRIP_FAILURE_ALERT,
      PermissionType.RECEIVE_CASE_ASSIGNMENT_ALERT,
      PermissionType.RECEIVE_NO_DRIVERS_AVAILABLE_ALERT,
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
