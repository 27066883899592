import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IDutyRequestPolylineQueryParams,
  IDutyRequestPolylineResponse,
  ILastVehicleLocationResponse,
  IPostRequestFareEstimateBody,
  IPostRequestFareEstimateResponse,
  IRematchPostBody,
  IRequestCancellationPostBody,
  IRequestCancellationResponse,
  IRequestCurrentRoute,
  IRequestEditDropoffPostBody,
  IRequestForceMatchPostBody,
  IRequestInsightsResponse,
  IRequestListQueryParams,
  IRequestPatchBody,
  IRequestPaymentOptionsQueryParams,
  IRequestPaymentOptionsResponse,
  IRequestPostBody,
  IRequestResponse,
  IRequestSearchQueryParams,
  IRequestSearchResponse,
  ISlotCompletePostBody,
  ISlotResponse,
  ITipPostBody,
  ITipResponse,
  IVehicleStub,
  Resources,
} from '../types'

export class RequestResource extends GenericResource<
  IRequestResponse,
  IRequestListQueryParams,
  IRequestPatchBody,
  IRequestPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()

  constructor(agent: AxiosInstance) {
    super(Resources.Requests, agent)
  }

  public async cancel(requestId: string, body: IRequestCancellationPostBody): Promise<IRequestCancellationResponse> {
    const res = await this.agent.post<IRequestCancellationResponse>(
      `${Resources.Requests}/${requestId}/cancellation`,
      body
    )
    return res.data
  }

  public async rematch(requestId: string, body: IRematchPostBody): Promise<IRequestCancellationResponse> {
    const res = await this.agent.post<IRequestCancellationResponse>(`${Resources.Requests}/${requestId}/rematch`, body)
    return res.data
  }

  public async getVehicle(requestId: string): Promise<IVehicleStub> {
    const res = await this.agent.get<IVehicleStub>(`${Resources.Requests}/${requestId}/vehicle`)
    return res.data
  }

  public async getVehicleLocation(requestId: string): Promise<ILastVehicleLocationResponse> {
    const res = await this.agent.get<ILastVehicleLocationResponse>(`${Resources.Requests}/${requestId}/vehicleLocation`)
    return res.data
  }

  public async getCurrentRoute(requestId: string): Promise<IRequestCurrentRoute> {
    const res = await this.agent.get<IRequestCurrentRoute>(`${Resources.Requests}/${requestId}/currentRoute`)
    return res.data
  }

  public async getTip(requestId: string): Promise<ITipResponse> {
    const { data } = await this.agent.get<ITipResponse>(`${Resources.Requests}/${requestId}/tip`)
    return data
  }

  public async postTip(requestId: string, body: ITipPostBody): Promise<ITipResponse> {
    const { data } = await this.agent.post<ITipResponse>(`${Resources.Requests}/${requestId}/tip`, body)
    return data
  }

  public async getInsights(requestId: string): Promise<IRequestInsightsResponse> {
    const res = await this.agent.get<IRequestInsightsResponse>(`${Resources.Requests}/${requestId}/insights`)
    return res.data
  }

  public async reverseNoShow(requestId: string): Promise<IRequestResponse> {
    const { data } = await this.agent.post<IRequestResponse>(`${Resources.Requests}/${requestId}/reverseNoShow`)
    return data
  }

  public async reverseCancellation(requestId: string): Promise<IRequestResponse> {
    const { data } = await this.agent.post<IRequestResponse>(`${Resources.Requests}/${requestId}/reverseCancellation`)
    return data
  }

  public async editDropoff(requestId: string, body: IRequestEditDropoffPostBody): Promise<IRequestResponse> {
    const { data } = await this.agent.post<IRequestResponse>(`${Resources.Requests}/${requestId}/editDropoff`, body)
    return data
  }

  public async unmatch(requestId: string): Promise<IRequestResponse> {
    const { data } = await this.agent.post<IRequestResponse>(`${Resources.Requests}/${requestId}/unmatch`)
    return data
  }

  public async reversePickup(requestId: string): Promise<IRequestResponse> {
    const { data } = await this.agent.post<IRequestResponse>(`${Resources.Requests}/${requestId}/reversePickup`)
    return data
  }

  public async reverseDropoff(requestId: string): Promise<IRequestResponse> {
    const { data } = await this.agent.post<IRequestResponse>(`${Resources.Requests}/${requestId}/reverseDropoff`)
    return data
  }

  public async forceMatch(requestId: string, body: IRequestForceMatchPostBody = {}): Promise<IRequestResponse> {
    const { data } = await this.agent.post<IRequestResponse>(`${Resources.Requests}/${requestId}/forceMatch`, body)
    return data
  }

  public async createFareEstimate(
    requestId: string,
    body: IPostRequestFareEstimateBody
  ): Promise<IPostRequestFareEstimateResponse> {
    const { data } = await this.agent.post<IPostRequestFareEstimateResponse>(
      `${Resources.Requests}/${requestId}/fareEstimate`,
      body
    )
    return data
  }

  public async patchMetadata(requestId: string, body: { metadata: Record<string, unknown> }): Promise<void> {
    await this.agent.patch(`${Resources.Requests}/${requestId}/metadata`, { metadata: body.metadata })
  }

  /**
   * @deprecated. DO NOT USE OR EXPOSE EXTERNALLY.
   *
   * This endpoint is INTERNAL ONLY and TEMPORARY. It will be removed when a refactor for this endpoint and some of the
   * driverOps endpoints is complete.
   *
   * This is used by the admin request slot completion flow until the refactor is complete.
   * See LOCO-1314 for more details.
   */
  public async slotsComplete(requestId: string, slotId: string, body: ISlotCompletePostBody): Promise<ISlotResponse> {
    const res = await this.agent.post<ISlotResponse>(
      `${Resources.Requests}/${requestId}/${Resources.Slots}/${slotId}/complete`,
      body
    )
    return res.data
  }

  public async search(params: IRequestSearchQueryParams): Promise<IRequestSearchResponse> {
    const res = await this.agent.get<IRequestSearchResponse>('requests/search', { params })
    return res.data
  }

  public async getPaymentOptions(
    requestId: string,
    params: IRequestPaymentOptionsQueryParams
  ): Promise<IRequestPaymentOptionsResponse> {
    const res = await this.agent.get<IRequestPaymentOptionsResponse>(
      `${Resources.Requests}/${requestId}/paymentOptions`,
      { params }
    )
    return res.data
  }

  public async getRequestPolyline(
    requestId: string,
    params: IDutyRequestPolylineQueryParams
  ): Promise<IDutyRequestPolylineResponse> {
    const res = await this.agent.get<IDutyRequestPolylineResponse>(`${Resources.Requests}/${requestId}/polyline`, {
      params,
    })
    return res.data
  }
}
