import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IFileResponse, IFileTempUrlResponse, IPublicFileResponse } from '@sparelabs/http-client-utils'

export type FileUrlValue = IPublicFileResponse | IFileResponse | null
export type TempUrlType = (id: string) => Promise<IFileTempUrlResponse>

export const getIconFromFileFormat = (format: string): IconProp => {
  if (format === 'application/pdf') {
    return 'file-pdf'
  }
  if (format.startsWith('image')) {
    return 'file-image'
  }
  if (format === 'text/csv') {
    return 'file-csv'
  }
  if (
    // cspell: disable
    format === 'application/vnd.ms-excel' ||
    format === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    // cspell: enable
  ) {
    return 'file-excel'
  }
  return 'file-alt'
}

export const isPublicFileResponse = (value: FileUrlValue): value is IPublicFileResponse =>
  (value as IPublicFileResponse)?.url !== undefined

export const isFileResponse = (value: FileUrlValue): value is IFileResponse =>
  (value as IFileResponse)?.id !== undefined

export const isFile = (value: FileUrlValue | File): value is File => value instanceof File

export const openTempUrl = async (value: FileUrlValue, tempUrl?: TempUrlType) => {
  if (!value || !isFileResponse(value) || !tempUrl) {
    return
  }

  const url = await tempUrl(value.id)
  if (url.tempUrl) {
    window.open(url.tempUrl, '_blank')
  } else {
    throw new Error('Server returned a blank url')
  }
}
