/**
 * The response fields of a global organization
 */
export interface IGlobalOrganizationResponse {
  id: string
  name: string
  apiHost: string
  routingHost: string
  logoUrl: string | null
  organizationKey: string | null
}

export interface IGlobalOrganizationsQueryParams {
  isDriver?: boolean
  isAdmin?: boolean
}

export interface IGlobalApiRegionInformation {
  name: string
  apiUrl: string
  routingHost: string
}

export interface IGlobalProxyWebhookPostBody {
  externalId: string
  provider: OpenFleetProvider
}

enum OpenFleetProvider {
  Lyft = 'lyft',
  Uber = 'uber',
  ICabbi = 'icabbi',
  Uzurv = 'uzurv',
  Mti = 'mti',
  Jagwire = 'jagwire',
}
