import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'

export enum FormContentStatus {
  Active = 'active',
  Archived = 'archived',
}

export enum FormContentStage {
  Default = 'default',
  PostSubmit = 'postSubmit',
}

export interface IListFormContentQueryParams extends IListModelQueryParamValues {
  formId?: string
  status?: FormContentStatus
}

export interface IFormContentPostBody {
  formId: string
  order: string
  content: string
  stage: FormContentStage
}

export interface IFormContentPatchBody {
  status?: FormContentStatus
  order?: string
  content?: string
}

export interface IFormContentResponse extends IModelJsonValues, IFormContentPostBody {
  status: FormContentStatus
  stage: FormContentStage
}
