import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { WebColors, WebGradients } from '@sparelabs/colors'

export interface IBannerTheme {
  backgroundColor: string
  background?: string
  textColor: string
  icon?: IconProp
}

export const infoBannerTheme: IBannerTheme = {
  backgroundColor: WebColors.interactiveLowContrast,
  textColor: WebColors.interactiveHighContrast,
  icon: 'info-circle',
}

export const successBannerTheme: IBannerTheme = {
  backgroundColor: WebColors.positiveLowContrast,
  textColor: WebColors.positiveHighContrast,
  icon: 'check',
}

export const dangerBannerTheme: IBannerTheme = {
  backgroundColor: WebColors.negativeLowContrast,
  textColor: WebColors.negativeHighContrast,
  icon: 'exclamation-triangle',
}

export const warningBannerTheme: IBannerTheme = {
  backgroundColor: WebColors.warningLowContrast,
  textColor: WebColors.warningHighContrast,
  icon: 'exclamation-triangle',
}

export const neutralBannerTheme: IBannerTheme = {
  backgroundColor: WebColors.accentCoolGrayLowContrast,
  textColor: WebColors.accentCoolGrayHighContrast,
  icon: 'info-circle',
}

export const noConnectionBannerTheme: IBannerTheme = {
  backgroundColor: WebColors.accentGrayLowContrast,
  textColor: WebColors.accentGrayHighContrast,
  icon: 'info-circle',
}

export const purpleBannerTheme: IBannerTheme = {
  backgroundColor: WebColors.accentPurpleLowContrast,
  textColor: WebColors.accentPurpleHighContrast,
  icon: 'info-circle',
}

export const aiBannerTheme: IBannerTheme = {
  backgroundColor: WebColors.accentPurpleLowContrast,
  background: WebGradients.ai,
  textColor: WebColors.contentPrimary,
  icon: 'sparkles',
}
