import { library } from '@fortawesome/fontawesome-svg-core'
import { faComments, faInfoCircle, faPhone } from '@fortawesome/pro-solid-svg-icons'
import { faAlicorn } from '@fortawesome/pro-solid-svg-icons/faAlicorn'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'

export const initializeFontAwesome = (): void => {
  library.add(faAlicorn)
  library.add(faCheck)
  library.add(faInfoCircle)
  library.add(faPhone)
  library.add(faComments)
}
