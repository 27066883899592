import { BadArgumentsError } from '@sparelabs/error-types'
// eslint-disable-next-line import/no-cycle
import { Geography } from './Geography'
import { GeoJsonCoordinate, GeometryType, ILineString, IPoint, IPolygon } from './GeographyTypes'

export const buildPoint = (long: number, lat: number): IPoint => ({
  type: GeometryType.Point,
  coordinates: [long, lat],
})

export const buildPolygon = (coordinates: GeoJsonCoordinate[][]): IPolygon => {
  for (const face of coordinates) {
    if (face.length >= 1) {
      const firstPoint = buildPoint(...face[0])
      const lastPoint = buildPoint(...face.slice(-1)[0])
      if (!Geography.arePointsEqual(firstPoint, lastPoint)) {
        throw new BadArgumentsError(
          `Every face in a GeoJSON polygon must have the first point equal to the last point (first: ${JSON.stringify(
            firstPoint
          )}, last: ${JSON.stringify(lastPoint)})`,
          { coordinates }
        )
      }
    }
  }
  return {
    type: GeometryType.Polygon,
    coordinates,
  }
}

export const buildLineString = (coordinates: GeoJsonCoordinate[]): ILineString => ({
  type: GeometryType.LineString,
  coordinates,
})
