import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'
import { ReviewPermissionSet, reviewPermissionSetGrouping } from './ReviewPermissions'

export enum ApiKeyPermissionSet {
  View = 'api_key_view',
  Write = 'api_key_write',
  Delete = 'api_key_delete',
  KeyUser = 'api_key_user',
}

export const apiKeyPermissionSetGrouping: IPermissionSetGrouping<ApiKeyPermissionSet> = {
  key: PermissionSetGroupingKey.ApiKey,
  active: {
    [ApiKeyPermissionSet.View]: [PermissionType.GET_ANY_API_KEY],
    [ApiKeyPermissionSet.Write]: [PermissionType.WRITE_ANY_API_KEY],
    [ApiKeyPermissionSet.Delete]: [PermissionType.DELETE_ANY_API_KEY],
    [ApiKeyPermissionSet.KeyUser]: [
      // This should be refactored to remove the need for this permission set.

      // Permissions for API Keys.
      // TODO currently api keys and administrators have the same permissions.
      // When we work on scoping down what API Keys can do it would be good to remove this coupling
      // ----------------------------------
      ...reviewPermissionSetGrouping.active[ReviewPermissionSet.New], // used by API consumers to create reviews for others

      PermissionType.POST_DOPS_COMPLETE_SLOT, // used by API Key in av-service
      PermissionType.POST_DOPS_COMPLETE_DUTY, // used by API key
      PermissionType.POST_ANY_DUTY_AV_STATE, // used by API Key in av-service
      PermissionType.POST_ANY_DUTY_VL, // used by API Key in av-service

      PermissionType.TESTING_API_ACCESS, // used by E2E tests

      PermissionType.GENERATE_OWN_USER_ORG_TOKENS, // used by Monitor to act on behalf of users, TODO we need to remove this functionality from spare (at least from production)
      PermissionType.GENERATE_ANY_USER_ORG_TOKENS, // used by Monitor to act on behalf of users, TODO we need to remove this functionality from spare (at least from production)
      // ----------------------------------
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [ApiKeyPermissionSet.KeyUser],
}
