import { IListModelQueryParamValues } from '@sparelabs/http-client-utils'
import { IBin } from './MetricsTypes'
import { IOnTimePerformanceMetrics } from './SharedMetricsTypes'
import { Interval } from './TimeTypes'

export enum FleetMetricType {
  Duty = 'duty',
  OnTimePerformance = 'onTimePerformance',
  DutyLateness = 'dutyLateness',
}

export type FleetMetricOutputType = IDutyMetrics | IOnTimePerformanceMetrics | IDutyLatenessMetrics
interface IFleetMetricOutput<T extends FleetMetricType, D extends FleetMetricOutputType> {
  type: T
  data: D
}

export type IFleetMetric =
  | IFleetMetricOutput<FleetMetricType.Duty, IDutyMetrics>
  | IFleetMetricOutput<FleetMetricType.OnTimePerformance, IOnTimePerformanceMetrics>
  | IFleetMetricOutput<FleetMetricType.DutyLateness, IDutyLatenessMetrics>

export enum FleetMetricScope {
  All = 'all',
  Active = 'active',
  Archived = 'archived',
  // this is used specifically for billing service
  AllInternalAndShared = 'allInternalAndShared',
}

export interface IListFleetMetricsQueryParams extends IListModelQueryParamValues {
  metricTypes: FleetMetricType[]
  fromTs: number
  toTs: number
  timezone?: string
  interval?: Interval
  fleetIds?: string[] | FleetMetricScope
}

export interface IListFleetMetricsResponse {
  metrics: IFleetMetric[]
}

export interface IDutyMetrics {
  vehicleHrSBins: IBin[]
  completedBoardingsBins: IBin[]
  totalVehicleHoursS: number
  actualVehicleHoursS: number
  numPooledBoardings: number
  numPooledBookings: number
  totalRevenueHoursS: number
  totalTravelDistanceM: number
  deadheadTravelDistanceM: number
  totalRevenueDistanceM: number
  totalBreakLengthS: number
  totalBreakDistanceM: number
  activeDutyCountBins: IBin[]
  activeDutyCountMax: number
  totalCompletedBookings: number
}

export interface IDutyLatenessMetrics {
  dutyLatenessBins: IBin[]
}
