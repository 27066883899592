import { PaymentMethodBrand } from '@sparelabs/domain-logic'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { IChargeStub } from './ChargeTypes'
import { ConnectedAccountType } from './ConnectedAccountTypes'
import { CreatedInterface } from './EstimateTypes'
import { IFarePassStub } from './FarePassTypes'

export enum FarePassAllocationStatus {
  /**
   * Fare pass allocation can be redeemed towards the cost of rides
   */
  Active = 'active',
  /**
   * Fare pass allocation cannot be used as it contains no more stored value. (Only valid for stored value fare passes)
   */
  Depleted = 'depleted',
  /**
   * Fare pass allocation cannot be used as it has expired. (Only valid for time-based fare passes)
   */
  Expired = 'expired',
  /**
   * Fare pass allocation cannot be used as it has been refunded.
   */
  Deactivated = 'deactivated',
}

export interface IFarePassAllocationPostBody {
  farePassId: string
  chargeId?: string | null
  paymentMethodId?: string | null
  notes?: string | null
  userId: string
  updateFutureFaresForStoredValuePasses?: boolean
  // TODO: Make required once admin and rider changes are released
  createdInterface?: CreatedInterface
}

export interface IFarePassAllocationRefundPostBody {
  amount: number
  reason: string
}

export interface IFarePassAllocationPatchBody {
  status?: FarePassAllocationStatus
  notes?: string | null
}

export interface IFarePassAllocationRedemptionInfo {
  amountRedeemed: number
  timesRedeemed: number
}

export interface IFarePassAllocationResponse extends IModelJsonValues, IFarePassAllocationRedemptionInfo {
  status: FarePassAllocationStatus
  farePass: IFarePassStub
  userId: string
  chargeId: string | null
  paymentMethod: IFarePassAllocationPaymentMethodStub | null
  notes: string | null
  startTs: number | null
  endTs: number | null
  createdInterface: CreatedInterface
  creatorId: string | null
  lastRedeemedTs: number | null
  user: IFarePassAllocationRiderStub
  charge: IChargeStub | null
}

export interface IFarePassAllocationListQueryParams extends IListModelQueryParamValues {
  status?: FarePassAllocationStatus
  farePassId?: string
  userId?: string
}

export interface IFarePassAllocationPaymentMethodStub {
  id: string
  last4Digits: string | null
  brand: PaymentMethodBrand
  connectedAccountType: ConnectedAccountType
}

export interface IFarePassAllocationRiderStub {
  id: string
  firstName: string | null
  lastName: string | null
  email: string | null
  phoneNumber: string | null
  externalNumericId: number | null
}
