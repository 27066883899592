import { sortBy } from 'lodash'
import { SlotType } from '../types'

export interface ISortableSlot {
  scheduledTs?: number | null
  type: SlotType
  id?: string | null
}

export class SlotOrdering {
  public static sortSlots<T extends ISortableSlot>(slots: T[]): T[] {
    return sortBy(slots, ['scheduledTs', (slot) => this.getSlotTypeSortOrder(slot.type), 'id'])
  }

  public static getSlotTypeSortOrder(slotType: SlotType): number {
    switch (slotType) {
      case SlotType.StartLocation:
        return 0
      case SlotType.VehicleLocation:
        return 1
      case SlotType.Pickup:
        return 2
      case SlotType.Dropoff:
        return 3
      case SlotType.RouteStop:
        return 4
      case SlotType.EndLocation:
        return 6
      case SlotType.DriverBreak:
        return 7
    }
  }
}
