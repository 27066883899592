import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { ISlotQueryParams, ISlotResponse, Resources } from '../types'

export class SlotResource extends GenericResource<ISlotResponse, ISlotQueryParams, null, null> {
  public get = this.buildGet()
  public list = this.buildList()
  constructor(agent: AxiosInstance) {
    super(Resources.Slots, agent)
  }
}
