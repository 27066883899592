import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum CancellationReasonPermissionSet {
  View = 'cancellation_reason_view',
  Write = 'cancellation_reason_write',
  Delete = 'cancellation_reason_delete',
}

export const cancellationReasonPermissionSetGrouping: IPermissionSetGrouping<CancellationReasonPermissionSet> = {
  key: PermissionSetGroupingKey.CancellationReason,
  active: {
    [CancellationReasonPermissionSet.View]: [PermissionType.GET_ANY_CANCELLATION_REASON],
    [CancellationReasonPermissionSet.Write]: [PermissionType.POST_ANY_CANCELLATION_REASON],
    [CancellationReasonPermissionSet.Delete]: [PermissionType.DELETE_ANY_CANCELLATION_REASON],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
