import { GenericResource, IListModelQueryParamValues } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IBreakPolicyPatchBody, IBreakPolicyPostBody, IBreakPolicyResponse, Resources } from '../types'

export class BreakPolicyResource extends GenericResource<
  IBreakPolicyResponse,
  IListModelQueryParamValues,
  IBreakPolicyPatchBody,
  IBreakPolicyPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()
  public del = this.buildDel()

  constructor(agent: AxiosInstance) {
    super(Resources.BreakPolicy, agent)
  }
}
