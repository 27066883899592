import { IListModelQueryParamValues } from '@sparelabs/http-client-utils'

export enum CustomFieldType {
  Text = 'text',
  Number = 'number',
  Toggle = 'toggle',
  SingleChoice = 'singleChoice',
  MultipleChoice = 'multipleChoice',
  Date = 'date',
  Datetime = 'datetime',
  Address = 'address',
  File = 'file',
  Files = 'files',
  Drawing = 'drawing',

  // Used by surveys.
  NpsScore = 'npsScore',

  // Used by vehicle inspections
  PassFail = 'passFail',
}

export enum CustomFieldStatus {
  Active = 'active',
  Archived = 'archived',
}

export interface ICustomFieldChoice {
  label: string
  value: string
}

export enum CustomFieldVisibility {
  Hidden = 'hidden',
  View = 'view',
  Edit = 'edit',
}

export enum CustomFieldTextType {
  SingleLine = 'singleLine',
  MultiLine = 'multiLine',
}

export interface ICustomFieldPostBody {
  fieldGroupId: string
  type: CustomFieldType
  key: string
  label: string
  isRequired?: boolean
  hint?: string | null
  choices?: ICustomFieldChoice[] | null
  textType?: CustomFieldTextType | null
  order?: string
  isAvailableToRiderInterface?: boolean
  isAvailableToDriverInterface?: boolean
  riderInterfaceVisibility?: CustomFieldVisibility
  driverInterfaceVisibility?: CustomFieldVisibility
  isSearchable?: boolean
}

export interface ICustomFieldPatchBody extends Partial<Omit<ICustomFieldPostBody, 'fieldGroupId' | 'key'>> {
  status?: CustomFieldStatus
}

export interface ICustomFieldResponse extends Required<ICustomFieldPostBody> {
  id: string
  createdAt: number
  updatedAt: number
  status: CustomFieldStatus
}

export interface ICustomFieldGroupResponse {
  id: string
}

export interface IListCustomFieldsQueryParams extends IListModelQueryParamValues {
  fieldGroupIds?: string[]
  status?: CustomFieldStatus
}

export enum CustomFieldQueryComparison {
  Equal = 'eq',
  GreaterThan = 'gt',
  LessThan = 'lt',
  GreaterThanOrEqual = 'gte',
  LessThanOrEqual = 'lte',
}

export type CustomFieldFindOptionQuery = Record<string, { comparison: CustomFieldQueryComparison; value: string }>
