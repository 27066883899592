import { RoundedButton, SubmitButtonTheme } from '@sparelabs/web-ui-components'
import React from 'react'
import { st } from 'src/locales/TranslationHelper'
import { ActionButtonsWrapper, Container } from './Layout'

interface IProps {
  message?: string
}
export const ErrorView = ({ message }: IProps): JSX.Element => (
  <Container>
    <p>{st.errors.defaultMessage()}</p>
    <pre>{message}</pre>
    <ActionButtonsWrapper>
      <RoundedButton theme={SubmitButtonTheme} onClick={() => window.location.reload()}>
        {st.common.tryAgain()}
      </RoundedButton>
    </ActionButtonsWrapper>
  </Container>
)
