import { CustomFieldStatus, IAdminResponse, ICustomFieldResponse, IRiderResponse } from '@sparelabs/api-client'
import { FormStatus, ICaseFormResponse, ICaseResponse, IFormResponse } from '@sparelabs/engage-client'
import { GenericUtils } from '@sparelabs/generic-utils'
import { pick, startCase } from 'lodash'
import moment from 'moment-timezone'
import { st } from '../locales/TranslationHelper'
import {
  IMarkdownCustomFieldFormatterOptions,
  MarkdownCustomFieldFormatter,
} from '../variables/MarkdownCustomFieldFormatter'
import {
  IMarkdownVariable,
  IMarkdownVariableDefinitionValue,
  MarkdownVariableDefinitionType,
} from '../variables/MarkdownVariableTypes'

// eslint-disable-next-line id-length
const tLabels = st.engageLetterLabels
const tHelpers = st.genericHelpers

// Define which fields from the rider object are available
const RIDER_FIELDS = ['firstName', 'lastName', 'email', 'phoneNumber', 'externalNumericId'] as const

interface IFormWithFields extends IFormResponse {
  fields: ICustomFieldResponse[]
}

interface ICaseWithRelations extends ICaseResponse {
  assignedAdmin: IAdminResponse | null
  rider: Pick<IRiderResponse, typeof RIDER_FIELDS[number] | 'metadata'>
  caseForms: ICaseFormResponse[]
}

export interface IEngageLetterVariableBuilderData {
  forms: IFormWithFields[]
  riderCustomFields: ICustomFieldResponse[]
}

export interface IEngageLetterVariableRenderData {
  caseData: ICaseWithRelations
  forms: IFormWithFields[]
  riderCustomFields: ICustomFieldResponse[]
  formatOptions: IMarkdownCustomFieldFormatterOptions
}

type ILetterVariable = IMarkdownVariable<IEngageLetterVariableBuilderData, IEngageLetterVariableRenderData>

const RiderVar: ILetterVariable = {
  buildDefinition: ({ riderCustomFields }) => {
    const values: IMarkdownVariableDefinitionValue[] = RIDER_FIELDS.map((field) => ({
      key: `rider.${field}`,
      label: startCase(field),
    }))

    for (const field of riderCustomFields.filter((field) => field.status === CustomFieldStatus.Active)) {
      // TODO deal with archived fields
      values.push({
        key: `rider.${field.key}`,
        label: startCase(field.label),
      })
    }

    return [{ key: 'rider', groupLabel: 'Rider', values }]
  },

  buildData: async ({ caseData, riderCustomFields, formatOptions }) => {
    const riderMetadata = await MarkdownCustomFieldFormatter.format(
      riderCustomFields,
      caseData.rider.metadata,
      formatOptions
    )
    return { rider: { ...pick(caseData.rider, RIDER_FIELDS), ...riderMetadata } }
  },
}

const DateVar: ILetterVariable = {
  buildDefinition: () => [
    {
      key: 'date',
      groupLabel: tLabels.date.singularName(),
      values: [{ key: 'date.current', label: tLabels.date.currentDate() }],
    },
  ],
  buildData: ({ formatOptions }) => ({
    date: { current: moment().tz(formatOptions.timezone).format('LL') },
  }),
}

const FormsVar: ILetterVariable = {
  buildDefinition: ({ forms }) =>
    forms
      .filter((form) => form.status === FormStatus.Active)
      .map((form) => ({
        key: form.key,
        groupLabel: form.name,
        values: form.fields
          .filter((field) => field.status === CustomFieldStatus.Active)
          .map((field) => ({
            key: `forms.${form.key}.${field.key}`,
            label: field.label,
          })),
      })),
  buildData: async ({ caseData, forms, formatOptions }) => {
    const formVariables: Record<string, unknown> = {}
    for (const form of forms) {
      const data = caseData.caseForms.find((caseForm) => caseForm.formId === form.id)?.metadata || {}
      formVariables[form.key] = await MarkdownCustomFieldFormatter.format(form.fields, data, formatOptions)
    }
    return { forms: formVariables }
  },
}

const CaseVar: ILetterVariable = {
  buildDefinition: () => [
    {
      key: 'case',
      groupLabel: tLabels.case.singularName(),
      values: [
        {
          key: 'case.id',
          label: tLabels.case.id(),
        },
        {
          key: 'assignee.name',
          label: tLabels.case.assignedTo(),
        },
      ],
    },
  ],
  buildData: ({ caseData }) => {
    const name = `${caseData.assignedAdmin?.firstName} ${caseData.assignedAdmin?.lastName}`.trim()
    return { case: { id: caseData.id }, assignee: { name } }
  },
}

const GenericVar: ILetterVariable = {
  buildDefinition: () => {
    const insertAs = MarkdownVariableDefinitionType.Logic

    return [
      {
        key: 'logicUtils',
        groupLabel: tLabels.genericHelpers.logicUtils(),
        values: [
          { key: 'eq value1 value2', label: tHelpers.eq(), insertAs },
          { key: 'uneq value1 value2', label: tHelpers.uneq(), insertAs },
          { key: 'lt value1 value2', label: tHelpers.lt(), insertAs },
          { key: 'lte value1 value2', label: tHelpers.lte(), insertAs },
          { key: 'gt value1 value2', label: tHelpers.gt(), insertAs },
          { key: 'gte value1 value2', label: tHelpers.gte(), insertAs },
        ],
      },
      {
        key: 'arrayUtils',
        groupLabel: tLabels.genericHelpers.arrayUtils(),
        values: [
          { key: 'length list_or_array', label: tHelpers.length(), insertAs },

          { key: 'includes list_or_array value', label: tHelpers.includes(), insertAs },
        ],
      },
      {
        key: 'stringUtils',
        groupLabel: tLabels.genericHelpers.stringUtils(),
        values: [
          { key: 'lowercase value', label: tHelpers.lowercase(), insertAs },

          { key: 'uppercase value', label: tHelpers.uppercase(), insertAs },

          { key: 'contains string substring', label: tHelpers.contains(), insertAs },
        ],
      },
    ]
  },
  buildHelpers: () => ({
    eq: GenericUtils.eq,
    uneq: GenericUtils.uneq,
    lt: GenericUtils.lt,
    lte: GenericUtils.lte,
    gt: GenericUtils.gt,
    gte: GenericUtils.gte,

    length: GenericUtils.length,
    includes: GenericUtils.includes,

    lowercase: GenericUtils.lowercase,
    uppercase: GenericUtils.uppercase,
    contains: GenericUtils.contains,
  }),
}

export const EngageLetterVariables: ILetterVariable[] = [RiderVar, DateVar, FormsVar, CaseVar, GenericVar]
