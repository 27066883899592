import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { NativeObjectType } from './NativeObjectTypes'

export enum DataRetentionScheduleStatus {
  Active = 'active',
  Archived = 'archived',
}

export enum DataRetentionSchedulePeriodUnit {
  Year = 'year',
  Month = 'month',
}

export enum DataRetentionScheduleRounding {
  End = 'end',
  None = 'none',
}

export enum DataRetentionScheduleStartAt {
  Created = 'created',
  FromCustomFieldDate = 'fromCustomFieldDate',
  Pickup = 'pickup',
  Start = 'start',
  End = 'end',
  Expiry = 'expiry',
}

interface IDataRetentionStartBase {
  rounding: DataRetentionScheduleRounding
}

export type DataRetentionStartCustomField = IDataRetentionStartBase & {
  at: DataRetentionScheduleStartAt.FromCustomFieldDate
  customField: {
    type: Exclude<NativeObjectType, NativeObjectType.Case>
    key: string
  }
}

type DataRetentionStartEntityField = IDataRetentionStartBase & {
  at: Exclude<DataRetentionScheduleStartAt, DataRetentionScheduleStartAt.FromCustomFieldDate>
}

export interface IDataRetentionSchedulePeriod {
  start: DataRetentionStartCustomField | DataRetentionStartEntityField
  unit: DataRetentionSchedulePeriodUnit
  length: number
}

export interface IDataRetentionScheduleResponse extends IModelJsonValues {
  period: IDataRetentionSchedulePeriod
  target: NativeObjectType
  status: DataRetentionScheduleStatus
}

export interface IScheduleCustomFieldError {
  message: string
  scheduleId: string
  key: string
  type: NativeObjectType
}

export interface IDataRetentionSchedulePostBody {
  period: IDataRetentionSchedulePeriod
  target: NativeObjectType
}

export type DataRetentionSchedulePatchBody = Partial<IDataRetentionSchedulePostBody> & {
  status?: DataRetentionScheduleStatus
}

export interface IDataRetentionScheduleListQueryParams extends IListModelQueryParamValues {
  status?: DataRetentionScheduleStatus
  targets?: NativeObjectType[]
}
