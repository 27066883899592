import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { IBadgeTheme } from './BadgeThemes'

export interface IBadgeProps {
  children?: ReactNode
  theme: IBadgeTheme
}

export const baseBadgeStyles = css`
  color: ${({ theme }: IBadgeProps) => theme.textColor};
  background-color: ${({ theme }: IBadgeProps) => theme.backgroundColor};
  border-radius: 4px;
  outline: ${({ theme }: IBadgeProps) => (theme.borderColor ? `1px solid ${theme.borderColor}` : 'none')};
  outline-offset: ${({ theme }: IBadgeProps) => (theme.borderColor ? '-1px' : 'none')};
  width: fit-content;
  white-space: nowrap;
  background: ${({ theme }: IBadgeProps) => theme.background};
`

const StyledBadge = styled.div`
  ${baseBadgeStyles}
  padding: 4px 8px;
  font-size: 13px;
  line-height: 14px;
`

// The extra layer of indirection is to be able to use `theme` as a prop name,
// since `styled-components` reserves `theme` as a special prop.
export const Badge = ({ theme, children, ...rest }: IBadgeProps): JSX.Element => (
  <StyledBadge theme={theme} {...rest}>
    {children}
  </StyledBadge>
)
