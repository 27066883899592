import { GenericResource, IListModelQueryParamValues } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IUserSettingsPostBody, IUserSettingsResponse, Resources } from '../types'

export class UserSettingsResource extends GenericResource<
  IUserSettingsResponse,
  IListModelQueryParamValues,
  unknown,
  IUserSettingsPostBody
> {
  public async save(body: IUserSettingsPostBody): Promise<void> {
    await this.agent.post('users/settings', body)
  }

  constructor(agent: AxiosInstance) {
    super(Resources.UserSettings, agent)
  }
}
