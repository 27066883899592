import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IOrganizationZonesCentroidResponse,
  IZoneListQueryParams,
  IZonePostBody,
  IZoneResponse,
  Resources,
} from '../types'

export class ZoneResource extends GenericResource<
  IZoneResponse,
  IZoneListQueryParams,
  Partial<IZonePostBody>,
  IZonePostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  public del = this.buildDel()
  constructor(agent: AxiosInstance) {
    super(Resources.Zones, agent)
  }

  public async getOrganizationZonesCentroid(): Promise<IOrganizationZonesCentroidResponse> {
    const response = await this.agent.get<IOrganizationZonesCentroidResponse>('/organizations/zones/centroid')
    return response.data
  }
}
