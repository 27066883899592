import { IListModelQueryParamValues } from '@sparelabs/http-client-utils'
import { IChargeMetrics, IEstimateMetrics, IRequestMetrics, IReviewMetrics } from './ServiceMetricsTypes'
import { IOnTimePerformanceMetrics } from './SharedMetricsTypes'
import { Interval } from './TimeTypes'

export enum RiderMetricType {
  Request = 'request',
  Charge = 'charge',
  Estimate = 'estimate',
  Review = 'review',
  OnTimePerformance = 'onTimePerformance',
}

export type RiderMetricOutputType =
  | IRequestMetrics
  | IChargeMetrics
  | IEstimateMetrics
  | IReviewMetrics
  | IOnTimePerformanceMetrics

interface IRiderMetricOutput<T extends RiderMetricType, D extends RiderMetricOutputType> {
  type: T
  data: D
}

export type IRiderMetric =
  | IRiderMetricOutput<RiderMetricType.Request, IRequestMetrics>
  | IRiderMetricOutput<RiderMetricType.Charge, IChargeMetrics>
  | IRiderMetricOutput<RiderMetricType.Estimate, IEstimateMetrics>
  | IRiderMetricOutput<RiderMetricType.Review, IReviewMetrics>
  | IRiderMetricOutput<RiderMetricType.OnTimePerformance, IOnTimePerformanceMetrics>

export interface IListRiderMetricsQueryParams extends IListModelQueryParamValues {
  metricTypes: RiderMetricType[]
  fromTs: number
  toTs: number
  timezone?: string
  interval?: Interval
  riderId: string
}

export interface IListRiderMetricsResponse {
  metrics: IRiderMetric[]
}
