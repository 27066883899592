import { Language } from '@sparelabs/api-client'

export enum Country {
  UnitedStates = 'us',
  Norway = 'no',
  Japan = 'jp',
  Germany = 'de',
  Spain = 'es',
  Iceland = 'is',
  Sweden = 'se',
  France = 'fr',
  Brazil = 'br',
}

export const countryToCallingCodeMap: Record<Country, string> = {
  [Country.UnitedStates]: '1',
  [Country.Norway]: '47',
  [Country.Japan]: '81',
  [Country.Germany]: '49',
  [Country.Spain]: '34',
  [Country.Iceland]: '354',
  [Country.Sweden]: '46',
  [Country.France]: '33',
  [Country.Brazil]: '55',
}

/**
 * This maps the most likely country for a given language.
 *
 * TO DO: This is a short term fix to keep customers happy, longer term we should either add
 * a country field to organizations / mobile apps, or guess the country based on Geo-IP.
 */
export const localizationToCountryCodeMap: Record<Language, Country> = {
  [Language.English]: Country.UnitedStates,
  [Language.Norwegian]: Country.Norway,
  [Language.NorwegianNynorsk]: Country.Norway,
  [Language.Japanese]: Country.Japan,
  [Language.German]: Country.Germany,
  [Language.Spanish]: Country.Spain,
  [Language.Catalan]: Country.Spain,
  [Language.Icelandic]: Country.Iceland,
  [Language.Swedish]: Country.Sweden,
  [Language.French]: Country.France,
  [Language.Portuguese]: Country.Brazil,
}

export class LocalizationPhoneHelper {
  public static getCallingCode(locale: string): string {
    const country = localizationToCountryCodeMap[locale as Language] || Country.UnitedStates
    return countryToCallingCodeMap[country] || countryToCallingCodeMap[Country.UnitedStates]
  }

  public static guessCountry(locale: string): string {
    return localizationToCountryCodeMap[locale as Language] || Country.UnitedStates
  }
}
