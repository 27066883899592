import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum UserFleetAgreementPermissionSet {
  View = 'user_fleet_agreement_view',
  New = 'user_fleet_agreement_new',
  Delete = 'user_fleet_agreement_delete',
  DeleteAll = 'user_fleet_agreement_delete_all',
}

export const userFleetAgreementPermissionSetGrouping: IPermissionSetGrouping<UserFleetAgreementPermissionSet> = {
  key: PermissionSetGroupingKey.UserFleetAgreement,
  active: {
    [UserFleetAgreementPermissionSet.View]: [PermissionType.GET_ANY_USER_FLEET_AGREEMENT],
    [UserFleetAgreementPermissionSet.New]: [
      PermissionType.POST_ANY_USER_FLEET_AGREEMENT,
      PermissionType.POST_ANY_USER_FLEET_AGREEMENT_SEND,
    ],
    [UserFleetAgreementPermissionSet.Delete]: [PermissionType.DELETE_ANY_USER_FLEET_AGREEMENT],
    [UserFleetAgreementPermissionSet.DeleteAll]: [PermissionType.DELETE_ALL_USER_FLEET_AGREEMENT],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
