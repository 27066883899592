import { GenericResource, IListModelQueryParamValues } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { ISamplePointsPostBody, ISamplePointsResponse, Resources } from '../types'

export class SamplePointsResource extends GenericResource<
  ISamplePointsResponse,
  IListModelQueryParamValues,
  null,
  ISamplePointsPostBody
> {
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.SamplePoints, agent)
  }
}
