import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { Resources } from '../types'
import { ITriggeredOptimizationRequest, ITriggeredOptimizationResponse } from '../types/TriggeredOptimizationTypes'

export class TriggeredOptimizationResource extends GenericResource<
  ITriggeredOptimizationResponse,
  never,
  never,
  ITriggeredOptimizationRequest
> {
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.TriggeredOptimizations, agent)
  }
}
