import { BadArgumentsError } from '@sparelabs/error-types'
import Chance from 'chance'
import randomSeed from 'random-seed'

const DEFAULT_SEED = '123456789'
const COLORS = [
  'Black',
  'Silver',
  'Gray',
  'White',
  'Maroon',
  'Red',
  'Purple',
  'Fuchsia',
  'Green',
  'Lime',
  'Olive',
  'Yellow',
  'Navy',
  'Blue',
  'Teal',
  'Aqua',
] as const
export type Color = typeof COLORS[number]

export class RandomClass {
  private static assertMinLessThanMax(min: number, max: number): void {
    if (max < min) {
      throw new BadArgumentsError(`Min (${min}) should not be greater than max (${max})`)
    }
  }

  private chance = new Chance(DEFAULT_SEED)
  private random = randomSeed.create(DEFAULT_SEED)

  constructor(seed: string = DEFAULT_SEED) {
    this.setSeed(seed)
  }

  public setSeed(seed: string): void {
    this.chance = new Chance(seed)
    this.random = randomSeed.create(seed)
  }

  /**
   * Generate a random floating point value between
   * the given min (inclusive) and max (exclusive).
   */
  public floatBetween(min: number, max: number): number {
    RandomClass.assertMinLessThanMax(min, max)
    return this.random.floatBetween(min, max)
  }

  /**
   * Generate an integer uniformly at random between
   * the given min and max (both inclusive).
   */
  public intBetween(min: number, max: number): number {
    RandomClass.assertMinLessThanMax(min, max)
    return this.random.intBetween(min, max)
  }

  /**
   * Generates the value "true" with the given probability.
   * (Otherwise, generates "false".)
   */
  public bool(probTrue: number): boolean {
    if (probTrue > 1 || probTrue < 0) {
      throw new BadArgumentsError(`Value provided (${probTrue}) is not a valid probability`)
    }
    const rand = this.random.floatBetween(0, 1)
    return rand < probTrue
  }

  public string(length: number): string {
    return this.chance.string({ length })
  }

  public color(): Color {
    return this.chooseValue(COLORS as unknown as Color[])
  }

  /**
   * Return a value selected uniformly at random from the given array.
   *
   * @throws if the array is empty
   */
  public chooseValue<T>(array: T[]): T {
    if (array.length < 1) {
      throw new BadArgumentsError('Cannot choose value from empty array')
    }
    const randIndex = this.intBetween(0, array.length - 1)
    return array[randIndex]
  }

  public pick<T>(array: T[], quantity: number): T[] {
    if (quantity > array.length) {
      throw new BadArgumentsError('Quantity must be less than array length', { array, quantity })
    }
    // spell-checker: disable-next-line
    return this.chance.pickset(array, quantity)
  }

  /**
   * Returns a shuffled array with Fisher–Yates shuffle Algorithm
   * @param values Array to shuffle
   */
  public shuffle = <T>(values: T[]): T[] => {
    for (let index = values.length - 1; index > 0; index--) {
      const swappedIndex = Math.floor(this.floatBetween(0, 1) * index)
      const temp = values[index]
      values[index] = values[swappedIndex]
      values[swappedIndex] = temp
    }
    return values
  }
}

export const Random = new RandomClass()
