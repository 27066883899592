import { MarkdownConverter } from '@sparelabs/markdown-utils'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import styled from 'styled-components'
import { borderRadius } from '../util'

const MarkdownContainer = styled.div<{ width?: string }>`
  ${({ width }) => (width ? `max-width: ${width};` : '')}
  line-height: 20px;
`

interface IProps {
  children: string
  skipHtml?: boolean
  width?: string
}

const converter = new MarkdownConverter()

export const Markdown = ({ children, skipHtml, width }: IProps): JSX.Element => {
  const markdown = skipHtml ? children : converter.convertToHtml(children)

  const components = {
    img: ({ ...props }) => <img style={{ width: '100%', borderRadius }} {...props} />,
  }

  return (
    <MarkdownContainer width={width}>
      <ReactMarkdown
        skipHtml={skipHtml}
        rehypePlugins={[rehypeRaw] as ReactMarkdown.PluggableList}
        components={components}
      >
        {markdown}
      </ReactMarkdown>
    </MarkdownContainer>
  )
}
