import { TimeConstraintType } from '../scheduling'
import { RequestIntentType } from '../types'
import { IRequestConstraints } from './RequestConstraints'

/**
 * Contains logic that acts on top of request constraints.
 */
export class RequestConstraintUtils {
  public static getEarliestSchedulablePickupTs(constraints: IRequestConstraints): number {
    switch (constraints.type) {
      case RequestIntentType.LeaveAt:
        return constraints.pickupConstraint.min
      case RequestIntentType.ArriveBy:
        switch (constraints.pickupConstraint.type) {
          case TimeConstraintType.Relative:
            return constraints.dropoffConstraint.min - constraints.pickupConstraint.maxOffset
          case TimeConstraintType.Absolute:
            return constraints.pickupConstraint.min
        }
    }
  }

  public static getLatestSchedulablePickupTs(constraints: IRequestConstraints, minTravelDuration: number): number {
    switch (constraints.type) {
      case RequestIntentType.LeaveAt:
        return constraints.pickupConstraint.max
      case RequestIntentType.ArriveBy:
        switch (constraints.pickupConstraint.type) {
          case TimeConstraintType.Relative:
            return constraints.dropoffConstraint.max - minTravelDuration
          case TimeConstraintType.Absolute:
            return constraints.pickupConstraint.max
        }
    }
  }

  public static getEarliestSchedulableDropoffTs(
    constraints: IRequestConstraints,
    minTravelDuration: number,
    pickupCompletedTs?: number
  ): number {
    switch (constraints.type) {
      case RequestIntentType.LeaveAt:
        return (pickupCompletedTs ?? constraints.pickupConstraint.min) + minTravelDuration
      case RequestIntentType.ArriveBy:
        return constraints.dropoffConstraint.min
    }
  }

  public static getLatestSchedulableDropoffTs(constraints: IRequestConstraints, pickupEta: number | null): number {
    switch (constraints.type) {
      case RequestIntentType.LeaveAt:
        return Math.max(pickupEta ?? 0, constraints.pickupConstraint.max) + constraints.dropoffConstraint.maxOffset
      case RequestIntentType.ArriveBy:
        return constraints.dropoffConstraint.max
    }
  }

  public static getDesiredPickupTs(constraint: IRequestConstraints, travelDurationSeconds: number): number {
    if (constraint.type === RequestIntentType.LeaveAt) {
      return constraint.pickupConstraint.desired
    }

    if (constraint.pickupConstraint.type === TimeConstraintType.Absolute) {
      return constraint.pickupConstraint.desired
    }

    return constraint.dropoffConstraint.desired - travelDurationSeconds
  }
}
