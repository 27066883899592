import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum LaunchPermissionSet {
  UsePortal = 'launch_use_portal',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const launchPermissionSetGrouping: IPermissionSetGrouping<LaunchPermissionSet> = {
  key: PermissionSetGroupingKey.Launch,
  active: {
    [LaunchPermissionSet.UsePortal]: [
      PermissionType.CAN_USE_ADMIN_PORTAL, // Required for Spare Launch to appear
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
