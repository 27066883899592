import { IAbsoluteTimeConstraint, IRelativeTimeConstraint, SlotType } from '../..'

// These override parameter types are used for estimates and virtual estimates
// Virtual estimates are matched as part of the request flow and use existing overrides
export enum OverrideParametersType {
  NoOverrides = 'NoOverrides',
  StandardOverrides = 'StandardOverrides',
  ConfigurableOverrides = 'ConfigurableOverrides',
}

export interface INoOverrides {
  type: OverrideParametersType.NoOverrides
}

export interface IStandardOverrides {
  type: OverrideParametersType.StandardOverrides
}

export interface IConfigurableOverrides {
  type: OverrideParametersType.ConfigurableOverrides
  includeCurrentRequest: boolean
  includeMatchedRequests: boolean
  excludeRequestIds: string[]
  dutyIdsToExtend: string[]
}

/**
 * In addition to the three previous interfaces we have IEngineRequestOverride as a fourth option.
 * This occurs from `POST /request` calls, after which virtual estimates are matched so that we can
 * rank dispatchers. IEngineRequestOverride is thus an option in two discriminating unions:
 *   - IOverrideParameters
 *   - IConstraintOverrideRule
 */
export type IOverrideParameters = INoOverrides | IStandardOverrides | IConfigurableOverrides | IEngineRequestOverride

export enum OverrideRuleType {
  Estimate = 'Estimate',
  Request = 'Request',
  None = 'None',
}

/**
 * General flow for constraint overrides:
 *  - Override estimate comes in:
 *    - Overrides for estimates essentially stretch constraints by a fixed amount equal to Service.maxConstraintOverride (NewOverrideCalculator).
 *    - For standard overrides this applies to all matched requests, and to the current request iif ManualWithNegotiation is used.
 *    - For configurable overrides this can apply to matched requests and/or the current request.
 *    - Constraints for the relevant requests are stretched (NewOverrideCalculator).
 *    - Estimates come back.
 *    - If the scheduled times are outside the real-time constraints then the corresponding request has been overridden.
 *    - Note: At this point it makes no difference whether the overrides are configurable or standard. All that matters are the times returned.
 *    - We calculate a generated override (GeneratedOverrideCalculator).
 *    - We add a small buffer to compensate for potential changes during the booking flow (ConstraintOverrideExtender).
 *    - Finally the overrides are saved to the EstimateResult.
 *
 *  - Request for that estimate tries to match:
 *    - As part of the request flow we first match a series of virtual estimates to rank dispatchers.
 *    - The overrides for both the request and the virtual estimates are determined by what was saved in the EstimateResult (NewOverrideCalculator).
 *    - Constraints for the relevant requests are stretched.
 *    - The request is matched.
 *
 *  - In the future, some other matching is occurring relating to the overridden request:
 *    - We stretch the constraints of any requests (and log)
 *      where their scheduledTs is outside of their real-time constraints (ExistingOverrideCalculator)
 */

export type IConstraintOverrideRule = IEngineEstimateOverride | IEngineRequestOverride | IEngineNullOverride

export interface IEngineEstimateOverride {
  type: OverrideRuleType.Estimate
  organizationId: string
  maxOverride: number
  includeCurrentRequest: boolean
  includeMatchedRequests: boolean
  excludeRequestIds: Set<string>
  dutyIdsToExtend: Set<string>
}
export interface IEngineRequestOverride {
  type: OverrideRuleType.Request
  constraintOverrides: IConstraintOverride[]
}

interface IEngineNullOverride {
  type: OverrideRuleType.None
}

export const EngineNullOverride: IEngineNullOverride = {
  type: OverrideRuleType.None,
}

export type IConstraintOverride = IRequestConstraintOverride | IDutyEndConstraintOverride

export type IRequestConstraintOverride = IAbsoluteConstraintOverride | IRelativeConstraintOverride

export enum ConstraintOverrideType {
  AbsoluteRequest = 'absolute',
  RelativeRequest = 'relative',
  DutyEnd = 'dutyEnd',
}

export interface IAbsoluteConstraintOverride {
  type: ConstraintOverrideType.AbsoluteRequest
  slotType: SlotType.Dropoff | SlotType.Pickup
  requestId: string
  scheduledTs: number
  constraint: IAbsoluteTimeConstraint
}

export interface IRelativeConstraintOverride {
  type: ConstraintOverrideType.RelativeRequest
  slotType: SlotType.Dropoff | SlotType.Pickup
  requestId: string
  newTimeInVehicle: number
  constraint: IRelativeTimeConstraint
}

export interface IDutyEndConstraintOverride {
  type: ConstraintOverrideType.DutyEnd
  dutyId: string
  scheduledTs: number
  endRequestedTs: number
}
