import { IRange } from '@sparelabs/math'
import { IAbsoluteTimeConstraint, OffsetDirection, TimeConstraintType } from './TimeConstraintTypes'

export class TimeConstraintUtils {
  public static toAbsolute(
    constraint: { maxOffset: number; direction: OffsetDirection },
    baseTs: number
  ): IAbsoluteTimeConstraint {
    return {
      type: TimeConstraintType.Absolute,
      min: constraint.direction === OffsetDirection.Before ? baseTs - constraint.maxOffset : baseTs,
      desired: baseTs,
      max: constraint.direction === OffsetDirection.After ? baseTs + constraint.maxOffset : baseTs,
    }
  }

  public static toWidestPossibleAbsolute(
    constraint: { maxOffset: number; direction: OffsetDirection },
    base: IAbsoluteTimeConstraint
  ): IRange {
    switch (constraint.direction) {
      case OffsetDirection.Before:
        return {
          min: base.min - constraint.maxOffset,
          max: base.max,
        }
      case OffsetDirection.After:
        return {
          min: base.min,
          max: base.max + constraint.maxOffset,
        }
    }
  }
}
