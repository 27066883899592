import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum BulkMessagePermissionSet {
  View = 'bulk_message_view',
  Write = 'bulk_message_write',
}

export const bulkMessagePermissionSetGrouping: IPermissionSetGrouping<BulkMessagePermissionSet> = {
  key: PermissionSetGroupingKey.BulkMessage,
  active: {
    [BulkMessagePermissionSet.View]: [
      PermissionType.GET_ANY_BULK_NOTIFICATION,
      PermissionType.GET_ANY_BULK_NOTIFICATION_ANALYTICS,
    ],
    [BulkMessagePermissionSet.Write]: [PermissionType.WRITE_ANY_BULK_NOTIFICATION],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
