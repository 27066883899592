import asyncPoolLib from 'tiny-async-pool'

const DEFAULT_MAX_CONCURRENCY = 8

/**
 * Runs multiple promise-returning & async functions in a limited concurrency pool. It rejects immediately as soon as
 * one of the promises rejects. It resolves when all the promises complete.
 *
 * This is to be preferred over using `Promise.all` on arrays of unknown length, to limit CPU-intensiveness of
 * workloads. `Promise.all` can be dangerous if you have a very large number of tasks, as it executes them all
 * concurrently, while this limits how many tasks are executed concurrently.
 *
 * We create this thin wrapper around the 3rd-party dependency to:
 * - Provide a reasonable default concurrency limit
 * - Make dependency upgrades easier (upgrade tiny-async-pool in one place for all of our packages)
 *
 * @param items The items to run the task against
 * @param callback The task to run against each item
 * @param maxConcurrency The max number of tasks to run concurrently
 * @returns The results of the tasks
 */
export const asyncPool = <In, Out>(
  items: readonly In[],
  task: (item: In) => Promise<Out>,
  maxConcurrency: number = DEFAULT_MAX_CONCURRENCY
): Promise<Out[]> => asyncPoolLib(maxConcurrency, items, task)

export const safeAsyncPool = async <In, Success, Failure>(
  items: readonly In[],
  task: (item: In) => Promise<Success>,
  handleError: (item: In, error: unknown) => Promise<Failure>,
  maxConcurrency: number = DEFAULT_MAX_CONCURRENCY
): Promise<{ successes: Success[]; failures: Failure[] }> => {
  const successes: Success[] = []
  const failures: Failure[] = []

  const safeTask = async (item: In) => {
    try {
      successes.push(await task(item))
    } catch (error) {
      failures.push(await handleError(item, error))
    }
  }

  await asyncPool(items, safeTask, maxConcurrency)
  return { successes, failures }
}
