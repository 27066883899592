/**
 *  Stripe card brand format on PaymentMethods
 */
export enum StripeCardBrand {
  Visa = 'visa',
  Amex = 'amex',
  MasterCard = 'mastercard',
  Discover = 'discover',
  Jcb = 'jcb',
  Diners = 'diners',
  Unionpay = 'unionpay',
  Unknown = 'unknown',
}
