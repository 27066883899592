import { AddressFieldName, AddressFieldNameNoCompanyAndPhone, AddressFormatter, IAddress } from '@sparelabs/address'
import { BrowserLanguageHelper } from '@sparelabs/translate'
import { isEqual, uniqueId } from 'lodash'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTryAsync } from '../../hooks/useTryAsync'
import { InputLabel } from '../InputWithLabel'
import { SingleChoiceInput } from './SingleChoiceInput'
import { TextInput } from './TextInput'

const FieldContainer = styled.div`
  flex-basis: 100%;
  min-width: 0px;
  margin-bottom: 16px;
`
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 500px;

  > :not(:last-child) {
    margin-right: 5px;
  }
`

const Container = styled.div`
  padding-bottom: 4px;
`

interface IProps {
  value?: Partial<IAddress>
  onChange: (value: Partial<IAddress>) => void
  apiUrl: string
  onError: (error: Error) => void
  id?: string
}

const DEFAULT_ADDRESS_DATA = { version: 1, country: 'US' }

export const AddressInput = ({ value, onChange, apiUrl, onError, id = uniqueId() }: IProps) => {
  const addressFormatter = new AddressFormatter(apiUrl, BrowserLanguageHelper.getCurrentLanguageCode())

  const { value: countries } = useTryAsync(onError, async () => addressFormatter.getCountries())

  const countryChoices: Array<{ label: string; value: string }> = countries
    ? countries.map((country) => ({ value: country.code, label: country.name }))
    : []

  const [format, setFormat] = useState<AddressFieldName[][]>([])
  const [labelTable, setLabelTable] = useState<Record<AddressFieldNameNoCompanyAndPhone, string>>()
  const [zoneChoices, setZoneChoices] = useState<Array<{ label: string; value: string }>>([])

  const [address, setAddress] = useState<Partial<IAddress>>(value ?? DEFAULT_ADDRESS_DATA)

  const setStateTable: Record<AddressFieldNameNoCompanyAndPhone, (arg?: string) => void> = {
    [AddressFieldName.FirstName]: (firstName) => setAddress({ ...address, firstName }),
    [AddressFieldName.LastName]: (lastName) => setAddress({ ...address, lastName }),
    [AddressFieldName.Country]: (country) => setAddress({ ...address, country }),
    [AddressFieldName.City]: (city) => setAddress({ ...address, city }),
    [AddressFieldName.PostalCode]: (zip) => setAddress({ ...address, zip }),
    [AddressFieldName.Zone]: (province) => setAddress({ ...address, province }),
    [AddressFieldName.Address1]: (address1) => setAddress({ ...address, address1 }),
    [AddressFieldName.Address2]: (address2) => setAddress({ ...address, address2 }),
  }

  const stateTable: Record<AddressFieldNameNoCompanyAndPhone, string> = {
    [AddressFieldName.FirstName]: address.firstName ?? '',
    [AddressFieldName.LastName]: address.lastName ?? '',
    [AddressFieldName.Country]: address.country ?? '',
    [AddressFieldName.City]: address.city ?? '',
    [AddressFieldName.PostalCode]: address.zip ?? '',
    [AddressFieldName.Zone]: address.province ?? '',
    [AddressFieldName.Address1]: address.address1 ?? '',
    [AddressFieldName.Address2]: address.address2 ?? '',
  }

  useEffect(() => {
    onChange({ ...address })
  }, [address])

  useEffect(() => {
    if (!isEqual(value, address)) {
      setAddress(value ?? DEFAULT_ADDRESS_DATA)
    }
  }, [value])

  useTryAsync(
    onError,
    async () => {
      const countryCode = address.country ?? DEFAULT_ADDRESS_DATA.country
      const country = await addressFormatter.getCountry(countryCode)
      setFormat(await addressFormatter.getOrderedFields(countryCode))
      setZoneChoices(country.zones.map((zone) => ({ value: zone.code, label: zone.name })))
      setLabelTable(addressFormatter.createLabelTable(country))
      if (!country.zones.map((zone) => zone.code).includes(address.province ?? '')) {
        setAddress({ ...address, province: '' })
      }
    },
    [address.country]
  )

  const renderField = (
    field: AddressFieldNameNoCompanyAndPhone,
    labelTable: Record<AddressFieldNameNoCompanyAndPhone, string>
  ) => (
    <FieldContainer key={field}>
      <InputLabel htmlFor={id}>{labelTable[field]}</InputLabel>
      {field === AddressFieldName.Country ? (
        <SingleChoiceInput<string>
          choices={countryChoices}
          value={stateTable[field]}
          onChange={setStateTable[field]}
          id={id}
        />
      ) : field === AddressFieldName.Zone ? (
        <SingleChoiceInput<string>
          choices={zoneChoices}
          value={stateTable[field]}
          onChange={setStateTable[field]}
          id={id}
        />
      ) : (
        <TextInput label={field} value={stateTable[field]} onChange={setStateTable[field]} id={id} />
      )}
    </FieldContainer>
  )

  return (
    <Container>
      {labelTable &&
        format.map((row) => (
          <RowContainer key={row[0]}>
            {row
              .filter((field) => field !== AddressFieldName.Phone && field !== AddressFieldName.Company)
              .map((field) => renderField(field as AddressFieldNameNoCompanyAndPhone, labelTable))}
          </RowContainer>
        ))}
    </Container>
  )
}
