import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IRouteAssignmentListQueryParams,
  IRouteAssignmentPostBody,
  IRouteAssignmentResponse,
  IRouteAssignmentStateResponse,
  Resources,
} from '../types'

export class RouteAssignmentResource extends GenericResource<
  IRouteAssignmentResponse,
  IRouteAssignmentListQueryParams,
  Partial<IRouteAssignmentPostBody>,
  IRouteAssignmentPostBody
> {
  public post = this.buildPost()
  public del = this.buildDel()
  public list = this.buildList()
  constructor(agent: AxiosInstance) {
    super(Resources.RouteAssignments, agent)
  }

  public async getAssignmentState(date: string): Promise<IRouteAssignmentStateResponse> {
    const res = await this.agent.get<IRouteAssignmentStateResponse>('routeAssignmentState', { params: { date } })
    return res.data
  }
}
