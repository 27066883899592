import React, { useState } from 'react'
import { ButtonSpinner } from '../animations'
import { AbstractButton, IButtonProps } from './AbstractButton'
import { LoadingButtonTheme } from './ButtonThemes'

export interface IAsyncButtonProps extends IButtonProps {
  onClick: () => Promise<void>
}

export const AsyncAbstractButton = ({
  children,
  icon,
  validateActionText,
  disabled,
  onClick,
  ...rest
}: IAsyncButtonProps) => {
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <AbstractButton
      {...rest}
      {...(loading ? { theme: LoadingButtonTheme } : { icon, validateActionText })}
      disabled={disabled || loading}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick={async () => {
        if (loading) {
          return
        }

        setLoading(true)

        try {
          await onClick()
        } catch (error) {
          // Do nothing.
        }

        setLoading(false)
      }}
    >
      {loading && <ButtonSpinner />}
      {children}
    </AbstractButton>
  )
}
