import {
  AuthContextPropagator,
  CrossServiceContextPropagator,
  IAuthContext,
  ICrossServiceContext,
} from '@sparelabs/context-propagation'
import { MetadataError } from '@sparelabs/error-types'
import { isEmpty, omit } from 'lodash'
import { IHttpRequestLogPayload, ILogPayload } from './LogTypes'

/**
 * We format logs entries to be compatible with GCP Logging:
 * https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry
 */
export interface ILogEntry extends Partial<IGoogleCloudErrorFields>, Partial<ICrossServiceContext> {
  message: string
  httpRequest?: IHttpRequestLogPayload
  authContext?: IAuthContext
  data?: Record<string, unknown>
  [key: string]: unknown
}

/**
 * For logs with errors, we format error details to be compatible with GCP Error Reporting:
 * https://cloud.google.com/error-reporting/docs/formatting-error-messages
 */
interface IGoogleCloudErrorFields {
  serviceContext: {
    service: string
    version?: string
  }
  context?: {
    httpRequest?: {
      method?: string
      url?: string
      responseStatusCode?: number
      remoteIp?: string
    }
  }
  stack_trace?: string
  exception?: unknown
}

interface IPackageJson {
  name: string
  version: string
}

const getPackageJson = (): IPackageJson => {
  const fallback = {
    name: '<unknown package>',
    version: '<unknown version>',
  }
  try {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    const packageJson = require(`${process.cwd()}/package.json`) as unknown as Partial<IPackageJson>
    return {
      name: packageJson.name ?? fallback.name,
      version: packageJson.version ?? fallback.version,
    }
  } catch (error) {
    return fallback
  }
}

// MetadataError and its subclasses contains some bloat from the extendable-error lib
const unwantedExtendableErrorFields = ['_stack', '_error']

export class LogEntryBuilder {
  private static readonly packageJson: IPackageJson = getPackageJson()

  public static build(message: string, payload: ILogPayload, machineReadable: boolean): ILogEntry {
    const { httpRequest, error, ...data } = payload
    return {
      message,
      data: isEmpty(data) ? undefined : data,
      httpRequest: isEmpty(httpRequest) ? undefined : httpRequest,
      authContext: AuthContextPropagator.getContext() ?? undefined,
      ...CrossServiceContextPropagator.getContext(),
      ...(error !== undefined && error !== null && this.formatError(error, machineReadable)),
    }
  }

  private static formatError(
    error: unknown,
    machineReadable: boolean
  ): (IGoogleCloudErrorFields & { error?: unknown }) | { error: unknown } {
    // GCP Logging expects one representation of errors to render them well, pino-pretty expects another
    return machineReadable ? this.formatErrorForGoogleCloud(error) : this.formatErrorForPino(error)
  }

  private static formatErrorForGoogleCloud(error: unknown): IGoogleCloudErrorFields & { error?: unknown } {
    const formattedError: { stack_trace?: string; error?: unknown; exception?: unknown } =
      error instanceof Error
        ? {
            stack_trace: error.stack,
            error: {
              name: error.name ?? '',
              message: error.message ?? '',
              ...omit(error, ['name', 'message', 'stack', ...unwantedExtendableErrorFields]),
            },
          }
        : { exception: error }
    return {
      serviceContext: {
        service: this.packageJson.name,
        version: this.packageJson.version,
      },
      ...formattedError,
    }
  }

  private static formatErrorForPino(error: unknown): { error: unknown } {
    // pino just requires an error field
    return { error: error instanceof MetadataError ? omit(error, unwantedExtendableErrorFields) : error }
  }
}
