import { GenericResource, IListResponse } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IListMobileAppQueryParams,
  IListMobileAppWithReleaseInfoQueryParams,
  IMobileAppPatchBody,
  IMobileAppPostBody,
  IMobileAppResponse,
  Resources,
} from '../types'

export class MobileAppResource extends GenericResource<
  IMobileAppResponse,
  IListMobileAppQueryParams,
  IMobileAppPatchBody,
  IMobileAppPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  public del = this.buildDel()

  constructor(agent: AxiosInstance) {
    super(Resources.MobileApps, agent)
  }

  public async search(search: string): Promise<IListResponse<IMobileAppResponse>> {
    const { data } = await this.agent.get<IListResponse<IMobileAppResponse>>(this.resource, { params: { search } })
    return data
  }

  public async getWithReleaseInfo(mobileAppId: string): Promise<IMobileAppResponse> {
    const { data } = await this.agent.get<IMobileAppResponse>(`whitelabel/${this.resource}/${mobileAppId}`)
    return data
  }

  public async listWithReleaseInfo(
    params: IListMobileAppWithReleaseInfoQueryParams,
    fetchAllPages: boolean = false
  ): Promise<IListResponse<IMobileAppResponse>> {
    if (fetchAllPages) {
      const data = await this.allPageFetcher.fetch<IMobileAppResponse, IListMobileAppQueryParams>(
        `whitelabel/${this.resource}`,
        params
      )
      return { data, skip: 0, total: data.length, limit: data.length }
    }

    const { data } = await this.agent.get<IListResponse<IMobileAppResponse>>(`whitelabel/${this.resource}`, {
      params,
    })

    return data
  }

  public async postOrganizationLink(mobileAppId: string, organizationId: string): Promise<void> {
    await this.agent.post(this.buildOrganizationPath(mobileAppId), { organizationId })
  }

  public async deleteOrganizationLink(mobileAppId: string, organizationId: string): Promise<void> {
    await this.agent.delete(`${this.buildOrganizationPath(mobileAppId)}/${organizationId}`)
  }

  private buildOrganizationPath(mobileAppId: string): string {
    return `${this.resource}/${mobileAppId}/${Resources.Organizations}`
  }
}
