import { AxiosInstance } from 'axios'
import { INylasAdminGrantResponse, INylasAuthPostBody, INylasAuthResponse, INylasCallbackPostBody } from '../types'

export class NylasAuthResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async postAuth(body: INylasAuthPostBody): Promise<INylasAuthResponse> {
    const res = await this.agent.post<INylasAuthResponse>('engage/nylas/auth', body)
    return res.data
  }

  public async postCallback(body: INylasCallbackPostBody): Promise<INylasAdminGrantResponse> {
    const res = await this.agent.post<INylasAdminGrantResponse>('engage/nylas/callback', body)
    return res.data
  }
}
