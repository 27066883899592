import { WebColors } from '@sparelabs/colors'
import React from 'react'
import { st } from '../locales/TranslationHelper'
import { Banner } from './Banner'
import { IBannerTheme } from './BannerThemes'

const superAdminTheme: IBannerTheme = {
  backgroundColor: WebColors.accentPurpleLowContrast,
  textColor: WebColors.accentPurpleHighContrast,
  icon: 'magic',
}

export const RootAdminBanner = () => <Banner theme={superAdminTheme}>{st.foundations.restrictedToRootAdmins()}</Banner>
