import { AllReportsModels } from './AnalyzeReportModelTypes'

export enum CaseActivityLogReportEventColumns {
  Time = 'time',
  Activity = 'activity',
  CreatedInterface = 'createdInterface',
  AuthorId = 'authorId',
  AuthorName = 'authorName',
  Details = 'details',
}

/**
 * Models that are included in the Case Activity Log Report
 *
 * The order here controls the order of models on the 'Edit Columns' modal
 */
export const CaseActivityLogReportModelNamesArray = [AllReportsModels.Event]

export type ICaseActivityLogReportColumns = CaseActivityLogReportEventColumns

export const CaseActivityLogReportModelAndColumns = {
  [AllReportsModels.Event]: CaseActivityLogReportEventColumns,
}

export const CaseActivityLogReportColumnNames = Object.values<ICaseActivityLogReportColumns>(
  CaseActivityLogReportEventColumns
)
