import { isEmpty } from 'lodash'
import pino, {
  Bindings,
  LoggerOptions,
  SerializerFn,
  TransportMultiOptions,
  TransportPipelineOptions,
  TransportSingleOptions,
} from 'pino'
import { ILogEntry, LogEntryBuilder } from './LogEntryBuilder'
import { ILogger, ILoggerBuildOptions, ILogPayload, LogLevel } from './LogTypes'

const DEFAULT_LOG_NAMESPACE: string = 'spare'
const SILENT_LOG_LEVEL: string = 'silent'
const CLUSTER_ENVIRONMENTS: Array<string | undefined> = ['production', 'staging', 'testing']
const messageKey: string = 'message'

// Map pino log levels to GCP log levels
const gcpLogLevels: Record<string, string> = {
  [LogLevel.Error]: 'ERROR',
  [LogLevel.Warn]: 'WARNING',
  [LogLevel.Info]: 'INFO',
  [LogLevel.Trace]: 'DEBUG',
}

interface IPinoFormatters {
  level?: (label: string, number: number) => object
  bindings?: (bindings: Bindings) => object
  log?: (object: Record<string, unknown>) => Record<string, unknown>
}

type IPinoTransports = TransportSingleOptions | TransportMultiOptions | TransportPipelineOptions

export class Logger implements ILogger {
  public static build(namespace: string, buildOptions?: ILoggerBuildOptions): ILogger {
    const name = this.buildName(namespace, buildOptions)
    const machineReadable = this.machineReadableLogs()
    const level = this.buildLevel()
    const isSilent = level === SILENT_LOG_LEVEL
    const pinoOptions: LoggerOptions = {
      name: this.buildName(namespace, buildOptions),
      level: this.buildLevel(),
      messageKey,
      formatters: isSilent ? undefined : this.buildFormatters(machineReadable),
      transport: isSilent ? undefined : this.buildTransport(machineReadable),
      serializers: isSilent ? undefined : this.buildSerializers(machineReadable),
    }
    // In Jest tests, the logs sometimes gets lost, this workaround fixes that
    const pinoLogger = this.isJest() ? pino(pinoOptions, pino.destination()) : pino(pinoOptions)
    return new Logger(name, pinoLogger, machineReadable, buildOptions?.defaultPayload)
  }

  private constructor(
    public readonly namespace: string,
    private readonly pinoLogger: pino.Logger,
    private readonly machineReadable: boolean,
    private readonly defaultPayload?: Record<string, unknown>
  ) {}

  public error(message: string, payload?: ILogPayload): void {
    // These isLevelEnabled checks are just a small optimization - checking if a level is enabled is basically free,
    // building a log entry is more expensive
    if (this.isLevelEnabled(LogLevel.Error)) {
      this.pinoLogger.error(this.buildLogEntry(message, payload))
    }
  }

  public warn(message: string, payload?: ILogPayload): void {
    if (this.isLevelEnabled(LogLevel.Warn)) {
      this.pinoLogger.warn(this.buildLogEntry(message, payload))
    }
  }

  public info(message: string, payload?: ILogPayload): void {
    if (this.isLevelEnabled(LogLevel.Info)) {
      this.pinoLogger.info(this.buildLogEntry(message, payload))
    }
  }

  public trace(message: string, payload?: ILogPayload): void {
    if (this.isLevelEnabled(LogLevel.Trace)) {
      this.pinoLogger.trace(this.buildLogEntry(message, payload))
    }
  }

  public isLevelEnabled(level: LogLevel): boolean {
    return this.pinoLogger.isLevelEnabled(level)
  }

  private static isTest(): boolean {
    return process.env.NODE_ENV?.toLowerCase() === 'test'
  }

  private static isJest(): boolean {
    return Boolean(process.env.JEST_WORKER_ID)
  }

  private static machineReadableLogs(): boolean {
    if (process.env.MACHINE_READABLE_LOGS?.toLowerCase() === 'true') {
      return true
    }
    return CLUSTER_ENVIRONMENTS.includes(process.env.NODE_ENV)
  }

  private static buildName(namespace: string, buildOptions?: ILoggerBuildOptions): string {
    const parentNamespace = buildOptions?.parentLogger?.namespace ?? DEFAULT_LOG_NAMESPACE

    const service: string | undefined = process.env.SPARE_SERVICE_NAME ?? undefined
    return `${service ? `${service.toUpperCase()}:` : ''}${parentNamespace}:${namespace}`
  }

  private static buildLevel(): string {
    const defaultLevel = this.isTest() ? SILENT_LOG_LEVEL : LogLevel.Info
    return process.env.LOG_LEVEL?.toLowerCase() ?? defaultLevel
  }

  private static buildFormatters(machineReadable: boolean): IPinoFormatters | undefined {
    if (machineReadable) {
      return {
        level: (label: string) => ({ severity: gcpLogLevels[label] ?? 'ERROR' }),
      }
    }
    return undefined
  }

  private static buildTransport(machineReadable: boolean): IPinoTransports | undefined {
    if (machineReadable) {
      return undefined
    }
    return {
      target: 'pino-pretty',
      options: {
        colorize: true,
        singleLine: true,
        messageKey,
        translateTime: 'SYS:yyyy-mm-dd HH:MM:ss',
        ignore: 'pid,hostname',
      },
    }
  }

  private static buildSerializers(machineReadable: boolean): { [key: string]: SerializerFn } | undefined {
    if (machineReadable) {
      return undefined
    }
    return { error: pino.stdSerializers.err }
  }

  private buildLogEntry(message: string, payload?: ILogPayload): ILogEntry {
    const combinedPayload = isEmpty(this.defaultPayload) ? payload ?? {} : { ...payload, ...this.defaultPayload }
    return LogEntryBuilder.build(message, combinedPayload, this.machineReadable)
  }
}
