import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum DriverUserPermissionSet {
  View = 'driver_user_view',
  Edit = 'driver_user_edit',
  Delete = 'driver_user_delete',
  Import = 'driver_user_import',
  Export = 'driver_user_export',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const driverUserPermissionSetGrouping: IPermissionSetGrouping<DriverUserPermissionSet> = {
  key: PermissionSetGroupingKey.DriverUser,
  active: {
    [DriverUserPermissionSet.View]: [
      PermissionType.GET_ANY_DRIVER,
      PermissionType.LIST_ANY_DRIVER,
    ],
    [DriverUserPermissionSet.Edit]: [
      PermissionType.POST_ANY_DRIVER,
      PermissionType.PATCH_ANY_DRIVER,
      PermissionType.POST_UPLOAD_PHOTO /* Used for uploading a profile photos to users accounts */,
      PermissionType.POST_ANY_FILE /* Used for uploading files to users profiles */,
    ],
    [DriverUserPermissionSet.Delete]: [PermissionType.DELETE_ANY_DRIVER],
    [DriverUserPermissionSet.Import]: [PermissionType.IMPORT_ANY_DRIVER],
    [DriverUserPermissionSet.Export]: [PermissionType.EXPORT_ANY_DRIVER],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
