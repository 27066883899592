import { HelperDelegate } from 'handlebars'

// Definition Types
export enum MarkdownVariableDefinitionType {
  Default = 'default',
  Link = 'link',
  Logic = 'logic',
}

export interface IMarkdownVariableDefinitionValue {
  key: string
  label: string
  insertAs?: MarkdownVariableDefinitionType
  linkLabel?: string
}

export interface IMarkdownVariableDefinition {
  key: string
  groupLabel?: string
  values: IMarkdownVariableDefinitionValue[]
}

// Implementation Types
export type MarkdownVariableData = Record<string, unknown>
export type MarkdownVariableHelper = HelperDelegate
export type MarkdownVariableHelpers = Record<string, HelperDelegate>

// Grouped Definition and Implementation Type
export interface IMarkdownVariable<DefinitionData extends object, RenderData extends object> {
  buildDefinition: (data: DefinitionData) => IMarkdownVariableDefinition[] | undefined
  buildData?: (data: RenderData) => MarkdownVariableData | undefined | Promise<MarkdownVariableData | undefined>
  buildHelpers?: (
    data: RenderData
  ) => MarkdownVariableHelpers | undefined | Promise<MarkdownVariableHelpers | undefined>
}
