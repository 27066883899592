import React from 'react'
import styled from 'styled-components'
import { IInputProps } from '../InputTypes'
import { ISingleChoice, SingleChoiceButton } from './SingleChoice'

const SingleChoiceWrapper = styled.div`
  flex-direction: row;
`

export interface ISingleChoiceCustomInput<T> extends Omit<IInputProps<T>, 'onChange' | 'value'> {
  choices: T[]
  onChange: (value: T) => void
  renderOption?: (value: T) => JSX.Element
  id?: string
  value?: string
  disabled?: boolean
  containerSize?: number
}

export const SingleChoiceCustomInput = <T extends ISingleChoice>({
  id,
  choices,
  value,
  onChange,
  containerSize,
  disabled,
  renderOption,
  ...inputAttributes
}: ISingleChoiceCustomInput<T>): JSX.Element => (
  <SingleChoiceWrapper role='radiogroup' id={id}>
    {choices.map((choice) => (
      <SingleChoiceButton<T>
        {...inputAttributes}
        key={choice.value}
        isSelected={choice.value === value}
        option={choice}
        onChange={onChange}
        containerSize={containerSize}
        disabled={disabled}
        renderOption={renderOption}
      />
    ))}
  </SingleChoiceWrapper>
)
