import { marked, Renderer as MarkedRenderer } from 'marked'

// Type extension to allow for overwritten properties in the MarkedRenderer
class ExtendedMarkedRenderer extends MarkedRenderer {
  [key: string]: unknown
}

export class MarkedRendererBuilder {
  public static readonly DEFAULT_OVERRIDES: marked.RendererObject = {
    // Every new line in markdown is considered a new paragraph, this prevents <img> tags from being wrapped in <p> tags
    paragraph: (text: string): string => {
      if (text.startsWith('<img')) {
        return `${text}\n`
      }
      return `<p>${text}</p>\n`
    },
  }

  public static readonly PRINT_OVERRIDES: marked.RendererObject = {
    link: (href: string, title: string, text: string) => {
      const cleanHref = this.cleanUrl(href)
      if (cleanHref === null) {
        return text
      }
      return `<a href="${cleanHref}"${title ? ` title="${title}"` : ''}>${text} (${cleanHref})</a>`
    },
  }

  public static build(overrides: Partial<marked.RendererObject> = this.DEFAULT_OVERRIDES): MarkedRenderer {
    const renderer = new ExtendedMarkedRenderer()

    for (const [key, property] of Object.entries(overrides)) {
      renderer[key] = property
    }

    return renderer
  }

  // Source: https://github.com/markedjs/marked/blob/f0507cbd83c63f42b16cda6f9c18de71dfb17ebb/src/helpers.ts#L66
  private static cleanUrl(href: string) {
    try {
      href = encodeURI(href).replace(/%25/g, '%')
    } catch (_error) {
      return null
    }
    return href
  }
}
