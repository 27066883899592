import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum VehiclePermissionSet {
  View = 'vehicle_view',
  New = 'vehicle_new',
  Edit = 'vehicle_edit',
  Import = 'vehicle_import',
  Export = 'vehicle_export',
  ViewInspection = 'vehicle_view_inspection',
  ManageInspection = 'vehicle_manage_inspection',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const vehiclePermissionSetGrouping: IPermissionSetGrouping<VehiclePermissionSet> = {
  key: PermissionSetGroupingKey.Vehicle,
  active: {
    [VehiclePermissionSet.View]: [
      PermissionType.GET_ANY_VEHICLE,
      PermissionType.LIST_ANY_VEHICLE,
    ],
    [VehiclePermissionSet.New]: [PermissionType.POST_ANY_VEHICLE],
    [VehiclePermissionSet.Edit]: [PermissionType.PATCH_ANY_VEHICLE],
    [VehiclePermissionSet.Import]: [PermissionType.IMPORT_ANY_VEHICLE],
    [VehiclePermissionSet.Export]: [PermissionType.EXPORT_ANY_VEHICLE],
    [VehiclePermissionSet.ManageInspection]: [
      PermissionType.WRITE_ANY_VEHICLE_INSPECTION,
      PermissionType.WRITE_ANY_VEHICLE_INSPECTION_TEMPLATE,
      PermissionType.WRITE_ANY_CUSTOM_FIELD,
      PermissionType.GET_ANY_VEHICLE_INSPECTION,
      PermissionType.GET_ANY_VEHICLE_INSPECTION_TEMPLATE,
      PermissionType.LIST_ANY_VEHICLE_INSPECTION_TEMPLATE,
      PermissionType.GET_ANY_DRIVER,
      PermissionType.GET_ANY_VEHICLE,
      PermissionType.GET_ANY_DUTY,
      PermissionType.LIST_ANY_DRIVER,
      PermissionType.LIST_ANY_VEHICLE,
      PermissionType.LIST_ANY_DUTY,
      PermissionType.LIST_ANY_CUSTOM_FIELD,
    ],
    [VehiclePermissionSet.ViewInspection]: [
      PermissionType.GET_ANY_VEHICLE_INSPECTION,
      PermissionType.GET_ANY_VEHICLE_INSPECTION_TEMPLATE,
      PermissionType.LIST_ANY_VEHICLE_INSPECTION_TEMPLATE,
      PermissionType.GET_ANY_DRIVER,
      PermissionType.GET_ANY_VEHICLE,
      PermissionType.GET_ANY_DUTY,
      PermissionType.LIST_ANY_DRIVER,
      PermissionType.LIST_ANY_VEHICLE,
      PermissionType.LIST_ANY_DUTY,
      PermissionType.LIST_ANY_CUSTOM_FIELD,
    ]
  },
  unAssignablePermissionSets: [],
  deprecated: [],
}
