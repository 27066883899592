import { IModelJsonValues } from '@sparelabs/http-client-utils'
import { IChargeStub } from './ChargeTypes'
import { CreatedInterface } from './EstimateTypes'

export enum WalletTransactionType {
  TopUp = 'topUp',
  AutoTopUp = 'autoTopUp',
  Refund = 'refund',
  Spend = 'spend',
}

export interface IPaymentProviderConfigWallet {
  clientSafe: {
    minTopUp: number
    maxBalance: number | undefined
    canTopUpFromRider: boolean
    defaultAutoTopUpTriggerAmount: number | undefined
  }
  clientUnsafe: Record<never, undefined>
}

export interface IWalletTransactionResponse extends IModelJsonValues {
  userId: string
  type: WalletTransactionType
  notes: string | null
  amountChanged: number
  tripRequestedPickupTs: number | null
  charge: IChargeStub | null
  requestId: string | null
  createdInterface: CreatedInterface | null
}

export interface IAddWalletBalanceResponse {
  balance: number
  walletPaymentMethodId: string
}

export interface IAddWalletBalancePostBody {
  userId: string
  walletPaymentMethodTypeId: string
  chargePaymentMethodId: string | null
  amountToAdd: number
  notes?: string | null
  createdInterface?: CreatedInterface // TODO make required when rider changes are done
}

export interface IWalletAutoTopUpResponse {
  id: string
  amount: number
  triggerAmount: number
  walletId: string
  userId: string
  paymentMethodId: string | null
  hasRiderAgreed: boolean
  createdInterface: CreatedInterface
}

export interface IWalletAutoTopUpPatchBody {
  amount: number
  triggerAmount: number
  paymentMethodId: string
  hasRiderAgreed: boolean
}

export interface IWalletAutoTopUpPostBody {
  userId: string
  amount: number
  triggerAmount: number
  paymentMethodId: string
  hasRiderAgreed: boolean
  createdInterface?: CreatedInterface // TODO make required when rider changes are done
}
