import { ReportType } from './AnalyzeReportsTypes'

export enum ReportFileFormat {
  Csv = 'csv',
  Xlsx = 'xlsx',
}

export enum ReportJobStatus {
  InProgress = 'inProgress',
  Completed = 'completed',
  Failed = 'failed',
}

export type IAnalyzeReportJobPostBody = IAnalyzeReportParameters

export type IAnalyzeReportParameters =
  | IRidershipReportParameters
  | IDriverReportParameters
  | IServiceNtdReportParameters
  | IFleetNtdReportParameters

interface IAnalyzeReportParametersCommon<Name extends ReportType, Filters extends {}> {
  type: Name
  fileFormat: ReportFileFormat
  filters: Filters
  fields?: string[]
}

export interface IRidershipReportParameters
  extends IAnalyzeReportParametersCommon<
    ReportType.Ridership,
    {
      startTs: number
      endTs: number
      fleetIds?: string[]
      serviceIds?: string[]
    }
  > {}

export interface IDriverReportParameters
  extends IAnalyzeReportParametersCommon<
    ReportType.Driver,
    {
      startTs: number
      endTs: number
      fleetIds: string[]
    }
  > {}

export interface IServiceNtdReportParameters
  extends IAnalyzeReportParametersCommon<
    ReportType.ServiceNTD,
    {
      startDate: string
      endDate: string
      serviceIds: string[]
    }
  > {}

export interface IFleetNtdReportParameters
  extends IAnalyzeReportParametersCommon<
    ReportType.FleetNTD,
    {
      startDate: string
      endDate: string
      fleetIds: string[]
    }
  > {}

export type IAnalyzeReportJobPayload =
  | IInProgressReportJobPayload
  | ICompletedReportJobPayload
  | IFailedReportJobPayload

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInProgressReportJobPayload {}

export interface ICompletedReportJobPayload {
  reportUrl: string
}

export interface IFailedReportJobPayload {
  failureCode: string
  failureMessage: string
}

export type IAnalyzeReportJobResponse = IInProgressReportJob | ICompletedReportJob | IFailedReportJob

interface IAnalyzeReportJobCommon<Status extends ReportJobStatus, Payload extends IAnalyzeReportJobPayload> {
  id: string
  createdAt: number
  updatedAt: number
  status: Status
  parameters: IAnalyzeReportParameters
  payload: Payload
}

export interface IInProgressReportJob
  extends IAnalyzeReportJobCommon<ReportJobStatus.InProgress, IInProgressReportJobPayload> {}

export interface ICompletedReportJob
  extends IAnalyzeReportJobCommon<ReportJobStatus.Completed, ICompletedReportJobPayload> {}

export interface IFailedReportJob extends IAnalyzeReportJobCommon<ReportJobStatus.Failed, IFailedReportJobPayload> {}

// Useful if you have a report job, and want to know what kind of report job it is
interface IUnknownReportJob extends IAnalyzeReportJobCommon<any, any> {}

export const isInProgressReportJob = (job: IUnknownReportJob): job is IInProgressReportJob =>
  job.status === ReportJobStatus.InProgress

export const isCompletedReportJob = (job: IUnknownReportJob): job is ICompletedReportJob =>
  job.status === ReportJobStatus.Completed

export const isFailedReportJob = (job: IUnknownReportJob): job is IFailedReportJob =>
  job.status === ReportJobStatus.Failed
