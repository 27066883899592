import React from 'react'
import { Container, InfoParagraph, TermsSection } from 'src/components'
import { st } from 'src/locales/TranslationHelper'
import styled from 'styled-components'
import { useFormContext } from '../FormContext'

const Title = styled.h1`
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
`
export const SpecialistWelcomeStep = (): JSX.Element => {
  const { onNextStep, form } = useFormContext()

  // TODO: Save the terms acceptance?

  return (
    <Container>
      <Title>Welcome</Title>
      <InfoParagraph>{st.forms.specialist.welcomeMessage()}</InfoParagraph>
      <TermsSection terms={form.organizationTerms} onContinueClick={onNextStep} />
    </Container>
  )
}
