import { AsyncSuccessOrFailureChain } from './AsyncSuccessOrFailureChain'
import { SuccessOrFailure } from './SuccessOrFailure'

/**
 * Use this class for sequences of validation steps, each of which returns
 * a SuccessOrFailure instance. This class enables such code to be written
 * in a much more functional style, reducing the boilerplate of
 * multiple blocks of if(!success) { return failure }
 */
export class SuccessOrFailureChain<InputType, ReasonType> {
  constructor(private readonly input: SuccessOrFailure<InputType, ReasonType>) {}

  public ifSuccess<OutputType>(
    callback: (input: InputType) => SuccessOrFailure<OutputType, ReasonType>
  ): SuccessOrFailureChain<OutputType, ReasonType> {
    return new SuccessOrFailureChain(this.evaluate(callback))
  }

  public ifSuccessAsync<OutputType>(
    callback: (input: InputType) => Promise<SuccessOrFailure<OutputType, ReasonType>>
  ): AsyncSuccessOrFailureChain<OutputType, ReasonType> {
    return new AsyncSuccessOrFailureChain<InputType, ReasonType>(Promise.resolve(this.input)).ifSuccessAsync(callback)
  }

  public result(): SuccessOrFailure<InputType, ReasonType> {
    return this.input
  }

  private evaluate<OutputType>(callback: (input: InputType) => SuccessOrFailure<OutputType, ReasonType>) {
    // short-circuit failures
    return this.input.success ? callback(this.input.value) : this.input
  }
}
