import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IEventListQueryParams, IEventResponse, Resources } from '../types'

export class EventResource extends GenericResource<IEventResponse, IEventListQueryParams, never, never> {
  public get = this.buildGet()
  public list = this.buildList()

  constructor(agent: AxiosInstance) {
    super(Resources.Events, agent)
  }
}
