import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { IPublicFormResponse } from './FormTypes'

export enum CaseTypeStatus {
  Active = 'active',
  Archived = 'archived',
}

export interface ICaseTypePostBody {
  name: string
  key: string
  timerLimitTs?: number | null
  riderInterfaceVisibility?: boolean
  riderInterfaceName?: string | null
}

export interface ICaseTypePatchBody {
  name?: string
  status?: CaseTypeStatus
  timerLimitTs?: number | null
  riderInterfaceVisibility?: boolean
  riderInterfaceName?: string | null
}

export interface ICaseTypeResponse extends IModelJsonValues, ICaseTypePostBody {
  status: CaseTypeStatus
}

export interface IListCaseTypeQueryParams extends IListModelQueryParamValues {
  status?: CaseTypeStatus
  riderInterfaceVisibility?: boolean
}

export interface IGetPublicCaseTypeQueryParams {
  caseTypeKey: string
  organizationId: string
}

export interface IPublicCaseTypeResponse {
  id: string
  name: string
  key: string
  forms: IPublicFormResponse[]
  riderInterfaceVisibility?: boolean
  riderInterfaceName?: string | null
}
