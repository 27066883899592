import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IOAuthAuthorizePostBody, IOAuthAuthorizeResponse, Resources } from '../types'

export class OAuthAuthorizeResource extends GenericResource<
  IOAuthAuthorizeResponse,
  never,
  never,
  IOAuthAuthorizePostBody
> {
  constructor(agent: AxiosInstance) {
    super(Resources.OAuthAuthorize, agent)
  }

  public post = this.buildPost()
}
