import moment from 'moment-timezone'

export class TimeExportHelper {
  constructor(
    private readonly timezone: string,
    private readonly locale: string,
    private readonly dateTimeFormat?: string
  ) {}

  public tsToTime(ts: number): string {
    return moment
      .unix(ts)
      .tz(this.timezone)
      .locale(this.locale)
      .format(this.dateTimeFormat ?? 'LL LT')
  }

  public tsToTimeWithSeconds(ts: number): string {
    return moment
      .unix(ts)
      .tz(this.timezone)
      .locale(this.locale)
      .format(this.dateTimeFormat ?? 'LL LTS')
  }

  public dateToTime(date: Date): string {
    return this.tsToTime(moment(date).unix())
  }

  public dateToTimeWithSeconds(date: Date): string {
    return this.tsToTimeWithSeconds(moment(date).unix())
  }
}
