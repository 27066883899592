import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'
import styled from 'styled-components'

export interface IFileIconProps {
  icon: IconProp
  className?: string
}

export const FileViewStyledIcon = styled(FontAwesomeIcon)`
  width: 24px !important;
  height: 24px;
`

const IconWrapper = styled.div`
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
`

export const FileIcon: FC<IFileIconProps> = ({ icon, className }) => (
  <IconWrapper className={className}>
    <FileViewStyledIcon icon={icon} />
  </IconWrapper>
)
