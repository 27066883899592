import { BadRequestError } from '@sparelabs/error-types'
import { buildPoint, IPoint, IPolygon } from '@sparelabs/geography'
import tzLookup from 'tz-lookup'

export class TimezoneLookup {
  public static getTimezone({ coordinates }: IPoint): string {
    try {
      return tzLookup(coordinates[1], coordinates[0])
    } catch (error) {
      if ((error as Error).message.includes('invalid coordinates')) {
        throw new BadRequestError(
          () => `The provided coordinates (longitude ${coordinates[0]}, latitude ${coordinates[1]}) are invalid`
        )
      }
      throw error
    }
  }

  public static getTimeZoneForArea(area: IPolygon): string {
    return TimezoneLookup.getTimezone(buildPoint(...area.coordinates[0][0]))
  }
}
