import { BaseClient, IApiClientConfiguration } from '@sparelabs/http-client-utils'
import {
  BillingAccountAssignmentResource,
  BillingAccountMetricsResource,
  BillingAccountResource,
  FileResource,
  GeographyResource,
  MobileAppResource,
  OrganizationResource,
  ReportResource,
  SamplePointsResource,
  UploadResource,
  WorkOsResource,
} from '../resources'
import { DataMigrationResource } from '../resources/DataMigrationResource'
import { FeatureEntitlementResource } from '../resources/FeatureEntitlementResource'
import { FeatureFlagResource } from '../resources/FeatureFlagResource'
import { MobileAppReleaseResource } from '../resources/MobileAppReleaseResource'
import { SuperAdminResource } from '../resources/SuperAdminResource'

export class SuperAdminApiClient extends BaseClient {
  public readonly billingAccountAssignments: BillingAccountAssignmentResource
  public readonly billingAccounts: BillingAccountResource
  public readonly billingAccountMetrics: BillingAccountMetricsResource
  public readonly files: FileResource
  public readonly geographies: GeographyResource
  public readonly mobileApps: MobileAppResource
  public readonly mobileAppRelease: MobileAppReleaseResource
  public readonly organizations: OrganizationResource
  public readonly reports: ReportResource
  public readonly superAdmins: SuperAdminResource
  public readonly upload: UploadResource
  public readonly workOs: WorkOsResource
  public readonly samplePoints: SamplePointsResource
  public readonly featureEntitlement: FeatureEntitlementResource
  public readonly featureFlags: FeatureFlagResource
  public readonly dataMigration: DataMigrationResource

  constructor(configuration: Partial<IApiClientConfiguration>) {
    super(configuration)
    this.billingAccountAssignments = new BillingAccountAssignmentResource(this.agent)
    this.billingAccounts = new BillingAccountResource(this.agent)
    this.billingAccountMetrics = new BillingAccountMetricsResource(this.agent)
    this.files = new FileResource(this.agent)
    this.geographies = new GeographyResource(this.agent)
    this.mobileApps = new MobileAppResource(this.agent)
    this.mobileAppRelease = new MobileAppReleaseResource(this.agent)
    this.organizations = new OrganizationResource(this.agent)
    this.reports = new ReportResource(this.agent)
    this.superAdmins = new SuperAdminResource(this.agent)
    this.upload = new UploadResource(this.agent)
    this.workOs = new WorkOsResource(this.agent)
    this.samplePoints = new SamplePointsResource(this.agent)
    this.featureEntitlement = new FeatureEntitlementResource(this.agent)
    this.featureFlags = new FeatureFlagResource(this.agent)
    this.dataMigration = new DataMigrationResource(this.agent)
  }
}
