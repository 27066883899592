import { WebColors } from '@sparelabs/colors'
import styled, { keyframes } from 'styled-components'

const loadSpin = keyframes`
  to {
    transform: rotate(360deg);
  }
`
export const LoaderSpinner = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-color: ${WebColors.contentSecondary} ${WebColors.contentSecondary} ${WebColors.contentSecondary} transparent;
  animation: ${loadSpin} 0.8s linear infinite;
`
LoaderSpinner.displayName = 'LoaderSpinner'
