import { AxiosInstance } from 'axios'
import {
  IGetPublicOrganizationQueryParams,
  IPublicMobileAppResponse,
  IPublicOrganizationResponse,
  ITermsGetQueryParams,
  ITermsResponse,
} from '../types'

export class PublicResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async getTerms(params: ITermsGetQueryParams): Promise<ITermsResponse> {
    const res = await this.agent.get<ITermsResponse>('public/terms', { params })
    return res.data
  }

  /**
   * @Deprecated
   */
  public async getOrganization(params: IGetPublicOrganizationQueryParams): Promise<IPublicOrganizationResponse> {
    const res = await this.agent.get<IPublicOrganizationResponse>('public/organization', { params })
    return res.data
  }

  public async getOrganizationByRouteParam(id: string): Promise<IPublicOrganizationResponse> {
    const res = await this.agent.get<IPublicOrganizationResponse>(`public/organizations/${id}`)
    return res.data
  }

  public async getOrganizationByKey(key: string): Promise<IPublicOrganizationResponse> {
    const res = await this.agent.get<IPublicOrganizationResponse>(`public/organizations/key/${key}`)
    return res.data
  }

  public async getMobileApp(id: string): Promise<IPublicMobileAppResponse> {
    const res = await this.agent.get<IPublicMobileAppResponse>(`public/mobileApps/${id}`)
    return res.data
  }
}
