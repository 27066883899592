import { FileError } from 'react-dropzone'
import { st } from '../../../locales/TranslationHelper'
import { AcceptedFileTypes } from './FileInputTypes'

interface IAcceptedFiles {
  [key: string]: AcceptedFileTypes[]
}

export interface IApiError {
  response?: {
    data: {
      message: string
    }
  }
}

export const createAcceptedFilesObj = (fileTypes: AcceptedFileTypes[]): IAcceptedFiles => {
  // Ensure that we don't have duplicate file extensions
  const uniqueListOfFileTypes = [...new Set(fileTypes)]

  const acceptedFiles = uniqueListOfFileTypes.reduce(
    (acceptedFilesObj: IAcceptedFiles, currentFileType: AcceptedFileTypes) => {
      switch (currentFileType) {
        case '.png':
          acceptedFilesObj['image/png'] = ['.png']
          return acceptedFilesObj
        case '.jpg':
          acceptedFilesObj['image/jpeg'] = ['.jpg', '.jpeg']
          return acceptedFilesObj
        case '.csv':
          acceptedFilesObj['text/csv'] = ['.csv']
          return acceptedFilesObj
        case '.json':
          acceptedFilesObj['application/json'] = ['.json']
          return acceptedFilesObj
        case '.pdf':
          acceptedFilesObj['application/pdf'] = ['.pdf']
          return acceptedFilesObj
        case '.xls':
          acceptedFilesObj['application/vnd.ms-excel'] = ['.xls']
          return acceptedFilesObj
        case '.xlsx':
          acceptedFilesObj['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] = ['.xlsx']
          return acceptedFilesObj
        case '.xml':
          acceptedFilesObj['text/xml'] = ['.xml']
          acceptedFilesObj['application/octet-stream'] = ['.xml']
          acceptedFilesObj['application/xml'] = ['.xml']
          return acceptedFilesObj
        case '.geojson':
          acceptedFilesObj['application/geo+json'] = ['.geojson']
          return acceptedFilesObj
        case '.kml':
          acceptedFilesObj['application/vnd.google-earth.kml+xml'] = ['.kml']
          return acceptedFilesObj
        case '.kmz':
          acceptedFilesObj['application/vnd.google-earth.kmz'] = ['.kmz']
          return acceptedFilesObj
        case '.zip':
          acceptedFilesObj['application/zip'] = ['.zip']
          return acceptedFilesObj
        case '.osc':
          acceptedFilesObj['text/plain'] = ['.osc']
          return acceptedFilesObj
        default:
          return acceptedFilesObj
      }
    },
    {}
  )

  return acceptedFiles
}

export const getErrorMessage = (
  error: FileError,
  acceptedFileTypes: AcceptedFileTypes[],
  maxSizeInBytes: number | undefined
): string => {
  if (error.code === 'file-invalid-type') {
    const fileTypes = acceptedFileTypes.join(', ')
    return acceptedFileTypes.length > 1
      ? st.inputs.dropzone.invalidFileTypeMultipleFormats({ fileTypes })
      : st.inputs.dropzone.invalidFileTypeSingle({ fileTypes })
  } else if (error.code === 'file-too-large') {
    return maxSizeInBytes ? st.inputs.dropzone.invalidFileSize({ fileSize: maxSizeInBytes / 1000000 }) : error.message
  } else if (error.code === 'too-many-files') {
    return st.inputs.dropzone.tooManyFiles()
  }

  return error.message
}

export const isApiError = (error: Error | IApiError): error is IApiError => (error as IApiError)?.response !== undefined

export const getDescription = (acceptedFiles: string[], maxFileSizeBytes: number): string | undefined => {
  if (acceptedFiles.length === 0) {
    return undefined
  }
  const fileTypes = acceptedFiles.join(', ')
  const maxFileSizeMB = Number((maxFileSizeBytes / 1000000).toFixed(2))
  return st.inputs.dropzone.description({ fileTypes, maxFileSizeMB })
}
