import React from 'react'
import { st } from '../../locales/TranslationHelper'
import { IInputProps } from './InputTypes'
import { StyledInput } from './styled/StyledInput'

interface IDateInputProps extends IInputProps<string> {
  value?: string
  minDate?: string
  maxDate?: string
  id?: string
  isRequired?: boolean // Set to false if allow to clear the input
}

export const DateInput = ({
  value,
  onChange,
  minDate,
  maxDate,
  invalid,
  isRequired = true,
  id,
  ...inputAttributes
}: IDateInputProps): JSX.Element => (
  <StyledInput
    {...inputAttributes}
    aria-label={st.inputs.dateTime.selectDateLabel()}
    role='textbox'
    type='date'
    invalid={invalid}
    value={value}
    min={minDate}
    max={maxDate}
    onChange={(event) => {
      if (isRequired && event.target.value) {
        onChange(event.target.value)
      } else if (!isRequired) {
        onChange(event.target.value)
      }
    }}
    id={id}
  />
)
