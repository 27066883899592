export enum SlotType {
  VehicleLocation = 'vehicleLocation',
  StartLocation = 'startLocation',
  Pickup = 'pickup',
  Dropoff = 'dropoff',
  EndLocation = 'endLocation',
  RouteStop = 'routeStop',
  DriverBreak = 'driverBreak',
}

export enum SlotStatus {
  Scheduled = 'scheduled',
  Completed = 'completed',
  Cancelled = 'cancelled',
  InProgress = 'inProgress',
}
