import { AccessibilityFeature, RiderType } from '@sparelabs/domain-logic'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { IGroupConditionResponse } from './GroupConditionTypes'

export enum GroupMembershipStatus {
  Active = 'active',
  Archived = 'archived',
  Expired = 'expired',
  Upcoming = 'upcoming',
  ActiveAndUpcoming = 'activeAndUpcoming', // This status is used for admin to list active and upcoming group memberships only
}

export interface IGroupMembershipPostBody {
  userId: string
  groupId: string
  metadata: Record<string, unknown>
  expiryTs?: number | null
  startTs?: number | null
  approvals?: IApproval[]
  groupConditionIds?: string[]
}

export interface IGroupMembershipPatchBody {
  status?: GroupMembershipStatus.Active | GroupMembershipStatus.Archived
  metadata?: Record<string, unknown>
  expiryTs?: number | null
  startTs?: number | null
  approvals?: IApproval[]
  groupConditionIds?: string[]
}

export interface IGroupMembershipResponse extends IModelJsonValues, IGroupMembershipPostBody {
  group: {
    name: string
    description: string | null
  }
  status: GroupMembershipStatus
  expiryTs: number | null
  startTs: number | null
  approvals: IApproval[]
  groupConditionIds: string[]
  /**
   * This has some overlap with `groupConditionIds`, but exists so that we can include the name of each condition in exports
   */
  conditions: Array<Pick<IGroupConditionResponse, 'id' | 'name'>>
  user: {
    id: string
    firstName: string | null
    lastName: string | null
    email: string | null
    phoneNumber: string | null
  }
}

export interface IGroupMembershipQueryParams extends IListModelQueryParamValues {
  groupId?: string
  userId?: string
  status?: GroupMembershipStatus
}

export enum ApprovalType {
  AccessibilityFeature = 'accessibilityFeature',
  RiderType = 'riderType',
}

export interface IAccessibilityFeatureApproval {
  type: ApprovalType.AccessibilityFeature
  fields: {
    type: AccessibilityFeature
    min: number
    max: number | null
  }
}

export interface IRiderTypeApproval {
  type: ApprovalType.RiderType
  fields: {
    type: RiderType
    min: number
    max: number | null
  }
}

export type IApproval = IAccessibilityFeatureApproval | IRiderTypeApproval
