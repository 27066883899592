import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'

export type TicketExpiryOption = ITicketExpiryDuration | ITicketExpiryCalendar

export interface ITicketExpiryDuration {
  name: string
  description?: string
  type: TicketExpiryType.Duration
  durationSeconds: number
}

export interface ITicketExpiryCalendar {
  name: string
  description?: string
  type: TicketExpiryType.Calendar
  period: TicketCalendarExpiryPeriod
}

export enum TicketExpiryType {
  Duration = 'duration',
  Calendar = 'calendar',
}

export enum TicketCalendarExpiryPeriod {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export interface ITicketOption {
  name: string
  description?: string
}

/**
 * While this does have the same shape as ITicketOption,
 * it is a separate interface to make it clear that it is a different option that is independent of ticket options.
 *
 * Allows us to independently change rider options without affecting ticket options.
 */
export interface ITicketRiderOption {
  name: string
  description?: string
}

export interface ITicketInstancePostBody {
  riderId: string
  paymentMethodId: string | null
  chargeId?: string // for rider purchase flow
  expiryOption: TicketExpiryOption
  riderOption: ITicketRiderOption
  ticketOption: ITicketOption
}

export interface ITicketInstanceResponse extends IModelJsonValues {
  ticketPaymentMethodTypeId: string
  riderId: string
  activatedTs: number | null
  cancelledTs: number | null
  expiryTs: number | null

  cost: number
  expiryOption: TicketExpiryOption
  riderOption: ITicketOption
  ticketOption: ITicketOption
  status: TicketStatus
}

export interface ITicketInstanceListQueryParams extends IListModelQueryParamValues {
  status?: TicketStatus
  riderId?: string
}

export enum TicketStatus {
  Active = 'active',
  Inactive = 'inactive',
  Cancelled = 'cancelled',
  Expired = 'expired',
}
