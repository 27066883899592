/**
 * Describes the relationship between an organization and data that is shared across orgs.
 *
 * - Data first gets shared across orgs when a fleet is shared from a FleetProvider org to other orgs, called
 *   ServiceProvider orgs.
 * - When a Request from a ServiceProvider org gets matched to a duty in a fleet belonging to another org
 *   (the FleetProvider org), this creates more data sharing, for example:
 *    - the FleetProvider org needs access to that Request so that the driver knows who to pick up,
 *      special accessibility requirements they may have, etc.
 *    - the ServiceProvider org wants some insight into the current vehicle location of the duty in the FleetProvider
 *      org, so that the rider can know when their driver is close to arriving
 */
export enum AccessRole {
  FleetProvider = 'fleetProvider',
  ServiceProvider = 'serviceProvider',
}
