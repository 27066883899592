import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IWebhookPatchBody, IWebhookPostBody, IWebhookResponse, Resources } from '../types'

export class WebhookResource extends GenericResource<IWebhookResponse, {}, IWebhookPatchBody, IWebhookPostBody> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  public del = this.buildDel()
  constructor(agent: AxiosInstance) {
    super(Resources.Webhooks, agent)
  }
}
