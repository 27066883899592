import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { Currency } from './CurrencyTypes'

export enum PromoStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Archived = 'archived',
}

export enum PromoAllocationType {
  Promo = 'promo',

  /**
   * @deprecated This is no longer used, and should be removed in the future
   */
  ReferralGive = 'referralGive',

  /**
   * @deprecated This is no longer used, and should be removed in the future
   */
  ReferralGet = 'referralGet',
}

export enum PromoAllocationStatus {
  /* unused, therefore can be used for booking new trips */
  Unused = 'unused',
  /* expired, so cannot be used for new trips */
  Expired = 'expired',
  /* allocated amount has been fully used up */
  Used = 'used',
  /* promo has been archived, any remaining amount on promo cannot be used anymore */
  Archived = 'archived',
}

export enum FareRedemptionStatus {
  Pending = 'pending',
  Success = 'success',
  Cancelled = 'cancelled',
}

export interface IPromoResponse extends IPromoPostBody, IModelJsonValues {
  currency: Currency
}

export interface IPromoListQueryParams extends IListModelQueryParamValues {
  status?: PromoStatus
  codes?: string[]
}

export type IPromoPatchBody = Partial<IPromoPostBody>

export interface IPromoPostBody {
  code: string
  description: string
  expiryTs: number
  maxAmount: number
  limitUsers: number
  status: PromoStatus
  limitRides: number | null
}

export interface IPromoAllocationRedemptionInfo {
  amountRedeemed: number
  usesRedeemed: number
}

export interface IPromoAllocationResponse extends IModelJsonValues, IPromoAllocationRedemptionInfo {
  userId: string
  status: PromoAllocationStatus
  type: PromoAllocationType
  expiryTs: number
  maxAmount: number
  limitRides: number | null
  currency: Currency
  promoId: string | null
  promoCode: string | null
  promoDescription: string | null
}

export interface IPromoAllocationPostBody {
  userId: string
  code: string
}

export interface IPromoAllocationListQueryParams extends IListModelQueryParamValues {
  userId?: string
  promoId?: string
}
