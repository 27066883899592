// Copied from https://ihateregex.io/expr/uuid/
const regexStr = '[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}'
const uuidRegex = new RegExp(regexStr)
const exactUuidRegex = new RegExp(`^${regexStr}$`)
export class Uuid {
  public static containsUuid(text: string): boolean {
    return uuidRegex.test(text)
  }

  public static isExactUuid(text: string): boolean {
    return exactUuidRegex.test(text)
  }

  public static replace(text: string, replacement: string): string {
    // Add the `g` flag on the regexp to match ALL occurrences of UUIDs.
    // TODO: Switch to replaceAll when we upgrade JS versions to remove the need for this
    return text.replace(new RegExp(uuidRegex, 'g'), replacement)
  }
}
