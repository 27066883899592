import { IModelJsonValues, ISharedQueryParams } from '@sparelabs/http-client-utils'

export interface IServiceFleetListQueryParams extends ISharedQueryParams {
  serviceId?: string
  fleetId?: string
  serviceIds?: string[]
  fleetIds?: string[]
}

export interface IServiceFleetPostBody {
  serviceId: string
  fleetId: string
  priority: ServiceFleetPriority
}

export interface IDeprecatedServiceFleetPostBody {
  serviceId: string
  fleetId: string
}

export interface IServiceFleetPatchBody {
  serviceId?: string
  fleetId?: string
  priority?: ServiceFleetPriority
}

export interface IServiceFleetResponse extends IModelJsonValues {
  serviceId: string
  fleetId: string
  priority: ServiceFleetPriority
}

export enum ServiceFleetPriority {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}
