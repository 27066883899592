import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'
import { OrganizationPermissionSet, organizationPermissionSetGrouping } from './OrganizationPermissions'
import { PaymentMethodTypePermissionSet, paymentMethodTypePermissionSetGrouping } from './PaymentMethodTypePermissions'
import { UserOwnPermissionSet, userOwnPermissionSetGrouping } from './UserOwnPermissions'

export enum DriverPermissionSet {
  Driver = 'driver',
}

export const driverPermissionSetGrouping: IPermissionSetGrouping<DriverPermissionSet> = {
  key: PermissionSetGroupingKey.Driver,
  active: {
    [DriverPermissionSet.Driver]: [
      ...organizationPermissionSetGrouping.active[OrganizationPermissionSet.ViewOwn],
      ...organizationPermissionSetGrouping.active[OrganizationPermissionSet.ViewCustomSchemas],
      ...userOwnPermissionSetGrouping.active[UserOwnPermissionSet.User],
      ...userOwnPermissionSetGrouping.active[UserOwnPermissionSet.Driver],
      ...paymentMethodTypePermissionSetGrouping.active[PaymentMethodTypePermissionSet.View],

      /* Used for uploading a profile picture */
      PermissionType.POST_UPLOAD_PHOTO,

      /* UI check permissions that will determine if user is allowed to user driver app */
      PermissionType.CAN_USE_DRIVER_APP,

      /* Permission needed to `start driving unscheduled/pause/resume` matching on own duty */
      PermissionType.WRITE_OWN_DUTY,

      /* Permission needed to see details about the fleet and see parameters set on it */
      PermissionType.GET_ANY_FLEET,

      /* Permission needed to see stops when doing a flagdown operation */
      PermissionType.GET_ANY_STOP,
      PermissionType.LIST_ANY_STOP,

      /* TODO used by some older drivers apps to fetch service information */
      PermissionType.GET_ANY_SERVICE,
      PermissionType.LIST_ANY_SERVICE,

      /* Permission needed to check which services are available when doing a flag down operation */
      PermissionType.GET_ESTIMATE_SERVICES,

      /* Permissions needed for operating the vehicle */
      PermissionType.POST_DOPS_COMPLETE_SLOT,
      PermissionType.POST_DOPS_COMPLETE_DUTY,
      PermissionType.POST_DOPS_ARRIVE_AT_SLOTS,
      PermissionType.GET_OWN_SLOT,
      PermissionType.POST_OWN_DUTY_VL,
      PermissionType.GET_OWN_LAST_DUTY_VL,
      PermissionType.GET_OWN_DUTY,
      PermissionType.LIST_OWN_DUTY,

      /* Permissions needed to see manage own vehicles */
      PermissionType.GET_OWN_VEHICLE,
      PermissionType.WRITE_OWN_VEHICLE,

      /* Permissions needed to see organization vehicles */
      PermissionType.GET_ANY_VEHICLE,
      PermissionType.LIST_ANY_VEHICLE,

      PermissionType.GET_OWN_DRIVER_BREAK,

      /* Permissions needed for Accept Reject functionality */
      PermissionType.POST_DOPS_UPDATE_OFFER,

      /* Permissions needed for flag downs */
      PermissionType.GET_DOPS_FLAGDOWN_ESTIMATE,
      PermissionType.POST_DOPS_FLAGDOWN_REQUEST,
      PermissionType.GET_DOPS_FLAGDOWN_REQUEST,
      PermissionType.GET_DOPS_RIDER,
      PermissionType.POST_DOPS_VOIP_ACCESS_TOKEN,

      /** Permissions needed for driver communications */
      PermissionType.POST_DOPS_COMMS_ACCESS_TOKEN,
      PermissionType.POST_DOPS_DRIVER_RIDER_CALL_EVENT,

      /* Permissions needed to fetch previous requests */
      PermissionType.LIST_DOPS_PAST_REQUESTS,
      /* Permissions needed to see a driver's earnings*/
      PermissionType.GET_DOPS_EARNINGS_SUMMARY,

      /* TODO: Required for Flag Down functionality in Driver App because drivers need to
       * be able to see all fleets associated to a service. This can be removed once
       * we have a proper service selection user interface */
      PermissionType.LIST_ANY_SERVICE_FLEET,

      PermissionType.CAN_OPERATE_ORGANIZATION_VEHICLE,

      /* Permissions needed to cancel request */
      PermissionType.POST_OWN_REQUEST_CANCELLATION_AS_DRIVER,

      /* Permissions for driver accounts to create and manage their reviews */
      PermissionType.GET_OWN_REVIEW,
      PermissionType.POST_OWN_REVIEW,

      /* Permissions for managing the device version / app version for driver app and push notification registration */
      PermissionType.LIST_OWN_DEVICE,
      PermissionType.WRITE_OWN_DEVICE,
      PermissionType.DELETE_OWN_DEVICE,

      /* Permissions for sending requests to the places-service for autocomplete suggestions, place details, and reverse geocoding */
      PermissionType.GET_AUTOCOMPLETE_SUGGESTIONS,
      PermissionType.GET_PLACE_DETAILS,
      PermissionType.GET_REVERSE_GEOCODE,

      /* Permissions required to update request custom fields */
      PermissionType.PATCH_ANY_REQUEST_METADATA,

      /* Permissions required to view payment options for requests associated with an ongoing duty */
      PermissionType.GET_OWN_REQUEST_PAYMENT_OPTIONS,

      /** Allow updating details of a request associated with a duty assigned to this driver */
      PermissionType.PATCH_OWN_REQUEST,

      /* Permissions required to submit a vehicle inspection */
      PermissionType.GET_OWN_VEHICLE_INSPECTION,
      PermissionType.WRITE_OWN_VEHICLE_INSPECTION,
      PermissionType.GET_ANY_VEHICLE_TYPE,
      PermissionType.GET_ANY_VEHICLE_INSPECTION_TEMPLATE,
      PermissionType.GET_ANY_FILE,

      PermissionType.GENERATE_OWN_USER_ORG_TOKENS,
    ],
  },
  unAssignablePermissionSets: [DriverPermissionSet.Driver],
  deprecated: [],
}
