import { WebColors } from '@sparelabs/colors'
import React, { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'
import { LABEL_COLUMN_WIDTH_PX, LABEL_MARGIN_RIGHT_PX } from '../util'
import { ValidationError } from './ViewField'

const StyledFieldset = styled.fieldset`
  display: flex;
  flex-direction: row;
  font-size: 15px;
`

const StyledLegend = styled.legend`
  margin-bottom: 4px;
  float: left;
  line-height: 20px;
  font-size: inherit;
  color: ${WebColors.contentSecondary};
  min-width: ${LABEL_COLUMN_WIDTH_PX}px;
  width: ${LABEL_COLUMN_WIDTH_PX}px;
  margin-right: ${LABEL_MARGIN_RIGHT_PX}px;
`

const InputsContainer = styled.div`
  flex-grow: 1;
  overflow-wrap: break-word;
  min-width: 270px;
`

interface IProps {
  legend: string
  children: ReactNode
  error?: string
  containerStyles?: CSSProperties
}
export const FormFieldset = ({ legend, children, error, containerStyles }: IProps): JSX.Element => (
  <StyledFieldset style={containerStyles}>
    <StyledLegend>{legend}</StyledLegend>
    <InputsContainer>
      {children}
      {error && <ValidationError role='alert'>{error}</ValidationError>}
    </InputsContainer>
  </StyledFieldset>
)
