import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
  Portal,
  Close as RadixClose,
  Content as RadixContent,
  Overlay as RadixOverlay,
  Root,
  Title,
} from '@radix-ui/react-dialog'
import { WebColors } from '@sparelabs/colors'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { ShadowContainer } from '../containers/ShadowContainer'
import { st } from '../locales/TranslationHelper'
import { ButtonFocusTheme, MODAL_Z_INDEX, overlay } from '../util'

const Content = styled(RadixContent)<{ width: string }>`
  background-color: ${WebColors.backgroundSecondary};
  ${({ width }) => `width: ${width};`};
  margin: 0px 24px;
  border-radius: 10px;
  &:focus {
    outline: none;
  }
`

const Overlay = styled(RadixOverlay)`
  background: ${overlay};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  justify-content: center;
  align-items: start;
  overflow-y: auto;
  z-index: ${MODAL_Z_INDEX};
  padding-top: 30px;
  padding-bottom: 30px;
`

const Header = styled(ShadowContainer)`
  display: flex;
  flex-direction: row;
  font-size: 15px;
  font-weight: 400;
  border-bottom: 1px solid ${WebColors.borderPrimary};
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: ${WebColors.backgroundPrimary};
  position: relative;
`

const DialogTitle = styled(Title)`
  margin: 0;
  flex: 1;
  color: ${WebColors.contentPrimary};
  font-size: 22px;
  padding-top: 26px;
  padding-bottom: 28px;
  padding-left: 20px;
  padding-right: 20px;
`

const Close = styled(RadixClose)`
  display: flex;
  padding: 20px;
  flex: 0;
  align-items: center;
  cursor: pointer;
  color: ${WebColors.interactivePrimary};
  background-color: ${WebColors.backgroundPrimary};
  border: none;
  border-radius: 10px;
  // to over-write radix outline
  &:focus {
    outline: none;
  }
  ${ButtonFocusTheme}
  &:focus-visible {
    outline-offset: -5px;
  }
`

const CloseText = styled.div`
  margin-left: 5px;
  font-size: 15px;
  line-height: 15px;
`

const RoundedButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ModalActionContainer = styled(RoundedButtonsWrapper)`
  position: relative;
  flex-wrap: wrap;
  margin: -18px 0px -18px 0px;
  padding: 0px 16px 0px 16px;
  display: flex;
  gap: 8px;
  z-index: 1052;
`

export const ModalBody = styled.div`
  color: ${WebColors.contentPrimary};
  font-size: 15px;
  background-color: ${WebColors.backgroundSecondary};
`

export const ModalFooter = styled.div`
  border-top: 1px solid ${WebColors.borderPrimary};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: ${WebColors.backgroundPrimary};
  display: flex;
`

export interface IModalProps {
  title: string
  isOpen: boolean
  toggle: () => void // The toggle function is actually only used to close the modal
  actions?: ReactNode
  children?: ReactNode
  className?: string
  /**
   * We use this size prop to determine the width of the modal.
   * Please use this instead of manually setting the width as it will ensure consistency. 🙇‍♂️
   *
   * TODO: remove 'xl', which currently is a special case for a specific Engage modal
   */
  size?: 'default' | 'large' | 'xl'
}

const getWidth = ({ size }: Pick<IModalProps, 'size'>): string => {
  if (size === 'xl') {
    return '1275px' // TODO: this is a special case for a specific Engage model and we should remove it and use large instead
  }

  if (size === 'large') {
    return '1180px'
  }

  return '520px'
}

export const Modal = ({ isOpen, toggle, title, actions, children, size = 'default' }: IModalProps): JSX.Element => (
  <Root open={isOpen}>
    <Portal>
      <Overlay>
        <Content
          onEscapeKeyDown={toggle}
          onPointerDownOutside={(event) => {
            const currentTarget = event.currentTarget as HTMLElement
            /**
             * Clicking the scrollbar closes the modal. This is a known issue with Radix's Overlay component:
             * https://github.com/radix-ui/primitives/issues/1280
             *
             * As mentioned in the issue, there is no real clean and good way to determine if a click was on a scrollbar or not,
             * hence this hacky workaround
             */
            if (event.detail.originalEvent.offsetX <= currentTarget.clientWidth) {
              toggle()
            }
          }}
          aria-describedby={undefined}
          width={getWidth({ size })}
        >
          <Header>
            <DialogTitle>{title}</DialogTitle>
            <Close onClick={toggle}>
              <Icon icon='times' />
              <CloseText>{st.modal.close()}</CloseText>
            </Close>
          </Header>
          {actions && <ModalActionContainer>{actions}</ModalActionContainer>}
          {children}
        </Content>
      </Overlay>
    </Portal>
  </Root>
)
