import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IJourneyPatchBody,
  IJourneyPostBody,
  IJourneyResponse,
  IListActiveJourneyResponse,
  IListJourneyQueryParams,
  Resources,
} from '../types'

export class JourneyResource extends GenericResource<
  IJourneyResponse,
  IListJourneyQueryParams,
  IJourneyPatchBody,
  IJourneyPostBody
> {
  public post = this.buildPost()
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()

  public async listActiveJourneys(params: IListJourneyQueryParams): Promise<IListActiveJourneyResponse> {
    const { data } = await this.agent.get<IListActiveJourneyResponse>(`${Resources.Journeys}/active`, { params })
    return data
  }

  constructor(agent: AxiosInstance) {
    super(Resources.Journeys, agent)
  }
}
