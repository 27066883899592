import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IListRiderMetricsQueryParams, IListRiderMetricsResponse, Resources } from '../types'

export class RiderMetricsResource extends GenericResource<
  IListRiderMetricsResponse,
  IListRiderMetricsQueryParams,
  never,
  never
> {
  // the return value of the /metrics/riders endpoint differs from the generic IListResponse
  public fetchMetrics = async (params: IListRiderMetricsQueryParams): Promise<IListRiderMetricsResponse> => {
    const response = await this.agent.get<IListRiderMetricsResponse>(`${this.resource}`, { params })
    return response.data
  }

  constructor(agent: AxiosInstance) {
    super(Resources.RiderMetrics, agent)
  }
}
