import styled from 'styled-components'
import { LoaderSpinner } from './LoaderSpinner'

/**
 * LoaderSpinner used to replace the Icon in a RoundedButton
 */
export const ButtonSpinner = styled(LoaderSpinner)`
  height: 14px;
  width: 14px;
`
