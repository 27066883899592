import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IGlobalUserListQueryParams,
  IGlobalUserPatchBody,
  IGlobalUserPostDataBody,
  IGlobalUserResponse,
  Resources,
} from '../types'

export class GlobalUserResource extends GenericResource<
  IGlobalUserResponse,
  IGlobalUserListQueryParams,
  IGlobalUserPatchBody,
  IGlobalUserPostDataBody
> {
  constructor(agent: AxiosInstance) {
    super(Resources.GlobalUser, agent)
  }

  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()
}
