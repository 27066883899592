export enum PermissionType {
  GET_OWN_REVIEW = 'GET_OWN_REVIEW',
  GET_ANY_REVIEW = 'GET_ANY_REVIEW',
  POST_OWN_REVIEW = 'POST_OWN_REVIEW',
  POST_ANY_REVIEW = 'POST_ANY_REVIEW',

  GET_ANY_ANNOUNCEMENT = 'GET_ANY_ANNOUNCEMENT',
  LIST_ACTIVE_ANNOUNCEMENT = 'LIST_ACTIVE_ANNOUNCEMENT',

  WRITE_ANY_ANNOUNCEMENT = 'WRITE_ANY_ANNOUNCEMENT',

  GET_ANY_PROMO = 'GET_ANY_PROMO',
  LIST_ANY_PROMO = 'LIST_ANY_PROMO',
  WRITE_ANY_PROMO = 'WRITE_ANY_PROMO',

  GET_ANY_PROMO_ALLOCATION = 'GET_ANY_PROMO_ALLOCATION',
  POST_ANY_PROMO_ALLOCATION = 'POST_ANY_PROMO_ALLOCATION',
  GET_OWN_PROMO_ALLOCATION = 'GET_OWN_PROMO_ALLOCATION',
  POST_OWN_PROMO_ALLOCATION = 'POST_OWN_PROMO_ALLOCATION',

  GET_ANY_FARE_PASS = 'GET_ANY_FARE_PASS',
  WRITE_ANY_FARE_PASS = 'WRITE_ANY_FARE_PASS',

  GET_OWN_FARE_PASS_ALLOCATION = 'GET_OWN_FARE_PASS_ALLOCATION',
  GET_ANY_FARE_PASS_ALLOCATION = 'GET_ANY_FARE_PASS_ALLOCATION',
  EXPORT_ANY_FARE_PASS_ALLOCATION = 'EXPORT_ANY_FARE_PASS_ALLOCATION',
  POST_OWN_FARE_PASS_ALLOCATION = 'POST_OWN_FARE_PASS_ALLOCATION',
  POST_ANY_FARE_PASS_ALLOCATION = 'POST_ANY_FARE_PASS_ALLOCATION',
  PATCH_ANY_FARE_PASS_ALLOCATION = 'PATCH_ANY_FARE_PASS_ALLOCATION',
  IMPORT_ANY_FARE_PASS_ALLOCATION = 'IMPORT_ANY_FARE_PASS_ALLOCATION',

  LIST_ANY_FARE_REDEMPTION = 'LIST_ANY_FARE_REDEMPTION',

  GET_ANY_SERVICE = 'GET_ANY_SERVICE',
  LIST_ANY_SERVICE = 'LIST_ANY_SERVICE',
  WRITE_ANY_SERVICE = 'WRITE_ANY_SERVICE',
  DELETE_ANY_SERVICE = 'DELETE_ANY_SERVICE',

  GET_ANY_ROUTE = 'GET_ANY_ROUTE',
  LIST_ANY_ROUTE = 'LIST_ANY_ROUTE',
  WRITE_ANY_ROUTE = 'WRITE_ANY_ROUTE',

  POST_ANY_ROUTE_ASSIGNMENT = 'POST_ANY_ROUTE_ASSIGNMENT',
  DELETE_ANY_ROUTE_ASSIGNMENT = 'DELETE_ANY_ROUTE_ASSIGNMENT',
  LIST_ANY_ROUTE_ASSIGNMENT = 'LIST_ANY_ROUTE_ASSIGNMENT',
  LIST_ANY_ROUTE_ASSIGNMENT_STATE = 'LIST_ANY_ROUTE_ASSIGNMENT_STATE',

  GET_ANY_FLEET = 'GET_ANY_FLEET',
  LIST_ANY_FLEET = 'LIST_ANY_FLEET',
  PATCH_ANY_FLEET = 'PATCH_ANY_FLEET',
  POST_ANY_FLEET = 'POST_ANY_FLEET',

  GET_ANY_FLEET_PROVIDER = 'GET_ANY_FLEET_PROVIDER',
  LIST_ANY_FLEET_ACCESS = 'LIST_ANY_FLEET_ACCESS',
  POST_ANY_FLEET_ACCESS = 'POST_ANY_FLEET_ACCESS',
  DELETE_ANY_FLEET_ACCESS = 'DELETE_ANY_FLEET_ACCESS',

  POST_ANY_SERVICE_FLEET = 'POST_ANY_SERVICE_FLEET',
  DELETE_ANY_SERVICE_FLEET = 'DELETE_ANY_SERVICE_FLEET',
  LIST_ANY_SERVICE_FLEET = 'LIST_ANY_SERVICE_FLEET',
  PATCH_ANY_SERVICE_FLEET = 'PATCH_ANY_SERVICE_FLEET',

  GET_OWN_REQUEST = 'GET_OWN_REQUEST',
  GET_ANY_REQUEST = 'GET_ANY_REQUEST',
  LIST_ANY_REQUEST = 'LIST_ANY_REQUEST',
  POST_OWN_REQUEST = 'POST_OWN_REQUEST',
  POST_ANY_REQUEST = 'POST_ANY_REQUEST',
  PATCH_OWN_REQUEST = 'PATCH_OWN_REQUEST',
  PATCH_ANY_REQUEST = 'PATCH_ANY_REQUEST',
  IMPORT_ANY_REQUEST = 'IMPORT_ANY_REQUEST',
  POST_OWN_REQUEST_FARE_ESTIMATE = 'POST_OWN_REQUEST_FARE_ESTIMATE',
  POST_ANY_REQUEST_FARE_ESTIMATE = 'POST_ANY_REQUEST_FARE_ESTIMATE',
  POST_OWN_REQUEST_CANCELLATION = 'POST_OWN_REQUEST_CANCELLATION',
  POST_OWN_REQUEST_CANCELLATION_AS_DRIVER = 'POST_OWN_REQUEST_CANCELLATION_AS_DRIVER',
  POST_ANY_REQUEST_CANCELLATION = 'POST_ANY_REQUEST_CANCELLATION',
  POST_ANY_REQUEST_NO_SHOW_REVERSAL = 'POST_ANY_REQUEST_NO_SHOW_REVERSAL',
  POST_ANY_REQUEST_EDIT_DROPOFF = 'POST_ANY_REQUEST_EDIT_DROPOFF',
  POST_ANY_REQUEST_UNMATCH = 'POST_ANY_REQUEST_UNMATCH',
  POST_ANY_REQUEST_REVERSE_PICKUP = 'POST_ANY_REQUEST_REVERSE_PICKUP',
  POST_ANY_REQUEST_REVERSE_CANCELLATION = 'POST_ANY_REQUEST_REVERSE_CANCELLATION',
  POST_ANY_REQUEST_REVERSE_DROPOFF = 'POST_ANY_REQUEST_REVERSE_DROPOFF',
  POST_ANY_REQUEST_FORCE_MATCH = 'POST_ANY_REQUEST_FORCE_MATCH',
  GET_OWN_LAST_REQUEST_VL = 'GET_OWN_REQUEST_VL',
  GET_ANY_LAST_REQUEST_VL = 'GET_ANY_REQUEST_VL',
  GET_OWN_REQUEST_VEHICLE = 'GET_OWN_REQUEST_VEHICLE',
  GET_ANY_REQUEST_VEHICLE = 'GET_ANY_REQUEST_VEHICLE',
  POST_ANY_REQUEST_REMATCH = 'POST_ANY_REQUEST_REMATCH',
  GET_OWN_REQUEST_SLOTS = 'GET_OWN_REQUEST_SLOTS',
  GET_ANY_REQUEST_SLOTS = 'GET_ANY_REQUEST_SLOTS',
  GET_ANY_REQUEST_INSIGHTS = 'GET_ANY_REQUEST_INSIGHTS',
  GET_OWN_REQUEST_INSIGHTS = 'GET_OWN_REQUEST_INSIGHTS',
  PATCH_ANY_REQUEST_METADATA = 'PATCH_ANY_REQUEST_METADATA',
  GET_ANY_REQUEST_PAYMENT_OPTIONS = 'GET_ANY_REQUEST_PAYMENT_OPTIONS',
  GET_OWN_REQUEST_PAYMENT_OPTIONS = 'GET_OWN_REQUEST_PAYMENT_OPTIONS',
  GET_ANY_REQUEST_PREDICTIONS = 'GET_ANY_REQUEST_PREDICTIONS',
  GET_OWN_REQUEST_PREDICTIONS = 'GET_OWN_REQUEST_PREDICTIONS',

  CAN_CREATE_OWN_UNMATCHED_REQUEST = 'CAN_CREATE_OWN_UNMATCHED_REQUEST',
  CAN_CREATE_ANY_UNMATCHED_REQUEST = 'CAN_CREATE_ANY_UNMATCHED_REQUEST',

  GET_OWN_TIP = 'GET_OWN_TIP',
  GET_ANY_TIP = 'GET_ANY_TIP',
  POST_OWN_TIP = 'POST_OWN_TIP',
  POST_ANY_TIP = 'POST_ANY_TIP',

  LIST_ANY_TIP_POLICY = 'LIST_ANY_TIP_POLICY',
  GET_ANY_TIP_POLICY = 'GET_ANY_TIP_POLICY',
  WRITE_ANY_TIP_POLICY = 'WRITE_ANY_TIP_POLICY',
  DELETE_ANY_TIP_POLICY = 'DELETE_ANY_TIP_POLICY',

  GET_OWN_VEHICLE = 'GET_OWN_VEHICLE',
  GET_ANY_VEHICLE = 'GET_ANY_VEHICLE',
  LIST_ANY_VEHICLE = 'LIST_ANY_VEHICLE',
  WRITE_OWN_VEHICLE = 'WRITE_OWN_VEHICLE',
  PATCH_ANY_VEHICLE = 'PATCH_ANY_VEHICLE',
  POST_ANY_VEHICLE = 'POST_ANY_VEHICLE',
  IMPORT_ANY_VEHICLE = 'IMPORT_ANY_VEHICLE',

  PREVIEW_ANY_SIMULATION = 'PREVIEW_ANY_SIMULATION',
  POST_ANY_SIMULATION = 'POST_ANY_SIMULATION',

  GET_ANY_SIMULATION_RESULT = 'GET_ANY_SIMULATION_RESULT',
  WRITE_ANY_SIMULATION_RESULT = 'WRITE_ANY_SIMULATION_RESULT',
  DELETE_ANY_SIMULATION_RESULT = 'DELETE_ANY_SIMULATION_RESULT',

  GET_OWN_SLOT = 'GET_OWN_SLOT',
  GET_ANY_SLOT = 'GET_ANY_SLOT',
  LIST_ANY_SLOT = 'LIST_ANY_SLOT',
  COMPLETE_ANY_SLOT = 'COMPLETE_ANY_SLOT',

  GET_ANY_STOP = 'GET_ANY_STOP',
  LIST_ANY_STOP = 'LIST_ANY_STOP',
  POST_ANY_STOP = 'POST_ANY_STOP',
  PATCH_ANY_STOP = 'PATCH_ANY_STOP',
  DELETE_ANY_STOP = 'DELETE_ANY_STOP',
  DELETE_ANY_MULTIPLE_STOPS = 'DELETE_ANY_MULTIPLE_STOPS',

  GET_ANY_ZONE = 'GET_ANY_ZONE',
  LIST_ANY_ZONE = 'LIST_ANY_ZONE',
  POST_ANY_ZONE = 'POST_ANY_ZONE',
  PATCH_ANY_ZONE = 'PATCH_ANY_ZONE',
  DELETE_ANY_ZONE = 'DELETE_ANY_ZONE',
  GET_ANY_USER_BAN = 'GET_ANY_USER_BAN',
  WRITE_ANY_USER_BAN = 'WRITE_ANY_USER_BAN',

  // // NEW USER PERMISSIONS

  GET_OWN_USER = 'GET_OWN_USER',
  PATCH_OWN_USER = 'PATCH_OWN_USER',
  DELETE_OWN_USER = 'DELETE_OWN_USER',

  GET_OWN_RIDER = 'GET_OWN_RIDER',
  // TODO - look if we can change this
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PATCH_OWN_RIDER = 'GET_OWN_RIDER',
  GET_OWN_DRIVER = 'GET_OWN_DRIVER',
  PATCH_OWN_DRIVER = 'PATCH_OWN_DRIVER',
  GET_OWN_ADMIN = 'GET_OWN_ADMIN',
  PATCH_OWN_ADMIN = 'PATCH_OWN_ADMIN',

  GET_ANY_RIDER = 'GET_ANY_RIDER',
  LIST_ANY_RIDER = 'LIST_ANY_RIDER',
  PATCH_ANY_RIDER = 'PATCH_ANY_RIDER',
  POST_ANY_RIDER = 'POST_ANY_RIDER',
  DELETE_ANY_RIDER = 'DELETE_ANY_RIDER',
  IMPORT_ANY_RIDER = 'IMPORT_ANY_RIDER',

  GET_ANY_DRIVER = 'GET_ANY_DRIVER',
  LIST_ANY_DRIVER = 'LIST_ANY_DRIVER',
  PATCH_ANY_DRIVER = 'PATCH_ANY_DRIVER',
  POST_ANY_DRIVER = 'POST_ANY_DRIVER',
  DELETE_ANY_DRIVER = 'DELETE_ANY_DRIVER',
  IMPORT_ANY_DRIVER = 'IMPORT_ANY_DRIVER',

  GET_ANY_ADMIN = 'GET_ANY_ADMIN',
  PATCH_ANY_ADMIN = 'PATCH_ANY_ADMIN',
  POST_ANY_ADMIN = 'POST_ANY_ADMIN',
  DELETE_ANY_ADMIN = 'DELETE_ANY_ADMIN',
  IMPORT_ANY_ADMIN = 'IMPORT_ANY_ADMIN',

  LIST_METRICS = 'LIST_METRICS',
  POST_DOPS_UPDATE_OFFER = 'POST_DOPS_UPDATE_OFFER',
  GET_ANY_DUTY_REQUEST_OFFER = 'GET_ANY_DUTY_REQUEST_OFFER',

  POST_DOPS_COMPLETE_SLOT = 'POST_DOPS_COMPLETE_SLOT',
  POST_DOPS_COMPLETE_DUTY = 'POST_DOPS_COMPLETE_DUTY',
  POST_DOPS_ARRIVE_AT_SLOTS = 'POST_DOPS_ARRIVE_AT_SLOTS',
  GET_DOPS_FLAGDOWN_ESTIMATE = 'GET_DOPS_FLAGDOWN_ESTIMATE',
  POST_DOPS_FLAGDOWN_REQUEST = 'POST_DOPS_FLAGDOWN_REQUEST',
  GET_DOPS_FLAGDOWN_REQUEST = 'GET_DOPS_FLAGDOWN_REQUEST',
  GET_DOPS_RIDER = 'GET_DOPS_RIDER',

  GET_DOPS_EARNINGS_SUMMARY = 'GET_DOPS_EARNINGS_SUMMARY',

  POST_DOPS_VOIP_ACCESS_TOKEN = 'POST_DOPS_VOIP_ACCESS_TOKEN',

  POST_DOPS_DRIVER_RIDER_CALL_EVENT = 'POST_DOPS_DRIVER_RIDER_CALL_EVENT',

  POST_DOPS_COMMS_ACCESS_TOKEN = 'POST_DOPS_COMMS_ACCESS_TOKEN',
  POST_DISPATCHER_COMMS_ACCESS_TOKEN = 'POST_DISPATCHER_COMMS_ACCESS_TOKEN',

  LIST_DOPS_PAST_REQUESTS = 'LIST_DOPS_PAST_REQUESTS',

  POST_UPLOAD_PHOTO = 'POST_UPLOAD_PHOTO',
  POST_ANY_FILE = 'POST_ANY_FILE',
  GET_ANY_FILE = 'GET_ANY_FILE',
  POST_ANY_FILE_SCAN = 'POST_ANY_FILE_SCAN',

  GET_ESTIMATE_SERVICES = 'GET_ESTIMATE_SERVICES',
  GET_ESTIMATE_SERVICES_FROM = 'GET_ESTIMATE_SERVICES_FROM',
  GET_ESTIMATE_SERVICES_TO = 'GET_ESTIMATE_SERVICES_TO',
  GET_SERVICE_AVAILABILITY = 'GET_SERVICE_AVAILABILITY',
  GET_OWN_ESTIMATE_REQUEST = 'GET_OWN_ESTIMATE_REQUEST',
  GET_ANY_ESTIMATE_REQUEST = 'GET_ANY_ESTIMATE_REQUEST',
  GET_ANY_ESTIMATE_REQUEST_WITH_OVERRIDES = 'GET_ANY_ESTIMATE_REQUEST_WITH_OVERRIDES',

  GET_JOURNEY_ESTIMATES = 'GET_JOURNEY_ESTIMATES',
  GET_JOURNEY_OPTIONS = 'GET_JOURNEY_OPTIONS',

  GET_OWN_JOURNEY = 'GET_OWN_JOURNEY',
  WRITE_OWN_JOURNEY = 'WRITE_OWN_JOURNEY',

  GET_ANY_JOURNEY = 'GET_ANY_JOURNEY',
  WRITE_ANY_JOURNEY = 'WRITE_ANY_JOURNEY',

  POST_OWN_STRIPE_TOKEN = 'POST_OWN_STRIPE_TOKEN',
  POST_OWN_STRIPE_SESSION = 'POST_OWN_STRIPE_SESSION',
  POST_ANY_STRIPE_SESSION = 'POST_ANY_STRIPE_SESSION',
  POST_OWN_STRIPE_SETUP_INTENT = 'POST_OWN_STRIPE_SETUP_INTENT',
  POST_ANY_STRIPE_SETUP_INTENT = 'POST_ANY_STRIPE_SETUP_INTENT',
  POST_OWN_NIMOCA_ID = 'POST_OWN_NIMOCA_ID',
  POST_ANY_GOPASS_PAYMENT_METHOD = 'POST_ANY_GOPASS_PAYMENT_METHOD',

  POST_ANY_WALLET = 'POST_ANY_WALLET',
  POST_OWN_WALLET = 'POST_OWN_WALLET',
  IMPORT_ANY_WALLET = 'IMPORT_ANY_WALLET',

  LIST_OWN_WALLET_TRANSACTION = 'LIST_OWN_WALLET_TRANSACTION',
  LIST_ANY_WALLET_TRANSACTION = 'LIST_ANY_WALLET_TRANSACTION',

  WRITE_ANY_WALLET_AUTO_TOP_UP = 'WRITE_ANY_WALLET_AUTO_TOP_UP',
  WRITE_OWN_WALLET_AUTO_TOP_UP = 'WRITE_OWN_WALLET_AUTO_TOP_UP',
  DELETE_ANY_WALLET_AUTO_TOP_UP = 'DELETE_ANY_WALLET_AUTO_TOP_UP',
  DELETE_OWN_WALLET_AUTO_TOP_UP = 'DELETE_OWN_WALLET_AUTO_TOP_UP',

  GET_ANY_PAYMENT_METHOD = 'GET_ANY_PAYMENT_METHOD',
  GET_OWN_PAYMENT_METHOD = 'GET_OWN_PAYMENT_METHOD',
  DELETE_OWN_PAYMENT_METHOD = 'DELETE_OWN_PAYMENT_METHOD',
  DELETE_ANY_PAYMENT_METHOD = 'DELETE_ANY_PAYMENT_METHOD',

  GET_ANY_CHARGE = 'GET_ANY_CHARGE',
  LIST_ANY_CHARGE = 'LIST_ANY_CHARGE',
  GET_OWN_CHARGE = 'GET_OWN_CHARGE',
  POST_ANY_CHARGE_REFUND = 'POST_ANY_CHARGE_REFUND',
  POST_OWN_CHARGE = 'POST_OWN_CHARGE',

  GET_ANY_API_KEY = 'GET_ANY_API_KEY',
  WRITE_ANY_API_KEY = 'WRITE_ANY_API_KEY',
  DELETE_ANY_API_KEY = 'DELETE_ANY_API_KEY',

  GET_OWN_FAVORITE_LOCATION = 'GET_OWN_FAVORITE_LOCATION',
  GET_ANY_FAVORITE_LOCATION = 'GET_ANY_FAVORITE_LOCATION',
  WRITE_OWN_FAVORITE_LOCATION = 'WRITE_OWN_FAVORITE_LOCATION',
  WRITE_ANY_FAVORITE_LOCATION = 'WRITE_ANY_FAVORITE_LOCATION',
  DELETE_OWN_FAVORITE_LOCATION = 'DELETE_OWN_FAVORITE_LOCATION',
  DELETE_ANY_FAVORITE_LOCATION = 'DELETE_ANY_FAVORITE_LOCATION',
  IMPORT_ANY_FAVORITE_LOCATION = 'IMPORT_ANY_FAVORITE_LOCATION',

  GET_ANY_NOTIFICATION_SETTING = 'GET_ANY_NOTIFICATION_SETTING',
  POST_ANY_NOTIFICATION_SETTING_TEST = 'POST_ANY_NOTIFICATION_SETTING_TEST',
  WRITE_ANY_NOTIFICATION_SETTING = 'WRITE_ANY_NOTIFICATION_SETTING',
  DELETE_ANY_NOTIFICATION_SETTING = 'DELETE_ANY_NOTIFICATION_SETTING',

  GET_ANY_BULK_NOTIFICATION = 'GET_ANY_BULK_NOTIFICATION',
  WRITE_ANY_BULK_NOTIFICATION = 'WRITE_ANY_BULK_NOTIFICATION',
  POST_ANY_BULK_NOTIFICATION_SEND = 'POST_ANY_BULK_NOTIFICATION_SEND',

  GET_ANY_BULK_NOTIFICATION_ANALYTICS = 'GET_ANY_BULK_NOTIFICATION_ANALYTICS',

  GET_ANY_GROUP = 'GET_ANY_GROUP',
  LIST_ANY_GROUP = 'LIST_ANY_GROUP',
  WRITE_ANY_GROUP = 'WRITE_ANY_GROUP',
  DELETE_ANY_GROUP = 'DELETE_ANY_GROUP',

  GET_ANY_GROUP_CONDITION = 'GET_ANY_GROUP_CONDITION',
  WRITE_ANY_GROUP_CONDITION = 'WRITE_ANY_GROUP_CONDITION',
  DELETE_ANY_GROUP_CONDITION = 'DELETE_ANY_GROUP_CONDITION',

  GET_ANY_GROUP_MEMBERSHIP = 'GET_ANY_GROUP_MEMBERSHIP',
  POST_ANY_GROUP_MEMBERSHIP = 'POST_ANY_GROUP_MEMBERSHIP',
  PATCH_ANY_GROUP_MEMBERSHIP = 'PATCH_ANY_GROUP_MEMBERSHIP',
  IMPORT_ANY_GROUP_MEMBERSHIP = 'IMPORT_ANY_GROUP_MEMBERSHIP',
  EXPORT_ANY_GROUP_MEMBERSHIP = 'EXPORT_ANY_GROUP_MEMBERSHIP',
  LIST_OWN_GROUP_MEMBERSHIP = 'LIST_OWN_GROUP_MEMBERSHIP',
  GET_OWN_GROUP_MEMBERSHIP = 'GET_OWN_GROUP_MEMBERSHIP',
  POST_OWN_GROUP_MEMBERSHIP = 'POST_OWN_GROUP_MEMBERSHIP',
  PATCH_OWN_GROUP_MEMBERSHIP = 'PATCH_OWN_GROUP_MEMBERSHIP',
  DELETE_OWN_GROUP_MEMBERSHIP = 'DELETE_OWN_GROUP_MEMBERSHIP',

  GET_OWN_ORGANIZATION = 'GET_OWN_ORGANIZATION',
  GET_ANY_ORGANIZATION = 'GET_ANY_ORGANIZATION',
  LIST_ANY_ORGANIZATION = 'LIST_ANY_ORGANIZATION',
  PATCH_OWN_ORGANIZATION = 'PATCH_OWN_ORGANIZATION',
  PATCH_ANY_ORGANIZATION = 'PATCH_ANY_ORGANIZATION',
  POST_ANY_ORGANIZATION = 'POST_ANY_ORGANIZATION',
  DELETE_ANY_ORGANIZATION = 'DELETE_ANY_ORGANIZATION',

  POST_ANY_TEST_ORGANIZATION = 'POST_ANY_TEST_ORGANIZATION',
  PATCH_OWN_TEST_ORGANIZATION = 'PATCH_OWN_TEST_ORGANIZATION',
  DELETE_OWN_TEST_ORGANIZATION = 'DELETE_OWN_TEST_ORGANIZATION',
  PATCH_OWN_TEST_ORGANIZATION_CONFIGURATION = 'PATCH_OWN_TEST_ORGANIZATION_CONFIGURATION',

  POST_ANY_PAYMENT_PROVIDER = 'POST_ANY_PAYMENT_PROVIDER',
  POST_TEST_ORG_PAYMENT_PROVIDER = 'POST_TEST_ORG_PAYMENT_PROVIDER',
  PATCH_ANY_PAYMENT_PROVIDER = 'PATCH_ANY_PAYMENT_PROVIDER',

  GET_GLOBAL_STRIPE_CONFIG = 'GET_GLOBAL_STRIPE_CONFIG',

  PATCH_OWN_ORGANIZATION_NOTIFICATION_PROVIDERS = 'PATCH_OWN_ORGANIZATION_NOTIFICATION_PROVIDERS',
  PATCH_ANY_ORGANIZATION_NOTIFICATION_PROVIDERS = 'PATCH_ANY_ORGANIZATION_NOTIFICATION_PROVIDERS',
  DELETE_ORGANIZATION_RIDERS = 'DELETE_ORGANIZATION_RIDERS',

  GET_OWN_ORGANIZATION_CONFIGURATION = 'GET_OWN_ORGANIZATION_CONFIGURATION',
  GET_ANY_ORGANIZATION_CONFIGURATION = 'GET_ANY_ORGANIZATION_CONFIGURATION',
  PATCH_OWN_ORGANIZATION_CONFIGURATION = 'PATCH_OWN_ORGANIZATION_CONFIGURATION',
  PATCH_ANY_ORGANIZATION_CONFIGURATION = 'PATCH_ANY_ORGANIZATION_CONFIGURATION',

  GET_ANY_CONNECTED_ACCOUNT = 'GET_ANY_CONNECTED_ACCOUNT',
  DELETE_ANY_CONNECTED_ACCOUNT = 'DELETE_ANY_CONNECTED_ACCOUNT',
  GET_OWN_CONNECTED_ACCOUNT = 'GET_OWN_CONNECTED_ACCOUNT',
  DELETE_OWN_CONNECTED_ACCOUNT = 'DELETE_OWN_CONNECTED_ACCOUNT',

  POST_ANY_PAY_COLLECTION_CALL = 'POST_ANY_PAY_COLLECTION_CALL',
  GET_ANY_PAY_COLLECTION_CALL = 'GET_ANY_PAY_COLLECTION_CALL',
  POST_OWN_PAY_COLLECTION_CALL = 'POST_OWN_PAY_COLLECTION_CALL',
  GET_OWN_PAY_COLLECTION_CALL = 'GET_OWN_PAY_COLLECTION_CALL',

  GET_OWN_DUTY = 'GET_OWN_DUTY',
  GET_ANY_DUTY = 'GET_ANY_DUTY',
  LIST_OWN_DUTY = 'LIST_OWN_DUTY',
  LIST_ANY_DUTY = 'LIST_ANY_DUTY',
  PATCH_ANY_DUTY = 'PATCH_ANY_DUTY',
  POST_ANY_DUTY = 'POST_ANY_DUTY',
  POST_ANY_MULTIPLE_DUTIES = 'POST_ANY_MULTIPLE_DUTIES',

  DELETE_ANY_DUTY = 'DELETE_ANY_DUTY',
  DELETE_ANY_MULTIPLE_DUTIES = 'DELETE_ANY_MULTIPLE_DUTIES',

  CANCEL_OWN_DUTY = 'CANCEL_OWN_DUTY',
  CANCEL_ANY_DUTY = 'CANCEL_ANY_DUTY',

  WRITE_OWN_DUTY = 'WRITE_OWN_DUTY',

  IMPORT_ANY_DUTY = 'IMPORT_ANY_DUTY',

  POST_OWN_DUTY_VL = 'POST_OWN_DUTY_VL',
  POST_ANY_DUTY_VL = 'POST_ANY_DUTY_VL',
  GET_OWN_LAST_DUTY_VL = 'GET_OWN_LAST_DUTY_VL',
  GET_ANY_LAST_DUTY_VL = 'GET_ANY_LAST_DUTY_VL',
  GET_ANY_VL = 'GET_ANY_VL',

  POST_ANY_DUTY_AV_STATE = 'POST_ANY_DUTY_AV_STATE',

  CAN_OPERATE_ORGANIZATION_VEHICLE = 'CAN_OPERATE_ORGANIZATION_VEHICLE',

  GENERATE_OWN_USER_ORG_TOKENS = 'GENERATE_OWN_USER_ORG_TOKENS',
  GENERATE_ANY_USER_ORG_TOKENS = 'GENERATE_ANY_USER_ORG_TOKENS',

  JOIN_ANY_ORGANIZATION = 'JOIN_ANY_ORGANIZATION',
  JOIN_OWN_ORGANIZATION = 'JOIN_OWN_ORGANIZATION',

  LIST_ANY_ALERT = 'LIST_ANY_ALERT',
  LIST_OWN_ALERT = 'LIST_OWN_ALERT',
  PATCH_ANY_ALERT = 'PATCH_ANY_ALERT',
  PATCH_OWN_ALERT = 'PATCH_OWN_ALERT',
  POST_ANY_ALERT = 'POST_ANY_ALERT',

  LIST_ANY_ALERT_SUBSCRIPTION = 'LIST_ANY_ALERT_SUBSCRIPTION',
  LIST_OWN_ALERT_SUBSCRIPTION = 'LIST_OWN_ALERT_SUBSCRIPTION',
  POST_ANY_ALERT_SUBSCRIPTION = 'POST_ANY_ALERT_SUBSCRIPTION',
  POST_OWN_ALERT_SUBSCRIPTION = 'POST_OWN_ALERT_SUBSCRIPTION',
  DELETE_ANY_ALERT_SUBSCRIPTION = 'DELETE_ANY_ALERT_SUBSCRIPTION',
  DELETE_OWN_ALERT_SUBSCRIPTION = 'DELETE_OWN_ALERT_SUBSCRIPTION',

  RECEIVE_LATE_DUTIES_ALERT = 'RECEIVE_LATE_DUTIES_ALERT',
  RECEIVE_LATE_DUTY_START_ALERT = 'RECEIVE_LATE_DUTY_START_ALERT',
  RECEIVE_NO_SCHEDULED_DUTIES_ALERT = 'RECEIVE_NO_SCHEDULED_DUTIES_ALERT',
  RECEIVE_NEED_VEHICLE_LOCATION_UPDATE_ALERT = 'RECEIVE_NEED_VEHICLE_LOCATION_UPDATE_ALERT',
  RECEIVE_NEGATIVE_REVIEW_POSTED_ALERT = 'RECEIVE_NEGATIVE_REVIEW_POSTED_ALERT',
  RECEIVE_POSITIVE_COMMENT_REVIEW_POSTED_ALERT = 'RECEIVE_POSITIVE_COMMENT_REVIEW_POSTED_ALERT',
  RECEIVE_NO_SHOW_CANCELLATION_ALERT = 'RECEIVE_NO_SHOW_CANCELLATION_ALERT',
  RECEIVE_SYSTEM_AT_CAPACITY_ALERT = 'RECEIVE_SYSTEM_AT_CAPACITY_ALERT',
  RECEIVE_IDLE_VEHICLE_ALERT = 'RECEIVE_IDLE_VEHICLE_ALERT',
  RECEIVE_STRANDED_REQUESTS_ALERT = 'RECEIVE_STRANDED_REQUESTS_ALERT',
  RECEIVE_SIMULTANEOUS_DRIVER_LOGIN_ALERT = 'RECEIVE_SIMULTANEOUS_DRIVER_LOGIN_ALERT',
  RECEIVE_RECURRING_TRIP_FAILURE_ALERT = 'RECEIVE_RECURRING_TRIP_FAILURE_ALERT',
  RECEIVE_CASE_ASSIGNMENT_ALERT = 'RECEIVE_CASE_ASSIGNMENT_ALERT',
  RECEIVE_NO_DRIVERS_AVAILABLE_ALERT = 'RECEIVE_NO_DRIVERS_AVAILABLE_ALERT',

  GET_ANY_WEBHOOK = 'GET_ANY_WEBHOOK',
  WRITE_ANY_WEBHOOK = 'WRITE_ANY_WEBHOOK',
  DELETE_ANY_WEBHOOK = 'DELETE_ANY_WEBHOOK',

  // Direct data exports
  EXPORT_ANY_PAYMENT_METHOD = 'EXPORT_ANY_PAYMENT_METHOD',
  EXPORT_ANY_PAYMENT_METHOD_TYPE = 'EXPORT_ANY_PAYMENT_METHOD_TYPE',
  EXPORT_ANY_WALLET_TRANSACTION = 'EXPORT_ANY_WALLET_TRANSACTION',
  EXPORT_ANY_RIDER = 'EXPORT_ANY_RIDER',
  EXPORT_ANY_DRIVER = 'EXPORT_ANY_DRIVER',
  EXPORT_ANY_VEHICLE = 'EXPORT_ANY_VEHICLE',
  EXPORT_ANY_PROMO_ALLOCATION = 'EXPORT_ANY_PROMO_ALLOCATION',
  EXPORT_ANY_REQUEST_RECURRENCE = 'EXPORT_ANY_REQUEST_RECURRENCE',

  // Special reports
  EXPORT_ANY_DRIVER_REPORT = 'EXPORT_ANY_DRIVER_REPORT',
  EXPORT_ANY_RIDERSHIP_REPORT = 'EXPORT_ANY_RIDERSHIP_REPORT',
  EXPORT_ANY_NTD_REPORT = 'EXPORT_ANY_NTD_REPORT',
  EXPORT_ANY_ALL_CASES_REPORT = 'EXPORT_ANY_ALL_CASES_REPORT',
  EXPORT_ANY_CASE_ACTIVITY_LOG_REPORT = 'EXPORT_ANY_CASE_ACTIVITY_LOG_REPORT',

  GET_ANY_REPORT_TEMPLATE = 'GET_ANY_REPORT_TEMPLATE',
  DELETE_ANY_REPORT_TEMPLATE = 'DELETE_ANY_REPORT_TEMPLATE',
  WRITE_ANY_REPORT_TEMPLATE = 'WRITE_ANY_REPORT_TEMPLATE',
  EXPORT_ANY_REPORT_TEMPLATE = 'EXPORT_ANY_REPORT_TEMPLATE',

  POST_ANY_ANALYZE_REPORT_JOB = 'POST_ANY_ANALYZE_REPORT_JOB',
  GET_ANY_ANALYZE_REPORT_JOB = 'GET_ANY_ANALYZE_REPORT_JOB',

  CAN_USE_ADMIN_PORTAL = 'CAN_USE_ADMIN_PORTAL',
  CAN_USE_DRIVER_APP = 'CAN_USE_DRIVER_APP',

  LIST_ANY_DEVICE = 'LIST_ANY_DEVICE',
  LIST_OWN_DEVICE = 'LIST_OWN_DEVICE',
  WRITE_OWN_DEVICE = 'WRITE_OWN_DEVICE',
  DELETE_OWN_DEVICE = 'DELETE_OWN_DEVICE',

  LIST_ANY_TIME_RULE = 'LIST_ANY_TIME_RULE',
  GET_ANY_TIME_RULE = 'GET_ANY_TIME_RULE',
  POST_ANY_TIME_RULE = 'POST_ANY_TIME_RULE',
  PATCH_ANY_TIME_RULE = 'PATCH_ANY_TIME_RULE',
  DELETE_ANY_TIME_RULE = 'DELETE_ANY_TIME_RULE',

  GET_ANY_FARE_RULE = 'GET_ANY_FARE_RULE',
  LIST_ANY_FARE_RULE = 'LIST_ANY_FARE_RULE',
  WRITE_ANY_FARE_RULE = 'WRITE_ANY_FARE_RULE',
  DELETE_ANY_FARE_RULE = 'DELETE_ANY_FARE_RULE',

  GET_ANY_REPORT = 'GET_ANY_REPORT',
  PATCH_ANY_REPORT = 'PATCH_ANY_REPORT',
  GET_ANY_REPORT_PREVIEW = 'GET_ANY_REPORT_PREVIEW',

  RECEIVE_DAILY_REPORTS = 'RECEIVE_DAILY_REPORTS',
  RECEIVE_WEEKLY_REPORTS = 'RECEIVE_WEEKLY_REPORTS',

  GET_ANY_REQUEST_RECURRENCE = 'GET_ANY_REQUEST_RECURRENCE',
  WRITE_ANY_REQUEST_RECURRENCE = 'WRITE_ANY_REQUEST_RECURRENCE',
  IMPORT_ANY_REQUEST_RECURRENCE = 'IMPORT_ANY_REQUEST_RECURRENCE',

  GET_ANY_MOBILE_APP = 'GET_ANY_MOBILE_APP',
  LIST_ANY_MOBILE_APP = 'LIST_ANY_MOBILE_APP',
  WRITE_ANY_MOBILE_APP = 'WRITE_ANY_MOBILE_APP',
  DELETE_ANY_MOBILE_APP = 'DELETE_ANY_MOBILE_APP',
  POST_ANY_MOBILE_APP_ORGANIZATION = 'POST_ANY_MOBILE_APP_ORGANIZATION',
  DELETE_ANY_MOBILE_APP_ORGANIZATION = 'DELETE_ANY_MOBILE_APP_ORGANIZATION',
  POST_SPARE_MOBILE_APP_ORGANIZATION = 'POST_SPARE_MOBILE_APP_ORGANIZATION',

  GET_ANY_MOBILE_APP_RELEASE = 'GET_ANY_MOBILE_APP_RELEASE',
  WRITE_ANY_MOBILE_APP_RELEASE = 'WRITE_ANY_MOBILE_APP_RELEASE',
  DELETE_ANY_MOBILE_APP_RELEASE = 'DELETE_ANY_MOBILE_APP_RELEASE',

  POST_ANY_TEST_MOBILE_APP_ORGANIZATION = 'POST_ANY_TEST_MOBILE_APP_ORGANIZATION',
  DELETE_ANY_TEST_MOBILE_APP_ORGANIZATION = 'DELETE_ANY_TEST_MOBILE_APP_ORGANIZATION',

  WRITE_ANY_CUSTOM_FIELD = 'WRITE_ANY_CUSTOM_FIELD',
  LIST_ANY_CUSTOM_FIELD = 'LIST_ANY_CUSTOM_FIELD',
  GET_NATIVE_OBJECT_EXTENSIONS = 'GET_NATIVE_OBJECT_EXTENSIONS',
  POST_ANY_CUSTOM_FIELD_GROUP = 'POST_ANY_CUSTOM_FIELD_GROUP',

  LIST_ANY_CASE_TYPE = 'LIST_ANY_CASE_TYPE',
  GET_ANY_CASE_TYPE = 'GET_ANY_CASE_TYPE',
  WRITE_ANY_CASE_TYPE = 'WRITE_ANY_CASE_TYPE',

  GET_ANY_CASE = 'GET_ANY_CASE',
  LIST_OWN_CASE = 'LIST_OWN_CASE',
  GET_OWN_CASE = 'GET_OWN_CASE',
  WRITE_ANY_CASE = 'WRITE_ANY_CASE',
  POST_OWN_CASE = 'POST_OWN_CASE',
  IMPORT_ANY_CASE = 'IMPORT_ANY_CASE',

  LIST_ANY_CASE_STATUS = 'LIST_ANY_CASE_STATUS',
  WRITE_ANY_CASE_STATUS = 'WRITE_ANY_CASE_STATUS',

  GET_ANY_FORM = 'GET_ANY_FORM',
  WRITE_ANY_FORM = 'WRITE_ANY_FORM',

  LIST_ANY_FORM_CONTENT = 'LIST_ANY_FORM_CONTENT',
  GET_ANY_FORM_CONTENT = 'GET_ANY_FORM_CONTENT',
  WRITE_ANY_FORM_CONTENT = 'WRITE_ANY_FORM_CONTENT',

  GET_ANY_CASE_FORM = 'GET_ANY_CASE_FORM',
  GET_OWN_CASE_FORM = 'GET_OWN_CASE_FORM',
  WRITE_ANY_CASE_FORM = 'WRITE_ANY_CASE_FORM',

  WRITE_OWN_CASE_FORM = 'WRITE_OWN_CASE_FORM',

  GET_ANY_LETTER = 'GET_ANY_LETTER',
  WRITE_ANY_LETTER = 'WRITE_ANY_LETTER',

  GET_ANY_CASE_LETTER = 'GET_ANY_CASE_LETTER',
  WRITE_ANY_CASE_LETTER = 'WRITE_ANY_CASE_LETTER',
  POST_ANY_CASE_LETTER_DOWNLOAD = 'POST_ANY_CASE_LETTER_DOWNLOAD',

  GET_ANY_WORKFLOW = 'GET_ANY_WORKFLOW',
  WRITE_ANY_WORKFLOW = 'WRITE_ANY_WORKFLOW',

  GET_ANY_WORKFLOW_RUN = 'GET_ANY_WORKFLOW_RUN',

  GET_KPI_PREDICTION = 'GET_KPI_PREDICTION',
  GET_SAMPLE_TRIPS = 'GET_SAMPLE_TRIPS',
  POST_ZONE_SUMMARY = 'POST_ZONE_SUMMARY',
  POST_ROI_CALCULATION = 'POST_ROI_CALCULATION',

  GET_ANY_PROJECT = 'GET_ANY_PROJECT',
  WRITE_ANY_PROJECT = 'WRITE_ANY_PROJECT',
  DELETE_ANY_PROJECT = 'DELETE_ANY_PROJECT',

  GET_ANY_REALIZE_SERVICE = 'GET_ANY_REALIZE_SERVICE',
  POST_ANY_REALIZE_SERVICE = 'POST_ANY_REALIZE_SERVICE',
  PATCH_ANY_REALIZE_SERVICE = 'PATCH_ANY_REALIZE_SERVICE',
  DELETE_ANY_REALIZE_SERVICE = 'DELETE_ANY_REALIZE_SERVICE',

  GET_ANY_REALIZE_ZONE = 'GET_ANY_REALIZE_ZONE',
  POST_ANY_REALIZE_ZONE = 'POST_ANY_REALIZE_ZONE',
  PATCH_ANY_REALIZE_ZONE = 'PATCH_ANY_REALIZE_ZONE',
  DELETE_ANY_REALIZE_ZONE = 'DELETE_ANY_REALIZE_ZONE',

  POST_ANY_REALIZE_FIXED_ROUTE_GTFS = 'POST_ANY_REALIZE_FIXED_ROUTE_GTFS',

  GET_ANY_REALIZE_FIXED_ROUTE = 'GET_ANY_REALIZE_FIXED_ROUTE',
  DELETE_ANY_REALIZE_FIXED_ROUTE = 'DELETE_ANY_REALIZE_FIXED_ROUTE',

  GET_ANY_REALIZE_FIXED_ROUTE_STOP = 'GET_ANY_REALIZE_FIXED_ROUTE_STOP',

  GET_ANY_REALIZE_FIXED_ROUTE_STOP_CONNECTION = 'GET_ANY_REALIZE_FIXED_ROUTE_STOP_CONNECTION',

  GET_ANY_REALIZE_FLEET = 'GET_ANY_REALIZE_FLEET',
  WRITE_ANY_REALIZE_FLEET = 'WRITE_ANY_REALIZE_FLEET',
  DELETE_ANY_REALIZE_FLEET = 'DELETE_ANY_REALIZE_FLEET',

  LIST_ANY_REALIZE_SERVICE_FLEET = 'LIST_ANY_REALIZE_SERVICE_FLEET',
  WRITE_ANY_REALIZE_SERVICE_FLEET = 'WRITE_ANY_REALIZE_SERVICE_FLEET',
  DELETE_ANY_REALIZE_SERVICE_FLEET = 'DELETE_ANY_REALIZE_SERVICE_FLEET',

  LIST_ANY_GTFS_FILES = 'LIST_ANY_GTFS_FILES',

  GET_ANY_REALIZE_STOP = 'GET_ANY_REALIZE_STOP',
  POST_ANY_REALIZE_STOP = 'POST_ANY_REALIZE_STOP',
  PATCH_ANY_REALIZE_STOP = 'PATCH_ANY_REALIZE_STOP',
  DELETE_ANY_REALIZE_STOP = 'DELETE_ANY_REALIZE_STOP',

  GET_ANY_DEMAND_SEGMENT = 'GET_ANY_DEMAND_SEGMENT',
  WRITE_ANY_DEMAND_SEGMENT = 'WRITE_ANY_DEMAND_SEGMENT',
  DELETE_ANY_DEMAND_SEGMENT = 'DELETE_ANY_DEMAND_SEGMENT',

  LIST_ANY_HISTORIC_TRIP_DATASET = 'LIST_ANY_HISTORIC_TRIP_DATASET',
  POST_ANY_HISTORIC_TRIP_DATASET = 'POST_ANY_HISTORIC_TRIP_DATASET',
  DELETE_ANY_HISTORIC_TRIP_DATASET = 'DELETE_ANY_HISTORIC_TRIP_DATASET',

  GET_ANY_DATASET = 'GET_ANY_DATASET',
  WRITE_ANY_DATASET = 'WRITE_ANY_DATASET',
  DELETE_ANY_DATASET = 'DELETE_ANY_DATASET',

  LIST_ANY_DATASET_POINTS = 'LIST_ANY_DATASET_POINTS',
  POST_ANY_DATASET_POINTS = 'POST_ANY_DATASET_POINTS',
  LIST_ANY_DATASET_HEATMAP_POINTS = 'LIST_ANY_DATASET_HEATMAP_POINTS',
  POST_ANY_DATASET_HEATMAP_POINTS = 'POST_ANY_DATASET_HEATMAP_POINTS',

  LIST_ANY_DATASET_POLYGON = 'LIST_ANY_DATASET_POLYGON',
  POST_ANY_DATASET_POLYGON = 'POST_ANY_DATASET_POLYGON',

  GET_ANY_DATA_RETENTION_SCHEDULE = 'GET_ANY_DATA_RETENTION_SCHEDULE',
  LIST_ANY_DATA_RETENTION_SCHEDULE = 'LIST_ANY_DATA_RETENTION_SCHEDULE',
  WRITE_ANY_DATA_RETENTION_SCHEDULE = 'WRITE_ANY_DATA_RETENTION_SCHEDULE',
  EXPORT_ANY_DATA_RETENTION_REPORT = 'EXPORT_ANY_DATA_RETENTION_REPORT',

  POST_ANY_EMAIL = 'POST_ANY_EMAIL',
  POST_ANY_SMS = 'POST_ANY_SMS',

  GET_ANY_BILLING_ACCOUNT = 'GET_ANY_BILLING_ACCOUNT',
  LIST_ANY_BILLING_ACCOUNT = 'LIST_ANY_BILLING_ACCOUNT',
  WRITE_ANY_BILLING_ACCOUNT = 'WRITE_ANY_BILLING_ACCOUNT',
  DELETE_ANY_BILLING_ACCOUNT = 'DELETE_ANY_BILLING_ACCOUNT',
  GET_OWN_BILLING_ACCOUNT_ALERT_STATUS = 'GET_OWN_BILLING_ACCOUNT_ALERT_STATUS',

  GET_ANY_BILLING_ACCOUNT_ASSIGNMENT = 'GET_ANY_BILLING_ACCOUNT_ASSIGNMENT',
  POST_ANY_BILLING_ACCOUNT_ASSIGNMENT = 'POST_ANY_BILLING_ACCOUNT_ASSIGNMENT',
  DELETE_ANY_BILLING_ACCOUNT_ASSIGNMENT = 'DELETE_ANY_BILLING_ACCOUNT_ASSIGNMENT',

  GET_SUPER_ADMIN = 'GET_SUPER_ADMIN',
  LIST_SUPER_ADMIN = 'LIST_SUPER_ADMIN',
  WRITE_SUPER_ADMIN = 'WRITE_SUPER_ADMIN',
  DELETE_SUPER_ADMIN = 'DELETE_SUPER_ADMIN',

  GET_ANY_ORGANIZATION_SUPER_ADMIN = 'GET_ANY_ORGANIZATION_SUPER_ADMIN',
  POST_ANY_ORGANIZATION_SUPER_ADMIN = 'POST_ANY_ORGANIZATION_SUPER_ADMIN',
  DELETE_ANY_ORGANIZATION_SUPER_ADMIN = 'DELETE_ANY_ORGANIZATION_SUPER_ADMIN',
  POST_OWN_ORGANIZATION_SUPER_ADMIN = 'POST_OWN_ORGANIZATION_SUPER_ADMIN',
  DELETE_OWN_ORGANIZATION_SUPER_ADMIN = 'DELETE_OWN_ORGANIZATION_SUPER_ADMIN',

  POST_ANY_EXTERNAL_DISPATCH_ESTIMATE = 'POST_ANY_EXTERNAL_DISPATCH_ESTIMATE',
  POST_ANY_EXTERNAL_DISPATCH_RIDE = 'POST_ANY_EXTERNAL_DISPATCH_RIDE',
  // TODO - look if we can change this
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  GET_ANY_EXTERNAL_DISPATCH_RIDE = 'POST_ANY_EXTERNAL_DISPATCH_ESTIMATE',
  POST_ANY_EXTERNAL_DISPATCH_RIDE_CANCEL = 'POST_ANY_EXTERNAL_DISPATCH_RIDE_CANCEL',
  POST_ANY_EXTERNAL_DISPATCH_STATUS = 'POST_ANY_EXTERNAL_DISPATCH_STATUS',

  GET_OPEN_FLEET_ESTIMATE = 'GET_OPEN_FLEET_ESTIMATE',
  POST_OPEN_FLEET_REQUEST = 'POST_OPEN_FLEET_REQUEST',
  POST_OPEN_FLEET_CANCEL_REQUEST = 'POST_OPEN_FLEET_CANCEL_REQUEST',
  GET_OPEN_FLEET_REQUEST = 'GET_OPEN_FLEET_REQUEST',

  GET_AUTOCOMPLETE_SUGGESTIONS = 'GET_AUTOCOMPLETE_SUGGESTIONS',
  GET_PLACE_DETAILS = 'GET_PLACE_DETAILS',
  GET_REVERSE_GEOCODE = 'GET_REVERSE_GEOCODE',
  GET_GEOCODE = 'GET_GEOCODE',

  GET_PUBLIC_APP = 'GET_PUBLIC_APP',
  GET_ANY_APP = 'GET_ANY_APP',
  LIST_ANY_APP = 'LIST_ANY_APP',
  WRITE_ANY_APP = 'WRITE_ANY_APP',
  PATCH_ANY_APP_CLIENT_SECRET = 'PATCH_ANY_APP_CLIENT_SECRET',
  DELETE_ANY_APP = 'DELETE_ANY_APP',

  GET_ANY_APP_WEBHOOK = 'GET_ANY_APP_WEBHOOK',
  WRITE_ANY_APP_WEBHOOK = 'WRITE_ANY_APP_WEBHOOK',
  DELETE_ANY_APP_WEBHOOK = 'DELETE_ANY_APP_WEBHOOK',
  POST_APP_WEBHOOK_SEND = 'POST_APP_WEBHOOK_SEND',

  RECEIVE_PAYMENT_METHOD_CREATED = 'RECEIVE_PAYMENT_METHOD_CREATED',
  RECEIVE_PAYMENT_METHOD_ARCHIVED = 'RECEIVE_PAYMENT_METHOD_ARCHIVED',
  RECEIVE_DUTY_MATCHING_STATUS = 'RECEIVE_DUTY_MATCHING_STATUS',

  GET_ANY_APP_INSTALLATION = 'GET_ANY_APP_INSTALLATION',
  DELETE_ANY_APP_INSTALLATION = 'DELETE_ANY_APP_INSTALLATION',

  GENERATE_WORKOS_ADMIN_PORTAL_LINK = 'GENERATE_WORKOS_ADMIN_PORTAL_LINK',
  TESTING_API_ACCESS = 'TESTING_API_ACCESS',
  ROOT_ADMIN_ONLY = 'ROOT_ADMIN_ONLY',

  POST_OAUTH_AUTHORIZE = 'POST_OAUTH_AUTHORIZE',
  POST_OAUTH_AUTHENTICATE = 'POST_OAUTH_AUTHENTICATE',

  GET_ANY_BREAK_POLICY = 'GET_ANY_BREAK_POLICY',
  POST_ANY_BREAK_POLICY = 'POST_ANY_BREAK_POLICY',
  PATCH_ANY_BREAK_POLICY = 'PATCH_ANY_BREAK_POLICY',
  DELETE_ANY_BREAK_POLICY = 'DELETE_ANY_BREAK_POLICY',

  GET_ANY_RIDER_DRIVER_RESTRICTION = 'GET_ANY_RIDER_DRIVER_RESTRICTION',
  POST_ANY_RIDER_DRIVER_RESTRICTION = 'POST_ANY_RIDER_DRIVER_RESTRICTION',
  DELETE_ANY_RIDER_DRIVER_RESTRICTION = 'DELETE_ANY_RIDER_DRIVER_RESTRICTION',

  GET_ANY_RIDER_VEHICLE_TYPE_RESTRICTION = 'GET_ANY_RIDER_VEHICLE_TYPE_RESTRICTION',
  WRITE_ANY_RIDER_VEHICLE_TYPE_RESTRICTION = 'WRITE_ANY_RIDER_VEHICLE_TYPE_RESTRICTION',
  DELETE_ANY_RIDER_VEHICLE_TYPE_RESTRICTION = 'DELETE_ANY_RIDER_VEHICLE_TYPE_RESTRICTION',

  GET_ANY_VEHICLE_INSPECTION = 'GET_ANY_VEHICLE_INSPECTION',
  GET_OWN_VEHICLE_INSPECTION = 'GET_OWN_VEHICLE_INSPECTION',
  WRITE_ANY_VEHICLE_INSPECTION = 'WRITE_ANY_VEHICLE_INSPECTION',
  WRITE_OWN_VEHICLE_INSPECTION = 'WRITE_OWN_VEHICLE_INSPECTION',

  GET_ANY_VEHICLE_INSPECTION_TEMPLATE = 'GET_ANY_VEHICLE_INSPECTION_TEMPLATE',
  LIST_ANY_VEHICLE_INSPECTION_TEMPLATE = 'LIST_ANY_VEHICLE_INSPECTION_TEMPLATE',
  WRITE_ANY_VEHICLE_INSPECTION_TEMPLATE = 'WRITE_ANY_VEHICLE_INSPECTION_TEMPLATE',

  GET_ANY_VEHICLE_TYPE = 'GET_ANY_VEHICLE_TYPE',
  LIST_ANY_VEHICLE_TYPE = 'LIST_ANY_VEHICLE_TYPE',
  WRITE_ANY_VEHICLE_TYPE = 'WRITE_ANY_VEHICLE_TYPE',
  DELETE_ANY_VEHICLE_TYPE = 'DELETE_ANY_VEHICLE_TYPE',

  GET_ANY_CONSTRAINT_OVERRIDE_ACTION = 'GET_ANY_CONSTRAINT_OVERRIDE_ACTION',

  GET_ANY_REQUEST_CONSTRAINT_OVERRIDE = 'GET_ANY_REQUEST_CONSTRAINT_OVERRIDE',

  GET_OWN_USER_FLEET_AGREEMENT = 'GET_OWN_USER_FLEET_AGREEMENT',
  GET_ANY_USER_FLEET_AGREEMENT = 'GET_ANY_USER_FLEET_AGREEMENT',
  POST_OWN_USER_FLEET_AGREEMENT = 'POST_OWN_USER_FLEET_AGREEMENT',
  POST_ANY_USER_FLEET_AGREEMENT = 'POST_ANY_USER_FLEET_AGREEMENT',
  DELETE_OWN_USER_FLEET_AGREEMENT = 'DELETE_OWN_USER_FLEET_AGREEMENT',
  DELETE_ANY_USER_FLEET_AGREEMENT = 'DELETE_ANY_USER_FLEET_AGREEMENT',
  POST_ANY_USER_FLEET_AGREEMENT_SEND = 'POST_ANY_USER_FLEET_AGREEMENT_SEND',
  DELETE_ALL_USER_FLEET_AGREEMENT = 'DELETE_All_USER_FLEET_AGREEMENT',

  GET_OWN_DRIVER_BREAK = 'GET_OWN_DRIVER_BREAK',
  GET_ANY_DRIVER_BREAK = 'GET_ANY_DRIVER_BREAK',
  CANCEL_ANY_DRIVER_BREAK = 'CANCEL_ANY_DRIVER_BREAK',

  POST_ANTIVIRUS_SCAN = 'POST_ANTIVIRUS_SCAN',
  GET_ANTIVIRUS_VERSION = 'GET_ANTIVIRUS_VERSION',

  GET_ANY_EVENT = 'GET_ANY_EVENT',
  LIST_ANY_EVENT = 'LIST_ANY_EVENT',

  WRITE_ANY_MTI_ORGANIZATION = 'WRITE_ANY_MTI_ORGANIZATION',
  DELETE_ANY_MTI_ORGANIZATION = 'DELETE_ANY_MTI_ORGANIZATION',

  GET_ANY_DAILY_REQUEST_COUNTS = 'GET_ANY_DAILY_REQUEST_COUNTS',

  LIST_ANY_MATCHING_OUTCOMES = 'LIST_ANY_MATCHING_OUTCOMES',

  GET_ANY_OPEN_FLEET_CONFIGURATION = 'GET_ANY_OPEN_FLEET_CONFIGURATION',
  LIST_ANY_OPEN_FLEET_CONFIGURATION = 'LIST_ANY_OPEN_FLEET_CONFIGURATION',
  WRITE_ANY_OPEN_FLEET_CONFIGURATION = 'WRITE_ANY_OPEN_FLEET_CONFIGURATION',

  GET_ANY_OPEN_FLEET_LIMIT = 'GET_ANY_OPEN_FLEET_LIMIT',
  WRITE_ANY_OPEN_FLEET_LIMIT = 'WRITE_ANY_OPEN_FLEET_LIMIT',

  PROXY_OPEN_FLEET_WEBHOOK = 'PROXY_OPEN_FLEET_WEBHOOK',
  POST_OPEN_FLEET_WEBHOOK = 'POST_OPEN_FLEET_WEBHOOK',

  LIST_ANY_OPEN_FLEET_RECEIPT = 'LIST_ANY_OPEN_FLEET_RECEIPT',
  GET_ANY_OPEN_FLEET_RECEIPT = 'GET_ANY_OPEN_FLEET_RECEIPT',

  POST_ANY_MATCHING_SIMULATION = 'POST_ANY_MATCHING_SIMULATION',

  POST_ANY_TRIGGERED_OPTIMIZATION = 'POST_ANY_TRIGGERED_OPTIMIZATION',

  GET_ANY_PAYMENT_METHOD_TYPE = 'GET_ANY_PAYMENT_METHOD_TYPE',
  WRITE_ANY_PAYMENT_METHOD_TYPE = 'WRITE_ANY_PAYMENT_METHOD_TYPE',
  POST_TEST_ORG_PAYMENT_METHOD_TYPE = 'POST_TEST_ORG_PAYMENT_METHOD_TYPE',

  POST_METABASE_STATIC_EMBED = 'POST_METABASE_STATIC_EMBED',

  GET_ANY_SIMCITY_SIMULATION = 'GET_ANY_SIMCITY_SIMULATION',
  POST_ANY_SIMCITY_SIMULATION = 'POST_ANY_SIMCITY_SIMULATION',
  PUT_ANY_SIMCITY_SIMULATION = 'PUT_ANY_SIMCITY_SIMULATION',
  DELETE_ANY_SIMCITY_SIMULATION = 'DELETE_ANY_SIMCITY_SIMULATION',

  POST_ANY_PLEO_CREATE_LEAD_SCHEDULER = 'POST_ANY_PLEO_CREATE_LEAD_SCHEDULER',

  GET_ANY_DUTY_CONVERSATION = 'GET_ANY_DUTY_CONVERSATION',

  GET_ANY_IVR_CALL_METRICS = 'GET_ANY_IVR_CALL_METRICS',

  IMPORT_ANY_FEATURE_FLAG = 'IMPORT_ANY_FEATURE_FLAG',

  LIST_ANY_RIDER_ONBOARDING_FLOW = 'LIST_ANY_RIDER_ONBOARDING_FLOW',
  WRITE_ANY_RIDER_ONBOARDING_FLOW = 'WRITE_ANY_RIDER_ONBOARDING_FLOW',

  GET_ANY_AI_AGENT = 'GET_ANY_AI_AGENT',
  WRITE_ANY_AI_AGENT = 'WRITE_ANY_AI_AGENT',

  AUTH_RIDER_PHONE_PIN = 'AUTH_RIDER_PHONE_PIN',
  GET_RIDER_PHONE_PIN = 'GET_RIDER_PHONE_PIN',
  POST_RIDER_PHONE_PIN = 'POST_RIDER_PHONE_PIN',

  GET_ANY_AUTHORIZATION_GROUP = 'GET_ANY_AUTHORIZATION_GROUP',
  UPDATE_ANY_AUTHORIZATION_GROUP = 'UPDATE_ANY_AUTHORIZATION_GROUP',
  DELETE_ANY_AUTHORIZATION_GROUP = 'DELETE_ANY_AUTHORIZATION_GROUP',

  GET_ANY_AUTHORIZATION_PERMISSION_SET = 'GET_ANY_AUTHORIZATION_PERMISSION_SET',

  GET_SUPER_ADMIN_AUTHORIZATION_GROUP = 'GET_SUPER_ADMIN_AUTHORIZATION_GROUP',
  UPDATE_SUPER_ADMIN_USER_AUTHORIZATION_GROUP = 'UPDATE_SUPER_ADMIN_USER_AUTHORIZATION_GROUP',

  POST_ANY_DATA_MIGRATION = 'POST_ANY_DATA_MIGRATION',

  GET_ANY_CANCELLATION_REASON = 'GET_ANY_CANCELLATION_REASON',
  POST_ANY_CANCELLATION_REASON = 'POST_ANY_CANCELLATION_REASON',
  DELETE_ANY_CANCELLATION_REASON = 'DELETE_ANY_CANCELLATION_REASON',

  GET_ANY_ZONE_AREA = 'GET_ANY_ZONE_AREA',
  LIST_ANY_ZONE_AREA = 'LIST_ANY_ZONE_AREA',
  POST_ANY_ZONE_AREA = 'POST_ANY_ZONE_AREA',
  PATCH_ANY_ZONE_AREA = 'PATCH_ANY_ZONE_AREA',
  DELETE_ANY_ZONE_AREA = 'DELETE_ANY_ZONE_AREA',

  GET_ANY_ZONE_STOP = 'GET_ANY_ZONE_STOP',
  WRITE_ANY_ZONE_STOP = 'WRITE_ANY_ZONE_STOP',
  DELETE_ANY_ZONE_STOP = 'DELETE_ANY_ZONE_STOP',

  GET_OWN_NYLAS_ORGANIZATION_GRANT = 'GET_OWN_NYLAS_ORGANIZATION_GRANT',
  GET_ANY_NYLAS_ORGANIZATION_GRANT = 'GET_ANY_NYLAS_ORGANIZATION_GRANT',
  WRITE_ANY_NYLAS_ORGANIZATION_GRANT = 'WRITE_ANY_NYLAS_ORGANIZATION_GRANT',

  POST_NYLAS_AUTH = 'POST_NYLAS_AUTH',
  POST_NYLAS_CALLBACK = 'POST_NYLAS_CALLBACK',

  GET_OWN_NYLAS_ADMIN_GRANT = 'GET_OWN_NYLAS_ADMIN_GRANT',
  GET_ANY_NYLAS_ADMIN_GRANT = 'GET_ANY_NYLAS_ADMIN_GRANT',
  WRITE_OWN_NYLAS_ADMIN_GRANT = 'WRITE_OWN_NYLAS_ADMIN_GRANT',
  WRITE_ANY_NYLAS_ADMIN_GRANT = 'WRITE_ANY_NYLAS_ADMIN_GRANT',

  GET_ANY_TICKET_INSTANCE = 'GET_ANY_TICKET_INSTANCE',
  GET_OWN_TICKET_INSTANCE = 'GET_OWN_TICKET_INSTANCE',
  WRITE_ANY_TICKET_INSTANCE = 'WRITE_ANY_TICKET_INSTANCE',
  WRITE_OWN_TICKET_INSTANCE = 'WRITE_OWN_TICKET_INSTANCE',
}

// Use this array to store any deprecated PermissionType
// This will be use to propagate the change to SpiceDB
export const DeprecatedPermissionTypes: string[] = [
  'POST_ANY_ANNOUNCEMENT',
  'PATCH_ANY_ANNOUNCEMENT',
  'POST_ANY_PROMO',
  'PATCH_ANY_PROMO',
  'POST_ANY_FARE_PASS',
  'PATCH_ANY_FARE_PASS',
  'POST_ANY_SERVICE',
  'PATCH_ANY_SERVICE',
  'POST_ANY_ROUTE',
  'PATCH_ANY_ROUTE',
  'POST_ANY_TIP_POLICY',
  'PATCH_ANY_TIP_POLICY',
  'PATCH_OWN_VEHICLE',
  'POST_OWN_VEHICLE',
  'PATCH_ANY_USER_BAN',
  'POST_ANY_USER_BAN',
  'POST_OWN_JOURNEY',
  'PATCH_OWN_JOURNEY',
  'POST_ANY_JOURNEY',
  'PATCH_ANY_JOURNEY',
  'POST_ANY_WALLET_AUTO_TOP_UP',
  'PATCH_ANY_WALLET_AUTO_TOP_UP',
  'POST_OWN_WALLET_AUTO_TOP_UP',
  'PATCH_OWN_WALLET_AUTO_TOP_UP',
  'POST_ANY_API_KEY',
  'PATCH_ANY_API_KEY',
  'PATCH_OWN_FAVORITE_LOCATION',
  'POST_OWN_FAVORITE_LOCATION',
  'PATCH_ANY_FAVORITE_LOCATION',
  'POST_ANY_FAVORITE_LOCATION',
  'POST_ANY_NOTIFICATION_SETTING',
  'PATCH_ANY_NOTIFICATION_SETTING',
  'POST_ANY_BULK_NOTIFICATION',
  'PATCH_ANY_BULK_NOTIFICATION',
  'POST_ANY_GROUP',
  'PATCH_ANY_GROUP',
  'POST_ANY_GROUP_CONDITION',
  'PATCH_ANY_GROUP_CONDITION',
  'PATCH_OWN_DUTY',
  'POST_OWN_DUTY',
  'POST_ANY_WEBHOOK',
  'PATCH_ANY_WEBHOOK',
  'POST_ANY_REPORT_TEMPLATE',
  'PATCH_ANY_REPORT_TEMPLATE',
  'POST_OWN_DEVICE',
  'PATCH_OWN_DEVICE',
  'POST_ANY_FARE_RULE',
  'PATCH_ANY_FARE_RULE',
  'POST_ANY_REQUEST_RECURRENCE',
  'PATCH_ANY_REQUEST_RECURRENCE',
  'PATCH_ANY_MOBILE_APP',
  'POST_ANY_MOBILE_APP',
  'PATCH_ANY_MOBILE_APP_RELEASE',
  'POST_ANY_MOBILE_APP_RELEASE',
  'POST_ANY_CUSTOM_FIELD',
  'PATCH_ANY_CUSTOM_FIELD',
  'PATCH_ANY_CASE_TYPE',
  'POST_ANY_CASE_TYPE',
  'PATCH_ANY_CASE',
  'POST_ANY_CASE',
  'PATCH_ANY_CASE_STATUS',
  'POST_ANY_CASE_STATUS',
  'PATCH_ANY_FORM',
  'POST_ANY_FORM',
  'PATCH_ANY_FORM_CONTENT',
  'POST_ANY_FORM_CONTENT',
  'PATCH_ANY_CASE_FORM',
  'POST_ANY_CASE_FORM',
  'PATCH_OWN_CASE_FORM',
  'POST_OWN_CASE_FORM',
  'PATCH_ANY_LETTER',
  'POST_ANY_LETTER',
  'POST_ANY_CASE_LETTER',
  'PATCH_ANY_CASE_LETTER',
  'PATCH_ANY_WORKFLOW',
  'POST_ANY_WORKFLOW',
  'POST_ANY_PROJECT',
  'PATCH_ANY_PROJECT',
  'POST_ANY_REALIZE_FLEET',
  'PATCH_ANY_REALIZE_FLEET',
  'POST_ANY_REALIZE_SERVICE_FLEET',
  'PATCH_ANY_REALIZE_SERVICE_FLEET',
  'POST_ANY_DEMAND_SEGMENT',
  'PATCH_ANY_DEMAND_SEGMENT',
  'POST_ANY_DATASET',
  'PATCH_ANY_DATASET',
  'POST_ANY_DATA_RETENTION_SCHEDULE',
  'PATCH_ANY_DATA_RETENTION_SCHEDULE',
  'POST_ANY_BILLING_ACCOUNT',
  'PATCH_ANY_BILLING_ACCOUNT',
  'POST_SUPER_ADMIN',
  'PATCH_SUPER_ADMIN',
  'POST_ANY_APP',
  'PATCH_ANY_APP',
  'POST_ANY_APP_WEBHOOK',
  'PATCH_ANY_APP_WEBHOOK',
  'POST_ANY_RIDER_VEHICLE_TYPE_RESTRICTION',
  'PATCH_ANY_RIDER_VEHICLE_TYPE_RESTRICTION',
  'POST_ANY_VEHICLE_INSPECTION',
  'PATCH_ANY_VEHICLE_INSPECTION',
  'POST_OWN_VEHICLE_INSPECTION',
  'PATCH_OWN_VEHICLE_INSPECTION',
  'POST_ANY_VEHICLE_INSPECTION_TEMPLATE',
  'PATCH_ANY_VEHICLE_INSPECTION_TEMPLATE',
  'POST_ANY_VEHICLE_TYPE',
  'PATCH_ANY_VEHICLE_TYPE',
  'POST_ANY_MTI_ORGANIZATION',
  'PATCH_ANY_MTI_ORGANIZATION',
  'POST_ANY_OPEN_FLEET_CONFIGURATION',
  'PATCH_ANY_OPEN_FLEET_CONFIGURATION',
  'POST_ANY_OPEN_FLEET_LIMIT',
  'PATCH_ANY_OPEN_FLEET_LIMIT',
  'POST_ANY_PAYMENT_METHOD_TYPE',
  'PATCH_ANY_PAYMENT_METHOD_TYPE',
  'PATCH_ANY_RIDER_ONBOARDING_FLOW',
  'POST_ANY_RIDER_ONBOARDING_FLOW',
  'POST_ANY_AI_AGENT',
  'PATCH_ANY_AI_AGENT',
  'COPY_ANY_NOTIFICATION_SETTING',
  'COPY_ANY_ANNOUNCEMENT',
  'COPY_ANY_PAYMENT_METHOD_TYPE',
  'COPY_ANY_VEHICLE_TYPE',
  'COPY_ANY_DRIVER',
  'COPY_ANY_VEHICLE',
]
