import { IMarkdownVariableDefinition, MarkdownVariableDefinitionType } from '@sparelabs/markdown-utils'
import { ToolbarDropdownIcon, ToolbarIcon } from 'easymde'
import { st } from '../locales/TranslationHelper'

// Interface from EasyMDE to assert that the array is non-empty
interface IArrayOneOrMore<T> extends Array<T> {
  0: T
}

// `text` is a valid property and in EasyMDE docs, but it's not included in the typings
// https://github.com/Ionaru/easy-markdown-editor?tab=readme-ov-file#toolbar-customization
// PR open to fix this: https://github.com/Ionaru/easy-markdown-editor/pull/527
type PatchedToolbarIcon = ToolbarIcon | { text: string }

export class MarkdownVariablePickerBuilder {
  /**
   * Build a variable picker for the markdown editor toolbar.
   * @param variables Should be a non-empty array (although nothing will break if it's not)
   */
  public static build(variableDefinitions: IMarkdownVariableDefinition[]): ToolbarDropdownIcon | ToolbarIcon {
    const children = this.buildEntries(variableDefinitions) as IArrayOneOrMore<PatchedToolbarIcon>

    return {
      name: 'variables',
      className: 'fa fa-caret',
      text: st.inputs.markdown.variables(),
      title: st.inputs.markdown.variablesHint(),
      children,
    } as ToolbarDropdownIcon
  }

  private static buildEntries(variableDefinitions: IMarkdownVariableDefinition[]) {
    const toolbarEntries: PatchedToolbarIcon[] = []

    for (const { key: groupKey, values, groupLabel } of variableDefinitions) {
      if (groupLabel) {
        toolbarEntries.push({
          name: 'group-' + groupKey,
          className: 'group-heading',
          action: () => {},
          title: groupLabel,
          text: groupLabel,
        })
      }
      for (const { key, label, insertAs, linkLabel } of values) {
        toolbarEntries.push({
          name: 'variable-' + key,
          action: (_editor: EasyMDE) => {
            let insertText = `{{${key}}}`

            if (insertAs === MarkdownVariableDefinitionType.Link) {
              insertText = `[${linkLabel || label}](${insertText})`
            } else if (insertAs === MarkdownVariableDefinitionType.Logic) {
              insertText = `{{#if (${key})}}True part{{else}}False part{{/if}}`
            }

            _editor.codemirror.replaceSelection(insertText)
          },
          className: 'no-icon',
          title: label,
          text: label,
        })
      }
    }

    return toolbarEntries
  }
}
