import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum VehicleTypePermissionSet {
  View = 'vehicle_type_view',
  Write = 'vehicle_type_write',
  Delete = 'vehicle_type_delete',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const vehicleTypePermissionSetGrouping: IPermissionSetGrouping<VehicleTypePermissionSet> = {
  key: PermissionSetGroupingKey.VehicleType,
  active: {
    [VehicleTypePermissionSet.View]: [
      PermissionType.GET_ANY_VEHICLE_TYPE,
      PermissionType.LIST_ANY_VEHICLE_TYPE
    ],
    [VehicleTypePermissionSet.Write]: [PermissionType.WRITE_ANY_VEHICLE_TYPE],
    [VehicleTypePermissionSet.Delete]: [PermissionType.DELETE_ANY_VEHICLE_TYPE],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
