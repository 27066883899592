import { AddressFieldName, IAddress, ICountry } from './types'

export const FIELD_REGEXP = /{\w+}/g
export const FIELDS_MAPPING: {
  [key: string]: AddressFieldName
} = {
  '{firstName}': AddressFieldName.FirstName,
  '{lastName}': AddressFieldName.LastName,
  '{country}': AddressFieldName.Country,
  '{city}': AddressFieldName.City,
  '{zip}': AddressFieldName.PostalCode,
  '{province}': AddressFieldName.Zone,
  '{address1}': AddressFieldName.Address1,
  '{address2}': AddressFieldName.Address2,
  '{phone}': AddressFieldName.Phone,
  '{company}': AddressFieldName.Company,
}

export class TemplateHelper {
  /*
   * Returns empty string if all replacement fields are empty.
   */
  public static templateLine(country: ICountry, template: string, address: IAddress): string {
    const fieldKeys = template.match(FIELD_REGEXP)
    if (!fieldKeys) {
      return ''
    }

    let line = template
    let lineIsEmpty = true
    for (const key of fieldKeys) {
      const fieldName = key.replace('{', '').replace('}', '') as AddressFieldName

      if (address[fieldName]) {
        lineIsEmpty = false
      }

      switch (fieldName) {
        case AddressFieldName.Country:
          line = line.replace(`{${AddressFieldName.Country}}`, country.name)
          break
        case AddressFieldName.Zone:
          line = line.replace(
            `{${AddressFieldName.Zone}}`,
            country.zones?.find((zone) => zone.code === address.province)?.name ?? ''
          )
          break
        default:
          line = line.replace(key, address[fieldName] || '')
          break
      }
    }
    if (lineIsEmpty) {
      return ''
    }
    return line.trim().replace('  ', ' ')
  }
}
