import { IAddress } from '@sparelabs/address'
import { CustomFieldTextType, CustomFieldType, ICustomFieldResponse } from '@sparelabs/api-client'
import { isNumber } from '@sparelabs/core'
import { IFileResponse } from '@sparelabs/http-client-utils'
import { DrawingView, FieldValue, FileView } from '@sparelabs/web-ui-components'
import { isString } from 'lodash'
import React from 'react'
import { ApiClientBuilder } from 'src/api/ApiClientBuilders'
import { NoneField } from 'src/components/fields/NoneField'
import { ErrorHandler } from 'src/helpers'
import { isStringArray } from 'src/helpers/DataTypeHelpers'
import { DateTimeHelper } from 'src/helpers/DateTimeHelper'
import { st } from 'src/locales/TranslationHelper'
import styled from 'styled-components'
import { AddressView } from './AddressView'

const MultipleFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

const TextAreaValue = styled.div`
  white-space: pre-line;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 8px;
`

interface ICustomFieldViewProps {
  field: ICustomFieldResponse
  value: unknown
}

export const CustomFieldView = ({ field, value }: ICustomFieldViewProps): JSX.Element | null => {
  if (value === null || value === undefined) {
    return <NoneField />
  }

  switch (field.type) {
    case CustomFieldType.NpsScore:
    case CustomFieldType.Number:
    case CustomFieldType.SingleChoice:
      return <FieldValue>{value}</FieldValue>
    case CustomFieldType.Text:
      if (field.textType === CustomFieldTextType.MultiLine) {
        return <TextAreaValue>{value}</TextAreaValue>
      }
      return <FieldValue>{value}</FieldValue>
    case CustomFieldType.Date:
      if (!isString(value) && !isNumber(value)) {
        return <NoneField />
      }
      return <FieldValue>{DateTimeHelper.formatDate(value, DateTimeHelper.timezone)}</FieldValue>
    case CustomFieldType.Datetime:
      if (!isNumber(value)) {
        return <NoneField />
      }
      return <FieldValue>{DateTimeHelper.formatDateTime(value, DateTimeHelper.timezone)}</FieldValue>
    case CustomFieldType.MultipleChoice:
      if (!isStringArray(value)) {
        return <NoneField />
      }
      return <FieldValue>{value.join(', ')}</FieldValue>
    case CustomFieldType.Toggle:
      if (value === true) {
        return <FieldValue>{st.customFields.toggleButtonYes()}</FieldValue>
      }
      if (value === false) {
        return <FieldValue>{st.customFields.toggleButtonNo()}</FieldValue>
      }
      return <NoneField />
    case CustomFieldType.Address:
      return <AddressView value={value as Partial<IAddress>} />
    case CustomFieldType.File:
      return (
        <FileView
          value={value as IFileResponse}
          tempUrl={(id) => ApiClientBuilder.build().files.tempUrl(id)}
          onError={(error) => ErrorHandler.handleError(error)}
        />
      )
    case CustomFieldType.Files:
      return (
        <MultipleFileContainer>
          {(value as IFileResponse[])?.map((file) => (
            <FileView
              key={file.id}
              value={file}
              tempUrl={(id) => ApiClientBuilder.build().files.tempUrl(id)}
              onError={(error) => ErrorHandler.handleError(error)}
            />
          ))}
        </MultipleFileContainer>
      )
    case CustomFieldType.Drawing:
      return (
        <DrawingView
          value={value as IFileResponse}
          tempUrl={(id) => ApiClientBuilder.build().files.tempUrl(id)}
          onError={(error) => ErrorHandler.handleError(error)}
        />
      )
    // Pass fail is not supported in Engage
    case CustomFieldType.PassFail:
      return null
  }
}
