import { WebColors } from '@sparelabs/colors'
import { css } from 'styled-components'

export const borderRadius = '8px'
export const boxShadow =
  '0px 2px 8px rgba(27, 0, 82, 0.06), 0px 2px 4px rgba(27, 0, 82, 0.06), 0px 1px 2px rgba(27, 0, 82, 0.06), 0px 1px 1px rgba(27, 0, 82, 0.06), 0px 0px 0.5px 0.5px rgba(27, 0, 82, 0.06)'
export const boxShadowStrong =
  '0px 4px 16px rgba(27, 0, 82, 0.06), 0px 2px 4px rgba(27, 0, 82, 0.06), 0px 2px 4px rgba(27, 0, 82, 0.06), 0px 2px 2px rgba(27, 0, 82, 0.06), 0px 1px 2px rgba(27, 0, 82, 0.06), 0px 1px 1px rgba(27, 0, 82, 0.06), 0px 0px 0.5px rgba(27, 0, 82, 0.06)'
export const boxShadowStronger =
  '0px 0px 0.5px 0.5px #1B005214, 0px 1px 1px 0px #1B005214, 0px 1px 2px 0px #1B005214, 0px 2px 4px 0px #1B005214, 0px 2px 8px 0px #1B00521F, 0px 4px 24px 0px #1B005229, 0px 4px 64px 0px #1B00522E, 0px 8px 96px 0px #1B00523D'
export const INPUT_HEIGHT = '36px'
export const INPUT_MIN_WIDTH_PX = 150
export const INPUT_MAX_WIDTH_PX = 500
export const INPUT_BREAKPOINT_WIDTH_PX = 350
export const INPUT_MIN_WIDTH: string = `${INPUT_MIN_WIDTH_PX}px`
export const INPUT_MAX_WIDTH: string = `${INPUT_MAX_WIDTH_PX}px`

export const shadowLow = '0px 0px 4px rgba(0, 0, 0, 0.2)'
export const shadowDefault = '0px 0px 8px rgba(0, 0, 0, 0.1);'
export const shadowHigh = '0px 4px 16px rgba(0, 0, 0, 0.1)'

export const overlay = 'rgba(0, 0, 0, 0.5)'

export const LABEL_COLUMN_WIDTH_PX = 230
export const LABEL_MARGIN_RIGHT_PX = 12
export const CONTENT_WIDTH_PX = INPUT_MAX_WIDTH_PX + LABEL_COLUMN_WIDTH_PX + LABEL_MARGIN_RIGHT_PX

export const MODAL_Z_INDEX = 1050
export const POPOVER_Z_INDEX = 1051

export const FOCUS_WIDTH = '1.5px'

export const InputFocusTheme = css`
  outline-width: ${FOCUS_WIDTH} !important;
  outline-style: solid !important;
  outline-color: ${WebColors.interactivePrimary} !important;
`

export const ButtonFocusTheme = css`
  &:focus-visible {
    outline-color: ${WebColors.focusOutline} !important;
    outline-style: solid !important;
    outline-width: ${FOCUS_WIDTH} !important;
  }
`

/**
 * based on common breakpoints
 * https://www.freec2odecamp.org/news/css-media-queries-breakpoints-media-types-standard-resolutions-and-more/
 */

export const breakpoints = {
  xs: '320px',
  sm: '480px',
  md: '768px',
  lg: '1024px',
  xl: '1200px',
}
