import { AllReportsModels } from './AnalyzeReportModelTypes'

export enum DataRetentionReportColumnTypes {
  id = 'id',
  target = 'target',
  triggerDate = 'triggerDate',
  retainUntilDate = 'retainUntilDate',
  identifier = 'identifier',
}

export const AllDataRetentionModelsNamesArray = [
  AllReportsModels.Rider,
  AllReportsModels.Case,
  AllReportsModels.Driver,
  AllReportsModels.GroupMembership,
  AllReportsModels.Vehicle,
  AllReportsModels.Service,
  AllReportsModels.Request,
  AllReportsModels.Duty,
]

export const DataRetentionReportModelAndColumns = {
  [AllReportsModels.Rider]: DataRetentionReportColumnTypes,
  [AllReportsModels.Case]: DataRetentionReportColumnTypes,
  [AllReportsModels.Driver]: DataRetentionReportColumnTypes,
  [AllReportsModels.GroupMembership]: DataRetentionReportColumnTypes,
  [AllReportsModels.Vehicle]: DataRetentionReportColumnTypes,
  [AllReportsModels.Service]: DataRetentionReportColumnTypes,
  [AllReportsModels.Request]: DataRetentionReportColumnTypes,
  [AllReportsModels.Duty]: DataRetentionReportColumnTypes,
}

export const DataRetentionReportColumnNames =
  Object.values<DataRetentionReportColumnTypes>(DataRetentionReportColumnTypes)
