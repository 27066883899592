import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IDataMigrationModelsResponse,
  IDataMigrationPostBody,
  IDataMigrationResultResponse,
  IEngageDataMigrationModelsResponse,
  IEngageDataMigrationPostBody,
  Resources,
} from '../types'

export class DataMigrationResource extends GenericResource<never, never, never, never> {
  constructor(agent: AxiosInstance) {
    super(Resources.DataMigration, agent)
  }

  public async copyLaunchModels(params: IDataMigrationPostBody): Promise<IDataMigrationResultResponse[]> {
    const res = await this.agent.post<IDataMigrationResultResponse[]>(`${this.resource}`, params)
    return res.data
  }

  public async getLaunchModels(params: { organizationId: string }): Promise<IDataMigrationModelsResponse> {
    const res = await this.agent.get<IDataMigrationModelsResponse>(`${this.resource}`, { params })
    return res.data
  }

  public async copyEngageModels(params: IEngageDataMigrationPostBody): Promise<IDataMigrationResultResponse[]> {
    const res = await this.agent.post<IDataMigrationResultResponse[]>(`engage/${this.resource}`, params)
    return res.data
  }

  public async getEngageModels(params: { organizationId: string }): Promise<IEngageDataMigrationModelsResponse> {
    const res = await this.agent.get<IEngageDataMigrationModelsResponse>(`engage/${this.resource}`, { params })
    return res.data
  }
}
