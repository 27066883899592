export enum Interval {
  Minute = 'minutes',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

/**
 * Uses definitions from moment.js
 * See https://momentjs.com/docs/#/get-set/day/
 */
export enum NumberedDayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum DayOfWeek {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export interface IIsoWeekdayToDayOfWeek {
  [isoWeekday: number]: DayOfWeek
}

export const IsoWeekdayMap: IIsoWeekdayToDayOfWeek = {
  1: DayOfWeek.Monday,
  2: DayOfWeek.Tuesday,
  3: DayOfWeek.Wednesday,
  4: DayOfWeek.Thursday,
  5: DayOfWeek.Friday,
  6: DayOfWeek.Saturday,
  7: DayOfWeek.Sunday,
}
