import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IPromoListQueryParams, IPromoPatchBody, IPromoPostBody, IPromoResponse, Resources } from '../types'

export class PromoResource extends GenericResource<
  IPromoResponse,
  IPromoListQueryParams,
  IPromoPatchBody,
  IPromoPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.Promos, agent)
  }
}
