import {
  IFareRedemptionStub,
  IRequestAccessibilityFeature,
  IRequestRiderType,
  RequestIntentType,
} from '@sparelabs/domain-logic'
import { IPoint } from '@sparelabs/geography'
import { CreatedInterface } from './EstimateTypes'
import { IPrice } from './SharedTypes'

export interface IJourneyOptionsQueryParams {
  createdInterface: CreatedInterface
  ts: number
  arriveBy: boolean
  startLongitude: number
  startLatitude: number
  endLongitude: number
  endLatitude: number
  legModes?: JourneyOptionLegMode[]
  riderId?: string
  accessibilityFeatures: string[]
  numRiders?: number
  riders?: string[]
  paymentMethodTypeId?: string
  overwriteOrganizationId?: string
}

export enum JourneyOptionLegMode {
  OnDemand = 'onDemand',
  Bus = 'bus',
  Train = 'train',
  Walk = 'walk',
  Tram = 'tram',
}

export interface IJourneyOptionsResponse {
  data: IJourneyOption[]
}

export interface IJourneyOption {
  /* Meta data */
  riderId?: string
  createdInterface: CreatedInterface

  /* Geographical data */
  requestedStartAddress?: string
  requestedStartLocationName?: string
  requestedStartLocation: IPoint
  requestedEndAddress?: string
  requestedEndLocationName?: string
  requestedEndLocation: IPoint

  /* Temporal data */
  intentType: RequestIntentType
  scheduledStartTs: number
  scheduledMinStartTs?: number
  scheduledMaxStartTs?: number
  scheduledEndTs: number
  scheduledMinEndTs?: number
  scheduledMaxEndTs?: number

  /* Fare data */
  fare?: IPrice // null unless there is an on-demand leg
  fareRedemptions: IFareRedemptionStub[]

  /* Child data */
  riders: IRequestRiderType[]
  accessibilityFeatures: IRequestAccessibilityFeature[]
  legs: IJourneyOptionLeg[]
}

export interface IJourneyOptionLeg {
  /* Agency data */
  agencyOtpId?: string
  agencyGtfsId?: string

  /* Route data */
  routeOtpId?: string
  routeGtfsId?: string

  routeLongName?: string
  routeShortName?: string
  routeColor?: string
  routeTextColor?: string

  mode: JourneyOptionLegMode
  interlineWithPreviousLeg?: boolean

  /* Trip data */
  tripOtpId?: string
  tripGtfsId?: string
  headsign?: string

  /* Geographical data */
  travelDistanceM: number
  polyline?: string
  pickupAddress?: string
  pickupLocation?: IPoint
  dropoffAddress?: string
  dropoffLocation?: IPoint

  /* Temporal data */
  duration: number
  startTs: number
  startMinTs?: number
  startMaxTs?: number
  endTs: number
  endMinTs?: number
  endMaxTs?: number

  /* Child data */
  nextDepartures: IJourneyOptionLegNextDeparture[]
  steps: IJourneyOptionStep[]
}

export interface IJourneyOptionLegNextDeparture {
  ts: number
  frequencyInSec?: number
  isLast?: boolean
}

export interface IJourneyOptionStep {
  name: string
  location: IPoint
}
