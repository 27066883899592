import { FeatureFlag } from './types/FeatureFlag'

export const FeatureFlagHints: Record<FeatureFlag, string> = {
  [FeatureFlag.Promos]:
    'Enables Promos in Launch and Rider. Without it, the Promos menu item is hidden in both products, and no promo codes can be created or used.',
  [FeatureFlag.DisableDriverAppFlagDown]:
    'Removes the ability for drivers to accept "flagdown" riders from the Driver App.',
  [FeatureFlag.AllowMatchingConfiguration]:
    'Allows users to see the fleet matching configuration sliders on the fleets page - this is advanced functionality. Default to off.',
  [FeatureFlag.FarePasses]:
    'Enables the FarePasses tab under Spare Pay in the admin panel. This allows organizations to create and sell Fare Passes in the rider application.',
  [FeatureFlag.VoiceNotifications]: 'Enables the ability for rider notifications to be sent as a robocall',
  [FeatureFlag.UseDriverAppBearing]:
    'Use the bearing sent from the driver app. If turned off, interpolate bearing based on last 2 locations (Calculations made in the API). Default to Off.',
  [FeatureFlag.DriverInAppCalling]: 'Allows Drivers to Call Riders with a button in the Driver Application',
  [FeatureFlag.DemandForecasting]: 'DEPRECATED - Do not use. Shows demand forecasting based on inaccurate data',
  [FeatureFlag.DisableNavigationIos]: 'Disables map navigation for iOS devices',
  [FeatureFlag.DisableNavigationAndroid]: 'Disables map navigation Android devices',
  [FeatureFlag.DisplayDriveUntilMessaging]:
    'If turned on, the copy under "New Ride Request" mentions the time they\'ll drive until. If turned off, the copy will be what was posted above.',
  [FeatureFlag.EngageAIScan]:
    'Adds the ability for Written Documents to be scanned into Engage using AI based Data Extraction',
  [FeatureFlag.DisableRiderOnboarding]:
    'Prevents this series of onboarding screens from being automatically shown when booking a ride for the first time',
  [FeatureFlag.FareRules]:
    'Enables the Fare Rules tab under Spare Pay in the admin panel. This allows organizations to create custom Fare Rules',
  [FeatureFlag.TipPolicies]:
    'Enables the "tipping policy" tab of Spare Pay. Allows service planners to specify a variety of fixed tip options. Mainly relevant to ride-hailing orgs.',
  [FeatureFlag.Routes]:
    'Enables the "Routes" tab under service planning. Routes lets customers create fixed route services. ',
  [FeatureFlag.ShowAllSlotsToDriver]: 'Allows the drivers to see their whole duty manifest from within the driver app',
  [FeatureFlag.Multimodal]:
    'Enables the Multi Modal Functionality in the Rider App. The Multi Modal functionality requires GTFS feeds to work',
  [FeatureFlag.ExcludeWalkingTimeInDesiredTs]: 'DEPRECATED - Do not use.',
  [FeatureFlag.RequestInsights]:
    'With this enabled, pooled riders can see the Co2 savings of their pooled ride (compared to taking a private vehicle) in the rider app.',
  [FeatureFlag.AllowBookingWithoutPaymentMethod]:
    'Allows rides to be booked with a promo code as a payment method. This was introduced for LaWaWa. Default to off.',
  [FeatureFlag.DriverTripHistory]:
    'Enables the Past Trips menu in the driver app, where drivers can see some information about all the requests matched to them in the past',
  [FeatureFlag.DisableEditingAddingVehicle]:
    'Disables the ability for A/R Fleet Drivers to Add/Change their vehicle when starting an unscheduled duty in the driver app',
  [FeatureFlag.EngineV2GlobalOptimization]:
    'Can we automatically optimize driver routes, moving requests between drivers? Must also enable EngineV2 when enabling EngineV2GlobalOptimization',
  [FeatureFlag.OpenFleet]:
    'Enable a new external fleet type which can communicate with external open fleets like Uzurv, Lyft, Uber, etc.',
  [FeatureFlag.StripeCustomerDetails]:
    'Includes additional customer details including name, email and phone number in Stripe customer objects. Default to "No". ',
  [FeatureFlag.MergeZonesOnLiveMap]: 'Merges the all zones into one on the live map',
  [FeatureFlag.MergeZonesOnServicesPage]: 'Merges the all zones into one on the services page',
  [FeatureFlag.MergeZonesOnDutyTimeTravel]: 'Merges the all zones into one on duty time travel',
  [FeatureFlag.ConstraintOverrides]:
    'Enable new estimate endpoints to "force a trip in": override other peoples constraints in an effort to fit in an important ride',
  [FeatureFlag.LiveGlobalOptimization]:
    'Run global optimization on live duties. Requires EngineV2 and EngineV2GlobalOptimization',
  [FeatureFlag.LyftPromotion]:
    'This will display a banner that allows admins to install Lyft in their organization and will grant them free rides through Lyft.',
  [FeatureFlag.MultimodalTransitHubs]: 'Enable labelling stops as transit hubs for multimodal to route to and from.',
  [FeatureFlag.DriverBreaks]:
    'Automated version of driver breaks, where the engine will optimize break times for greater efficiency.',
  [FeatureFlag.AutoCompleteOnlyWhenAtEndLocation]:
    'Only autocomplete duties once they are within 300m of their end location. If this feature flag is turned off then any duty with all slots completed will end as soon as the requested duty end time is reached.',
  [FeatureFlag.LyftPassLink]:
    'Separate feature for university programs where we simply link out to lyft and do not handle the trip booking internally',
  [FeatureFlag.ReportTemplate]:
    'Create custom report templates in Analyze. Warning: Enabling this also changes the order of columns in exported reports and may break customer workflows that rely on the old column order.',
  [FeatureFlag.LockToDuty]: 'Allow operators to lock a request to a certain duty on creation or rematching',
  [FeatureFlag.InAppTesting]:
    'Shows the "In App Testing" nav link under the super admin menu with the deep link to log into the Rider App with the current organization',
  [FeatureFlag.EtaChangedNotifications]:
    'Enables the Delayed and Early pickup notifications in the Rider Notification Menu',
  [FeatureFlag.CapMetroCustomRoles]:
    'Exclusive feature for the CapMetro organization. Set of requested additional roles.',
  [FeatureFlag.CustomRoles]: 'Enables Custom Administrator User Roles',
  [FeatureFlag.CompleteActivityLog]:
    'Allows viewing all past events in the activity log. With this disabled, only events that were published within the last 14 days are visible.',
  [FeatureFlag.FixedRouteTravelDuration]:
    'Finds the equivalent fixed route travel duration of a Spare trip and adds it to a column in the ridership report, calculated using a third-party API',
  [FeatureFlag.DriverRiderReviews]: 'Enables driver and rider reviews within the apps.',
  [FeatureFlag.Announcements]: 'Enables Service Announcements.',
  [FeatureFlag.OrganizationPrivacyConfigurations]: 'Enable Organization Privacy Configurations.',
  [FeatureFlag.SMSRiderNotifications]: 'Enables SMS Rider Notifications.',
  [FeatureFlag.WalkingDirections]: 'Enables Realistic walking directions.',
  [FeatureFlag.RecurringTrips]: 'Enables Recurring Trips.',
  [FeatureFlag.OptimizationInsights]:
    'EXPERIMENTAL: Adds a new Optimization Insights page to Service Planning that allows running simulations to determine the optimal service configuration.',
  [FeatureFlag.PaymentMethodTypePurchaseLink]: 'Enables purchase buttons per payment method type',
  [FeatureFlag.SmsEta]: "Enables org riders to query for their next ride's ETA via SMS.",
  [FeatureFlag.SmsCancelRide]: 'Enables org riders to cancel their next ride via SMS.',
  [FeatureFlag.PhoneIvrEta]: "Enables org riders to query for their next ride's ETA via automated phone call.",
  [FeatureFlag.PhoneIvrBookRide]: 'Enables riders to book their next trip via automated phone call.',
  [FeatureFlag.PhoneIvrCancelRide]: 'Enables riders to cancel their upcoming trip via automated phone call.',
  [FeatureFlag.ManuallyTriggeredOptimization]:
    'Allow manually triggering a run of Global Optimization in the admin UI (from the Duty Schedule view). Currently not intended for real customers, just for internal testing and niche use cases.',
  [FeatureFlag.BulkMessages]: 'Enables sending Bulk Messages. Requires a Twilio short code.',
  [FeatureFlag.FixedRouteAlternativesInLaunch]: 'Enables displaying of fixed route options on the estimate page.',
  [FeatureFlag.LanguageAndChannelPreferences]:
    'Enables riders to select preferred language and channel for notifications.',
  [FeatureFlag.ReduceEngineBinLocking]:
    'When optimizing, the engine will lock less of the upcoming stops to the current duty. This gives the engine more opportunity for optimization, but can result in weird changes to a drivers upcoming itinerary at the last minute. User beware',
  [FeatureFlag.CapMetroGroupExpiryNotifications]:
    "Exclusive feature for the CapMetro organization. Set of group expiry notification to riders from 'MetroAccess' group who are due to expire in 30, 60, 90 days.",
  [FeatureFlag.RenameAccessibilityFeatures]:
    'Renames some accessibility features (to be deprecated by a more advanced feature)',
  [FeatureFlag.MoreFrequentOptimization]:
    'Run optimization more frequently. GO once per hour instead of once per day, GOLD every 5 minutes instead of every hour.',
  [FeatureFlag.CallForVerificationCode]: 'Enables org riders to receive verification code with a phone call',
  [FeatureFlag.ShowDefaultRequestPreferences]:
    'When turned on, riders can view and edit their default request preferences',
  [FeatureFlag.IvrCallRecording]: 'Enables call recording for IVR calls',
  [FeatureFlag.LiveTraffic]:
    'This feature flag is poorly named - it switches the Driver App to use Mapbox driving directions, instead of the default OSRM driving directions. It has no impact on Engine or anything else but Driver App driving directions. MapBox driving directions are generally better, but be warned that they ignore OSM changesets.',
  [FeatureFlag.DriverDispatchCommunication]: 'Enables driver and dispatch communication',
  [FeatureFlag.EngageCasesOnRider]: 'Enables riders to view their associated Engage cases on the Rider App.',
  [FeatureFlag.ProblemTripsBackgroundMatching]:
    'Enables a job to automatically rematch upcoming unmatched and late requests',
  [FeatureFlag.DriverShowRiderFullName]: 'Enables Driver app displaying full name of a rider',
  [FeatureFlag.DisableRiderAppPhotoEditing]: 'Disables the ability for riders to edit their profile photo',
  [FeatureFlag.SchedulingBeforeEstimates]: 'Force riders to choose a time before they see available services',
  [FeatureFlag.ForceMatch]: 'Force a request to be matched to a duty',
  [FeatureFlag.UpdatedRiderInterface]: 'Enables the new Rider interface',
  [FeatureFlag.UnscheduledDriving]:
    'Allows drivers to drive their own vehicle for a given fleet, without a scheduled duty',
  [FeatureFlag.AcceptRejectFleet]: 'Allow users to create fleets that require drivers to accept or reject requests',
  [FeatureFlag.EnableRiderAppMixpanelAnalytics]: 'Enables Mixpanel analytics in the Rider app',
  [FeatureFlag.PaymentMethodTypesInRiderApp]:
    'Enables the ability to book requests in the rider app using configurable payment method types instead of only cash and rider owned payment methods.',
  [FeatureFlag.AutoOverrideConstraints]:
    'Enables a 15 min max constraints override for a repeated matching of requests that were not matched',
  [FeatureFlag.DisableUpdatingDefaultProfileFields]: 'Prevents Riders from changing their first and last name',
  [FeatureFlag.MetabaseReports]: 'Enables custom reports through metabase',
  [FeatureFlag.ConditionalEligibility]: 'Enables conditional eligibility for group memberships',
  [FeatureFlag.DisableConditionalEligibilityBanner]:
    'If checked do not show the conditional eligibility banner on the request creation screen in admin and do not prevent request creation in the rider app. This only takes effect if the conditionalEligibility feature flag is enabled.',
  [FeatureFlag.DisplayOnlyPickupTimeInRideOptions]:
    'Replaces the drop-off time displayed under the ride options with the pick-up time."',
  [FeatureFlag.DataReconciliation]: 'Enables the edit of metric-related fields for completed requests',
  [FeatureFlag.ManualInterventions]:
    'Enables edit dropoff, complete pickup or dropoff, reverse pickup or dropoff, reverse no-show, reverse cancellation features',
  [FeatureFlag.DisableImprovedTimeRules]:
    'Time rules will revert to the former, more permissive behaviour, which will not restrict entire pickup/dropoff windows to occur entirely within time rules.',
  [FeatureFlag.LocationNotes]:
    'Enables the ability to add notes to favorite locations. Will show up as pickup / dropoff notes in admin and driver app',
  [FeatureFlag.MultiEstimateRider]:
    'Enables riders to schedule rides within an extended time window using the rider app',
  [FeatureFlag.MergeZonesOnRider]: "Merges the all zones into one on the rider's map",
  [FeatureFlag.DutyPulloutVisibility]:
    'Enables new live map behaviour which makes duty pullouts (late or on time) visible',
  [FeatureFlag.DriverIdleMap]: 'Driver app shows the map when the driver is idle (no active requests)',
  [FeatureFlag.DisablePleoLiveMessage]: 'Disables the PLEO modal for live message',
  [FeatureFlag.DisablePleoManualInterventions]: 'Disables the PLEO modal for manual interventions',
  [FeatureFlag.ValidatePickupAndDropoffLocationsRider]:
    "Validates if the pickup and dropoff locations are in the same service's areas in the rider app",
  [FeatureFlag.ShowCapacityRules]:
    'Shows the capacity rules on the vehicle types page, this will be hidden for the winnipeg presentation and launch cause...reasons',
  [FeatureFlag.VehicleTypes]: 'Enables the vehicle types feature, meant to aid in the rollout of this new feature',
  [FeatureFlag.VehicleClass]:
    'Enables the vehicle class selection, vehicle class selection on the vehicle will allow for configuring customized routing.',
  [FeatureFlag.PSTAGroupExpiryNotifications]:
    "Exclusive feature for the PSTA organization. Set of group expiry notification to riders from 'ADA Access' group who are due to expire in 30 days.",
  [FeatureFlag.GATRAGroupExpiryNotifications]:
    "Exclusive feature for the GATRA organization. Set of group expiry notification to riders from 'ADA Access' group who are due to expire in 30 days.",
  [FeatureFlag.RiderPhonePin]: 'Enables rider phone PIN features',
  [FeatureFlag.LaunchAIVoice]: 'Enables AI Voice for the Launch product',
  [FeatureFlag.EngageAIVoice]: 'Enables AI Voice for the Engage product',
  [FeatureFlag.RiderStripePaymentSDK]: 'Enables the Stripe Payment SDK for the rider app (Mobile Only)',
  [FeatureFlag.RiderConfigurableOnboarding]: 'Enables the admin to configure the onboarding that is shown to riders',
  [FeatureFlag.VehicleInspections]: 'Enables the pre/post-duty vehicle inspections feature',
  [FeatureFlag.RiderShowNewStopMarkers]: 'Enables the new stop markers on the rider app',
  [FeatureFlag.RiderZoneRestrictionByGroups]: 'Enables restriction of zones in the rider app based on groups',
  [FeatureFlag.HideStopDescriptionsOnRiderTimeline]: 'Hides the stop descriptions on the rider timeline',
  [FeatureFlag.LiveRequest]: 'Enables the Live Request feature',
  [FeatureFlag.Wallet]: 'Allows a wallet payment provider to be set up and configured',
  [FeatureFlag.DisableLocationNotesInRiderApp]: 'Disables the ability to view and edit location notes in the rider app',
  [FeatureFlag.DataRetention]: 'Enables data retention reports',
  [FeatureFlag.BookingCopilot]: 'Enables Booking Copilot',
  [FeatureFlag.requestViewV2]: 'Switches the old Request View to the new Redesign',
  [FeatureFlag.CustomCancellationReasons]: 'Enables custom cancellation reasons',
  [FeatureFlag.EnableRiderAppDriverPolyline]:
    'Enables showing a polyline of the route the driver is taking in the rider app',
  [FeatureFlag.AnalyzeCopilot]: 'Enables Analyze Copilot',
  [FeatureFlag.DutyLatenessByBins]:
    'Changes duty lateness calculation to only look at requests associated to the closest bins to the duty',
  [FeatureFlag.AllowIncompleteForms]: 'Allows admin users on Engage to submit incomplete case forms',
  [FeatureFlag.AiVoiceClaudeModel]: 'Enables the claude-3-5-sonnet-20240620 model for AI Voice',
  [FeatureFlag.Tickets]: 'Enables the ticket payment method type',
  [FeatureFlag.AiVoiceGpt4OMini]: 'Enables the gpt-4o-mini-2024-07-18 model for AI Voice',
  [FeatureFlag.MultipleWorkflows]: 'Enables multiple workflows',
  [FeatureFlag.GtfsFlexExportImprovements]: 'Enables GTFS Flex Export Improvements',
  [FeatureFlag.GCPGateway]: 'Enables organization to use GCP Gateway for API requests',
  [FeatureFlag.ZoneStopLibrary]: 'Enables the usage of zone stop library',
  [FeatureFlag.RiderNewScheduleTripFlow]: 'Enables the schedule trip flow for rider',
  [FeatureFlag.RiderNewScheduleButton]: 'Adds the schedule button on the home card in the rider app',
  [FeatureFlag.EngageCaseViewV2]: 'Switches the old Case View to the new Redesign',
}
