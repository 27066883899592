// Why we disabled this rule: react-router-dom has a funny way of typing its 'to' prop which conflicts with are our unsafe-assignment-any rule
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React, { CSSProperties, ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { ButtonIcon } from '../icons'
import { AbstractButtonStyles } from './AbstractButton'
import { DefaultButtonTheme, IButtonTheme } from './ButtonThemes'
import { roundedButtonStyle } from './RoundedButton'

export interface ILinkButtonProps extends LinkProps {
  icon?: IconProp
  iconColor?: string
  theme?: IButtonTheme
  width?: string
  style?: CSSProperties
  children?: ReactNode
  className?: string
  isExternal?: boolean
}

const BaseLinkStyles = css`
  ${AbstractButtonStyles}
  ${roundedButtonStyle}
  display: inline-flex;
  text-align: center;
  text-decoration: none !important;
`
const InternalLink = styled(Link)`
  ${BaseLinkStyles}
`

const ExternalLink = styled.a`
  ${BaseLinkStyles}
`

export const LinkButton = ({
  isExternal,
  to,
  icon,
  iconColor,
  children,
  theme = DefaultButtonTheme,
  ...rest
}: ILinkButtonProps): JSX.Element =>
  isExternal ? (
    <ExternalLink {...rest} href={to} theme={theme}>
      {icon && <ButtonIcon icon={icon} color={iconColor ?? theme.textColor} />}
      {children}
    </ExternalLink>
  ) : (
    <InternalLink {...rest} to={to} theme={theme}>
      {icon && <ButtonIcon icon={icon} color={iconColor ?? theme.textColor} />}
      {children}
    </InternalLink>
  )
