import { IDifferenceLine, LineDifferenceType } from './DifferenceCalculator'

export enum DifferenceType {
  Previous = 'previous',
  Current = 'current',
}

export const isLineDifferent = (line: IDifferenceLine | undefined, differenceType: DifferenceType): boolean => {
  if (!line) {
    return false
  }
  switch (differenceType) {
    case DifferenceType.Previous:
      return [LineDifferenceType.Modified, LineDifferenceType.Removed].includes(line.differenceType)
    case DifferenceType.Current:
      return [LineDifferenceType.Modified, LineDifferenceType.Added].includes(line.differenceType)
  }
}
