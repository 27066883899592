import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum RiderVehicleTypeRestrictionPermissionSet {
  View = 'rider_vehicle_type_restriction_view',
  Write = 'rider_vehicle_type_restriction_write',
  Delete = 'rider_vehicle_type_restriction_delete',
}

export const riderVehicleTypeRestrictionPermissionSetGrouping: IPermissionSetGrouping<RiderVehicleTypeRestrictionPermissionSet> =
  {
    key: PermissionSetGroupingKey.RiderVehicleTypeRestriction,
    active: {
      [RiderVehicleTypeRestrictionPermissionSet.View]: [PermissionType.GET_ANY_RIDER_VEHICLE_TYPE_RESTRICTION],
      [RiderVehicleTypeRestrictionPermissionSet.Write]: [PermissionType.WRITE_ANY_RIDER_VEHICLE_TYPE_RESTRICTION],
      [RiderVehicleTypeRestrictionPermissionSet.Delete]: [PermissionType.DELETE_ANY_RIDER_VEHICLE_TYPE_RESTRICTION],
    },
    deprecated: [],
    unAssignablePermissionSets: [],
  }
