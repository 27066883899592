import { MetadataError } from '@sparelabs/error-types'
import { IRelativeTimeConstraint, OffsetDirection } from '../scheduling'
import { SlotType } from '../types'

export interface ISlotPair<T extends ISlotPairInput> {
  firstIndex?: number
  secondIndex?: number

  first?: T
  second?: T
}

export interface ISlotPairInput {
  type: SlotType
  dutyId?: string
  requestId?: string | null
  driverBreakId?: string | null
}

export class SlotPairMap<T extends ISlotPairInput = ISlotPairInput> {
  public static getSlotPairId(slot: ISlotPairInput): string {
    const pairId = slot.requestId || slot.dutyId
    if (!pairId) {
      throw new MetadataError('Found slot with undefined dutyId', { slot })
    }
    return pairId
  }

  private readonly requestPairMap: Map<string, ISlotPair<T>> = new Map<string, ISlotPair<T>>()
  private readonly otherPairMap: Map<string, ISlotPair<T>> = new Map<string, ISlotPair<T>>()

  constructor(slots: T[]) {
    for (const [index, slot] of slots.entries()) {
      const slotPairId: string = SlotPairMap.getSlotPairId(slot)

      // load precomputed pair if it exists
      const pairMap = this.choosePairMap(slot)
      const pair: ISlotPair<T> = pairMap.get(slotPairId) || {}

      switch (slot.type) {
        case SlotType.Pickup:
        case SlotType.StartLocation:
          pair.firstIndex = index
          pair.first = slot
          break
        case SlotType.Dropoff:
        case SlotType.EndLocation:
          pair.secondIndex = index
          pair.second = slot
      }

      pairMap.set(slotPairId, pair)
    }
  }

  public getBase(
    slot: ISlotPairInput,
    relativeConstraint: Pick<IRelativeTimeConstraint, 'direction'>
  ): { baseSlot: T; baseIndex: number } | undefined {
    const result = this.getPairFor(slot)
    if (result) {
      return relativeConstraint.direction === OffsetDirection.After
        ? { baseSlot: result.first as T, baseIndex: result.firstIndex as number }
        : { baseSlot: result.second as T, baseIndex: result.secondIndex as number }
    }
    return undefined
  }

  public getPairById(id: string): ISlotPair<T> | undefined {
    return this.requestPairMap.get(id) || this.otherPairMap.get(id)
  }

  public getAllRequestPairs(): Array<ISlotPair<T>> {
    return Array.from(this.requestPairMap.values())
  }

  public getPairFor(slot: ISlotPairInput): ISlotPair<T> | undefined {
    return this.getPairById(SlotPairMap.getSlotPairId(slot))
  }

  public getAllPairs(): Array<ISlotPair<T>> {
    return Array.from(this.otherPairMap.values()).concat(this.getAllRequestPairs())
  }

  private choosePairMap(slot: T) {
    return slot.requestId ? this.requestPairMap : this.otherPairMap
  }
}
