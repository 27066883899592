import { WebColors } from '@sparelabs/colors'
import { isString, uniqueId } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { IInputProps } from '../InputTypes'
import { Choice, IChoice } from './Choice'

interface IProps<T> extends Omit<IInputProps<T[]>, 'onChange' | 'value'> {
  values: T[] | undefined
  groups: Array<{ name: string; choices: Array<IChoice<T>> }>
  onChange: (value: T[]) => void
  readOnly?: boolean
  id?: string
}

const Container = styled.div`
  padding-top: 12px;
  line-height: 1;
`

const GroupContainer = styled.div`
  display: grid;
  align-items: top;
  grid-template-columns: 1fr 2fr;
  padding-bottom: 12px;
`

const NameContainer = styled.h2`
  font-weight: normal;
  padding-top: 3px;

  // font-size, height and border-bottom set so this appears as a line in the middle of the <h2> tag
  border-bottom: 1px solid ${WebColors.borderPrimary};
  font-size: 15px;
  height: 12px;
`

const GroupName = styled.span`
  // Setting background so the name covers the line from NameContainer.
  background: ${WebColors.backgroundPrimary};
  color: ${WebColors.contentSecondary};
  padding-right: 8px;
`

const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 8px;
  gap: 8px;
`

export const GroupedMultipleChoiceInput = <T,>({ id, groups, ...inputProps }: IProps<T>) => (
  <Container>
    {groups.map((group) => (
      <GroupContainer key={group.name}>
        <NameContainer>
          <GroupName>{group.name}</GroupName>
        </NameContainer>
        <ChoicesContainer>
          {group.choices.map((choice) => (
            <Choice
              {...inputProps}
              choice={choice}
              containerId={isString(id) ? id : uniqueId('grouped-multiple-choice-input-')}
              key={isString(choice.value) ? choice.value : JSON.stringify(choice.value)}
            />
          ))}
        </ChoicesContainer>
      </GroupContainer>
    ))}
  </Container>
)
