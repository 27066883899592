import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'
import { CasePermissionSet, casePermissionSetGrouping } from './EngagePermissions'
import { GroupPermissionSet, groupPermissionSetGrouping } from './GroupPermissions'
import { OrganizationPermissionSet, organizationPermissionSetGrouping } from './OrganizationPermissions'
import { PaymentMethodTypePermissionSet, paymentMethodTypePermissionSetGrouping } from './PaymentMethodTypePermissions'
import { UserOwnPermissionSet, userOwnPermissionSetGrouping } from './UserOwnPermissions'

export enum RiderPermissionSet {
  Rider = 'rider',
}

export enum RiderOnboardingFlowPermissionSet {
  View = 'rider_onboarding_view',
  Write = 'rider_onboarding_write',
}

export const riderPermissionSetGrouping: IPermissionSetGrouping<RiderPermissionSet> = {
  key: PermissionSetGroupingKey.Rider,
  active: {
    [RiderPermissionSet.Rider]: [
      ...organizationPermissionSetGrouping.active[OrganizationPermissionSet.ViewOwn],
      ...organizationPermissionSetGrouping.active[OrganizationPermissionSet.ViewCustomSchemas],

      ...userOwnPermissionSetGrouping.active[UserOwnPermissionSet.User],
      ...userOwnPermissionSetGrouping.active[UserOwnPermissionSet.Rider],

      ...casePermissionSetGrouping.active[CasePermissionSet.SubmitOwn],
      ...casePermissionSetGrouping.active[CasePermissionSet.ViewOwn],

      ...paymentMethodTypePermissionSetGrouping.active[PaymentMethodTypePermissionSet.View],

      ...groupPermissionSetGrouping.active[GroupPermissionSet.WriteOwnMembership],
      ...groupPermissionSetGrouping.active[GroupPermissionSet.ViewOwnMembership],

      PermissionType.LIST_ANY_RIDER_ONBOARDING_FLOW,

      PermissionType.GET_OWN_REVIEW,
      PermissionType.POST_OWN_REVIEW,

      PermissionType.GET_OWN_PROMO_ALLOCATION,
      PermissionType.POST_OWN_PROMO_ALLOCATION,

      PermissionType.GET_ANY_SERVICE,
      PermissionType.LIST_ANY_SERVICE,

      PermissionType.GET_ANY_ANNOUNCEMENT,
      PermissionType.LIST_ACTIVE_ANNOUNCEMENT,

      PermissionType.GET_OWN_REQUEST,
      PermissionType.POST_OWN_REQUEST,
      PermissionType.POST_OWN_REQUEST_CANCELLATION,
      PermissionType.POST_OWN_REQUEST_FARE_ESTIMATE,
      PermissionType.PATCH_OWN_REQUEST,
      PermissionType.GET_OWN_LAST_REQUEST_VL,
      PermissionType.GET_OWN_REQUEST_VEHICLE,
      PermissionType.GET_OWN_REQUEST_SLOTS,
      PermissionType.GET_OWN_REQUEST_INSIGHTS,
      PermissionType.GET_OWN_REQUEST_PAYMENT_OPTIONS,
      PermissionType.GET_OWN_REQUEST_PREDICTIONS,
      PermissionType.CAN_CREATE_OWN_UNMATCHED_REQUEST,

      // TODO get rid of this permission and deprecate
      // access to vehicle info for riders. necessary vehicle info
      // should be bundled inside of a request object
      PermissionType.GET_ANY_VEHICLE,
      PermissionType.GET_OWN_VEHICLE,

      // TODO get rid of all these permissions
      // currently in here because the rider app does not check
      // permissions before listing/adding/patching vehicles
      PermissionType.WRITE_OWN_VEHICLE,

      // TODO get rid of this permissions
      // currently in here because the rider app does not check
      // permissions before listing duties
      PermissionType.LIST_OWN_DUTY,

      PermissionType.GET_ANY_STOP,
      PermissionType.LIST_ANY_STOP,

      PermissionType.GET_ANY_ZONE,
      PermissionType.LIST_ANY_ZONE,

      /* Used for uploading a profile picture */
      PermissionType.POST_UPLOAD_PHOTO,

      PermissionType.GET_ESTIMATE_SERVICES,
      PermissionType.GET_ESTIMATE_SERVICES_FROM,
      PermissionType.GET_ESTIMATE_SERVICES_TO,
      PermissionType.GET_SERVICE_AVAILABILITY,
      PermissionType.GET_OWN_ESTIMATE_REQUEST,

      PermissionType.GET_JOURNEY_ESTIMATES,
      PermissionType.GET_JOURNEY_OPTIONS,

      PermissionType.GET_OWN_JOURNEY,
      PermissionType.WRITE_OWN_JOURNEY,

      PermissionType.POST_OWN_STRIPE_TOKEN,
      PermissionType.POST_OWN_STRIPE_SESSION,
      PermissionType.POST_OWN_STRIPE_SETUP_INTENT,
      PermissionType.POST_OWN_NIMOCA_ID,

      PermissionType.POST_OWN_WALLET,
      PermissionType.LIST_OWN_WALLET_TRANSACTION,
      PermissionType.WRITE_OWN_WALLET_AUTO_TOP_UP,
      PermissionType.DELETE_OWN_WALLET_AUTO_TOP_UP,

      PermissionType.GET_OWN_PAYMENT_METHOD,
      PermissionType.DELETE_OWN_PAYMENT_METHOD,

      PermissionType.POST_OWN_PAY_COLLECTION_CALL,
      PermissionType.GET_OWN_PAY_COLLECTION_CALL,

      PermissionType.GET_ANY_CHARGE,
      PermissionType.GET_OWN_CHARGE,

      PermissionType.GET_OWN_FAVORITE_LOCATION,
      PermissionType.WRITE_OWN_FAVORITE_LOCATION,
      PermissionType.DELETE_OWN_FAVORITE_LOCATION,

      PermissionType.GET_ANY_GROUP,

      PermissionType.GET_OWN_CONNECTED_ACCOUNT,
      PermissionType.DELETE_OWN_CONNECTED_ACCOUNT,

      PermissionType.LIST_OWN_DEVICE,
      PermissionType.WRITE_OWN_DEVICE,
      PermissionType.DELETE_OWN_DEVICE,

      PermissionType.GET_OWN_FARE_PASS_ALLOCATION,
      PermissionType.POST_OWN_FARE_PASS_ALLOCATION,

      PermissionType.GET_ANY_FARE_PASS,

      PermissionType.GET_OWN_TIP,
      PermissionType.POST_OWN_TIP,

      PermissionType.GET_ANY_TIP_POLICY,

      PermissionType.POST_OWN_CHARGE,

      PermissionType.GET_AUTOCOMPLETE_SUGGESTIONS,
      PermissionType.GET_PLACE_DETAILS,
      PermissionType.GET_REVERSE_GEOCODE,

      PermissionType.GET_OWN_USER_FLEET_AGREEMENT,
      PermissionType.POST_OWN_USER_FLEET_AGREEMENT,
      PermissionType.DELETE_OWN_USER_FLEET_AGREEMENT,

      PermissionType.GET_OWN_TICKET_INSTANCE,
      PermissionType.WRITE_OWN_TICKET_INSTANCE,

      PermissionType.GET_ANY_ZONE_STOP,
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [RiderPermissionSet.Rider],
}

export const riderOnboardingFlowPermissionSetGrouping: IPermissionSetGrouping<RiderOnboardingFlowPermissionSet> = {
  key: PermissionSetGroupingKey.RiderOnboardingFlow,
  active: {
    [RiderOnboardingFlowPermissionSet.View]: [PermissionType.LIST_ANY_RIDER_ONBOARDING_FLOW],
    [RiderOnboardingFlowPermissionSet.Write]: [PermissionType.WRITE_ANY_RIDER_ONBOARDING_FLOW],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}

export const riderOnboardingFlowPermissionSets = riderOnboardingFlowPermissionSetGrouping.active
export const riderPermissionSets = riderPermissionSetGrouping.active
