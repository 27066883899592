import { LanguageKey } from './Translator'

export class BrowserLanguageHelper {
  public static getCurrentLanguageCode(): LanguageKey {
    let currentLanguageCode = 'en'

    if (navigator.languages && navigator.languages.length) {
      currentLanguageCode = navigator.languages[0]
    } else if ((navigator as { userLanguage?: string }).userLanguage) {
      // IE only
      currentLanguageCode = (navigator as unknown as { userLanguage: string }).userLanguage
    } else if (navigator.language) {
      currentLanguageCode = navigator.language
    }

    return currentLanguageCode.substring(0, 2)
  }
}
