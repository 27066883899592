import { CustomFieldStatus, ICustomFieldResponse, IRiderMeResponse, IUserMePatchBody } from '@sparelabs/api-client'
import { FeatureFlag } from '@sparelabs/feature-flags'
import { EmptyValue, PhotoInput, RoundedButton, SubmitButtonTheme, TextInput } from '@sparelabs/web-ui-components'
import { sortBy } from 'lodash'
import React, { SyntheticEvent, useState } from 'react'
import { useAsync } from 'react-use'
import { ApiClientBuilder } from 'src/api/ApiClientBuilders'
import {
  ActionButtonsWrapper,
  CustomFieldWithLabel,
  ErrorView,
  FieldContainer,
  FormContainer,
  InfoParagraph,
  LoadingView,
  StackedInputWithLabel,
  StackedViewField,
} from 'src/components'
import {
  ErrorHandler,
  FormValidator,
  IValidateFormField,
  ValidateConstraintType,
  withErrorHandler,
  withLoadingState,
} from 'src/helpers'
import { AuthenticationHelper } from 'src/helpers/AuthenticationHelper'
import { st } from 'src/locales/TranslationHelper'
import { useFormContext } from '../FormContext'

const t = st.forms.riderProfile

enum UserFieldType {
  Text,
  Photo,
}

interface IUserField extends IValidateFormField {
  key: keyof IUserMePatchBody
  type: UserFieldType
  readonly?: boolean
}

const buildUserFields = (nameReadOnly: boolean): IUserField[] => [
  {
    key: 'firstName',
    type: UserFieldType.Text,
    label: t.firstNameLabel(),
    constraint: { maxStringLength: 32 },
    readonly: nameReadOnly,
  },
  {
    key: 'lastName',
    type: UserFieldType.Text,
    label: t.lastNameLabel(),
    constraint: { maxStringLength: 32 },
    readonly: nameReadOnly,
  },
  {
    key: 'email',
    type: UserFieldType.Text,
    label: t.emailLabel(),
    constraint: { type: ValidateConstraintType.Email, maxStringLength: 64 },
  },
  { key: 'photoUrl', type: UserFieldType.Photo, label: t.photoLabel(), constraint: {} },
]

const isFormValid = (
  data: IUserMePatchBody,
  metadata: Record<string, unknown>,
  userFields: IUserField[],
  customFields: ICustomFieldResponse[]
): boolean =>
  FormValidator.isFormValid(data as Record<string, unknown>, userFields) &&
  FormValidator.areCustomFieldsValid(metadata, customFields)

interface IProps {
  rider: IRiderMeResponse
  userFields: IUserField[]
  customFields: ICustomFieldResponse[]
}

const RiderProfileView = ({ rider, userFields, customFields }: IProps): JSX.Element => {
  const { onNextStep } = useFormContext()

  const [loading, setLoading] = useState(false)
  const [showValidation, setShowValidation] = useState(false)
  const [data, setData] = useState<IUserMePatchBody>({
    firstName: rider.firstName,
    lastName: rider.lastName,
    email: rider.email,
    photoUrl: rider.photoUrl ?? undefined,
  })
  const [metadata, setMetadata] = useState(rider.metadata)

  const handleSubmit = withErrorHandler(async (event?: SyntheticEvent) => {
    if (event) {
      event.preventDefault()
    }
    if (loading) {
      return
    }

    if (!isFormValid(data, metadata, userFields, customFields)) {
      setShowValidation(true)
      setLoading(false)
      return
    }

    setShowValidation(false)
    const client = ApiClientBuilder.build()
    await client.users.patchMeRider({ ...data, metadata })
    onNextStep()
  }, setLoading)

  const handlePhotoUpload = withLoadingState((file: File) => {
    const client = ApiClientBuilder.build()
    return client.upload.photo(file)
  }, setLoading)

  return (
    <FormContainer onSubmit={handleSubmit}>
      <InfoParagraph>{t.infoPrompt()}</InfoParagraph>
      <FieldContainer>
        <StackedViewField label={st.forms.auth.phoneNumberLabel()}>
          {rider.phoneNumber ?? <EmptyValue>{st.common.none()}</EmptyValue>}
        </StackedViewField>

        {userFields.map(({ key, type, label, constraint, readonly }, index) => (
          <StackedInputWithLabel
            key={key}
            label={label}
            error={showValidation ? FormValidator.validate(data[key], label, constraint) : undefined}
            renderInput={({ id }) =>
              type === UserFieldType.Photo ? (
                <PhotoInput
                  id={id}
                  value={data.photoUrl}
                  onChange={(newValue) => setData({ ...data, photoUrl: newValue })}
                  upload={handlePhotoUpload}
                  onError={(error) => ErrorHandler.handleError(error)}
                />
              ) : (
                <TextInput
                  id={id}
                  label={label}
                  value={data[key]}
                  readOnly={readonly}
                  onChange={(newValue) => setData({ ...data, [key]: newValue })}
                  autoFocus={index === 0} // Immediately focus on the first field
                />
              )
            }
          />
        ))}
        {customFields.map((field) => (
          <CustomFieldWithLabel
            field={field}
            key={field.key}
            showValidation={showValidation}
            metadata={metadata}
            onChange={setMetadata}
          />
        ))}
      </FieldContainer>
      <ActionButtonsWrapper>
        <RoundedButton disabled={loading} theme={SubmitButtonTheme} onClick={handleSubmit}>
          {st.common.continue()}
        </RoundedButton>
      </ActionButtonsWrapper>
    </FormContainer>
  )
}

export const RiderProfileStep = (): JSX.Element => {
  const { loading, error, value } = useAsync(async () => {
    const client = ApiClientBuilder.build()
    const rider = await client.users.getMeRider()

    const extensions = await client.customSchemas.getNativeObjectExtensions()
    const customFields = sortBy(
      extensions.rider.fields.filter((field) => field.status === CustomFieldStatus.Active),
      (field) => field.order
    )

    const disableUpdateProfileFeatureFlag = await checkDisableUpdateProfileFeatureFlag()
    const preventEditingName = disableUpdateProfileFeatureFlag && rider.firstName != null && rider.lastName != null
    const userFields = buildUserFields(preventEditingName)

    return { rider, customFields, userFields }
  }, [])

  if (loading) {
    return <LoadingView />
  }

  if (error || !value) {
    return <ErrorView message={error?.message || st.errors.noContent()} />
  }

  return <RiderProfileView {...value} />
}

async function checkDisableUpdateProfileFeatureFlag() {
  const organizationId = AuthenticationHelper.getOrganizationId()
  if (!AuthenticationHelper.isAuthenticated() || !organizationId) {
    return false
  }

  const client = ApiClientBuilder.build()
  const organization = await client.organizations.get(organizationId)
  return organization.featureFlags.includes(FeatureFlag.DisableUpdatingDefaultProfileFields)
}
