import { CountryLoader } from './CountryLoader'
import { FIELD_REGEXP, FIELDS_MAPPING, TemplateHelper } from './TemplateHelper'
import { AddressFieldName, AddressFieldNameNoCompanyAndPhone, IAddress, ICountry } from './types'

const LINE_DELIMITER = '_'
const DEFAULT_FORM_LAYOUT =
  '{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{country}{province}{zip}_{phone}'
const DEFAULT_SHOW_LAYOUT =
  '{lastName} {firstName}_{company}_{address1} {address2}_{city} {province} {zip}_{country}_{phone}'

const ORDERED_COUNTRIES_CACHE: {
  [locale: string]: ICountry[]
} = {}

export class AddressFormatter {
  private readonly countryLoader: CountryLoader

  constructor(host: string, private readonly locale: string) {
    this.countryLoader = new CountryLoader(host)
  }

  public async getCountry(countryCode: string): Promise<ICountry> {
    return this.loadCountryFromCache(countryCode) ?? this.countryLoader.loadCountry(this.locale, countryCode)
  }

  public async getCountries(): Promise<ICountry[]> {
    const countries = await this.countryLoader.loadCountries(this.locale)
    ORDERED_COUNTRIES_CACHE[this.locale] = countries

    return countries
  }

  /* Returns the address ordered in an array based based on the country code
   * Eg.:
   *   [
   *     'Shopify',
   *     'First Name Last Name',
   *     'Address 1',
   *     'address2',
   *     'Montreal',
   *     'Canada Quebec H2J 4B7',
   *     '514 444 3333'
   *   ]
   */
  public async format(address: IAddress): Promise<string[]> {
    const country = await this.getCountry(address.country)
    const layout = country?.formatting.show ?? DEFAULT_SHOW_LAYOUT
    return layout.split(LINE_DELIMITER).map((fields) => TemplateHelper.templateLine(country, fields, address).trim())
  }

  /* Returns an array that shows how to order fields based on the country code
   * Eg.:
   *   [
   *     ['company'],
   *     ['firstName', 'lastName'],
   *     ['address1'],
   *     ['address2'],
   *     ['city'],
   *     ['country', 'province', 'zip'],
   *     ['phone']
   *   ]
   */
  public async getOrderedFields(countryCode: string): Promise<AddressFieldName[][]> {
    const country = await this.getCountry(countryCode)
    const format = country?.formatting.edit ?? DEFAULT_FORM_LAYOUT

    return format
      .split(LINE_DELIMITER)
      .map((fields) => fields.match(FIELD_REGEXP)?.map((field) => FIELDS_MAPPING[field]) ?? [])
  }

  private loadCountryFromCache(countryCode: string): ICountry | undefined {
    return ORDERED_COUNTRIES_CACHE[this.locale]?.find((country) => country.code === countryCode)
  }

  public createLabelTable(country: ICountry): Record<AddressFieldNameNoCompanyAndPhone, string> {
    const labels = country.labels
    return {
      [AddressFieldName.FirstName]: labels.firstName,
      [AddressFieldName.LastName]: labels.lastName,
      [AddressFieldName.Country]: labels.country,
      [AddressFieldName.City]: labels.city,
      [AddressFieldName.PostalCode]: labels.postalCode,
      [AddressFieldName.Zone]: labels.zone,
      [AddressFieldName.Address1]: labels.address1,
      [AddressFieldName.Address2]: labels.address2,
    }
  }
}
