import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum IvrPermissionSet {
  ViewMetrics = 'ivr_view_metrics',
}

export const ivrPermissionSetGrouping: IPermissionSetGrouping<IvrPermissionSet> = {
  key: PermissionSetGroupingKey.Ivr,
  active: {
    [IvrPermissionSet.ViewMetrics]: [PermissionType.GET_ANY_IVR_CALL_METRICS],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
