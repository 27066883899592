import { Language } from '@sparelabs/api-client'
import { DeepPartial } from '@sparelabs/core'
import i18next from 'i18next'

type INestedStrings<T> = { [P in keyof T]: INestedStrings<T[P]> | string } | string
/* One-layer nesting is mandatory with i18next library */
export type ITranslationStrings<T> = { [P in keyof T]: { [K in keyof T[P]]: INestedStrings<T[P][K]> } }

export type PartialTranslationStrings<T> = DeepPartial<ITranslationStrings<T>>

export type TranslatedLanguageStringRecord<T> = Record<
  Exclude<Language, Language.English>,
  PartialTranslationStrings<T>
>

export type LanguageKey = string

/**
 * Replace the reserved namespace (:) and key (.) separators if they exist in the key by the UTF-8 encoding of the character
 *
 * For example, input -> output:
 *
 * cspell: disable
 *
 * 'request.recurrence:write' -> 'request%2Erecurrence%3Awrite'
 * 'service:write' -> 'service%2EWrite
 *
 * cspell:enable
 */
export const replaceReservedSeparators = <T extends string | number>(key: T): string =>
  key.toString().replace(/:|\./g, (match: string) => '%' + match.charCodeAt(0).toString(16).toUpperCase())

const onMissingKeyFound = (ns: string, key: string) => {
  if (key) {
    // Red screen when key is not found
    // eslint-disable-next-line no-console
    console.error(`${ns}: ${key} is missing.`)
  }
}

export class Translator<T> {
  public i18nextInstance: i18next.i18n
  private readonly fallbackLng: string = 'en'
  private translateConfig: i18next.InitOptions = {}
  private translator: T
  private readonly defaultLanguage: ITranslationStrings<T>

  constructor(
    defaultLanguage: ITranslationStrings<T>,
    translatedLanguages: Partial<TranslatedLanguageStringRecord<T>>,
    languageKey: LanguageKey,
    errorHandler: (error: Error) => void
  ) {
    this.defaultLanguage = defaultLanguage
    const allLocales: Record<string, i18next.ResourceLanguage> = {
      en: defaultLanguage,
      ...(translatedLanguages as i18next.ResourceLanguage),
    }
    this.setConfig(allLocales)
    // Init i18next
    this.i18nextInstance = i18next.createInstance(this.translateConfig, errorHandler)

    // Creating our translator and setting language
    let activeLocale = allLocales[languageKey]
    // If there are no keys for the current language setting, switch to fallback
    if (activeLocale) {
      this.i18nextInstance.changeLanguage(languageKey)
    } else {
      activeLocale = allLocales[this.fallbackLng]
      this.i18nextInstance.changeLanguage(this.fallbackLng)
    }
    // We always make the translator out of en locale because it contains
    // all the keys that are necessary for full UI
    this.translator = this.makeTranslator(defaultLanguage, ':')
  }

  public changeLanguage(locale: string): void {
    this.i18nextInstance.changeLanguage(locale)
    this.translator = this.makeTranslator(this.defaultLanguage, ':')
  }

  public get st(): T {
    return this.translator
  }

  public translateByKey(key: string, locale?: string): string | null {
    if (locale) {
      this.i18nextInstance.changeLanguage(locale)
    }
    if (this.i18nextInstance.exists(key)) {
      return this.i18nextInstance.t(key) as string
    }
    return null
  }

  private setConfig(allLocales: Record<string, i18next.ResourceLanguage>): void {
    this.translateConfig = {
      fallbackLng: this.fallbackLng,
      interpolation: {
        escapeValue: false,
      },
      debug: false,
      ns: ['screens', 'common'],
      defaultNS: 'common',
      saveMissing: true,
      missingKeyHandler: onMissingKeyFound,
      resources: allLocales,
    }
  }

  private makeTranslator(localeFilePart: any, keySeparator: string, nestingKey: string = ''): T {
    const container = {}
    for (const key of Object.keys(localeFilePart)) {
      Object.defineProperty(container, key, {
        get: () => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          const nestedFilePart = localeFilePart[key]
          if (typeof nestedFilePart === 'string') {
            return (options: any, locale?: Language) => {
              if (locale) {
                this.i18nextInstance.changeLanguage(locale)
              }
              return this.i18nextInstance.t(`${nestingKey}${key}`, options) as string
            }
          }
          return this.makeTranslator(nestedFilePart, '.', `${nestingKey}${key}${keySeparator}`)
        },
      })
    }
    return container as T
  }
}
