import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
// eslint-disable-next-line import/no-cycle
import { IListMobileAppQueryParams } from './MobileAppTypes'

export enum IosReleaseStatus {
  Accepted = 'accepted',
  DeveloperRemovedFromSale = 'developerRemovedFromSale',
  DeveloperRejected = 'developerRejected',
  InReview = 'inReview',
  InvalidBinary = 'invalidBinary',
  MetadataRejected = 'metadataRejected',
  PendingAppleRelease = 'pendingAppleRelease',
  PendingContract = 'pendingContract',
  PendingDeveloperRelease = 'pendingDeveloperRelease',
  PrepareForSubmission = 'prepareForSubmission',
  PreorderReadyForSale = 'preorderReadyForSale',
  ProcessingForAppStore = 'processingForAppStore',
  ReadyForReview = 'readyForReview',
  ReadyForSale = 'readyForSale',
  Rejected = 'rejected',
  RemovedFromSale = 'removedFromSale',
  WaitingForExportCompliance = 'waitingForExportCompliance',
  WaitingForReview = 'waitingForReview',
  ReplacedWithNewVersion = 'replacedWithNewVersion',
}

export enum AndroidReleaseStatus {
  Completed = 'completed',
  Draft = 'draft',
  Halted = 'halted',
  InProgress = 'inProgress',
  StatusUnspecified = 'statusUnspecified',
}

export enum MobileAppListViews {
  All = 'All',
  ActionNeeded = 'ActionNeeded',
}

export interface IPlayStoreStatus extends IModelJsonValues {
  liveMajorVersion: number | null
  liveMinorVersion: number | null
  livePatchVersion: number | null
  releaseMajorVersion: number | null
  releaseMinorVersion: number | null
  releasePatchVersion: number | null
  releaseStatus: AndroidReleaseStatus
}

export interface IAppStoreStatus extends IModelJsonValues {
  liveMajorVersion: number | null
  liveMinorVersion: number | null
  livePatchVersion: number | null
  releaseMajorVersion: number
  releaseMinorVersion: number
  releasePatchVersion: number
  releaseStatus: IosReleaseStatus
}

export interface IWebStatus extends IModelJsonValues {
  liveMajorVersion: number | null
  liveMinorVersion: number | null
  livePatchVersion: number | null
}

export interface IListMobileAppWithReleaseInfoQueryParams extends IListMobileAppQueryParams {
  view?: MobileAppListViews
}

export interface IReleaseResponse extends IModelJsonValues {
  major: number
  minor: number
  patch: number
  releaseNotes: string
  releaseTs: number
}

export type IMobileAppReleasePostBody = Omit<
  IReleaseResponse,
  'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'patch'
> & {
  patch?: number
}

export type IMobileAppReleasePatchBody = Partial<IMobileAppReleasePostBody>

export type IListMobileAppReleaseQueryParams = IListModelQueryParamValues
