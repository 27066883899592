import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IPromoAllocationListQueryParams,
  IPromoAllocationPostBody,
  IPromoAllocationResponse,
  Resources,
} from '../types'

export class PromoAllocationResource extends GenericResource<
  IPromoAllocationResponse,
  IPromoAllocationListQueryParams,
  never,
  IPromoAllocationPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.PromoAllocations, agent)
  }
}
