import { ConnectedAccountType } from './ConnectedAccountTypes'

export enum PayCallStatus {
  InProgress = 'inProgress',
  Completed = 'completed',
  Failed = 'failed',
}

export enum PayDataStatus {
  Pending = 'pending',
  Completed = 'completed',
  Skipped = 'skipped',
}

export interface IPayCollectionCallPostBody {
  userId: string
  connectedAccountType: ConnectedAccountType
}

export interface IPayCollectionCallResponse {
  id: string
  connectedAccountType: ConnectedAccountType
  userId: string
  callStatus: PayCallStatus
  creditCardNumberStatus: PayDataStatus
  expirationDateStatus: PayDataStatus
  postalCodeStatus: PayDataStatus
  securityCodeStatus: PayDataStatus
}
