import { Currency } from '@sparelabs/currency'
import { sumBy } from 'lodash'
import { DiscountType, IFareRedemptionStub, IFareStub } from '..'

/**
 * TODO: There is a "fare" amount of redundancy in these data structures. E.g.
 *  - both contain a `currency` field
 *  - fare `total` should always be `cost` - `discount`
 *  - fare `discount` should always equal the sum of the amounts of the fare redemptions
 */
export interface IFareTarget {
  fare: IFareStub
  fareRedemptions: IFareRedemptionStub[]
}

export class FareSemantics {
  public static requiresPayment({ fare }: Pick<IFareTarget, 'fare'>): boolean {
    return fare.total > 0
  }

  public static isFreeBeforeDiscounts({ fare }: Pick<IFareTarget, 'fare'>): boolean {
    return fare.cost === 0
  }

  public static hasDiscount({ fare }: Pick<IFareTarget, 'fare'>): boolean {
    return fare.discount > 0
  }

  public static usesFarePass(redemption: { type: DiscountType }): boolean {
    return redemption.type === DiscountType.TimeBased || redemption.type === DiscountType.StoredValue
  }

  public static usesPromo(redemption: { type: DiscountType }): boolean {
    return redemption.type === DiscountType.Promo
  }

  public static buildFareTarget(
    costPreDiscount: number,
    discounts: Array<Pick<IFareRedemptionStub, 'type' | 'amount'>>,
    currency: Currency
  ): IFareTarget {
    const discount = sumBy(discounts, (discount) => discount.amount)
    return {
      fare: {
        cost: costPreDiscount,
        discount,
        total: costPreDiscount - discount,
        currency,
      },
      fareRedemptions: discounts.map((redemption) => ({
        ...redemption,
        currency,
      })),
    }
  }
}
