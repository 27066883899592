import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons'
import { WebColors } from '@sparelabs/colors'
import { IFileResponse, IFileTempUrlResponse, IPublicFileResponse } from '@sparelabs/http-client-utils'
import React from 'react'
import styled from 'styled-components'
import { INPUT_MAX_WIDTH } from '../../../util'
import { FileView } from '../../displays'
import { StyledDropzone } from '../styled'
import { IStyledDropzoneProps } from './FileInputTypes'

library.add(faTrashAlt)

export interface IFileInputProps<T extends IFileResponse | IPublicFileResponse>
  extends Omit<IStyledDropzoneProps, 'onDrop'> {
  value: IFileResponse | null
  onChange: (value: (T extends IFileResponse ? IFileResponse : IPublicFileResponse) | null) => void
  disableRemove?: boolean
  upload: (fileData: File) => Promise<T extends IFileResponse ? IFileResponse : IPublicFileResponse>
  tempUrl?: (id: string) => Promise<IFileTempUrlResponse>
  id?: string
  // The props sets the height of the field input field to match the container's height, causing the file input to cover the container.
  coverContainer?: boolean
}

const FileContainer = styled.div`
  max-width: ${INPUT_MAX_WIDTH};
  border: 1px solid ${WebColors.borderPrimary};
  border-radius: 8px;
  padding: 16px 16px;
  background-color: ${WebColors.backgroundPrimary};
`

export function FileInput<D extends IFileResponse | IPublicFileResponse>({
  value,
  onChange,
  disableRemove,
  upload,
  tempUrl,
  onError,
  label,
  error,
  accept,
  maxSizeInBytes,
  id,
  coverContainer,
}: IFileInputProps<D>): JSX.Element {
  return value ? (
    <FileContainer>
      <FileView
        value={value}
        disableRemove={disableRemove}
        onRemove={() => onChange(null)}
        tempUrl={tempUrl}
        onError={onError}
      />
    </FileContainer>
  ) : (
    <StyledDropzone
      label={label}
      width={INPUT_MAX_WIDTH}
      error={error}
      accept={accept}
      maxSizeInBytes={maxSizeInBytes}
      onDrop={async (file: File) => {
        const res = await upload(file)
        onChange(res)
      }}
      onError={onError}
      id={id}
      coverContainer={coverContainer}
    />
  )
}
