import { AllCasesReportCaseColumns, AllCasesReportRiderColumns } from './AnalyzeAllCasesReportColumnTypes'
import { AllReportsModels } from './AnalyzeReportModelTypes'

export enum CaseTypeReportCaseTypeColumns {
  CaseForms = 'caseForms',
}

/**
 * Models that are included in the Case Template Report
 *
 * The order here controls the order of models on the 'Edit Columns' modal
 */
export const CaseTypeReportModelNamesArray = [AllReportsModels.Rider, AllReportsModels.Case, AllReportsModels.CaseType]

export type ICaseTypeReportColumns =
  | AllCasesReportRiderColumns
  | AllCasesReportCaseColumns
  | CaseTypeReportCaseTypeColumns

export const CaseTypeReportModelAndColumns = {
  [AllReportsModels.Rider]: AllCasesReportRiderColumns,
  [AllReportsModels.Case]: AllCasesReportCaseColumns,
  [AllReportsModels.CaseType]: CaseTypeReportCaseTypeColumns,
}

export const CaseTypeReportColumnNames = Object.values<ICaseTypeReportColumns>(AllCasesReportRiderColumns)
  .concat(Object.values(AllCasesReportCaseColumns))
  .concat(Object.values(CaseTypeReportCaseTypeColumns))
