import { ICustomFieldResponse } from '@sparelabs/api-client'
import { IFormContentResponse } from '@sparelabs/engage-client'
import { Banner, infoBannerTheme, Markdown, RoundedButton, SubmitButtonTheme } from '@sparelabs/web-ui-components'
import React, { useState } from 'react'
import {
  ActionButtonsWrapper,
  BannerContainer,
  CustomFieldWithLabel,
  FieldContainer,
  FormBackButton,
  FormContainer,
} from 'src/components'
import { isCustomField, isFormContent } from 'src/helpers'
import { st } from 'src/locales/TranslationHelper'
import { useFormContext } from '../../FormContext'
import { FormSubmitted } from '../../FormStepTypes'

export type IFormField = ICustomFieldResponse | IFormContentResponse

interface IProps {
  fields: IFormField[]
  onSubmit: (metadata: Record<string, unknown>) => void
  showValidation: boolean
}

// Only show the banner when the form has never been submitted and this isn't a "new case"/intake form
const showNotSubmittedBanner = (formSubmitted: FormSubmitted, caseId?: string): boolean =>
  formSubmitted === FormSubmitted.Never && Boolean(caseId)

export const FormInputForm = ({ fields, onSubmit, showValidation }: IProps): JSX.Element => {
  const { caseId, formSubmitted, prevCaseForm, onPrevStep } = useFormContext()
  const [metadata, setMetadata] = useState<Record<string, unknown>>(prevCaseForm?.metadata || {})

  return (
    <FormContainer>
      <FieldContainer>
        {showNotSubmittedBanner(formSubmitted, caseId) && (
          <BannerContainer>
            <Banner theme={infoBannerTheme}>{st.forms.pageLayout.formNotYetSubmitted()}</Banner>
          </BannerContainer>
        )}
        {fields.map((formItem, index) => {
          if (isCustomField(formItem)) {
            return (
              <CustomFieldWithLabel
                key={formItem.key}
                field={formItem}
                showValidation={showValidation}
                metadata={metadata}
                onChange={setMetadata}
              />
            )
          }
          if (isFormContent(formItem)) {
            return (
              <Markdown key={index} skipHtml={false}>
                {formItem.content}
              </Markdown>
            )
          }
          return null
        })}
      </FieldContainer>
      <ActionButtonsWrapper>
        <FormBackButton onClick={onPrevStep} />
        <RoundedButton theme={SubmitButtonTheme} onClick={() => onSubmit(metadata)}>
          {st.common.submit()}
        </RoundedButton>
      </ActionButtonsWrapper>
    </FormContainer>
  )
}
