import { BaseClient, IApiClientConfiguration } from '@sparelabs/http-client-utils'
import {
  AdminResource,
  AlertResource,
  AnnouncementResource,
  AntivirusResource,
  ApiKeyResource,
  AuthResource,
  AutocompleteSuggestionsResource,
  BillingAccountAssignmentResource,
  BillingAccountResource,
  BreakPolicyResource,
  ChargeResource,
  ConnectedAccountResource,
  CustomSchemaResource,
  DataRetentionScheduleResource,
  DeprecatedServiceFleetResource,
  DeviceResource,
  DispatcherOperationsResource,
  DriverOperationsResource,
  DriverResource,
  DutyRequestOfferResource,
  DutyResource,
  EmailResource,
  EstimateResource,
  ExternalDispatchResource,
  FarePassAllocationResource,
  FarePassResource,
  FareRedemptionResource,
  FareRuleResource,
  FavoriteLocationResource,
  FileResource,
  FleetMetricsResource,
  FleetResource,
  GeographyResource,
  GlobalUserResource,
  GroupMembershipResource,
  GroupResource,
  JourneyEstimateResource,
  JourneyOptionsResource,
  MetabaseResource,
  MobileAppResource,
  NotificationAnalyticsResource,
  NotificationSettingResource,
  OrganizationResource,
  PaymentMethodResource,
  PlaceDetailsResource,
  PromoAllocationResource,
  PromoResource,
  PublicResource,
  ReportResource,
  RequestDailyCountResource,
  RequestPredictionResource,
  RequestRecurrenceResource,
  RequestResource,
  ReverseGeocodeResource,
  ReviewResource,
  RiderResource,
  RiderVehicleTypeRestrictionResource,
  RouteAssignmentResource,
  RouteOptimizationResource,
  RouteResource,
  ServiceFleetResource,
  ServiceMetricsResource,
  ServiceResource,
  SlotResource,
  SmsResource,
  StopResource,
  SupportedClientsResource,
  TicketInstanceResource,
  TimeRuleResource,
  TipPolicyResource,
  TokenContextResource,
  UploadResource,
  UserBanResource,
  UserResource,
  VehicleInspectionResource,
  VehicleInspectionTemplateResource,
  VehicleLocationResource,
  VehicleResource,
  WebhookResource,
  WorkOsResource,
  ZoneResource,
} from '../resources'
import { AnalyzeReportJobResource } from '../resources/AnalyzeReportJobResource'
import { AppInstallationResource } from '../resources/AppInstallationResource'
import { AppResource } from '../resources/AppResource'
import { AppWebhookResource } from '../resources/AppWebhookResource'
import { AuthorizationGroupResource } from '../resources/AuthorizationGroupResource'
import { AuthorizationPermissionSetResource } from '../resources/AuthorizationPermissionSetResource'
import { BulkNotificationResource } from '../resources/BulkNotificationResource'
import { CallCentreMetricsResource } from '../resources/CallCentreMetricsResource'
import { CancellationReasonResource } from '../resources/CancellationReasonsResource'
import { DriverBreakResource } from '../resources/DriverBreakResource'
import { DutyConversationResource } from '../resources/DutyConversationResource'
import { EstimateOverrideResource } from '../resources/EstimateOverrideResource'
import { EventResource } from '../resources/EventResource'
import { GeocodeResource } from '../resources/GeocodeResource'
import { GlobalResource } from '../resources/GlobalResource'
import { GroupConditionResource } from '../resources/GroupConditionResource'
import { JourneyResource } from '../resources/JourneyResource'
import { LyftInstallationResource } from '../resources/LyftInstallationResource'
import { MatchingOutcomeResource } from '../resources/MatchingOutcomeResource'
import { MatchingSimulationResource } from '../resources/MatchingSimulationResource'
import { MobileAppReleaseResource } from '../resources/MobileAppReleaseResource'
import { NotificationProviderConfigurationResource } from '../resources/NotificationProviderConfigurationResource'
import { OAuthAuthorizeResource } from '../resources/OAuthResource'
import { PaymentMethodTypeResource } from '../resources/PaymentMethodTypeResource'
import { PaymentProviderResource } from '../resources/PaymentProviderResource'
import { PleoResource } from '../resources/PleoResource'
import { ReportTemplatesResource } from '../resources/ReportTemplatesResource'
import { RiderMetricsResource } from '../resources/RiderMetricsResource'
import { RiderOnboardingResource } from '../resources/RiderOnboardingResource'
import { SuperAdminAuthorizationGroupResource } from '../resources/SuperAdminAuthorizationGroupResource'
import { TriggeredOptimizationResource } from '../resources/TriggeredOptimizationResource'
import { UserFleetAgreementResource } from '../resources/UserFleetAgreementResource'
import { UserGroupResource } from '../resources/UserGroupResource'
import { UserSettingsResource } from '../resources/UserSettingsResource'
import { VehicleTypeResource } from '../resources/VehicleTypeResource'
import { WalletAutoTopUpResource } from '../resources/WalletAutoTopUpResource'

export class ApiClient extends BaseClient {
  public auth: AuthResource
  public analyzeReportJobs: AnalyzeReportJobResource
  public workos: WorkOsResource
  public metabase: MetabaseResource
  public fleets: FleetResource
  public paymentMethods: PaymentMethodResource
  public paymentMethodTypes: PaymentMethodTypeResource
  public paymentProviders: PaymentProviderResource
  public requests: RequestResource
  public stops: StopResource
  public vehicles: VehicleResource
  public vehicleLocations: VehicleLocationResource
  public services: ServiceResource
  public users: UserResource
  public riders: RiderResource
  public drivers: DriverResource
  public admins: AdminResource
  public userSettings: UserSettingsResource
  public deprecatedServiceFleets: DeprecatedServiceFleetResource
  public serviceFleets: ServiceFleetResource
  public webhooks: WebhookResource
  public zones: ZoneResource
  public duties: DutyResource
  public slots: SlotResource
  public driverOperations: DriverOperationsResource
  public estimates: EstimateResource
  public estimatesOverride: EstimateOverrideResource
  public journeyEstimates: JourneyEstimateResource
  public journeyOptions: JourneyOptionsResource
  public upload: UploadResource
  public organizations: OrganizationResource
  public geographies: GeographyResource
  public reports: ReportResource
  public supportedClients: SupportedClientsResource
  public announcements: AnnouncementResource
  public apiKeys: ApiKeyResource
  public charges: ChargeResource
  public dataRetentionSchedule: DataRetentionScheduleResource
  public devices: DeviceResource
  public dutyRequestOffers: DutyRequestOfferResource
  public farePasses: FarePassResource
  public farePassAllocations: FarePassAllocationResource
  public fareRedemptions: FareRedemptionResource
  public fareRules: FareRuleResource
  public favoriteLocations: FavoriteLocationResource
  public groups: GroupResource
  public groupMemberships: GroupMembershipResource
  public groupConditions: GroupConditionResource
  public notificationSettings: NotificationSettingResource
  public bulkNotifications: BulkNotificationResource
  public promos: PromoResource
  public promoAllocations: PromoAllocationResource
  public requestRecurrences: RequestRecurrenceResource
  public reviews: ReviewResource
  public routeOptimization: RouteOptimizationResource
  public timeRules: TimeRuleResource
  public userBans: UserBanResource
  public customSchemas: CustomSchemaResource
  public serviceMetrics: ServiceMetricsResource
  public riderMetrics: RiderMetricsResource
  public fleetMetrics: FleetMetricsResource
  public callCentreMetrics: CallCentreMetricsResource
  public public: PublicResource
  public files: FileResource
  public tipPolicies: TipPolicyResource
  public tokenContext: TokenContextResource
  public connectedAccounts: ConnectedAccountResource
  public alerts: AlertResource
  public routes: RouteResource
  public routeAssignments: RouteAssignmentResource
  public externalDispatch: ExternalDispatchResource
  public places: {
    autocompleteSuggestions: AutocompleteSuggestionsResource
    placeDetails: PlaceDetailsResource
    reverseGeocode: ReverseGeocodeResource
    geocode: GeocodeResource
  }

  public emails: EmailResource
  public sms: SmsResource
  public mobileApps: MobileAppResource
  public mobileAppRelease: MobileAppReleaseResource
  public billingAccounts: BillingAccountResource
  public billingAccountAssignments: BillingAccountAssignmentResource
  public apps: AppResource
  public appInstallations: AppInstallationResource
  public appWebhooks: AppWebhookResource
  public oAuthAuthorize: OAuthAuthorizeResource
  public lyftInstallation: LyftInstallationResource
  public reportTemplates: ReportTemplatesResource
  public breakPolicies: BreakPolicyResource
  public driverBreaks: DriverBreakResource
  public global: GlobalResource
  public globalUser: GlobalUserResource
  public requestDailyCounts: RequestDailyCountResource
  public userFleetAgreements: UserFleetAgreementResource
  public events: EventResource
  public antivirus: AntivirusResource
  public matchingSimulations: MatchingSimulationResource
  public matchingOutcomes: MatchingOutcomeResource
  public triggeredOptimizations: TriggeredOptimizationResource
  public notificationAnalytics: NotificationAnalyticsResource
  public pleo: PleoResource
  public dispatcherOperations: DispatcherOperationsResource
  public notificationProviderConfigurations: NotificationProviderConfigurationResource
  public vehicleInspections: VehicleInspectionResource
  public vehicleInspectionTemplates: VehicleInspectionTemplateResource
  public vehicleTypes: VehicleTypeResource
  public riderVehicleTypeRestrictions: RiderVehicleTypeRestrictionResource
  public walletAutoTopUp: WalletAutoTopUpResource
  public dutyConversation: DutyConversationResource
  public riderOnboardingFlow: RiderOnboardingResource
  public journeyResource: JourneyResource
  public requestPredictions: RequestPredictionResource
  public cancellationReasons: CancellationReasonResource
  public authorizationGroup: AuthorizationGroupResource
  public authorizationPermissionSets: AuthorizationPermissionSetResource
  public superAdminAuthorizationGroup: SuperAdminAuthorizationGroupResource
  public userGroup: UserGroupResource
  public ticketInstance: TicketInstanceResource

  constructor(configuration: Partial<IApiClientConfiguration>) {
    super(configuration)
    this.auth = new AuthResource(this.agent)
    this.analyzeReportJobs = new AnalyzeReportJobResource(this.agent)
    this.workos = new WorkOsResource(this.agent)
    this.metabase = new MetabaseResource(this.agent)
    this.fleets = new FleetResource(this.agent)
    this.paymentMethods = new PaymentMethodResource(this.agent)
    this.paymentMethodTypes = new PaymentMethodTypeResource(this.agent)
    this.paymentProviders = new PaymentProviderResource(this.agent)
    this.estimates = new EstimateResource(this.agent)
    this.estimatesOverride = new EstimateOverrideResource(this.agent)
    this.journeyEstimates = new JourneyEstimateResource(this.agent)
    this.journeyOptions = new JourneyOptionsResource(this.agent)
    this.requests = new RequestResource(this.agent)
    this.stops = new StopResource(this.agent)
    this.vehicles = new VehicleResource(this.agent)
    this.vehicleLocations = new VehicleLocationResource(this.agent)
    this.services = new ServiceResource(this.agent)
    this.users = new UserResource(this.agent)
    this.riders = new RiderResource(this.agent)
    this.drivers = new DriverResource(this.agent)
    this.admins = new AdminResource(this.agent)
    this.userSettings = new UserSettingsResource(this.agent)
    this.deprecatedServiceFleets = new DeprecatedServiceFleetResource(this.agent)
    this.serviceFleets = new ServiceFleetResource(this.agent)
    this.webhooks = new WebhookResource(this.agent)
    this.zones = new ZoneResource(this.agent)
    this.duties = new DutyResource(this.agent)
    this.slots = new SlotResource(this.agent)
    this.driverOperations = new DriverOperationsResource(this.agent)
    this.upload = new UploadResource(this.agent)
    this.organizations = new OrganizationResource(this.agent)
    this.geographies = new GeographyResource(this.agent)
    this.reports = new ReportResource(this.agent)
    this.supportedClients = new SupportedClientsResource(this.agent)
    this.announcements = new AnnouncementResource(this.agent)
    this.apiKeys = new ApiKeyResource(this.agent)
    this.charges = new ChargeResource(this.agent)
    this.dataRetentionSchedule = new DataRetentionScheduleResource(this.agent)
    this.devices = new DeviceResource(this.agent)
    this.dutyRequestOffers = new DutyRequestOfferResource(this.agent)
    this.farePasses = new FarePassResource(this.agent)
    this.farePassAllocations = new FarePassAllocationResource(this.agent)
    this.fareRedemptions = new FareRedemptionResource(this.agent)
    this.fareRules = new FareRuleResource(this.agent)
    this.favoriteLocations = new FavoriteLocationResource(this.agent)
    this.groups = new GroupResource(this.agent)
    this.groupMemberships = new GroupMembershipResource(this.agent)
    this.groupConditions = new GroupConditionResource(this.agent)
    this.notificationSettings = new NotificationSettingResource(this.agent)
    this.bulkNotifications = new BulkNotificationResource(this.agent)
    this.promos = new PromoResource(this.agent)
    this.promoAllocations = new PromoAllocationResource(this.agent)
    this.requestRecurrences = new RequestRecurrenceResource(this.agent)
    this.reviews = new ReviewResource(this.agent)
    this.routeOptimization = new RouteOptimizationResource(this.agent)
    this.timeRules = new TimeRuleResource(this.agent)
    this.userBans = new UserBanResource(this.agent)
    this.customSchemas = new CustomSchemaResource(this.agent)
    this.serviceMetrics = new ServiceMetricsResource(this.agent)
    this.fleetMetrics = new FleetMetricsResource(this.agent)
    this.callCentreMetrics = new CallCentreMetricsResource(this.agent)
    this.public = new PublicResource(this.agent)
    this.files = new FileResource(this.agent)
    this.tipPolicies = new TipPolicyResource(this.agent)
    this.tokenContext = new TokenContextResource(this.agent)
    this.connectedAccounts = new ConnectedAccountResource(this.agent)
    this.emails = new EmailResource(this.agent)
    this.sms = new SmsResource(this.agent)
    this.alerts = new AlertResource(this.agent)
    this.mobileApps = new MobileAppResource(this.agent)
    this.mobileAppRelease = new MobileAppReleaseResource(this.agent)
    this.routes = new RouteResource(this.agent)
    this.routeAssignments = new RouteAssignmentResource(this.agent)
    this.externalDispatch = new ExternalDispatchResource(this.agent)
    this.places = {
      autocompleteSuggestions: new AutocompleteSuggestionsResource(this.agent),
      placeDetails: new PlaceDetailsResource(this.agent),
      reverseGeocode: new ReverseGeocodeResource(this.agent),
      geocode: new GeocodeResource(this.agent),
    }
    this.billingAccounts = new BillingAccountResource(this.agent)
    this.billingAccountAssignments = new BillingAccountAssignmentResource(this.agent)
    this.apps = new AppResource(this.agent)
    this.appInstallations = new AppInstallationResource(this.agent)
    this.appWebhooks = new AppWebhookResource(this.agent)
    this.oAuthAuthorize = new OAuthAuthorizeResource(this.agent)
    this.lyftInstallation = new LyftInstallationResource(this.agent)
    this.breakPolicies = new BreakPolicyResource(this.agent)
    this.driverBreaks = new DriverBreakResource(this.agent)
    this.global = new GlobalResource(this.agent)
    this.globalUser = new GlobalUserResource(this.agent)
    this.requestDailyCounts = new RequestDailyCountResource(this.agent)
    this.userFleetAgreements = new UserFleetAgreementResource(this.agent)
    this.events = new EventResource(this.agent)
    this.antivirus = new AntivirusResource(this.agent)
    this.reportTemplates = new ReportTemplatesResource(this.agent)
    this.matchingSimulations = new MatchingSimulationResource(this.agent)
    this.matchingOutcomes = new MatchingOutcomeResource(this.agent)
    this.triggeredOptimizations = new TriggeredOptimizationResource(this.agent)
    this.notificationAnalytics = new NotificationAnalyticsResource(this.agent)
    this.pleo = new PleoResource(this.agent)
    this.dispatcherOperations = new DispatcherOperationsResource(this.agent)
    this.notificationProviderConfigurations = new NotificationProviderConfigurationResource(this.agent)
    this.vehicleInspections = new VehicleInspectionResource(this.agent)
    this.vehicleInspectionTemplates = new VehicleInspectionTemplateResource(this.agent)
    this.vehicleTypes = new VehicleTypeResource(this.agent)
    this.riderVehicleTypeRestrictions = new RiderVehicleTypeRestrictionResource(this.agent)
    this.walletAutoTopUp = new WalletAutoTopUpResource(this.agent)
    this.dutyConversation = new DutyConversationResource(this.agent)
    this.riderOnboardingFlow = new RiderOnboardingResource(this.agent)
    this.journeyResource = new JourneyResource(this.agent)
    this.requestPredictions = new RequestPredictionResource(this.agent)
    this.riderMetrics = new RiderMetricsResource(this.agent)
    this.cancellationReasons = new CancellationReasonResource(this.agent)
    this.authorizationGroup = new AuthorizationGroupResource(this.agent)
    this.authorizationPermissionSets = new AuthorizationPermissionSetResource(this.agent)
    this.superAdminAuthorizationGroup = new SuperAdminAuthorizationGroupResource(this.agent)
    this.userGroup = new UserGroupResource(this.agent)
    this.ticketInstance = new TicketInstanceResource(this.agent)
  }
}
