import {
  ArriveByPickupConstraintType,
  RiderType,
  ScheduledRequestFlexWindowType,
  WalkingDirectionsStrategy,
  ZoneDirection,
  ZoneType,
} from '@sparelabs/domain-logic'
import { IPoint, IPolygon } from '@sparelabs/geography'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { IServiceAccessibilityFeature } from './AccessibilityTypes'
import { AllowCreatingNewUnmatchedRequestsTypes } from './AllowCreatingNewUnmatchedRequestsTypes'
import { CreatedInterface } from './EstimateTypes'
import { IFareRuleResponse } from './FareRuleTypes'
import { IFleetResponse, IFleetStub } from './FleetTypes'
import { IGroupResponse } from './GroupTypes'
import { IServiceFleetResponse } from './ServiceFleetTypes'
import { ITimeRule, ITimeRuleOverride, ITimeRuleResponse } from './TimeRuleTypes'
import { ITipPolicyResponse } from './TipPolicyTypes'

/**
 * @deprecated use from @sparelabs/domain-logic instead
 */
export { ZoneType, ZoneDirection, WalkingDirectionsStrategy }

export const DEFAULT_MIN_ADVANCE_BOOKING_SECONDS = 0
export const DEFAULT_MIN_ADVANCE_BOOKING_TIME_OF_DAY = 0
export const DEFAULT_MAX_ADVANCE_BOOKING_DAYS = 7
export const DEFAULT_DETOUR_FLEXIBILITY_SECONDS = 900
export const DEFAULT_MAX_CONSTRAINT_OVERRIDE = 3600

/**
 * @deprecated no longer meaningful, should not be used in any new code
 */
export enum ServiceType {
  OnDemand = 'ondemand',
}

export enum ServiceStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Archived = 'archived',
}

/**
 * @deprecated - Use fleet.type instead
 */
export enum ServiceSupplyType {
  GuaranteedSupply = 'guaranteedSupply', // auto-accept only
  UnlimitedSupply = 'unlimitedSupply', // accept-reject only
  MixedSupply = 'mixedSupply', // service integrations only
}

export enum MinAdvanceBookingType {
  None = 'none',
  FixedOffset = 'fixedOffset',
  TimeOfDay = 'timeOfDay',
}

export enum TripFulfillmentStyle {
  StaticStops = 'staticStops',
  DoorToDoor = 'doorToDoor',
}

export enum DetourFlexibilityLevel {
  L1 = 'l1',
  L2 = 'l2',
  L3 = 'l3',
  L4 = 'l4',
}

export enum MatchingPriority {
  Low = 'low',
  High = 'high',
}

export interface IServiceBrand {
  name: string
  photoUrl: string
}

export interface IServiceDeprecatedApiFields {
  exclusionDates: string[]
  /**
   * @deprecated - Use fleet.type instead
   */
  supplyType: ServiceSupplyType
  /**
   * @deprecated - Use acceptedFlexLeaveAtForward instead
   */
  pickupFlexibility: number
  /**
   * @deprecated - No longer in use and will be removed in February 2021
   */
  perRiderBoardingTime: number
  /**
   * @deprecated - use availableToInterfaces instead
   */
  isAvailableToRiderInterface: boolean
  /**
   * @deprecated - use availableToInterfaces instead
   */
  isAvailableToDriverInterface: boolean
}

export interface IServicePostBody {
  name: string
  description?: string | null
  brandImageUrl?: string | null
  type: ServiceType
  startDate?: string
  endDate?: string
  baseBoardingTime?: number
  allowPublicDrivers?: boolean
  minDriverNoticeSeconds?: number
  status?: ServiceStatus
  accessibilityFeatures?: IServiceAccessibilityFeature[]
  riderReviewsEnabled?: boolean
  driverReviewsEnabled?: boolean
  riderTypes?: RiderType[]
  detourFlexibilitySeconds?: number
  allowScheduledLeaveAtBooking?: boolean
  allowScheduledArriveByBooking?: boolean
  availableToInterfaces?: CreatedInterface[]
  minAdvanceBookingType?: MinAdvanceBookingType
  minAdvanceBookingSeconds?: number
  minAdvanceBookingTimeOfDay?: number
  allowAdminOverrideMinAdvanceBooking?: boolean
  maxAdvanceBookingDays?: number
  allowAdminOverrideMaxAdvanceBooking?: boolean
  nextAvailableFlexibility?: number
  recurringTripCreationDeadlineDays?: number
  metadata?: Record<string, unknown>
  isCashEnabled?: boolean
  enabledPaymentMethodTypes?: string[]
  scheduledRequestFlexWindowType?: ScheduledRequestFlexWindowType
  acceptedFlexLeaveAtForward?: number
  acceptedFlexLeaveAtBackward?: number
  acceptedFlexArriveByBackward?: number
  arriveByPickupConstraintFlex?: number
  arriveByPickupConstraintType?: ArriveByPickupConstraintType
  scheduledSearchFlexLeaveAtForward?: number
  scheduledSearchFlexLeaveAtBackward?: number
  scheduledSearchFlexArriveByBackward?: number
  recurringTripFlexLeaveAtForward?: number
  recurringTripFlexLeaveAtBackward?: number
  recurringTripFlexArriveByBackward?: number
  showDriverProfileToRiders?: boolean
  matchingPriority?: MatchingPriority
  minTripDistanceMeters?: number
  enablePooling?: boolean
  maxRiders?: number | null
  enableTipping?: boolean
  walkingDirections?: WalkingDirectionsStrategy
  fareRuleId?: string | null
  tipPolicyId?: string | null
  showPickupDropoffWindows?: boolean
  maxConstraintOverride?: number | null
  isVisible?: boolean
  buildNextAvailableRequestsForNow?: boolean
  disableBumpingToEarlierProtection?: boolean
  disableBumpingToLaterProtection?: boolean
  fleetPriorityWaitSeconds?: number
  startTryingToMatchOffset?: number | null
  stopTryingToMatchOffset?: number | null
  allowUnmatchedRequests?: boolean
  allowCreatingNewUnmatchedRequests?: AllowCreatingNewUnmatchedRequestsTypes[]
  allowDriverToEditRequestPaymentMethod?: boolean
  leaveAtNumberOfFallbacksForward?: number
  leaveAtNumberOfFallbacksBackward?: number
  arriveByNumberOfFallbacksBackward?: number
}

export interface IServiceResponse extends IModelJsonValues, Required<IServicePostBody>, IServiceDeprecatedApiFields {
  id: string
  timezone: string
  timeRules: ITimeRule[]
  timeRuleOverrides: ITimeRuleOverride[]
  linkedFleets: IFleetStub[]
  tipPolicyId: string | null
  enabledPaymentMethodTypes: string[]
  hasCustomFare: boolean
}

export interface IServiceListQueryParams extends IListModelQueryParamValues {
  type?: ServiceType
  status?: ServiceStatus | ServiceStatus[]
  tipPolicyId?: string
  fareRuleId?: string
  isVisible?: boolean
}

export interface IZonePostBodyCommon {
  serviceId: string
  name: string
  direction: ZoneDirection
  type: ZoneType
  groupIds: string[]
  timeRuleId: string | null
  isVisibleToRiderApp?: boolean
  externalId?: string | null
}

/**
 * @deprecated - Use IZonePostBodyZoneArea instead
 */
export interface IZonePostBodyDeprecated extends IZonePostBodyCommon {
  /**
   * @deprecated - Create/select a zoneArea and provide its id as zoneAreaId instead
   */
  area: IPolygon
}

export interface IZonePostBodyZoneArea extends IZonePostBodyCommon {
  zoneAreaId: string
}

export const isZonePostBodyDeprecated = (body: IZonePostBody): body is IZonePostBodyDeprecated => 'area' in body
export const isZonePostBody = (body: IZonePostBody): body is IZonePostBodyZoneArea => 'zoneAreaId' in body

export type IZonePostBody = IZonePostBodyDeprecated | IZonePostBodyZoneArea

export type IZonePatchBody = Partial<IZonePostBodyCommon> & {
  /**
   * @deprecated - Create/select a zoneArea and provide its id as zoneAreaId instead
   */
  area?: IPolygon
  zoneAreaId?: string
}

export interface IZoneResponse extends IModelJsonValues, Required<IZonePostBodyCommon> {
  id: string
  area: IPolygon
  zoneAreaId: string
}

export interface IStopPostBody {
  code: string
  name: string | null
  description: string | null
  location: IPoint
  wheelchairBoarding: boolean
  zoneId: string
  isVisibleToRiderApp: boolean
  isTransitHub?: boolean
  isEnabled?: boolean
}

export interface IStopResponse extends IModelJsonValues, IStopPostBody {
  id: string
}

export interface IZoneListQueryParams extends IListModelQueryParamValues {
  serviceId?: string
  serviceIds?: string[]
  direction?: ZoneDirection
  type?: ZoneType
  serviceType?: ServiceType
  serviceStatus?: ServiceStatus | ServiceStatus[]
  timeRuleId?: string
  isVisibleToRiderApp?: boolean
}

export interface IStopListQueryParams extends IListModelQueryParamValues {
  search?: string
  serviceId?: string
  serviceIds?: string[]
  zoneId?: string
  zoneDirection?: ZoneDirection
  zoneType?: ZoneType[] | ZoneType
  serviceType?: ServiceType[]
  serviceStatus?: ServiceStatus[] | ServiceStatus
  timeRuleId?: string
  isVisibleToRiderApp?: boolean
}

export interface IServiceData {
  servicesById: Map<string, IServiceResponse>
  zonesByServiceId: Map<string, IZoneResponse[]>
  stopsByZoneId: Map<string, IStopResponse[]>
  timeRules: ITimeRuleResponse[]
  fleets: IFleetResponse[]
  serviceFleets: Map<string, IServiceFleetResponse[]>
  groups: IGroupResponse[]
  fareRules: IFareRuleResponse[]
  tipPolicies: ITipPolicyResponse[]
}

export interface IOrganizationZonesCentroidResponse {
  centroidPoint: IPoint | null
  centroidRadius: number | null
}
