/**
 * Similar to lodash.chunk, but distributes the elements as evenly as possible, e.g:
 * - maxSize 50 for an array of length 100 results in chunks of size: [50, 50]
 * - maxSize 50 for an array of length 101 results in chunks of size: [33, 34, 34]
 */
export const chunkEvenly = <T>(list: T[], maxSize: number): T[][] => {
  if (maxSize <= 0) {
    throw new Error('maxSize must be greater than 0')
  }

  const chunkCount = Math.ceil(list.length / maxSize)

  /**
   * This is a variation of Bresenham's line algorithm (https://en.wikipedia.org/wiki/Bresenham%27s_line_algorithm).
   * The line algorithm calculates a linear progression of rounded values.
   * Geometrically it amounts to picking the integer closest to the interpolated float value of the line.
   * We can then use the difference between two successive integer values as chunk sizes.
   */
  const counts: number[] = []
  let rest: number = 0

  for (let chunkIndex = 0; chunkIndex < chunkCount; chunkIndex++) {
    rest += list.length
    counts.push(Math.floor(rest / chunkCount))
    rest = rest % chunkCount
  }

  let listIndex = 0
  const chunks: T[][] = []

  for (let chunkIndex = 0; chunkIndex < chunkCount; chunkIndex++) {
    const chunk: T[] = []
    for (let ind = 0; ind < counts[chunkIndex]; ind++, listIndex++) {
      chunk.push(list[listIndex])
    }
    chunks.push(chunk)
  }

  return chunks
}
