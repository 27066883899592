import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'

export enum UserBanType {
  PhoneNumber = 'phoneNumber',
  UserId = 'userId',
}

export interface IUserBanListQueryParams extends IListModelQueryParamValues {
  /**
   * Only matches bans by User ID
   */
  userId?: string
  /**
   * Must be an exact match (including + and country code)
   */
  phoneNumber?: string
  isExpired?: boolean
  type?: UserBanType
}

export interface IUserBanPostBody {
  userId?: string
  banStartTs?: number
  banExpiryTs: number
  banReason: string
  phoneNumber?: string
}

export interface IUserBanPatchBody {
  banExpiryTs: number
}

export interface IUserBanResponse extends IModelJsonValues {
  type: UserBanType
  userId: string | null
  banStartTs: number | null
  banExpiryTs: number
  banReason: string
  isExpired: boolean
  phoneNumber: string | null
}
