export class Time {
  /**
   * Return the current Unix timestamp in seconds (e.g. number of seconds since midnight UTC on Jan 1st 1970)
   */
  public static getNowTs(): number {
    return this.msToTs(Date.now())
  }

  /**
   * Convert a Date object into a Unix timestamp in seconds.
   */
  public static toTs(date: Date): number {
    return this.msToTs(date.getTime())
  }

  private static msToTs(ms: number): number {
    // never return fractional seconds
    return Math.floor(ms / 1000)
  }
}
