import { AxiosInstance } from 'axios'
import { IExternalDispatchStatusPostBody } from '../types'

export class ExternalDispatchResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async updateStatus(body: IExternalDispatchStatusPostBody): Promise<void> {
    await this.agent.post('openFleets/status', body)
  }
}
