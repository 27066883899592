import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Accordion from '@radix-ui/react-accordion'
import { WebColors } from '@sparelabs/colors'
import React from 'react'
import styled, { keyframes } from 'styled-components'

/**
 * -- Accordion components --
 *
 * This file is pretty much just a wrapper around @radix-ui/react-accordion: https://www.radix-ui.com/primitives/docs/components/accordion
 *
 * It is wrapped so that:
 * - We can have consistent accordion styles and behaviour across the product
 * - It is easier to swap out for a different library if required
 *
 * @example Usage with prebuilt AccordionHeaderWithTitleAndIcon component (easier option if you don't need custom styling)
 *
 * <AccordionRoot>
 *   <AccordionItem>
 *     <AccordionHeaderWithTitleAndIcon title='My Title' />
 *     <AccordionContent>Some Content</AccordionContent>
 *   </AccordionItem>
 * </AccordionRoot>
 *
 * @example Usage with base AccordionHeader and AccordionTrigger (provides more flexibility)
 *
 * <AccordionRoot>
 *   <AccordionItem>
 *       <AccordionHeader className={className}>
 *         <AccordionTrigger>
 *           <div>My custom accordion header</div>
 *       </AccordionTrigger>
 *     </AccordionHeader>
 *     <AccordionContent>Some Content</AccordionContent>
 *   </AccordionItem>
 * </AccordionRoot>
 */

export const AccordionTitle = styled.span`
  font-size: 15px;
  padding-right: 10px;
  display: inline-flex;
  align-items: center;
`

export const AccordionRoot = Accordion.Root

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`

export const AccordionContent = styled(Accordion.Content)`
  overflow: hidden;

  &[data-state='open'] {
    animation-name: ${slideDown};
    animation-duration: 300ms;
    animation-timing-function: ease-out;
  }
  &[data-state='closed'] {
    animation-name: ${slideUp};
    animation-duration: 300ms;
    animation-timing-function: ease-out;
  }
`

export const AccordionItem = Accordion.Item

export const AccordionHeader = Accordion.Header

export const AccordionTrigger = styled(Accordion.Trigger)`
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  text-align: left;
  background-color: ${WebColors.backgroundPrimary};
  font-family: inherit;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
`

/**
 * This saves some effort building an accordion header with a title and icon.
 *
 * If you want to do anything custom just use AccordionHeader and AccordionTrigger directly
 */
export const AccordionHeaderWithTitleAndIcon = ({
  title,
  className,
}: {
  title: string | JSX.Element
  className?: string
}): JSX.Element => (
  <AccordionHeader className={className}>
    <StyledAccordionTrigger>
      {typeof title === 'string' ? <AccordionTitle>{title}</AccordionTitle> : title}
      <AccordionIcon icon='chevron-down' aria-hidden className='accordion-chevron' />
    </StyledAccordionTrigger>
  </AccordionHeader>
)

const StyledAccordionTrigger = styled(AccordionTrigger)`
  border-bottom: 1px solid ${WebColors.borderPrimary};
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  background-color: ${WebColors.backgroundPrimary};

  &[data-state='open'] > .accordion-chevron {
    transform: rotate(180deg);
  }
`

const AccordionIcon = styled(FontAwesomeIcon)`
  width: 15px;
  height: 15px;
  align-self: center;
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
`

const AccordionHeaderContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-right: 20px;
`

/**
 * Another version that has a button in case you want to add a delete button or something similar
 */
export const AccordionTitleWithButtons = ({ title, buttons }: { title: string; buttons: JSX.Element[] }) => (
  <AccordionHeaderContainer>
    <AccordionTitle>{title}</AccordionTitle>
    {buttons}
  </AccordionHeaderContainer>
)
