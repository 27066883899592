import { EngageReportingPermissionSet, PermissionSetType } from '../sets'
import { EventPermissionSet } from '../sets/EventPermissions'
import { RiderUserPermissionSet } from '../sets/RiderUserPermissions'
import { AdminOperatorRole } from './AdminOperatorRole'

export const AccessToEngageReportsRole: PermissionSetType[] = [
  ...AdminOperatorRole,

  EngageReportingPermissionSet.View,
  EngageReportingPermissionSet.Write,
  EngageReportingPermissionSet.Delete,
  EngageReportingPermissionSet.Export,
  RiderUserPermissionSet.View,
  EventPermissionSet.View,
]
