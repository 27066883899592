import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'

export enum LetterStatus {
  Active = 'active',
  Archived = 'archived',
}

export interface ILetterResponse extends IModelJsonValues {
  name: string
  content: string
  status: LetterStatus
  caseTypeId: string
  mailingAddressFieldId: string | null
}

export interface IListLetterQueryParams extends IListModelQueryParamValues {
  caseTypeId?: string
  status?: LetterStatus
}

export interface ILetterPostBody {
  name: string
  content: string
  caseTypeId: string
  // TODO: make required after frontend is updated to always send this
  mailingAddressFieldId?: string | null
}

export interface ILetterPatchBody {
  name?: string
  content?: string
  status?: LetterStatus
  mailingAddressFieldId?: string | null
}
