export enum Package {
  Base = 'base',
  LaunchConfiguration = 'launchConfiguration',
  DriverAppConfiguration = 'driverAppConfiguration',
  RiderAppConfiguration = 'riderAppConfiguration',
  Dispatch = 'dispatch',
  Optimization = 'optimization',
  DriverPro = 'driverPro',
  RiderPro = 'riderPro',
  Multimodal = 'multimodal',
  NotificationsPro = 'notificationsPro',
  spareIvr = 'spareIvr',
  Voice = 'voice',
  EligibilityPro = 'eligibilityPro',
  DataAccess = 'dataAccess',
  AlaCarte = 'alaCarte',
  InProductMarketing = 'inProductMarketing',
  InDevelopment = 'inDevelopment',
}
