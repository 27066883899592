import { AxiosInstance } from 'axios'
import { ISmsPostBody, Resources } from '../types'

export class SmsResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async sendSms(body: ISmsPostBody): Promise<void> {
    await this.agent.post<null>(Resources.Sms, body)
  }
}
