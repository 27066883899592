import { HOUR, TimezoneHelper } from '@sparelabs/time'
import moment, { Moment } from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { INPUT_MAX_WIDTH, INPUT_MIN_WIDTH } from '../../util'
import { DateInput } from './DateInput'
import { DstFallBackChoice } from './DstFallBackChoice'
import { DstSprintForwardHint } from './DstSpringForwardHint'
import { TimeInput } from './TimeInput'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 7px;

    &:first-child {
      margin-top: 0px;
    }
  }
`

const DateTimeContainer = styled.div`
  display: flex;
  max-width: ${INPUT_MAX_WIDTH};
  align-items: center;
`

const DateInputContainer = styled.div`
  margin-right: 10px;
  min-width: ${INPUT_MIN_WIDTH};
`

interface IProps {
  value?: number | null
  onChange: (value: number | undefined | null) => void
  timezone?: string
  minDate?: string
  maxDate?: string
  isRequired?: boolean // Set to false if allow to clear the input
  id?: string
}

/** Returns time in seconds from the beginning of the day, accounting for DST transitions. */
export const timeFromTs = (ts: number, timezone: string): number => {
  const startOfDay = moment.unix(ts).tz(timezone).startOf('day')
  const momentAtTs = moment.unix(ts).tz(timezone)
  return momentAtTs.diff(startOfDay, 'seconds') + getDstModifierSeconds(startOfDay, momentAtTs)
}

/** Returns the number of seconds added or lost by DST transitions happening between `earlier` and `later`. */
const getDstModifierSeconds = (earlier: Moment, later: Moment): number => {
  if (earlier.isDST() === later.isDST()) {
    return 0
  }
  return earlier.isDST() ? -HOUR : HOUR
}

export const DateTimeInput = ({
  value,
  onChange,
  timezone = moment.tz.guess(),
  minDate,
  maxDate,
  isRequired = true,
  id,
}: IProps): JSX.Element => {
  const [date, setDate] = useState<string | undefined>(value ? TimezoneHelper.parseDate(value, timezone) : undefined)
  const [time, setTime] = useState<number | undefined>(value ? timeFromTs(value, timezone) : undefined)

  useEffect(() => {
    if (value) {
      setDate(TimezoneHelper.parseDate(value, timezone))
      setTime(timeFromTs(value, timezone))
    }
  }, [value, timezone])

  useEffect(() => {
    if (date !== undefined && date !== '' && time !== undefined) {
      onChange(TimezoneHelper.buildTimestamp(date, time, timezone))
    } else if (date === '') {
      onChange(null)
    }
  }, [date, time])

  return (
    <Container>
      <DateTimeContainer>
        <DateInputContainer id={id}>
          <DateInput value={date} onChange={setDate} minDate={minDate} maxDate={maxDate} isRequired={isRequired} />
        </DateInputContainer>
        <TimeInput value={time} onChange={setTime} />
      </DateTimeContainer>
      <DstSprintForwardHint date={date} time={time} timezone={timezone} />
      <DstFallBackChoice ts={value} timezone={timezone} onChange={(ts) => onChange(ts)} />
    </Container>
  )
}
