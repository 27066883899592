import { findLastIndex } from 'lodash'

export class AcceptRejectDutyLogic {
  /**
   * @param newRequestId id of the new request being matched to target accept/reject duty
   * @param slots list of slots of an accept/reject duty. *ORDER MATTERS*
   */
  public static requiresSendingOffer(slots: Array<{ requestId: string | null }>, newRequestId: string): boolean {
    // Appending new request slots after an accept/reject duty's last scheduled slot
    // requires sending an offer to the driver
    const acceptedRequestInsertionIndex = slots.findIndex((slot) => slot.requestId === newRequestId)
    const lastPreviouslyScheduledSlotIndex = findLastIndex(slots, (slot) => slot.requestId !== newRequestId)
    return acceptedRequestInsertionIndex > lastPreviouslyScheduledSlotIndex
  }
}
