import { AddressFormatter, IAddress } from '@sparelabs/address'
import { WebColors } from '@sparelabs/colors'
import { borderRadius, useTryAsync as useTryAsyncWithHandler } from '@sparelabs/web-ui-components'
import { isEmpty } from 'lodash'
import React, { DependencyList } from 'react'
import { NoneField } from 'src/components/fields/NoneField'
import { ErrorHandler } from 'src/helpers'
import { AuthenticationHelper } from 'src/helpers/AuthenticationHelper'
import styled from 'styled-components'

const AddressContainer = styled.div`
  max-width: 500px;
  background-color: ${WebColors.backgroundPrimary};
  border: 1px solid ${WebColors.borderPrimary};
  border-radius: ${borderRadius};
  box-shadow: 0 0 10px rgba(212, 212, 212, 0.5);
  padding: 16px;
`

const AddressTextContainer = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

const Address = styled.div`
  color: ${WebColors.contentSecondary};
  font-size: 15px;
`

interface IAddressViewProps<ValueType> {
  value: ValueType
}

export const AddressView = ({ value: unformattedAddress }: IAddressViewProps<Partial<IAddress>>): JSX.Element => {
  const addressFormatter = new AddressFormatter(AuthenticationHelper.getRegionalHost(), window.navigator.language)
  const { value: formattedAddress } = useTryAsync(async () =>
    unformattedAddress?.country ? addressFormatter.format(unformattedAddress as IAddress) : undefined
  )

  return formattedAddress ? (
    <AddressContainer>
      <AddressTextContainer>
        {formattedAddress.map((address) => (!isEmpty(address) ? <Address key={address}>{address}</Address> : null))}
      </AddressTextContainer>
    </AddressContainer>
  ) : (
    <NoneField />
  )
}

const useTryAsync = <T,>(callback: () => Promise<T>, dependencies?: DependencyList) =>
  useTryAsyncWithHandler<T>((error) => ErrorHandler.handleError(error), callback, dependencies)
