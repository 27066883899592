import { Item } from '@radix-ui/react-toggle-group'
import { WebColors } from '@sparelabs/colors'
import React from 'react'
import styled from 'styled-components'
import { borderRadius } from '../util'
import { ITitledFigureProps, TitledFigure } from './TitledFigure'

export interface ISummaryBarItemProps<T extends string> extends ITitledFigureProps {
  value: T
}

const StyledItem = styled(Item)`
  padding 8px 16px;
  border: none;
  border-right: 1px solid ${WebColors.borderPrimary};
  background-color: ${WebColors.backgroundPrimary};
  flex: 1;
  &:first-child {
    border-top-left-radius: ${borderRadius};
    border-bottom-left-radius: ${borderRadius};
  }
  &:last-child {
    border-top-right-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
    border-right: none;
  }
  &:hover {
    background-color: ${WebColors.interactiveLowContrast};
  }
  &:hover, &:focus, &[data-state='on']{
    box-shadow: inset 0px 0px 0px 2px ${WebColors.interactivePrimary};
    outline: none;
  }
`

export const SummaryBarItem = <T extends string>(props: ISummaryBarItemProps<T>): JSX.Element => (
  <StyledItem value={props.value} aria-label={props.title}>
    <TitledFigure {...props} />
  </StyledItem>
)
