import { ICustomFieldResponse } from '@sparelabs/api-client'
import { FormContentStage, FormUtils, ICaseFormResponse, IPublicEngageFormResponse } from '@sparelabs/engage-client'
import { sortBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { EngageClientBuilder } from 'src/api/ApiClientBuilders'
import { FormValidator, isCustomField, withErrorHandler } from 'src/helpers'
import { AuthenticationHelper } from 'src/helpers/AuthenticationHelper'
import { st } from 'src/locales/TranslationHelper'
import { useFormContext } from '../FormContext'
import { FormSubmitted } from '../FormStepTypes'
import { AlreadySubmitted } from './FormInput/AlreadySubmitted'
import { FormInputForm, IFormField } from './FormInput/FormInputForm'

const getCustomFields = (formFields: IFormField[]): ICustomFieldResponse[] =>
  formFields.filter((formItem) => isCustomField(formItem)) as ICustomFieldResponse[]

const organizeFields = (form: IPublicEngageFormResponse): IFormField[] => {
  const formContent = form.formContents.filter((content) => content.stage === FormContentStage.Default)
  return sortBy([...form.customFields, ...formContent], (field) => field.order)
}

export const FormInputStep = (): JSX.Element => {
  const { caseId, form, formSubmitted, prevCaseForm, setCaseForm, onNextStep } = useFormContext()
  const formFields = useMemo(() => organizeFields(form), [form])
  const [showAlreadySubmitted, setShowAlreadySubmitted] = useState(formSubmitted === FormSubmitted.Previously)
  const [showValidation, setShowValidation] = useState<boolean>(false)

  if (showAlreadySubmitted) {
    return (
      <AlreadySubmitted
        isPublicForm={!FormUtils.isAuthRequired(form)}
        onUpdateClick={() => setShowAlreadySubmitted(false)}
      />
    )
  }

  const handleSubmit = withErrorHandler(async (metadata: Record<string, unknown>) => {
    setShowValidation(true)
    if (!FormValidator.areCustomFieldsValid(metadata, getCustomFields(formFields))) {
      toast(st.validation.invalidFields())
      return
    }

    let result
    if (FormUtils.isAuthRequired(form)) {
      result = await updateOrCreateCaseForm(form, metadata, caseId, prevCaseForm?.id)
    } else {
      await updateOrCreatePublicCaseForm(form, metadata, caseId)
    }

    setCaseForm(result, FormSubmitted.ThisSession)
    onNextStep()
  })

  return <FormInputForm fields={formFields} onSubmit={handleSubmit} showValidation={showValidation} />
}

async function updateOrCreateCaseForm(
  form: IPublicEngageFormResponse,
  metadata: Record<string, unknown>,
  caseId?: string,
  caseFormId?: string
): Promise<ICaseFormResponse> {
  const userId = AuthenticationHelper.getUserId()
  if (!AuthenticationHelper.isAuthenticated() || !userId) {
    throw new Error(st.errors.authRequired())
  }

  // Patch existing case form
  const client = EngageClientBuilder.build()
  if (caseFormId) {
    return client.caseForms.patch(caseFormId, { metadata })
  }

  // Ensure a case exists
  if (!caseId) {
    const caseData = await client.cases.post({
      caseTypeId: form.caseTypeId,
      userId,
      note: null,
    })
    caseId = caseData.id
  }

  // Create new case-form
  return client.caseForms.post({ caseId, formId: form.id, metadata })
}

async function updateOrCreatePublicCaseForm(
  form: IPublicEngageFormResponse,
  metadata: Record<string, unknown>,
  caseId?: string
): Promise<void> {
  if (!caseId) {
    throw new Error(st.errors.missingCaseId())
  }

  const client = EngageClientBuilder.build()
  await client.public.postCaseForm({ formId: form.id, caseId, metadata })
}
