import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IAutocompleteSuggestionsResponse, IGetAutocompleteSuggestionsQueryParams, Resources } from '../types'

export class AutocompleteSuggestionsResource extends GenericResource<
  IAutocompleteSuggestionsResponse,
  IGetAutocompleteSuggestionsQueryParams,
  never,
  never
> {
  constructor(agent: AxiosInstance) {
    super(Resources.AutocompleteSuggestions, agent)
  }

  public async get(params: IGetAutocompleteSuggestionsQueryParams): Promise<IAutocompleteSuggestionsResponse[]> {
    const res = await this.agent.get<IAutocompleteSuggestionsResponse[]>(this.resource, { params })
    return res.data
  }
}
