import { isObject } from '@sparelabs/core'
import { toString, uniq } from 'lodash'
import { IDifferenceValue } from './DifferenceValue'

export type IDifferenceRecord<T> = {
  [key in keyof T]?: T[key] | IDifferenceValue
}

export class DifferenceFormatter {
  public static format<T extends Record<string, unknown>>(previous: T, current: T): IDifferenceRecord<T> {
    const difference: IDifferenceRecord<T> = {}

    const keys = uniq([...Object.keys(previous), ...Object.keys(current)]) as Array<keyof T>

    for (const key of keys) {
      const previousValue = this.toString(previous[key])
      const currentValue = this.toString(current[key])

      if (this.isDifferent(previousValue, currentValue)) {
        difference[key] = {
          previous: previousValue,
          current: currentValue,
        }
      } else {
        difference[key] = previous[key]
      }
    }

    return difference
  }

  public static toString(value: unknown): string {
    if (isObject(value)) {
      return JSON.stringify(value, null, 2)
    } else if (Array.isArray(value)) {
      return value.map((obj) => (isObject(obj) ? JSON.stringify(obj) : toString(obj))).join(', ')
    }
    return toString(value)
  }

  public static isDifferent(previous: string, current: string): boolean {
    return previous.trim() !== current.trim()
  }
}
