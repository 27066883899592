import { DAY } from '@sparelabs/time'
import { padStart } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { st } from '../../locales/TranslationHelper'
import { IInputProps } from './InputTypes'
import { StyledInput } from './styled/StyledInput'

const TextContainer = styled.span`
  width: 50%;
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
`
export const MINUTE = 60
export const HOUR = 60 * MINUTE

const padZeroes = (value: number): string => padStart(`${value}`, 2, '0')

const tsToHours = (ts: number): number => Math.floor((ts % DAY) / HOUR)
const tsToMinutes = (ts: number): number => Math.floor(((ts % DAY) % HOUR) / MINUTE)
export const fromTsToHHMM = (ts: number): string => `${padZeroes(tsToHours(ts))}:${padZeroes(tsToMinutes(ts))}`

export const fromHHMMtoTs = (hhmm: string, mode: TimeInputMode = TimeInputMode.StartOfDay): number => {
  const hhmmArray = hhmm.split(':')

  if (mode === TimeInputMode.EndOfDay && hhmmArray[0] === '00' && hhmmArray[1] === '00') {
    return DAY
  }

  return parseInt(hhmmArray[0], 10) * HOUR + parseInt(hhmmArray[1], 10) * MINUTE
}

export enum TimeInputMode {
  StartOfDay = 'startOfDay',
  EndOfDay = 'endOfDay',
}

export interface ITimeInput extends IInputProps<number> {
  onChange: (value?: number) => void
  value?: number
  text?: string
  id?: string
  midnightMode?: TimeInputMode
}

export const TimeInput = ({
  value,
  invalid,
  onChange,
  text,
  id,
  midnightMode,
  ...inputAttributes
}: ITimeInput): JSX.Element => {
  const timeFormatted = value !== undefined ? fromTsToHHMM(value) : undefined

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <StyledInput
        {...inputAttributes}
        id={id}
        aria-label={st.inputs.dateTime.selectTimeLabel()}
        role='textbox'
        type='time'
        invalid={invalid}
        value={timeFormatted}
        aria-valuetext={timeFormatted ? st.inputs.dateTime.accessibleTimeHint({ time: timeFormatted }) : ''}
        onChange={(event) => {
          if (event.target.value) {
            onChange(fromHHMMtoTs(event.target.value, midnightMode))
          }
          /**
           * TODO: Add a way to clear the input. This will require auditing
           * admin panel's use of this component to make sure `validate: ['required']`
           * exists on every time and datetime field that should have it.
           */
        }}
      />
      {text && <TextContainer>{text}</TextContainer>}
    </div>
  )
}
