import { ITranslationStrings } from '@sparelabs/translate'
import { ILocaleTranslationKeys } from './keys'

const en: ITranslationStrings<ILocaleTranslationKeys> = {
  common: {
    back: 'Back',
    continue: 'Continue',
    none: 'None',
    submit: 'Submit',
    tryAgain: 'Try Again',
  },
  customFields: {
    toggleButtonYes: 'Yes',
    toggleButtonNo: 'No',
  },
  errors: {
    authRequired: 'This action requires authentication.',
    defaultMessage: 'Something went wrong while rendering the form. Please try again.',
    missingCaseId: 'Invalid form url. Please retry the link or contact the organization for assistance.',
    noContent: 'No content found.',
    noPermission: 'You do not have permission to access this form.',
    pageNotFound: 'Page not found',
    imageNotUploaded:
      'Image cannot be viewed as something went wrong while uploading. Please try uploading the image again.',
  },
  forms: {
    acceptTerms: {
      part1: "By continuing, you agree to Spare's ",
      linkTermsOfUseText: 'Terms of Use',
      part2: ' and ',
      linkPrivacyPolicyText: 'Privacy Policy',
      punctuation: '.',
    },
    auth: {
      codeLabel: 'Verification Code',
      codePrompt: 'We sent you a text message with a verification code. Please enter the code below.',
      phoneNumberLabel: 'Phone Number',
      phoneNumberPrompt: 'To get started, please enter your phone number.',
      trouble: 'Having trouble receiving the code?',
      resendVoice: 'Call me with the code',
      resendVoiceDisabled: 'Call me with the code in {{seconds}}s',
      resendSms: 'Text me the code',
      resendSmsDisabled: 'Text me the code in {{seconds}}s',
    },
    pageLayout: {
      headerSubtitle: 'by {{orgName}}',
      formNotYetSubmitted: 'This form hasn’t been submitted yet.',
    },
    submission: {
      successMessage: 'Thank you, we’ve received your response.',
      infoMessage: 'This form has already been submitted.',
      privacyMessage: 'To preserve privacy, the content of the previous submission cannot be shown.',
      updateInfo: {
        part1: 'If you need to update the info you’ve provided, you can ',
        linkStartOverText: 'fill out the form again',
        punctuation: '.',
      },
      updateOverwriteWarning: 'This will overwrite the previous submission.',
    },
    riderProfile: {
      infoPrompt: 'Please ensure your profile information is entered correctly.',
      firstNameLabel: 'First Name',
      lastNameLabel: 'Last Name',
      emailLabel: 'Email',
      photoLabel: 'Photo',
    },
    specialist: {
      welcomeMessage:
        'This form is for medical specialists and practitioners only. If you are the applicant, please provide your doctor or specialist the link to this form to fill out.',
    },
  },
  validation: {
    invalid: 'Invalid {{label}}',
    invalidFields: 'Please check over the form submission again and ensure your inputs are correct.',
    invalidPhoneNumber: 'Please enter a valid phone number with the country code. Example: +16045342342',
    maxLength: '{{label}} must have less than {{maxLength}} characters',
    required: '{{label}} is required',
    value: 'Value',
  },
}

// eslint-disable-next-line import/no-default-export
export default en
