import { SlotStatus, SlotType, Window } from '@sparelabs/domain-logic'
import { IPoint } from '@sparelabs/geography'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { IRequestAccessibilityFeature } from './AccessibilityTypes'
import { Currency } from './CurrencyTypes'
import { IFareRedemptionStub } from './FareRedemptionTypes'
import { IFareStub } from './FareTypes'
import { IPaymentMethodTypeStub } from './PaymentMethodTypeTypes'
import { IPaymentMethodResponse } from './PaymentTypes'
import { IRequestRiderType } from './RequestRiderTypes'
import { RequestStatus } from './RequestTypes'
import { IUserStub } from './UserTypes'
/**
 * @deprecated Use SlotType from @sparelabs/domain-logic instead
 */
export { SlotType, SlotStatus }

export interface ISlotResponse extends IModelJsonValues, ISlotRequestInformation {
  dutyId: string
  type: SlotType
  requestedTs: number
  requestedFlexibility: number
  scheduledTs: number
  status: SlotStatus
  requestedAddress: string
  requestedLocation: IPoint
  scheduledStopId?: string | null
  scheduledAddress: string | null
  scheduledLocation: IPoint | null
  walkingDistance: number /* meters */
  walkingDuration: number /* seconds */
  completedTs: number | null
  completedLocation: IPoint | null
  completedAddress: string | null
  cancelledTs: number | null
  arrivedTs: number | null
  anonymized: boolean
  metadata: Record<string, unknown>
  eta: number | null
  request: ISlotRequestStub | null
  fareRedemptions: IFareRedemptionStub[]
  driveDuration: number | null
  driverBreakId: string | null
  startedTs: number | null
  stop: {
    internalDescription: string | null
  }
  service: ISlotServiceStub | null
}

/** @deprecated - the following request fields are deprecated in favor of RequestStub */
export interface ISlotRequestInformation {
  requestId: string | null
  earningAmount?: number
  earningCurrency?: Currency
  rider: IUserStub | null
  requestStatus?: RequestStatus
  requestNotes?: string
  requestNumRiders?: number
  requestRiders?: IRequestRiderType[]
  accessibilityFeatures?: IRequestAccessibilityFeature[]
  pickupWindow?: Window | null
  dropoffWindow?: Window | null
}

export interface ISlotRequestStub {
  id: string
  earningAmount: number
  earningCurrency: Currency
  rider: IUserStub | null
  status: RequestStatus
  notes: string | null
  numRiders: number
  riders: IRequestRiderType[]
  accessibilityFeatures: IRequestAccessibilityFeature[]
  fare: IFareStub
  prepaid: boolean
  paymentMethod: IPaymentMethodResponse | null
  paymentMethodType: IPaymentMethodTypeStub | null
  /**
   * @deprecated Use `paymentMethod.id` instead
   */
  paymentMethodId: string | null
  /**
   * @deprecated Use `paymentMethodType.id` instead
   */
  paymentMethodTypeId: string | null
  metadata: Record<string, unknown>
  pickupNotes: string | null
  dropoffNotes: string | null
  pickupWindow?: Window | null
  dropoffWindow?: Window | null
  groupMemberships: ISlotGroupMembershipStub[]
}

export interface ISlotGroupMembershipStub {
  id: string
  groupName: string
  groupDescription: string | null
  metadata: Record<string, unknown>
}

export interface ISlotServiceStub {
  id: string
  name: string
  allowDriverToEditRequestPaymentMethod: boolean
}

export interface ISlotQueryParams extends IListModelQueryParamValues {
  requestStatus?: RequestStatus | RequestStatus[]
  status?: SlotStatus | SlotStatus[]
  dutyId?: string
  fromRequestedTs?: number
  toRequestedTs?: number
  type?: SlotType
}

export interface ISlotCompletePostBody {
  location: IPoint
  completionTs: number
  completionAddress?: string
}

export interface IRequestSlotResponse {
  pairs: IRequestSlotPair[]
}

export interface IRequestSlotPair {
  dutyId: string
  createdAt: number
  pickup: IRequestSlot
  dropoff: IRequestSlot
}

export interface IRequestSlot {
  initialScheduledTs: number
  scheduledTs: number
}

export interface ISlotPatchBody extends Partial<Pick<ISlotResponse, 'status'>> {}
