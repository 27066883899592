import { GenericResource, IListModelQueryParamValues } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { ITipPolicyPatchBody, ITipPolicyPostBody, ITipPolicyResponse, Resources } from '../types'

export class TipPolicyResource extends GenericResource<
  ITipPolicyResponse,
  IListModelQueryParamValues,
  ITipPolicyPatchBody,
  ITipPolicyPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()
  public del = this.buildDel()

  constructor(agent: AxiosInstance) {
    super(Resources.TipPolicies, agent)
  }
}
