import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IGroupConditionPatchBody,
  IGroupConditionPostBody,
  IGroupConditionQueryParams,
  IGroupConditionResponse,
  Resources,
} from '../types'

export class GroupConditionResource extends GenericResource<
  IGroupConditionResponse,
  IGroupConditionQueryParams,
  IGroupConditionPatchBody,
  IGroupConditionPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()
  public del = this.buildDel()

  constructor(agent: AxiosInstance) {
    super(Resources.GroupConditions, agent)
  }
}
