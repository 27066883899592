import {
  AccessibilityFeature,
  DutyStatus,
  IVehicleAccessibilityFeature,
  IVehicleOccupancySpot,
  MatchingEndpoint,
  OffsetDirection,
  RequestIntentType,
  SlotType,
  TimeConstraintType,
  VehicleCapacityType,
} from '@sparelabs/domain-logic'
import { IPoint } from '@sparelabs/geography'
import { IRequestAccessibilityFeature } from './AccessibilityTypes'
import { RequestStatus } from './RequestTypes'
import { ServiceFleetPriority } from './ServiceFleetTypes'
import { MatchingPriority } from './ServiceTypes'

export interface IRouteOptimizationGlobalOptimizationRequest {
  supply: IRouteOptimizationGlobalOptimizationSupply
  demand: IRouteOptimizationGlobalOptimizationDemand
  options: IRouteOptimizationGlobalOptimizationOptions
}

export interface IRouteOptimizationGlobalOptimizationResponse {
  initialMetrics: IRouteOptimizationSolutionMetrics
  solutionMetrics: IRouteOptimizationSolutionMetrics
  modifiedDuties: IRouteOptimizationModifiedDuty[]
  completedRequestIds: string[]
  rejectedRequestIds: string[]
  generatedOverrides: IRouteOptimizationGeneratedOverride[]
}

export interface IRouteOptimizationMatchRequestRequest {
  matchedRequests: IRouteOptimizationRequest[]
  assignedRouteStops: IRouteOptimizationAssignedRouteStop[]
  breaks: IRouteOptimizationBreak[]
  requestToMatch: IRouteOptimizationRequestToMatch
  duties: IRouteOptimizationDuty[]
  vehicles: IRouteOptimizationVehicle[]
  fleets: IRouteOptimizationFleet[]
  drivingSpeedMultiplier: number
  optimizationStartTs: number
}

export interface IRouteOptimizationMatchRequestResponse {
  initialMetrics: IRouteOptimizationSolutionMetrics
  solutionMetrics: IRouteOptimizationSolutionMetrics
  modifiedDuty: IRouteOptimizationModifiedDuty
  generatedOverrides: IRouteOptimizationGeneratedOverride[]
}

export interface IRouteOptimizationOptimizeDutyRequest {
  duty: IRouteOptimizationDuty
  vehicle: IRouteOptimizationVehicle
  requests: IRouteOptimizationRequest[]
  assignedRouteStops: IRouteOptimizationAssignedRouteStop[]
  breaks: IRouteOptimizationBreak[]
  fleet: IRouteOptimizationFleet
  drivingSpeedMultiplier: number
  optimizationStartTs: number
}

export interface IRouteOptimizationOptimizeDutyResponse {
  duty: IRouteOptimizationModifiedDuty
  initialCost: number
  solutionCost: number
}

export interface IRouteOptimizationModifyDutyRequest {
  duty: IRouteOptimizationDuty
  fleet: IRouteOptimizationFleet
  vehicles: IRouteOptimizationVehicle[]
  requests: IRouteOptimizationRequest[]
  breaks: IRouteOptimizationBreak[]
  assignedRouteStops: IRouteOptimizationAssignedRouteStop[]
  modificationsType: RouteOptimizationDutyModificationsType
  dutyModifications: IRouteOptimizationDutyModifications | undefined
  vehicleModifications: IRouteOptimizationVehicle[] | undefined
  requestModifications: IRouteOptimizationRequestUpdate[] | undefined
  optimizationStartTs: number
  drivingSpeedMultiplier: number
  allowTimeConstraintViolations: boolean
}

export interface IRouteOptimizationModifyDutyResponse {
  modifiedDuty: IRouteOptimizationModifiedDuty
}

export enum RouteOptimizationDutyModificationsType {
  Patch = 'patch',
  Route = 'route',
  Slot = 'slot',
  Vehicle = 'vehicle',
  Request = 'request',
}

export interface IRouteOptimizationPatchDutyModifications {
  vehicleId: string | undefined
  startRequestedTs: number | undefined
  startLocation: IRouteOptimizationLocation | undefined
  endRequestedTs: number | undefined
  endLocation: IRouteOptimizationLocation | undefined
}

export interface IRouteOptimizationRouteDutyModifications {
  newAssignedRouteStops: IRouteOptimizationNewAssignedRouteStop[]
}

export interface IRouteOptimizationSlotDutyModifications {
  slotsToAdd: IRouteOptimizationIntermediateSlot[]
  slotIdsToRemove: string[]
}

export interface IRouteOptimizationRequestUpdate {
  id: string
  numRiders: number
  accessibilityFeatures: IRequestAccessibilityFeature[]
}

export type IRouteOptimizationDutyModifications =
  | IRouteOptimizationPatchDutyModifications
  | IRouteOptimizationRouteDutyModifications
  | IRouteOptimizationSlotDutyModifications

export interface IRouteOptimizationGlobalOptimizationSupply {
  duties: IRouteOptimizationDuty[]
  vehicles: IRouteOptimizationVehicle[]
  fleets: IRouteOptimizationFleet[]
}

export interface IRouteOptimizationGlobalOptimizationDemand {
  requests: IRouteOptimizationRequest[]
  assignedRouteStops: IRouteOptimizationAssignedRouteStop[]
  breaks: IRouteOptimizationBreak[]
}

export interface IRouteOptimizationServiceFleet {
  serviceId: string
  serviceFleetPriority: ServiceFleetPriority
}

export interface IRouteOptimizationFleet {
  id: string
  connectedServices: IRouteOptimizationServiceFleet[]
  systemEfficiencyRatio?: number
  waitVsDetourTimeRatio?: number
}

export interface IRouteOptimizationVehicle {
  id: string
  capacityType: VehicleCapacityType
  spots?: IVehicleOccupancySpot[]
  filoFeatures?: AccessibilityFeature[]
  passengerSeats?: number
  accessibilityFeatures?: IVehicleAccessibilityFeature[]
}

interface IRouteOptimizationRequestBase {
  id: string
  serviceId: string
  status: RequestStatus
  numRiders: number
  accessibilityFeatures: IRequestAccessibilityFeature[]
  travelDuration: number
  requestType: MatchingEndpoint
  lockedToDutyId?: string
  disallowedDutyIds: string[]
  priority: MatchingPriority
  enablePooling: boolean
  baseBoardingTimeSeconds: number
  additionalBoardingTimeSeconds: number
}

export interface IRouteOptimizationRequest extends IRouteOptimizationRequestBase {
  constraintsData: IRouteOptimizationConstraintsData
  pickupSlot: IRouteOptimizationRequestSlot
  dropoffSlot: IRouteOptimizationRequestSlot
}

export interface IRouteOptimizationRequestToMatch extends IRouteOptimizationRequestBase {
  requestedPickupLocation: IRouteOptimizationLocation
  requestedDropoffLocation: IRouteOptimizationLocation
  potentialEndpoints: IRouteOptimizationEndpointPair[]
  constraintsData: IRouteOptimizationConstraintsDataForRequestToMatch
}

export interface IRouteOptimizationEndpointPair {
  pickup: IRouteOptimizationEndpoint
  dropoff: IRouteOptimizationEndpoint
}

export interface IRouteOptimizationEndpoint {
  location: IRouteOptimizationLocation
  stopId?: string
  walkingDistance: number
  walkingDuration: number
}

export interface IRouteOptimizationRequestSlot {
  id: string
  location: IRouteOptimizationLocation
  scheduledTs: number | null
  completedTs: number | null
}

export interface IRouteOptimizationAbsoluteTimeConstraint {
  timeConstraintType: TimeConstraintType.Absolute
  min: number
  desired: number
  max: number
  maxOffset: null
  direction: null
}

export interface IRouteOptimizationRelativeTimeConstraint {
  timeConstraintType: TimeConstraintType.Relative
  min: null
  desired: null
  max: null
  maxOffset: number
  direction: OffsetDirection
}

export type IRouteOptimizationTimeConstraint =
  | IRouteOptimizationAbsoluteTimeConstraint
  | IRouteOptimizationRelativeTimeConstraint

interface IRouteOptimizationLeaveAtRequestConstraints {
  constraintsType: RequestIntentType.LeaveAt
  pickupConstraint: IRouteOptimizationAbsoluteTimeConstraint
  dropoffConstraint: IRouteOptimizationRelativeTimeConstraint
}

interface IRouteOptimizationArriveByRequestConstraints {
  constraintsType: RequestIntentType.ArriveBy
  pickupConstraint: IRouteOptimizationTimeConstraint
  dropoffConstraint: IRouteOptimizationAbsoluteTimeConstraint
}

export type IRouteOptimizationRequestConstraints =
  | IRouteOptimizationLeaveAtRequestConstraints
  | IRouteOptimizationArriveByRequestConstraints

export interface IRouteOptimizationRealTimeRequestConstraints {
  constraintsType: RequestIntentType
  pickupConstraint: IRouteOptimizationTimeConstraint
  dropoffConstraint: IRouteOptimizationTimeConstraint
}

export interface IRouteOptimizationConstraintsDataForRequestToMatch {
  basicConstraints: IRouteOptimizationRequestConstraints
  pickupOverride: IRouteOptimizationRequestOverride | null
  dropoffOverride: IRouteOptimizationRequestOverride | null
}

export interface IRouteOptimizationConstraintsData extends IRouteOptimizationConstraintsDataForRequestToMatch {
  pickupScheduledTs: number | null
  dropoffScheduledTs: number | null
  pickupCompletedTs: number | null
  disableBumpingToEarlierProtection: boolean | null
  disableBumpingToLaterProtection: boolean | null
}

export enum RouteOptimizationRequestOverrideType {
  Estimate = 'estimate',
  Absolute = 'absolute',
  Relative = 'relative',
  DutyEnd = 'dutyEnd',
}

export enum RouteOptimizationDutyOverrideType {
  Estimate = 'estimate',
  Request = 'request',
}

export type IRouteOptimizationRequestOverride =
  | IRouteOptimizationEstimateOverride
  | IRouteOptimizationAbsoluteOverride
  | IRouteOptimizationRelativeOverride

export interface IRouteOptimizationEstimateOverride {
  overrideType: RouteOptimizationRequestOverrideType.Estimate
  maxOverrideDuration: number
}

export interface IRouteOptimizationAbsoluteOverride {
  overrideType: RouteOptimizationRequestOverrideType.Absolute
  scheduledTs: number
}

export interface IRouteOptimizationRelativeOverride {
  overrideType: RouteOptimizationRequestOverrideType.Relative
  newTimeInVehicle: number
}

export type IRouteOptimizationDutyEndOverride =
  | IRouteOptimizationDutyEstimateOverride
  | IRouteOptimizationDutyRequestOverride

export interface IRouteOptimizationDutyEstimateOverride {
  overrideType: RouteOptimizationDutyOverrideType.Estimate
  maxOverrideDuration: number
}

export interface IRouteOptimizationDutyRequestOverride {
  overrideType: RouteOptimizationDutyOverrideType.Request
  scheduledTs: number
}

export interface IRouteOptimizationGeneratedOverride {
  overrideType:
    | RouteOptimizationRequestOverrideType.Absolute
    | RouteOptimizationRequestOverrideType.Relative
    | RouteOptimizationRequestOverrideType.DutyEnd
  slotType?: SlotType.Pickup | SlotType.Dropoff
  requestId?: string
  dutyId?: string
  scheduledTs?: number
  newTimeInVehicle?: number
  endRequestedTs?: number
  constraint?: IRouteOptimizationTimeConstraint
}

export interface IRouteOptimizationSolutionMetrics {
  solverCost: number
  unmatchedRequestCosts: Array<{ requestId: string; cost: number }>
  completedRequests: number
  rejectedRequests: number
  completedBoardings: number
  numDuties: number
  fleetEfficiencyMetrics: IRouteOptimizationFleetEfficiencyMetrics
  riderExperienceMetrics?: IRouteOptimizationRiderExperienceMetrics
  numIterationsPerformed?: number
}

export interface IRouteOptimizationFleetEfficiencyMetrics {
  totalVehicleSeconds: number
  totalDrivingSeconds: number
  totalDutyLatenessSeconds: number
  totalDrivingMeters: number
  totalDeadheadMeters: number
  pooledRequestPercentage: number
}

export interface IRouteOptimizationRiderExperienceMetrics {
  detourSeconds: IRouteOptimizationMetricDistribution
  distanceFromDesiredSeconds: IRouteOptimizationMetricDistribution
  numberOnTimeRequests: number
  numberLateRequests: number
}

export interface IRouteOptimizationMetricDistribution {
  min: number
  mean: number
  max: number
}

export type RouteOptimizationDutyStatus = Extract<DutyStatus, DutyStatus.Scheduled | DutyStatus.InProgress>

export interface IRouteOptimizationDuty {
  id: string
  fleetId: string
  vehicleId: string
  start: IRouteOptimizationStartLocationSlot
  stops: IRouteOptimizationIntermediateSlot[]
  end?: IRouteOptimizationEndLocationSlot
  endRequestedTs?: number
  status: RouteOptimizationDutyStatus
  slotIdsRecentlyVisited: string[]
  shouldOptOutOfBinLocking: boolean
  dutyEndOverride?: IRouteOptimizationDutyEndOverride
}

export interface IRouteOptimizationModifiedDuty extends IRouteOptimizationDuty {
  scheduleLegs: IDutyScheduleLeg[]
}

/**
 * An IDutyScheduleLeg exists between two bins of slots, but is focussed on the bin at the start of the leg
 */
export interface IDutyScheduleLeg {
  /**
   * The slots that are at the START of the leg
   */
  slots: Array<{ id: string; scheduledTs: number }>

  /**
   * The duration spent at the bin at the start of the leg. For example, if it's a bin of request slots, this is the
   * bin boarding time, while if it's a bin of a single break slot, it's the break duration
   */
  binDuration: number

  /**
   * The amount of driving time between this bin and the next bin
   */
  binDrivingDuration: number

  /**
   * The amount of idle time between this bin and the next bin. This idle time could occur at the start of the leg or
   * at the end of the leg. Realistically it's most likely to occur when no riders are on board:
   *  - If the start bin is a pickup, the end bin a dropoff, and after that dropoff the vehicle is empty, idle time
   *    probably happens at the end of the bin
   *  - While if the start bin ends with an empty vehicle (dropoff, break, etc.), and the next bin is a pickup, idle
   *    could happen at either the start of the end of the bin, but probably at the start
   *
   * Idle time is really the time between the start of this bin and the start of the next bin, that isn't taken up in
   * binDuration or binDrivingDuration.
   */
  binIdleDuration: number
}

export interface IRouteOptimizationBaseSlot {
  id: string
  slotType: SlotType
  location: IRouteOptimizationLocation
  scheduledTs: number
}

/**
 * Start location slots can be of two kinds:
 * - when a duty is scheduled it is the actual start of the duty
 * - when a duty is in-progress it is a vehicle location
 * In the latter case we have a bearing in addition to coordinates, and that bearing can have
 * a large impact on the routing data when for instance the vehicle happens to be on a highway.
 */
export interface IRouteOptimizationStartLocationSlot extends IRouteOptimizationBaseSlot {
  slotType: SlotType.StartLocation
  bearing: number | null
}

export interface IRouteOptimizationEndLocationSlot extends IRouteOptimizationBaseSlot {
  slotType: SlotType.EndLocation
}

export interface IRouteOptimizationPickupSlot extends IRouteOptimizationBaseSlot {
  slotType: SlotType.Pickup
  requestId: string
}

export interface IRouteOptimizationDropoffSlot extends IRouteOptimizationBaseSlot {
  slotType: SlotType.Dropoff
  requestId: string
}

export interface IRouteOptimizationRouteStopSlot extends IRouteOptimizationBaseSlot {
  slotType: SlotType.RouteStop
}

export interface IRouteOptimizationDriverBreakSlot extends IRouteOptimizationBaseSlot {
  slotType: SlotType.DriverBreak
  startedTs?: number
  breakId: string
}

export type IRouteOptimizationMatchedRequestSlot = IRouteOptimizationPickupSlot | IRouteOptimizationDropoffSlot

export type IRouteOptimizationIntermediateSlot =
  | IRouteOptimizationMatchedRequestSlot
  | IRouteOptimizationRouteStopSlot
  | IRouteOptimizationDriverBreakSlot

export type IRouteOptimizationTerminalSlot = IRouteOptimizationStartLocationSlot | IRouteOptimizationEndLocationSlot

export type IRouteOptimizationSlot = IRouteOptimizationIntermediateSlot | IRouteOptimizationTerminalSlot

export interface IRouteOptimizationClassifiedSlot {
  slotType: SlotType
  requestId?: string
}

export const isTerminalSlot = (slot: IRouteOptimizationClassifiedSlot): slot is IRouteOptimizationTerminalSlot =>
  slot.slotType === SlotType.StartLocation || slot.slotType === SlotType.EndLocation

export const isMatchedRequestSlot = (
  slot: IRouteOptimizationClassifiedSlot
): slot is IRouteOptimizationMatchedRequestSlot =>
  slot.slotType === SlotType.Pickup || slot.slotType === SlotType.Dropoff

export const isIntermediateSlot = (
  slot: IRouteOptimizationClassifiedSlot
): slot is IRouteOptimizationIntermediateSlot =>
  isMatchedRequestSlot(slot) || slot.slotType === SlotType.RouteStop || slot.slotType === SlotType.DriverBreak

export const getRequestId = (slot: IRouteOptimizationClassifiedSlot): string | undefined =>
  isMatchedRequestSlot(slot) ? slot.requestId : undefined

export const getAllSlots = (duty: IRouteOptimizationDuty): IRouteOptimizationBaseSlot[] => [
  duty.start,
  ...duty.stops,
  ...(duty.end ? [duty.end] : []),
]

export interface IRouteOptimizationAssignedRouteStop {
  id: string
  dutyId: string
  location: IRouteOptimizationLocation
  basicConstraint: IRouteOptimizationAbsoluteTimeConstraint
}

export interface IRouteOptimizationNewAssignedRouteStop extends IRouteOptimizationAssignedRouteStop {
  scheduledTs: number
}

export interface IRouteOptimizationBreak {
  id: string
  dutyId: string
  duration: number
  basicConstraint: IRouteOptimizationAbsoluteTimeConstraint
}

export interface IRouteOptimizationLocation {
  location: IPoint
  address: string | null
}

export interface IRouteOptimizationSlotPair {
  fromSlotId: string
  toSlotId: string
}

export interface IRouteOptimizationInvalidReason {
  code: RouteOptimizationInvalidReasonCode
  message: string
}

export enum RouteOptimizationInvalidReasonCode {
  TravelTimeViolation = 'TRAVEL_TIME_VIOLATION',
  AbsoluteTimeConstraintViolation = 'ABSOLUTE_TIME_CONSTRAINT_VIOLATION',
  RelativeTimeConstraintViolation = 'RELATIVE_TIME_CONSTRAINT_VIOLATION',
  PassengerSeatViolation = 'PASSENGER_SEAT_VIOLATION',
  AccessibilityFeatureViolation = 'ACCESSIBILITY_FEATURE_VIOLATION',
  FiloAccessibilityFeatureViolation = 'FILO_ACCESSIBILITY_FEATURE_VIOLATION',
  NoReturnToPickupOrDropoffViolation = 'NO_RETURN_TO_PICKUP_OR_DROPOFF_VIOLATION',
  SlotOrderingViolation = 'SLOT_ORDERING_VIOLATION',
  ServiceConstraintViolation = 'SERVICE_CONSTRAINT_VIOLATION',
  ScheduledTimeViolation = 'SCHEDULED_TIME_VIOLATION',
}

export interface IRouteOptimizationGlobalOptimizationOptions {
  /** Lets you make driving speeds faster or slower than routing/traffic data would otherwise suggest */
  drivingSpeedMultiplier: number

  /** Should Global Optimization stop running once all requests in the problem are matched? */
  stopOnceAllRequestsMatched: boolean

  /** Timestamp when you made the call - useful for things like reproducible replays and other */
  optimizationStartTs: number

  /**
   * If we don't stop early for any other reason, how long should Global Optimization run?  Note that Global Optimization will stop at the
   * next "good stopping point" after this amount of time, so calls can exceed this by a wide margin in edge cases.
   */
  targetRuntimeSeconds: number

  /**
   * How many "iterations" of Global Optimization should we run? An iteration is a single solution of the problem, many iterations can be
   * run and the best solution chosen. Read more here: https://github.com/graphhopper/jsprit/blob/master/docs/Meta-Heuristic.md
   *
   * The server will choose a good default if you don't set one.
   */
  maxIterations?: number

  /**
   * Stop if this many "iterations" happen without the solution significantly improving - a good sign that the solution we have is about as
   * good as it's going to get.
   *
   * The server will choose a good default if you don't set one.
   */
  maxIterationsWithoutSignificantImprovement?: number

  /**
   * Focusing optimization on a specific time window makes it perform faster, with a better solution within said time window, and no
   * improvement outside said time window.
   *
   * Defaults to no focus window (optimizes all parts of the problem equally).
   */
  focusWindow?: IRouteOptimizationFocusWindow

  /** Providing a fixed random seed can make global optimization results more predictable */
  randomSeed?: number
}

export interface IRouteOptimizationFocusWindow {
  fromTs: number
  untilTs: number
}
