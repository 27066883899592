import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IGroupMembershipPatchBody,
  IGroupMembershipPostBody,
  IGroupMembershipQueryParams,
  IGroupMembershipResponse,
  Resources,
} from '../types'

export class GroupMembershipResource extends GenericResource<
  IGroupMembershipResponse,
  IGroupMembershipQueryParams,
  IGroupMembershipPatchBody,
  IGroupMembershipPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.GroupMemberships, agent)
  }
}
