import { VehicleClass } from '@sparelabs/geography'
import { AccessibilityFeature } from '../types'

export enum VehicleOccupancySpotOptionType {
  Rider = 'rider',
  Feature = 'feature',
}

interface IBaseVehicleOccupancySpotOption {
  type: VehicleOccupancySpotOptionType
  count: number
  canCommingle: boolean
}

export interface IVehicleOccupancySpotFeatureOption extends IBaseVehicleOccupancySpotOption {
  type: VehicleOccupancySpotOptionType.Feature
  canAccommodateRider: boolean
  accessibilityFeature: AccessibilityFeature
}

export interface IVehicleOccupancySpotRiderOption extends IBaseVehicleOccupancySpotOption {
  type: VehicleOccupancySpotOptionType.Rider
}

export type IVehicleOccupancySpotOption = IVehicleOccupancySpotFeatureOption | IVehicleOccupancySpotRiderOption

export interface IVehicleOccupancySpot {
  options: IVehicleOccupancySpotOption[]
  description?: string
}

export interface IVehicleType {
  id: string
  createdAt: number
  updatedAt: number
  name: string
  spots: IVehicleOccupancySpot[]
  preDutyTemplateId: string | null
  postDutyTemplateId: string | null
  filoFeatures: AccessibilityFeature[]
  vehicleClass: VehicleClass
}
