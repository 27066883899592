// Shared HEX codes for colors used across all Spare products
// HEX codes should only go in THIS file; others should reference this file
export const colors = {
  // WHITE & BLACK
  white: '#FFFFFF',
  black: '#000000',
  // GRAY
  gray05: '#f5f5f5',
  gray10: '#e3e3e3',
  gray20: '#d1d1d1',
  gray25: '#bdbdbd',
  gray30: '#adadad',
  gray40: '#8c8c8c',
  gray50: '#707070',
  gray55: '#616161',
  gray60: '#545454',
  gray70: '#3d3d3d',
  gray80: '#303030',
  gray90: '#212121',
  gray95: '#171717',
  // COOL GRAY
  coolGray05: '#f5f6fa',
  coolGray10: '#e1e4ef',
  coolGray20: '#cacfe2',
  coolGray25: '#b5bcd4',
  coolGray30: '#a3abc7',
  coolGray40: '#828cb0',
  coolGray50: '#63709c',
  coolGray55: '#545f87',
  coolGray60: '#485275',
  coolGray70: '#333b57',
  coolGray80: '#282f48',
  coolGray90: '#1c2136',
  coolGray95: '#121626',
  // BLUE
  blue05: '#f0f7ff',
  blue10: '#d1e6ff',
  blue20: '#add1ff',
  blue25: '#94c1ff',
  blue30: '#75aeff',
  blue40: '#3d8aff',
  blue50: '#0062ff',
  blue55: '#0055e6',
  blue60: '#0047c7',
  blue70: '#003499',
  blue80: '#00287a',
  blue90: '#001d5c',
  blue95: '#001442',
  // GREEN
  green05: '#defcf3',
  green10: '#b7f0df',
  green20: '#7de3c5',
  green25: '#39d5a6',
  green30: '#0ac78e',
  green40: '#009e6f',
  green50: '#008059',
  green55: '#00704f',
  green60: '#006144',
  green70: '#004732',
  green80: '#003827',
  green90: '#00291d',
  green95: '#001a12',
  // RED
  red05: '#fff5f7',
  red10: '#ffdbe1',
  red20: '#ffbdc8',
  red25: '#ffa3b3',
  red30: '#ff8a9d',
  red40: '#fa4c69',
  red50: '#e00025',
  red55: '#c70021',
  red60: '#ad001d',
  red70: '#800015',
  red80: '#660011',
  red90: '#4d000d',
  red95: '#380009',
  // YELLOW
  yellow05: '#fff4e0',
  yellow10: '#ffe0ad',
  yellow20: '#ffc66b',
  yellow25: '#ffad2e',
  yellow30: '#fa9600',
  yellow40: '#cc7900',
  yellow50: '#a35f00',
  yellow55: '#8f5200',
  yellow60: '#804800',
  yellow70: '#5c3300',
  yellow80: '#472700',
  yellow90: '#331c00',
  yellow95: '#241300',
  // PURPLE
  purple05: '#f5f5ff',
  purple10: '#e1e0ff',
  purple20: '#cfccff',
  purple25: '#beb8ff',
  purple30: '#ada3ff',
  purple40: '#8d7aff',
  purple50: '#6e52ff',
  purple55: '#5b33ff',
  purple60: '#450fff',
  purple70: '#3200c7',
  purple80: '#2c009e',
  purple90: '#240075',
  purple95: '#1b0052',
  // BROWN
  brown05: '#faf3f0',
  brown10: '#f2e0d9',
  brown20: '#e9cbbe',
  brown25: '#e0b6a3',
  brown30: '#d7a088',
  brown40: '#c77857',
  brown50: '#a85a38',
  brown55: '#914e30',
  brown60: '#7e432a',
  brown70: '#5c311f',
  brown80: '#492718',
  brown90: '#361d12',
  brown95: '#22120b',
  // ACCENT COLORS - TODO swap instances of these to reference tokens from a theme file instead
  pink: '#E47084',
  purple: '#7D57B5',
  // SHADOW SETTINGS - TODO move these elsewhere with other non-color tokens
  opacity20: 0.2,
  opacity80: 0.8,
  radius10: 10,
  radius20: 20,
  // UTILITY COLORS
  mapboxLight: '#DDE4ED',
  mapboxDark: '#4C6782',
  // GRAPH BAR COLORS
  graphBlue70: '#2C475F',
  graphBlue60: '#352C5F',
  graphBlue50: '#3C2D91',
  graphBlue30: '#5137E0',
  graphBlue20: '#2E8DDE',
  graphPink50: '#8036AE',
  graphPink30: '#AF347B',
  graphRed50: '#DE3348',
  graphRed30: '#EE6662',
  graphRed20: '#FE987C',
  graphRed10: '#FED799',
}

/*
JSON configuration file for these colors, generated at https://color-play-2.xxjasonmillerxx.repl.co/
Paste this back into the Color Grid Data field to make edits

{
	"contrastSteps": 13,
	"stepNames": [
		"05",
		"10",
		"20",
		"25",
		"30",
		"40",
		"50",
		"55",
		"60",
		"70",
		"80",
		"90",
		"95"
	],
	"contrasts": [
		"1.09",
		"1.28",
		"1.55",
		"1.86",
		"2.25",
		"3.36",
		"4.95",
		"6.19",
		"7.57",
		"10.86",
		"13.19",
		"15.9",
		"17.92"
	],
	"saturation": [
		"0",
		"50",
		"100",
		"100",
		"100",
		"100",
		"100",
		"60"
	],
	"satAdjust": [
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"-10",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"-20",
		"-35",
		"-35",
		"-35",
		"-10",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"0",
		"-10",
		"-20",
		"-25",
		"-30",
		"-35",
		"-40",
		"-35",
		"-30",
		"-25",
		"-20",
		"-10",
		"0"
	],
	"hueFamilies": 8,
	"hueNames": [
		"gray",
		"brown",
		"red",
		"yellow",
		"green",
		"blue",
		"purple",
		"coolGray"
	],
	"hueStarts": [
		"0",
		"18",
		"350",
		"38",
		"162",
		"212",
		"240",
		"227"
	],
	"hueEnds": [
		"0",
		"18",
		"350",
		"32",
		"162",
		"222",
		"260",
		"227"
	],
	"hueEndEnabled": [
		false,
		false,
		false,
		true,
		false,
		true,
		true,
		false
	],
	"flips": [
		false,
		false,
		true,
		false,
		false,
		false,
		false,
		false
	]
}

*/
