import { WebColors } from '@sparelabs/colors'
import { isNumber } from '@sparelabs/core'
import { HOUR } from '@sparelabs/time'
import moment from 'moment-timezone'
import React from 'react'
import styled from 'styled-components'
import { st } from '../../locales/TranslationHelper'
import { SingleChoiceInput } from './SingleChoiceInput'

const formatFallBackTs = (ts: number, timezone: string): string =>
  `(${moment.unix(ts).tz(timezone).format('h:mm A')}/${moment.unix(ts).tz('UTC').format('H:mm')} UTC)`

const Container = styled.div`
  > * {
    margin-top: 7px;

    &:first-child {
      margin-top: 0px;
    }
  }
`

const Hint = styled.div`
  color: ${WebColors.contentTertiary};
  font-size: 13px;
  line-height: 16px;
`

interface IProps {
  ts: number | undefined | null
  timezone: string
  onChange: (ts: number | undefined) => void
}

export const DstFallBackChoice = ({ ts, timezone, onChange }: IProps): JSX.Element | null => {
  if (!isNumber(ts)) {
    return null
  }

  const time = moment.unix(ts).tz(timezone)
  const isBeforeTimeSetBack = time.hours() === time.clone().add(1, 'hour').hours()
  const isAfterTimeSetBack = time.hours() === time.clone().subtract(1, 'hour').hours()

  if (!isBeforeTimeSetBack && !isAfterTimeSetBack) {
    return null
  }

  const tsBeforeTimeSetBack = isBeforeTimeSetBack ? ts : ts - HOUR
  const tsAfterTimeSetBack = tsBeforeTimeSetBack + HOUR

  return (
    <Container>
      <SingleChoiceInput
        value={ts}
        choices={[
          {
            label: `${st.inputs.dateTime.dst.fallBack.beforeTimeSetBack()} ${formatFallBackTs(
              tsBeforeTimeSetBack,
              timezone
            )}`,
            value: tsBeforeTimeSetBack,
          },
          {
            label: `${st.inputs.dateTime.dst.fallBack.afterTimeSetBack()} ${formatFallBackTs(
              tsAfterTimeSetBack,
              timezone
            )}`,
            value: tsAfterTimeSetBack,
          },
        ]}
        onChange={onChange}
      />
      <Hint>{st.inputs.dateTime.dst.fallBack.hint()}</Hint>
    </Container>
  )
}
