import { PermissionSetType, RiderPermissionSet } from '../sets'
import { convertRBACRoleToPermissionType } from '../utils'

/**
 * Rider role permissions are defined as a set so that we can modify their permissions in one place
 * Do not add a singular permission here, add it to the RiderPermissionSet.Rider set
 */
export const RiderRole: PermissionSetType[] = [RiderPermissionSet.Rider]

export const RiderRolePermissions = convertRBACRoleToPermissionType(RiderRole)
