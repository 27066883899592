import styled from 'styled-components'

export const MapBoxContainer = styled.div`
  .mapboxgl-ctrl-bottom-left,
  .mapboxgl-ctrl-bottom-right {
    display: none !important;
  }

  @media only percy {
    display: none;
  }
`
