import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  ICustomFieldGroupResponse,
  ICustomFieldPatchBody,
  ICustomFieldPostBody,
  ICustomFieldResponse,
  IListCustomFieldsQueryParams,
  INativeObjectsResponse,
  Resources,
} from '../types'

export class CustomSchemaResource extends GenericResource<
  ICustomFieldResponse,
  IListCustomFieldsQueryParams,
  ICustomFieldPatchBody,
  ICustomFieldPostBody
> {
  constructor(agent: AxiosInstance) {
    super(Resources.CustomFields, agent)
  }

  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()

  public async getNativeObjectExtensions(): Promise<INativeObjectsResponse> {
    const res = await this.agent.get<INativeObjectsResponse>('customSchemas/nativeObjectExtensions')
    return res.data
  }

  public async postCustomFieldGroup(): Promise<ICustomFieldGroupResponse> {
    const res = await this.agent.post<ICustomFieldGroupResponse>('customSchemas/fieldGroups')
    return res.data
  }
}
