import { AxiosInstance } from 'axios'
import {
  IDriverDispatchCommsToken,
  IDriverOpsCompleteDutyBody,
  IDriverOpsCompleteSlotBody,
  IDriverRiderCallEventPostBody,
  IDutyResponse,
  ISlotResponse,
} from '../types'

export class DriverOperationsResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async completeSlot(body: IDriverOpsCompleteSlotBody): Promise<ISlotResponse> {
    const res = await this.agent.post<ISlotResponse>('driverOperations/completeSlot', body)
    return res.data
  }

  public async completeDuty(body: IDriverOpsCompleteDutyBody): Promise<IDutyResponse> {
    const res = await this.agent.post<IDutyResponse>('driverOperations/completeDuty', body)
    return res.data
  }

  public async getConversationToken(dutyId: string): Promise<IDriverDispatchCommsToken> {
    const res = await this.agent.post<IDriverDispatchCommsToken>('driverOperations/conversationToken', { dutyId })
    return res.data
  }

  public async recordDriverRiderCall(body: IDriverRiderCallEventPostBody): Promise<void> {
    await this.agent.post<IDriverRiderCallEventPostBody>('driverOperations/riderCallEvent', body)
  }
}
