import { ApiError } from '@sparelabs/error-types'
import { AxiosError } from 'axios'

export const isAxiosError = (error: unknown): error is AxiosError => Boolean((error as AxiosError).isAxiosError)

// Example usage - to conveniently check if something is a 5xx error, `isHttpErrorInRange(error, { min: 500, max: 599 })`
export const isHttpErrorInRange = (error: unknown, range: { min: number; max: number }): boolean => {
  const status: number | undefined =
    isAxiosError(error) &&
    error.response?.status &&
    (typeof error.response.status === 'number' || (error.response.status as any) instanceof Number)
      ? error.response.status
      : error instanceof ApiError
      ? error.status
      : undefined
  return status !== undefined && status >= range.min && status <= range.max
}

// For detecting cases where we don't even get an HTTP response back, there's just some sort of network timeout/connection issue/etc.
export const isNetworkError = (error: unknown): boolean => {
  const { message } = error as Error
  return (
    (typeof message === 'string' || (message as unknown) instanceof String) &&
    (message.includes('connect ETIMEDOUT') ||
      message.includes('connect ECONNREFUSED') ||
      message.includes('socket hang up'))
  )
}
