import Tippy, { TippyProps } from '@tippyjs/react'
import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { followCursor } from 'tippy.js'
// eslint-disable-next-line no-restricted-imports
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/themes/translucent.css'

import 'tippy.js/animations/shift-away.css'

const StyledToolTip = styled(Tippy)<{ noPadding?: boolean }>`
  .tippy-content {
    display: grid;
    place-content: center;
    padding: ${(props) => (props.noPadding ? '0px' : '6.4px 12.8px')};
    line-height: 22.8px;
    font-weight: 400px;
    font-size: 0.95rem;
  }
`

export interface ITooltipProps extends TippyProps {
  noPadding?: boolean
}

export const Tooltip = forwardRef<Element, ITooltipProps>(({ children, ...rest }: ITooltipProps, ref) => (
  <StyledToolTip ref={ref} {...rest} plugins={[followCursor]}>
    {children}
  </StyledToolTip>
))
Tooltip.displayName = 'Tooltip'
