import React, { ReactElement, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { borderRadius, boxShadow } from '../util'
import { TabBarItem, TabBarItemComponent } from './TabBarItem'

export type TabBarContainerComponent = React.ComponentType<
  React.HTMLAttributes<HTMLDivElement> & { backgroundColor?: string }
>

const StyledTabBarContainer: TabBarContainerComponent = styled.div<{ backgroundColor?: string }>`
  box-shadow: ${boxShadow};
  border-radius: ${borderRadius};
  background-color: ${(props) => props.backgroundColor || 'white'};
  display: flex;
  flex-direction: row;
`

export interface ITabDefinition<T extends string> {
  label: string
  key: T
}

export interface ITabBarOption<T> {
  key: T
  label: string
}

interface IProps<T extends string> {
  activeTab: T
  onTabChange: (newActiveTab: T) => void
  tabs: Array<ITabBarOption<T>>
  backgroundColor?: string
  StyledTabItem?: TabBarItemComponent
  StyledContainer?: TabBarContainerComponent
}

interface ITabBarCommonProps<T extends string> extends Omit<IProps<T>, 'onTabChange'> {
  focusedTabIndex: number
  tabIndex: (tab: T) => number
  onClick: (event: React.MouseEvent<HTMLButtonElement>, tab: ITabBarOption<T>) => void
  onKeyboardNavigation: (event: React.KeyboardEvent<HTMLButtonElement>, newIndex: number) => void
  refs: React.MutableRefObject<HTMLButtonElement[]>
}

function TabBarCommon<T extends string>({
  tabs,
  activeTab,
  focusedTabIndex,
  onClick,
  onKeyboardNavigation,
  tabIndex,
  backgroundColor,
  refs,
  StyledTabItem,
  StyledContainer = StyledTabBarContainer,
}: ITabBarCommonProps<T>): ReactElement {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (event.key) {
      case 'ArrowLeft':
        event.persist()
        onKeyboardNavigation(event, Math.max(focusedTabIndex - 1, 0))
        break

      case 'ArrowRight':
        event.persist()
        onKeyboardNavigation(event, Math.min(focusedTabIndex + 1, tabs.length - 1))
        break

      case 'Home':
        event.persist()
        onKeyboardNavigation(event, 0)
        break

      case 'End':
        event.persist()
        onKeyboardNavigation(event, tabs.length - 1)
        break
    }
  }

  return (
    // TODO: the role='tablist' should also be accompanied by an aria-label or aria-labelledby
    // https://www.w3.org/WAI/ARIA/apg/patterns/tabs/
    <StyledContainer backgroundColor={backgroundColor} role='tablist'>
      {tabs.map((tab, index) => (
        <TabBarItem
          key={tab.key}
          id={tab.key}
          ref={(el: HTMLButtonElement) => (refs.current[index] = el)}
          isActive={tab.key === activeTab}
          onClick={(event) => onClick(event, tab)}
          onKeyDown={handleKeyDown}
          tabIndex={tabIndex(tab.key)}
          role='tab'
          aria-selected={tab.key === activeTab}
          StyledTabItem={StyledTabItem}
        >
          {tab.label}
        </TabBarItem>
      ))}
    </StyledContainer>
  )
}

/**
 * A keyboard-navigable tab bar which requires explicit user action to change tabs;
 * navigation does not follow focus.
 * Arrow keys will focus a tab, and Enter or Space will activate it.
 * Use this component when tab panels have a delay before loading/rendering (e.g., data fetch, other async calls),
 * so that keyboard tab navigation is not hindered by unnecessary loads.
 * Use @function AutomaticTabBar instead for tab panels that render instantaneously.
 * See @link https://www.w3.org/WAI/ARIA/apg/practices/keyboard-interface/#kbd_selection_follows_focus for more information.
 */
export function ManualTabBar<T extends string>({
  tabs,
  activeTab,
  onTabChange,
  backgroundColor,
  StyledTabItem,
  StyledContainer,
}: IProps<T>): ReactElement {
  const [focusedTabIndex, setFocusedTabIndex] = React.useState<number>(0)
  const refs = useRef<HTMLButtonElement[]>([])
  const tabKeys = useMemo(() => tabs.map((tab) => tab.key), [tabs])

  const handleKeyboardNavigation = (event: React.KeyboardEvent<HTMLButtonElement>, index: number) => {
    if (index !== focusedTabIndex) {
      event.preventDefault()
      refs.current[index].focus()
      setFocusedTabIndex(index)
    }
  }

  return (
    <TabBarCommon<T>
      tabs={tabs}
      activeTab={activeTab}
      onClick={(event: React.MouseEvent<HTMLButtonElement>, tab: ITabBarOption<T>) => {
        event.preventDefault()
        setFocusedTabIndex(tabKeys.indexOf(tab.key))
        onTabChange(tab.key)
      }}
      tabIndex={(tab) => (tabKeys.indexOf(tab) === focusedTabIndex ? 0 : -1)}
      focusedTabIndex={focusedTabIndex}
      onKeyboardNavigation={handleKeyboardNavigation}
      backgroundColor={backgroundColor}
      refs={refs}
      StyledTabItem={StyledTabItem}
      StyledContainer={StyledContainer}
    />
  )
}

/**
 * A keyboard-navigable tab bar which automatically changes focus as the user navigates.
 * Arrow keys will change focus to another tab and simultaneously activate the tab.
 * Use this component when tab panels are immediately available, rendering instantaneously.
 * It's very important to use @function ManualTabBar instead for tab panels that take time to load/render
 * (e.g., data fetch, other async calls).
 * See @link https://www.w3.org/WAI/ARIA/apg/practices/keyboard-interface/#kbd_selection_follows_focus for more information.
 */
export function AutomaticTabBar<T extends string>({
  tabs,
  activeTab,
  onTabChange,
  backgroundColor,
  StyledTabItem,
  StyledContainer,
}: IProps<T>): ReactElement {
  const [focusedTabIndex, setFocusedTabIndex] = React.useState<number>(0)
  const refs = useRef<HTMLButtonElement[]>([])
  const tabKeys = useMemo(() => tabs.map((tab) => tab.key), [tabs])

  const handleKeyboardNavigation = (event: React.KeyboardEvent<HTMLButtonElement>, index: number) => {
    const target = tabs[index]
    if (target) {
      event.preventDefault()
      refs.current[index].focus()
      setFocusedTabIndex(index)
      onTabChange(target.key)
    }
  }

  return (
    <TabBarCommon<T>
      tabs={tabs}
      activeTab={activeTab}
      onClick={(event: React.MouseEvent<HTMLButtonElement>, tab: ITabBarOption<T>) => {
        event.preventDefault()
        setFocusedTabIndex(tabKeys.indexOf(tab.key))
        onTabChange(tab.key)
      }}
      tabIndex={(tab) => (tab === activeTab ? 0 : -1)}
      focusedTabIndex={focusedTabIndex}
      onKeyboardNavigation={handleKeyboardNavigation}
      backgroundColor={backgroundColor}
      refs={refs}
      StyledTabItem={StyledTabItem}
      StyledContainer={StyledContainer}
    />
  )
}
