import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Announce } from '@radix-ui/react-announce'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { WebColors } from '@sparelabs/colors'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { st } from '../../../locales/TranslationHelper'
import { DropzoneErrors, IDropzoneErrorMessagesProps } from './FileInputTypes'

library.add(faExclamationCircle)

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
`

const ErrorMessage = styled.span`
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 8px;
  color: ${WebColors.negativePrimary};
  font-size: 13px;
  line-height: 20px;
`

const renderErrors = (errors: DropzoneErrors) => {
  if (Array.isArray(errors)) {
    return errors.map((error) => <Error key={error}>{error}</Error>)
  } else if (typeof errors === 'string') {
    return <Error>{errors}</Error>
  }

  return null
}

const Error = ({ children }: { children: ReactNode }) => (
  <Announce role='alert'>
    <ErrorMessage>
      <FontAwesomeIcon icon='exclamation-circle' />
      <VisuallyHidden>{st.inputs.dropzone.error()}</VisuallyHidden>
      {children}
    </ErrorMessage>
  </Announce>
)

export const DropzoneErrorMessages = ({ errors }: IDropzoneErrorMessagesProps): JSX.Element => (
  <Wrapper>{renderErrors(errors)}</Wrapper>
)
