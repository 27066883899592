import { AllReportsModels } from './AnalyzeReportModelTypes'

export enum NTDReportDutyColumns {
  ScheduledVehicleHourS = 'scheduledVehicleHourS',
  ActualVehicleHourS = 'actualVehicleHourS',
  actualVehicleDistanceM = 'actualVehicleDistanceM',
  RevenueHourS = 'revenueHourS',
  RevenueDistanceM = 'revenueDistanceM',

  TotalBreakLengthS = 'totalBreakLengthS',
  TotalBreakDistanceM = 'totalBreakDistanceM',
  PassengerDistanceTraveledM = 'passengerDistanceTraveledM',
}

export enum NTDReportRequestColumns {
  NumCompletedBoardings = 'numCompletedBoardings',
  NumCompletedRequests = 'numCompletedRequests',
  NumCancelledRequests = 'numCancelledRequests',
  NumNoShowRequests = 'numNoShowRequests',
}

export enum NTDReportAdditionalColumns {
  Date = 'date',
}

export const FleetNTDReportModelNamesArray = [
  AllReportsModels.Duty,
  AllReportsModels.Request,
  AllReportsModels.Additional,
]

export const FleetNTDReportModelAndColumns = {
  [AllReportsModels.Duty]: NTDReportDutyColumns,
  [AllReportsModels.Request]: NTDReportRequestColumns,
  [AllReportsModels.Additional]: NTDReportAdditionalColumns,
}

export type IFleetNTDReportColumns = NTDReportDutyColumns | NTDReportRequestColumns | NTDReportAdditionalColumns

export const FleetNTDReportColumnNames = Object.values<IFleetNTDReportColumns>(NTDReportDutyColumns)
  .concat(Object.values(NTDReportRequestColumns))
  .concat(Object.values(NTDReportAdditionalColumns))

export const ServiceNTDReportModelNamesArray = [AllReportsModels.Request, AllReportsModels.Additional]

export const ServiceNTDReportModelAndColumns = {
  [AllReportsModels.Request]: NTDReportRequestColumns,
  [AllReportsModels.Additional]: NTDReportAdditionalColumns,
}

export type IServiceNTDReportColumns = NTDReportRequestColumns | NTDReportAdditionalColumns

export const ServiceNTDReportColumnNames = Object.values<IServiceNTDReportColumns>(NTDReportRequestColumns).concat(
  Object.values(NTDReportAdditionalColumns)
)
