import React from 'react'
import { InputType } from 'reactstrap/lib/Input'
import styled from 'styled-components'
import { CharacterCount, IMaxLengthOption } from './CharacterCount'
import { IInputProps } from './InputTypes'
import { StyledInput } from './styled/StyledInput'

const SpaceContainer = styled.div`
  margin-top: 7px;
`
export interface ITextInputProps extends IInputProps<string> {
  type?: InputType
  label?: string
  placeholder?: string
  disabled?: boolean
  id?: string
  maxLengthOption?: IMaxLengthOption
}

export const TextInput = ({
  value,
  label,
  onChange,
  placeholder,
  invalid,
  type = 'text',
  disabled,
  id,
  autoFocus,
  maxLengthOption,
  ...inputAttributes
}: ITextInputProps): JSX.Element => (
  <>
    <StyledInput
      {...inputAttributes}
      aria-label={label}
      id={id}
      type={type}
      value={value ?? ''}
      invalid={invalid}
      onChange={(event) => onChange(event.target.value)}
      placeholder={placeholder}
      disabled={disabled}
      autoFocus={autoFocus}
    />
    {maxLengthOption && (
      <SpaceContainer>
        <CharacterCount value={value ?? ''} maxLengthOption={maxLengthOption} isCompact={true} />
      </SpaceContainer>
    )}
  </>
)
