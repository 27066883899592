export * from './types'
export * from './client'
export * from './resources'
export * from '@sparelabs/permissions'

export { default as OpenSpec } from './docs/projects/public/Open.oas.json'
/**
 * TODO: We are looking into moving all communication-related endpoints to communication-service
 *
 * once that is done, we can remove this spec
 */
export { default as CommunicationSpecDeprecated } from './docs/projects/internal/CommunicationDeprecated.oas.json'
export { default as AlertingSpec } from './docs/projects/internal/Alerting.oas.json'
export { default as AnalyticsSpec } from './docs/projects/public/Analytics.oas.json'
export { default as LyftSpec } from './docs/projects/internal/Lyft.oas.json'
export { default as UberSpec } from './docs/projects/internal/Uber.oas.json'
export { default as ICabbiSpec } from './docs/projects/internal/ICabbi.oas.json'
export { default as MtiSpec } from './docs/projects/internal/Mti.oas.json'
export { default as UzurvSpec } from './docs/projects/internal/Uzurv.oas.json'
export { default as JagwireSpec } from './docs/projects/internal/Jagwire.oas.json'
export { default as WhitelabelSpec } from './docs/projects/internal/Whitelabel.oas.json'
export { default as AiVoiceSpec } from './docs/projects/internal/AiVoice.oas.json'
export { default as BillingSpec } from './docs/projects/internal/Billing.oas.json'
export { default as EventSpec } from './docs/projects/internal/Event.oas.json'
export const ADMIN_SOCKET_PATH: string = '/v1/socket/admin'
