import { Currency } from '@sparelabs/currency'
import { ChargeStatus } from '@sparelabs/domain-logic'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { ChargeStripeData } from '@sparelabs/stripe'
import { IStripeActionRequired } from './StripeTypes'

/**
 * @deprecated use from @sparelabs/domain-logic instead
 */
export { ChargeStatus }

export type ChargeExternalData = ChargeStripeData | {}

export type ChargeActionRequired = IStripeActionRequired | null

export interface IChargeResponse extends IModelJsonValues {
  amount: number
  currency: Currency
  description: string | null
  status: ChargeStatus
  refundReason: string | null
  userId: string
  paymentMethodId: string
  externalId: string | null
  externalData: ChargeExternalData
  requestId: string | null
  farePassAllocationId: string | null
  actionRequired: ChargeActionRequired
}

export interface IChargeRefundPostBody {
  amount?: number
  refundReason: string
}

export interface IChargeQueryParams extends IListModelQueryParamValues {
  userId?: string
  requestId?: string
  farePassAllocationId?: string
  status?: ChargeStatus
  externalIds?: string[]
}

export interface IChargePostBody {
  amount: number
  currency: Currency
  paymentMethodId: string
}

export interface IChargePatchBody {
  amount: number
}

export interface IChargeStub {
  id: string
  externalId: string | null
  status: ChargeStatus
}
