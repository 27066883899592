import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance, AxiosResponse } from 'axios'
import {
  DataImportResult,
  IFeatureFlagListQueryParams,
  IFeatureFlagsResponse,
  ImportDetailsResponse,
  Resources,
} from '../types'

export class FeatureFlagResource extends GenericResource<
  IFeatureFlagsResponse,
  IFeatureFlagListQueryParams,
  never,
  never
> {
  public list = this.buildList()

  constructor(agent: AxiosInstance) {
    super(Resources.FeatureFlags, agent)
  }

  public async importFeatureFlags(formData: FormData): Promise<AxiosResponse<DataImportResult>> {
    const res = await this.agent.post<DataImportResult>(`${this.resource}/import`, formData)
    return res
  }

  public async getImportDetails(): Promise<AxiosResponse<ImportDetailsResponse>> {
    const res = await this.agent.get<ImportDetailsResponse>(`${this.resource}/import`)
    return res
  }

  public async enableFeatureFlagOrganization(featureFlag: string, body: { organizationIds: string[] }): Promise<void> {
    await this.agent.patch<void>(`${this.resource}/${featureFlag}/action/enable`, body)
  }

  public async disableFeatureFlagOrganization(featureFlag: string, body: { organizationIds: string[] }): Promise<void> {
    await this.agent.patch<void>(`${this.resource}/${featureFlag}/action/disable`, body)
  }
}
