import { IPoint } from '@sparelabs/geography'
import { last } from 'lodash'
import { SlotType } from '../types'
import { LocationInferringSlotBinCalculator } from './LocationInferringSlotBinCalculator'
import { IBinnableSlot } from './SlotBinCalculator'

export interface IBinnableSlotWithRequest extends IBinnableSlot {
  scheduledLocation: IPoint
  type: SlotType
  requestId: string | null
}

export interface ISlotBinWithRequest<T extends IBinnableSlotWithRequest> {
  location: IPoint
  slots: T[]
}

/**
 * When calculating valid insertion indices, we want to never have to worry about split up a bin
 * (ie never want to have to insert a slot into a bin where that slot has a different location than the bin).
 * To this end we use the notion of vehicle emptiness to split up bins:
 * if the bin non-empty, we would never want to insert a new slot (with a different location) there,
 * because then whichever rider is in the bin before the newly inserted slot would still be there
 * when we come back to the bin after the new slot, meaning it contradicts noReturnToPickup.
 * So splitting the bin on empty vehicle is the only thing required to make sure we never have to split a bin...
 * with the exception of the following edge case:
 * if we are currently inserting a dropoff and the first item in a bin is the pickup for the request we are inserting
 * we could potentially want to do the dropoff right after that pickup, breaking up its bin
 * to cover this edge case we ignore the pickup for the current request when calculating vehicle occupancy (if the id is provided)
 *
 * I opted to just include the location inferring logic in this class (rather than make LocationInferringSlotBinCalculator
 * wrap this one like it does the original SlotBinCalculator) because this additional logic is only necessary when
 * calculating potential insertion indices,
 * and the optional parameters for deciding whether to split or not and potentially passing the requestId to ignore got messy.
 */
export class EmptyVehicleSplittingSlotBinCalculator {
  public static binByGeohash<T extends IBinnableSlotWithRequest>(slots: T[]): Array<ISlotBinWithRequest<T>> {
    const bins = LocationInferringSlotBinCalculator.binByGeohash(slots)

    // if no locations can be inferred the above call returns the empty array
    // in this case we just put every slot into its own bin
    if (!bins.length && slots.length) {
      return slots.map((slot) => ({ location: slot.scheduledLocation, slots: [slot] }))
    }

    const vehicleState = this.getVehicleState(slots)

    const splitBins = []

    for (const bin of bins) {
      const firstSlot = bin.slots[0]
      splitBins.push({ location: firstSlot.scheduledLocation, slots: [firstSlot] })
      if (firstSlot.type === SlotType.Pickup && firstSlot.requestId) {
        vehicleState.add(firstSlot.requestId)
      } else if (firstSlot.type === SlotType.Dropoff && firstSlot.requestId) {
        vehicleState.delete(firstSlot.requestId)
      }
      for (const slot of bin.slots.slice(1)) {
        const previousBin = last(splitBins) as ISlotBinWithRequest<T>
        if (vehicleState.size) {
          previousBin.slots.push(slot)
        } else {
          splitBins.push({ location: slot.scheduledLocation, slots: [slot] })
        }
        if (slot.type === SlotType.Pickup && slot.requestId) {
          vehicleState.add(slot.requestId)
        } else if (slot.type === SlotType.Dropoff && slot.requestId) {
          vehicleState.delete(slot.requestId)
        }
      }
    }

    return splitBins
  }

  private static getVehicleState<T extends IBinnableSlotWithRequest>(slots: T[]): Set<string> {
    const pickupRequestIds = new Set<string>()
    const dropoffsWithoutPickups = new Set<string>()
    for (const slot of slots) {
      if (slot.type === SlotType.Pickup && slot.requestId && slot.requestId) {
        pickupRequestIds.add(slot.requestId)
      } else if (slot.type === SlotType.Dropoff && slot.requestId && !pickupRequestIds.has(slot.requestId)) {
        dropoffsWithoutPickups.add(slot.requestId)
      }
    }
    return dropoffsWithoutPickups
  }
}
