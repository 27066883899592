export enum DailyRequestCountsGroupBy {
  Summary = 'summary',
  Hour = 'hour',
  Service = 'service',
  Driver = 'driver',
  Fleet = 'fleet',
}

export enum DailyRequestCountsSummaryStatus {
  Past = 'past',
  Upcoming = 'upcoming',
}

export interface IGetDailyRequestCountsQueryParams {
  date: string
  groupBy: DailyRequestCountsGroupBy
  driverId?: string
  serviceId?: string
  fleetId?: string
}

export type IDailyRequestCountsResponse =
  | IDailyGroupedRequestCountsSummaryResponse
  | IDailyGroupedRequestCountsByHourResponse
  | IDailyGroupedRequestCountsByServiceResponse
  | IDailyGroupedRequestCountsByDriverResponse
  | IDailyGroupedRequestCountsByFleetResponse

interface IDailyGroupedRequestCountsBaseResponse<
  GroupBy extends DailyRequestCountsGroupBy,
  GroupKey extends string | number | DailyRequestCountsSummaryStatus
> {
  groupBy: GroupBy
  total: IRequestCounts
  rows: Array<IRequestCountsRow<GroupKey>>
}

interface IDailyGroupedRequestCountsSummaryResponse
  extends IDailyGroupedRequestCountsBaseResponse<DailyRequestCountsGroupBy.Summary, DailyRequestCountsSummaryStatus> {}

interface IDailyGroupedRequestCountsByHourResponse
  extends IDailyGroupedRequestCountsBaseResponse<DailyRequestCountsGroupBy.Hour, number> {}

interface IDailyGroupedRequestCountsByServiceResponse
  extends IDailyGroupedRequestCountsBaseResponse<DailyRequestCountsGroupBy.Service, string> {}

interface IDailyGroupedRequestCountsByDriverResponse
  extends IDailyGroupedRequestCountsBaseResponse<DailyRequestCountsGroupBy.Driver, string> {}

interface IDailyGroupedRequestCountsByFleetResponse
  extends IDailyGroupedRequestCountsBaseResponse<DailyRequestCountsGroupBy.Fleet, string> {}

export interface IRequestCountsRow<T extends string | number> extends IRequestCounts {
  groupKey: T
}

export interface IRequestCounts {
  all: number
  completedCount: number
  lateCount: number
  onTimeCount: number
  matchedCount: number
  unscheduledCount: number
  onHoldCount: number
  dispatchingCount: number
  offeredToFleetCount: number
  awaitingDispatchCount: number
  noDriversAvailableCount: number
  cancelledNoShowCount: number
}
