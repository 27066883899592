import { WebColors } from '@sparelabs/colors'
import { FunctionComponent } from 'react'
import { Input, InputProps } from 'reactstrap'
import styled, { css } from 'styled-components'
import { borderRadius, INPUT_MAX_WIDTH, InputFocusTheme } from '../../../util'

const caretSvg =
  // cspell: disable-next-line
  "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='14px' height='12px' viewBox='0 0 14 12' enable-background='new 0 0 14 12' xml:space='preserve'%3E%3Cpolygon points='3.862,7.931 0,4.069 7.725,4.069 '/%3E%3C/svg%3E"

const SelectStyles = `
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("${caretSvg}") !important;
  background-position: right 50%;
  background-repeat: no-repeat;
  padding: 0.375rem 1.5rem 0.375rem 0.75rem;
`

export const styledInputStyle = css`
  outline: ${(props: InputProps) => (props.invalid ? `1.5px solid ${WebColors.negativePrimary}` : 'none')} !important;
  border: ${(props: InputProps) => (props.invalid ? 'none' : `1px solid ${WebColors.borderPrimary}`)} !important;
  border-radius: ${borderRadius} !important;
  padding: 0.5em;
  max-width: ${INPUT_MAX_WIDTH};
  flex-grow: 1;
  font-size: 15px;
  &::placeholder {
    color: ${WebColors.contentTertiary};
  }
  &:focus:invalid {
    padding: 0.5em;
  }
  [disabled] {
    color: ${WebColors.contentTertiary};
  }
  &:focus {
    box-shadow: none !important;
    border: 1px solid transparent !important;
    ${(props: InputProps) => (props.invalid ? '' : InputFocusTheme)}
  }
  // this is to remove bootstrap's icons on the input
  ${(props: InputProps) => (props.type === 'select' ? SelectStyles : 'background-image: none !important;')}
`

export const StyledInput: FunctionComponent<InputProps> = styled(Input)`
  ${styledInputStyle}
`
