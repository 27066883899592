import React from 'react'
import { CharacterCount, IMaxLengthOption } from './CharacterCount'
import { IInputProps } from './InputTypes'
import { StyledInput } from './styled/StyledInput'

interface ITextAreaProps extends IInputProps<string> {
  label?: string
  placeholder?: string
  id?: string
  maxLengthOption?: IMaxLengthOption
  style?: React.CSSProperties | undefined
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}

export const TextAreaInput = ({
  value,
  label,
  onChange,
  invalid,
  placeholder,
  id,
  maxLengthOption,
  style,
  disabled,
  onKeyDown,
  onBlur,
  ...inputAttributes
}: ITextAreaProps) => (
  <>
    <StyledInput
      {...inputAttributes}
      aria-label={label}
      type='textarea'
      value={value ?? ''}
      invalid={invalid}
      // Will output undefined instead of empty strings
      onChange={(event) => onChange(event.target.value)}
      style={style ?? { height: 200, marginTop: '8px', marginBottom: '7px' }}
      placeholder={placeholder}
      disabled={disabled}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      id={id}
    />
    {maxLengthOption && <CharacterCount value={value ?? ''} maxLengthOption={maxLengthOption} />}
  </>
)
