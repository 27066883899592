import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactNode } from 'react'
import { useToggle } from 'react-use'
import styled from 'styled-components'

const CARET_WIDTH = '18px'

const PrimaryContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

const CaretContainer = styled.div`
  width: ${CARET_WIDTH};
`

const ChildrenContainer = styled.div<{ isExpanded: boolean }>`
  padding-left: ${CARET_WIDTH};
  overflow: hidden;
  ${({ isExpanded }) => (isExpanded ? '' : 'height: 0px;')}
`
interface IProps {
  parent: ReactNode
  children?: ReactNode
  renderExpanded?: boolean
  onExpanded?: () => void
}

const Caret = ({ isExpanded }: { isExpanded: boolean }) => (
  <FontAwesomeIcon icon='caret-right' rotation={isExpanded ? 90 : undefined} />
)

export const Collapsible = ({ parent, children, renderExpanded, onExpanded }: IProps) => {
  const [isExpanded, toggle] = useToggle(renderExpanded ?? false)

  const onClick = () => {
    onExpanded?.()
    toggle()
  }

  return (
    <>
      <PrimaryContainer onClick={onClick}>
        <CaretContainer>
          <Caret isExpanded={isExpanded} />
        </CaretContainer>
        {parent}
      </PrimaryContainer>
      <ChildrenContainer isExpanded={isExpanded}>{children}</ChildrenContainer>
    </>
  )
}
