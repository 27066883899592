import { PermissionSetType } from '../../sets'
import { AdminUserPermissionSet } from '../../sets/AdminUserPermissions'
import { AnalyticsPermissionSet } from '../../sets/AnalyticsPermissions'
import { AnnouncementPermissionSet } from '../../sets/AnnouncementPermissions'
import { BulkMessagePermissionSet } from '../../sets/BulkMessagePermissions'
import { ChargePermissionSet } from '../../sets/ChargePermissions'
import { ConnectedAccountPermissionSet } from '../../sets/ConnectedAccountPermissions'
import { DriverUserPermissionSet } from '../../sets/DriverUserPermissions'
import { DutyPermissionSet } from '../../sets/DutyPermissions'
import { EventPermissionSet } from '../../sets/EventPermissions'
import { FarePassPermissionSet } from '../../sets/FarePassPermissions'
import { FavoriteLocationPermissionSet } from '../../sets/FavoriteLocationPermissions'
import { GroupPermissionSet } from '../../sets/GroupPermissions'
import { LaunchReportingPermissionSet } from '../../sets/LaunchReportingPermissions'
import { NotificationSettingPermissionSet } from '../../sets/NotificationSettingPermissions'
import { OperationalAlertsPermissionSet } from '../../sets/OperationalAlertsPermissions'
import { OrganizationPermissionSet } from '../../sets/OrganizationPermissions'
import { PaymentMethodPermissionSet } from '../../sets/PaymentMethodPermissions'
import { PromoPermissionSet } from '../../sets/PromoPermissions'
import { RequestPermissionSet } from '../../sets/RequestPermissions'
import { ReviewPermissionSet } from '../../sets/ReviewPermissions'
import { RiderUserPermissionSet } from '../../sets/RiderUserPermissions'
import { ServicePermissionSet } from '../../sets/ServicePermissions'
import { SimulationPermissionSet } from '../../sets/SimulationPermissions'
import { UserBanPermissionSet } from '../../sets/UserBanPermissions'
import { UserFleetAgreementPermissionSet } from '../../sets/UserFleetAgreementPermissions'
import { UserOwnPermissionSet } from '../../sets/UserOwnPermissions'
import { VehiclePermissionSet } from '../../sets/VehiclePermissions'
import { WebhookPermissionSet } from '../../sets/WebhookPermissions'

export const CapMetroServiceProviderRole: PermissionSetType[] = [
  UserOwnPermissionSet.Admin,
  UserOwnPermissionSet.User,

  RiderUserPermissionSet.View,
  RiderUserPermissionSet.Export,

  DriverUserPermissionSet.View,
  DriverUserPermissionSet.Edit,
  DriverUserPermissionSet.Import,
  DriverUserPermissionSet.Export,

  UserBanPermissionSet.View,

  FavoriteLocationPermissionSet.View,
  FavoriteLocationPermissionSet.Edit,
  FavoriteLocationPermissionSet.Delete,

  VehiclePermissionSet.View,
  VehiclePermissionSet.Edit,

  DutyPermissionSet.View,
  DutyPermissionSet.Manage,
  DutyPermissionSet.ViewVehicleLocation,
  DutyPermissionSet.ViewDriverConversation,
  DutyPermissionSet.StartDriverConversation,

  ServicePermissionSet.View,

  AnnouncementPermissionSet.View,
  AnnouncementPermissionSet.Write,

  PromoPermissionSet.View,

  FarePassPermissionSet.View,

  RequestPermissionSet.Estimate,
  RequestPermissionSet.CreateUnmatchedRequest,
  RequestPermissionSet.View,
  RequestPermissionSet.ViewDiscounts,
  RequestPermissionSet.Write,
  RequestPermissionSet.Rematch,
  RequestPermissionSet.EstimateWithOverrides,
  RequestPermissionSet.ViewRequestPredictions,

  UserFleetAgreementPermissionSet.View,

  SimulationPermissionSet.View,
  SimulationPermissionSet.New,
  SimulationPermissionSet.DeleteResults,

  GroupPermissionSet.View,

  GroupPermissionSet.ViewMembership,

  ReviewPermissionSet.View,

  ChargePermissionSet.View,

  PaymentMethodPermissionSet.View,
  PaymentMethodPermissionSet.InitiatePhoneCollection,
  PaymentMethodPermissionSet.Export,
  PaymentMethodPermissionSet.Delete,

  ConnectedAccountPermissionSet.View,

  NotificationSettingPermissionSet.View,
  BulkMessagePermissionSet.View,

  LaunchReportingPermissionSet.View,
  LaunchReportingPermissionSet.Write,
  LaunchReportingPermissionSet.Delete,
  LaunchReportingPermissionSet.Export,

  AnalyticsPermissionSet.Access,

  OrganizationPermissionSet.ViewOwn,
  OrganizationPermissionSet.ViewConfiguration,

  AdminUserPermissionSet.View,

  WebhookPermissionSet.View,
  WebhookPermissionSet.Write,

  OperationalAlertsPermissionSet.Subscribe,

  EventPermissionSet.View,
]
