import { PermissionType } from '../PermissionType'
import { SimulationPermissionSet, simulationPermissionSetGrouping } from '../sets'
import { SuperAdminOrganizationPermissions, SuperAdminPermissions } from '../sets/SuperAdminPermissions'

const SuperAdminToolPermissions: PermissionType[] = [
  ...simulationPermissionSetGrouping.active[SimulationPermissionSet.New],
  ...simulationPermissionSetGrouping.active[SimulationPermissionSet.DeleteResults],

  // Realize permissions
  PermissionType.WRITE_ANY_DATASET,
  PermissionType.DELETE_ANY_DATASET,
  PermissionType.POST_ANY_DATASET_POINTS,
  PermissionType.POST_ANY_DATASET_HEATMAP_POINTS,
  PermissionType.LIST_ANY_DATASET_HEATMAP_POINTS,
  PermissionType.LIST_ANY_DATASET_POINTS,
  PermissionType.LIST_ANY_DATASET_POLYGON,
  PermissionType.POST_ANY_DATASET_POLYGON,
  PermissionType.GET_ANY_DATASET,

  // Mobile app permissions
  PermissionType.GET_ANY_MOBILE_APP,
  PermissionType.LIST_ANY_MOBILE_APP,
  PermissionType.WRITE_ANY_MOBILE_APP,
  PermissionType.DELETE_ANY_MOBILE_APP,
  PermissionType.POST_ANY_MOBILE_APP_ORGANIZATION,
  PermissionType.DELETE_ANY_MOBILE_APP_ORGANIZATION,
  PermissionType.POST_UPLOAD_PHOTO,

  // Mobile app release permissions
  PermissionType.GET_ANY_MOBILE_APP_RELEASE,
  PermissionType.WRITE_ANY_MOBILE_APP_RELEASE,
  PermissionType.DELETE_ANY_MOBILE_APP_RELEASE,

  // Billing account permissions
  PermissionType.GET_ANY_BILLING_ACCOUNT,
  PermissionType.LIST_ANY_BILLING_ACCOUNT,
  PermissionType.WRITE_ANY_BILLING_ACCOUNT,
  PermissionType.DELETE_ANY_BILLING_ACCOUNT,
  PermissionType.GET_ANY_BILLING_ACCOUNT_ASSIGNMENT,
  PermissionType.POST_ANY_BILLING_ACCOUNT_ASSIGNMENT,
  PermissionType.DELETE_ANY_BILLING_ACCOUNT_ASSIGNMENT,

  // Simcity permissions
  PermissionType.GET_ANY_SIMCITY_SIMULATION,
  PermissionType.POST_ANY_SIMCITY_SIMULATION,
  PermissionType.PUT_ANY_SIMCITY_SIMULATION,
  PermissionType.DELETE_ANY_SIMCITY_SIMULATION,

  PermissionType.GET_ANY_DAILY_REQUEST_COUNTS,

  PermissionType.GET_ANY_REPORT,
  PermissionType.PATCH_ANY_REPORT,
  PermissionType.GET_ANY_REPORT_PREVIEW,

  PermissionType.LIST_ANY_GTFS_FILES,

  PermissionType.ROOT_ADMIN_ONLY,

  // This permission allows our partner success personnel help setup SSO for new domains
  PermissionType.GENERATE_WORKOS_ADMIN_PORTAL_LINK,
]

export const DefaultSuperAdminRole: PermissionType[] = [
  ...SuperAdminOrganizationPermissions.viewAny,
  ...SuperAdminOrganizationPermissions.manageOwn,
  ...SuperAdminOrganizationPermissions.createAny,
  ...SuperAdminPermissions.view,
  ...SuperAdminToolPermissions,

  // allow a super admin to call MTI organization endpoints until they are removed
  PermissionType.WRITE_ANY_MTI_ORGANIZATION,
  PermissionType.DELETE_ANY_MTI_ORGANIZATION,

  PermissionType.CAN_CREATE_ANY_UNMATCHED_REQUEST,
]
