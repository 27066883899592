import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum FarePassPermissionSet {
  View = 'fare_pass_view',
  Write = 'fare_pass_write',
  NewAllocation = 'fare_pass_new_allocation',
  EditAllocation = 'fare_pass_edit_allocation',
  ImportAllocations = 'fare_pass_import_allocations',
  ExportAllocations = 'fare_pass_export_allocations',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const farePassPermissionSetGrouping: IPermissionSetGrouping<FarePassPermissionSet> = {
  key: PermissionSetGroupingKey.FarePass,
  active: {
    [FarePassPermissionSet.View]: [
      PermissionType.GET_ANY_FARE_PASS,
      PermissionType.GET_ANY_FARE_PASS_ALLOCATION,
    ],
    [FarePassPermissionSet.Write]: [PermissionType.WRITE_ANY_FARE_PASS],
    [FarePassPermissionSet.NewAllocation]: [
      PermissionType.POST_ANY_FARE_PASS_ALLOCATION,
    ],
    [FarePassPermissionSet.EditAllocation]: [
      PermissionType.PATCH_ANY_FARE_PASS_ALLOCATION,
    ],
    [FarePassPermissionSet.ImportAllocations]: [PermissionType.IMPORT_ANY_FARE_PASS_ALLOCATION],
    [FarePassPermissionSet.ExportAllocations]: [PermissionType.EXPORT_ANY_FARE_PASS_ALLOCATION],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
