import { AddressFormatter, IAddress } from '@sparelabs/address'
import { CustomFieldType, ICustomFieldResponse } from '@sparelabs/api-client'
import { IFileResponse } from '@sparelabs/http-client-utils'
import moment from 'moment-timezone'

export interface IMarkdownCustomFieldFormatterOptions {
  timezone: string
  addressFormatter: AddressFormatter
  fetchFileUrl: (id: string) => Promise<string>
}

export class MarkdownCustomFieldFormatter {
  public static async format(
    fields: ICustomFieldResponse[],
    data: Record<string, unknown>,
    options: IMarkdownCustomFieldFormatterOptions
  ): Promise<Record<string, string | undefined>> {
    const formattedData: Record<string, string | undefined> = {}

    for (const key of Object.keys(data)) {
      const field = fields.find((field) => field.key === key)
      const value = data[key]

      formattedData[key] = field ? await this.formatValue(field, value, options) : String(value)
    }

    return formattedData
  }

  public static async formatValue(
    field: ICustomFieldResponse,
    value: unknown,
    options: IMarkdownCustomFieldFormatterOptions
  ): Promise<string | undefined> {
    switch (field.type) {
      case CustomFieldType.Date:
        return moment.tz(value as string, 'YYYY-MM-DD', options.timezone).format('dddd, LL')

      case CustomFieldType.Datetime:
        return moment
          .unix(value as number)
          .tz(options.timezone)
          .format('LLLL')

      case CustomFieldType.MultipleChoice:
        return field.choices
          ?.filter((choice) => (value as string[])?.includes(choice.value))
          .map((choice) => choice.label)
          .join(', ')

      case CustomFieldType.SingleChoice:
        return field.choices?.find((choice) => choice.value === value)?.label

      case CustomFieldType.Address: {
        return (value as IAddress)?.country
          ? (await options.addressFormatter.format(value as IAddress)).filter((line) => line).join('<br>')
          : undefined
      }

      case CustomFieldType.File:
        return (value as IFileResponse)?.name

      case CustomFieldType.Files:
        return (value as IFileResponse[])?.map((file) => file.name).join(', ')

      case CustomFieldType.Text:
      case CustomFieldType.NpsScore:
      case CustomFieldType.Number:
      case CustomFieldType.Toggle:
        return String(value)

      case CustomFieldType.Drawing: {
        const fileUrl = await options.fetchFileUrl((value as IFileResponse)?.id)
        return `![](${fileUrl})`
      }

      default:
        return undefined
    }
  }
}
