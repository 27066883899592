import { PhoneNumberInput } from '@sparelabs/web-ui-components'
import React, { SyntheticEvent, useState } from 'react'
import { FormContainer, InfoPrompt, ITermsSectionProps, StackedInputWithLabel, TermsSection } from 'src/components'
import { st } from 'src/locales/TranslationHelper'
import styled from 'styled-components'

const PhoneNumberField = styled(StackedInputWithLabel)`
  margin-top: 20px;
  div.react-tel-input > input.form-control {
    max-width: unset !important;
  }
`

interface IProps {
  terms: ITermsSectionProps['terms']
  onSubmit: (phoneNumber: string) => void
  error?: string
  initialValue?: string
}

export const RiderAuthPhoneForm = ({ terms, onSubmit, error, initialValue = '' }: IProps): JSX.Element => {
  const [phoneNumber, setPhoneNumber] = useState<string>(initialValue)

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault()
    onSubmit(phoneNumber)
  }

  return (
    <FormContainer onSubmit={handleSubmit}>
      <InfoPrompt>{st.forms.auth.phoneNumberPrompt()}</InfoPrompt>
      <PhoneNumberField
        label={st.forms.auth.phoneNumberLabel()}
        error={error}
        renderInput={({ id }) => <PhoneNumberInput id={id} value={phoneNumber} onChange={setPhoneNumber} autoFocus />}
      />
      <TermsSection terms={terms} continueButtonType='submit' />
    </FormContainer>
  )
}
