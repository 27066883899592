import { WebColors } from '@sparelabs/colors'
import styled from 'styled-components'
import { InputFocusTheme } from '../../util'

export const CheckboxInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: ${WebColors.backgroundPrimary};
  border: 1px solid ${WebColors.borderSecondary};
  border-radius: 1px;
  display: inline-block;
  position: relative;
  height: 13px;
  width: 13px;
  flex-shrink: 0;
  cursor: pointer;

  // align with top of text
  align-self: flex-start;
  margin-top: 3px;

  :disabled {
    background-color: ${WebColors.backgroundSecondary};
    border-color: ${WebColors.borderPrimary};
    cursor: not-allowed;
  }
  :focus,
  :active {
    border-radius: 2px !important;
    ${InputFocusTheme}
    outline-offset: 1px;
  }
  :checked,
  :indeterminate {
    background-color: ${WebColors.interactivePrimary};
    border-color: ${WebColors.interactivePrimary};
  }

  :checked:after {
    content: '';
    position: absolute;
    display: block;
    bottom: 2px;
    right: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(38deg);
    -ms-transform: rotate(38deg);
    transform: rotate(38deg);
  }

  :indeterminate:after {
    content: '';
    position: absolute;
    display: block;
    bottom: 4px;
    right: 1.5px;
    width: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
  }
`
