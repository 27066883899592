// eslint-disable-next-line no-restricted-imports
import { flatten, List, ListIterator, Many } from 'lodash'

export class SafeArray<InputType> {
  constructor(private readonly array: InputType[], private readonly errorHandler?: (error: Error) => void) {}

  public flatMap<OutputType>(callback: ListIterator<InputType, Many<OutputType>>): SafeArray<OutputType> {
    const result = this.array.flatMap(this.makeSafe(callback))
    return new SafeArray<OutputType>(result, this.errorHandler)
  }

  public async flatMapAsync<OutputType>(
    callback: ListIterator<InputType, Promise<Many<OutputType>>>
  ): Promise<SafeArray<OutputType>> {
    const result = await Promise.all(this.array.flatMap(this.makeSafeAsync(callback)))
    return new SafeArray(flatten(result), this.errorHandler)
  }

  public toArray(): InputType[] {
    return Array.from(this.array)
  }

  private makeSafe<OutputType>(callback: ListIterator<InputType, Many<OutputType>>) {
    return (item: InputType, index: number, collection: List<InputType>): Many<OutputType> => {
      try {
        return callback(item, index, collection)
      } catch (error) {
        if (this.errorHandler) {
          this.errorHandler(error as Error)
        }
        return []
      }
    }
  }

  private makeSafeAsync<OutputType>(callback: ListIterator<InputType, Promise<Many<OutputType>>>) {
    return async (item: InputType, index: number, collection: List<InputType>): Promise<Many<OutputType>> => {
      try {
        return await callback(item, index, collection)
      } catch (error) {
        if (this.errorHandler) {
          this.errorHandler(error as Error)
        }
        return []
      }
    }
  }
}
