import { ExtendableError } from './ExtendableError'

/**
 * We extend ExtendableError because of a Typescript limitation to do with extending built-in types. See:
 *   - https://github.com/jestjs/jest/issues/8279
 *   - https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
 */
export class MetadataError extends ExtendableError {
  /**
   * @param message - a short, human-readable explanation for why this error was thrown.
   * Try to avoid putting specific application state in the message to make it easier to search for/
   * aggregate.
   * @param metadata - a JSON object of useful application state to serve as context to someone
   * trying to understand why this error might have been thrown
   */
  constructor(message: string, public readonly metadata?: object) {
    super(message)
  }
}
