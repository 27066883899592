import { ToggleGroup } from '@radix-ui/react-toggle-group'
import { WebColors } from '@sparelabs/colors'
import React from 'react'
import styled from 'styled-components'
import { borderRadius, boxShadow } from '../util'

interface ISummaryBarProps<T extends string> {
  children: JSX.Element | JSX.Element[]
  value?: T | undefined
  onChange?: (value: T | undefined) => void
}
const StyledGroup = styled(ToggleGroup)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-shadow: ${boxShadow};
  border-radius: ${borderRadius};
  background-color: ${WebColors.backgroundSecondary};
`

export const SummaryBar = <T extends string>({ children, value, onChange }: ISummaryBarProps<T>): JSX.Element => (
  <StyledGroup
    type='single'
    value={value || ''}
    onValueChange={(value: T) => onChange && onChange((value as T) || undefined)}
    rovingFocus={false}
  >
    {children}
  </StyledGroup>
)
