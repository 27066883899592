import { PermissionType } from './PermissionType'
import { PermissionSets, PermissionSetType } from './sets'

const isValueInEnum = <T extends Record<string, string>>(enumObj: T, value: string): value is T[keyof T] =>
  Object.values(enumObj).includes(value as T[keyof T])

/**
 * Temporary function that converts the RBAC roles to permission types
 * This will be removed once the RBAC roles are fully implemented
 */
export const convertRBACRoleToPermissionType = (
  permissions: Array<PermissionType | PermissionSetType>
): PermissionType[] => {
  const permissionTypes: PermissionType[] = []

  for (const permission of permissions) {
    if (isValueInEnum(PermissionType, permission)) {
      permissionTypes.push(permission as PermissionType)
    } else {
      permissionTypes.push(...PermissionSets[permission as PermissionSetType])
    }
  }

  return permissionTypes
}
