import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IReviewListQueryParams, IReviewPostBody, IReviewResponse, Resources } from '../types'

export class ReviewResource extends GenericResource<IReviewResponse, IReviewListQueryParams, never, IReviewPostBody> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.Reviews, agent)
  }
}
