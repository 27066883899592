import { WebColors } from '@sparelabs/colors'
import React, { ReactNode } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { INPUT_BREAKPOINT_WIDTH_PX, INPUT_MAX_WIDTH, LABEL_COLUMN_WIDTH_PX, LABEL_MARGIN_RIGHT_PX } from '../util'
import { InfoTooltip } from './InfoTooltip'

const LABEL_COLUMN_WIDTH = `${LABEL_COLUMN_WIDTH_PX}px`
const VALUE_COLUMN_MIN_WIDTH = '270px'
const TEXT_LINE_HEIGHT = '20px'

export interface IFieldContainerProps {
  stacked?: boolean
}

export const ViewFieldContainer = styled.div<IFieldContainerProps>`
  display: flex;
  flex-direction: ${({ stacked }) => (stacked ? 'column' : 'row')};
  flex-wrap: wrap;
  font-size: 15px;
  gap: ${({ stacked }) => (stacked ? '4px' : '0px ')};
`

export const FieldLabel = styled.label<IFieldContainerProps>`
  margin-bottom: 0px;
  font-weight: 400;
  line-height: ${TEXT_LINE_HEIGHT};
  color: ${WebColors.contentSecondary};
  min-width: ${LABEL_COLUMN_WIDTH};
  max-width: ${({ stacked }) => (stacked ? INPUT_MAX_WIDTH : LABEL_COLUMN_WIDTH)};
  margin-right: ${LABEL_MARGIN_RIGHT_PX}px;
  display: flex;
  height: fit-content;
`

export const FieldValueContainer = styled.div<IFieldContainerProps>`
  flex-grow: 1;
  overflow-wrap: break-word;
  min-width: ${VALUE_COLUMN_MIN_WIDTH};
  flex-basis: ${({ stacked }) => `${stacked ? '0px' : `${INPUT_BREAKPOINT_WIDTH_PX}px`}`};
`

export const FieldValue = styled.div`
  line-height: ${TEXT_LINE_HEIGHT};
  max-width: ${INPUT_MAX_WIDTH};
`

export const FieldHint = styled.div`
  color: ${WebColors.contentTertiary};
  font-size: 13px;
  font-weight: 400;
  white-space: pre-wrap;
  margin-top: 5px;
  max-width: ${INPUT_MAX_WIDTH};
`

export const ValidationError = styled.div`
  color: ${WebColors.negativePrimary};
  font-size: 14px;
  margin-top: 5px;
  max-width: ${INPUT_MAX_WIDTH};
`
const HorizontalBar = styled.div`
  background-color: ${WebColors.borderPrimary};
  height: 1px;
  flex: 1;
  margin-left: 12px;
  margin-top: 8px;
`

export const EmptyValue = styled.span`
  color: ${WebColors.contentTertiary};
`

const StyledInfoTooltipWrapper = styled.span`
  margin-left: 8px;
`

interface IFieldProps {
  children?: ReactNode
  label?: string
  hint?: ReactNode
  tooltip?: string
  error?: string
  className?: string
  id?: string
  horizontalBar?: boolean
  isRequired?: boolean
  labelStyle?: CSSProperties
  valueStyle?: CSSProperties
  stacked?: boolean
}

export const ViewField = ({
  label,
  children,
  hint,
  tooltip,
  error,
  className,
  horizontalBar,
  labelStyle,
  valueStyle,
  stacked,
}: IFieldProps): JSX.Element => (
  <ViewFieldContainer className={className} stacked={stacked}>
    <FieldLabel style={labelStyle} stacked={stacked}>
      <div>{label}</div>
      {tooltip && (
        <StyledInfoTooltipWrapper>
          <InfoTooltip content={tooltip} />
        </StyledInfoTooltipWrapper>
      )}
      {horizontalBar && <HorizontalBar />}
    </FieldLabel>
    <FieldValueContainer style={valueStyle} stacked={stacked}>
      <FieldValue style={valueStyle}>{children}</FieldValue>
      {hint && <FieldHint>{hint}</FieldHint>}
      {error && <ValidationError role='alert'>{error}</ValidationError>}
    </FieldValueContainer>
  </ViewFieldContainer>
)
