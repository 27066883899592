/**
 * Replacement for the library `extendable-error` (https://github.com/vilicvane/extendable-error) which is not maintained anymore, and suggests the following alternative
 *
 * The replacement is required due to a change in Axios where the `stack` property was being modified, however `extendable-error` has the stack as readonly causing the failure in Axios
 */
export abstract class ExtendableError extends Error {
  constructor(message?: string) {
    super(message)
    Object.setPrototypeOf(this, new.target.prototype)
    this.name = new.target.name
  }
}
