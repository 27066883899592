import { PermissionType } from '../PermissionType'

export const SuperAdminOrganizationPermissions = {
  viewAny: [
    PermissionType.GET_ANY_ORGANIZATION,
    PermissionType.LIST_ANY_ORGANIZATION,
    PermissionType.GET_ANY_ORGANIZATION_CONFIGURATION,
    PermissionType.GET_ANY_ORGANIZATION_SUPER_ADMIN,
  ],
  manageOwn: [
    PermissionType.PATCH_OWN_ORGANIZATION,
    PermissionType.PATCH_OWN_ORGANIZATION_CONFIGURATION,
    PermissionType.PATCH_OWN_ORGANIZATION_NOTIFICATION_PROVIDERS,
    PermissionType.POST_OWN_ORGANIZATION_SUPER_ADMIN,
    PermissionType.DELETE_OWN_ORGANIZATION_SUPER_ADMIN,
    PermissionType.JOIN_OWN_ORGANIZATION,
  ],
  manageAny: [
    PermissionType.PATCH_ANY_ORGANIZATION,
    PermissionType.PATCH_ANY_ORGANIZATION_CONFIGURATION,
    PermissionType.POST_ANY_ORGANIZATION_SUPER_ADMIN,
    PermissionType.DELETE_ANY_ORGANIZATION_SUPER_ADMIN,
    PermissionType.JOIN_ANY_ORGANIZATION,
    PermissionType.PATCH_ANY_ORGANIZATION_NOTIFICATION_PROVIDERS,
    PermissionType.DELETE_ANY_ORGANIZATION,
    PermissionType.IMPORT_ANY_FEATURE_FLAG,
    PermissionType.POST_ANY_DATA_MIGRATION,
  ],
  createAny: [PermissionType.POST_ANY_ORGANIZATION],
}

export const SuperAdminPermissions = {
  view: [
    PermissionType.GET_SUPER_ADMIN,
    PermissionType.LIST_SUPER_ADMIN,
    PermissionType.GET_SUPER_ADMIN_AUTHORIZATION_GROUP,
  ],
  manage: [
    PermissionType.WRITE_SUPER_ADMIN,
    PermissionType.DELETE_SUPER_ADMIN,
    PermissionType.UPDATE_SUPER_ADMIN_USER_AUTHORIZATION_GROUP,
  ],
}
