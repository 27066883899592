import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum RiderDriverRestrictionPermissionSet {
  View = 'rider_driver_restriction_view',
  Create = 'rider_driver_restriction_create',
  Delete = 'rider_driver_restriction_delete',
}

export const riderDriverRestrictionPermissionSetGrouping: IPermissionSetGrouping<RiderDriverRestrictionPermissionSet> =
  {
    key: PermissionSetGroupingKey.RiderDriverRestriction,
    active: {
      [RiderDriverRestrictionPermissionSet.View]: [PermissionType.GET_ANY_RIDER_DRIVER_RESTRICTION],
      [RiderDriverRestrictionPermissionSet.Create]: [PermissionType.POST_ANY_RIDER_DRIVER_RESTRICTION],
      [RiderDriverRestrictionPermissionSet.Delete]: [PermissionType.DELETE_ANY_RIDER_DRIVER_RESTRICTION],
    },
    deprecated: [],
    unAssignablePermissionSets: [],
  }
