import { z } from '@sparelabs/zod'

export const IScannedAddressSchema = z.object({
  country: z.string().nullable(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  address1: z.string().nullable(),
  address2: z.string().nullable(),
  city: z.string().nullable(),
  province: z.string().nullable(),
  zip: z.string().nullable(),
})

const IScannedFieldSchema = z.object({
  key: z.string(),
  value: z.union([z.string(), z.array(z.string()), z.number(), z.boolean(), IScannedAddressSchema]).nullable(),
  /**
   * Percentage value as decimal between 0 and 1.
   */
  confidence: z.number(),
})

export const IScannedResultSchema = z.array(IScannedFieldSchema)

export type IScannedAddress = z.infer<typeof IScannedAddressSchema>
export type IScannedField = z.infer<typeof IScannedFieldSchema>
export type IScannedResult = z.infer<typeof IScannedResultSchema>

export enum AIScanStrategy {
  VertexAI = 'vertexAI',
  DocumentAI = 'documentAI',
}

export enum AIScanContext {
  NewCaseForm = 'newCaseForm',
  EditCaseForm = 'editCaseForm',
}

export interface IFileScanPostBody {
  strategy: AIScanStrategy
  fieldGroupId: string
}

export interface IFileScanResponse {
  result: IScannedResult
}
