import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum PaymentMethodTypePermissionSet {
  View = 'payment_method_type_view',
  Write = 'payment_method_type_write',
  Export = 'payment_method_type_export',
}

export const paymentMethodTypePermissionSetGrouping: IPermissionSetGrouping<PaymentMethodTypePermissionSet> = {
  key: PermissionSetGroupingKey.PaymentMethodType,
  active: {
    [PaymentMethodTypePermissionSet.View]: [PermissionType.GET_ANY_PAYMENT_METHOD_TYPE],
    [PaymentMethodTypePermissionSet.Write]: [
      PermissionType.WRITE_ANY_PAYMENT_METHOD_TYPE,
      PermissionType.POST_ANY_PAYMENT_PROVIDER,
      PermissionType.PATCH_ANY_PAYMENT_PROVIDER,
      PermissionType.GET_GLOBAL_STRIPE_CONFIG,
    ],
    [PaymentMethodTypePermissionSet.Export]: [PermissionType.EXPORT_ANY_PAYMENT_METHOD_TYPE],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
