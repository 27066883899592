import { WebColors } from '@sparelabs/colors'
import { dateFormat as defaultDateFormat } from '@sparelabs/time'
import color from 'color'
import React, { CSSProperties, useEffect, useState } from 'react'
import {
  DateRangePicker,
  DateRangePickerShape,
  OpenDirectionShape,
  SingleDatePicker,
  SingleDatePickerShape,
} from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import styled from 'styled-components'
import { st } from '../../../locales/TranslationHelper'
import { borderRadius, boxShadow, boxShadowStrong, ButtonFocusTheme, shadowHigh } from '../../../util'

const OverrideStyles = styled.div<{ $isFullWidth?: boolean }>`
  ${({ $isFullWidth }) => ($isFullWidth ? 'width: 100%;' : '')}

  .SingleDatePicker {
    ${({ $isFullWidth }) => ($isFullWidth ? 'width: 100%;' : '')}
  }

  .DateInput {
    width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : '130px')};
    min-width: ${({ $isFullWidth }) => ($isFullWidth ? '170px' : '130px')};
  }

  .DateInput_input {
    font-weight: 300;
    font-size: 15px;
    line-height: 15px;
    padding: 6px 7px 5px;
    text-align: center;
    &:focus-visible {
      outline: none;
      color: ${WebColors.contentInvertedPrimary};
      background: ${WebColors.interactivePrimary};
      border-radius: 6px; // border-radius of outer container minus padding
      border: 0px;
      &::placeholder {
        color: ${WebColors.contentInvertedPrimary};
      }
    }
  }

  .DateInput_input__focused {
    color: ${WebColors.contentInvertedPrimary};
    background: ${WebColors.interactivePrimary};
    border-radius: 6px; // border-radius of outer container minus padding
    border: 0px;
    &::placeholder {
      color: ${WebColors.contentInvertedPrimary};
    }
  }

  .DayPickerNavigation_button {
    &:focus-visible {
      outline-color: ${WebColors.focusOutline} !important;
      outline-style: solid !important;
      outline-width: 2px !important;
    }
  }

  .DateInput_fang {
    display: none !important;
  }

  .DateRangePickerInput,
  .SingleDatePickerInput {
    ${({ $isFullWidth }) => ($isFullWidth ? 'width: 100%;' : '')}
    border: 0;
    border-radius: ${borderRadius};
    padding: 4px;
    transition: all 0.3s ease;
    box-shadow: ${boxShadow};
    &:hover,
    &:focus,
    &:active {
      box-shadow: ${boxShadowStrong};
    }
  }

  .SingleDatePickerInput__disabled,
  .DateInput_input__disabled {
    background: #fff;
    font-style: normal;
    cursor: default !important;
  }

  .DateRangePicker_picker,
  .SingleDatePicker_picker {
    z-index: 2;
  }

  .SingleDatePickerInput {
    height: 38px;
    line-height: 27px;
    .DateInput_input {
      cursor: pointer;
    }
  }

  .DateRangePickerInput_arrow {
    line-height: 0px;
    vertical-align: middle;
    padding: 0px 7px;
  }

  .DateRangePickerInput_arrow_svg {
    fill: ${WebColors.interactivePrimary};
    height: 15px;
    width: 15px;
  }

  .CalendarDay__blocked_out_of_range {
    background: ${WebColors.backgroundSecondary};
    :hover {
      background: ${WebColors.backgroundSecondary};
    }
  }

  .CalendarDay:focus-visible {
    ${ButtonFocusTheme}
    outline-offset: -2px;
    border-radius: 5px;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${WebColors.interactivePrimary};
    border: 1px solid ${WebColors.interactivePrimary};
    color: ${WebColors.contentInvertedPrimary};
  }

  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${color(WebColors.interactivePrimary).lighten(0.3).hex()};
    border: 1px solid ${color(WebColors.interactivePrimary).lighten(0.3).hex()};
    color: ${WebColors.contentInvertedPrimary};
  }

  .CalendarDay__selected_span {
    background: ${color(WebColors.interactivePrimary).lighten(0.15).hex()};
    border: 1px solid ${color(WebColors.interactivePrimary).lighten(0.15).hex()};
    color: ${WebColors.contentInvertedPrimary};
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:active,
  .CalendarDay__hovered_span:hover {
    background: ${color(WebColors.interactivePrimary).lighten(0.4).hex()};
    border: 1px solid ${color(WebColors.interactivePrimary).lighten(0.4).hex()};
    color: ${WebColors.contentInvertedPrimary};
  }

  .DayPickerNavigation_button__default {
    border: 0px;
    &:hover,
    &:focus,
    &:active {
      svg {
        fill: ${WebColors.interactivePrimary};
      }
      border: 0px;
      outline: none;
      background: none;
    }
  }

  .DayPicker__horizontal {
    box-shadow: ${shadowHigh};
  }

  .DateRangePickerInput_calendarIcon,
  .SingleDatePickerInput_calendarIcon {
    margin: 0;
    padding: 3px 5px 0px 9px;
    vertical-align: top;
    outline: none;
  }
  .DateRangePickerInput_calendarIcon_svg,
  .SingleDatePickerInput_calendarIcon_svg {
    fill: ${WebColors.contentPrimary};
  }

  .CalendarDay__highlighted_calendar,
  .CalendarDay__highlighted_calendar:hover {
    background: ${WebColors.interactivePrimary};
    color: ${WebColors.contentInvertedPrimary};
  }
`

export type IStyledSingleDatePickerProps = SingleDatePickerShape & {
  openDirection?: OpenDirectionShape
  style?: CSSProperties
  dateFormat?: string
}

export const StyledSingleDatePicker = ({
  style,
  focused,
  onFocusChange,
  dateFormat = defaultDateFormat,
  ...rest
}: IStyledSingleDatePickerProps) => {
  const [focus, setFocus] = useState(focused)

  // we add our own event listeners since react-dates does not have props to change key handlers
  useEffect(() => {
    const input: HTMLInputElement | null = document.querySelector('.SingleDatePickerInput')
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setFocus(false)
      }
      if (event.key === 'Enter') {
        setFocus(true)
      }
    }

    input?.addEventListener('keydown', handleKeyDown)

    return () => input?.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <OverrideStyles style={style} $isFullWidth={dateFormat !== defaultDateFormat}>
      <SingleDatePicker
        focused={focus}
        onFocusChange={({ focused }) => setFocus(focused)}
        displayFormat={dateFormat}
        isOutsideRange={() => false}
        hideKeyboardShortcutsPanel={true}
        screenReaderInputMessage={st.date.dateInputMessage()}
        {...rest}
      />
    </OverrideStyles>
  )
}
StyledSingleDatePicker.displayName = 'StyledSingleDatePicker'

export const StyledDateRangePicker = (props: DateRangePickerShape) => (
  <OverrideStyles>
    <DateRangePicker
      displayFormat={defaultDateFormat}
      startDatePlaceholderText={defaultDateFormat}
      endDatePlaceholderText={defaultDateFormat}
      numberOfMonths={1}
      isDayBlocked={() => false}
      isOutsideRange={() => false}
      horizontalMargin={20}
      minimumNights={0}
      {...props}
    />
  </OverrideStyles>
)
