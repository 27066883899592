import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum AuthorizationGroupPermissionSet {
  View = 'authorization_group_view',
  Write = 'authorization_group_write',
  Delete = 'authorization_group_delete',
}

export const authorizationGroupPermissionSetGrouping: IPermissionSetGrouping<AuthorizationGroupPermissionSet> = {
  key: PermissionSetGroupingKey.AuthorizationGroup,
  active: {
    [AuthorizationGroupPermissionSet.View]: [
      PermissionType.GET_ANY_AUTHORIZATION_GROUP,
      PermissionType.GET_ANY_AUTHORIZATION_PERMISSION_SET,
    ],
    [AuthorizationGroupPermissionSet.Write]: [PermissionType.UPDATE_ANY_AUTHORIZATION_GROUP],
    [AuthorizationGroupPermissionSet.Delete]: [PermissionType.DELETE_ANY_AUTHORIZATION_GROUP],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
