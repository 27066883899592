import { FIXED_TIME_CONSTRAINT_BUFFER } from '../request'
import { IAbsoluteTimeConstraint, TimeConstraintType } from '../scheduling/TimeConstraintTypes'

export class RouteStopConstraints {
  public static build(initialScheduledTs: number): IAbsoluteTimeConstraint {
    return {
      type: TimeConstraintType.Absolute,
      min: initialScheduledTs - FIXED_TIME_CONSTRAINT_BUFFER,
      desired: initialScheduledTs,
      max: initialScheduledTs + FIXED_TIME_CONSTRAINT_BUFFER,
    }
  }
}
