import { WebColors } from '@sparelabs/colors'
import React from 'react'
import { constants } from 'src/env/Environment'
import styled from 'styled-components'
import { FullWidthContainer } from '../Layout'

const FooterContainer = styled(FullWidthContainer)`
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: ${WebColors.contentSecondary};
  height: 100px;
  padding: 25px;
`

export const Footer = (): JSX.Element => (
  <FooterContainer>
    <div>
      {' • • • '}
      <span style={{ marginLeft: '1em', marginRight: '1em' }}>{`©${new Date().getFullYear()} Spare Labs Inc`}</span>
      {' • • • '}
    </div>
    <span>{`v${constants.version}`}</span>
  </FooterContainer>
)
