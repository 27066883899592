import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IRequestRecurrenceListQueryParams,
  IRequestRecurrencePatchBody,
  IRequestRecurrencePostBody,
  IRequestRecurrenceResponse,
  Resources,
} from '../types'

export class RequestRecurrenceResource extends GenericResource<
  IRequestRecurrenceResponse,
  IRequestRecurrenceListQueryParams,
  IRequestRecurrencePatchBody,
  IRequestRecurrencePostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.RequestRecurrences, agent)
  }
}
