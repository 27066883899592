import DOMPurify from 'dompurify'
import { marked } from 'marked'
import { MarkedRendererBuilder } from './MarkedRendererBuilder'

export class MarkdownConverter {
  public readonly sanitizer: DOMPurify.DOMPurifyI

  constructor(domWindow: Window = window) {
    this.sanitizer = this.buildSanitizer(domWindow)
  }

  public convertToHtml(markdown: string, printable = false, options: marked.MarkedOptions = {}): string {
    const rendererOverrides = {
      ...MarkedRendererBuilder.DEFAULT_OVERRIDES,
      ...(printable ? MarkedRendererBuilder.PRINT_OVERRIDES : {}),
    }
    const mergedOptions = {
      gfm: true,
      breaks: true,
      renderer: MarkedRendererBuilder.build(rendererOverrides),
      ...options,
    }

    return this.sanitizeHtml(marked.parse(markdown, mergedOptions))
  }

  public convertToDom(markdown: string, printable = false, options: marked.MarkedOptions = {}): HTMLElement {
    const rendererOverrides = {
      ...MarkedRendererBuilder.DEFAULT_OVERRIDES,
      ...(printable ? MarkedRendererBuilder.PRINT_OVERRIDES : {}),
    }
    const mergedOptions = {
      gfm: true,
      breaks: true,
      renderer: MarkedRendererBuilder.build(rendererOverrides),
      ...options,
    }

    const html = this.convertToHtml(markdown, printable, mergedOptions)
    return this.sanitizer.sanitize(html, { RETURN_DOM: true })
  }

  public sanitizeHtml(html: string): string {
    return this.sanitizer.sanitize(html)
  }

  private buildSanitizer(window: Window): DOMPurify.DOMPurifyI {
    const sanitizer = DOMPurify(window)

    // For all anchor tags with a link, open the link in a new tab
    sanitizer.addHook('afterSanitizeAttributes', (node) => {
      if (node.tagName === 'A' && 'href' in node) {
        node.setAttribute('target', '_blank')
        node.setAttribute('rel', 'noopener noreferrer')
      }
    })

    return sanitizer
  }
}
