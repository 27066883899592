import { GenericResource, IListResponse } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IAddWalletBalancePostBody,
  IAddWalletBalanceResponse,
  IPaymentMethodQueryParams,
  IPaymentMethodResponse,
  IWalletTransactionResponse,
  Resources,
} from '../types'

export class PaymentMethodResource extends GenericResource<
  IPaymentMethodResponse,
  IPaymentMethodQueryParams,
  never,
  never
> {
  public get = this.buildGet()
  public list = this.buildList()
  public del = this.buildDel()

  public async getWalletTransactions(id: string): Promise<IListResponse<IWalletTransactionResponse>> {
    const { data } = await this.agent.get<IListResponse<IWalletTransactionResponse>>(
      `${this.resource}/${id}/walletTransactions`
    )

    return data
  }

  public async addBalance(body: IAddWalletBalancePostBody): Promise<IAddWalletBalanceResponse> {
    const { data } = await this.agent.post<IAddWalletBalanceResponse>(`${this.resource}/wallet/addBalance`, body)

    return data
  }

  constructor(agent: AxiosInstance) {
    super(Resources.PaymentMethods, agent)
  }
}
