import { BadArgumentsError, MetadataError } from '@sparelabs/error-types'
import { Random, RandomClass } from '@sparelabs/random'

type Weighted<K> = K & { weight: number }

/**
 * Randomly sample items from a list by their relative weights.
 * Any items without weights get their weights automatically set to 1.
 */
export class WeightedDistributionSampler<T> {
  public static DEFAULT_WEIGHT = 1

  private readonly cumulativeWeights: number[]
  private readonly sumOfWeights: number

  private readonly random: RandomClass

  constructor(protected readonly items: Array<Weighted<T>>, seed?: string) {
    this.cumulativeWeights = new Array<number>(items.length)
    let runningTotal = 0

    for (const [index, item] of items.entries()) {
      if (item.weight < 0) {
        throw new BadArgumentsError('Negative weights are not allowed', { item })
      }
      if (isNaN(item.weight) && item.weight !== undefined) {
        throw new BadArgumentsError('Weight must be a number', { item })
      }
      // If there is no weight, set it to the default value
      runningTotal += item.weight === undefined ? WeightedDistributionSampler.DEFAULT_WEIGHT : item.weight
      this.cumulativeWeights[index] = runningTotal
    }
    this.sumOfWeights = runningTotal

    if (runningTotal === 0) {
      throw new BadArgumentsError('Sum of weights must be greater than zero')
    }

    this.random = seed ? new RandomClass(seed) : Random
  }

  public sample(): T {
    const randomValue = this.random.floatBetween(0, this.sumOfWeights)
    for (let index = 0; index < this.items.length; index++) {
      if (randomValue <= this.cumulativeWeights[index]) {
        const { weight, ...item } = this.items[index]
        return item as unknown as T
      }
    }
    // this should be unreachable code
    throw new MetadataError('Unexpected: sampled a random value greater than sum of weights', {
      randomValue,
      sumOfWeights: this.sumOfWeights,
    })
  }
}
