export enum FeatureFlag {
  Promos = 'promos',
  DisableDriverAppFlagDown = 'disableDriverAppFlagDown',
  AllowMatchingConfiguration = 'allowMatchingConfiguration',
  FarePasses = 'farePasses',
  VoiceNotifications = 'voiceNotifications',
  UseDriverAppBearing = 'useDriverAppBearing',
  DriverInAppCalling = 'driverInAppCalling',
  DemandForecasting = 'demandForecasting',
  DisableNavigationIos = 'disableNavigationIos',
  DisableNavigationAndroid = 'disableNavigationAndroid',
  DisplayDriveUntilMessaging = 'displayDriveUntilMessaging',
  EngageAIScan = 'engageAIScan',
  DisableRiderOnboarding = 'disableRiderOnboarding',
  FareRules = 'fareRules',
  TipPolicies = 'tipPolicies',
  Routes = 'routes',
  ShowAllSlotsToDriver = 'showAllSlotsToDriver',
  Multimodal = 'multimodal',
  ExcludeWalkingTimeInDesiredTs = 'excludeWalkingTimeInDesiredTs',
  RequestInsights = 'requestInsights',
  AllowBookingWithoutPaymentMethod = 'allowBookingWithoutPaymentMethod',
  DriverTripHistory = 'driverTripHistory',
  DisableEditingAddingVehicle = 'disableEditingAddingVehicle',
  EngineV2GlobalOptimization = 'engineV2GlobalOptimization',
  OpenFleet = 'openFleet',
  StripeCustomerDetails = 'stripeCustomerDetails',
  MergeZonesOnLiveMap = 'mergeZonesOnLiveMap',
  MergeZonesOnServicesPage = 'mergeZonesOnServicesPage',
  MergeZonesOnDutyTimeTravel = 'mergeZonesOnDutyTimeTravel',
  ConstraintOverrides = 'constraintOverrides',
  LiveGlobalOptimization = 'liveGlobalOptimization',
  LyftPromotion = 'lyftPromotion',
  MultimodalTransitHubs = 'multimodalTransitHubs',
  DriverBreaks = 'driverBreaks',
  AutoCompleteOnlyWhenAtEndLocation = 'autoCompleteOnlyWhenAtEndLocation',
  LyftPassLink = 'lyftPassLink',
  ReportTemplate = 'reportTemplate',
  LockToDuty = 'lockToDuty',
  InAppTesting = 'inAppTesting',
  EtaChangedNotifications = 'etaChangedNotifications',
  CapMetroCustomRoles = 'capMetroCustomRoles',
  CustomRoles = 'customRoles',
  CompleteActivityLog = 'completeActivityLog',
  FixedRouteTravelDuration = 'fixedRouteTravelDuration',
  DriverRiderReviews = 'driverRiderReviews',
  Announcements = 'announcements',
  OrganizationPrivacyConfigurations = 'organizationPrivacyConfigurations',
  SMSRiderNotifications = 'smsRiderNotifications',
  WalkingDirections = 'walkingDirections',
  RecurringTrips = 'recurringTrips',
  OptimizationInsights = 'optimizationInsights',
  PaymentMethodTypePurchaseLink = 'paymentMethodTypePurchaseLink',
  SmsEta = 'smsEta',
  SmsCancelRide = 'smsCancelRide',
  PhoneIvrEta = 'PhoneIvrEta',
  PhoneIvrBookRide = 'PhoneIvrBookRide',
  PhoneIvrCancelRide = 'PhoneIvrCancelRide',
  ManuallyTriggeredOptimization = 'manuallyTriggeredOptimization',
  BulkMessages = 'bulkMessages',
  FixedRouteAlternativesInLaunch = 'fixedRouteAlternativesInLaunch',
  LanguageAndChannelPreferences = 'languageAndChannelPreferences',
  ReduceEngineBinLocking = 'reduceEngineBinLocking',
  CapMetroGroupExpiryNotifications = 'capMetroGroupExpiryNotifications',
  RenameAccessibilityFeatures = 'renameAccessibilityFeatures',
  MoreFrequentOptimization = 'moreFrequentOptimization',
  CallForVerificationCode = 'callForVerificationCode',
  ShowDefaultRequestPreferences = 'showDefaultRequestPreferences',
  IvrCallRecording = 'ivrCallRecording',
  LiveTraffic = 'liveTraffic',
  LocationNotes = 'locationNotes',
  DriverDispatchCommunication = 'driverDispatchCommunication',
  DriverIdleMap = 'driverIdleMap',
  EngageCasesOnRider = 'engageCasesOnRider',
  ProblemTripsBackgroundMatching = 'problemTripsBackgroundMatching',
  DriverShowRiderFullName = 'driverShowRiderFullName',
  DisableRiderAppPhotoEditing = 'disableRiderAppPhotoEditing',
  SchedulingBeforeEstimates = 'schedulingBeforeEstimates',
  ForceMatch = 'forceMatch',
  UpdatedRiderInterface = 'updatedRiderInterface',
  UnscheduledDriving = 'unscheduledDriving',
  AcceptRejectFleet = 'acceptRejectFleet',
  EnableRiderAppMixpanelAnalytics = 'enableRiderAppMixpanelAnalytics',
  PaymentMethodTypesInRiderApp = 'paymentMethodTypesInRiderApp',
  AutoOverrideConstraints = 'autoOverrideConstraints',
  DisableUpdatingDefaultProfileFields = 'disableUpdatingDefaultProfileFields',
  MetabaseReports = 'metabaseReports',
  ConditionalEligibility = 'conditionalEligibility',
  DisplayOnlyPickupTimeInRideOptions = 'displayOnlyPickupTimeInRideOptions',
  DataReconciliation = 'dataReconciliation',
  ManualInterventions = 'manualInterventions',
  DisableImprovedTimeRules = 'disableImprovedTimeRules',
  MultiEstimateRider = 'multiEstimateRider',
  MergeZonesOnRider = 'mergeZonesOnRider',
  DutyPulloutVisibility = 'dutyPulloutVisibility',
  DisablePleoLiveMessage = 'disablePleoLiveMessage',
  DisablePleoManualInterventions = 'disablePleoManualInterventions',
  ValidatePickupAndDropoffLocationsRider = 'validatePickupAndDropoffLocationsRider',
  ShowCapacityRules = 'showCapacityRules',
  VehicleTypes = 'vehicleTypes',
  VehicleClass = 'vehicleClass',
  PSTAGroupExpiryNotifications = 'PSTAGroupExpiryNotifications',
  GATRAGroupExpiryNotifications = 'GATRAGroupExpiryNotifications',
  RiderPhonePin = 'riderPhonePin',
  LaunchAIVoice = 'launchAIVoice',
  EngageAIVoice = 'engageAIVoice',
  RiderStripePaymentSDK = 'riderStripePaymentSDK',
  Wallet = 'wallet',
  RiderConfigurableOnboarding = 'riderConfigurableOnboarding',
  VehicleInspections = 'vehicleInspections',
  RiderShowNewStopMarkers = 'riderShowNewStopMarkers',
  RiderZoneRestrictionByGroups = 'riderZoneRestrictionByGroups',
  HideStopDescriptionsOnRiderTimeline = 'hideStopDescriptionsOnRiderTimeline',
  LiveRequest = 'liveRequest',
  DisableLocationNotesInRiderApp = 'disableLocationNotesInRiderApp',
  DataRetention = 'dataRetention',
  BookingCopilot = 'bookingCopilot',
  requestViewV2 = 'requestViewV2',
  CustomCancellationReasons = 'customCancellationReasons',
  DisableConditionalEligibilityBanner = 'disableConditionalEligibilityBanner',
  EnableRiderAppDriverPolyline = 'enableRiderAppDriverPolyline',
  AnalyzeCopilot = 'analyzeCopilot',
  DutyLatenessByBins = 'dutyLatenessByBins',
  AllowIncompleteForms = 'allowIncompleteForms',
  AiVoiceClaudeModel = 'aiVoiceClaudeModel',
  Tickets = 'tickets',
  AiVoiceGpt4OMini = 'aiVoiceGpt4OMini',
  MultipleWorkflows = 'multipleWorkflows',
  GtfsFlexExportImprovements = 'gtfsFlexExportImprovements',
  GCPGateway = 'gcpGateway',
  ZoneStopLibrary = 'zoneStopLibrary',
  RiderNewScheduleTripFlow = 'riderNewScheduleTripFlow',
  RiderNewScheduleButton = 'riderNewScheduleButton',
  EngageCaseViewV2 = 'engageCaseViewV2',
}
