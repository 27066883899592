import { BoundsError, MetadataError } from '@sparelabs/error-types'
import { IPoint } from '@sparelabs/geography'
import { zip } from 'lodash'
import { SlotType } from '../types'

export interface ISlotLocationInfo {
  type: SlotType
  scheduledLocation?: IPoint | null
}

export type LocationRequired<T extends { scheduledLocation?: IPoint | null }> = T & {
  scheduledLocation: IPoint
}

export class SlotListLocationExtractor {
  public static populateLocations<T extends ISlotLocationInfo>(slots: T[]): Array<LocationRequired<T>> {
    const allLocations = this.extractLocations(slots)
    if (allLocations.length === 0) {
      return []
    }

    const slotsWithLocations = zip(slots, allLocations).map(([slot, location]): LocationRequired<T> => {
      // If the slot already has a scheduled location, then there is no need
      // to use the generated location from the list (in case extractLocations has a bug???)
      const scheduledLocation = (slot as T).scheduledLocation || location
      return { ...slot, scheduledLocation } as LocationRequired<T>
    })
    return slotsWithLocations
  }

  /**
   * scheduledLocation is an optional field on slots; this class encapsulates
   * the logic for how to extract a list of locations from a list of slots.
   *
   * Returns an empty array if it is not possible to extract locations from the list of slots.
   */
  public static extractLocations(slots: ISlotLocationInfo[]): IPoint[] {
    if (slots.length === 0 || slots.every((slot) => !slot.scheduledLocation)) {
      return []
    }

    const locations: IPoint[] = []
    for (const [index, slot] of slots.entries()) {
      if (slot.scheduledLocation) {
        locations.push(slot.scheduledLocation)
      } else {
        locations.push(this.getLocationFromNeighbor(index, Array.from(slots)))
      }
    }
    return locations
  }

  private static getLocationFromNeighbor(slotIndex: number, slots: ISlotLocationInfo[]): IPoint {
    if (slotIndex < 0 || slotIndex >= slots.length) {
      throw new BoundsError('Array index out of bounds', { index: slotIndex, array: slots })
    }
    const slot = slots[slotIndex]

    if (slot.scheduledLocation) {
      return slot.scheduledLocation
    }
    // reduce the candidate set to avoid the possibility of infinite recursion
    // we clone to avoid in-place modification
    slots.splice(slotIndex, 1)

    // We make recursive calls below in case there are multiple slots without locations next to each other
    switch (slot.type) {
      case SlotType.StartLocation:
        // since we removed the start slot, the next slot after it is now at the same index as it was previously
        return this.getLocationFromNeighbor(slotIndex, slots)
      case SlotType.EndLocation:
        // removing the end slot shouldn't affect the index of its neighbors that come before it
        return this.getLocationFromNeighbor(slotIndex - 1, slots)
      default:
        throw new MetadataError(`Slot of type ${slot.type} should always have a scheduled location`, { slot })
    }
  }
}
