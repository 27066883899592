import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum WebhookPermissionSet {
  View = 'webhook_view',
  Write = 'webhook_write',
}

export const webhookPermissionSetGrouping: IPermissionSetGrouping<WebhookPermissionSet> = {
  key: PermissionSetGroupingKey.Webhook,
  active: {
    [WebhookPermissionSet.View]: [PermissionType.GET_ANY_WEBHOOK],
    [WebhookPermissionSet.Write]: [PermissionType.WRITE_ANY_WEBHOOK, PermissionType.DELETE_ANY_WEBHOOK],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
