import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IVehicleInspectionTemplatePatchBody,
  IVehicleInspectionTemplatePostBody,
  IVehicleInspectionTemplateQueryParams,
  IVehicleInspectionTemplateResponse,
  Resources,
} from '../types'

export class VehicleInspectionTemplateResource extends GenericResource<
  IVehicleInspectionTemplateResponse,
  IVehicleInspectionTemplateQueryParams,
  IVehicleInspectionTemplatePatchBody,
  IVehicleInspectionTemplatePostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  constructor(agent: AxiosInstance) {
    super(Resources.VehicleInspectionTemplates, agent)
  }
}
