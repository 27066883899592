import { AxiosInstance } from 'axios'
import {
  IOverrideEstimateNextAvailableQueryParams,
  IOverrideEstimateOutput,
  IOverrideEstimateScheduledQueryParams,
  Resources,
} from '../types'

export class EstimateOverrideResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async getNextAvailable(params: IOverrideEstimateNextAvailableQueryParams): Promise<IOverrideEstimateOutput> {
    const res = await this.agent.get<IOverrideEstimateOutput>(`${Resources.Estimates}/nextAvailable/override`, {
      params,
    })
    return res.data
  }

  public async getScheduled(params: IOverrideEstimateScheduledQueryParams): Promise<IOverrideEstimateOutput> {
    const res = await this.agent.get<IOverrideEstimateOutput>(`${Resources.Estimates}/request/override`, { params })
    return res.data
  }
}
