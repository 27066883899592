import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { IUserStub } from './UserTypes'

export enum ReviewRole {
  Rider = 'rider',
  Driver = 'driver',
}

export interface IReviewStubResponse extends IModelJsonValues {
  rating: number
  comment: string | null
  completed: boolean
  requestId: string
  tags: ReviewTag[]
}

export interface IReviewResponse extends IReviewStubResponse {
  reviewee: IUserStub | null
  reviewerId: string
  revieweeId: string | null
}

export interface IReviewPostBody {
  rating: number
  comment?: string | null
  requestId: string
  reviewerId: string
}

export enum ReviewTag {
  AppExperience = 'appExperience',
  Professionalism = 'professionalism',
  AboveAndBeyond = 'aboveAndBeyond',
  Comfort = 'comfort',
  Driving = 'driving',
  OnTime = 'onTime',
  Traffic = 'traffic',
  LateDriver = 'lateDriver',
  Dropoff = 'dropoff',
  Pickup = 'pickup',
  BusyService = 'busyService',
  NoShow = 'noShow',
  StopLocation = 'stopLocation',
  Price = 'price',
  TransitConnection = 'transitConnection',
}

export interface IReviewListQueryParams extends IListModelQueryParamValues {
  reviewerId?: string
  revieweeId?: string | null
  rating?: number
  hasComment?: boolean
  fromRating?: number
  toRating?: number
}
