import { IModelJsonValues } from '@sparelabs/http-client-utils'
import { IPaymentMethodResponse } from './PaymentTypes'
import { IRequestResponse } from './RequestTypes'
import { IVehicleLocationResponse } from './VehicleLocationTypes'

/**
 * @deprecated all webhooks will eventually be sent out via the `app-service` and use `AppWebhookType`
 */
export enum WebhookType {
  RequestStatus = 'requestStatus',
  VehicleLocation = 'vehicleLocation',
  Eta = 'eta',
  PaymentMethodCreated = 'paymentMethodCreated',
  PaymentMethodArchived = 'paymentMethodArchived',
  DutyMatchingStatus = 'dutyMatchingStatus',
  RiderCreated = 'riderCreated',
  RiderUpdated = 'riderUpdated',
}

export interface IWebhookHeader {
  key: string
  value: string
}

export interface IWebhookPostBody {
  types: WebhookType[]
  headers: IWebhookHeader[]
  url: string
}

export interface IWebhookPatchBody {
  types?: WebhookType[]
  headers?: IWebhookHeader[]
  url?: string
}

export interface IWebhookResponse extends IModelJsonValues {
  types: WebhookType[]
  headers: IWebhookHeader[]
  url: string
}

export interface IRequestEtaUpdate {
  requestId: string
  pickupETA: number | null
  dropoffETA: number | null
}

export interface IETAWebhookData {
  updates: IRequestEtaUpdate[]
}

export interface IWebhookVehicleLocationBody {
  type: WebhookType.VehicleLocation
  data: IVehicleLocationResponse
}

export interface IWebhookEtaBody {
  type: WebhookType.Eta
  data: IETAWebhookData
}

export interface IWebhookRequestStatusBody {
  type: WebhookType.RequestStatus
  data: IRequestResponse
}

export interface IWebhookPaymentMethodBody {
  type: WebhookType.PaymentMethodArchived | WebhookType.PaymentMethodCreated
  data: IPaymentMethodResponse
}

export interface IWebhookDutyMatchingStatusData {
  dutyId: string
  isMatchingEnabled: boolean
}

export interface IWebhookDutyMatchingStatusBody {
  type: WebhookType.DutyMatchingStatus
  data: IWebhookDutyMatchingStatusData
}

export interface IWebhookRiderCreatedBody {
  type: WebhookType.RiderCreated
  data: { userId: string }
}

export interface IWebhookRiderUpdatedBody {
  type: WebhookType.RiderUpdated
  data: { userId: string }
}

export type WebhookBody =
  | IWebhookVehicleLocationBody
  | IWebhookEtaBody
  | IWebhookRequestStatusBody
  | IWebhookPaymentMethodBody
  | IWebhookDutyMatchingStatusBody
  | IWebhookRiderCreatedBody
  | IWebhookRiderUpdatedBody
