export interface ILogger {
  namespace: string
  error(message: string, payload?: ILogPayload): void
  warn(message: string, payload?: ILogPayload): void
  info(message: string, payload?: ILogPayload): void
  trace(message: string, payload?: ILogPayload): void
  isLevelEnabled(level: LogLevel): boolean
}

export interface ILoggerBuildOptions {
  parentLogger?: ILogger
  defaultPayload?: Record<string, unknown>
}

export enum LogLevel {
  Error = 'error',
  Warn = 'warn',
  Info = 'info',
  Trace = 'trace',
}

// GCP format for logging information about HTTP requests: https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#HttpRequest
export interface IHttpRequestLogPayload {
  requestMethod?: string
  requestUrl?: string
  requestSize?: string
  status?: number
  responseSize?: string
  userAgent?: string
  remoteIp?: string
  serverIp?: string
  referer?: string
  latency?: string
  cacheLookup?: boolean
  cacheHit?: boolean
  cacheValidatedWithOriginServer?: boolean
  cacheFillBytes?: string
  protocol?: string
}

export interface ILogPayload {
  error?: unknown
  httpRequest?: IHttpRequestLogPayload
  [key: string]: unknown
}
