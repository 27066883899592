/**
 * All possible models for all reports
 */
export enum AllReportsModels {
  Duty = 'duty',
  Request = 'request',
  Additional = 'additional',
  Rider = 'rider',
  AccessibilityFeatures = 'accessibilityFeatures',
  Driver = 'driver',
  Vehicle = 'vehicle',
  Service = 'service',
  Fleet = 'fleet',
  Estimate = 'estimate',
  Zone = 'zone',
  Slot = 'slot',
  Fare = 'fare',
  Tip = 'tip',
  PaymentMethod = 'paymentMethod',
  Promotion = 'promotion',
  Review = 'review',
  FarePass = 'farePass',
  Boarding = 'boarding',
  Break = 'break',
  Case = 'case',
  Event = 'event',
  OpenFleet = 'openFleet',
  CaseLetter = 'caseLetter',
  CaseType = 'caseType',
  Charge = 'charge',
  GroupMembership = 'groupMembership',
}
