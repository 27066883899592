import { WebColors } from '@sparelabs/colors'
import styled from 'styled-components'
import { PopoverContentCSS } from '../../popovers'
import { borderRadius } from '../../util'

export const MarkdownInputContainer = styled.div<{ height?: string; width?: string }>`
  ${({ width }) => (width ? `width: ${width};` : '')};

  .EasyMDEContainer .CodeMirror {
    border-color: ${WebColors.borderPrimary};
    border-bottom-left-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
    padding-top: 0;
    ${({ height }) => (height ? `height: ${height};` : '')};
  }

  .editor-toolbar {
    border-color: ${WebColors.borderPrimary};
    border-top-left-radius: ${borderRadius};
    border-top-right-radius: ${borderRadius};
    background-color: ${WebColors.backgroundPrimary};

    i.separator {
      border-left-color: ${WebColors.borderPrimary};
    }

    .easymde-dropdown,
    button {
      color: ${WebColors.contentPrimary};
      border-radius: 3px;

      &.active,
      &:hover {
        border-color: ${WebColors.borderPrimary};
      }

      &.variables {
        font-weight: normal;
        font-size: 15px;
        background: none;
        padding: 0 8px;
      }
    }

    .easymde-dropdown-content {
      ${PopoverContentCSS};
      top: 28px;
      left: 0;
      overflow: auto;
      max-height: 400px;

      button {
        padding: 8px 12px;
        font-weight: 400;
        height: auto;
        border: none;
        text-align: left;

        &:hover {
          background-color: ${WebColors.backgroundPrimaryHover};
        }
      }

      .group-heading {
        user-select: none;
        cursor: default;
        font-size: 13px;
        color: ${WebColors.contentSecondary};
        padding-top: 16px;

        &:hover {
          background-color: initial;
        }
      }
    }
  }
`
