import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { CreatedInterface } from './EstimateTypes'
import { Language } from './SharedTypes'

export enum NotificationType {
  RideReminder = 'rideReminder',
  RideReminderDayBefore = 'rideReminderDayBefore',
  RequestCreated = 'requestCreated',
  RideProcessingReminder = 'rideProcessingReminder',
  RideProcessingReminderDayBefore = 'rideProcessingReminderDayBefore',
  RideAccepted = 'rideAccepted',
  RideNoDriversAvailable = 'rideNoDriversAvailable',
  RideCancelled = 'rideCancelled',
  RideArriving = 'rideArriving',
  RideCompleted = 'rideCompleted',
  LatePickup = 'latePickup',
  EarlyPickup = 'earlyPickup',
  RideCancelledNoShow = 'rideCancelledNoShow',
  RideCancelledAdmin = 'rideCancelledAdmin',
  RideCancelledDriver = 'rideCancelledDriver',
  ImpossibleJourney = 'impossibleJourney',
}

export const NotificationSettingDefaultTemplates: Record<NotificationType, string> = {
  [NotificationType.RideAccepted]: 'Your ride {{{rideCurrentScheduledPickupTime}}} has been accepted',
  [NotificationType.RideCancelled]: 'Your ride {{{rideCurrentScheduledPickupTime}}} has been cancelled',
  [NotificationType.RideNoDriversAvailable]: 'We were not able to find a driver for your ride {{{rideRequestedTime}}}',
  [NotificationType.RideReminder]: 'Reminder that you have an accepted ride {{{rideCurrentScheduledPickupTime}}}',
  [NotificationType.RideReminderDayBefore]:
    'Reminder that you have an accepted ride {{{rideCurrentScheduledPickupTime}}}',
  [NotificationType.RequestCreated]:
    'Your ride {{{rideRequestedTime}}} from {{{rideRequestedPickupAddress}}} to {{{rideRequestedDropoffAddress}}} has been booked',
  [NotificationType.RideProcessingReminder]:
    'Reminder that you have requested a ride {{{rideRequestedTime}}} from {{{rideRequestedPickupAddress}}} to {{{rideRequestedDropoffAddress}}}',
  [NotificationType.RideProcessingReminderDayBefore]:
    'Reminder that you have requested a ride {{{rideRequestedTime}}} from {{{rideRequestedPickupAddress}}} to {{{rideRequestedDropoffAddress}}}',
  [NotificationType.RideArriving]: 'Your driver is arriving!',
  [NotificationType.RideCompleted]: 'You successfully completed your {{{rideCurrentScheduledPickupTime}}} trip!',
  [NotificationType.LatePickup]:
    'The estimated time of arrival for your ride {{{rideInitialScheduledPickupTime}}} is now {{{rideCurrentScheduledPickupTime}}}',
  [NotificationType.EarlyPickup]:
    'The estimated time of arrival for your ride {{{rideInitialScheduledPickupTime}}} is now {{{rideCurrentScheduledPickupTime}}}',
  [NotificationType.RideCancelledNoShow]:
    'Your ride {{{rideCurrentScheduledPickupTime}}} has been cancelled due to not being at {{{rideScheduledPickupAddress}}} at the above time',
  [NotificationType.RideCancelledAdmin]:
    'Your ride {{{rideCurrentScheduledPickupTime}}} has been cancelled by an admin',
  [NotificationType.RideCancelledDriver]:
    'Your ride {{{rideCurrentScheduledPickupTime}}} has been cancelled by the driver',
  [NotificationType.ImpossibleJourney]:
    "Your ride {{{rideCurrentScheduledPickupTime}}} wasn't possible anymore due to a change of schedules, please rebook it if needed",
}

export interface INotificationSettingResponse extends IModelJsonValues {
  notificationType: NotificationType
  deliveryMethod: NotificationDeliveryMethod
  template: string | null
  createdInterfaces: CreatedInterface[]
  status: NotificationSettingStatus
  serviceIds: string[]
}

export enum NotificationDeliveryMethod {
  Sms = 'sms',
  PhoneCall = 'phoneCall',
  Email = 'email',
  Push = 'push',
  PushFallbackSms = 'pushFallbackSms',
}

export interface INotificationContent {
  message: string
  subject?: string
}

export type INotificationMessages = Partial<Record<Language, INotificationContent>>

export interface INotificationSettingPostBody {
  notificationType: NotificationType
  createdInterfaces?: CreatedInterface[]
  deliveryMethod: NotificationDeliveryMethod
  template?: string | null
  defaultSubject?: string | null
  additionalMessages?: INotificationMessages
  serviceIds: string[]
}

export interface INotificationSettingPatchBody {
  notificationType?: NotificationType
  createdInterfaces?: CreatedInterface[]
  deliveryMethod?: NotificationDeliveryMethod
  template?: string | null
  defaultSubject?: string | null
  additionalMessages?: INotificationMessages
  status?: NotificationSettingStatus
  serviceIds?: string[]
}

export interface INotificationSettingQueryParams extends IListModelQueryParamValues {
  notificationType?: NotificationType
  deliveryMethod?: NotificationDeliveryMethod
  status?: NotificationSettingStatus
  serviceId?: string
}

export enum NotificationSettingStatus {
  Draft = 'draft',
  Published = 'published',
}

export enum RiderNotificationPreference {
  Trip = 'trip',
  Administrative = 'administrative',
}
