import { DAY } from '@sparelabs/time'

export class DutyValidation {
  public static readonly DURATION_LIMIT: number = DAY * 2 // 48 hours

  public static isDurationValid(startTs: number, endTs: number): boolean {
    const dutyDuration: number = endTs - startTs
    return dutyDuration <= this.DURATION_LIMIT
  }

  public static isStartBeforeEnd(startTs: number, endTs: number): boolean {
    return startTs < endTs
  }
}
