import { IGlobalOrganizationResponse } from '@sparelabs/api-client'
import { ICaseFormResponse, IPublicEngageFormResponse } from '@sparelabs/engage-client'
import React, { createContext, ReactNode, useState } from 'react'
import { FormStep, FormStepFlows, FormSubmitted } from './FormStepTypes'

export interface IFormContext {
  caseId?: string
  flow: FormStep[]
  flowIndex: number
  form: IPublicEngageFormResponse
  formSubmitted: FormSubmitted
  organization: IGlobalOrganizationResponse
  prevCaseForm?: ICaseFormResponse
  onNextStep: () => void
  onPrevStep: () => void
  goToStep: (step: FormStep) => void
  setCaseId: (caseId: string) => void
  setCaseForm: (caseForm: ICaseFormResponse | undefined, when: FormSubmitted) => void
}
const FormContext = createContext<IFormContext>({} as IFormContext)
export const useFormContext = (): IFormContext => React.useContext(FormContext)

interface IProps {
  children: ReactNode
  form: IPublicEngageFormResponse
  organization: IGlobalOrganizationResponse
  caseId?: string
}
export const FormContextProvider = ({ children, form, organization, caseId: inputCaseId }: IProps): JSX.Element => {
  const [caseId, setCaseId] = useState(inputCaseId)
  const [prevCaseForm, setPrevCaseForm] = useState<ICaseFormResponse | undefined>(undefined)
  const [formSubmitted, setFormSubmitted] = useState<FormSubmitted>(
    form.submitted ? FormSubmitted.Previously : FormSubmitted.Never
  )

  const flow = FormStepFlows[form.type]
  const [flowIndex, setFlowIndex] = useState(0)

  const onNextStep = () => setFlowIndex((prev) => (prev < flow.length - 1 ? prev + 1 : prev))
  const onPrevStep = () => setFlowIndex((prev) => (prev > 0 ? prev - 1 : prev))
  const goToStep = (step: FormStep) => {
    const newFlowIndex = flow.findIndex((flowStep) => flowStep === step)
    setFlowIndex(newFlowIndex < 0 ? 0 : newFlowIndex)
  }
  const setCaseForm = (caseForm: ICaseFormResponse | undefined, when: FormSubmitted) => {
    setPrevCaseForm(caseForm)
    setFormSubmitted(when)
    // Update the caseId to support editing the submission within the same session
    if (caseForm?.caseId !== caseId) {
      setCaseId(caseForm?.caseId)
    }
  }

  return (
    <FormContext.Provider
      value={{
        caseId,
        flow,
        flowIndex,
        form,
        formSubmitted,
        organization,
        prevCaseForm,
        onNextStep,
        onPrevStep,
        goToStep,
        setCaseId,
        setCaseForm,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}
