import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IListRiderOnboardingQueryParams,
  IRiderOnboardingPatchBody,
  IRiderOnboardingPostBody,
  IRiderOnboardingResponse,
  Resources,
} from '../types'

export class RiderOnboardingResource extends GenericResource<
  IRiderOnboardingResponse,
  IListRiderOnboardingQueryParams,
  IRiderOnboardingPatchBody,
  IRiderOnboardingPostBody
> {
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()

  constructor(agent: AxiosInstance) {
    super(Resources.RiderOnboardingFlow, agent)
  }
}
