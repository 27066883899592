import { PermissionType } from './PermissionType'

export enum PermissionSetGroupingKey {
  AdminUser = 'AdminUser',
  AiAgent = 'AiAgent',
  Analytics = 'Analytics',
  Announcement = 'Announcement',
  ApiKey = 'ApiKey',
  App = 'App',
  AuthorizationGroup = 'AuthorizationGroup',
  BulkMessage = 'BulkMessage',
  CancellationReason = 'CancellationReason',
  Case = 'Case',
  CaseType = 'CaseType',
  Charge = 'Charge',
  ConnectedAccount = 'ConnectedAccount',
  DataRetentionSchedule = 'DataRetentionSchedule',
  Device = 'Device',
  Driver = 'Driver',
  DriverUser = 'DriverUser',
  Duty = 'Duty',
  DutyRequestOffer = 'DutyRequestOffer',
  EngageReporting = 'EngageReporting',
  Event = 'Event',
  FarePass = 'FarePass',
  FavoriteLocation = 'FavoriteLocation',
  Form = 'Form',
  Group = 'Group',
  Ivr = 'Ivr',
  Journey = 'Journey',
  Launch = 'Launch',
  LaunchReporting = 'LaunchReporting',
  Letter = 'Letter',
  NotificationSetting = 'NotificationSetting',
  NylasAdminGrant = 'NylasAdminGrant',
  MatchingOutcome = 'MatchingOutcome',
  OperationalAlerts = 'OperationalAlerts',
  Organization = 'Organization',
  PaymentMethod = 'PaymentMethod',
  PaymentMethodType = 'PaymentMethodType',
  Promo = 'Promo',
  Realize = 'Realize',
  Request = 'Request',
  Review = 'Review',
  Rider = 'Rider',
  RiderDriverRestriction = 'RiderDriverRestriction',
  RiderOnboardingFlow = 'RiderOnboardingFlow',
  RiderVehicleTypeRestriction = 'RiderVehicleTypeRestriction',
  RiderUser = 'RiderUser',
  Service = 'Service',
  Simulation = 'Simulation',
  Ticket = 'Ticket',
  Tip = 'Tip',
  UserBan = 'UserBan',
  UserFleetAgreement = 'UserFleetAgreement',
  UserOwn = 'UserOwn',
  Vehicle = 'Vehicle',
  VehicleType = 'VehicleType',
  Webhook = 'Webhook',
  Workflow = 'Workflow',
}

export interface IPermissionSetGrouping<T extends string> {
  key: PermissionSetGroupingKey
  active: Record<T, PermissionType[]>

  /**
   * Determines which permission sets are hidden from the UI and cannot be assigned to users.
   * An example of un-assignable permission set is the `driver` or `rider` permission sets
   */
  unAssignablePermissionSets: T[]

  /**
   * Use this array to store any deprecated PermissionSet.
   *
   * This will be used to propagate the change to SpiceDB.
   * */
  deprecated: T[]
}
