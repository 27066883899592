import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { ILetterPatchBody, ILetterPostBody, ILetterResponse, IListLetterQueryParams } from '../types'
import { Resources } from './ResourcesType'

export class LetterResource extends GenericResource<
  ILetterResponse,
  IListLetterQueryParams,
  ILetterPatchBody,
  ILetterPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  constructor(agent: AxiosInstance) {
    super(Resources.Letters, agent)
  }
}
