// Why we disabled this rule: react-router-dom's typing of its 'to' prop conflicts with our unsafe-assignment-any rule
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WebColors } from '@sparelabs/colors'
import React, { CSSProperties } from 'react'
import { LinkProps, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { NavLinkStyles } from '../NavLinkStyles'

const BreadcrumbContainer = styled.nav`
  display: flex;
  gap: 8px;
  align-items: center;
`

const BreadcrumbItem = styled(NavLink)`
  ${NavLinkStyles}
  margin-top: 0;
  color: ${WebColors.contentSecondary} !important;
  cursor: 'pointer';
  &:hover {
    color: ${WebColors.interactivePrimary} !important;
  }
  &.selected {
    color: ${WebColors.interactivePrimary} !important;
  }
`

const BreadcrumbPlaceholder = styled.div`
  height: 20px;
  width: 20px;
`

const BreadcrumbItemSeparator = () => (
  <FontAwesomeIcon color={WebColors.borderSecondary} icon='chevron-right' size='sm' />
)

export interface IBreadcrumbItem {
  label: string
  to: LinkProps['to']
}

export interface IBreadcrumbProps {
  items: IBreadcrumbItem[]
  containerStyles?: CSSProperties
  onBreadcrumbClick?: (item: IBreadcrumbItem) => void
}

export const Breadcrumb = ({ items, containerStyles, onBreadcrumbClick }: IBreadcrumbProps): JSX.Element => (
  <BreadcrumbContainer aria-label='Breadcrumb' style={containerStyles}>
    {items.length === 0 ? (
      <BreadcrumbPlaceholder />
    ) : (
      items.map((item) => (
        <React.Fragment key={item.label}>
          <BreadcrumbItem to={item.to} onClick={() => onBreadcrumbClick && onBreadcrumbClick(item)}>
            {item.label}
          </BreadcrumbItem>
          <BreadcrumbItemSeparator />
        </React.Fragment>
      ))
    )}
  </BreadcrumbContainer>
)
