import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { ButtonIcon } from '../icons'
import { CONTENT_WIDTH_PX } from '../util'
import { IBannerTheme } from './BannerThemes'

interface IProps {
  theme: IBannerTheme
  children?: ReactNode
  className?: string
  parentPaddingPx?: number
  maxWidth?: string // Valid CSS property for max-width (e.g. '500px', 'none', '75%', '5em', etc.)
  fontSize?: string
}

const Container = styled(({ maxWidth, theme, fontSize, parentPaddingPx, ...rest }: IProps) => <div {...rest} />)`
  max-width: ${(props) => props.maxWidth};
  background-color: ${({ theme }: IProps) => theme.backgroundColor};
  color: ${({ theme }: IProps) => theme.textColor};
  border-radius: 8px;
  font-size: ${(props) => props.fontSize};
  padding: ${(props) => props.parentPaddingPx}px;
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-bottom: 8px;
  white-space: pre-line;
  background: ${({ theme }: IProps) => theme.background};
`

export const Banner = ({
  theme,
  children,
  className,
  parentPaddingPx = 16,
  maxWidth = `${CONTENT_WIDTH_PX}px`,
  fontSize = '15px',
}: IProps): JSX.Element => (
  <Container
    theme={theme}
    maxWidth={maxWidth}
    parentPaddingPx={parentPaddingPx}
    fontSize={fontSize}
    className={className}
  >
    {theme.icon && <ButtonIcon marginRight='16px' marginTop='4px' icon={theme.icon} color={theme.textColor} />}
    {children}
  </Container>
)
