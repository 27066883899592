import { partition, toString } from 'lodash'
import { MetadataError } from '../errors'

export interface ISuccess<ValueType> {
  success: true
  value: ValueType
}

export interface IFailure<ReasonType = string> {
  success: false
  reason: ReasonType
}

/**
 * Represents the concept that a function can return one type of output
 * if it runs "successfully", and a different type of output if it "fails".
 *
 * Note that there is some overlap between this pattern, and plain Javascript exception handling.
 * This pattern is preferred when we want to propagate specific failure information to the caller
 * in a systematic, type-safe way.
 */
export type SuccessOrFailure<ValueType, ReasonType = string> = ISuccess<ValueType> | IFailure<ReasonType>

export const success = <T>(value: T): ISuccess<T> => ({ success: true, value })
export const failure = <T>(reason: T): IFailure<T> => ({ success: false, reason })

/**
 * If the SuccessOrFailure is a success, gets the value. If it's a failure, throws
 *
 * @param successOrFailure
 * @returns
 */
export const getSuccess = <ValueType, ReasonType>(
  successOrFailure: SuccessOrFailure<ValueType, ReasonType>
): ValueType => {
  if (successOrFailure.success) {
    return successOrFailure.value
  }
  throw liftError(successOrFailure.reason)
}

export const partitionBySuccess = <ValueType, ReasonType>(
  successOrFailures: Array<SuccessOrFailure<ValueType, ReasonType>>
): [ValueType[], ReasonType[]] => {
  const [successes, failures] = partition(successOrFailures, (successOrFailure) => successOrFailure.success)
  return [
    successes.map((success) => (success as ISuccess<ValueType>).value),
    failures.map((failure) => (failure as IFailure<ReasonType>).reason),
  ]
}

export const successOrFailureFromPromise = async <T>(
  callback: () => Promise<T>
): Promise<SuccessOrFailure<T, Error>> => {
  try {
    const result = await callback()
    return success(result)
  } catch (error) {
    return failure(liftError(error))
  }
}

export const failIfEmpty = <T, R>(list: T[], reason: R): SuccessOrFailure<T[], R> =>
  list.length > 0 ? success(list) : failure(reason)

const liftError = (error: unknown): Error => {
  if (error instanceof Error) {
    return error
  }
  return new MetadataError(toString(error))
}

/**
 * @deprecated - should only be necessary in projects that currently lack strict null checks.
 * In those projects, the compiler is not smart enough to infer a type of IFailure when result.success is falsy,
 * as technically it could also be null or undefined. This helper function exists to avoid having to ignore the linter
 * with directives such as // @typescript-eslint/no-unnecessary-boolean-literal-compare.
 *
 * In the long term, all of our TS projects should use strict null checks, at which point this helper function can be
 * removed.
 */
export const isFailure = <ValueType, ReasonType>(
  result: SuccessOrFailure<ValueType, ReasonType>
): result is IFailure<ReasonType> => !result.success

/**
 * @deprecated - Same as above: necessary predicate for type narrowing until strict mode is fully generalized
 */
export const isSuccess = <ValueType, ReasonType>(
  result: SuccessOrFailure<ValueType, ReasonType>
): result is ISuccess<ValueType> => result.success
