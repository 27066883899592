import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IDeprecatedServiceFleetPostBody,
  IServiceFleetListQueryParams,
  IServiceFleetPatchBody,
  IServiceFleetPostBody,
  IServiceFleetResponse,
  Resources,
} from '../types'

export class DeprecatedServiceFleetResource extends GenericResource<
  IServiceFleetResponse,
  never,
  never,
  IDeprecatedServiceFleetPostBody
> {
  public post = this.buildPost()
  public del = this.buildDel()
  constructor(agent: AxiosInstance) {
    super(Resources.ServiceFleets, agent)
  }
}

export class ServiceFleetResource extends GenericResource<
  IServiceFleetResponse,
  IServiceFleetListQueryParams,
  IServiceFleetPatchBody,
  IServiceFleetPostBody
> {
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()
  public del = this.buildDel()
  constructor(agent: AxiosInstance) {
    super(Resources.ServiceFleets, agent)
  }
}
