import { IAddressSchema } from '@sparelabs/address'
import {
  IFileResponse,
  IFileResponseSchema,
  IListModelQueryParamValues,
  IModelJsonValuesSchema,
} from '@sparelabs/http-client-utils'
import { z } from '@sparelabs/zod'

export enum CaseLetterStatus {
  Active = 'active',
  Archived = 'archived',
}

export enum CaseLetterSentStatus {
  Ready = 'ready',
  Sent = 'sent',
}

export enum MailingAddressLabelStyle {
  Style5160 = '5160',
}

export interface ICaseLetterGenerationOptions {
  useLargeFont?: boolean
}

export const ICaseLetterEventSchema = z.object({
  id: z.string(),
  caseId: z.string(),
  letterId: z.string(),
  file: IFileResponseSchema,
  creatorId: z.string().nullable(),
  status: z.nativeEnum(CaseLetterStatus),
  sentById: z.string().nullable(),
  sentTs: z.number().nullable(),
  sentStatus: z.nativeEnum(CaseLetterSentStatus),
  mailingAddress: IAddressSchema.nullable(),
})

export const ICaseLetterResponseSchema = IModelJsonValuesSchema.extend(ICaseLetterEventSchema.shape)

export type ICaseLetterResponse = z.infer<typeof ICaseLetterResponseSchema>

export interface IListCaseLetterQueryParams extends IListModelQueryParamValues {
  caseId?: string
  letterId?: string
  creatorId?: string
  status?: CaseLetterStatus
  userId?: string
  caseTypeId?: string
  fromSentTs?: number
  toSentTs?: number
  sentStatus?: CaseLetterSentStatus[]
}

export interface ICaseLetterPostBody {
  caseId: string
  letterId: string
  // If the file is not provided by the frontend, the backend will generate it
  file?: IFileResponse
  generationOptions?: ICaseLetterGenerationOptions
}

export interface ICaseLetterPatchBody {
  status?: CaseLetterStatus
  sentTs?: number | null
  sentById?: string | null
  sentStatus?: CaseLetterSentStatus
}

export interface ICaseLetterDownloadBody {
  ids: string[]
  labelStyle: MailingAddressLabelStyle
}
