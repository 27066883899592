import { AllReportsModels } from './AnalyzeReportModelTypes'

export enum OpenFleetReportRequestColumns {
  RequestId = 'requestId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  ExternalId = 'externalId',
  Provider = 'provider',
  FleetId = 'fleetId',
  Product = 'product',
  RequestStatus = 'requestStatus',
  DistanceMeters = 'distanceMeters',
  DurationSeconds = 'durationSeconds',
  Pooled = 'pooled',
}

export enum OpenFleetReportCostColumns {
  EstimatedFareCents = 'estimatedFareCents',
  EstimatedFareCurrency = 'estimatedFareCurrency',
  FinalFareCents = 'finalFareCents',
  FinalFareCurrency = 'finalFareCurrency',
  FinalFareLineItems = 'finalFareLineItems',
}

export const AllOpenFleetModelsNamesArray = [AllReportsModels.Request, AllReportsModels.OpenFleet]

export type IOpenFleetReportColumns = OpenFleetReportRequestColumns | OpenFleetReportCostColumns

export const OpenFleetReportModelAndColumns = {
  [AllReportsModels.Request]: OpenFleetReportRequestColumns,
  [AllReportsModels.OpenFleet]: OpenFleetReportCostColumns,
}

export const OpenFleetReportColumnNames = Object.values<IOpenFleetReportColumns>(OpenFleetReportRequestColumns).concat(
  Object.values(OpenFleetReportCostColumns)
)
