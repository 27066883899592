import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum AnalyticsPermissionSet {
  Access = 'analytics_analytics',
}

export const analyticsPermissionSetGrouping: IPermissionSetGrouping<AnalyticsPermissionSet> = {
  key: PermissionSetGroupingKey.Analytics,
  active: {
    [AnalyticsPermissionSet.Access]: [
      PermissionType.LIST_METRICS,
      PermissionType.LIST_ANY_SERVICE,
      PermissionType.LIST_ANY_FLEET,
      PermissionType.RECEIVE_DAILY_REPORTS,
      PermissionType.RECEIVE_WEEKLY_REPORTS,
      PermissionType.POST_METABASE_STATIC_EMBED,
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
