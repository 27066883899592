import { ApiClient } from '@sparelabs/api-client'
import { EngageClient } from '@sparelabs/engage-client'
import { IApiClientConfiguration } from '@sparelabs/http-client-utils'
import { constants } from 'src/env/Environment'
import { AuthenticationHelper } from 'src/helpers/AuthenticationHelper'

const getDefaultConfiguration = (): Pick<IApiClientConfiguration, 'host' | 'token'> => ({
  host: AuthenticationHelper.getRegionalHost(),
  token: AuthenticationHelper.getUserToken(),
})

export class ApiClientBuilder {
  public static buildWithoutOrganizationContext(): ApiClient {
    return new ApiClient({ host: constants.apiUrl })
  }

  public static build(configuration?: Partial<IApiClientConfiguration>): ApiClient {
    return new ApiClient({
      ...getDefaultConfiguration(),
      ...configuration,
    })
  }
}

export class EngageClientBuilder {
  public static build(configuration?: Partial<IApiClientConfiguration>): EngageClient {
    return new EngageClient({
      ...getDefaultConfiguration(),
      ...configuration,
    })
  }
}
