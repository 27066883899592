import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { IUserStub } from './UserTypes'

export enum DutyRequestOfferStatus {
  Sent = 'sent',
  Seen = 'seen',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export interface IDutyRequestOfferResponse extends IModelJsonValues {
  dutyId: string
  requestId: string
  status: DutyRequestOfferStatus
  expiryTs: number
  sentTs: number
  seenTs: number | null
  receivedTs: number | null
  acceptedTs: number | null
  rejectedTs: number | null
  driverStub: IUserStub | null
}

export interface IDutyRequestOfferListQueryParams extends IListModelQueryParamValues {
  requestId?: string
  dutyId?: string
  status?: DutyRequestOfferStatus
}
