import { AxiosInstance } from 'axios'
import { IListModelQueryParamValues, IListResponse } from '../types'
import { AllPageFetcher } from './AllPageFetcher'

export type Resources = string

export class GenericResource<Response, ListParams extends IListModelQueryParamValues, PatchBody, PostBody> {
  protected readonly allPageFetcher: AllPageFetcher

  constructor(protected readonly resource: Resources, protected readonly agent: AxiosInstance) {
    this.allPageFetcher = new AllPageFetcher(agent)
  }

  protected buildGet() {
    return async (id: string): Promise<Response> => {
      const res = await this.agent.get<Response>(`${this.resource}/${id}`)
      return res.data
    }
  }

  protected buildList() {
    /**
     * @param params Parameters of the search query
     * @param fetchAllPages Will determine if we will fetch all pages sequentially in a loop.
     */
    return async (params: ListParams, fetchAllPages: boolean = false): Promise<IListResponse<Response>> => {
      if (params.ids && params.ids.length === 0) {
        return {
          limit: params.limit ?? 50,
          skip: params.skip ?? 0,
          total: 0,
          data: [],
        }
      }

      if (fetchAllPages) {
        const data = await this.allPageFetcher.fetch<Response, ListParams>(this.resource, params)
        return { data, skip: 0, total: data.length, limit: data.length }
      }

      const { data } = await this.agent.get<IListResponse<Response>>(this.resource, { params })
      return data
    }
  }

  protected buildPatch() {
    return async (id: string, body: PatchBody): Promise<Response> => {
      const res = await this.agent.patch<Response>(`${this.resource}/${id}`, body)
      return res.data
    }
  }

  protected buildPost() {
    return async (body: PostBody): Promise<Response> => {
      const res = await this.agent.post<Response>(this.resource, body)
      return res.data
    }
  }

  protected buildDel() {
    return async (id: string): Promise<Response> => {
      const res = await this.agent.delete<Response>(`${this.resource}/${id}`)
      return res.data
    }
  }
}
