import { WebColors } from '@sparelabs/colors'
import React from 'react'
import styled from 'styled-components'
import { DefaultButtonTheme, SubmitButtonTheme } from '../controls/ButtonThemes'
import { RoundedButton } from '../controls/RoundedButton'
import { breakpoints, overlay } from '../util'

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${overlay};
  z-index: 100;
  @media (orientation: portrait) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  min-height: 150px;
  background-color: ${WebColors.backgroundPrimary};
  padding: 24px;
  border-radius: 8px;

  margin-top: 20vh;
  width: 415px;
  @media (orientation: portrait) {
    margin-top: 0;
    width: 100vw;
    border-radius: 0;
  }

  @media only screen and (max-width: ${breakpoints.sm}) {
    padding: 24px 16px;
  }
`

const Title = styled.p`
  font-size: 21px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  color: ${WebColors.contentPrimary};
`

const Text = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 24px;
  color: ${WebColors.contentSecondary};
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: ${breakpoints.sm}) {
    justify-content: flex-start;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;

  @media only screen and (max-width: ${breakpoints.sm}) {
    flex-direction: column-reverse;
  }
`

// since flexbox gap doesn't have the best browser support - individually style child buttons to apply spacing
const Button = styled(RoundedButton)`
  margin-left: 8px;

  @media only screen and (max-width: ${breakpoints.sm}) {
    margin-left: 0;
    margin-top: 8px;
  }
`

export interface IAlertDialogProps {
  buttons: IAlertDialogButton[]
  title: string
  message?: string
}

// local definition of AlertButton from react-native lib
export interface IAlertDialogButton {
  text: string
  onPress: () => void
}

export const AlertDialog = ({ buttons, title, message }: IAlertDialogProps): JSX.Element => {
  const renderButtons = () =>
    buttons.map((button, index) => {
      const first = index === 0
      return (
        <Button key={index} onClick={button.onPress} theme={first ? SubmitButtonTheme : DefaultButtonTheme}>
          {button.text}
        </Button>
      )
    })

  return (
    <Overlay>
      <Container>
        <Title>{title}</Title>
        <Text>{message}</Text>
        <Footer>
          <ButtonGroup>{renderButtons()}</ButtonGroup>
        </Footer>
      </Container>
    </Overlay>
  )
}
