import { EnumValues } from 'enum-values'
import { FeatureFlag } from './types'
import { Package } from './types/Package'

const baseFeatureFlags = [
  FeatureFlag.ReportTemplate,
  FeatureFlag.FareRules,
  FeatureFlag.LiveGlobalOptimization,
  FeatureFlag.EngineV2GlobalOptimization,
  FeatureFlag.LiveRequest,
]
const riderProFeatureFlags = [
  FeatureFlag.Announcements,
  FeatureFlag.RecurringTrips,
  FeatureFlag.DriverRiderReviews,
  FeatureFlag.WalkingDirections,
  FeatureFlag.Promos,
  FeatureFlag.FarePasses,
  FeatureFlag.Wallet,
]
const driverProFeatureFlags = [
  FeatureFlag.ShowAllSlotsToDriver,
  FeatureFlag.DriverRiderReviews,
  FeatureFlag.DriverTripHistory,
  FeatureFlag.TipPolicies,
  FeatureFlag.UnscheduledDriving,
  FeatureFlag.AcceptRejectFleet,
]
const eligibilityProFeatureFlags = [FeatureFlag.EngageAIScan, FeatureFlag.EngageAIVoice]
const optimizationFeatureFlags = [
  FeatureFlag.AllowMatchingConfiguration,
  FeatureFlag.DriverBreaks,
  FeatureFlag.OptimizationInsights,
  FeatureFlag.MoreFrequentOptimization,
  FeatureFlag.ProblemTripsBackgroundMatching,
  FeatureFlag.DemandForecasting,
]
const dispatchFeatureFlags = [
  FeatureFlag.FixedRouteTravelDuration,
  FeatureFlag.LockToDuty,
  FeatureFlag.ConstraintOverrides,
  FeatureFlag.DriverDispatchCommunication,
  FeatureFlag.ForceMatch,
  FeatureFlag.ManualInterventions,
  FeatureFlag.DutyPulloutVisibility,
]
const dataAndAccessFeatureFlags = [
  FeatureFlag.CompleteActivityLog,
  FeatureFlag.OrganizationPrivacyConfigurations,
  FeatureFlag.DataReconciliation,
]
const voiceFeatureFlags = [FeatureFlag.DriverInAppCalling, FeatureFlag.VoiceNotifications]
const multiModalFeatureFlags = [
  FeatureFlag.Routes,
  FeatureFlag.Multimodal,
  FeatureFlag.MultimodalTransitHubs,
  FeatureFlag.GtfsFlexExportImprovements,
  FeatureFlag.FixedRouteAlternativesInLaunch,
]
const alaCarteFeatureFlags = [
  FeatureFlag.OpenFleet,
  FeatureFlag.LyftPromotion,
  FeatureFlag.PaymentMethodTypePurchaseLink,
  FeatureFlag.PaymentMethodTypesInRiderApp,
  FeatureFlag.MultipleWorkflows,
]
const notificationsProFeatureFlags = [
  FeatureFlag.SmsEta,
  FeatureFlag.SmsCancelRide,
  FeatureFlag.SMSRiderNotifications,
  FeatureFlag.BulkMessages,
  FeatureFlag.LanguageAndChannelPreferences,
  FeatureFlag.EtaChangedNotifications,
]
const ivrFeatureFlags = [
  FeatureFlag.PhoneIvrEta,
  FeatureFlag.PhoneIvrBookRide,
  FeatureFlag.PhoneIvrCancelRide,
  FeatureFlag.IvrCallRecording,
  FeatureFlag.LaunchAIVoice,
  FeatureFlag.RiderPhonePin,
]

const riderAppConfigurationFeatureFlags = [
  FeatureFlag.SchedulingBeforeEstimates,
  FeatureFlag.DisableRiderAppPhotoEditing,
  FeatureFlag.ShowDefaultRequestPreferences,
  FeatureFlag.EngageCasesOnRider,
  FeatureFlag.CallForVerificationCode,
  FeatureFlag.DisableRiderOnboarding,
  FeatureFlag.EnableRiderAppMixpanelAnalytics,
  FeatureFlag.DisableUpdatingDefaultProfileFields,
  FeatureFlag.DisplayOnlyPickupTimeInRideOptions,
  FeatureFlag.MultiEstimateRider,
  FeatureFlag.MergeZonesOnRider,
  FeatureFlag.HideStopDescriptionsOnRiderTimeline,
  FeatureFlag.DisableLocationNotesInRiderApp,
  FeatureFlag.EnableRiderAppDriverPolyline,
]

const driverAppConfigurationFeatureFlags = [
  FeatureFlag.DriverShowRiderFullName,
  FeatureFlag.DisableDriverAppFlagDown,
  FeatureFlag.UseDriverAppBearing,
  FeatureFlag.DisableNavigationIos,
  FeatureFlag.DisableNavigationAndroid,
  FeatureFlag.DisplayDriveUntilMessaging,
  FeatureFlag.DisableEditingAddingVehicle,
  FeatureFlag.DriverIdleMap,
]

const inProductMarketingFeatureFlags = [FeatureFlag.DisablePleoLiveMessage, FeatureFlag.DisablePleoManualInterventions]

const launchConfigurationFeatureFlags = [
  FeatureFlag.AllowBookingWithoutPaymentMethod,
  FeatureFlag.StripeCustomerDetails,
  FeatureFlag.MergeZonesOnLiveMap,
  FeatureFlag.MergeZonesOnServicesPage,
  FeatureFlag.MergeZonesOnDutyTimeTravel,
  FeatureFlag.AutoCompleteOnlyWhenAtEndLocation,
  FeatureFlag.InAppTesting,
  FeatureFlag.ExcludeWalkingTimeInDesiredTs,
  FeatureFlag.DisableImprovedTimeRules,
]

const productionFeatureFlags = new Set([
  ...baseFeatureFlags,
  ...riderProFeatureFlags,
  ...driverProFeatureFlags,
  ...eligibilityProFeatureFlags,
  ...optimizationFeatureFlags,
  ...dispatchFeatureFlags,
  ...dataAndAccessFeatureFlags,
  ...voiceFeatureFlags,
  ...multiModalFeatureFlags,
  ...alaCarteFeatureFlags,
  ...notificationsProFeatureFlags,
  ...ivrFeatureFlags,
  ...launchConfigurationFeatureFlags,
  ...riderAppConfigurationFeatureFlags,
  ...driverAppConfigurationFeatureFlags,
  ...inProductMarketingFeatureFlags,
])

export const inDevelopmentFeatureFlags = EnumValues.getValues<FeatureFlag>(FeatureFlag).filter(
  (flag) => !productionFeatureFlags.has(flag)
)

export const PackageFeatureFlagMap: Record<Package, FeatureFlag[]> = {
  [Package.Base]: baseFeatureFlags,
  [Package.LaunchConfiguration]: launchConfigurationFeatureFlags,
  [Package.DriverAppConfiguration]: driverAppConfigurationFeatureFlags,
  [Package.RiderAppConfiguration]: riderAppConfigurationFeatureFlags,
  [Package.Dispatch]: dispatchFeatureFlags,
  [Package.Optimization]: optimizationFeatureFlags,
  [Package.DriverPro]: driverProFeatureFlags,
  [Package.RiderPro]: riderProFeatureFlags,
  [Package.Multimodal]: multiModalFeatureFlags,
  [Package.NotificationsPro]: notificationsProFeatureFlags,
  [Package.spareIvr]: ivrFeatureFlags,
  [Package.Voice]: voiceFeatureFlags,
  [Package.EligibilityPro]: eligibilityProFeatureFlags,
  [Package.DataAccess]: dataAndAccessFeatureFlags,
  [Package.AlaCarte]: alaCarteFeatureFlags,
  [Package.InProductMarketing]: inProductMarketingFeatureFlags,
  [Package.InDevelopment]: inDevelopmentFeatureFlags,
}

export const featureEntitlementExclusions = new Set([...inProductMarketingFeatureFlags, ...inDevelopmentFeatureFlags])
