import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IListMobileAppReleaseQueryParams,
  IMobileAppReleasePatchBody,
  IMobileAppReleasePostBody,
  IReleaseResponse,
  Resources,
} from '../types'

export class MobileAppReleaseResource extends GenericResource<
  IReleaseResponse,
  IListMobileAppReleaseQueryParams,
  IMobileAppReleasePatchBody,
  IMobileAppReleasePostBody
> {
  public get = this.buildGet()
  public post = this.buildPost()
  public patch = this.buildPatch()
  public delete = this.buildDel()
  public list = this.buildList()

  constructor(agent: AxiosInstance) {
    super(Resources.MobileAppRelease, agent)
  }
}
