import { IModelJsonValues, ISharedQueryParams } from '@sparelabs/http-client-utils'
import { AccessRole } from './AccessRuleTypes'

export enum FleetType {
  AutoAccept = 'autoAccept',
  AcceptReject = 'acceptReject',
  External = 'external',
}

export enum FleetStatus {
  Active = 'active',
  Archived = 'archived',
}

export enum FleetExternalDispatchProvider {
  Lyft = 'lyft',
  Uzurv = 'uzurv',
  Uber = 'uber',
  Mti = 'mti',
  Jagwire = 'jagwire',
}

/**
 * Open Fleet architecture is being refactored. This enum represents open fleet providers no longer supported in the old architecture.
 */
export const UNSUPPORTED_OPEN_FLEET_V1_PROVIDERS = [FleetExternalDispatchProvider.Lyft]

export interface IFleetListQueryParams extends ISharedQueryParams {
  type?: FleetType[] | FleetType
  status?: FleetStatus
  serviceId?: string
  role?: AccessRole
  isVisible?: boolean
  breakPolicyId?: string
  agreementRequired?: boolean
}

export interface IFleetPostBody {
  name: string
  type: FleetType
  systemEfficiencyRatio?: number
  waitVsDetourTimeRatio?: number
  driverWaitTimeThreshold?: number
  allowOverbooking: boolean
  externalDispatchProvider?: FleetExternalDispatchProvider
  isVisible?: boolean
  breakPolicyId?: string | null
  logoUrl?: string
  termsUrl?: string | null
  supportUrl?: string | null
  agreementRequired?: boolean
  configurationId?: string
}

export interface IFleetPatchBody {
  name?: string
  status?: FleetStatus.Archived
  systemEfficiencyRatio?: number
  waitVsDetourTimeRatio?: number
  driverWaitTimeThreshold?: number
  allowOverbooking?: boolean
  isVisible?: boolean
  breakPolicyId?: string | null
  logoUrl?: string
  termsUrl?: string | null
  supportUrl?: string | null
  agreementRequired?: boolean
  configurationId?: string | null
}

export interface IFleetProviderGetParams {
  fleetId: string
}

export interface IFleetAccessRuleListParams {
  fleetId: string
}

export interface IFleetAccessRulePostParams {
  fleetId: string
}

export interface IFleetAccessRulePostBody {
  organizationId: string
}

export interface IFleetAccessRuleDeleteParams {
  fleetId: string
  organizationId: string
}

export interface IFleetAccessResponse {
  organizationId: string
  organizationName: string
}

export interface IListFleetAccessRulesResponse {
  sharedWith: IFleetAccessResponse[]
}

export interface IFleetResponse extends IModelJsonValues {
  name: string
  type: FleetType
  status: FleetStatus
  access: AccessRole
  systemEfficiencyRatio: number
  waitVsDetourTimeRatio: number
  driverWaitTimeThreshold: number
  allowOverbooking: boolean
  externalDispatchProvider: FleetExternalDispatchProvider | null
  linkedServices: Array<{ id: string }>
  isVisible: boolean
  breakPolicyId: string | null
  logoUrl: string | null
  termsUrl: string | null
  supportUrl: string | null
  agreementRequired: boolean
  configurationId: string | null
}

export interface IFleetStub {
  id: string
  name: string
  type: FleetType
  status: FleetStatus
  logoUrl: string | null
  termsUrl: string | null
  supportUrl: string | null
  agreementRequired: boolean
  configurationId?: string | null
}
