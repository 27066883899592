import { IPoint } from '@sparelabs/geography'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { DutyStatus } from './DutyTypes'
import { DayOfWeek } from './TimeTypes'

export interface ITimeTableEntry {
  dayOfWeek: DayOfWeek
  startTime: number
  times: number[]
}

export enum RouteStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Archived = 'archived',
}

export interface IRouteStop {
  name: string
  location: IPoint
  slackSeconds: number
}

export interface IRouteCommon {
  name: string
  status: RouteStatus
  stops: IRouteStop[]
  days: DayOfWeek[]
  startDate: string
  endDate: string
  /**
   * Start time in number of seconds from start of day in organization timezone
   */
  startTimes: number[]
}

export interface IRouteResponse extends IRouteCommon, IModelJsonValues {
  timeTable: ITimeTableEntry[]
}

export interface IRouteListQueryParams extends IListModelQueryParamValues {
  status?: RouteStatus | RouteStatus[]
}

export interface IRoutePostBody extends IRouteCommon {}

export interface IRoutePatchBody extends Partial<IRouteCommon> {}

export interface IRouteAssignmentListQueryParams extends IListModelQueryParamValues {
  routeId?: string
  dutyStatus?: DutyStatus | DutyStatus[]
}

export interface IRouteAssignmentPostBody {
  dutyId: string
  routeId: string
  startTs: number
}

export interface IRouteAssignmentResponse extends IModelJsonValues, IRouteAssignmentPostBody {
  endTs: number
}

export interface IRouteAssignmentStateEntry {
  routeId: string
  dutyId?: string
  startTs: number
  endTs: number
  id?: string
}

export interface IRouteAssignmentStateResponse {
  assignments: IRouteAssignmentStateEntry[]
  routes: Array<{
    id: string
    name: string
  }>
}
