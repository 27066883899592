import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum WorkflowPermissionSet {
  View = 'workflow_view',
  Write = 'workflow_write',
  Delete = 'workflow_delete',
}

export const workflowPermissionSetGrouping: IPermissionSetGrouping<WorkflowPermissionSet> = {
  key: PermissionSetGroupingKey.Workflow,
  active: {
    [WorkflowPermissionSet.View]: [PermissionType.GET_ANY_WORKFLOW],
    [WorkflowPermissionSet.Write]: [PermissionType.WRITE_ANY_WORKFLOW],
    [WorkflowPermissionSet.Delete]: [PermissionType.GET_ANY_WORKFLOW_RUN],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
