import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum RiderUserPermissionSet {
  View = 'rider_user_view',
  New = 'rider_user_new',
  Edit = 'rider_user_edit',
  Delete = 'rider_user_delete',
  Import = 'rider_user_import',
  Export = 'rider_user_export',
  SetPhonePin = 'rider_user_set_phone_pin',
}

/* Rider user management */
export const riderUserPermissionSetGrouping: IPermissionSetGrouping<RiderUserPermissionSet> = {
  key: PermissionSetGroupingKey.RiderUser,
  active: {
    [RiderUserPermissionSet.View]: [
      PermissionType.GET_ANY_RIDER,
      PermissionType.LIST_ANY_RIDER,
      PermissionType.GET_RIDER_PHONE_PIN,
    ],
    [RiderUserPermissionSet.New]: [PermissionType.POST_ANY_RIDER],
    [RiderUserPermissionSet.Edit]: [
      PermissionType.PATCH_ANY_RIDER,
      PermissionType.POST_UPLOAD_PHOTO /* Used for uploading a profile photos to users accounts */,
      PermissionType.POST_ANY_FILE /* Used for uploading files to users profiles */,
    ],
    [RiderUserPermissionSet.Delete]: [PermissionType.DELETE_ANY_RIDER],
    [RiderUserPermissionSet.Import]: [PermissionType.IMPORT_ANY_RIDER],
    [RiderUserPermissionSet.Export]: [PermissionType.EXPORT_ANY_RIDER],
    [RiderUserPermissionSet.SetPhonePin]: [PermissionType.POST_RIDER_PHONE_PIN],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
