import React from 'react'
import { IInputProps } from './InputTypes'
import { StyledInput } from './styled/StyledInput'

interface INumberInputProps extends IInputProps<number> {
  label?: string
  allowDecimals?: boolean
}

export const NumberInput = ({ label, value, onChange, allowDecimals = false, ...inputProps }: INumberInputProps) => (
  <StyledInput
    {...inputProps}
    aria-label={label}
    value={value?.toString() ?? ''}
    onChange={(event) => {
      onChange(
        event.target.value ? (allowDecimals ? parseFloat(event.target.value) : parseInt(event.target.value)) : undefined
      )
    }}
    role='textbox'
    type='number'
  />
)
