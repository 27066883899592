import { UnexpectedError } from '@sparelabs/error-types'
import { FlagDownConstants } from '../flagDown'
import { OffsetDirection, TimeConstraintType, Window } from '../scheduling'
import { ArriveByPickupConstraintType, RequestIntentType } from '../types'
import { IArriveByConstraints, ILeaveAtConstraints, IRequestConstraints } from './RequestConstraints'
import { RequestSemantics } from './RequestSemantics'

export interface IConstraintRequestValues {
  id: string
  travelDuration: number
  travelDurationFlexibility: number
  flagDownDutyId: string | null
  unmatchedPickupWindow: Window | null
  unmatchedDropoffWindow: Window | null
  arriveByPickupConstraintType: ArriveByPickupConstraintType | null
}

export interface IConstraintMatchedRequestValues extends IConstraintRequestValues {
  matchedPickupWindow: Window | null
  matchedDropoffWindow: Window | null
}

/**
 * "Builds" constraints from requests.
 * ie, takes the relevant windows from the request object, and constructs the complementary (relative) windows
 */
export class RequestConstraintsBuilder {
  public static buildForUnmatched(
    request: IConstraintRequestValues,
    intentType: RequestIntentType,
    estimatedCreatedTs: number
  ): IRequestConstraints {
    switch (intentType) {
      case RequestIntentType.LeaveAt:
        return this.buildLeaveAtUnmatched(request, estimatedCreatedTs)
      case RequestIntentType.ArriveBy:
        return this.buildArriveByUnmatched(request)
    }
  }

  public static buildForMatched(
    request: IConstraintMatchedRequestValues,
    intentType: RequestIntentType
  ): IRequestConstraints {
    switch (intentType) {
      case RequestIntentType.LeaveAt:
        return this.buildLeaveAtMatched(request)
      case RequestIntentType.ArriveBy:
        return this.buildArriveByMatched(request)
    }
  }

  private static buildLeaveAtUnmatched(
    request: IConstraintRequestValues,
    estimatedCreatedTs: number
  ): ILeaveAtConstraints {
    if (!request.unmatchedPickupWindow) {
      throw new UnexpectedError('Unmatched request missing pickup window', { requestId: request.id })
    }
    /**
     * Flagdown requests get an extra high detour flexibility when they are initially matched,
     * just to ensure a high match rate for flagdowns
     */
    const detourFlexibility = RequestSemantics.isFlagDownRequest(request)
      ? FlagDownConstants.FLAG_DOWN_PRE_MATCH_TRAVEL_DURATION_FLEXIBILITY
      : request.travelDurationFlexibility

    /**
     * If the desired pickup time is in the past, we need to bump it forward so that it aims no earlier
     * than the estimated created time:
     *
     * If it is in the past, assume that the rider wants a trip as soon as possible. Such a discrepancy
     * may arise due to rematching (e.g. on duty cancellation), or simply due to delay between the
     * time the estimate was created, and the first time the request gets matched.
     */
    const desiredTimeInPastAmount = Math.max(estimatedCreatedTs - request.unmatchedPickupWindow.desired, 0)
    const adjustedPickupConstraint = {
      min: request.unmatchedPickupWindow.min + desiredTimeInPastAmount,
      desired: request.unmatchedPickupWindow.desired + desiredTimeInPastAmount,
      max: request.unmatchedPickupWindow.max + desiredTimeInPastAmount,
    }

    return {
      type: RequestIntentType.LeaveAt,
      pickupConstraint: { type: TimeConstraintType.Absolute, ...adjustedPickupConstraint },
      dropoffConstraint: {
        type: TimeConstraintType.Relative,
        maxOffset: request.travelDuration + detourFlexibility,
        direction: OffsetDirection.After,
      },
    }
  }

  private static buildArriveByUnmatched(request: IConstraintRequestValues): IArriveByConstraints {
    if (!request.unmatchedDropoffWindow) {
      throw new UnexpectedError('Unmatched request missing dropoff window', { requestId: request.id })
    }
    if (request.arriveByPickupConstraintType === ArriveByPickupConstraintType.Window) {
      if (!request.unmatchedPickupWindow) {
        throw new UnexpectedError('Unmatched request missing pickup window', { requestId: request.id })
      }
      return {
        type: RequestIntentType.ArriveBy,
        pickupConstraint: { type: TimeConstraintType.Absolute, ...request.unmatchedPickupWindow },
        dropoffConstraint: { type: TimeConstraintType.Absolute, ...request.unmatchedDropoffWindow },
      }
    }

    return {
      type: RequestIntentType.ArriveBy,
      pickupConstraint: {
        type: TimeConstraintType.Relative,
        direction: OffsetDirection.Before,
        maxOffset: request.travelDuration + request.travelDurationFlexibility,
      },
      dropoffConstraint: { type: TimeConstraintType.Absolute, ...request.unmatchedDropoffWindow },
    }
  }

  private static buildLeaveAtMatched(request: IConstraintMatchedRequestValues): ILeaveAtConstraints {
    if (!request.matchedPickupWindow) {
      throw new UnexpectedError('Matched request missing pickup window', { requestId: request.id })
    }

    return {
      type: RequestIntentType.LeaveAt,
      pickupConstraint: { type: TimeConstraintType.Absolute, ...request.matchedPickupWindow },
      dropoffConstraint: {
        type: TimeConstraintType.Relative,
        direction: OffsetDirection.After,
        maxOffset: request.travelDuration + request.travelDurationFlexibility,
      },
    }
  }

  private static buildArriveByMatched(request: IConstraintMatchedRequestValues): IArriveByConstraints {
    if (!request.matchedDropoffWindow) {
      throw new UnexpectedError('Matched request missing dropoff window', { requestId: request.id })
    }
    if (request.arriveByPickupConstraintType === ArriveByPickupConstraintType.Window) {
      if (!request.matchedPickupWindow) {
        throw new UnexpectedError('Matched request missing pickup window', { requestId: request.id })
      }
      return {
        type: RequestIntentType.ArriveBy,
        pickupConstraint: { type: TimeConstraintType.Absolute, ...request.matchedPickupWindow },
        dropoffConstraint: { type: TimeConstraintType.Absolute, ...request.matchedDropoffWindow },
      }
    }

    return {
      type: RequestIntentType.ArriveBy,
      pickupConstraint: {
        type: TimeConstraintType.Relative,
        direction: OffsetDirection.Before,
        maxOffset: request.travelDuration + request.travelDurationFlexibility,
      },
      dropoffConstraint: { type: TimeConstraintType.Absolute, ...request.matchedDropoffWindow },
    }
  }
}
