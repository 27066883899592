import type { Stripe } from 'stripe'

export type ConnectedAccountExternalData = Stripe.Customer | Stripe.DeletedCustomer | {}

export interface IConnectedAccount {
  organizationId: string
  type: ConnectedAccountType
  externalId: string
  externalData: any
  userId: string
}

/**
 * @deprecated use paymentMethodTypeId when making a connected account instead
 */
export enum ConnectedAccountType {
  Stripe = 'stripe',
  Nimoca = 'nimoca',
  GoPass = 'gopass',
  Wallet = 'wallet',
}

export interface IConnectedAccountTemplate {
  connectedAccountType: ConnectedAccountType
  clientSafe: any
  clientUnsafe: any
}
