import { BaseClient } from '@sparelabs/http-client-utils'
import {
  CaseFormResource,
  CaseLetterResource,
  CaseResource,
  CaseStatusResource,
  CaseTypeResource,
  FormContentResource,
  FormResource,
  LetterResource,
  PublicResource,
} from './resources'
import { NylasAuthResource } from './resources/NylasAuthResource'
import { NylasOrganizationGrantResource } from './resources/NylasOrganizationGrantResource'

export class EngageClient extends BaseClient {
  public caseForms = new CaseFormResource(this.agent)
  public caseLetters = new CaseLetterResource(this.agent)
  public cases = new CaseResource(this.agent)
  public caseStatuses = new CaseStatusResource(this.agent)
  public caseTypes = new CaseTypeResource(this.agent)
  public formContents = new FormContentResource(this.agent)
  public forms = new FormResource(this.agent)
  public letters = new LetterResource(this.agent)
  public nylasAdminGrants = new NylasAuthResource(this.agent)
  public nylasAuth = new NylasAuthResource(this.agent)
  public nylasOrganizationGrants = new NylasOrganizationGrantResource(this.agent)
  public public = new PublicResource(this.agent)
}
