import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum TicketPermissionSet {
  ViewInstance = 'ticket_view_instance',
  WriteInstance = 'ticket_write_instance',
}

export const ticketPermissionSetGrouping: IPermissionSetGrouping<TicketPermissionSet> = {
  key: PermissionSetGroupingKey.Ticket,
  active: {
    [TicketPermissionSet.ViewInstance]: [PermissionType.GET_ANY_TICKET_INSTANCE],
    [TicketPermissionSet.WriteInstance]: [PermissionType.WRITE_ANY_TICKET_INSTANCE],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
