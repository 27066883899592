import { IModelJsonValues } from '@sparelabs/http-client-utils'

export interface IInlineRequestCancellationDetails {
  cancelledBy: RequestCancelledBy
  fault: RequestCancellationFault
  reason: string
  notes: string | null
  metadata?: Record<string, unknown> | null
}
export interface IRequestCancellationResponse extends IModelJsonValues {
  requestId: string
  cancelledBy: RequestCancelledBy
  fault: RequestCancellationFault
  reason: RequestCancellationReason | string
  notes: string | null
  metadata?: Record<string, unknown> | null
}

export enum RequestCancelledBy {
  Driver = 'driver',
  Rider = 'rider',
  Admin = 'admin',
  ExternalDispatcher = 'externalDispatcher',
  Engine = 'engine',
}

export enum RequestCancellationReason {
  NoShow = 'noShow',
  RiderBan = 'riderBan',
  LateDriver = 'lateDriver',
  AlternativeMethod = 'alternativeMethod',
  ScheduleChange = 'scheduleChange',
  Other = 'other',
}

export enum RequestCancellationFault {
  DriverFault = 'driverFault',
  RiderFault = 'riderFault',
  NoFault = 'noFault',
}
