import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IReportTemplateListQueryParams, IReportTemplatePostBody, IReportTemplateResponse, Resources } from '../types'

export class ReportTemplatesResource extends GenericResource<
  IReportTemplateResponse,
  IReportTemplateListQueryParams,
  Partial<IReportTemplatePostBody>,
  IReportTemplatePostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()
  public delete = this.buildDel()

  constructor(agent: AxiosInstance) {
    super(Resources.ReportTemplates, agent)
  }

  public async generate(question: string): Promise<IReportTemplatePostBody> {
    const res = await this.agent.get<IReportTemplatePostBody>(`${this.resource}/generate`, {
      params: { question },
    })
    return res.data
  }
}
