import { library } from '@fortawesome/fontawesome-svg-core'
import { faCloudUploadAlt } from '@fortawesome/pro-solid-svg-icons'
import { WebColors } from '@sparelabs/colors'
import React from 'react'
import styled from 'styled-components'
import { st } from '../../locales/TranslationHelper'
import { FileIcon } from './FileIcon'
import { FileViewContainer, FileViewFileName, FileViewFileNameContainer } from './FileViewStyles'

library.add(faCloudUploadAlt)

const UploadIcon = styled(FileIcon)`
  color: ${WebColors.contentSecondary};
`

export const FileUploadingView = (): JSX.Element => (
  <FileViewContainer>
    <UploadIcon icon='cloud-upload-alt' />
    <FileViewFileNameContainer>
      <FileViewFileName>{st.inputs.shared.uploading()}</FileViewFileName>
    </FileViewFileNameContainer>
  </FileViewContainer>
)
