import { IAddress } from '@sparelabs/address'
import { CustomFieldTextType, CustomFieldType, ICustomFieldChoice, ICustomFieldResponse } from '@sparelabs/api-client'
import { FormType } from '@sparelabs/engage-client'
import {
  ACCEPTED_FILE_EXTENSION_TYPES_FOR_RIDERS_DRIVERS,
  IFileResponse,
  IFileTempUrlResponse,
} from '@sparelabs/http-client-utils'
import {
  AcceptedFileTypes,
  AddressInput,
  DateInput,
  DateTimeInput,
  DrawingInput,
  FileInput,
  MultipleChoiceInput,
  MultipleFileInput,
  NumberInput,
  SingleChoiceInput,
  TextAreaInput,
  TextInput,
  ToggleInput,
} from '@sparelabs/web-ui-components'
import React from 'react'
import { useMap } from 'react-use'
import { ApiClientBuilder } from 'src/api/ApiClientBuilders'
import { constants } from 'src/env/Environment'
import { ErrorHandler } from 'src/helpers'
import { st } from 'src/locales/TranslationHelper'
import { useFormContext } from 'src/pages/Form/FormContext'

interface ICustomFieldInputProps {
  field: ICustomFieldResponse
  onChange: (value: unknown) => void
  value: unknown
  id?: string
}

const ACCEPTED_FILE_TYPES = ACCEPTED_FILE_EXTENSION_TYPES_FOR_RIDERS_DRIVERS as AcceptedFileTypes[]

export const CustomFieldInput = ({ field, onChange, value, id }: ICustomFieldInputProps): JSX.Element | null => {
  const { form, organization } = useFormContext()
  const isPublicForm = form.type === FormType.Specialist
  const organizationId = organization.id

  const [publicFileMap, { set: setPublicFile }] = useMap<Record<string, File>>({})

  const handleUpload = async (file: File): Promise<IFileResponse> => {
    if (isPublicForm) {
      const result = await ApiClientBuilder.build().upload.publicFile(file, { organizationId })
      setPublicFile(result.id, file)
      return result
    }
    return ApiClientBuilder.build().upload.file(file)
  }

  const handleTempUrl = async (id: string): Promise<IFileTempUrlResponse> => {
    if (isPublicForm && publicFileMap[id]) {
      return { tempUrl: URL.createObjectURL(publicFileMap[id]) }
    }
    if (isPublicForm) {
      throw new Error(st.errors.imageNotUploaded())
    }
    return ApiClientBuilder.build().files.tempUrl(id)
  }

  switch (field.type) {
    case CustomFieldType.Text:
      switch (field.textType) {
        case CustomFieldTextType.MultiLine:
          return <TextAreaInput id={id} label={field.label} onChange={onChange} value={value as string} />
        case CustomFieldTextType.SingleLine:
        default:
          return <TextInput id={id} label={field.label} onChange={onChange} value={value as string} />
      }
    case CustomFieldType.NpsScore:
    case CustomFieldType.Number:
      return <NumberInput id={id} onChange={onChange} value={value as number} />
    case CustomFieldType.Toggle:
      return <ToggleInput id={id} onChange={onChange} value={value as boolean} />
    case CustomFieldType.SingleChoice:
      return (
        <SingleChoiceInput
          id={id}
          choices={field.choices as ICustomFieldChoice[]}
          required={field.isRequired}
          onChange={onChange}
          value={value}
        />
      )
    case CustomFieldType.MultipleChoice:
      return (
        <MultipleChoiceInput
          id={id}
          choices={field.choices as ICustomFieldChoice[]}
          onChange={onChange}
          values={value as string[]}
        />
      )
    case CustomFieldType.Date:
      return <DateInput id={id} onChange={onChange} value={(value as string) ?? ''} />
    case CustomFieldType.Datetime:
      return <DateTimeInput id={id} onChange={onChange} value={value as number} />
    case CustomFieldType.Address:
      return (
        <AddressInput
          id={id}
          onChange={onChange}
          value={value as Partial<IAddress>}
          apiUrl={constants.apiUrl}
          onError={(error) => ErrorHandler.handleError(error)}
        />
      )
    case CustomFieldType.File:
      return (
        <FileInput<IFileResponse>
          id={id}
          onChange={onChange}
          accept={ACCEPTED_FILE_TYPES}
          label={field.label}
          value={value as IFileResponse}
          upload={handleUpload}
          tempUrl={handleTempUrl}
          onError={(error) => ErrorHandler.handleError(error)}
        />
      )
    case CustomFieldType.Files:
      return (
        <MultipleFileInput<IFileResponse>
          id={id}
          onChange={onChange}
          accept={ACCEPTED_FILE_TYPES}
          label={field.label}
          value={value as IFileResponse[]}
          upload={handleUpload}
          tempUrl={handleTempUrl}
          onError={(error) => ErrorHandler.handleError(error)}
        />
      )
    case CustomFieldType.Drawing:
      return (
        <DrawingInput
          id={id}
          value={value as IFileResponse}
          onChange={onChange}
          upload={handleUpload}
          onError={(error) => ErrorHandler.handleError(error)}
        />
      )
    // Pass fail is not supported in Engage
    case CustomFieldType.PassFail:
      return null
  }
}
