import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { ITimeRange } from './SharedTypes'

export enum TimeRuleDays {
  EveryDay = 'everyday',
  Weekdays = 'weekdays',
  Weekends = 'weekends',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export interface ITimeRule extends ITimeRange {
  days: TimeRuleDays
}

export interface ITimeRuleOverride {
  date: string
  rules: ITimeRange[]
}

export interface ITimeRulePostBody {
  name: string
  rules: ITimeRule[]
  ruleOverrides: ITimeRuleOverride[]
}

export interface ITimeRuleResponse extends IModelJsonValues {
  id: string
  name: string
  rules: ITimeRule[]
  ruleOverrides: ITimeRuleOverride[]
}

export interface ITimeRulePatchBody {
  name?: string
  rules?: ITimeRule[]
  ruleOverrides?: ITimeRuleOverride[]
}

export interface ITimeRuleListQueryParams extends IListModelQueryParamValues {
  search?: string
}
