import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExclamation } from '@fortawesome/pro-solid-svg-icons'
import { WebColors } from '@sparelabs/colors'
import React from 'react'
import styled from 'styled-components'
import { st } from '../../locales/TranslationHelper'
import { FileIcon } from './FileIcon'
import { FileViewContainer, FileViewFailedFileName, FileViewFileNameContainer } from './FileViewStyles'

library.add(faFileExclamation)

interface IProps {
  name: string
}

const FileViewUploadContainer = styled(FileViewContainer)`
  display: flex;
  gap: 8px;
  color: ${WebColors.warningPrimary};
`

const FileViewUploadFailed = styled.div`
  font-size: 13px;
  line-height: 16px;
`

export const FileUploadError = ({ name }: IProps): JSX.Element => (
  <FileViewUploadContainer>
    <FileIcon icon='file-exclamation' />
    <FileViewFileNameContainer>
      <FileViewFailedFileName>{name}</FileViewFailedFileName>
      <FileViewUploadFailed>{st.inputs.shared.uploadFailed()}</FileViewUploadFailed>
    </FileViewFileNameContainer>
  </FileViewUploadContainer>
)
