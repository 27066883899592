import { Currency } from './CurrencyTypes'
/**
 * @deprecated use from @sparelabs/domain-logic instead
 */
export { RiderType, MatchingEndpoint } from '@sparelabs/domain-logic'

export interface IPrice {
  currency: Currency
  amount: number
}

export interface IEstimatedRange {
  minTs: number
  ts: number
  maxTs: number
}

export interface ITimeRange {
  startTs: number
  endTs: number
}

export enum UnitsOfDistance {
  Kilometer = 'km',
  Mile = 'mi',
}

/* The following languages are currently supported for customers */
export enum Language {
  English = 'en',
  Norwegian = 'nb',
  NorwegianNynorsk = 'nn',
  Japanese = 'ja',
  German = 'de',
  Spanish = 'es',
  Catalan = 'ca',
  Icelandic = 'is',
  Swedish = 'sv',
  French = 'fr',
  Portuguese = 'pt',
}

interface ILanguageToLocale {
  [language: string]: Language
}

export const LanguageLocaleMap: ILanguageToLocale = {
  english: Language.English,
  norwegian: Language.Norwegian,
  'norwegian nynorsk': Language.NorwegianNynorsk,
  japanese: Language.Japanese,
  german: Language.German,
  spanish: Language.Spanish,
  catalan: Language.Catalan,
  icelandic: Language.Icelandic,
  swedish: Language.Swedish,
  french: Language.French,
  portuguese: Language.Portuguese,
}

interface ILocaleToLanguage {
  [language: string]: string
}

export const LocaleLanguageMap: ILocaleToLanguage = {
  en: 'English',
  nb: 'Norwegian',
  nn: 'Norwegian Nynorsk',
  ja: 'Japanese',
  de: 'German',
  es: 'Spanish',
  ca: 'Catalan',
  is: 'Icelandic',
  sv: 'Swedish',
  fr: 'French',
  pt: 'Portuguese',
}
