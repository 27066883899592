import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IUserGroupPatchBody, IUserGroupResponse, Resources } from '../types'

export class UserGroupResource extends GenericResource<IUserGroupResponse, never, never, IUserGroupPatchBody> {
  constructor(agent: AxiosInstance) {
    super(Resources.UserGroups, agent)
  }

  public updateUserGroups = async (userId: string, groupIds: string[]) => {
    const res = await this.agent.patch<IUserGroupResponse>(`${this.resource}/${userId}`, {
      groupIds,
    })
    return res.data
  }
}
