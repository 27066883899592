/**
 * OAuthScope enum value format is like resource.subResource:access
 */

// cspell: disable
export enum OAuthScope {
  AnnouncementRead = 'announcement:read',
  AnnouncementWrite = 'announcement:write',
  DriverRead = 'driver:read',
  DriverWrite = 'driver:write',
  DriverOperationsWrite = 'driver.operations:write',
  DutyRequestOfferRead = 'duty.requestOffer:read',
  DutyRead = 'duty:read',
  DutyWrite = 'duty:write',
  FarePassRead = 'farePass:read',
  FarePassWrite = 'farePass:write',
  FarePassAllocationRead = 'farePass.allocation:read',
  FarePassAllocationWrite = 'farePass.allocation:write',
  FavoriteLocationRead = 'favoriteLocation:read',
  FavoriteLocationWrite = 'favoriteLocation:write',
  FleetRead = 'fleet:read',
  FleetWrite = 'fleet:write',
  GroupRead = 'group:read',
  GroupWrite = 'group:write',
  GroupMembershipRead = 'group.membership:read',
  GroupMembershipWrite = 'group.membership:write',
  NotificationSettingRead = 'notification.setting:read',
  NotificationSettingWrite = 'notification.setting:write',
  OrganizationConfigurationRead = 'organization.configuration:read',
  PaymentMethodRead = 'paymentMethod:read',
  PaymentMethodWrite = 'paymentMethod:write',
  PromoRead = 'promo:read',
  PromoWrite = 'promo:write',
  PromoAllocationRead = 'promo.allocation:read',
  PromoAllocationWrite = 'promo.allocation:write',
  RequestRead = 'request:read',
  RequestWrite = 'request:write',
  RequestEstimateRead = 'request.estimate:read',
  RequestRecurrenceRead = 'request.recurrence:read',
  RequestRecurrenceWrite = 'request.recurrence:write',
  RequestSlotRead = 'request.slot:read',
  RequestInsightRead = 'request.insight:read',
  RequestVehicleRead = 'request.vehicle:read',
  RequestVehicleLocationRead = 'request.vehicle.location:read',
  ReviewRead = 'review:read',
  ReviewWrite = 'review:write',
  RiderRead = 'rider:read',
  RiderWrite = 'rider:write',
  ServiceRead = 'service:read',
  ServiceWrite = 'service:write',
  ServiceFleetRead = 'service.fleet:read',
  ServiceFleetWrite = 'service.fleet:write',
  SlotRead = 'slot:read',
  StopRead = 'stop:read',
  StopWrite = 'stop:write',
  TimeRuleRead = 'timeRule:read',
  TimeRuleWrite = 'timeRule:write',
  TipRead = 'tip:read',
  TipWrite = 'tip:write',
  TipPolicyRead = 'tip.policy:read',
  TipPolicyWrite = 'tip.policy:write',
  UserBanRead = 'userBan:read',
  UserBanWrite = 'userBan:write',
  UserFleetAgreementRead = 'userFleetAgreement:read',
  UserFleetAgreementWrite = 'userFleetAgreement:write',
  VehicleRead = 'vehicle:read',
  VehicleWrite = 'vehicle:write',
  ZoneRead = 'zone:read',
  ZoneWrite = 'zone:write',
  ZoneAreaRead = 'zoneArea:read',
  ZoneAreaWrite = 'zoneArea:write',

  ZoneStopRead = 'zoneStop:read',
  ZoneStopWrite = 'zoneStop:write',
}
// cspell: enable
