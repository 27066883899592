import { ICustomFieldResponse } from './CustomFieldTypes'

export enum NativeObjectType {
  Rider = 'rider',
  Driver = 'driver',
  GroupMembership = 'groupMembership',
  Vehicle = 'vehicle',
  Service = 'service',
  Request = 'request',
  Duty = 'duty',
  Case = 'case',
}

interface INativeObjectsExtensionResponseEntry {
  fieldGroupId: string
  fields: ICustomFieldResponse[]
}

export type INativeObjectsResponse = Record<NativeObjectType, INativeObjectsExtensionResponseEntry>
