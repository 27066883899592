import { GenericResource, IListResponse } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  ISuperAdminListQueryParams,
  ISuperAdminPatchBody,
  ISuperAdminPostBody,
  ISuperAdminResponse,
  Resources,
} from '../types'

export class SuperAdminResource extends GenericResource<
  ISuperAdminResponse,
  ISuperAdminListQueryParams,
  ISuperAdminPatchBody,
  ISuperAdminPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  public del = this.buildDel()

  constructor(agent: AxiosInstance) {
    super(Resources.SuperAdmins, agent)
  }

  public async search(search: string): Promise<IListResponse<ISuperAdminResponse>> {
    const { data } = await this.agent.get<IListResponse<ISuperAdminResponse>>(this.resource, { params: { search } })
    return data
  }
}
