import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import FormData from 'form-data'
import { IAntivirusScanResponse, IAntivirusVersionResponse, Resources } from '../types'

export class AntivirusResource extends GenericResource<never, never, never, never> {
  constructor(agent: AxiosInstance) {
    super(Resources.Antivirus, agent)
  }

  public async postFileScan(fileBuffer: Buffer, fileName: string): Promise<IAntivirusScanResponse> {
    const formData = new FormData({ maxDataSize: 1000000000000 })

    formData.append('upload', fileBuffer, fileName)

    const res = await this.agent.post<IAntivirusScanResponse>(`${this.resource}/scan`, formData, {
      headers: {
        ...formData.getHeaders(),
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
    return res.data
  }

  public async getVersion(): Promise<IAntivirusVersionResponse> {
    const res = await this.agent.get<IAntivirusVersionResponse>(`${this.resource}/version`)
    return res.data
  }
}
