import { WebColors } from '@sparelabs/colors'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { st } from '../locales/TranslationHelper'
import { LABEL_COLUMN_WIDTH_PX, LABEL_MARGIN_RIGHT_PX } from '../util'
import { InfoTooltip } from './InfoTooltip'
import { FieldHint, FieldValue, FieldValueContainer, ValidationError } from './ViewField'

export const CompactFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 15px;
  flex-direction: column;
`

export const CompactFieldLabel = styled.label`
  max-width: initial;
  margin-bottom: 4px;
  gap: 3px;
  margin-bottom: 0px;
  font-weight: 400;
  line-height: 20px;
  color: ${WebColors.contentSecondary};
  min-width: ${LABEL_COLUMN_WIDTH_PX}px;
  margin-right: ${LABEL_MARGIN_RIGHT_PX}px;
  display: flex;
  height: fit-content;
`

export const CompactFieldHint = styled(FieldHint)`
  max-width: initial;
`

export const CompactValidationError = styled(ValidationError)`
  max-width: initial;
`

const StyledInfoTooltipWrapper = styled.span`
  margin-left: 6px;
`

// Since FieldValueContainer had flex-direction: row with flex-basis set (for auto-wrapping), but this component changes
// the flex-direction to column, we need to unset the flex-basis value:
const CompactFieldValueContainer = styled(FieldValueContainer)`
  flex-basis: unset;
`

interface IProps {
  children?: ReactNode
  label?: string
  hint?: ReactNode
  tooltip?: string
  error?: string
  className?: string
  id?: string
  horizontalBar?: boolean
  isRequired?: boolean
}

export const CompactField = ({
  label,
  children,
  hint,
  error,
  tooltip,
  className,
  isRequired,
  id,
}: IProps): JSX.Element => (
  <CompactFieldContainer className={className}>
    <CompactFieldLabel htmlFor={id}>
      {label} {isRequired && <span>{st.common.required()}</span>}
      {tooltip && (
        <StyledInfoTooltipWrapper>
          <InfoTooltip content={tooltip} />
        </StyledInfoTooltipWrapper>
      )}
    </CompactFieldLabel>
    {children && (
      <CompactFieldValueContainer>
        <FieldValue>{children}</FieldValue>
        {hint && <CompactFieldHint>{hint}</CompactFieldHint>}
        {error && <CompactValidationError>{error}</CompactValidationError>}
      </CompactFieldValueContainer>
    )}
  </CompactFieldContainer>
)
