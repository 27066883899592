import { WebColors } from '@sparelabs/colors'
import React from 'react'
import { st } from 'src/locales/TranslationHelper'
import styled from 'styled-components'
import { InfoParagraph } from './Layout'

const t = st.forms.submission

const LinkText = styled.a`
  color: ${WebColors.interactivePrimary};
  :hover {
    color: ${WebColors.interactivePrimaryHover};
  }
  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    border: 0px !important;
  }
`

interface IProps {
  onUpdateClick: () => void
}

export const UpdateFormSection = ({ onUpdateClick }: IProps): JSX.Element => (
  <InfoParagraph role='complementary'>
    {t.updateInfo.part1()}
    <LinkText onClick={onUpdateClick}>{t.updateInfo.linkStartOverText()}</LinkText>
    {t.updateInfo.punctuation()}
  </InfoParagraph>
)
