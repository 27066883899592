import { IFileResponse } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IMobileFile,
  IMobileImageFile,
  IPublicUploadFilePostBody,
  IUploadPhotoOptions,
  IUploadQueryParams,
} from '../types'

export class UploadResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async photo(
    photoData: File | IMobileImageFile | Blob,
    options: IUploadPhotoOptions = { resizePhoto: true }
  ): Promise<{ url: string }> {
    const formData = new FormData()
    formData.append('upload', photoData as unknown as Blob)
    formData.append('options', JSON.stringify(options))
    const res = await this.agent.post<{ url: string }>('upload/photo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res.data
  }

  public async file(fileData: File | IMobileFile, params?: IUploadQueryParams): Promise<IFileResponse> {
    const formData = new FormData()
    formData.append('upload', fileData as unknown as Blob)
    const res = await this.agent.post<IFileResponse>('upload/file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params,
    })
    return res.data
  }

  public async publicFile(fileData: File | IMobileFile, body: IPublicUploadFilePostBody): Promise<IFileResponse> {
    const formData = new FormData()
    formData.append('upload', fileData as unknown as Blob)
    formData.append('organizationId', body.organizationId)
    const res = await this.agent.post<IFileResponse>('upload/publicFile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res.data
  }
}
