import { PermissionType } from '../PermissionType'
import { OAuthScope } from './OAuthScope'

// Use `//` as a prettier hack to keep all arrays multi-lined and more readable (https://github.com/prettier/prettier-vscode/issues/352)
export const OAuthScopePermissionMap: Record<OAuthScope, PermissionType[]> = {
  [OAuthScope.AnnouncementRead]: [
    PermissionType.GET_ANY_ANNOUNCEMENT, //
    PermissionType.LIST_ACTIVE_ANNOUNCEMENT,
  ],
  [OAuthScope.AnnouncementWrite]: [
    PermissionType.WRITE_ANY_ANNOUNCEMENT, //
  ],
  [OAuthScope.DriverOperationsWrite]: [
    PermissionType.POST_DOPS_COMPLETE_SLOT,
    PermissionType.POST_DOPS_COMPLETE_DUTY,
    PermissionType.POST_DOPS_UPDATE_OFFER,
  ],
  [OAuthScope.DriverRead]: [
    PermissionType.GET_ANY_DRIVER, //
    PermissionType.LIST_ANY_DRIVER,
  ],
  [OAuthScope.DriverWrite]: [
    PermissionType.POST_ANY_DRIVER,
    PermissionType.PATCH_ANY_DRIVER,
    PermissionType.DELETE_ANY_DRIVER,
  ],
  [OAuthScope.DutyRead]: [
    PermissionType.GET_ANY_DUTY,
    PermissionType.LIST_ANY_DUTY,
    PermissionType.RECEIVE_DUTY_MATCHING_STATUS,
  ],
  [OAuthScope.DutyWrite]: [
    PermissionType.POST_ANY_DUTY,
    PermissionType.POST_ANY_MULTIPLE_DUTIES,
    PermissionType.PATCH_ANY_DUTY,
    PermissionType.DELETE_ANY_DUTY,
    PermissionType.DELETE_ANY_MULTIPLE_DUTIES,
    PermissionType.CANCEL_ANY_DUTY,
  ],
  [OAuthScope.DutyRequestOfferRead]: [
    PermissionType.GET_ANY_DUTY_REQUEST_OFFER, //
  ],
  [OAuthScope.FarePassRead]: [
    PermissionType.GET_ANY_FARE_PASS, //
  ],
  [OAuthScope.FarePassWrite]: [
    PermissionType.WRITE_ANY_FARE_PASS, //
  ],
  [OAuthScope.FarePassAllocationRead]: [
    PermissionType.GET_ANY_FARE_PASS_ALLOCATION, //
  ],
  [OAuthScope.FarePassAllocationWrite]: [
    PermissionType.POST_ANY_FARE_PASS_ALLOCATION,
    PermissionType.PATCH_ANY_FARE_PASS_ALLOCATION,
    PermissionType.IMPORT_ANY_FARE_PASS_ALLOCATION,
  ],
  [OAuthScope.FavoriteLocationRead]: [
    PermissionType.GET_ANY_FAVORITE_LOCATION, //
  ],
  [OAuthScope.FavoriteLocationWrite]: [
    PermissionType.WRITE_ANY_FAVORITE_LOCATION,
    PermissionType.DELETE_ANY_FAVORITE_LOCATION,
  ],
  [OAuthScope.FleetRead]: [
    PermissionType.GET_ANY_FLEET, //
    PermissionType.LIST_ANY_FLEET,
  ],
  [OAuthScope.FleetWrite]: [
    PermissionType.POST_ANY_FLEET, //
    PermissionType.PATCH_ANY_FLEET,
  ],
  [OAuthScope.GroupRead]: [
    PermissionType.GET_ANY_GROUP, //
    PermissionType.LIST_ANY_GROUP,
  ],
  [OAuthScope.GroupWrite]: [
    PermissionType.WRITE_ANY_GROUP, //
  ],
  [OAuthScope.GroupMembershipRead]: [
    PermissionType.GET_ANY_GROUP_MEMBERSHIP, //
  ],
  [OAuthScope.GroupMembershipWrite]: [
    PermissionType.GET_ANY_GROUP_MEMBERSHIP, //
  ],
  [OAuthScope.NotificationSettingRead]: [
    PermissionType.GET_ANY_NOTIFICATION_SETTING, //
  ],
  [OAuthScope.NotificationSettingWrite]: [
    PermissionType.WRITE_ANY_NOTIFICATION_SETTING, //
    PermissionType.DELETE_ANY_NOTIFICATION_SETTING,
  ],
  [OAuthScope.PaymentMethodRead]: [
    PermissionType.GET_ANY_PAYMENT_METHOD,
    PermissionType.RECEIVE_PAYMENT_METHOD_ARCHIVED,
    PermissionType.RECEIVE_PAYMENT_METHOD_CREATED,
  ],
  [OAuthScope.OrganizationConfigurationRead]: [
    PermissionType.GET_OWN_ORGANIZATION_CONFIGURATION, //
  ],
  [OAuthScope.PaymentMethodWrite]: [
    PermissionType.DELETE_ANY_PAYMENT_METHOD, //
  ],
  [OAuthScope.PromoRead]: [
    PermissionType.GET_ANY_PROMO, //
    PermissionType.LIST_ANY_PROMO,
  ],
  [OAuthScope.PromoWrite]: [
    PermissionType.WRITE_ANY_PROMO, //
  ],
  [OAuthScope.PromoAllocationRead]: [
    PermissionType.GET_ANY_PROMO_ALLOCATION, //
  ],
  [OAuthScope.PromoAllocationWrite]: [
    PermissionType.POST_ANY_PROMO_ALLOCATION, //
  ],
  [OAuthScope.RequestRead]: [
    PermissionType.GET_ANY_REQUEST, //
    PermissionType.LIST_ANY_REQUEST,
  ],
  [OAuthScope.RequestWrite]: [
    PermissionType.POST_ANY_REQUEST, //
    PermissionType.POST_ANY_REQUEST_CANCELLATION,
    PermissionType.POST_ANY_REQUEST_NO_SHOW_REVERSAL,
    PermissionType.POST_ANY_REQUEST_REMATCH,
    PermissionType.PATCH_ANY_REQUEST,
    PermissionType.PATCH_ANY_REQUEST_METADATA,
    PermissionType.IMPORT_ANY_REQUEST,
  ],
  [OAuthScope.RequestEstimateRead]: [
    PermissionType.GET_ANY_ESTIMATE_REQUEST,
    PermissionType.GET_ANY_ESTIMATE_REQUEST_WITH_OVERRIDES,
    PermissionType.GET_ESTIMATE_SERVICES,
    PermissionType.GET_ESTIMATE_SERVICES_FROM,
    PermissionType.GET_ESTIMATE_SERVICES_TO,
  ],
  [OAuthScope.RequestRecurrenceRead]: [
    PermissionType.GET_ANY_REQUEST_RECURRENCE, //
  ],
  [OAuthScope.RequestRecurrenceWrite]: [
    PermissionType.WRITE_ANY_REQUEST_RECURRENCE,
    PermissionType.IMPORT_ANY_REQUEST_RECURRENCE,
  ],
  [OAuthScope.RequestSlotRead]: [
    PermissionType.GET_ANY_REQUEST_SLOTS, //
  ],
  [OAuthScope.RequestInsightRead]: [
    PermissionType.GET_ANY_REQUEST_INSIGHTS, //
  ],
  [OAuthScope.RequestVehicleRead]: [
    PermissionType.GET_ANY_REQUEST_VEHICLE, //
  ],
  [OAuthScope.RequestVehicleLocationRead]: [
    PermissionType.GET_ANY_LAST_REQUEST_VL, //
  ],
  [OAuthScope.ReviewRead]: [
    PermissionType.GET_ANY_REVIEW, //
  ],
  [OAuthScope.ReviewWrite]: [
    PermissionType.POST_ANY_REVIEW, //
  ],
  [OAuthScope.RiderRead]: [
    PermissionType.GET_ANY_RIDER, //
    PermissionType.LIST_ANY_RIDER,
  ],
  [OAuthScope.RiderWrite]: [
    PermissionType.POST_ANY_RIDER, //
    PermissionType.PATCH_ANY_RIDER,
    PermissionType.DELETE_ANY_RIDER,
  ],
  [OAuthScope.ServiceRead]: [
    PermissionType.GET_ANY_SERVICE, //
    PermissionType.LIST_ANY_SERVICE,
  ],
  [OAuthScope.ServiceWrite]: [
    PermissionType.WRITE_ANY_SERVICE, //
  ],
  [OAuthScope.ServiceFleetRead]: [
    PermissionType.LIST_ANY_SERVICE_FLEET, //
  ],
  [OAuthScope.ServiceFleetWrite]: [
    PermissionType.POST_ANY_SERVICE_FLEET, //
    PermissionType.PATCH_ANY_SERVICE_FLEET,
    PermissionType.DELETE_ANY_SERVICE_FLEET,
  ],
  [OAuthScope.SlotRead]: [
    PermissionType.GET_ANY_SLOT, //
    PermissionType.LIST_ANY_SLOT,
  ],
  [OAuthScope.StopRead]: [
    PermissionType.GET_ANY_STOP, //
    PermissionType.LIST_ANY_STOP,
  ],
  [OAuthScope.StopWrite]: [
    PermissionType.POST_ANY_STOP, //
    PermissionType.PATCH_ANY_STOP,
    PermissionType.DELETE_ANY_STOP,
  ],
  [OAuthScope.TimeRuleRead]: [
    PermissionType.GET_ANY_TIME_RULE, //
    PermissionType.LIST_ANY_TIME_RULE,
  ],
  [OAuthScope.TimeRuleWrite]: [
    PermissionType.POST_ANY_TIME_RULE,
    PermissionType.PATCH_ANY_TIME_RULE,
    PermissionType.DELETE_ANY_TIME_RULE,
  ],
  [OAuthScope.TipRead]: [
    PermissionType.GET_ANY_TIP, //
  ],
  [OAuthScope.TipWrite]: [
    PermissionType.POST_ANY_TIP, //
  ],
  [OAuthScope.TipPolicyRead]: [
    PermissionType.GET_ANY_TIP_POLICY, //
    PermissionType.LIST_ANY_TIP_POLICY,
  ],
  [OAuthScope.TipPolicyWrite]: [
    PermissionType.WRITE_ANY_TIP_POLICY, //
    PermissionType.DELETE_ANY_TIP_POLICY,
  ],
  [OAuthScope.UserBanRead]: [
    PermissionType.GET_ANY_USER_BAN, //
  ],
  [OAuthScope.UserBanWrite]: [
    PermissionType.WRITE_ANY_USER_BAN, //
  ],
  [OAuthScope.UserFleetAgreementRead]: [
    PermissionType.GET_ANY_USER_FLEET_AGREEMENT, //
  ],
  [OAuthScope.UserFleetAgreementWrite]: [
    PermissionType.POST_ANY_USER_FLEET_AGREEMENT, //
    PermissionType.DELETE_ANY_USER_FLEET_AGREEMENT,
    PermissionType.POST_ANY_USER_FLEET_AGREEMENT_SEND,
  ],
  [OAuthScope.VehicleRead]: [
    PermissionType.GET_ANY_VEHICLE, //
    PermissionType.LIST_ANY_VEHICLE,
  ],
  [OAuthScope.VehicleWrite]: [
    PermissionType.POST_ANY_VEHICLE, //
    PermissionType.PATCH_ANY_VEHICLE,
  ],
  [OAuthScope.ZoneRead]: [
    PermissionType.GET_ANY_ZONE, //
    PermissionType.LIST_ANY_ZONE,
  ],
  [OAuthScope.ZoneWrite]: [
    PermissionType.POST_ANY_ZONE, //
    PermissionType.PATCH_ANY_ZONE,
    PermissionType.DELETE_ANY_ZONE,
  ],
  [OAuthScope.ZoneAreaRead]: [
    PermissionType.GET_ANY_ZONE_AREA, //
    PermissionType.LIST_ANY_ZONE_AREA,
  ],
  [OAuthScope.ZoneAreaWrite]: [
    PermissionType.POST_ANY_ZONE_AREA, //
    PermissionType.PATCH_ANY_ZONE_AREA,
    PermissionType.DELETE_ANY_ZONE_AREA,
  ],
  [OAuthScope.ZoneStopRead]: [
    PermissionType.GET_ANY_ZONE_STOP, //
  ],
  [OAuthScope.ZoneStopWrite]: [
    PermissionType.WRITE_ANY_ZONE_STOP, //
    PermissionType.DELETE_ANY_ZONE_STOP,
  ],
}
