import { WebColors } from '@sparelabs/colors'
import React from 'react'
import { st } from 'src/locales/TranslationHelper'
import styled from 'styled-components'

const GrayField = styled.span`
  color: ${WebColors.contentTertiary};
`

export const NoneField = () => <GrayField>{st.common.none()}</GrayField>
