export class DefaultValueMap<K, V> {
  private readonly map = new Map<K, V>()
  constructor(private readonly initializeDefaultValue: () => V) {}

  public get(key: K): V {
    if (!this.map.has(key)) {
      this.map.set(key, this.initializeDefaultValue())
    }
    return this.map.get(key) as V
  }

  public set(key: K, value: V) {
    return this.map.set(key, value)
  }

  public asMap(): Map<K, V> {
    return this.map
  }

  public clear(): void {
    this.map.clear()
  }
}
