import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IVehicleLocationListQueryParams,
  IVehicleLocationPostBody,
  IVehicleLocationResponse,
  Resources,
} from '../types'

export class VehicleLocationResource extends GenericResource<
  IVehicleLocationResponse,
  IVehicleLocationListQueryParams,
  never,
  IVehicleLocationPostBody
> {
  public list = this.buildList()

  constructor(agent: AxiosInstance) {
    super(Resources.VehicleLocations, agent)
  }
}
