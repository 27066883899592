import { EventSource } from '@sparelabs/api-client'
import { ICaseFormResponse } from './CaseFormTypes'
import { CaseTimerEventType } from './CaseTypes'

export interface IListOperationQueryParams {
  limit?: number
  skip?: number
}

export interface IAllCasesExportQueryParams extends IListOperationQueryParams {
  fromReceivedTs?: number
  toReceivedTs?: number
  caseTypeId?: string
  formIds?: string[]
}

// TODO: [OPEN-920] refactor IAllCasesExportResponse to clean up and reduce number of fields
export interface IAllCasesExportResponse {
  caseId: string
  riderId: string
  riderName: string | null
  received: string
  caseTemplateId: string
  caseTemplateName: string
  status: string
  assignedToId: string | null
  assignedToName: string | null
  timerLimit: number | null
  timerState: CaseTimerEventType | null
  timerDaysCounted: number | null
  timerDaysUntilLimit: number | null
  timerDeadline: string | null
  notes: string | null
  caseForms: ICaseFormResponse[] | null
}

export interface ICaseActivityLogExportQueryParams extends IListOperationQueryParams {
  caseId: string
}

export enum CaseEventType {
  CaseCreated = 'caseCreated',
  CaseUpdated = 'caseUpdated',
  CaseFormCreated = 'caseFormCreated',
  CaseFormUpdated = 'caseFormUpdated',
  CaseLetterCreated = 'caseLetterCreated',
  CaseLetterUpdated = 'caseLetterUpdated',
  LetterGenerated = 'letterGenerated',
}

export interface ICaseActivityLogExportResponse {
  time: string
  activity: CaseEventType
  createdInterface: EventSource | null
  authorId: string | null
  authorName: string | null
  details: Record<string, unknown>
}
