import { RecursivePartial } from '@sparelabs/core'
import { IModelJsonValues } from '@sparelabs/http-client-utils'
import { IPaymentProviderConfigGoPass } from './GoPassTypes'
import { IPaymentProviderConfigNimoca } from './NimocaTypes'
import { IPaymentProviderConfigStripe, IStripeConnectSetupConfig } from './StripeTypes'
import { IPaymentProviderConfigWallet } from './WalletTypes'

export enum PaymentProviderType {
  Stripe = 'stripe',
  Nimoca = 'nimoca',
  GoPass = 'gopass',
  Wallet = 'wallet',
}

export type IPaymentProviderConfig =
  | IPaymentProviderConfigStripe
  | IPaymentProviderConfigNimoca
  | IPaymentProviderConfigGoPass
  | IPaymentProviderConfigWallet

export interface IPaymentProviderResponse extends IModelJsonValues {
  type: PaymentProviderType
  config: Omit<IPaymentProviderConfig, 'clientUnsafe'>
}

export interface IPaymentProviderPostBody {
  type: PaymentProviderType
  config: IPaymentProviderConfig | IStripeConnectSetupConfig
  /**
   * Optional authorization code used for payment providers (i.e. Stripe) which rely on an OAuth connection flow
   */
  authorizationCode?: string
}

export interface IPaymentProviderPatchConfig {
  clientSafe?: IPaymentProviderConfig['clientSafe']
  /** clientUnsafe values will be merged with existing values, so that they do not have to be entered in the frontend every time */
  clientUnsafe?: RecursivePartial<IPaymentProviderConfig['clientUnsafe']>
}

export interface IPaymentProviderPatchBody {
  config: IPaymentProviderPatchConfig | IStripeConnectSetupConfig
  /**
   * Optional authorization code used for payment providers (i.e. Stripe) which rely on an OAuth connection flow
   */
  authorizationCode?: string
}

export interface IStripeConnectPostBody extends IPaymentProviderPostBody {
  config: IStripeConnectSetupConfig
  authorizationCode: string
}

export interface IStripeConnectPatchBody extends IPaymentProviderPatchBody {
  config: IStripeConnectSetupConfig
  authorizationCode: string
}
