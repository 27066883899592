import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IAdminListQueryParams, IAdminPatchBody, IAdminPostBody, IAdminResponse, Resources } from '../types'

export class AdminResource extends GenericResource<
  IAdminResponse,
  IAdminListQueryParams,
  IAdminPatchBody,
  IAdminPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public del = this.buildDel()
  public post = this.buildPost()
  constructor(agent: AxiosInstance) {
    super(Resources.Admins, agent)
  }
}
