import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IRequestPredictionQueryParams, IRequestPredictionResponse, Resources } from '../types'

export class RequestPredictionResource extends GenericResource<IRequestPredictionResponse[], never, never, never> {
  constructor(agent: AxiosInstance) {
    super(Resources.RequestPredictions, agent)
  }

  public async getPredictedRequests(
    riderId: string,
    params?: IRequestPredictionQueryParams
  ): Promise<IRequestPredictionResponse[]> {
    const { data } = await this.agent.get<IRequestPredictionResponse[]>(`${this.resource}/${riderId}`, {
      params,
    })
    return data
  }
}
