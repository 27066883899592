import { IListModelQueryParamValues } from '@sparelabs/http-client-utils'

export enum CallCentreMetricType {
  Ivr = 'ivr',
}

export type CallCentreMetricOutputType = IIvrCallMetrics

interface ICallCentreMetricOutput<T extends CallCentreMetricType, D extends CallCentreMetricOutputType> {
  type: T
  data: D
}

export type ICallCentreMetric = ICallCentreMetricOutput<CallCentreMetricType.Ivr, IIvrCallMetrics>

export interface ICallCentreMetricsResponse {
  metrics: ICallCentreMetric[]
}
export interface IListCallCentreMetricsQueryParams extends IListModelQueryParamValues {
  metricTypes: CallCentreMetricType[]
  fromTs: number
  toTs: number
}

export interface IIvrCallMetrics {
  totalCalls: number
  totalCallsForwardedToSupport: number
  totalIvrDurationS: number
  totalEtaActionCount: number
  totalCancellationActionCount: number
  totalBookingActionCount: number
  totalUniqueUsers: number
  totalCallsWithForwardSupportAndActions: number
  totalCallsWithEtaAction: number
  totalCallsWithCancellationAction: number
  totalCallsWithBookingAction: number
}
