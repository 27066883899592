export interface IFormParams {
  organizationId: string
  caseTypeKey: string
  formKey: string
  caseId?: string
}

export class FormParamParser {
  public static parse(search: string): IFormParams {
    const params = new URLSearchParams(search)

    return {
      organizationId: this.getSearchParamOrThrow(params, 'organizationId'),
      caseTypeKey: this.getSearchParamOrThrow(params, 'caseTypeKey'),
      formKey: this.getSearchParamOrThrow(params, 'formKey'),
      caseId: params.get('caseId') || undefined,
    }
  }

  private static getSearchParamOrThrow(params: URLSearchParams, key: keyof IFormParams): string {
    const param = params.get(key)
    if (!param) {
      throw new Error(`Missing a search parameter: ${key}`)
    }
    return param
  }
}
