import { AxiosInstance } from 'axios'
import {
  IWorkOsAuthPostBody,
  IWorkOsAuthResponse,
  IWorkOsCallbackAdminBody,
  IWorkOsCallbackResponse,
  IWorkOsCallbackRiderBody,
  IWorkOsGenerateAdminPortalUrlBody,
  IWorkOsGenerateAdminPortalUrlResponse,
} from '../types'

export class WorkOsResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async auth(body: IWorkOsAuthPostBody): Promise<IWorkOsAuthResponse> {
    const res = await this.agent.post<IWorkOsAuthResponse>('identity/workos/auth', body)
    return res.data
  }

  public async callbackRider(body: IWorkOsCallbackRiderBody): Promise<IWorkOsCallbackResponse> {
    const res = await this.agent.post<IWorkOsCallbackResponse>('identity/workos/callback/rider', body)
    return res.data
  }

  public async callbackAdmin(body: IWorkOsCallbackAdminBody): Promise<IWorkOsCallbackResponse> {
    const res = await this.agent.post<IWorkOsCallbackResponse>('identity/workos/callback/admin', body)
    return res.data
  }

  public async generateAdminPortalUrl(
    body: IWorkOsGenerateAdminPortalUrlBody
  ): Promise<IWorkOsGenerateAdminPortalUrlResponse> {
    const res = await this.agent.post<IWorkOsGenerateAdminPortalUrlResponse>(
      'identity/workos/generateAdminPortalUrl',
      body
    )
    return res.data
  }
}
