import React from 'react'
import { Redirect, useParams } from 'react-router'

interface IExternalFormParams {
  orgId: string
  caseTypeKey: string
}

export const OldFormRoutes = (): JSX.Element => {
  const { orgId, caseTypeKey } = useParams<IExternalFormParams>()

  const search = new URLSearchParams({
    organizationId: orgId,
    caseTypeKey,
    formKey: caseTypeKey,
  }).toString()

  return <Redirect exact from='/forms/:orgId/:caseTypeKey' to={{ pathname: '/forms', search }} />
}
