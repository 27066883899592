import { AxiosInstance } from 'axios'
import { IJourneyEstimateQueryParams, IJourneyEstimateResponse, Resources } from '../types'

export class JourneyEstimateResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async list(params: IJourneyEstimateQueryParams): Promise<IJourneyEstimateResponse> {
    const { data } = await this.agent.get<IJourneyEstimateResponse>(`${Resources.Estimates}/journeys`, { params })
    return data
  }
}
