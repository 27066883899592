import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum ServicePermissionSet {
  View = 'service_view',
  Manage = 'service_manage',
  Share = 'service_share',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const servicePermissionSetGrouping: IPermissionSetGrouping<ServicePermissionSet> = {
  key: PermissionSetGroupingKey.Service,
  active: {
    [ServicePermissionSet.View]: [
      PermissionType.GET_ANY_SERVICE,
      PermissionType.LIST_ANY_SERVICE,
      PermissionType.GET_ANY_FLEET,
      PermissionType.LIST_ANY_FLEET,
      PermissionType.LIST_ANY_SERVICE_FLEET,
      PermissionType.GET_ANY_FLEET_PROVIDER,
      PermissionType.LIST_ANY_FLEET_ACCESS,
      PermissionType.GET_ANY_TIME_RULE,
      PermissionType.LIST_ANY_TIME_RULE,
      PermissionType.GET_ANY_STOP,
      PermissionType.LIST_ANY_STOP,
      PermissionType.GET_ANY_ZONE,
      PermissionType.LIST_ANY_ZONE,
      PermissionType.GET_ANY_FARE_RULE,
      PermissionType.LIST_ANY_FARE_RULE,
      PermissionType.GET_ANY_TIP_POLICY,
      PermissionType.LIST_ANY_TIP_POLICY,
      PermissionType.GET_ANY_ROUTE,
      PermissionType.LIST_ANY_ROUTE,
      PermissionType.GET_ANY_BREAK_POLICY,
      PermissionType.GET_ANY_RIDER_DRIVER_RESTRICTION,
      PermissionType.GET_ANY_CONSTRAINT_OVERRIDE_ACTION,
      PermissionType.GET_ANY_REQUEST_CONSTRAINT_OVERRIDE,
      PermissionType.GET_ANY_OPEN_FLEET_CONFIGURATION,
      PermissionType.LIST_ANY_OPEN_FLEET_CONFIGURATION,
      PermissionType.GET_ANY_OPEN_FLEET_LIMIT,
      PermissionType.GET_ANY_ZONE_AREA,
      PermissionType.LIST_ANY_ZONE_AREA,
      PermissionType.GET_ANY_ZONE_STOP,
    ],
    [ServicePermissionSet.Manage]: [
      PermissionType.WRITE_ANY_SERVICE,
      PermissionType.DELETE_ANY_SERVICE,
      PermissionType.PATCH_ANY_SERVICE_FLEET,
      PermissionType.POST_ANY_SERVICE_FLEET,
      PermissionType.DELETE_ANY_SERVICE_FLEET,
      PermissionType.PATCH_ANY_FLEET,
      PermissionType.POST_ANY_FLEET,
      PermissionType.POST_ANY_TIME_RULE,
      PermissionType.PATCH_ANY_TIME_RULE,
      PermissionType.DELETE_ANY_TIME_RULE,
      PermissionType.POST_ANY_STOP,
      PermissionType.PATCH_ANY_STOP,
      PermissionType.DELETE_ANY_STOP,
      PermissionType.DELETE_ANY_MULTIPLE_STOPS,
      PermissionType.POST_ANY_ZONE,
      PermissionType.PATCH_ANY_ZONE,
      PermissionType.DELETE_ANY_ZONE,
      PermissionType.WRITE_ANY_FARE_RULE,
      PermissionType.DELETE_ANY_FARE_RULE,
      PermissionType.WRITE_ANY_TIP_POLICY,
      PermissionType.DELETE_ANY_TIP_POLICY,
      PermissionType.WRITE_ANY_ROUTE,
      PermissionType.POST_ANY_BREAK_POLICY,
      PermissionType.PATCH_ANY_BREAK_POLICY,
      PermissionType.DELETE_ANY_BREAK_POLICY,
      PermissionType.POST_ANY_RIDER_DRIVER_RESTRICTION,
      PermissionType.DELETE_ANY_RIDER_DRIVER_RESTRICTION,
      PermissionType.WRITE_ANY_OPEN_FLEET_CONFIGURATION,
      PermissionType.WRITE_ANY_OPEN_FLEET_LIMIT,
      PermissionType.POST_ANY_MATCHING_SIMULATION,
      PermissionType.POST_ANY_ZONE_AREA,
      PermissionType.PATCH_ANY_ZONE_AREA,
      PermissionType.DELETE_ANY_ZONE_AREA,
      PermissionType.WRITE_ANY_ZONE_STOP,
      PermissionType.DELETE_ANY_ZONE_STOP,
    ],
    [ServicePermissionSet.Share]: [
      PermissionType.POST_ANY_FLEET_ACCESS,
      PermissionType.DELETE_ANY_FLEET_ACCESS,
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
