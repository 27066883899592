import { AxiosInstance } from 'axios'
import {
  IAdminMePatchBody,
  IAdminMeResponse,
  IDriverMePatchBody,
  IDriverMeResponse,
  IRiderMeJoinGroupPostBody,
  IRiderMePatchBody,
  IRiderMeResponse,
  IUserIntercomHashResponse,
  IUserMePermissionsResponse,
  IUserMePhoneRequestPostBody,
  IUserMePhoneVerifyPostBody,
  IUserSupportProviderHashResponse,
  RE_CAPTCHA_TOKEN_HEADER,
  Resources,
} from '../types'

export class UserResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async getIntercomHash(): Promise<IUserIntercomHashResponse> {
    const res = await this.agent.get<IUserIntercomHashResponse>(`${Resources.Users}/me/intercomHash`)
    return res.data
  }

  public async getSupportProviderHash(): Promise<IUserSupportProviderHashResponse> {
    const res = await this.agent.get<IUserSupportProviderHashResponse>(`${Resources.Users}/me/supportProviderHash`)
    return res.data
  }

  public async getMePermissions(): Promise<IUserMePermissionsResponse> {
    const res = await this.agent.get<IUserMePermissionsResponse>(`${Resources.Users}/me/permissions`)
    return res.data
  }

  public async getMeDriver(): Promise<IDriverMeResponse> {
    const res = await this.agent.get<IDriverMeResponse>(`${Resources.Users}/me/driver`)
    return res.data
  }

  public async patchMeDriver(body: IDriverMePatchBody): Promise<IDriverMeResponse> {
    const res = await this.agent.patch<IDriverMeResponse>(`${Resources.Users}/me/driver`, body)
    return res.data
  }

  public async getMeAdmin(): Promise<IAdminMeResponse> {
    const res = await this.agent.get<IAdminMeResponse>(`${Resources.Users}/me/admin`)
    return res.data
  }

  public async patchMeAdmin(body: IAdminMePatchBody): Promise<IAdminMeResponse> {
    const res = await this.agent.patch<IAdminMeResponse>(`${Resources.Users}/me/admin`, body)
    return res.data
  }

  public async getMeRider(): Promise<IRiderMeResponse> {
    const res = await this.agent.get<IRiderMeResponse>(`${Resources.Users}/me/rider`)
    return res.data
  }

  public async patchMeRider(body: IRiderMePatchBody): Promise<IRiderMeResponse> {
    const res = await this.agent.patch<IRiderMeResponse>(`${Resources.Users}/me/rider`, body)
    return res.data
  }

  public async deleteMeRider(): Promise<void> {
    await this.agent.delete<void>(`${Resources.Users}/me/rider`)
  }

  public async joinRiderGroup(body: IRiderMeJoinGroupPostBody): Promise<IRiderMeResponse> {
    const res = await this.agent.post<IRiderMeResponse>(`${Resources.Users}/me/rider/group`, body)
    return res.data
  }

  public async leaveRiderGroup(): Promise<IRiderMeResponse> {
    const res = await this.agent.delete<IRiderMeResponse>(`${Resources.Users}/me/rider/group`)
    return res.data
  }

  public async leaveRiderGroupById(id: string): Promise<void> {
    await this.agent.delete(`${Resources.Users}/me/rider/group/${id}`)
  }

  public async postUserMePhoneRequest(body: IUserMePhoneRequestPostBody, reCaptchaToken?: string): Promise<void> {
    await this.agent.post<void>(`${Resources.Users}/me/phone/request`, body, {
      headers: reCaptchaToken ? { [RE_CAPTCHA_TOKEN_HEADER]: reCaptchaToken } : {},
    })
  }

  public async postUserMePhoneVerify(body: IUserMePhoneVerifyPostBody): Promise<void> {
    await this.agent.post<void>(`${Resources.Users}/me/phone/verify`, body)
  }
}
