import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IGetPlaceDetailsQueryParams, IPlaceDetailsResponse, Resources } from '../types'

export class PlaceDetailsResource extends GenericResource<
  IPlaceDetailsResponse,
  IGetPlaceDetailsQueryParams,
  never,
  never
> {
  constructor(agent: AxiosInstance) {
    super(Resources.PlaceDetails, agent)
  }

  public async get(params: IGetPlaceDetailsQueryParams): Promise<IPlaceDetailsResponse> {
    const res = await this.agent.get<IPlaceDetailsResponse>(this.resource, { params })
    return res.data
  }
}
