export enum Resources {
  CaseForms = 'engage/caseForms',
  CaseLetters = 'engage/caseLetters',
  Cases = 'engage/cases',
  CaseStatuses = 'engage/caseStatuses',
  CaseTypes = 'engage/caseTypes',
  FormContents = 'engage/formContents',
  Forms = 'engage/forms',
  Letters = 'engage/letters',
  PublicCaseForms = 'public/engage/caseForms',
  PublicForm = 'public/engage/form',
  NylasOrganizationGrants = 'engage/nylas/organizationGrants',
  NylasAdminGrants = 'engage/nylas/adminGrants',
}
