import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IListServiceMetricsQueryParams, IListServiceMetricsResponse, Resources } from '../types'

export class ServiceMetricsResource extends GenericResource<
  IListServiceMetricsResponse,
  IListServiceMetricsQueryParams,
  never,
  never
> {
  // the return value of the /metrics/services endpoint differs from the generic IListResponse
  public fetchMetrics = async (params: IListServiceMetricsQueryParams): Promise<IListServiceMetricsResponse> => {
    const response = await this.agent.get<IListServiceMetricsResponse>(`${this.resource}`, { params })
    return response.data
  }

  constructor(agent: AxiosInstance) {
    super(Resources.ServiceMetrics, agent)
  }
}
