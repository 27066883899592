import { IListModelQueryParamValues } from '@sparelabs/http-client-utils'

type Latitude = number
type Longitude = number
export type PlacesLocation = [Latitude, Longitude]

export interface IGetAutocompleteSuggestionsQueryParams extends IListModelQueryParamValues {
  search: string
  location?: PlacesLocation
  language?: string
  useStrictBounds?: boolean
  radius?: number
}

export interface IGetPlaceDetailsQueryParams extends IListModelQueryParamValues {
  placeId: string
  language?: string
}

export interface IGetReverseGeocodeQueryParams extends IListModelQueryParamValues {
  location: PlacesLocation
  language?: string
}

export interface IGetGeocodeQueryParams extends IListModelQueryParamValues {
  address: string
  language?: string
}

export interface IAutocompleteSuggestionsResponse {
  placeId: string
  label: string
  subLabel: string
}

export interface IPlaceDetailsResponse {
  placeId: string
  name?: string
  location?: PlacesLocation
  address?: string
}

export interface IReverseGeocodeResponse {
  placeId?: string
  address?: string
}

export enum GoogleMapsError {
  MultipleLocationsFound = 'MultipleLocationsFound',
}
export interface IGeocodeResponse {
  location?: PlacesLocation
  placeId?: string
  error?: GoogleMapsError
}
