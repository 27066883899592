import { isNumber } from '@sparelabs/core'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'

export type IGroupCondition =
  | ITemperatureGroupCondition
  | ITimeOfDayGroupCondition
  | ITimeOfYearGroupCondition
  | IFreeTextGroupCondition

export type IGroupConditionResponse = IModelJsonValues & IGroupCondition

export type IGroupConditionPostBody = IGroupCondition
export type IGroupConditionPatchBody = Pick<Partial<IGroupConditionPostBody>, 'name' | 'config'>

export interface IGroupConditionQueryParams extends IListModelQueryParamValues {
  groupId?: string
}

export enum GroupConditionType {
  Temperature = 'temperature',
  TimeOfDay = 'timeOfDay',
  TimeOfYear = 'timeOfYear',
  FreeText = 'freeText',
}

export interface IGroupConditionCommon {
  name: string
  groupId: string
  type: GroupConditionType
  config: Record<string, unknown>
}

export interface ITemperatureGroupCondition extends IGroupConditionCommon {
  type: GroupConditionType.Temperature
  config: {
    /**
     * Temperature must be above this value to be eligible
     */
    above: number | null
    /**
     * Temperature must be below this value to be eligible
     */
    below: number | null
  }
}

export interface ITimeOfDayGroupCondition extends IGroupConditionCommon {
  type: GroupConditionType.TimeOfDay
  config: {
    /**
     * Time must be after this time to be eligible. Seconds since midnight.
     */
    after: number | null
    /**
     * Time must be before this time to be eligible. Seconds since midnight.
     */
    before: number | null
  }
}

export interface IMonthDay {
  /**
   * 1 - 12
   */
  month: number
  /**
   * 1 - 31
   */
  day: number
}

export interface ITimeOfYearGroupCondition extends IGroupConditionCommon {
  type: GroupConditionType.TimeOfYear
  config: {
    /**
     * Time must be on or after this date to be eligible
     */
    start: IMonthDay
    /**
     * Time must be on or before this date to be eligible
     */
    end: IMonthDay
  }
}

export interface IFreeTextGroupCondition extends IGroupConditionCommon {
  type: GroupConditionType.FreeText
  config: {
    /**
     * The free text to display
     */
    text: string
  }
}

export type IGroupConditionConfig = IGroupConditionResponse['config']

export const isTemperatureGroupCondition = (
  condition: IGroupConditionCommon
): condition is ITemperatureGroupCondition =>
  condition.type === GroupConditionType.Temperature &&
  'above' in condition.config &&
  'below' in condition.config &&
  (isNumber(condition.config.above) || isNumber(condition.config.below))

export const isTimeOfDayGroupCondition = (condition: IGroupConditionCommon): condition is ITimeOfDayGroupCondition =>
  condition.type === GroupConditionType.TimeOfDay &&
  'after' in condition.config &&
  'before' in condition.config &&
  (isNumber(condition.config.after) || isNumber(condition.config.before))

export const isTimeOfYearGroupCondition = (condition: IGroupConditionCommon): condition is ITimeOfYearGroupCondition =>
  condition.type === GroupConditionType.TimeOfYear &&
  'start' in condition.config &&
  'end' in condition.config &&
  condition.config.start !== null &&
  condition.config.end !== null

export const isFreeTextGroupCondition = (condition: IGroupConditionCommon): condition is IFreeTextGroupCondition =>
  condition.type === GroupConditionType.FreeText &&
  'text' in condition.config &&
  typeof condition.config.text === 'string'
