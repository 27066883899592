import styled, { css } from 'styled-components'
import { borderRadius, boxShadow, boxShadowStrong } from '../util'
import { AbstractButton } from './AbstractButton'

export const roundedButtonStyle = css`
  border-radius: ${borderRadius};
  box-shadow: ${boxShadow};
  &:hover,
  &:active,
  &:focus {
    box-shadow: ${boxShadowStrong};
  }
`

export const RoundedButton = styled(AbstractButton)`
  ${roundedButtonStyle}
`
