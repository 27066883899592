import { VerificationMethod } from '@sparelabs/api-client'
import { TimeUnits, toMilliseconds } from '@sparelabs/time'
import { RoundedButton, SubmitButtonTheme, TextInput } from '@sparelabs/web-ui-components'
import React, { SyntheticEvent, useState } from 'react'
import {
  ActionButtonsWrapper,
  FormBackButton,
  FormContainer,
  InfoPrompt,
  StackedInputWithLabel,
  WRAPPING_BREAKPOINT_WIDTH_PX,
} from 'src/components'
import { ResendCodeButton } from 'src/components/buttons/ResendCodeButton'
import { st } from 'src/locales/TranslationHelper'
import styled from 'styled-components'

const VerificationCodeField = styled(StackedInputWithLabel)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const ActionButtonsContainer = styled.div`
  justify-content: space-between;
  display: flex;

  @media (max-width: ${WRAPPING_BREAKPOINT_WIDTH_PX}px) {
    flex-direction: column;
  }
`

const ResendActionButtonsWrapper = styled(ActionButtonsWrapper)`
  margin-top: 10px;
  justify-content: left;
`

const StyledActionButtonsWrapper = styled(ActionButtonsWrapper)`
  margin-top: 10px;

  @media (max-width: ${WRAPPING_BREAKPOINT_WIDTH_PX}px) {
    margin-top: 48px;
  }
`

interface IProps {
  onSubmit: (code: string) => void
  onResendClick: (method: VerificationMethod) => void
  onBackClick: () => void
  error?: string
}

export const RiderAuthVerifyForm = ({ onSubmit, onResendClick, onBackClick, error }: IProps): JSX.Element => {
  const [code, setCode] = useState('')

  const handleSubmit = (event?: SyntheticEvent) => {
    if (event) {
      event.preventDefault()
    }
    onSubmit(code)
  }

  return (
    <FormContainer onSubmit={handleSubmit}>
      <InfoPrompt>{st.forms.auth.codePrompt()}</InfoPrompt>
      <VerificationCodeField
        label={st.forms.auth.codeLabel()}
        error={error}
        renderInput={({ id }) => (
          <TextInput
            id={id}
            value={code}
            onChange={(value) => setCode(value ?? '')}
            onSubmit={handleSubmit}
            autoFocus
          />
        )}
      />
      <InfoPrompt>{st.forms.auth.trouble()}</InfoPrompt>
      <ActionButtonsContainer>
        <ResendActionButtonsWrapper>
          <ResendCodeButton
            activateInitially={true}
            handleResend={() => onResendClick(VerificationMethod.Sms)}
            countdownMs={toMilliseconds(60, TimeUnits.Seconds)}
            method={VerificationMethod.Sms}
            activeText={st.forms.auth.resendSms()}
            icon='comments'
          />
          <ResendCodeButton
            handleResend={() => onResendClick(VerificationMethod.Voice)}
            countdownMs={toMilliseconds(60, TimeUnits.Seconds)}
            method={VerificationMethod.Voice}
            activeText={st.forms.auth.resendVoice()}
            icon='phone'
          />
        </ResendActionButtonsWrapper>
        <StyledActionButtonsWrapper>
          <FormBackButton onClick={onBackClick} />
          <RoundedButton theme={SubmitButtonTheme} onClick={handleSubmit}>
            {st.common.continue()}
          </RoundedButton>
        </StyledActionButtonsWrapper>
      </ActionButtonsContainer>
    </FormContainer>
  )
}
