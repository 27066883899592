import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IFarePassAllocationListQueryParams,
  IFarePassAllocationPatchBody,
  IFarePassAllocationPostBody,
  IFarePassAllocationResponse,
  Resources,
} from '../types'

export class FarePassAllocationResource extends GenericResource<
  IFarePassAllocationResponse,
  IFarePassAllocationListQueryParams,
  IFarePassAllocationPatchBody,
  IFarePassAllocationPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.FarePassAllocations, agent)
  }
}
