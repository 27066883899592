import { IPoint } from '@sparelabs/geography'
import { CreatedInterface } from './EstimateTypes'
import { IFareRedemptionStub } from './FareRedemptionTypes'
import { IFareStub } from './FareTypes'
import { IServiceBrand } from './ServiceTypes'
import { IEstimatedRange, IPrice } from './SharedTypes'

/** @deprecated, /estimate/journey endpoint is replaced by /journeyOptions */
export interface IJourneyEstimateQueryParams {
  ts: number
  arriveBy: boolean
  startLongitude: number
  startLatitude: number
  endLongitude: number
  endLatitude: number
  legModes?: LegMode[]
  riderId?: string
  accessibilityFeatures?: string[]
  createdInterface?: CreatedInterface
  numRiders?: number
  includeMixedOptions?: boolean // TODO: Remove this field after most users adopt Rider app with mixed option changes
  paymentMethodTypeId?: string
}

/** @deprecated, /estimate/journey endpoint is replaced by /journeyOptions */
export interface IJourneyEstimateResponse {
  data: IJourney[]
}

/** @deprecated, /estimate/journey endpoint is replaced by /journeyOptions */
export interface IJourney {
  id: string
  startTime: IEstimatedRange
  endTime: IEstimatedRange
  fare: IPrice | null // null unless there is an on-demand leg
  legs: ILeg[]
  duration: number
}

/** @deprecated, /estimate/journey endpoint is replaced by /journeyOptions */
export type ILeg = IFixedRouteLeg | IWalkingLeg | IOnDemandLeg

/** @deprecated, /estimate/journey endpoint is replaced by /journeyOptions */
export interface IFixedRouteLeg extends IAbstractLeg {
  mode: LegMode.Bus | LegMode.Train | LegMode.Tram
  routeName: string
  stops: IJourneyStop[]
  routeColor?: string
  routeTextColor?: string
  interlineWithPreviousLeg: boolean
}

/** @deprecated, /estimate/journey endpoint is replaced by /journeyOptions */
export interface IJourneyStop {
  name: string
  location: IPoint
}

/** @deprecated, /estimate/journey endpoint is replaced by /journeyOptions */
export interface IOnDemandLeg extends IAbstractLeg {
  mode: LegMode.OnDemand
  serviceId: string
  serviceName: string
  serviceBrand: IServiceBrand
  start: IJourneyStop
  end: IJourneyStop
  fare: IFareStub
  fareRedemptions: IFareRedemptionStub[]
}

/** @deprecated, /estimate/journey endpoint is replaced by /journeyOptions */
export interface IWalkingLeg extends IAbstractLeg {
  mode: LegMode.Walk
}

/** @deprecated, /estimate/journey endpoint is replaced by /journeyOptions */
export interface IAbstractLeg {
  mode: LegMode
  startTime: IEstimatedRange
  endTime: IEstimatedRange
  distance: number
  polyline: string
}

/** @deprecated, /estimate/journey endpoint is replaced by /journeyOptions */
export enum LegMode {
  OnDemand = 'onDemand',
  Bus = 'bus',
  Train = 'train',
  Walk = 'walk',
  Tram = 'tram',
}

/** @deprecated, /estimate/journey endpoint is replaced by /journeyOptions */
export enum FixedLegMarkerType {
  Board = 'board',
  Exit = 'exit',
}
