export const SECOND = 1
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR
export const WEEK = 7 * DAY
export const MILLISECOND = SECOND / 1000

export enum TimeUnits {
  Milliseconds = 'milliseconds',
  Seconds = 'seconds',
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
}

const getMultiplierToSeconds = (units: TimeUnits): number => {
  switch (units) {
    case TimeUnits.Seconds:
      return 1
    case TimeUnits.Minutes:
      return MINUTE
    case TimeUnits.Hours:
      return HOUR
    case TimeUnits.Days:
      return DAY
    case TimeUnits.Milliseconds:
      return MILLISECOND
  }
}

export const toSeconds = (value: number, units: TimeUnits): number => Math.floor(value * getMultiplierToSeconds(units))

export const toMilliseconds = (value: number, units: TimeUnits = TimeUnits.Seconds): number =>
  value * 1000 * getMultiplierToSeconds(units)
