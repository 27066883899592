import { dateFormat } from '@sparelabs/time'
import moment from 'moment-timezone'
import React, { useEffect, useRef, useState } from 'react'
import { FocusedInputShape } from 'react-dates'
import { v4 } from 'uuid'
import { st } from '../../locales/TranslationHelper'
import { StyledDateRangePicker } from './styled/StyledDatePicker'

export interface IDateRange {
  startDate: string | null
  endDate: string | null
}

interface IProps {
  value?: IDateRange
  onChange: (value: IDateRange) => void
  showIcon?: boolean
  id?: string
}

export const getScreenReaderInputMessage = (focusedInput: FocusedInputShape | null): string => {
  if (focusedInput) {
    if (focusedInput === 'startDate') {
      return st.date.dateRangePicker.startDateMessage()
    }
    return st.date.dateRangePicker.endDateMessage()
  }

  return st.date.dateInputMessage()
}

export const DateRangeInput = ({ value, onChange, showIcon = false }: IProps) => {
  const [focused, setFocused] = useState<FocusedInputShape | null>(null)

  const prevFocusRef = useRef<FocusedInputShape | null>('startDate')
  const focusedStateRef = useRef<FocusedInputShape | null>(focused)
  const setFocusedAndRef = (newFocus: FocusedInputShape | null) => {
    // only update our prevFocusRef to the last non-null value
    if (focused) {
      prevFocusRef.current = focused
    }
    focusedStateRef.current = newFocus
    setFocused(newFocus)
  }

  // we add our own event listeners since react-dates does not have props to change key handlers
  useEffect(() => {
    const input: HTMLInputElement | null = document.querySelector('.DateRangePickerInput')
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setFocusedAndRef(null)
      }
      // we don't want to change focus if the calendar is currently open
      if (event.key === 'Enter' && !focusedStateRef.current) {
        setFocusedAndRef(prevFocusRef.current)
      }
    }

    input?.addEventListener('keydown', handleKeyDown)

    return () => input?.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <StyledDateRangePicker
      startDateId={v4()}
      endDateId={v4()}
      startDate={value?.startDate ? moment(value.startDate, dateFormat) : null}
      endDate={value?.endDate ? moment(value.endDate, dateFormat) : null}
      onDatesChange={({ startDate: newStartDate, endDate: newEndDate }) => {
        onChange({
          startDate: newStartDate?.format(dateFormat) ?? null,
          endDate: newEndDate?.format(dateFormat) ?? null,
        })
      }}
      focusedInput={focused}
      onFocusChange={(newFocused) => {
        setFocusedAndRef(newFocused)
      }}
      showDefaultInputIcon={showIcon}
      screenReaderInputMessage={getScreenReaderInputMessage(focused)}
    />
  )
}
