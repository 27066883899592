import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IBillingAccountAssignmentListQueryParams,
  IBillingAccountAssignmentPostBody,
  IBillingAccountAssignmentResponse,
  Resources,
} from '../types'

export class BillingAccountAssignmentResource extends GenericResource<
  IBillingAccountAssignmentResponse,
  IBillingAccountAssignmentListQueryParams,
  never,
  IBillingAccountAssignmentPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public del = this.buildDel()
  constructor(agent: AxiosInstance) {
    super(Resources.BillingAccountAssignment, agent)
  }
}
