import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum MatchingOutcomePermissionSet {
  View = 'matching_outcome_view',
}

export const matchingOutcomePermissionSetGrouping: IPermissionSetGrouping<MatchingOutcomePermissionSet> = {
  key: PermissionSetGroupingKey.MatchingOutcome,
  active: {
    [MatchingOutcomePermissionSet.View]: [PermissionType.LIST_ANY_MATCHING_OUTCOMES],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
