/**
 * Specifies the max number of rows returnable by an single call to the API.
 *
 * TODO: We've started with an unreasonably high value for this constant to ensure that
 * it doesn't break clients that currently depend on fetching many rows in a single API call.
 * This number should be reduced as these pieces of client code get upgraded.
 *
 * CURRENTLY BLOCKED BY: DutyTimeTravel.tsx, which sets a limit of 20000 on fetching vehicle locations
 * CURRENTLY BLOCKED BY: Lack of paging logic in Admin Panel / Rider App / Driver App
 */
export const API_QUERY_MAX_ROW_LIMIT: number = 20000
export const API_QUERY_MAX_ROW_OFFSET: number = 50000

/**
 * Slot limit specified by driver app and is shared due to E2E test requiring knowledge
 * of our slot limit to populate Duty's history.
 */
export const MAX_DRIVER_SLOT_LIMIT: number = 100
