import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'

export enum ReportFrequency {
  Weekly = 'weekly',
  Daily = 'daily',
}

export interface IReportResponse extends IModelJsonValues {
  id: string
  organizationId: string
  type: ReportFrequency
  scheduledDeliveryTs: number
  completedDeliveryTs: number | null
  message: string | null
  fromTs: number
  toTs: number
}

export interface IReportPatchBody {
  message: string | null
}

export interface IReportQueryParams extends IListModelQueryParamValues {
  organizationId?: string
  isDelivered?: boolean
  type?: ReportFrequency
}

export interface IReportPreviewResponse {
  html: string
}
