/**
 * Make all properties of an object mutable
 *
 * For example:
 * class Person {
 *  readonly name: string
 * }
 *
 * const person = new Person()
 *
 * const mutablePerson: Mutable<Person> = asMutable(person)
 *
 * mutablePerson.name = 'John'
 *
 */
export type Mutable<T> = { -readonly [P in keyof T]: T[P] }

export const asMutable = <T>(value: T): Mutable<T> => value as Mutable<T>
