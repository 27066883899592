import { AxiosInstance } from 'axios'
import { ITokenContextResponse } from '../types'

export class TokenContextResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async get(): Promise<ITokenContextResponse> {
    const res = await this.agent.get<ITokenContextResponse>('identity/tokenContext')
    return res.data
  }
}
