import { PermissionSetType, RiderPermissionSet } from '../sets'
import { LaunchReportingPermissionSet } from '../sets/LaunchReportingPermissions'
import { AdminOperatorRole } from './AdminOperatorRole'

export const AccessToLaunchReportsRole: PermissionSetType[] = [
  ...AdminOperatorRole,

  // TODO: Replace rider permission set with more limited permission sets
  RiderPermissionSet.Rider,

  LaunchReportingPermissionSet.View,
  LaunchReportingPermissionSet.Write,
  LaunchReportingPermissionSet.Delete,
  LaunchReportingPermissionSet.Export,
]
