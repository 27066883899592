import { WebColors } from '@sparelabs/colors'
import { IFileResponse, IFileTempUrlResponse, IPublicFileResponse } from '@sparelabs/http-client-utils'
import React, { useState } from 'react'
import { useAsync } from 'react-use'
import styled from 'styled-components'
import { LoaderSpinner } from '../../animations'
import { st } from '../../locales/TranslationHelper'
import { borderRadius } from '../../util'
import { EmptyValue } from '../ViewField'

const Container = styled.div`
  background-color: ${WebColors.backgroundPrimary};
  border-radius: ${borderRadius};
  border: solid 1px ${WebColors.borderPrimary};
  display: flex;
  justify-content: center;
`

interface IProps {
  value: IFileResponse | IPublicFileResponse | undefined
  tempUrl(id: string): Promise<IFileTempUrlResponse>
  onError: (error: Error) => void
}
export const DrawingView = ({ value, tempUrl, onError }: IProps) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null)
  const [isEmpty, setIsEmpty] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)

  useAsync(async () => {
    setIsEmpty(false)
    if (isIPublicFileResponse(value)) {
      setFileUrl(value.url)
    } else if (isIFileResponse(value)) {
      try {
        const response = await tempUrl(value.id)
        setFileUrl(response.tempUrl)
      } catch (error: any) {
        setHasError(true)
        onError(error)
      }
    } else {
      setIsEmpty(true)
    }
  }, [value])

  if (isEmpty) {
    return <EmptyValue>{st.common.none()}</EmptyValue>
  } else if (hasError) {
    return <EmptyValue>{st.errors.loadingData()}</EmptyValue>
  }
  return <Container>{fileUrl ? <img src={fileUrl} /> : <LoaderSpinner />}</Container>
}

const isIPublicFileResponse = (value: IFileResponse | IPublicFileResponse | undefined): value is IPublicFileResponse =>
  value ? 'url' in value : false
const isIFileResponse = (value: IFileResponse | IPublicFileResponse | undefined): value is IFileResponse =>
  value ? 'id' in value : false
