import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil, RegionCode } from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

// Values from 'possible errors' in https://gitlab.com/catamphetamine/libphonenumber-js/-/tree/master?ref_type=heads#api
export enum PhoneParserFailureReason {
  notANumber = 'NOT_A_NUMBER',
  invalidCountry = 'INVALID_COUNTRY',
  tooShort = 'TOO_SHORT',
  tooLong = 'TOO_LONG',
  unknown = 'unknown', // Not from 'possible errors' docs fallback case
}

enum ExceptionStrings {
  INVALID_COUNTRY_CODE = 'Invalid country calling code',
  NOT_A_NUMBER = 'The string supplied did not seem to be a phone number',
  TOO_SHORT_AFTER_IDD = 'Phone number too short after IDD',
  TOO_SHORT_NSN = 'The string supplied is too short to be a phone number',
  TOO_LONG = 'The string supplied is too long to be a phone number',
}

export class PhoneParser {
  public static validate(phoneNumber: string): boolean {
    try {
      const parsedPhoneNumber = phoneUtil.parse(phoneNumber)
      return PhoneParser.isValid(parsedPhoneNumber)
    } catch (_error) {
      return false
    }
  }

  public static validateWithFailureReason(phoneNumber: string): PhoneParserFailureReason | true {
    // Run the standard validation, to help keep behavior consistent.
    const isValid = this.validate(phoneNumber)
    if (!isValid) {
      // This will validate again with more information on what went wrong.
      return this.validOrFailureReason(phoneNumber)
    }
    return true
  }

  public static getCountryInfo(phoneNumber: string): { country: RegionCode; countryCallingCode: string } | null {
    const parsedPhoneNumber = phoneUtil.parse(phoneNumber)

    const countryCode = parsedPhoneNumber.getCountryCode()
    if (!countryCode) {
      return null
    }

    const regionCode = phoneUtil.getRegionCodeForCountryCode(countryCode)
    if (!regionCode || regionCode === 'ZZ') {
      return null
    }

    return {
      country: regionCode,
      countryCallingCode: countryCode.toString(),
    }
  }

  public static parsePhoneNumber = (phoneNumber: string): string | null => {
    try {
      if (!phoneNumber.startsWith('+')) {
        phoneNumber = '+' + phoneNumber
      }
      const parsedPhoneNumber = phoneUtil.parse(phoneNumber)
      if (!PhoneParser.isValid(parsedPhoneNumber)) {
        return null
      }

      return phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164)
    } catch (_error) {
      return null
    }
  }

  private static isValid(parsedPhoneNumber: PhoneNumber): boolean {
    return Boolean(
      parsedPhoneNumber.getNationalNumber() &&
        parsedPhoneNumber.getCountryCode() &&
        phoneUtil.isValidNumber(parsedPhoneNumber)
    )
  }

  private static validOrFailureReason(phoneNumber: string): PhoneParserFailureReason | true {
    try {
      if (!phoneNumber.startsWith('+')) {
        phoneNumber = '+' + phoneNumber
      }
      const parsedPhoneNumber = phoneUtil.parse(phoneNumber)
      const error = phoneUtil.isPossibleNumberWithReason(parsedPhoneNumber)

      return PhoneParser.validationReasonToFailureReason(error) ?? true
    } catch (error) {
      return PhoneParser.exceptionToFailureReason(error)
    }
  }

  private static validationReasonToFailureReason(
    validationReason: PhoneNumberUtil.ValidationResult
  ): PhoneParserFailureReason | null {
    switch (validationReason) {
      case PhoneNumberUtil.ValidationResult.INVALID_COUNTRY_CODE:
        return PhoneParserFailureReason.invalidCountry
      case PhoneNumberUtil.ValidationResult.TOO_SHORT:
        return PhoneParserFailureReason.tooShort
      case PhoneNumberUtil.ValidationResult.TOO_LONG:
        return PhoneParserFailureReason.tooLong
      case PhoneNumberUtil.ValidationResult.IS_POSSIBLE:
        return null
      default:
        return PhoneParserFailureReason.unknown
    }
  }

  private static exceptionToFailureReason(ex: unknown): PhoneParserFailureReason {
    if (ex instanceof Error) {
      switch (ex.message) {
        case ExceptionStrings.INVALID_COUNTRY_CODE:
          return PhoneParserFailureReason.invalidCountry
        case ExceptionStrings.TOO_SHORT_AFTER_IDD:
        case ExceptionStrings.TOO_SHORT_NSN:
          return PhoneParserFailureReason.tooShort
        case ExceptionStrings.TOO_LONG:
          return PhoneParserFailureReason.tooLong
        case ExceptionStrings.NOT_A_NUMBER:
          return PhoneParserFailureReason.notANumber
      }
    }

    return PhoneParserFailureReason.unknown
  }
}
