import { WebColors } from '@sparelabs/colors'
import React from 'react'
import styled from 'styled-components'
import { InputFocusTheme } from '../../util'
import { IInputProps } from './InputTypes'

const StyledRadio = styled.input<{ invalid?: boolean }>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  height: 12px;
  width: 12px;
  background-color: ${WebColors.backgroundPrimary};
  border: 1px solid ${({ invalid }) => (invalid ? WebColors.negativePrimary : WebColors.borderPrimary)};
  border-radius: 50%;
  display: inline-block;
  position: relative;
  :focus {
    ${InputFocusTheme}
    outline-color: ${(props) => (props.invalid ? WebColors.negativePrimary : WebColors.interactivePrimary)} !important;
    outline-offset: 1px;
  }
  :checked {
    background-color: ${(props) => (props.invalid ? WebColors.negativePrimary : WebColors.interactivePrimary)};
    border-color: ${(props) => (props.invalid ? WebColors.negativePrimary : WebColors.interactivePrimary)};
  }
  :checked:after {
    content: ' ';
    border-radius: 50%;
    position: absolute;
    width: 4px;
    height: 4px;
    top: 3px;
    left: 3px;
    background: ${WebColors.contentInvertedPrimary};
  }
`

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const RadioTitle = styled.span`
  color: ${WebColors.contentPrimary};
  padding-left: 10px;
`

export const RadioLabel = styled.label`
  cursor: pointer;
  color: ${WebColors.contentPrimary};
  &:hover {
    color: ${WebColors.interactivePrimary} !important;
  }
  &:hover ${RadioTitle} {
    color: ${WebColors.interactivePrimary} !important;
  }
  margin-bottom: 0px;
`

interface IRadioInputProps extends IInputProps<boolean> {
  checked: boolean
  onChange: () => void
  id?: string
}
// TODO: requires rewriting as one component with many radio buttons inside radiogroup
export const RadioInput = ({ checked, onChange, invalid, id, value, ...rest }: IRadioInputProps): JSX.Element => (
  <StyledRadio
    type='radio'
    checked={checked}
    onChange={onChange}
    invalid={invalid}
    id={id}
    value={value === undefined || value === null ? '' : value.toString()}
    {...rest}
  />
)
