import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { Resources } from '../types'

export class LyftInstallationResource extends GenericResource<never, never, never, never> {
  constructor(agent: AxiosInstance) {
    super(Resources.LyftInstallation, agent)
  }

  public async post(): Promise<void> {
    await this.agent.post(this.resource)
  }
}
