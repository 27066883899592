import React, { useMemo } from 'react'
import { DifferenceCalculator, LineDifferenceType } from './DifferenceCalculator'
import { DifferenceType, isLineDifferent } from './DifferenceHelpers'
import {
  DifferenceContainer,
  DifferenceHalf,
  DifferenceHeader,
  DifferenceItem,
  DifferenceLine,
} from './DifferenceStyles'
import { IDifferenceValue } from './DifferenceValue'

export interface IDifferenceProps extends IDifferenceValue {}

export const Difference = ({ previous, current }: IDifferenceProps): JSX.Element => {
  const lines = useMemo(() => DifferenceCalculator.calculate(previous, current), [previous, current])

  return (
    <DifferenceContainer>
      <DifferenceHeader />
      {lines.map((line, lineIndex) => (
        <DifferenceLine key={lineIndex}>
          <DifferenceHalf
            different={isLineDifferent(line, DifferenceType.Previous)}
            type={DifferenceType.Previous}
            isTop={
              isLineDifferent(line, DifferenceType.Previous) &&
              !isLineDifferent(lines[lineIndex - 1], DifferenceType.Previous)
            }
            isBottom={
              isLineDifferent(line, DifferenceType.Previous) &&
              !isLineDifferent(lines[lineIndex + 1], DifferenceType.Previous)
            }
          >
            {line.previous.map((item, itemIndex) => (
              <DifferenceItem
                key={`${lineIndex}-${itemIndex}-${DifferenceType.Previous}`}
                different={item.different && line.differenceType === LineDifferenceType.Modified}
                type={DifferenceType.Previous}
              >
                {item.value}
              </DifferenceItem>
            ))}
          </DifferenceHalf>
          <DifferenceHalf
            different={[LineDifferenceType.Modified, LineDifferenceType.Added].includes(line.differenceType)}
            type={DifferenceType.Current}
            isTop={
              isLineDifferent(line, DifferenceType.Current) &&
              !isLineDifferent(lines[lineIndex - 1], DifferenceType.Current)
            }
            isBottom={
              isLineDifferent(line, DifferenceType.Current) &&
              !isLineDifferent(lines[lineIndex + 1], DifferenceType.Current)
            }
          >
            {line.current.map((item, itemIndex) => (
              <DifferenceItem
                key={`${lineIndex}-${itemIndex}-${DifferenceType.Current}`}
                different={item.different && line.differenceType === LineDifferenceType.Modified}
                type={DifferenceType.Current}
              >
                {item.value}
              </DifferenceItem>
            ))}
          </DifferenceHalf>
        </DifferenceLine>
      ))}
    </DifferenceContainer>
  )
}
