import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IRiderVehicleTypeRestrictionListQueryParams,
  IRiderVehicleTypeRestrictionPatchBody,
  IRiderVehicleTypeRestrictionPostBody,
  IRiderVehicleTypeRestrictionResponse,
  Resources,
} from '../types'

export class RiderVehicleTypeRestrictionResource extends GenericResource<
  IRiderVehicleTypeRestrictionResponse,
  IRiderVehicleTypeRestrictionListQueryParams,
  IRiderVehicleTypeRestrictionPatchBody,
  IRiderVehicleTypeRestrictionPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  public delete = this.buildDel()
  constructor(agent: AxiosInstance) {
    super(Resources.RiderVehicleTypeRestrictions, agent)
  }
}
