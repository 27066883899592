import { WebColors } from '@sparelabs/colors'
import React from 'react'
import styled from 'styled-components'
import { boxShadow } from '../../../util'
import { IInputProps } from '../InputTypes'
import { DEFAULT_CONTAINER_SIZE_PX } from './constants'

export interface ISingleChoice {
  value: string
}

interface ISingleChoiceContainerProps {
  size: number
  disabled?: boolean
}

const SingleChoiceContainer = styled.div<ISingleChoiceContainerProps>`
  display: inline-flex;
  flex-direction: column;
  flex-grow: 0;
  flex-wrap: 1;
  align-items: center;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  color: ${({ disabled }) => (disabled ? WebColors.contentTertiary : WebColors.contentPrimary)};
  cursor: ${({ onClick, disabled }) => (disabled ? 'not-allowed' : onClick ? 'pointer' : 'default')};
  justify-content: center;
`

const SelectedSingleChoiceContainer = styled(SingleChoiceContainer)`
  background-color: white;
  border-radius: 5px;
  box-shadow: ${boxShadow};
`

export interface ISingleChoiceButton<T> extends Omit<IInputProps<T>, 'onChange'> {
  option: T
  isSelected: boolean
  onChange: (value: T) => void
  renderOption?: (value: T) => JSX.Element
  disabled?: boolean
  containerSize?: number
}

export const SingleChoiceButton = <T extends ISingleChoice>({
  containerSize,
  option,
  disabled,
  renderOption,
  isSelected,
  onChange,
  ...inputAttributes
}: ISingleChoiceButton<T>): JSX.Element => {
  const commonProps = {
    ...inputAttributes,
    size: containerSize ?? DEFAULT_CONTAINER_SIZE_PX,
    role: 'radio',
    'aria-label': option.value,
    'aria-disabled': Boolean(disabled),
    disabled: Boolean(disabled),
  }

  const label = renderOption ? renderOption(option) : option.value

  if (isSelected) {
    return (
      <SelectedSingleChoiceContainer {...commonProps} aria-checked={true}>
        {label}
      </SelectedSingleChoiceContainer>
    )
  }
  return (
    <SingleChoiceContainer {...commonProps} onClick={() => !disabled && onChange && onChange(option)}>
      {label}
    </SingleChoiceContainer>
  )
}
