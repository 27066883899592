import { FormType } from '@sparelabs/engage-client'

export enum FormStep {
  Loading,
  RiderAuth,
  RiderProfile,
  SpecialistWelcome,
  FormInput,
  Finished,
}

export const FormStepFlows: Record<FormType, FormStep[]> = {
  [FormType.Rider]: [
    // Rider forms require authentication and profile information
    FormStep.RiderAuth,
    FormStep.RiderProfile,
    FormStep.FormInput,
    FormStep.Finished,
  ],
  [FormType.Specialist]: [
    // Specialist forms skip authentication, but have a special welcome page with term acceptance
    FormStep.SpecialistWelcome,
    FormStep.FormInput,
    FormStep.Finished,
  ],
}

export enum FormSubmitted {
  Never,
  Previously,
  ThisSession,
}
