import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'

export enum OverdueStatus {
  None = 'none',
  Warning = 'warning',
  Critical = 'critical',
}

export interface IBillingAccountListQueryParams extends IListModelQueryParamValues {}

export enum BillingMethod {
  AnnualProrated = 'annualProrated',
  AnnualBaseMonthlyOverage = 'annualBaseMonthlyOverage',
  MonthlyMetered = 'monthlyMetered',
}

export interface IBillingAccountPostBody {
  name: string
  chargebeeSubscriptionId: string
  billingMethod: BillingMethod
  customerRepSuperAdminId: string | null
  growthRepSuperAdminId: string | null
  committedActiveVehicleCount: number | null
  committedRevenueAmount: number | null
}

export interface IBillingAccountPatchBody {
  name?: string
  chargebeeSubscriptionId?: string
  billingMethod?: BillingMethod
  customerRepSuperAdminId?: string | null
  growthRepSuperAdminId?: string | null
  committedActiveVehicleCount?: number | null
  committedRevenueAmount?: number | null
}

export interface IBillingAccountResponse extends IModelJsonValues, IBillingAccountPostBody {
  chargebeeSubscriptionUrl: string
  foundInChargebee: boolean
  monthlyUsageCycles: {
    last: {
      startDate: string
      endDate: string
    }
    current: {
      startDate: string
      endDate: string
    }
  }
  overdueStatus: OverdueStatus
}

export interface IBillingAccountAlertStatusResponse {
  overdueStatus: OverdueStatus
}
