import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IDutyConversationListParams, IDutyConversationResponse, Resources } from '../types'

export class DutyConversationResource extends GenericResource<
  IDutyConversationResponse,
  IDutyConversationListParams,
  never,
  never
> {
  public get = this.buildGet()
  public list = this.buildList()
  constructor(agent: AxiosInstance) {
    super(Resources.DutyConversations, agent)
  }
}
