import { WebColors } from '@sparelabs/colors'
import React, { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'
import { LABEL_COLUMN_WIDTH_PX, LABEL_MARGIN_RIGHT_PX } from '../util'
import { ValidationError } from './ViewField'

const StyledFieldset = styled.fieldset`
  display: flex;
  font-size: 15px;
  height: fit-content;
`

const StyledLegend = styled.legend`
  padding-top: 8px;
  margin-bottom: 0px;
  line-height: 20px;
  font-size: inherit;
  color: ${WebColors.contentSecondary};
  width: auto;
`
const LabelWrapper = styled.div`
  display: flex;
  margin-right: 12px;
  align-items: center;
  height: fit-content;
  min-width: ${LABEL_COLUMN_WIDTH_PX}px;
  width: ${LABEL_COLUMN_WIDTH_PX}px;
`

const InputsContainer = styled.div`
  flex-grow: 1;
  overflow-wrap: break-word;
  min-width: 270px;
`
const HorizontalBar = styled.div`
  margin-top: 8px;
  background-color: ${WebColors.borderPrimary};
  height: 1px;
  flex: 1;
  margin-left: ${LABEL_MARGIN_RIGHT_PX}px;
`

interface IProps {
  legend: string
  children: ReactNode
  error?: string
  containerStyles?: CSSProperties
}
export const FormFieldsetHorizontalBar = ({ legend, children, error, containerStyles }: IProps): JSX.Element => (
  <StyledFieldset style={containerStyles}>
    <LabelWrapper>
      <StyledLegend>{legend}</StyledLegend>
      <HorizontalBar />
    </LabelWrapper>
    <InputsContainer>
      {children}
      {error && <ValidationError role='alert'>{error}</ValidationError>}
    </InputsContainer>
  </StyledFieldset>
)
