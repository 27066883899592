import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IBulkNotificationListQueryParams,
  IBulkNotificationPatchBody,
  IBulkNotificationPostBody,
  IBulkNotificationResponse,
  ISendBulkNotificationPostBody,
  Resources,
} from '../types'

export class BulkNotificationResource extends GenericResource<
  IBulkNotificationResponse,
  IBulkNotificationListQueryParams,
  IBulkNotificationPatchBody,
  IBulkNotificationPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.BulkNotifications, agent)
  }

  public send(body: ISendBulkNotificationPostBody): Promise<void> {
    return this.agent.post(`${this.resource}/send`, body)
  }
}
