import { Currency } from './types/CurrencyTypes'

export class StripeCurrencyHelper {
  public static isSupported(currency: Currency): boolean {
    return STRIPE_SUPPORTED_CURRENCIES.has(currency)
  }

  public static minimumCharge(currency: Currency): number {
    return STRIPE_MINIMUM_CHARGE_MAP.get(currency) || 0
  }

  public static isZeroDecimal(currency: Currency): boolean {
    return STRIPE_ZERO_DECIMAL_CURRENCIES.has(currency)
  }
}

/**
 * Not all world currencies are supported by stripe
 * See: https://stripe.com/docs/currencies#presentment-currencies
 */
const STRIPE_SUPPORTED_CURRENCIES = new Set<Currency>([
  Currency.AED,
  Currency.AFN,
  Currency.ALL,
  Currency.AMD,
  Currency.ANG,
  Currency.AOA,
  Currency.ARS,
  Currency.AUD,
  Currency.AWG,
  Currency.AZN,
  Currency.BAM,
  Currency.BBD,
  Currency.BDT,
  Currency.BGN,
  Currency.BIF,
  Currency.BMD,
  Currency.BND,
  Currency.BOB,
  Currency.BRL,
  Currency.BSD,
  Currency.BWP,
  Currency.BYN,
  Currency.BZD,
  Currency.CAD,
  Currency.CDF,
  Currency.CHF,
  Currency.CLP,
  Currency.CNY,
  Currency.COP,
  Currency.CRC,
  Currency.CVE,
  Currency.CZK,
  Currency.DJF,
  Currency.DKK,
  Currency.DOP,
  Currency.DZD,
  Currency.EGP,
  Currency.ETB,
  Currency.EUR,
  Currency.FJD,
  Currency.FKP,
  Currency.GBP,
  Currency.GEL,
  Currency.GIP,
  Currency.GMD,
  Currency.GNF,
  Currency.GTQ,
  Currency.GYD,
  Currency.HKD,
  Currency.HNL,
  Currency.HRK,
  Currency.HTG,
  Currency.HUF,
  Currency.IDR,
  Currency.ILS,
  Currency.INR,
  Currency.ISK,
  Currency.JMD,
  Currency.JPY,
  Currency.KES,
  Currency.KGS,
  Currency.KHR,
  Currency.KMF,
  Currency.KRW,
  Currency.KYD,
  Currency.KZT,
  Currency.LAK,
  Currency.LBP,
  Currency.LKR,
  Currency.LRD,
  Currency.LSL,
  Currency.MAD,
  Currency.MDL,
  Currency.MGA,
  Currency.MKD,
  Currency.MMK,
  Currency.MNT,
  Currency.MOP,
  Currency.MRO,
  Currency.MUR,
  Currency.MVR,
  Currency.MWK,
  Currency.MXN,
  Currency.MYR,
  Currency.MZN,
  Currency.NAD,
  Currency.NGN,
  Currency.NIO,
  Currency.NOK,
  Currency.NPR,
  Currency.NZD,
  Currency.PAB,
  Currency.PEN,
  Currency.PGK,
  Currency.PHP,
  Currency.PKR,
  Currency.PLN,
  Currency.PYG,
  Currency.QAR,
  Currency.RON,
  Currency.RSD,
  Currency.RUB,
  Currency.RWF,
  Currency.SAR,
  Currency.SBD,
  Currency.SCR,
  Currency.SEK,
  Currency.SGD,
  Currency.SHP,
  Currency.SLL,
  Currency.SOS,
  Currency.SRD,
  Currency.STD,
  Currency.SZL,
  Currency.THB,
  Currency.TJS,
  Currency.TOP,
  Currency.TRY,
  Currency.TTD,
  Currency.TWD,
  Currency.TZS,
  Currency.UAH,
  Currency.UGX,
  Currency.USD,
  Currency.UYU,
  Currency.UZS,
  Currency.VND,
  Currency.VUV,
  Currency.WST,
  Currency.XAF,
  Currency.XCD,
  Currency.XOF,
  Currency.XPF,
  Currency.YER,
  Currency.ZAR,
  Currency.ZMW,
])

/**
 * Minimum amount set based on Stripe's documentation
 * See: https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts
 */
const STRIPE_MINIMUM_CHARGE_MAP = new Map<Currency, number>([
  [Currency.AED, 200],
  [Currency.AUD, 50],
  [Currency.BGN, 100],
  [Currency.BRL, 50],
  [Currency.CAD, 50],
  [Currency.CHF, 50],
  [Currency.CZK, 1500],
  [Currency.DKK, 250],
  [Currency.EUR, 50],
  [Currency.GBP, 30],
  [Currency.HKD, 400],
  [Currency.HUF, 17500],
  [Currency.INR, 50],
  [Currency.JPY, 50],
  [Currency.MXN, 1000],
  [Currency.MYR, 200],
  [Currency.NOK, 300],
  [Currency.NZD, 50],
  [Currency.PLN, 200],
  [Currency.RON, 200],
  [Currency.SEK, 300],
  [Currency.SGD, 50],
  [Currency.USD, 50],
])

/**
 * For zero-decimal currencies, still provide amounts as an integer but without multiplying by 100. For example, to charge ¥500, provide an amount value of 500.
 * See: https://stripe.com/docs/currencies#zero-decimal
 */
const STRIPE_ZERO_DECIMAL_CURRENCIES = new Set<Currency>([
  Currency.BIF,
  Currency.CLP,
  Currency.DJF,
  Currency.GNF,
  Currency.JPY,
  Currency.KMF,
  Currency.KRW,
  Currency.MGA,
  Currency.PYG,
  Currency.RWF,
  Currency.UGX,
  Currency.VND,
  Currency.VUV,
  Currency.XAF,
  Currency.XOF,
  Currency.XPF,
])
