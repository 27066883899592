import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WebColors } from '@sparelabs/colors'
import React from 'react'
import { ITooltipProps, Tooltip } from '../tooltip'

interface IProps extends ITooltipProps {
  className?: string
  color?: string
  iconSize?: SizeProp
  icon?: IconProp
}

export const InfoTooltip = ({
  icon = 'info-circle',
  color,
  className,
  placement = 'top',
  theme = 'light',
  iconSize,
  ...rest
}: IProps): JSX.Element => (
  <Tooltip className={className} placement={placement} animation='shift-away' arrow={true} theme={theme} {...rest}>
    <span>
      <FontAwesomeIcon
        icon={icon}
        size={iconSize ?? 'xs'}
        color={color ?? WebColors.contentSecondary}
        className='info-tooltip-icon'
      />
    </span>
  </Tooltip>
)
