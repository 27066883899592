import { OAuthScope } from '@sparelabs/permissions'
import { Scope } from './AuthTypes'

export enum TokenType {
  UserOrg = 'user',
  UserOnly = 'userOnly',
  ApiKey = 'apiKey',
  ResetToken = 'resetToken',
  SuperAdmin = 'superAdmin',
  App = 'app',
}

export interface IJwtTokenCommon {
  id: string
  type: TokenType
  secret: string
  expires: number | null
}

export interface IResetToken extends IJwtTokenCommon {
  type: TokenType.ResetToken
}

export interface IUserOrgToken extends IJwtTokenCommon {
  type: TokenType.UserOrg
  organizationId: string
  scope: Scope
}

export interface IUserOnlyToken extends IJwtTokenCommon {
  type: TokenType.UserOnly
  scope: Scope
}

export interface ISuperAdminToken extends IJwtTokenCommon {
  type: TokenType.SuperAdmin
}

export interface IApiKeyToken extends IJwtTokenCommon {
  type: TokenType.ApiKey
}

export interface IAppToken {
  type: TokenType.App
  expires: number | null
  appId: string
  organizationId: string
  appInstallationId: string
  oAuthScopes: OAuthScope[]
}

export type IJwtToken = IUserOrgToken | IResetToken | IApiKeyToken | IUserOnlyToken | ISuperAdminToken | IAppToken
