import { AxiosInstance } from 'axios'
import { IListModelQueryParamValues, IListResponse, OrderDirection } from '../types'

export class AllPageFetcher {
  constructor(private readonly agent: AxiosInstance) {}

  public async fetch<T, P extends IListModelQueryParamValues>(path: string, params?: P): Promise<T[]> {
    const data: T[] = []

    while (true) {
      const res = await this.agent.get<IListResponse<T>>(path, {
        params: {
          orderBy: 'createdAt',
          orderDirection: OrderDirection.Ascending,
          ...params,
          skip: data.length,
        },
      })
      data.push(...res.data.data)

      if (data.length >= res.data.total) {
        return data
      }
    }
  }
}
