import { colors } from './SharedColors'

// Colors for dark mode in Spare Driver and Rider
// HEX codes are all defined in SharedColors.ts, and must be referenced from there
// Additionally, all keys added here should also be added to LightModeColors.ts
export const darkColors = {
  // BACKGROUNDS
  backgroundPrimary: colors.gray90,
  backgroundSecondary: colors.black,
  backgroundSecondaryAlt: colors.gray70,
  backgroundTertiary: colors.gray70,
  backgroundDisabled: colors.gray50,

  // CARDS
  cardBackgroundPrimary: colors.gray90,
  cardBackgroundInverted: colors.white,

  // PROGRESS BAR
  progressFillPrimary: colors.gray90,
  progressBackgroundPrimary: colors.gray50,

  // TEXT
  textPrimary: colors.white,
  textSecondary: colors.gray40,
  textDisabled: colors.gray60,
  textInverted: colors.gray90,

  // INTERACTIVE ELEMENTS (BLUES)
  interactivePrimary: colors.blue40,
  interactiveSecondary: colors.gray50,
  interactiveLowContrast: colors.blue70,
  interactiveHighContrast: colors.blue10,
  interactiveDisabled: colors.gray50,
  interactiveAlternative: colors.coolGray40,

  // POSITIVE (GREENS)
  positivePrimary: colors.green40,
  positiveLowContrast: colors.green70,
  positiveHighContrast: colors.green10,
  positiveDisabled: colors.gray50,

  // NEGATIVE (REDS)
  negativePrimary: colors.red40,
  negativeLowContrast: colors.red70,
  negativeHighContrast: colors.red10,
  negativeDisabled: colors.gray50,

  // WARNING (ORANGES)
  warningPrimary: colors.yellow30,
  warningLowContrast: colors.yellow70,
  warningHighContrast: colors.yellow10,
  warningDisabled: colors.gray50,

  // PICKUPS (GREENS)
  pickupPrimary: colors.green40,
  pickupDisabled: colors.gray50,

  // DROPOFFS (REDS)
  dropoffPrimary: colors.red40,
  dropoffDisabled: colors.gray50,

  // NOTES -- TODO consult
  notePrimary: colors.blue40,
  noteDisabled: colors.gray70,

  // ICONS
  circleIconInner: colors.gray90,
  favoriteHome: colors.blue40,
  favoriteWork: colors.purple40,
  favoriteOther: colors.green40,
  locationStopSuggestion: colors.purple40,
  locationDefaultSuggestion: colors.red30,
  mapMarker: colors.white,

  // RADIO BUTTON
  radioButtonUnfilled: colors.gray50,
  radioButtonFilled: colors.blue40,

  // SWITCH
  switchThumb: colors.white,
  switchTrackOff: colors.gray60,

  // SPINNER + LOADING INDICATOR
  spinner: colors.coolGray40,
  spinnerMessage: colors.white,
  loadingPrimary: colors.coolGray40,
  loadingInverted: colors.gray90,
  loadingMessage: colors.coolGray40,

  // PREDICTED DEMAND THEME
  routeStopSlot: colors.blue40,

  // SHADOW SETTINGS
  shadowColor: colors.black,
  shadowOpacity: colors.opacity80,
  shadowRadius: colors.radius20,

  // MISC
  shadow: colors.black,
  border: colors.black,
  borderSecondary: colors.gray60,
  borderMapbox: colors.mapboxDark,
}
