import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { ICancellationReasonPostBody, ICancellationReasonResponse, Resources } from '../types'

export class CancellationReasonResource extends GenericResource<
  ICancellationReasonResponse,
  any,
  any,
  ICancellationReasonPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()
  public delete = this.buildDel()

  constructor(agent: AxiosInstance) {
    super(Resources.CancellationReasons, agent)
  }
}
