import { VehicleCapacityType } from '@sparelabs/domain-logic'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { IVehicleAccessibilityFeature } from './AccessibilityTypes'

export enum VehicleOwner {
  Organization = 'organization',
  User = 'user',
}

export enum VehicleStatus {
  Active = 'active',
  Archived = 'archived',
}

export enum VehicleType {
  Sedan = 'sedan',
  Minivan = 'minivan',
  PassengerVan = 'passengerVan',
  Bus = 'bus',
}

export enum VehicleFuelType {
  Gas = 'gas',
  Electric = 'electric',
  Hybrid = 'hybrid',
}

export interface IVehiclePostBody {
  accessibilityFeatures: IVehicleAccessibilityFeature[]
  color: string
  identifier: string
  licensePlate: string
  make: string
  model: string
  ownerUserId: string | null
  passengerSeats: number
  photoUrl?: string | null
  emissionsRate?: number | null
  capacityType?: VehicleCapacityType
  vehicleTypeId?: string | null
}

export interface IVehiclePatchBody {
  accessibilityFeatures?: IVehicleAccessibilityFeature[]
  color?: string
  identifier?: string
  licensePlate?: string
  make?: string
  model?: string
  ownerUserId?: string | null
  passengerSeats?: number
  photoUrl?: string | null
  status?: VehicleStatus
  emissionsRate?: number | null
  capacityType?: VehicleCapacityType
  vehicleTypeId?: string | null
}

export interface IVehicleListQueryParams extends IListModelQueryParamValues {
  status?: VehicleStatus
  ownerUserId?: string
  identifier?: string
  external?: boolean
}

export interface IVehicleStub {
  id: string
  accessibilityFeatures: IVehicleAccessibilityFeature[]
  color: string
  identifier: string
  licensePlate: string
  make: string
  model: string
  passengerSeats: number
  photoUrl: string | null
  hasFullAccess: boolean
}

export interface IVehicleResponse extends IModelJsonValues, IVehiclePostBody {
  ownerType: VehicleOwner
  status: VehicleStatus
  emissionsRate: number | null
}

// Backwards compatibility
export interface IVehicle extends IVehicleResponse {}
