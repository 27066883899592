import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { ClientType, ISupportedClientsGetResponse, Resources } from '../types'

export class SupportedClientsResource extends GenericResource<ISupportedClientsGetResponse, never, never, never> {
  constructor(agent: AxiosInstance) {
    super(Resources.SupportedClients, agent)
  }

  public async get(clientType: ClientType): Promise<ISupportedClientsGetResponse> {
    const res = await this.agent.get<ISupportedClientsGetResponse>(`${Resources.SupportedClients}/${clientType}`)
    return res.data
  }
}
