import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IUserBanListQueryParams, IUserBanPatchBody, IUserBanPostBody, IUserBanResponse, Resources } from '../types'

export class UserBanResource extends GenericResource<
  IUserBanResponse,
  IUserBanListQueryParams,
  IUserBanPatchBody,
  IUserBanPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.UserBans, agent)
  }
}
