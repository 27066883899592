export enum WorkflowPauseReason {
  ManuallyPausedByAdmin = 'manuallyPausedByAdmin',
  CircularCallDetected = 'circularCallDetected',
  TooManyFailures = 'tooManyFailures',
}

export interface IWorkflowIssueCircularCallDetected {
  type: WorkflowPauseReason.CircularCallDetected
}

export interface IWorkflowIssueTooManyFailures {
  type: WorkflowPauseReason.TooManyFailures
}

export type WorkflowIssue = IWorkflowIssueCircularCallDetected | IWorkflowIssueTooManyFailures
