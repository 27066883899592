import type { Stripe } from 'stripe'

/**
 * @deprecated use from @sparelabs/domain-logic instead
 */
export { StripeCardBrand } from '@sparelabs/domain-logic'

/**
 * Note that we only import & export the Stripe type here so that
 * the types can be used across any platform
 */
export type { Stripe }

export enum StripePaymentMethodType {
  Card = 'card',
  SepaDebit = 'sepaDebit',
}

/**
 * Warning! This information may contain secret keys and should not be exposed to the client
 *
 * Instead use `IStripeClientSafeInfo`
 */
export type IPaymentProviderConfigStripe = IStripePaymentProviderBackend

export interface IStripeClientSafeCommon {
  twilioPayConfig?: {
    connectorName: string
    skipPostalCode?: boolean
  }
  enabledMethods?: StripePaymentMethodType[]
  /**
   * If email receipts are enabled, we will populate the `receipt_email` field when making charges.
   * See more information here: https://stripe.com/docs/receipts
   */
  enableEmailReceipts?: boolean
  captureImmediately?: boolean
}

export enum StripeConnectionType {
  Manual = 'manual',
  Connect = 'connect',
}

/**
 * Only used for Stripe V2 (Connect) configurations
 */
export enum StripeMode {
  Test = 'test',
  Live = 'live',
}

/**
 * @deprecated Insecure way of connecting to customer Stripe accounts. Instead, use "Stripe V2"
 */
export interface IStripeClientSafeV1 extends IStripeClientSafeCommon {
  connectionType: StripeConnectionType.Manual
  /**
   * Public key for the Stripe account of one of Spare's customers.
   */
  publishableApiKey: string
}

export interface IStripeClientSafeV2 extends IStripeClientSafeCommon {
  connectionType: StripeConnectionType.Connect

  /**
   * This indicates whether the configuration has been connected in live or test mode
   */
  mode: StripeMode

  /**
   * Spare's publishable API key for Stripe
   */
  sparePublishableApiKey: string

  /**
   * Spare's client ID for Stripe
   */
  spareClientId: string

  /**
   * Customer Stripe account linked to this organization via Stripe Connect,
   * so that we can create charges on behalf of that Stripe account
   */
  customerAccountId: string
}

/**
 * @deprecated Insecure way of connecting to customer Stripe accounts. Instead, use "Stripe V2"
 */
export interface IStripeClientUnsafeInfoV1 {
  secretApiKey: string
}

/**
 * @deprecated Insecure way of connecting to customer Stripe accounts. Instead, use "Stripe V2"
 */
export interface IStripeConfigurationBackendV1 {
  clientSafe: IStripeClientSafeV1
  clientUnsafe: IStripeClientUnsafeInfoV1
}

export interface IStripeConfigurationBackendV2 {
  clientSafe: IStripeClientSafeV2
  clientUnsafe: Record<string, never>
}

export type IStripeClientSafeInfo = IStripeClientSafeV1 | IStripeClientSafeV2
export type IStripePaymentProviderBackend = IStripeConfigurationBackendV2 | IStripeConfigurationBackendV1

export const isStripeClientSafeV2 = (clientSafe: IStripeClientSafeInfo): clientSafe is IStripeClientSafeV2 =>
  (clientSafe as IStripeClientSafeV2).connectionType === StripeConnectionType.Connect &&
  (clientSafe as IStripeClientSafeV2).sparePublishableApiKey !== undefined &&
  (clientSafe as IStripeClientSafeV2).spareClientId !== undefined &&
  (clientSafe as IStripeClientSafeV2).customerAccountId !== undefined &&
  (clientSafe as IStripeClientSafeV2).mode !== undefined

export const isStripeConfigurationBackendV2 = (
  config: IStripePaymentProviderBackend
): config is IStripeConfigurationBackendV2 => isStripeClientSafeV2(config.clientSafe)

export const isStripeClientSafeV1 = (clientSafe: IStripeClientSafeInfo): clientSafe is IStripeClientSafeV1 =>
  (clientSafe as IStripeClientSafeV1).connectionType === StripeConnectionType.Manual &&
  (clientSafe as IStripeClientSafeV1).publishableApiKey !== undefined

export const isStripeConfigurationBackendV1 = (
  config: IStripePaymentProviderBackend
): config is IStripeConfigurationBackendV1 =>
  isStripeClientSafeV1(config.clientSafe) && config.clientUnsafe.secretApiKey !== undefined

/**
 * Spare's public credentials for Stripe
 */
export interface IStripeGlobalConfigResponse {
  publishableApiKey: string
  clientId: string
}

export interface IStripeGlobalConfigQueryParams {
  mode?: StripeMode // Defaults to 'test'
}

/**
 * This type is only used when completing the OAuth flow for Stripe Connect
 */
export interface IStripeConnectSetupConfig {
  clientSafe: Omit<IStripeClientSafeV2, 'customerAccountId' | 'sparePublishableApiKey' | 'spareClientId'>
}

// STRIPE SESSIONS:

export interface IPostStripeSessionBody {
  paymentMethodTypes: StripePaymentMethodType[]
  successUrl: string
  cancelUrl: string
  userId?: string
}

export interface IPostStripeSessionResponse {
  sessionId: string
}

export interface IPostStripeSetupIntentBody {
  paymentMethodTypes: StripePaymentMethodType[]
  userId?: string
}

export interface IPostStripeSetupIntentResponse {
  clientSecret: string
}

export interface IStripeActionRequired {
  clientSecret: string
}

// END STRIPE SESSION
