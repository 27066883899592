import { constant, sumBy, times } from 'lodash'
import { IRequestRiderType, RiderType } from '../types'

export class RiderSemantics {
  public static DEFAULT_RIDER_TYPE: RiderType = RiderType.Adult
  public static DEFAULT_NUM_RIDERS: number = 1
  public static DEFAULT_RIDERS: IRequestRiderType[] = [
    { type: RiderSemantics.DEFAULT_RIDER_TYPE, count: RiderSemantics.DEFAULT_NUM_RIDERS },
  ]

  public static getNumRiders(riders: IRequestRiderType[]): number {
    return sumBy(riders, (rider: IRequestRiderType) => rider.count)
  }

  public static enumerateRiders(riders: IRequestRiderType[]): RiderType[] {
    return riders.flatMap((rider) => times(rider.count, constant(rider.type)))
  }
}
