import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useAsync, useLocation } from 'react-use'
import { ApiClientBuilder, EngageClientBuilder } from 'src/api/ApiClientBuilders'
import { BackgroundContainer, ErrorView, Header, LoadingView } from 'src/components'
import { AuthenticationHelper } from 'src/helpers/AuthenticationHelper'
import { st } from 'src/locales/TranslationHelper'
import { FormContextProvider, useFormContext } from './FormContext'
import { FormParamParser } from './FormParamParser'
import { FormStep } from './FormStepTypes'
import { FinishedStep, FormInputStep, RiderAuthStep, RiderProfileStep, SpecialistWelcomeStep } from './steps'

const FormStepComponentMap: Record<FormStep, () => JSX.Element | null> = {
  [FormStep.Loading]: LoadingView,
  [FormStep.RiderAuth]: RiderAuthStep,
  [FormStep.RiderProfile]: RiderProfileStep,
  [FormStep.SpecialistWelcome]: SpecialistWelcomeStep,
  [FormStep.FormInput]: FormInputStep,
  [FormStep.Finished]: FinishedStep,
}

const FormStepRenderer = (): JSX.Element => {
  const { flow, flowIndex } = useFormContext()

  // Flow logic is handled by the FormContextProvider
  const Component = useMemo(() => FormStepComponentMap[flow[flowIndex]], [flow, flowIndex])
  return <Component />
}

export const FormPage = (): JSX.Element => {
  const { search } = useLocation()
  const { loading, error, value } = useAsync(() => fetchData(search ?? ''), [search])

  if (loading) {
    return (
      <BackgroundContainer>
        <LoadingView />
      </BackgroundContainer>
    )
  }

  if (error || !value) {
    return (
      <BackgroundContainer>
        <ErrorView message={error?.message || st.errors.noContent()} />
      </BackgroundContainer>
    )
  }

  const { organization, form, caseId } = value

  return (
    <FormContextProvider form={form} organization={organization} caseId={caseId}>
      <Helmet>
        <title>{form.name}</title>
      </Helmet>
      <Header
        title={form.name}
        subtitle={st.forms.pageLayout.headerSubtitle({ orgName: organization.name })}
        organization={organization}
      />
      <BackgroundContainer>
        <FormStepRenderer />
      </BackgroundContainer>
    </FormContextProvider>
  )
}

/**
 * Uses the search param to fetch the required information to establish the FormContext
 */
async function fetchData(search: string) {
  const { organizationId, caseTypeKey, formKey, caseId } = FormParamParser.parse(search)

  const globalClient = ApiClientBuilder.buildWithoutOrganizationContext()
  const organization = await globalClient.global.getOrganization(organizationId)
  AuthenticationHelper.setRegionalHost(organization.apiHost)
  AuthenticationHelper.setOrganizationId(organizationId)

  const engageClient = EngageClientBuilder.build()
  const form = await engageClient.public.getForm({ organizationId, caseTypeKey, formKey, caseId })

  return { form, organization, caseId }
}
