import { AxiosInstance } from 'axios'
import { IApiClientConfiguration } from '../types'
import { AxiosAgentBuilder } from './AxiosAgentBuilder'

export abstract class BaseClient {
  // We allow access to the underlying agent so that people can add more interceptors, etc.
  public agent: AxiosInstance
  constructor(configuration: Partial<IApiClientConfiguration>) {
    if (new.target === BaseClient) {
      throw new TypeError('Cannot construct Abstract instances directly')
    }
    this.agent = AxiosAgentBuilder.build(configuration)
  }
}
