import { isObject } from '@sparelabs/core'
import { isString } from 'lodash'

export interface IDifferenceValue {
  previous: string
  current: string
}

export const isDifferenceValue = (value: unknown): value is IDifferenceValue =>
  isObject(value) && Object.keys(value).every((key) => ['previous', 'current'].includes(key) && isString(value[key]))
