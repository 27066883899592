import { range } from 'lodash'
import { Geography } from './Geography'
import { ILineString, IPoint } from './GeographyTypes'
import { buildLineString } from './GeometryBuilder'

const SEGMENT_COUNT: number = 50

export class ArcBuilder {
  /**
   * Builds a curved polyline between `from`  and `to` locations.
   * Current use case is rendering non-map-based paths in client apps.
   */
  public static buildArc(from: IPoint, to: IPoint): ILineString {
    const start = from.coordinates
    const end = to.coordinates
    const distance = Geography.distance(from, to)

    if (distance < 50) {
      return buildLineString([start, end])
    }

    let midLat = (start[1] + end[1]) / 2
    let midLon = (start[0] + end[0]) / 2

    // Add skew and arcHeight to move the midPoint
    const distanceModifier = distance / 3000000
    if (distance > 2000) {
      if (Math.abs(start[0] - end[0]) < 0.0001) {
        midLon -= 0.0095 + distanceModifier
      } else {
        midLat += 0.0095 + distanceModifier
      }
    } else if (Math.abs(start[0] - end[0]) < 0.0001) {
      midLon -= 0.0005
    } else {
      midLat += 0.0005
    }

    return buildLineString([
      start,
      ...range(SEGMENT_COUNT + 1).map((index): [number, number] => {
        const x = index / SEGMENT_COUNT
        const y = 1 - x

        return [
          Math.pow(y, 2) * start[0] + (2 * y * x * midLon + Math.pow(x, 2) * end[0]),
          Math.pow(y, 2) * start[1] + (2 * y * x * midLat + Math.pow(x, 2) * end[1]),
        ]
      }),
      end,
    ])
  }
}
