import { AxiosInstance } from 'axios'
import { IDriverDispatchCommsToken } from '../types'

export class DispatcherOperationsResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async getConversationToken(): Promise<IDriverDispatchCommsToken> {
    const res = await this.agent.post<IDriverDispatchCommsToken>('dispatcherOperations/conversationToken')
    return res.data
  }

  public async createDriverConversation(body: { dutyId: string }): Promise<void> {
    return this.agent.post('dispatcherOperations/createDriverConversation', body)
  }
}
