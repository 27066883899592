import { PermissionSetType, RiderPermissionSet } from '../sets'
import { BulkMessagePermissionSet } from '../sets/BulkMessagePermissions'
import { GroupPermissionSet } from '../sets/GroupPermissions'
import { NotificationSettingPermissionSet } from '../sets/NotificationSettingPermissions'
import { ServicePermissionSet } from '../sets/ServicePermissions'
import { AdminOperatorRole } from './AdminOperatorRole'

export const ServicePlannerRole: PermissionSetType[] = [
  ...AdminOperatorRole,

  // TODO: Replace rider permission set with more limited permission sets
  RiderPermissionSet.Rider,

  ServicePermissionSet.View,
  ServicePermissionSet.Manage,
  ServicePermissionSet.Share,

  GroupPermissionSet.View,
  GroupPermissionSet.Write,
  GroupPermissionSet.WriteCondition,

  NotificationSettingPermissionSet.View,
  NotificationSettingPermissionSet.Write,

  BulkMessagePermissionSet.View,
  BulkMessagePermissionSet.Write,
]
