export interface ITwilioParams {
  areaCode: number
  name: string
  countryCode: 'US' | 'CA'
  alphaNumericSenderId?: string
  forwardingNumber?: string
}

export interface ITwilioConfiguration {
  twilioAccountSid: string
  twilioAuthToken: string
  twilioFrom: string
  twilioFromFallback?: string[]
  twilioApiKey?: string
  twilioApiSecret?: string
  twilioApplicationId?: string
  forwardingNumber?: string
  twilioConversationServiceSid?: string
  twilioCallFrom?: string
}

export interface IMessageBirdConfiguration {
  messageBirdFrom: string
  messageBirdApiKey: string
}

export enum NotificationProviderType {
  Twilio = 'twilio',
  MessageBird = 'messageBird',
}
interface INotificationProviderTemplate {
  type: NotificationProviderType
}
export interface INotificationProviderTwilio extends ITwilioConfiguration, INotificationProviderTemplate {
  type: NotificationProviderType.Twilio
}
export interface INotificationProviderMessageBird extends IMessageBirdConfiguration, INotificationProviderTemplate {
  type: NotificationProviderType.MessageBird
}

export type INotificationProvider = INotificationProviderTwilio | INotificationProviderMessageBird

export type IClientSafeTwilioConfiguration = Omit<
  INotificationProviderTwilio,
  'twilioAuthToken' | 'twilioApiSecret' | 'twilioApiKey'
>
export type IClientSafeMessageBirdConfiguration = Omit<INotificationProviderMessageBird, 'messageBirdApiKey'>

export type INotificationProviderGetResponse = Array<
  IClientSafeTwilioConfiguration | IClientSafeMessageBirdConfiguration
>

export type ITwilioPatchBody = ITwilioParams
export type ITwilioPatchResponse = IClientSafeTwilioConfiguration
