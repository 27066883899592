import { isNumber } from '@sparelabs/core'
import { useState } from 'react'
import { useInterval } from 'react-use'

interface IStopwatch {
  /** Time passed in milliseconds */
  time: number
  start: () => void
  /**  Stops timer and resets time to 0ms */
  stop: () => void
}
const DELAY_MS = 100

export const useStopwatch = (): IStopwatch => {
  const [startTs, setStartTs] = useState<number | null>(null)
  const [time, setTime] = useState(0)

  const isRunning = isNumber(startTs)

  const start = () => {
    setStartTs(Date.now())
    setTime(0)
  }

  const stop = () => {
    setStartTs(null)
    setTime(0)
  }

  useInterval(() => isRunning && setTime(Date.now() - startTs), isRunning ? DELAY_MS : null)

  return { time, start, stop }
}
