import {
  DutyPermissionSet,
  NylasAdminGrantPermissionSet,
  PermissionSetType,
  RequestPermissionSet,
  RiderOnboardingFlowPermissionSet,
  VehiclePermissionSet,
} from '../sets'
import { AdminUserPermissionSet } from '../sets/AdminUserPermissions'
import { AiAgentPermissionSet } from '../sets/AiAgentPermissions'
import { AnalyticsPermissionSet } from '../sets/AnalyticsPermissions'
import { AnnouncementPermissionSet } from '../sets/AnnouncementPermissions'
import { ApiKeyPermissionSet } from '../sets/ApiKeyPermissions'
import { AppPermissionSet } from '../sets/AppPermissions'
import { AuthorizationGroupPermissionSet } from '../sets/AuthorizationGroupPermissions'
import { CancellationReasonPermissionSet } from '../sets/CancellationReasonPermissions'
import { DataRetentionSchedulePermissionSet } from '../sets/DataRetentionSchedulePermissions'
import { IvrPermissionSet } from '../sets/IvrPermissions'
import { LaunchReportingPermissionSet } from '../sets/LaunchReportingPermissions'
import { MatchingOutcomePermissionSet } from '../sets/MatchingOutcomePermissions'
import { OrganizationPermissionSet } from '../sets/OrganizationPermissions'
import { PaymentMethodTypePermissionSet } from '../sets/PaymentMethodTypePermissions'
import { RealizePermissionSet } from '../sets/RealizePermissions'
import { SimulationPermissionSet } from '../sets/SimulationPermissions'
import { UserFleetAgreementPermissionSet } from '../sets/UserFleetAgreementPermissions'
import { WebhookPermissionSet } from '../sets/WebhookPermissions'
import { WorkflowPermissionSet } from '../sets/WorkflowPermissions'
import { AccessToEngageRole } from './AccessToEngageRole'
import { FinanceManagerRole } from './FinanceManagerRole'
import { OperationsManagerRole } from './OperationsManagerRole'
import { ServicePlannerRole } from './ServicePlannerRole'

export const AdministratorRole: PermissionSetType[] = [
  // Permissions from sub roles
  ...OperationsManagerRole,
  ...ServicePlannerRole,
  ...FinanceManagerRole,
  ...AccessToEngageRole,

  AnalyticsPermissionSet.Access,

  LaunchReportingPermissionSet.View,
  LaunchReportingPermissionSet.Write,
  LaunchReportingPermissionSet.Delete,
  LaunchReportingPermissionSet.Export,

  RealizePermissionSet.Access,

  DataRetentionSchedulePermissionSet.View,
  DataRetentionSchedulePermissionSet.Write,

  OrganizationPermissionSet.WriteCustomSchemas,
  OrganizationPermissionSet.EditOwn,

  AdminUserPermissionSet.View,
  AdminUserPermissionSet.Edit,
  AdminUserPermissionSet.New,
  AdminUserPermissionSet.Delete,
  AdminUserPermissionSet.Import,
  NylasAdminGrantPermissionSet.Write,

  SimulationPermissionSet.View,

  ApiKeyPermissionSet.View,
  ApiKeyPermissionSet.Write,
  ApiKeyPermissionSet.Delete,

  WebhookPermissionSet.View,
  WebhookPermissionSet.Write,
  AnnouncementPermissionSet.Write,

  PaymentMethodTypePermissionSet.View,
  PaymentMethodTypePermissionSet.Write,

  RiderOnboardingFlowPermissionSet.Write,

  // Permissions for API Keys.
  // TODO currently api keys and administrators have the same permissions.
  // When we work on scoping down what API Keys can do it would be good to remove this coupling
  // ----------------------------------
  ApiKeyPermissionSet.KeyUser,
  // ----------------------------------

  AppPermissionSet.View,
  AppPermissionSet.AppInstallationView,
  AppPermissionSet.AppInstallationManage,

  UserFleetAgreementPermissionSet.DeleteAll,

  RequestPermissionSet.ViewDailyCounts,

  MatchingOutcomePermissionSet.View,

  // Slot completion from admin panel
  DutyPermissionSet.CompleteSlot,

  IvrPermissionSet.ViewMetrics,

  RequestPermissionSet.CreateUnmatchedRequest,

  VehiclePermissionSet.ManageInspection,
  VehiclePermissionSet.ViewInspection,

  WorkflowPermissionSet.View,
  WorkflowPermissionSet.Write,
  WorkflowPermissionSet.Delete,

  AiAgentPermissionSet.View,
  AiAgentPermissionSet.Write,

  AuthorizationGroupPermissionSet.View,
  AuthorizationGroupPermissionSet.Write,
  AuthorizationGroupPermissionSet.Delete,

  CancellationReasonPermissionSet.View,
  CancellationReasonPermissionSet.Write,
  CancellationReasonPermissionSet.Delete,
]
