import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum AppPermissionSet {
  View = 'app_view',
  Manage = 'app_manage',
  AppInstallationView = 'app_installation_view',
  AppInstallationManage = 'app_installation_manage',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const appPermissionSetGrouping: IPermissionSetGrouping<AppPermissionSet> = {
  key: PermissionSetGroupingKey.App,
  active: {
    [AppPermissionSet.View]: [
      PermissionType.GET_ANY_APP,
      PermissionType.LIST_ANY_APP,
      PermissionType.GET_ANY_APP_WEBHOOK,
    ],
    [AppPermissionSet.Manage]: [
      PermissionType.WRITE_ANY_APP,
      PermissionType.PATCH_ANY_APP_CLIENT_SECRET,
      PermissionType.DELETE_ANY_APP,
      PermissionType.WRITE_ANY_APP_WEBHOOK,
      PermissionType.DELETE_ANY_APP_WEBHOOK,
    ],
    [AppPermissionSet.AppInstallationView]: [
      PermissionType.GET_ANY_APP_INSTALLATION,
    ],
    [AppPermissionSet.AppInstallationManage]: [
      PermissionType.GET_PUBLIC_APP, // special permission which allows read access on apps developed in another organization
      PermissionType.POST_OAUTH_AUTHORIZE,
      PermissionType.DELETE_ANY_APP_INSTALLATION,
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
