import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  DataRetentionSchedulePatchBody,
  IDataRetentionScheduleListQueryParams,
  IDataRetentionSchedulePostBody,
  IDataRetentionScheduleResponse,
  Resources,
} from '../types'

export class DataRetentionScheduleResource extends GenericResource<
  IDataRetentionScheduleResponse,
  IDataRetentionScheduleListQueryParams,
  DataRetentionSchedulePatchBody,
  IDataRetentionSchedulePostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()

  constructor(agent: AxiosInstance) {
    super(Resources.DataRetentionSchedules, agent)
  }
}
