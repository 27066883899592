import { EnumValues } from 'enum-values'
import PACKAGE from '../../package.json'

export interface IConfiguration {
  apiUrl: string
  version: string
}

export enum Environment {
  Production_CA = 'production_ca',
  Production_US = 'production_us',
  Staging_CA = 'staging_ca',
  Staging_US = 'staging_us',
  Development = 'development',
  Ngrok = 'ngrok',
}

export type IConfigMap = { [key in Environment]: IConfiguration }

const { version } = PACKAGE

// setting all prod default urls to US region
// api host will be changed once we map it to the organization the form is associated to
const production_ca: IConfiguration = {
  apiUrl: 'https://api.us.sparelabs.com',
  version,
}

const production_us: IConfiguration = {
  apiUrl: 'https://api.us.sparelabs.com',
  version,
}

const staging_ca: IConfiguration = {
  apiUrl: process.env.REACT_APP_API_URL || 'https://api.staging.sparelabs.com',
  version,
}

const staging_us: IConfiguration = {
  apiUrl: process.env.REACT_APP_API_URL || 'https://api.staging.us.sparelabs.com',
  version,
}

const development: IConfiguration = {
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:3000',
  version,
}

const ngrok: IConfiguration = {
  apiUrl: process.env.REACT_APP_API_URL || 'https://api-spare.ngrok.io',
  version,
}

const buildDevClusterConfig = (url: string): IConfiguration => ({
  apiUrl: process.env.REACT_APP_API_URL || url.replace('forms.', 'api.'),
  version,
})

const configMap: IConfigMap = {
  [Environment.Production_CA]: production_ca,
  [Environment.Production_US]: production_us,
  [Environment.Staging_CA]: staging_ca,
  [Environment.Staging_US]: staging_us,
  [Environment.Development]: development,
  [Environment.Ngrok]: ngrok,
}

const rootUrlToEnvMap: Record<string, Environment> = {
  'https://forms.sparelabs.com': Environment.Production_CA,
  'https://forms.us.sparelabs.com': Environment.Production_US,
  'https://forms.staging.sparelabs.com': Environment.Staging_CA,
  'https://forms.staging.us.sparelabs.com': Environment.Staging_US,
  'http://localhost:3035': Environment.Development,
}

function getConfig(): IConfiguration {
  const rootUrl = `${location.protocol}//${location.host}`
  const environments = EnumValues.getValues<Environment>(Environment)
  const processEnvOverride = process.env.REACT_APP_ENV_OVERRIDE as Environment | undefined
  if (processEnvOverride && environments.includes(processEnvOverride)) {
    return configMap[processEnvOverride]
  } else if (rootUrlToEnvMap[rootUrl]) {
    return configMap[rootUrlToEnvMap[rootUrl]]
  }
  // This is not a pre-defined configuration, so likely we are running a custom developer cluster
  return buildDevClusterConfig(rootUrl)
}

export const constants = getConfig()
