import { ContextPropagator } from './ContextPropagator'

export enum AuthContextUserType {
  Admin = 'admin',
  Driver = 'driver',
  Rider = 'rider',
  SuperAdmin = 'superAdmin',
}

export interface IAuthContext {
  userId?: string
  organizationId?: string
  isTestOrganization?: boolean
  service?: string
  apiKeyId?: string
  appId?: string
  userTypes?: AuthContextUserType[]
}

export const AuthContextPropagator = new ContextPropagator<IAuthContext>()
