import { CustomFieldVisibility, ICustomFieldResponse } from '@sparelabs/api-client'
import { InputWithLabel, ViewField } from '@sparelabs/web-ui-components'
import React from 'react'
import { FormValidator } from 'src/helpers/FormValidator'
import styled, { css } from 'styled-components'
import { CustomFieldInput } from './CustomFieldInput'
import { CustomFieldView } from './CustomFieldView'

export const StackedInputsCSS = css`
  display: block;
  margin-bottom: 8px;

  label {
    line-height: 20px;
    padding-bottom: 5px;
  }
  * {
    max-width: unset;
  }
`

export const StackedViewField = styled(ViewField)`
  ${StackedInputsCSS}
`

export const StackedInputWithLabel = styled(InputWithLabel)`
  ${StackedInputsCSS}
`

interface ICustomFieldWithLabelProps {
  field: ICustomFieldResponse
  showValidation: boolean
  metadata: Record<string, unknown>
  onChange: (value: Record<string, unknown>) => void
}

export const CustomFieldWithLabel: React.FC<ICustomFieldWithLabelProps> = ({
  field,
  showValidation,
  metadata,
  onChange,
}: ICustomFieldWithLabelProps) => {
  if (field.riderInterfaceVisibility === CustomFieldVisibility.Hidden) {
    return null
  }

  if (field.riderInterfaceVisibility === CustomFieldVisibility.View) {
    return (
      <StackedViewField label={field.label}>
        <CustomFieldView field={field} value={metadata[field.key]} />
      </StackedViewField>
    )
  }

  return (
    <StackedInputWithLabel
      label={field.label}
      hint={field.hint ?? undefined}
      error={showValidation ? FormValidator.validateCustomField(metadata[field.key], field) : undefined}
      isRequired={field.isRequired}
      renderInput={({ id }) => (
        <CustomFieldInput
          id={id}
          field={field}
          value={metadata[field.key]}
          onChange={(value) => onChange({ ...metadata, [field.key]: value })}
        />
      )}
    />
  )
}
