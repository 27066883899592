import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IAppInstallationResponse, IListAppInstallationQueryParams, Resources } from '../types'

export class AppInstallationResource extends GenericResource<
  IAppInstallationResponse,
  IListAppInstallationQueryParams,
  never,
  never
> {
  constructor(agent: AxiosInstance) {
    super(Resources.AppInstallation, agent)
  }

  public get = this.buildGet()
  public list = this.buildList()
  public del = this.buildDel()
}
