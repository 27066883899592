import { WebColors } from '@sparelabs/colors'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { INPUT_MAX_WIDTH } from '../../util'

const CharacterCountHintContainer = styled.div`
  max-width: ${INPUT_MAX_WIDTH};
  display: flex;
  justify-content: space-between;
`

const CountText = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${WebColors.contentSecondary};
`

const HintText = styled.div<{ compact?: boolean }>`
  font-size: 13px;
  color: ${WebColors.contentTertiary};
  font-weight: 400;
  line-height: ${({ compact }) => (compact ? undefined : '20px')};
  max-width: 450px;
`

export interface IMaxLengthOption {
  maxLength: number
  hint: string
}
interface ICharacterCountProps {
  value: string
  maxLengthOption: IMaxLengthOption
  isCompact?: boolean
}

export const CharacterCount = ({ value, maxLengthOption, isCompact }: ICharacterCountProps): JSX.Element => {
  const [characterCount, setCharacterCount] = useState(value.length || 0)
  useEffect(() => {
    setCharacterCount(value.length || 0)
  }, [value])
  return (
    <CharacterCountHintContainer>
      <HintText compact={isCompact}>{maxLengthOption.hint}</HintText>
      <CountText>
        {characterCount}/{maxLengthOption.maxLength}
      </CountText>
    </CharacterCountHintContainer>
  )
}
