import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum RealizePermissionSet {
  Access = 'realize_access',
}

export const realizePermissionSetGrouping: IPermissionSetGrouping<RealizePermissionSet> = {
  key: PermissionSetGroupingKey.Realize,
  active: {
    [RealizePermissionSet.Access]: [
      PermissionType.GET_ANY_PROJECT,
      PermissionType.WRITE_ANY_PROJECT,
      PermissionType.DELETE_ANY_PROJECT,
      PermissionType.GET_ANY_REALIZE_SERVICE,
      PermissionType.POST_ANY_REALIZE_SERVICE,
      PermissionType.PATCH_ANY_REALIZE_SERVICE,
      PermissionType.DELETE_ANY_REALIZE_SERVICE,
      PermissionType.GET_ANY_REALIZE_ZONE,
      PermissionType.POST_ANY_REALIZE_ZONE,
      PermissionType.PATCH_ANY_REALIZE_ZONE,
      PermissionType.DELETE_ANY_REALIZE_ZONE,
      PermissionType.GET_KPI_PREDICTION,
      PermissionType.GET_SAMPLE_TRIPS,
      PermissionType.POST_ZONE_SUMMARY,
      PermissionType.POST_ROI_CALCULATION,
      PermissionType.GET_ANY_DATASET,
      PermissionType.LIST_ANY_DATASET_HEATMAP_POINTS,
      PermissionType.LIST_ANY_DATASET_POINTS,
      PermissionType.LIST_ANY_DATASET_POLYGON,
      PermissionType.POST_ANY_DATASET_POLYGON,
      PermissionType.GET_ANY_DEMAND_SEGMENT,
      PermissionType.WRITE_ANY_DEMAND_SEGMENT,
      PermissionType.DELETE_ANY_DEMAND_SEGMENT,
      PermissionType.LIST_ANY_GTFS_FILES,
      PermissionType.POST_ANY_REALIZE_FIXED_ROUTE_GTFS,
      PermissionType.GET_ANY_REALIZE_FIXED_ROUTE,
      PermissionType.DELETE_ANY_REALIZE_FIXED_ROUTE,
      PermissionType.GET_ANY_REALIZE_FIXED_ROUTE_STOP,
      PermissionType.GET_ANY_REALIZE_FIXED_ROUTE_STOP_CONNECTION,
      PermissionType.GET_ANY_REALIZE_FLEET,
      PermissionType.WRITE_ANY_REALIZE_FLEET,
      PermissionType.DELETE_ANY_REALIZE_FLEET,
      PermissionType.WRITE_ANY_REALIZE_SERVICE_FLEET,
      PermissionType.LIST_ANY_REALIZE_SERVICE_FLEET,
      PermissionType.DELETE_ANY_REALIZE_SERVICE_FLEET,
      PermissionType.GET_ANY_REALIZE_STOP,
      PermissionType.POST_ANY_REALIZE_STOP,
      PermissionType.PATCH_ANY_REALIZE_STOP,
      PermissionType.DELETE_ANY_REALIZE_STOP,
      PermissionType.LIST_ANY_HISTORIC_TRIP_DATASET,
      PermissionType.POST_ANY_HISTORIC_TRIP_DATASET,
      PermissionType.DELETE_ANY_HISTORIC_TRIP_DATASET,
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
