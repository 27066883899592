import { IPolygon, UTurnPenalty, VehicleClass } from '@sparelabs/geography'
import { IPublicFileResponse } from '@sparelabs/http-client-utils'

export enum RoutingDataVersion {
  V3 = '5.27.1',
  V2 = '5.24.0',
  V1 = '5.21',
}

export interface IRoutingDataPostBody {
  area: IPolygon
  leftHandTraffic: boolean
  avoidAlleys: boolean
  ignoreSideOfRoad: boolean
  uTurnPenalty: UTurnPenalty
  vehicleClass: VehicleClass
  avoidTolls: boolean
  changeSetFile: IPublicFileResponse | null
}

export interface IRoutingDataResponse extends IRoutingDataPostBody {
  url: string
  version: RoutingDataVersion
}
