import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum JourneyPermissionSet {
  View = 'journey_view',
  Write = 'journey_write',
}

export const journeyPermissionSetGrouping: IPermissionSetGrouping<JourneyPermissionSet> = {
  key: PermissionSetGroupingKey.Journey,
  active: {
    [JourneyPermissionSet.View]: [PermissionType.GET_ANY_JOURNEY],
    [JourneyPermissionSet.Write]: [PermissionType.WRITE_ANY_JOURNEY],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
