import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IReportPatchBody, IReportPreviewResponse, IReportQueryParams, IReportResponse, Resources } from '../types'

export class ReportResource extends GenericResource<IReportResponse, IReportQueryParams, IReportPatchBody, never> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()

  constructor(agent: AxiosInstance) {
    super(Resources.Reports, agent)
  }

  public async getPreview(id: string): Promise<IReportPreviewResponse> {
    const res = await this.agent.get<IReportPreviewResponse>(`${this.resource}/${id}/preview`)
    return res.data
  }
}
