import { IconName } from '@fortawesome/fontawesome-svg-core'
import { VerificationMethod } from '@sparelabs/api-client'
import { TimeUnits, toSeconds } from '@sparelabs/time'
import { DefaultButtonTheme, RoundedButton, useCountdown } from '@sparelabs/web-ui-components'
import React from 'react'
import { st } from 'src/locales/TranslationHelper'

/**
 * offset to ensure the we don't display 0 seconds left when the countdown is between 0 and 1 seconds
 */
const COUNTDOWN_OFFSET = 1

interface IResendCodeButtonProps {
  handleResend: () => void
  countdownMs: number
  method: VerificationMethod
  activeText: string
  icon: IconName
  activateInitially?: boolean
}

export const ResendCodeButton = ({
  handleResend,
  countdownMs,
  method,
  activeText,
  icon,
  activateInitially = false,
}: IResendCodeButtonProps) => {
  const { timeRemaining, start } = useCountdown({ time: countdownMs, start: activateInitially })

  const onClick = () => {
    start()
    handleResend()
  }

  return (
    <RoundedButton theme={DefaultButtonTheme} onClick={onClick} icon={icon} disabled={timeRemaining !== 0}>
      {timeRemaining === 0
        ? activeText
        : getDisabledText(method, toSeconds(timeRemaining, TimeUnits.Milliseconds) + COUNTDOWN_OFFSET)}
    </RoundedButton>
  )
}

const getDisabledText = (method: VerificationMethod, seconds: number): string =>
  method === VerificationMethod.Sms
    ? st.forms.auth.resendSmsDisabled({ seconds })
    : st.forms.auth.resendVoiceDisabled({ seconds })
