interface IObject {
  [key: string]: string | number | boolean | null | undefined
}

export class SnakeCase {
  public static objectCamelCaseToSnakeCase(object: IObject): IObject {
    const newObject: IObject = {}
    for (const key in object) {
      newObject[this.camelCaseToSnakeCase(key)] = object[key]
    }
    return newObject
  }

  public static camelCaseToSnakeCase(key: string): string {
    return key.replace(/([A-Z])/g, '_$1').toLowerCase()
  }
}
