import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Root as PortalRoot } from '@radix-ui/react-portal'
import React, { Children, ReactNode } from 'react'
import styled from 'styled-components'
import { POPOVER_Z_INDEX } from '../util'
import { PopoverContent } from './PopoverContent'
import { PopoverRoot } from './PopoverRoot'
import { PopoverTrigger } from './PopoverTrigger'

interface IProps {
  isOpen: boolean
  toggle: () => void
  width?: string
  disabled?: boolean
  isInModal?: boolean
  alignRight?: boolean
  icon?: IconProp
  customIcon?: JSX.Element
  toggleTitle?: ReactNode
  toggleButton?: JSX.Element
  children?: ReactNode
  style?: React.CSSProperties
  hideToggle?: boolean
  hideWhenNoChildren?: boolean
  ariaLabel?: string
}

// Portal defaults to the largest possible integer but we may want other components to overlay over the popover
const PopoverPortal = styled(PortalRoot)`
  z-index: ${POPOVER_Z_INDEX};
`

export const Popover = ({
  isOpen,
  toggle,
  icon,
  disabled = false,
  toggleTitle,
  customIcon,
  alignRight = false,
  children,
  style,
  hideToggle,
  ariaLabel,
  /**
   * Currently this only works when actions are conditionally rendered from `outside`
   * of the action component. Because we wrap many actions in `withPermissions`,
   * 'inside' each component, filtering out null is not straightforward.
   *
   * todo: Refine this so the Popover is hidden correctly based on actions hidden from `within`
   * as well as from `outside`.
   */
  hideWhenNoChildren = false,
}: IProps): JSX.Element | null => {
  const hasNoChildren = Children.toArray(children).length === 0

  return hideWhenNoChildren && hasNoChildren ? null : (
    <PopoverRoot isOpen={isOpen} toggle={toggle}>
      <PopoverTrigger
        disabled={disabled}
        isOpen={isOpen}
        customIcon={customIcon}
        icon={icon}
        toggleTitle={toggleTitle}
        hideToggle={hideToggle}
        aria-label={ariaLabel}
      />
      <PopoverPortal>
        {/* We wrap the contents inside our own portal to control it's z-index */}
        <PopoverContent alignRight={alignRight} style={style}>
          {children}
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>
  )
}
