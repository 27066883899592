import { BrowserLanguageHelper, TranslatedLanguageStringRecord, Translator } from '@sparelabs/translate'
import en from './en'
import * as ca from './files/ca.json'
import * as de from './files/de.json'
import * as es from './files/es.json'
import * as fr from './files/fr.json'
import * as is from './files/is.json'
import * as ja from './files/ja.json'
import * as nb from './files/nb.json'
import * as nn from './files/nn.json'
import * as pt from './files/pt.json'
import * as sv from './files/sv.json'
import { ILocaleTranslationKeys } from './keys'

const translatedLanguages: TranslatedLanguageStringRecord<ILocaleTranslationKeys> = {
  ja,
  nb,
  de,
  es,
  ca,
  is,
  sv,
  fr,
  nn,
  pt,
}

const translate = new Translator<ILocaleTranslationKeys>(
  en,
  translatedLanguages,
  BrowserLanguageHelper.getCurrentLanguageCode(),
  (error) => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }
)

export const st = translate.st
