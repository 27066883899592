import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IListFleetMetricsQueryParams, IListFleetMetricsResponse, Resources } from '../types'

export class FleetMetricsResource extends GenericResource<
  IListFleetMetricsResponse,
  IListFleetMetricsQueryParams,
  never,
  never
> {
  // the return value of the /metrics/fleets endpoint differs from the generic IListResponse
  public fetchMetrics = async (params: IListFleetMetricsQueryParams): Promise<IListFleetMetricsResponse> => {
    const response = await this.agent.get<IListFleetMetricsResponse>(`${this.resource}`, { params })
    return response.data
  }

  constructor(agent: AxiosInstance) {
    super(Resources.FleetMetrics, agent)
  }
}
