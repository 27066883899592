import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IVehicleListQueryParams, IVehiclePatchBody, IVehiclePostBody, IVehicleResponse, Resources } from '../types'

export class VehicleResource extends GenericResource<
  IVehicleResponse,
  IVehicleListQueryParams,
  IVehiclePatchBody,
  IVehiclePostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()
  constructor(agent: AxiosInstance) {
    super(Resources.Vehicles, agent)
  }
}
