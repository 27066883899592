import { GenericResource, IListResponse } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IDutiesPostBody,
  IDutiesSearchQueryParams,
  IDutiesSearchResponse,
  IDutyHistoryResponse,
  IDutyPatchBody,
  IDutyPolylineQueryParams,
  IDutyPolylineResponse,
  IDutyPostBody,
  IDutyQueryParams,
  IDutyResponse,
  IDutyStubQueryParams,
  IDutyStubResponse,
  ILastVehicleLocationResponse,
  IVehicleLocationPostBody,
  Resources,
} from '../types'

export class DutyResource extends GenericResource<IDutyResponse, IDutyQueryParams, IDutyPatchBody, IDutyPostBody> {
  public post = this.buildPost()
  public patch = this.buildPatch()
  public del = this.buildDel()
  public get = this.buildGet()
  public list = this.buildList()

  constructor(agent: AxiosInstance) {
    super(Resources.Duties, agent)
  }

  public async getLastVehicleLocation(dutyId: string): Promise<ILastVehicleLocationResponse> {
    const res = await this.agent.get<ILastVehicleLocationResponse>(`${Resources.Duties}/${dutyId}/vehicleLocation`)
    return res.data
  }

  public async postVehicleLocation(
    dutyId: string,
    body: IVehicleLocationPostBody
  ): Promise<ILastVehicleLocationResponse> {
    const res = await this.agent.post<ILastVehicleLocationResponse>(
      `${Resources.Duties}/${dutyId}/vehicleLocation`,
      body
    )
    return res.data
  }

  public async cancel(dutyId: string): Promise<IDutyResponse> {
    const res = await this.agent.post<IDutyResponse>(`${Resources.Duties}/${dutyId}/cancellation`)
    return res.data
  }

  public async getPolyline(dutyId: string, params: IDutyPolylineQueryParams = {}): Promise<IDutyPolylineResponse> {
    const res = await this.agent.get<IDutyPolylineResponse>(`${Resources.Duties}/${dutyId}/polyline`, { params })
    return res.data
  }

  public async getStub(dutyId: string): Promise<IDutyStubResponse> {
    const res = await this.agent.get<IDutyStubResponse>(`${Resources.Duties}/${dutyId}/stub`)
    return res.data
  }

  public async listStubs(
    params: IDutyStubQueryParams,
    fetchAllPages: boolean = false
  ): Promise<IListResponse<IDutyStubResponse>> {
    if (fetchAllPages) {
      const data = await this.allPageFetcher.fetch<IDutyStubResponse, IDutyStubQueryParams>('dutyStubs', params)
      return { data, skip: 0, total: data.length, limit: data.length }
    }

    const res = await this.agent.get<IListResponse<IDutyStubResponse>>('dutyStubs', { params })
    return res.data
  }

  public async postBulk(body: IDutiesPostBody): Promise<IListResponse<IDutyResponse>> {
    const res = await this.agent.post<IListResponse<IDutyResponse>>(`${Resources.Duties}/bulk`, body)
    return res.data
  }

  public async getHistory(dutyId: string): Promise<IDutyHistoryResponse> {
    const res = await this.agent.get<IDutyHistoryResponse>(`${Resources.Duties}/${dutyId}/history`)
    return res.data
  }

  public async search(params: IDutiesSearchQueryParams): Promise<IDutiesSearchResponse> {
    const res = await this.agent.get<IDutiesSearchResponse>('duties/search', { params })
    return res.data
  }
}
