import { AxiosInstance } from 'axios'
import {
  IBookingOptionsQueryParams,
  IBookingOptionsResponse,
  IEstimateNextAvailableQueryParams,
  IEstimateScheduledQueryParams,
  IEstimateServiceQueryParams,
  IEstimateServiceResponse,
  IOverrideEstimateOutput,
  Resources,
} from '../types'

export class EstimateResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async getServices(params: IEstimateServiceQueryParams): Promise<IEstimateServiceResponse> {
    const res = await this.agent.get<IEstimateServiceResponse>(`${Resources.Estimates}/services`, { params })
    return res.data
  }

  public async getNextAvailable(params: IEstimateNextAvailableQueryParams): Promise<IOverrideEstimateOutput> {
    const res = await this.agent.get<IOverrideEstimateOutput>(`${Resources.Estimates}/nextAvailable`, { params })
    return res.data
  }

  public async getScheduled(params: IEstimateScheduledQueryParams): Promise<IOverrideEstimateOutput> {
    const res = await this.agent.get<IOverrideEstimateOutput>(`${Resources.Estimates}/request`, { params })
    return res.data
  }

  public async getBookingOptions(
    params: IBookingOptionsQueryParams,
    abortSignal?: AbortSignal
  ): Promise<IBookingOptionsResponse> {
    const res = await this.agent.get<IBookingOptionsResponse>(`${Resources.Estimates}/bookingOptions`, {
      params,
      signal: abortSignal,
    })
    return res.data
  }
}
