import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'

export enum FormStatus {
  Active = 'active',
  Archived = 'archived',
}

export enum FormType {
  Rider = 'rider',
  Specialist = 'specialist',
}

export interface IFormResponse extends IModelJsonValues, IFormPostBody {
  fieldGroupId: string
  status: FormStatus
}

export interface IListFormQueryParams extends IListModelQueryParamValues {
  caseTypeId?: string
  status?: FormStatus
  type?: FormType
}

export interface IFormPatchBody {
  name?: string
  description?: string
  status?: FormStatus
  type?: FormType
  order?: string
}

export interface IFormPostBody {
  name: string
  description: string
  key: string
  caseTypeId: string
  type: FormType
  order: string
}

export interface IPublicFormResponse {
  id: string
  name: string
  description: string
  key: string
}
