import { DriverUserPermissionSet, PermissionSetType, RiderPermissionSet } from '../sets'
import { ChargePermissionSet } from '../sets/ChargePermissions'
import { ConnectedAccountPermissionSet } from '../sets/ConnectedAccountPermissions'
import { FarePassPermissionSet } from '../sets/FarePassPermissions'
import { PaymentMethodPermissionSet } from '../sets/PaymentMethodPermissions'
import { PromoPermissionSet } from '../sets/PromoPermissions'
import { RequestPermissionSet } from '../sets/RequestPermissions'
import { ReviewPermissionSet } from '../sets/ReviewPermissions'
import { RiderUserPermissionSet } from '../sets/RiderUserPermissions'
import { ServicePermissionSet } from '../sets/ServicePermissions'
import { TicketPermissionSet } from '../sets/TicketPermissions'
import { TipPermissionSet } from '../sets/TipPermissions'
import { AdminOperatorRole } from './AdminOperatorRole'

export const FinanceManagerRole: PermissionSetType[] = [
  ...AdminOperatorRole,

  // TODO: Replace rider permission set with more limited permission sets
  RiderPermissionSet.Rider,

  RiderUserPermissionSet.View,
  DriverUserPermissionSet.View,
  DriverUserPermissionSet.Export,

  /* Ability to View Service/Fleet/Zone/Stop/TimeRule */
  ServicePermissionSet.View,

  /* All permissions for Promo */
  PromoPermissionSet.View,
  PromoPermissionSet.Write,
  PromoPermissionSet.Export,
  PromoPermissionSet.ApplyToUser,

  /* Able to view and manage FarePass */
  FarePassPermissionSet.View,
  FarePassPermissionSet.Write,
  FarePassPermissionSet.NewAllocation,
  FarePassPermissionSet.EditAllocation,
  FarePassPermissionSet.ImportAllocations,
  FarePassPermissionSet.ExportAllocations,

  /* Able to view and manage Tickets */
  TicketPermissionSet.ViewInstance,
  TicketPermissionSet.WriteInstance,

  /* Ability to see request information and discounts */
  RequestPermissionSet.View,
  RequestPermissionSet.ViewDiscounts,

  /* Ability to view reviews */
  ReviewPermissionSet.View,

  /* Ability to view tips */
  TipPermissionSet.View,

  /* Ability to view and refund charges */
  ChargePermissionSet.View,
  ChargePermissionSet.Refund,

  /* Ability to view payment methods collected and export data */
  PaymentMethodPermissionSet.View,
  PaymentMethodPermissionSet.Export,
  PaymentMethodPermissionSet.CollectDirectly,
  PaymentMethodPermissionSet.InitiatePhoneCollection,
  PaymentMethodPermissionSet.Delete,

  /* Ability to view connected accounts and disconnect */
  ConnectedAccountPermissionSet.View,
  ConnectedAccountPermissionSet.Delete,
]
