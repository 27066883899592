import { IMarkdownVariable } from '@sparelabs/markdown-utils'
import { ErrorHandler } from 'src/helpers/ErrorHandler'

export interface IFormContentVariableRenderData {
  caseId?: string
}

type IFormContentVariable = IMarkdownVariable<object, IFormContentVariableRenderData>

const FormLinkVar: IFormContentVariable = {
  // Not implemented in Engage Forms
  buildDefinition: () => [],
  buildHelpers: ({ caseId }) => ({
    /**
     * `formLink`
     *
     *  Dynamic variable that inserts a link using a Handlebars Helper function. Shown in the editor as
     *  a list of "Link to {formName}". Note: the quotes around `"formKey"` are important for the
     *  Handlebars helper to work.
     *
     *  Spec:
     *  - Usage: `{{formLink "formKey"}}`
     *  - Output: `https://forms.sparelabs.com/form?organizationId=1&caseTypeId=2&caseId=3&formKey=formKey`
     */
    formLink: (formKey?: string) => {
      // Match orgId and caseTypeKey from the current form
      const currentSearchParams = new URLSearchParams(location.search)
      const organizationId = currentSearchParams.get('organizationId')
      const caseTypeKey = currentSearchParams.get('caseTypeKey')
      if (!formKey || !organizationId || !caseTypeKey || !caseId) {
        ErrorHandler.handleError(new Error('Unable to build form links as the current url is invalid.'))
        return ''
      }

      const newSearchParams = new URLSearchParams({
        organizationId,
        caseTypeKey,
        formKey,
        caseId,
      })
      return `${location.origin}${location.pathname}?${newSearchParams.toString()}`
    },
  }),
}

export const FormContentVariables: IFormContentVariable[] = [FormLinkVar]
