import { ITranslationStrings } from '@sparelabs/translate'
import { ILocaleTranslationKeys } from './keys'

const en: ITranslationStrings<ILocaleTranslationKeys> = {
  genericHelpers: {
    eq: 'Equals',
    uneq: 'Not Equals',
    lt: 'Less Than (<)',
    lte: 'Less Than or Equal (<=)',
    gt: 'Greater Than (>)',
    gte: 'Greater Than or Equal (>=)',
    and: 'Logical And (all arguments are true-like)',
    or: 'Logical Or (at least 1 arguments is true-like)',
    length: 'Length of a List',
    includes: 'List Includes a Value',
    lowercase: 'Lowercase',
    uppercase: 'Uppercase',
    contains: 'Contains',
    add: 'Sum a list of numbers (a + b + c + ...)',
    subtract: 'Subtract a list of numbers (a - b - c - ...)',
    multiply: 'Multiply a list of numbers (a * b * c * ...)',
    divide: 'Divide a list of numbers (a / b / c / ...)',
    round: 'Round a value to a precision (value, decimals = 0)',
    modulo: 'Remainder of a division (a % b)',
    percent: 'Percentage of a value (value, total)',
  },
  engageLetterLabels: {
    date: {
      singularName: 'Date',
      currentDate: 'Current Date',
    },
    case: {
      singularName: 'Case',
      id: 'Case ID',
      assignedTo: 'Assignee',
    },
    genericHelpers: {
      logicUtils: 'Logical Comparisons',
      arrayUtils: 'List Helpers',
      stringUtils: 'Text Helpers',
    },
  },
}

// eslint-disable-next-line import/no-default-export
export default en
