import React from 'react'
import { st } from '../../locales/TranslationHelper'
import { IInputProps } from './InputTypes'
import { StyledInput } from './styled/StyledInput'

// Empty string will cause the placeholder to show up.
const getStringFromValue = (value: unknown): string =>
  value === undefined || value === '' ? '' : JSON.stringify(value)

export interface ISingleChoiceInput<T> extends IInputProps<T> {
  choices: Array<{ label: string; value: T; isDisabled?: boolean }>
  selectOptionPrompt?: string
  value?: T
  id?: string
  /**
   * Defaults to true. If you want to allow clearing the selection, then set this to be false
   */
  required?: boolean
}

const getFirstOptionLabel = (
  selectOptionPrompt: string | undefined,
  required: boolean,
  placeholder: string | undefined
) => {
  if (selectOptionPrompt) {
    return selectOptionPrompt
  }

  if (placeholder) {
    return placeholder
  }

  if (required) {
    return st.inputs.choice.selectOptionPrompt()
  }

  return st.common.none()
}

export const SingleChoiceInput = <T,>({
  value,
  choices,
  selectOptionPrompt,
  onChange,
  invalid,
  id,
  disabled,
  required = true,
  placeholder,
  ...inputAttributes
}: ISingleChoiceInput<T>) => {
  const parseOnChange = (newValue: string) => {
    const choice = choices.find((choice) => getStringFromValue(choice.value) === newValue)
    onChange(choice?.value)
  }

  return (
    <StyledInput
      {...inputAttributes}
      type='select'
      onChange={(event) => parseOnChange(event.target.value)}
      value={getStringFromValue(value)}
      invalid={invalid}
      id={id}
      disabled={disabled}
      required={required}
    >
      <option disabled={required} value=''>
        {getFirstOptionLabel(selectOptionPrompt, required, placeholder)}
      </option>
      {choices.map((choice) => {
        const stringValue = getStringFromValue(choice.value)
        return (
          <option key={stringValue} value={stringValue} disabled={choice.isDisabled}>
            {choice.label}
          </option>
        )
      })}
    </StyledInput>
  )
}
