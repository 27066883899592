import { Banner, successBannerTheme } from '@sparelabs/web-ui-components'
import React from 'react'
import { BannerContainer, Container, InfoParagraph, UpdateFormSection } from 'src/components'
import { st } from 'src/locales/TranslationHelper'
import { PostSubmitFormSection } from 'src/pages/Form/steps/FormInput/PostSubmitFormSection'

interface IProps {
  isPublicForm: boolean
  onUpdateClick: () => void
}

export const AlreadySubmitted = ({ isPublicForm, onUpdateClick }: IProps): JSX.Element => (
  <Container>
    <BannerContainer>
      <Banner theme={successBannerTheme}>{st.forms.submission.infoMessage()}</Banner>
    </BannerContainer>

    <PostSubmitFormSection />

    {isPublicForm && <InfoParagraph role='complementary'>{st.forms.submission.privacyMessage()}</InfoParagraph>}
    <UpdateFormSection onUpdateClick={onUpdateClick} />
  </Container>
)
