import { IMarkdownVariable, IMarkdownVariableDefinition } from './MarkdownVariableTypes'

export class MarkdownVariableDefinitionBuilder {
  public static build<BuilderData extends object>(
    variables: Array<IMarkdownVariable<BuilderData, object>>,
    data: BuilderData
  ) {
    const output: IMarkdownVariableDefinition[] = []

    for (const variable of variables) {
      const result = variable.buildDefinition(data)
      if (result) {
        output.push(...result)
      }
    }

    return output
  }
}
