import { WebColors, WebGradients } from '@sparelabs/colors'

export interface IBadgeTheme {
  backgroundColor: string
  textColor: string
  borderColor?: string
  background?: string
}

export const greenBadgeTheme: IBadgeTheme = {
  backgroundColor: WebColors.positiveLowContrast,
  textColor: WebColors.positiveHighContrast,
}

export const blueBadgeTheme: IBadgeTheme = {
  backgroundColor: WebColors.interactiveLowContrast,
  textColor: WebColors.interactiveHighContrast,
}

export const redBadgeTheme: IBadgeTheme = {
  backgroundColor: WebColors.negativeLowContrast,
  textColor: WebColors.negativeHighContrast,
}

export const yellowBadgeTheme: IBadgeTheme = {
  backgroundColor: WebColors.warningLowContrast,
  textColor: WebColors.warningHighContrast,
}

export const blackBadgeTheme: IBadgeTheme = {
  backgroundColor: WebColors.backgroundTertiary,
  textColor: WebColors.contentPrimary,
}

export const transparentBadgeTheme: IBadgeTheme = {
  backgroundColor: 'rgba(0,0,0,0.24)',
  textColor: WebColors.contentInvertedPrimary,
}

export const grayBadgeTheme: IBadgeTheme = {
  backgroundColor: WebColors.accentGrayLowContrast,
  textColor: WebColors.accentGrayHighContrast,
}

export const purpleBadgeTheme: IBadgeTheme = {
  backgroundColor: WebColors.accentPurpleLowContrast,
  textColor: WebColors.accentPurpleHighContrast,
}

export const blueAltBadgeTheme: IBadgeTheme = {
  backgroundColor: WebColors.accentCoolGrayLowContrast,
  textColor: WebColors.accentCoolGrayHighContrast,
}

export const aiFeatureBadgeTheme: IBadgeTheme = {
  background: WebGradients.ai,
  textColor: WebColors.accentCoolGrayHighContrast,
  backgroundColor: WebColors.accentCoolGrayLowContrast,
}
