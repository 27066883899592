import { ContextPropagator } from './ContextPropagator'

export interface ICrossServiceContext {
  /**
   * A random UUID defined once for the root span and inherited by all "child spans", including downstream services
   * that receive calls triggered from this entrypoint.
   */
  correlationId: string
  /**
   * A random UUID defined once for every "child span", which can be arbitrarily granular, but by default
   * every "hop" into a downstream service is considered a child span.
   *
   * Example: If Service A makes 3 calls to Service B, every call to Service B will have a separate, unique spanId.
   */
  spanId: string
  /**
   * The spanId for the span that triggered this one. Undefined for entry-points into our application.
   *
   * Example: If ServiceA makes 3 calls to ServiceB, every call to ServiceB should have parentSpanId := ServiceA.spanId
   */
  parentSpanId: string | undefined
  /**
   * A human-readable label to identify the "root span" of the current execution context. It's kind of up to us how we
   * use this, but roughly speaking the root span corresponds to an execution path that was not triggered by another part
   * of our code, but rather by either an external incoming request, or some condition like a periodically recurring job.
   */
  rootSpanLabel: string
  /**
   * If work has been triggered by a Spare Flow workflow, this is the id of the workflow that triggered it.
   */
  workflowId: string | undefined
  /**
   * If work has been triggered by a Spare Flow workflow, this is the id of the individual run of the workflow that
   * triggered it.
   */
  workflowRunId: string | undefined
  /**
   * Relevant when one Spare Flow workflow triggers another workflow. For example, if workflow A triggers workflow B,
   * which triggers workflow C, and we're now in workflow C's execution context, this field would contain the IDs of
   * workflows A and B.
   */
  parentWorkflowIds: string[] | undefined
  /**
   * [ORG_ROUTE_METRICS]: which organization is responsible for the current workload?
   */
  organizationId?: string | undefined
}

/**
 * Context that is propagated across service boundaries, that we don't want to explicitly pass around everywhere.
 *
 * See the comments on the individual fields in {@link ICrossServiceContext} for a more detailed explanation of the
 * metadata used to achieve this.
 */
export class CrossServiceContextPropagator {
  private static readonly propagator = new ContextPropagator<ICrossServiceContext>()

  public static runWithContext<T>(context: ICrossServiceContext, callback: () => T): T {
    return this.propagator.runWithContext(context, callback)
  }

  public static getContext(): ICrossServiceContext | undefined {
    return this.propagator.getContext() ?? undefined
  }

  /**
   * [ORG_ROUTE_METRICS]: Set the organization ID on the current context.
   */
  public static setOrganizationId(organizationId: string) {
    const context = this.getContext()
    if (context) {
      context.organizationId = organizationId
    }
  }
}
