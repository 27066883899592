import { PermissionSetType, RiderPermissionSet } from '../sets'
import { RealizePermissionSet } from '../sets/RealizePermissions'
import { AdminOperatorRole } from './AdminOperatorRole'

export const AccessToRealizeRole: PermissionSetType[] = [
  ...AdminOperatorRole,

  // TODO: Replace rider permission set with more limited permission sets
  RiderPermissionSet.Rider,

  RealizePermissionSet.Access,
]
