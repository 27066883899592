import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'

export enum CaseStatusStatus {
  Active = 'active',
  Archived = 'archived',
}

export enum CaseStatusTheme {
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
  Blue = 'blue',
  Purple = 'purple',
  CoolGray = 'coolGray',
  Gray = 'gray',
}

export enum CaseStatusStage {
  Todo = 'todo',
  InProgress = 'inProgress',
  Done = 'done',
}

export interface IListCaseStatusQueryParams extends IListModelQueryParamValues {
  status?: CaseStatusStatus
  caseTypeId?: string
}

export interface ICaseStatusPatchBody {
  label?: string
  theme?: CaseStatusTheme
  order?: string
  status?: CaseStatusStatus
  stage?: CaseStatusStage
  riderInterfaceLabel?: string | null
  replacementId?: string
}
export interface ICaseStatusPostBody {
  label: string
  key: string
  caseTypeId: string
  theme: CaseStatusTheme
  order: string
  stage: CaseStatusStage
  riderInterfaceLabel?: string | null
}

export interface ICaseStatusResponse extends IModelJsonValues, ICaseStatusPostBody {
  status: CaseStatusStatus
  stage: CaseStatusStage
}
