import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IDriverBreakListQueryParams, IDriverBreakResponse, Resources } from '../types'

export class DriverBreakResource extends GenericResource<
  IDriverBreakResponse,
  IDriverBreakListQueryParams,
  null,
  null
> {
  public get = this.buildGet()
  public list = this.buildList()

  constructor(agent: AxiosInstance) {
    super(Resources.DriverBreaks, agent)
  }

  public async cancel(driverBreakId: string): Promise<IDriverBreakResponse> {
    const res = await this.agent.post<IDriverBreakResponse>(`${Resources.DriverBreaks}/${driverBreakId}/cancellation`)
    return res.data
  }
}
