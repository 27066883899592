import { ScheduledRequestFlexWindowType } from '../../types'
import {
  EngineNullOverride,
  IConstraintOverrideRule,
  IOverrideParameters,
  OverrideParametersType,
  OverrideRuleType,
} from './ConstraintOverrideTypes'

export class ConstraintOverrideRuleBuilder {
  public static buildForEstimates(
    overrideParameters: IOverrideParameters,
    service: {
      organizationId: string
      maxConstraintOverride: number
      scheduledRequestFlexWindowType: ScheduledRequestFlexWindowType
    }
  ): IConstraintOverrideRule {
    switch (overrideParameters.type) {
      /**
       * From `GET /estimates/nextAvailable` and `GET /estimates/request` calls.
       * We don't use overrides in this case.
       */
      case OverrideParametersType.NoOverrides:
        return EngineNullOverride

      /**
       * From `GET /estimates/.../override` calls when ConfigurableOverrides is not active.
       * This is the standard flow:
       *   - we only override matched requests
       */
      case OverrideParametersType.StandardOverrides:
        return {
          type: OverrideRuleType.Estimate,
          organizationId: service.organizationId,
          maxOverride: service.maxConstraintOverride,
          includeCurrentRequest: false,
          includeMatchedRequests: true,
          excludeRequestIds: new Set(),
          dutyIdsToExtend: new Set(),
        }

      /**
       * From `GET /estimates/.../override` calls when ConfigurableOverrides is active.
       * This is the configurable flow:
       *   - matched requests can be included
       *   - the current request can be included if we are using guaranteed windows
       *   - specific matched requests can also be excluded
       */
      case OverrideParametersType.ConfigurableOverrides:
        return {
          type: OverrideRuleType.Estimate,
          organizationId: service.organizationId,
          maxOverride: service.maxConstraintOverride,
          // We can only override the current request if we are using guaranteed windows
          // This should be controlled from the frontend but we add an extra precaution here
          includeCurrentRequest:
            overrideParameters.includeCurrentRequest &&
            service.scheduledRequestFlexWindowType === ScheduledRequestFlexWindowType.ManualWithNegotiation,
          includeMatchedRequests: overrideParameters.includeMatchedRequests,
          excludeRequestIds: new Set(overrideParameters.excludeRequestIds),
          dutyIdsToExtend: new Set(overrideParameters.dutyIdsToExtend),
        }

      /**
       * This fourth case occurs from `POST /requests` calls.
       * Virtual estimates are created as part of the request flow to rank dispatchers.
       * At that point we don't have the original override parameters used to get an estimate,
       * but we have the more accurate existingOverrides.
       */
      case OverrideRuleType.Request:
        return overrideParameters
    }
  }
}
