import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IRouteOptimizationGlobalOptimizationRequest,
  IRouteOptimizationGlobalOptimizationResponse,
  IRouteOptimizationMatchRequestRequest,
  IRouteOptimizationMatchRequestResponse,
  IRouteOptimizationModifyDutyRequest,
  IRouteOptimizationModifyDutyResponse,
  IRouteOptimizationOptimizeDutyRequest,
  IRouteOptimizationOptimizeDutyResponse,
  Resources,
} from '../types'

// This class encapsulates the endpoints provided by the route optimization service. However, this service doesn't really fit the
// CRUD model and provides multiple POST endpoints. So, the default POST type of this class is for the Global Optimization endpoint,
// and additional POST methods are provided as additional functions.
export class RouteOptimizationResource extends GenericResource<
  IRouteOptimizationGlobalOptimizationResponse,
  never,
  never,
  IRouteOptimizationGlobalOptimizationRequest
> {
  public async globallyOptimize(
    body: IRouteOptimizationGlobalOptimizationRequest
  ): Promise<IRouteOptimizationGlobalOptimizationResponse> {
    return (
      await this.agent.post<IRouteOptimizationGlobalOptimizationResponse>(`${this.resource}/globallyOptimize`, body)
    ).data
  }

  public async matchRequest(
    body: IRouteOptimizationMatchRequestRequest
  ): Promise<IRouteOptimizationMatchRequestResponse> {
    return (await this.agent.post<IRouteOptimizationMatchRequestResponse>(`${this.resource}/matchRequest`, body)).data
  }

  public async optimizeDuty(
    body: IRouteOptimizationOptimizeDutyRequest
  ): Promise<IRouteOptimizationOptimizeDutyResponse> {
    return (await this.agent.post<IRouteOptimizationOptimizeDutyResponse>(`${this.resource}/optimizeDuty`, body)).data
  }

  public async modifyDuty(body: IRouteOptimizationModifyDutyRequest): Promise<IRouteOptimizationModifyDutyResponse> {
    return (await this.agent.post<IRouteOptimizationModifyDutyResponse>(`${this.resource}/modifyDuty`, body)).data
  }

  constructor(agent: AxiosInstance) {
    super(Resources.RouteOptimization, agent)
  }
}
