import { FormContentStage, IFormContentResponse } from '@sparelabs/engage-client'
import { MarkdownVariableRenderer } from '@sparelabs/markdown-utils'
import { Markdown, ViewFieldContainer } from '@sparelabs/web-ui-components'
import { sortBy } from 'lodash'
import React from 'react'
import { useAsync } from 'react-use'
import { FormContentVariables } from 'src/helpers/FormContentVariables'
import styled from 'styled-components'
import { useFormContext } from '../../FormContext'

// Match regular UI font-size
const MarkdownContainer = styled(ViewFieldContainer)`
  font-size: 15px;
`
interface IRendererProps {
  postSubmitContent: IFormContentResponse[]
  caseId?: string
}

const FormContentRenderer = ({ caseId, postSubmitContent }: IRendererProps) => {
  const {
    loading,
    error,
    value: renderer,
  } = useAsync(async () => MarkdownVariableRenderer.build(FormContentVariables, { caseId }), [caseId])

  return (
    <MarkdownContainer>
      {loading
        ? 'Loading...'
        : error || !renderer
        ? null
        : postSubmitContent.map((formContent) => (
            <Markdown key={formContent.id} skipHtml={false}>
              {renderer.render(formContent.content)}
            </Markdown>
          ))}
    </MarkdownContainer>
  )
}

interface IProps {
  fallback?: JSX.Element
}

export const PostSubmitFormSection = ({ fallback }: IProps): JSX.Element | null => {
  const { caseId, form } = useFormContext()

  const postSubmitContent = sortBy(
    form.formContents.filter((content) => content.stage === FormContentStage.PostSubmit),
    (content) => content.order
  )
  if (!postSubmitContent.length) {
    return fallback ?? null
  }

  return <FormContentRenderer caseId={caseId} postSubmitContent={postSubmitContent} />
}
