import { IFailure, ISuccess, SuccessOrFailure } from './SuccessOrFailure'

/**
 * A version of SuccessOrFailureChain capable of executing asynchronous operations
 */
export class AsyncSuccessOrFailureChain<InputType, ReasonType = string> {
  constructor(private readonly input: Promise<SuccessOrFailure<InputType, ReasonType>>) {}

  public ifSuccessAsync<OutputType>(
    callback: (input: InputType) => Promise<SuccessOrFailure<OutputType, ReasonType>>
  ): AsyncSuccessOrFailureChain<OutputType, ReasonType> {
    return new AsyncSuccessOrFailureChain(this.evaluate(callback))
  }

  public async result(): Promise<SuccessOrFailure<InputType, ReasonType>> {
    return this.input
  }

  private async evaluate<OutputType>(
    callback: (input: InputType) => Promise<SuccessOrFailure<OutputType, ReasonType>>
  ): Promise<ISuccess<OutputType> | IFailure<ReasonType>> {
    // short-circuit failures
    const input = await this.input
    return input.success ? callback(input.value) : input
  }
}
