import { WebColors } from '@sparelabs/colors'
import styled, { css } from 'styled-components'
import { AbstractButton } from './AbstractButton'

export const flatButtonStyle = css`
  text-align: left;

  &:hover {
    background-color: ${WebColors.backgroundPrimaryHover};
  }
`

export const FlatButton = styled(AbstractButton)`
  ${flatButtonStyle}
`
