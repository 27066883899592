import { ICustomFieldResponse } from '@sparelabs/api-client'
import { IFormContentResponse } from '@sparelabs/engage-client'

export const isCustomField = (
  customField: ICustomFieldResponse | IFormContentResponse
): customField is ICustomFieldResponse => 'key' in customField && 'type' in customField && 'label' in customField

export const isFormContent = (
  formContent: IFormContentResponse | ICustomFieldResponse
): formContent is IFormContentResponse => 'content' in formContent
