import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { Resources } from '../types'
import { IFeatureEntitlementResponse } from '../types/FeatureFlagTypes'

export class FeatureEntitlementResource extends GenericResource<never, never, never, never> {
  constructor(agent: AxiosInstance) {
    super(Resources.FeatureEntitlement, agent)
  }

  public async getFeatureEntitlement(organizationId: string): Promise<IFeatureEntitlementResponse> {
    const res = await this.agent.get<IFeatureEntitlementResponse>(`featureEntitlement/${organizationId}`)
    return res.data
  }
}
