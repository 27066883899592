import { BulkMessagePermissionSet, FarePassPermissionSet, PermissionSetType, RiderPermissionSet } from '../sets'
import { AnnouncementPermissionSet } from '../sets/AnnouncementPermissions'
import { DriverUserPermissionSet } from '../sets/DriverUserPermissions'
import { FavoriteLocationPermissionSet } from '../sets/FavoriteLocationPermissions'
import { GroupPermissionSet } from '../sets/GroupPermissions'
import { NotificationSettingPermissionSet } from '../sets/NotificationSettingPermissions'
import { PromoPermissionSet } from '../sets/PromoPermissions'
import { RequestPermissionSet } from '../sets/RequestPermissions'
import { RiderUserPermissionSet } from '../sets/RiderUserPermissions'
import { UserBanPermissionSet } from '../sets/UserBanPermissions'
import { BookingAgentRole } from './BookingAgentRole'
import { SchedulerRole } from './SchedulerRole'

export const OperationsManagerRole: PermissionSetType[] = [
  ...BookingAgentRole,
  ...SchedulerRole,

  // TODO: Replace rider permission set with more limited permission sets
  RiderPermissionSet.Rider,

  RiderUserPermissionSet.Import,
  RiderUserPermissionSet.Export,

  DriverUserPermissionSet.Import,
  DriverUserPermissionSet.Export,

  GroupPermissionSet.Write,
  GroupPermissionSet.ImportMembership,
  GroupPermissionSet.ExportMembership,

  RequestPermissionSet.ExportRequestRecurrences,
  RequestPermissionSet.Rematch,
  RequestPermissionSet.ManualIntervention,
  RequestPermissionSet.EstimateWithOverrides,

  FavoriteLocationPermissionSet.Import,

  AnnouncementPermissionSet.Write,

  UserBanPermissionSet.Write,

  PromoPermissionSet.Export,

  NotificationSettingPermissionSet.View,

  BulkMessagePermissionSet.View,
  BulkMessagePermissionSet.Write,

  FarePassPermissionSet.EditAllocation,
]
