interface IUser {
  firstName: string | null
  lastName: string | null
  phoneNumber: string | null
  email: string | null
}
export class UserUtils {
  public static getUserName({ firstName, lastName, email, phoneNumber }: IUser): string | null {
    if (firstName) {
      return lastName ? `${firstName} ${lastName}` : firstName
    } else if (email) {
      return email
    }
    return phoneNumber
  }
}
