import { SuccessOrFailure, successOrFailureFromPromise } from '../types'

export interface IStopwatch {
  timeOperation<T>(
    callback: () => Promise<T>
  ): Promise<{ elapsedMilliseconds: number; result: SuccessOrFailure<T, Error> }>
}

export class Stopwatch {
  public static async timeOperation<T>(
    callback: () => Promise<T>
  ): Promise<{ elapsedMilliseconds: number; result: SuccessOrFailure<T, Error> }> {
    const startMs = Date.now()
    const result = await successOrFailureFromPromise(callback)
    return { elapsedMilliseconds: Date.now() - startMs, result }
  }
}
