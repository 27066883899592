import { ApiError, ErrorName, LocalizeFunction, NotFoundResource } from '@sparelabs/error-types'
import { IEstimateOutput } from './EstimateOperationTypes'

export interface IGenericErrorResponse {
  name: string
  message: string
  metadata?: Record<string, any>
}

export interface INotFoundErrorResponse extends IGenericErrorResponse {
  name: ErrorName.NotFoundError
  resource: NotFoundResource
}

export type IErrorResponse = IGenericErrorResponse | INotFoundErrorResponse

// This lives here instead of @sparelabs/error-types because we don't have access to the
// IEstimateOutput type in that package and don't want to bloat it by adding @sparelabs/api-client to it

export class NoDriversAvailableError<T = undefined> extends ApiError<T> {
  constructor(
    messageInput: LocalizeFunction<T>,
    public readonly metadata: { estimateId: string; theoreticalEstimate?: IEstimateOutput },
    localizeOptions?: T,
    serializeMetadata?: boolean
  ) {
    super(400, messageInput, metadata, localizeOptions, serializeMetadata)
  }
}
