import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IRouteListQueryParams, IRoutePatchBody, IRoutePostBody, IRouteResponse, Resources } from '../types'

export class RouteResource extends GenericResource<
  IRouteResponse,
  IRouteListQueryParams,
  Partial<IRoutePostBody>,
  IRoutePatchBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  public del = this.buildDel()
  constructor(agent: AxiosInstance) {
    super(Resources.Routes, agent)
  }
}
