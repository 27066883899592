import axios from 'axios'
import { graphqlQuery } from './graphqlQuery'
import { GraphqlOperationName, ICountry, ILoadCountriesResponse, ILoadCountryResponse, IResponseError } from './types'

const GRAPHQL_ENDPOINT = '/v1/countries/graphql'

export class CountryLoader {
  private countriesCache: Record<string, ICountry[]> = {}
  private countryCache: Record<string, ICountry> = {}

  constructor(private readonly host: string) {}

  public async loadCountries(locale: string): Promise<ICountry[]> {
    if (this.countriesCache[locale]) {
      return this.countriesCache[locale]
    }

    const response = await axios.post<ILoadCountriesResponse | IResponseError>(this.host + GRAPHQL_ENDPOINT, {
      query: graphqlQuery,
      operationName: GraphqlOperationName.Countries,
      variables: {
        locale: this.cleanLocale(locale),
      },
    })
    const countries: ILoadCountriesResponse | IResponseError = response.data

    if (!('data' in countries) && 'errors' in countries) {
      throw new Error(this.extractErrorMessage(countries))
    }

    this.countriesCache[locale] = countries.data.countries
    return countries.data.countries
  }

  public async loadCountry(locale: string, countryCode: string): Promise<ICountry> {
    const cacheKey = `${locale}-${countryCode}`
    if (this.countryCache[cacheKey]) {
      return this.countryCache[cacheKey]
    }

    const response = await axios.post<ILoadCountryResponse | IResponseError>(this.host + GRAPHQL_ENDPOINT, {
      query: graphqlQuery,
      operationName: GraphqlOperationName.Country,
      variables: {
        countryCode,
        locale: this.cleanLocale(locale),
      },
    })

    const country: ILoadCountryResponse | IResponseError = response.data

    if (!('data' in country) && 'errors' in country) {
      throw new Error(this.extractErrorMessage(country))
    }

    this.countryCache[cacheKey] = country.data.country
    return country.data.country
  }

  private cleanLocale(locale: string) {
    return locale.replace(/-/, '_').toUpperCase()
  }

  private extractErrorMessage(error: IResponseError) {
    return error.errors.map((error) => error.message).join('; ')
  }
}
