import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IAppWebhookPatchBody,
  IAppWebhookPostBody,
  IAppWebhookResponse,
  IListAppWebhookQueryParams,
  Resources,
  WebhookBody,
} from '../types'

export class AppWebhookResource extends GenericResource<
  IAppWebhookResponse,
  IListAppWebhookQueryParams,
  IAppWebhookPatchBody,
  IAppWebhookPostBody
> {
  constructor(agent: AxiosInstance) {
    super(Resources.AppWebhook, agent)
  }

  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  public del = this.buildDel()

  public async sendWebhook(organizationId: string, body: WebhookBody): Promise<void> {
    await this.agent.post(`${Resources.AppWebhook}/send`, { organizationId, body })
  }

  public async sendWebhookToApp<T>(appId: string, organizationId: string, body: WebhookBody): Promise<T> {
    const response = await this.agent.post<T>(`${Resources.AppWebhook}/send`, {
      appId,
      organizationId,
      body,
    })
    return response.data
  }
}
