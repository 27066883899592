import React from 'react'
import { Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Footer } from 'src/components'
import { FormPage } from 'src/pages/Form/FormPage'
import { NotFoundRoute } from 'src/routes/NotFoundRoute'
import { OldFormRoutes } from 'src/routes/OldFormRoutes'

const TOAST_DISMISS_DELAY_MS = 5000

export const AllRoutes: React.FC = () => (
  <BrowserRouter>
    <ToastContainer
      position='bottom-left'
      autoClose={TOAST_DISMISS_DELAY_MS}
      closeButton={false}
      hideProgressBar={true}
      toastClassName='custom-toast'
      bodyClassName='custom-toast-body'
    />
    <Switch>
      <Route path='/forms/:orgId/:caseTypeKey' component={OldFormRoutes} />
      <Route path='/forms' component={FormPage} />
      <Route component={NotFoundRoute} />
    </Switch>
    <Footer />
  </BrowserRouter>
)
