export const MIN_PASSWORD_LENGTH = 12
export const MAX_PASSWORD_LENGTH = 1024

export class PasswordValidator {
  public static isValid(password: string): boolean {
    return (
      this.isLongEnough(password) &&
      this.isWithinCharacterLimit(password) &&
      this.hasUpperCaseLetters(password) &&
      this.hasLowerCaseLetters(password) &&
      this.hasNumbers(password) &&
      this.hasSpecialCharacters(password)
    )
  }

  public static isLongEnough(password: string): boolean {
    return password.length >= MIN_PASSWORD_LENGTH
  }

  public static hasUpperCaseLetters(password: string): boolean {
    return password !== password.toLowerCase()
  }

  public static hasLowerCaseLetters(password: string): boolean {
    return password !== password.toUpperCase()
  }

  public static hasNumbers(password: string): boolean {
    return /\d/.test(password)
  }

  public static hasSpecialCharacters(password: string): boolean {
    // eslint-disable-next-line
    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)
  }

  public static isWithinCharacterLimit(password: string): boolean {
    return password.length <= MAX_PASSWORD_LENGTH
  }
}
