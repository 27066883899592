import moment from 'moment-timezone'

export const dateFormat = 'YYYY-MM-DD'
export const timeFormat = 'HH:mm'

export class DateUtils {
  /**
   * Returns unix timestamp in seconds
   */
  public static toTs(date: Date): number {
    return Math.floor(date.getTime() / 1000)
  }

  public static enumerateDates(startDate: string, endDate: string): string[] {
    const dates = []
    // choice of timezone shouldn't matter as long as we're consistent
    const endDateMoment = moment.utc(endDate)
    for (
      let currentDateMoment = moment.utc(startDate);
      currentDateMoment.isSameOrBefore(endDateMoment);
      currentDateMoment.add(1, 'day')
    ) {
      dates.push(currentDateMoment.format(dateFormat))
    }
    return dates
  }
}
