export type Latitude = number
export type Longitude = number
export type GeoJsonCoordinate = [Longitude, Latitude]

export enum GeometryType {
  Point = 'Point',
  LineString = 'LineString',
  Polygon = 'Polygon',
  MultiPoint = 'MultiPoint',
  MultiLineString = 'MultiLineString',
  MultiPolygon = 'MultiPolygon',
}

export interface IPoint {
  type: GeometryType.Point
  coordinates: GeoJsonCoordinate
}

export interface ILineString {
  type: GeometryType.LineString
  coordinates: GeoJsonCoordinate[]
}

export interface IPolygon {
  type: GeometryType.Polygon
  coordinates: GeoJsonCoordinate[][]
}

export interface IMultiPoint {
  type: GeometryType.MultiPoint
  coordinates: GeoJsonCoordinate[]
}

export type IGeometry = IPolygon | ILineString | IPoint | IMultiPoint

export enum FeatureType {
  FeatureCollection = 'FeatureCollection',
  Feature = 'Feature',
}

export interface IFeature {
  type: FeatureType.Feature
  geometry: IGeometry
  properties?: Record<string, any>
}

export interface IFeatureCollection {
  type: FeatureType.FeatureCollection
  features: IFeature[]
}

export interface IAddressPointPair {
  address: string
  location: IPoint
}

export enum UTurnPenalty {
  None = 'none',
  Normal = 'normal',
  High = 'high',
}

export enum VehicleClass {
  Car = 'car',
  Bus = 'bus',
}

export enum VehicleClassMaxWeight {
  Car = 2.5, // Default max_weight value for mapbox directions api
  Bus = 5.0,
}
