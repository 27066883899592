import { IListModelQueryParamValues } from '@sparelabs/http-client-utils'
import { IBin, IPointWithTime } from './MetricsTypes'
import { IOnTimePerformanceMetrics } from './SharedMetricsTypes'
import { Interval } from './TimeTypes'

export enum ServiceMetricType {
  Request = 'request',
  AllTimeRequest = 'allTimeRequest',
  Rider = 'rider',
  RiderCount = 'riderCount',
  Charge = 'charge',
  Service = 'service',
  Estimate = 'estimate',
  Review = 'review',
  OnTimePerformance = 'onTimePerformance',
  OpenFleetRequest = 'openFleetRequest',
}

export type ServiceMetricOutputType =
  | IRequestMetrics
  | IAllTimeRequestMetrics
  | IRiderMetrics
  | IRiderCountMetrics
  | IChargeMetrics
  | IServiceMetrics
  | IEstimateMetrics
  | IReviewMetrics
  | IOnTimePerformanceMetrics
  | IOpenFleetRequestMetrics

interface IServiceMetricOutput<T extends ServiceMetricType, D extends ServiceMetricOutputType> {
  type: T
  data: D
}

export type IServiceMetric =
  | IServiceMetricOutput<ServiceMetricType.Request, IRequestMetrics>
  | IServiceMetricOutput<ServiceMetricType.AllTimeRequest, IAllTimeRequestMetrics>
  | IServiceMetricOutput<ServiceMetricType.Rider, IRiderMetrics>
  | IServiceMetricOutput<ServiceMetricType.RiderCount, IRiderCountMetrics>
  | IServiceMetricOutput<ServiceMetricType.Charge, IChargeMetrics>
  | IServiceMetricOutput<ServiceMetricType.Service, IServiceMetrics>
  | IServiceMetricOutput<ServiceMetricType.Estimate, IEstimateMetrics>
  | IServiceMetricOutput<ServiceMetricType.Review, IReviewMetrics>
  | IServiceMetricOutput<ServiceMetricType.OnTimePerformance, IOnTimePerformanceMetrics>
  | IServiceMetricOutput<ServiceMetricType.OpenFleetRequest, IOpenFleetRequestMetrics>

export enum ServiceMetricScope {
  All = 'all',
  Active = 'active',
  Archived = 'archived',
}

export interface IListServiceMetricsQueryParams extends IListModelQueryParamValues {
  metricTypes: ServiceMetricType[]
  fromTs: number
  toTs: number
  timezone?: string
  interval?: Interval
  serviceIds?: string[] | ServiceMetricScope
}

export interface IListServiceMetricsResponse {
  metrics: IServiceMetric[]
}

export interface IRequestMetrics {
  /**
   * Number of requests in terminal status, which means their
   * status will not change anymore after this.
   */
  numOfTerminalRequests: number
  numOfCompletedRequests: number
  numOfCancelledRequests: number
  numOfNoDriversAvailableRequests: number
  numOfCompletedBoardings: number
  numOfNoDriversAvailableBoardings: number
  numOfNoShows: number
  totalTravelDurationSeconds: number
  totalTravelDistanceM: number
  completedBoardingsBins: IBin[]
  cancelledBoardingsBins: IBin[]
  travelDurationSecondsBins: IBin[]
  waitTimeSecondsBins: IBin[]
  numOfCancelledBoardings: number
  numOfNoShowBoardings: number
  uniqueRiderIds: string[]
  avgWaitTimeSeconds: number
  medianWaitTimeSeconds: number
  numBookingsAdminInterface: number
  numBookingsRiderInterface: number
  numBookingsRiderWebInterface: number
  numBookingsDriverInterface: number
  numBookingsIvrInterface: number
  pickupLocations: IPointWithTime[]
  dropoffLocations: IPointWithTime[]
  timezone: string
}

export interface IAllTimeRequestMetrics {
  ridersTaken1Trip: number
  ridersTakenOver1Trip: number
  numNewRiders: number
}

export interface IRiderMetrics {
  ridersCreatedBins: IBin[]
  numRidersCreated: number
}

export interface IRiderCountMetrics {
  ridersCreated: number
}

export interface IChargeMetrics {
  revenueAmountBins: IBin[]
  refundAmountBins: IBin[]
  revenueAmount: number
  refundAmount: number
  farePassRevenueAmount: number
  numFailedCharges: number
}

export interface IServiceMetrics {
  serviceHoursBins: IBin[]
  totalServiceHoursInSeconds: number
}

export interface IEstimateMetrics {
  waitTimeSecondsBins: IBin[]
}

export interface IReviewMetrics {
  meanReviewRating: number
}

export interface IOpenFleetRequestMetrics {
  numOfTerminalOpenFleetRequests: number
  numOfTerminalOpenFleetBoardings: number
  numOfCompletedOpenFleetRequests: number
  numOfCancelledOpenFleetRequests: number
  numOfCompletedOpenFleetBoardings: number
  numOfCancelledOpenFleetBoardings: number
  timezone: string
}
