import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum DutyPermissionSet {
  View = 'duty_view',
  Manage = 'duty_manage',
  CompleteSlot = 'duty_complete_slot',
  ViewVehicleLocation = 'duty_view_vehicle_location',
  StartDriverConversation = 'duty_start_driver_conversation',
  ViewDriverConversation = 'duty_view_driver_conversation',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const dutyPermissionSetGrouping: IPermissionSetGrouping<DutyPermissionSet> = {
  key: PermissionSetGroupingKey.Duty,
  active: {
    [DutyPermissionSet.View]: [
      PermissionType.GET_ANY_DUTY,
      PermissionType.LIST_ANY_DUTY,
      PermissionType.LIST_ANY_SLOT,
      PermissionType.GET_ANY_SLOT,
      PermissionType.LIST_ANY_ROUTE_ASSIGNMENT,
      PermissionType.LIST_ANY_ROUTE_ASSIGNMENT_STATE,
      PermissionType.GET_ANY_DRIVER_BREAK,
    ],
    [DutyPermissionSet.Manage]: [
      PermissionType.POST_ANY_DUTY,
      PermissionType.PATCH_ANY_DUTY,
      PermissionType.CANCEL_ANY_DUTY,
      PermissionType.IMPORT_ANY_DUTY,
      PermissionType.POST_ANY_MULTIPLE_DUTIES,
      PermissionType.DELETE_ANY_DUTY,
      PermissionType.DELETE_ANY_MULTIPLE_DUTIES,
      PermissionType.POST_ANY_ROUTE_ASSIGNMENT,
      PermissionType.DELETE_ANY_ROUTE_ASSIGNMENT,
      PermissionType.CANCEL_ANY_DRIVER_BREAK,
      PermissionType.POST_ANY_TRIGGERED_OPTIMIZATION,
    ],
    [DutyPermissionSet.ViewVehicleLocation]: [
      PermissionType.GET_ANY_VL,
      PermissionType.GET_ANY_LAST_DUTY_VL,
    ],
    [DutyPermissionSet.CompleteSlot]: [
      PermissionType.COMPLETE_ANY_SLOT,
    ],
    [DutyPermissionSet.StartDriverConversation]: [
      PermissionType.POST_DISPATCHER_COMMS_ACCESS_TOKEN, // Start conversation
    ],
    [DutyPermissionSet.ViewDriverConversation]: [
      PermissionType.GET_ANY_DUTY_CONVERSATION, // View conversation messages
    ]
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
