import { Change, diffLines, diffWords } from 'diff'

export interface IDifferenceLine {
  previous: IDifferenceItem[]
  current: IDifferenceItem[]
  differenceType: LineDifferenceType
}

export interface IDifferenceItem {
  value: string
  different: boolean
}

interface ILine {
  previous: string
  current: string
  differenceType: LineDifferenceType
}

export enum LineDifferenceType {
  Added = 'added',
  Removed = 'removed',
  Modified = 'modified',
  Unchanged = 'unchanged',
}

export class DifferenceCalculator {
  /**
   * Utilizing the O(ND) difference algorithm implemented by JSDiff calculates that line and word differences between two strings.
   */
  public static calculate(previousValue: string, currentValue: string): IDifferenceLine[] {
    const lines = this.calculateLineDifferences(previousValue, currentValue)
    return lines.map(this.calculateWordDifferences)
  }

  private static calculateLineDifferences(previousValue: string, currentValue: string): ILine[] {
    const differences = this.diffLinesWrapper(previousValue, currentValue)

    const result: ILine[] = []

    for (const [index, difference] of differences.entries()) {
      const lines = this.parseLines(difference, differences[index + 1])

      // When previous line is "removed" and next line is "added" then skip since it was already handled last loop
      if (difference.added && differences[index - 1]?.removed) {
        continue
      }

      // Unchanged lines
      if (!difference.removed && !difference.added) {
        result.push(
          ...lines.map((line) => ({ previous: line, current: line, differenceType: LineDifferenceType.Unchanged }))
        )
      }

      // When "removed" and next line is not "added" then these lines are considered completely removed
      if (difference.removed && !differences[index + 1]?.added) {
        result.push(
          ...lines.map((line) => ({ previous: line, current: '', differenceType: LineDifferenceType.Removed }))
        )
      }

      // When "added" and previous line is not "removed" then these lines are considered brand new
      if (difference.added && !differences[index - 1]?.removed) {
        result.push(...lines.map((line) => ({ previous: '', current: line, differenceType: LineDifferenceType.Added })))
      }

      // When "removed" and next line is "added" then some lines are considered modified
      if (difference.removed && differences[index + 1]?.added) {
        const nextLines = this.parseLines(differences[index + 1], differences[index + 2])

        for (let count = 0; count < Math.max(lines.length, nextLines.length); count++) {
          result.push({
            previous: lines[count] ?? '',
            current: nextLines[count] ?? '',
            differenceType: this.getDifferenceType(lines[count], nextLines[count]),
          })
        }
      }
    }

    return result
  }

  private static calculateWordDifferences(line: ILine): IDifferenceLine {
    const result: IDifferenceLine = {
      previous: [],
      current: [],
      differenceType: line.differenceType,
    }

    const differences = diffWords(line.previous, line.current, { ignoreWhitespace: false })

    for (const difference of differences) {
      if (difference.removed) {
        result.previous.push({ value: difference.value, different: true })
      }
      if (difference.added) {
        result.current.push({ value: difference.value, different: true })
      }
      if (!difference.added && !difference.removed) {
        result.previous.push({ value: difference.value, different: false })
        result.current.push({ value: difference.value, different: false })
      }
    }

    return result
  }

  /**
   * Wrapper around `diffLines` injects missing new line removals and additions.
   */
  private static diffLinesWrapper(previousValue: string, currentValue: string): Change[] {
    const differences = diffLines(previousValue.trim(), currentValue.trim(), {
      ignoreWhitespace: false,
      newlineIsToken: true,
      ignoreCase: false,
    }).filter((difference) => {
      // Filter out any empty lines that are considered unchanged. This is to prevent unnecessary empty lines from being added to the result.
      const isChanged = difference.added || difference.removed
      const isNewline = difference.value === '\n'
      return isChanged || !isNewline
    })

    const result: Change[] = []

    for (const [index, difference] of differences.entries()) {
      const previousDifference = differences[index - 1]
      const nextDifference = differences[index + 1]

      const isNewLineSurrounded = previousDifference?.value?.endsWith('\n') && nextDifference?.value?.startsWith('\n')

      if (difference.added && isNewLineSurrounded) {
        result.push({ count: 1, added: undefined, removed: true, value: '\n' })
      }

      result.push(difference)

      if (difference.removed && isNewLineSurrounded) {
        result.push({ count: 1, added: true, removed: undefined, value: '\n' })
      }
    }

    return result
  }

  /**
   * Parses a change value into a list of lines and considers all scenarios where a new line character would cause an unnecessary empty line.
   */
  private static parseLines(difference: Change, nextDifference?: Change): string[] {
    const lines = difference.value.split('\n')
    const isChanged = difference.added || difference.removed
    const isLastDifference = nextDifference === undefined

    if (lines.every((line) => line === '') && isChanged) {
      lines.pop()
      return lines
    }

    if (difference.value.startsWith('\n') && (!isChanged || isLastDifference)) {
      lines.shift()
    }

    if (difference.value.endsWith('\n')) {
      lines.pop()
    }

    return lines
  }

  private static getDifferenceType(previous?: string, current?: string): LineDifferenceType {
    if (previous === current) {
      return LineDifferenceType.Unchanged
    }

    if (previous === undefined) {
      return LineDifferenceType.Added
    }

    if (current === undefined) {
      return LineDifferenceType.Removed
    }

    return LineDifferenceType.Modified
  }
}
