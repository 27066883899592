import { Logger } from './Logger'
import { ILogger } from './LogTypes'

/**
 * A convenience class to easily inject loggers into classes.
 *
 * Meant to be extended by implementations that require a logger instance.
 */
export abstract class AbstractLoggingClass {
  protected readonly logger: ILogger

  constructor(parentLogger: ILogger) {
    this.logger = Logger.build(this.constructor.name, { parentLogger })
  }
}
