import { colors } from './SharedColors'

// Colors for light mode in Spare Driver and Rider
// HEX codes are all defined in SharedColors.ts, and must be referenced from there
// Additionally, all keys added here should also be added to DarkModeColors.ts
export const lightColors = {
  // BACKGROUNDS
  backgroundPrimary: colors.white,
  backgroundSecondary: colors.coolGray05,
  backgroundSecondaryAlt: colors.coolGray05,
  backgroundTertiary: colors.coolGray10,
  backgroundDisabled: colors.gray30,

  // CARDS
  cardBackgroundPrimary: colors.white,
  cardBackgroundInverted: colors.gray90,

  // PROGRESS BAR
  progressFillPrimary: colors.white,
  progressBackgroundPrimary: colors.gray70,

  // TEXT
  textPrimary: colors.coolGray90,
  textSecondary: colors.coolGray50,
  textDisabled: colors.coolGray30,
  textInverted: colors.white,

  // INTERACTIVE ELEMENTS (BLUES)
  interactivePrimary: colors.blue50,
  interactiveSecondary: colors.gray70,
  interactiveLowContrast: colors.blue10,
  interactiveHighContrast: colors.blue70,
  interactiveDisabled: colors.gray30, // TODO: verify if this doesn't cause unexpected issues
  interactiveAlternative: colors.coolGray50,

  // POSITIVE (GREENS)
  positivePrimary: colors.green50,
  positiveLowContrast: colors.green10,
  positiveHighContrast: colors.green70,
  positiveDisabled: colors.gray30,

  // NEGATIVE (REDS)
  negativePrimary: colors.red50,
  negativeLowContrast: colors.red10,
  negativeHighContrast: colors.red70,
  negativeDisabled: colors.gray30,

  // WARNING (ORANGES)
  warningPrimary: colors.yellow30,
  warningLowContrast: colors.yellow10,
  warningHighContrast: colors.yellow70,
  warningDisabled: colors.gray30,

  // PICKUPS (GREENS)
  pickupPrimary: colors.green50,
  pickupDisabled: colors.green30,

  // DROPOFFS (REDS)
  dropoffPrimary: colors.red50,
  dropoffDisabled: colors.red30,

  // NOTES
  notePrimary: colors.blue50,
  noteDisabled: colors.gray30,

  // ICONS
  circleIconInner: colors.white,
  favoriteHome: colors.blue50,
  favoriteWork: colors.purple50,
  favoriteOther: colors.green50,
  locationStopSuggestion: colors.purple50,
  locationDefaultSuggestion: colors.red30,
  mapMarker: colors.coolGray50,

  // RADIO BUTTON
  radioButtonUnfilled: colors.coolGray30,
  radioButtonFilled: colors.blue50,

  // SWITCH
  switchThumb: colors.white,
  switchTrackOff: colors.gray30,

  // SPINNER/LOADING
  spinner: colors.coolGray50,
  spinnerMessage: colors.coolGray90,
  loadingPrimary: colors.coolGray50,
  loadingInverted: colors.white,
  loadingMessage: colors.coolGray50,

  // PREDICTED DEMAND THEME
  routeStopSlot: colors.blue50,

  // SHADOW SETTINGS
  shadowColor: colors.coolGray95,
  shadowOpacity: colors.opacity20,
  shadowRadius: colors.radius10,

  // MISC
  border: colors.coolGray10,
  borderSecondary: colors.coolGray30,
  borderMapbox: colors.mapboxLight,
}
