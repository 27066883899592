import { Currency } from '@sparelabs/currency'

export enum DiscountType {
  TimeBased = 'timeBased',
  CalendarBased = 'calendarBased',
  StoredValue = 'storedValue',
  Promo = 'promo',
}

export interface IFareRedemptionStub {
  type: DiscountType
  amount: number
  currency: Currency
}
