import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  ICasePatchBody,
  ICasePostBody,
  ICaseResponse,
  ICaseSearchQueryParams,
  ICaseSearchResponse,
  IListCaseQueryParams,
} from '../types'
import { Resources } from './ResourcesType'

export class CaseResource extends GenericResource<ICaseResponse, IListCaseQueryParams, ICasePatchBody, ICasePostBody> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  constructor(agent: AxiosInstance) {
    super(Resources.Cases, agent)
  }

  public async import(caseTypeId: string, file: File): Promise<void> {
    const formData = new FormData()
    formData.append('file', file)
    await this.agent.post(`${Resources.CaseTypes}/${caseTypeId}/import`, formData)
  }

  public async search(params: ICaseSearchQueryParams): Promise<ICaseSearchResponse> {
    const res = await this.agent.get<ICaseSearchResponse>(`${Resources.Cases}/search`, { params })
    return res.data
  }
}
