import { WebColors } from '@sparelabs/colors'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const NavLinkStyles = css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
  font-size: 15px;
  line-height: 20px;
  margin-top: 12px;
  transition: all 0.3s ease;
  &:hover {
    text-decoration: none;
  }
  &.selected {
    text-decoration: none;
  }
  width: fit-content;
  max-width: 100%;
  &:focus {
    text-decoration: none;
  }
`

export const StyledActiveNavLink = styled(NavLink)`
  ${NavLinkStyles}
  color: ${WebColors.contentPrimary} !important;
  cursor: 'pointer';
  &:hover {
    color: ${WebColors.interactivePrimary} !important;
  }
  &.selected {
    color: ${WebColors.interactivePrimary} !important;
  }
`

export const StyledDisabledNavLink = styled.a`
  ${NavLinkStyles}
  color: ${WebColors.contentTertiary} !important;
  cursor: 'auto';
  pointer-events: 'none';
  &:hover {
    color: ${WebColors.contentTertiary} !important;
  }
  &.selected {
    color: ${WebColors.contentTertiary} !important;
  }
`
