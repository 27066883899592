export const unityNormalization = (valsToNormalize: number[]): number[] => {
  /**
   * Uses unity-based normalization to convert the numbers passed in to values between 0 and 1.
   * https://datascience.stackexchange.com/questions/5885/how-to-scale-an-array-of-signed-integers-to-range-from-0-to-1
   */
  const maxValue = Math.max.apply(null, valsToNormalize)
  const minValue = Math.min.apply(null, valsToNormalize)

  const normalizedVals = valsToNormalize.map((toNormalize) => (toNormalize - minValue) / (maxValue - minValue))
  return normalizedVals
}
