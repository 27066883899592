import { IFileResponse, IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { INotificationMessages, NotificationDeliveryMethod } from './NotificationSettingTypes'

export enum BulkNotificationFilterType {
  Group = 'group',
  PastTrip = 'pastTrip',
  ScheduledTrip = 'scheduledTrip',
  FilteredRiders = 'filteredRiders',
}

export interface IBulkNotificationDateRange {
  startDate: string | null
  endDate: string | null
}

export enum BulkNotificationStatus {
  Sent = 'sent',
  Scheduled = 'scheduled',
  Cancelled = 'cancelled',
}

export interface IBulkNotificationResponse extends IModelJsonValues {
  filters: IBulkNotificationFilters
  deliveryMethod: NotificationDeliveryMethod
  name: string
  defaultMessages: INotificationMessages
  messageOverrides: IMessageOverrides
  filterType: BulkNotificationFilterType
  scheduledTs: number | null
  status: BulkNotificationStatus
}

export interface IBulkNotificationPostBody {
  deliveryMethod: NotificationDeliveryMethod
  filterType: BulkNotificationFilterType
  filters: IBulkNotificationFilters
  name: string
  defaultMessages: INotificationMessages
  messageOverrides?: IMessageOverrides
  /**
   * Adding null to scheduledTs is to allow for updating a scheduled bulk notification to be sent immediately
   */
  scheduledTs?: number | null
}

export interface IBulkNotificationPatchBody {
  deliveryMethod?: NotificationDeliveryMethod
  filterType?: BulkNotificationFilterType
  filters?: IBulkNotificationFilters
  name?: string
  defaultMessages?: INotificationMessages
  messageOverrides?: IMessageOverrides
  /**
   * Adding null to scheduledTs is to allow for updating a scheduled bulk notification to be sent immediately
   */
  scheduledTs?: number | null
  status?: BulkNotificationStatus
}

export interface IBulkNotificationListQueryParams extends IListModelQueryParamValues {
  status?: BulkNotificationStatus
}

export interface IBulkNotificationFilters {
  groupIds?: string[]
  pastScheduledTripDate?: IBulkNotificationDateRange
  serviceIds?: string[]
  riderIds?: string[]
  file?: IFileResponse
}

export type IMessageOverrides = Partial<Record<NotificationDeliveryMethod, INotificationMessages>>

export interface ISendBulkNotificationPostBody {
  organizationId: string
  deliveryMethod: NotificationDeliveryMethod
  defaultMessages: INotificationMessages
  messageOverrides: IMessageOverrides
  riderIds: string[]
}
