import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum LaunchReportingPermissionSet {
  View = 'launch_reporting_view',
  Write = 'launch_reporting_write',
  Delete = 'launch_reporting_delete',
  Export = 'launch_reporting_export',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const launchReportingPermissionSetGrouping: IPermissionSetGrouping<LaunchReportingPermissionSet> = {
  key: PermissionSetGroupingKey.LaunchReporting,
  active: {
    [LaunchReportingPermissionSet.View]: [
      PermissionType.GET_ANY_ANALYZE_REPORT_JOB,
      PermissionType.LIST_ANY_SERVICE,
      PermissionType.LIST_ANY_FLEET,
      PermissionType.GET_ANY_REPORT_TEMPLATE,
    ],
    [LaunchReportingPermissionSet.Write]: [
      PermissionType.POST_ANY_ANALYZE_REPORT_JOB,
      PermissionType.WRITE_ANY_REPORT_TEMPLATE,
    ],
    [LaunchReportingPermissionSet.Delete]: [
      PermissionType.DELETE_ANY_REPORT_TEMPLATE
    ],
    [LaunchReportingPermissionSet.Export]: [
      PermissionType.EXPORT_ANY_REPORT_TEMPLATE,
      PermissionType.EXPORT_ANY_RIDERSHIP_REPORT,
      PermissionType.EXPORT_ANY_DRIVER_REPORT,
      PermissionType.EXPORT_ANY_NTD_REPORT,
      PermissionType.LIST_ANY_OPEN_FLEET_RECEIPT,
      PermissionType.EXPORT_ANY_DATA_RETENTION_REPORT,
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
