import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum CasePermissionSet {
  View = 'case_view',
  ViewOwn = 'case_view_own',
  SubmitOwn = 'case_submit_own',
  Write = 'case_write',
  ViewForms = 'case_view_forms',
  WriteForms = 'case_write_forms',
  ViewLetters = 'case_view_letters',
  WriteLetters = 'case_write_letters',
  WriteStatus = 'case_write_status',
}

export enum CaseTypePermissionSet {
  View = 'case_type_view',
  Write = 'case_type_write', // manageCaseTypes
}

export enum FormPermissionSet {
  View = 'form_view',
  Write = 'form_write',
  ViewContents = 'form_view_contents',
  WriteContents = 'form_write_contents',
}

export enum LetterPermissionSet {
  View = 'letter_view',
  Write = 'letter_write',
}

export enum NylasAdminGrantPermissionSet {
  View = 'nylas_admin_grant_view',
  ViewOwn = 'nylas_admin_grant_view_own',
  Write = 'nylas_admin_grant_write',
  WriteOwn = 'nylas_admin_grant_write_own',
  Auth = 'nylas_admin_grant_auth',
}

export const casePermissionSetGrouping: IPermissionSetGrouping<CasePermissionSet> = {
  key: PermissionSetGroupingKey.Case,
  active: {
    [CasePermissionSet.View]: [
      PermissionType.GET_ANY_CASE,
      PermissionType.LIST_ANY_CUSTOM_FIELD,
      PermissionType.GET_ANY_FILE,
      PermissionType.LIST_ANY_CASE_STATUS,
    ],
    [CasePermissionSet.ViewOwn]: [
      PermissionType.LIST_OWN_CASE,
      PermissionType.LIST_ANY_CASE_TYPE,
      PermissionType.LIST_ANY_CASE_STATUS,
    ],
    [CasePermissionSet.Write]: [
      PermissionType.WRITE_ANY_CASE,
      PermissionType.POST_OWN_CASE,
      PermissionType.POST_ANY_FILE,
      PermissionType.POST_ANY_FILE_SCAN,
      PermissionType.IMPORT_ANY_CASE,
    ],
    [CasePermissionSet.SubmitOwn]: [
      PermissionType.POST_OWN_CASE,
      PermissionType.POST_ANY_FILE,
      PermissionType.WRITE_OWN_CASE_FORM,
      PermissionType.GET_OWN_CASE,
      PermissionType.GET_OWN_CASE_FORM,
      PermissionType.GET_ANY_FORM,
      PermissionType.GET_NATIVE_OBJECT_EXTENSIONS,
      PermissionType.GET_ANY_FILE,
      PermissionType.LIST_ANY_CUSTOM_FIELD,
      PermissionType.LIST_ANY_FORM_CONTENT,
    ],
    [CasePermissionSet.ViewForms]: [
      PermissionType.GET_ANY_CASE_FORM,
      PermissionType.GET_OWN_CASE_FORM,
      PermissionType.GET_ANY_FILE,
      PermissionType.LIST_ANY_CUSTOM_FIELD,
    ],
    [CasePermissionSet.WriteForms]: [
      PermissionType.WRITE_ANY_CASE_FORM,
      PermissionType.WRITE_OWN_CASE_FORM,
      PermissionType.POST_ANY_FILE,
      PermissionType.POST_ANY_FILE_SCAN,
    ],
    [CasePermissionSet.ViewLetters]: [PermissionType.GET_ANY_CASE_LETTER],
    [CasePermissionSet.WriteLetters]: [
      PermissionType.WRITE_ANY_CASE_LETTER,
      PermissionType.POST_ANY_FILE,
      PermissionType.POST_ANY_CASE_LETTER_DOWNLOAD,
    ],
    [CasePermissionSet.WriteStatus]: [PermissionType.WRITE_ANY_CASE_STATUS],
  },
  deprecated: [],
  unAssignablePermissionSets: [
    CasePermissionSet.SubmitOwn, // Only used by Rider role
    CasePermissionSet.ViewOwn, // Only used by Rider role
  ],
}

export const caseTypePermissionSetGrouping: IPermissionSetGrouping<CaseTypePermissionSet> = {
  key: PermissionSetGroupingKey.CaseType,
  active: {
    [CaseTypePermissionSet.View]: [
      PermissionType.GET_ANY_CASE_TYPE,
      PermissionType.LIST_ANY_CASE_TYPE,
      PermissionType.LIST_ANY_CUSTOM_FIELD,
    ],
    [CaseTypePermissionSet.Write]: [PermissionType.WRITE_ANY_CASE_TYPE],
  },
  unAssignablePermissionSets: [],
  deprecated: [],
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const formPermissionSetGrouping: IPermissionSetGrouping<FormPermissionSet> = {
  key: PermissionSetGroupingKey.Form,
  active: {
    [FormPermissionSet.View]: [
      PermissionType.GET_ANY_FORM,
      PermissionType.LIST_ANY_CUSTOM_FIELD,
    ],
    [FormPermissionSet.Write]: [
      PermissionType.WRITE_ANY_FORM,
      PermissionType.WRITE_ANY_CUSTOM_FIELD,
      PermissionType.POST_ANY_CUSTOM_FIELD_GROUP,
    ],
    [FormPermissionSet.ViewContents]: [
      PermissionType.GET_ANY_FORM_CONTENT,
      PermissionType.LIST_ANY_FORM_CONTENT
    ],
    [FormPermissionSet.WriteContents]: [
      PermissionType.WRITE_ANY_FORM_CONTENT
    ],
  },
  unAssignablePermissionSets: [],
  deprecated: [],
}

export const letterPermissionSetGrouping: IPermissionSetGrouping<LetterPermissionSet> = {
  key: PermissionSetGroupingKey.Letter,
  active: {
    [LetterPermissionSet.View]: [PermissionType.GET_ANY_LETTER],
    [LetterPermissionSet.Write]: [PermissionType.WRITE_ANY_LETTER],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}

export const nylasAdminGrantPermissionSetGrouping: IPermissionSetGrouping<NylasAdminGrantPermissionSet> = {
  key: PermissionSetGroupingKey.NylasAdminGrant,
  active: {
    [NylasAdminGrantPermissionSet.Auth]: [PermissionType.POST_NYLAS_AUTH, PermissionType.POST_NYLAS_CALLBACK],
    [NylasAdminGrantPermissionSet.View]: [PermissionType.GET_ANY_NYLAS_ADMIN_GRANT],
    [NylasAdminGrantPermissionSet.ViewOwn]: [PermissionType.GET_OWN_NYLAS_ADMIN_GRANT],
    [NylasAdminGrantPermissionSet.Write]: [PermissionType.WRITE_ANY_NYLAS_ADMIN_GRANT],
    [NylasAdminGrantPermissionSet.WriteOwn]: [PermissionType.WRITE_OWN_NYLAS_ADMIN_GRANT],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
