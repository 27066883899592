import { WebColors } from '@sparelabs/colors'
import { IFileResponse, IPublicFileResponse } from '@sparelabs/http-client-utils'
import { DateUtils } from '@sparelabs/time'
import { debounce } from 'lodash'
import React, { useRef, useState } from 'react'
import CanvasDraw from 'react-canvas-draw'
import styled from 'styled-components'
import { v4 } from 'uuid'
import { LoaderSpinner } from '../../animations'
import { DefaultButtonTheme, RoundedButton } from '../../controls'
import { st } from '../../locales/TranslationHelper'
import { borderRadius } from '../../util'

const DRAW_END_DELAY_MS = 1000
interface IProps<T> {
  value?: IFileResponse
  onChange: (value: (T extends IFileResponse ? IFileResponse : IPublicFileResponse) | undefined) => void
  onError: (error: Error) => void
  width?: string
  upload(fileData: File): Promise<T extends IFileResponse ? IFileResponse : IPublicFileResponse>
  id?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const DrawingContainer = styled.div`
  position: relative;
`

const WidgetContainer = styled.div`
  display: flex;
  z-index: 16; // higher than canvas, lower than input box
  bottom: 0;
  right: 0;
  margin: 0 8px 8px 0;
  align-items: center;
  position: absolute;
`
const ClearButton = styled(RoundedButton)`
  margin-left: 8px;
`

const dataUrlToFile = (dataUrl: string, fileName: string): File => {
  const base64String = dataUrl.split(',')[1]
  const blobParts = new Uint8Array([...atob(base64String)].map((stringChar) => stringChar.charCodeAt(0)))

  return new File([blobParts], `${fileName}.png`, { type: 'image/png', lastModified: DateUtils.toTs(new Date()) })
}

export function DrawingInput<T extends IFileResponse | IPublicFileResponse>({
  onChange,
  onError,
  width,
  upload,
}: IProps<T>) {
  const canvas = useRef<CanvasDraw>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fileName] = useState<string>(v4())

  const onClickClear = () => {
    canvas.current?.clear()
  }
  const uploadDrawing = async () => {
    try {
      setIsLoading(true)
      // Hacky way to get the data url from this 'CanvasDraw' library.
      // eslint-disable-next-line
      const dataUrl = (canvas.current as any).canvasContainer?.childNodes[1].toDataURL()
      const file = dataUrlToFile(dataUrl, fileName)
      const fileResponse = await upload(file)
      onChange(fileResponse)
    } catch (error: any) {
      onError(error)
    } finally {
      setIsLoading(false)
    }
  }
  const onDrawEnd = debounce(uploadDrawing, DRAW_END_DELAY_MS)

  const canvasProps = {
    ref: canvas,
    hideGrid: true,
    canvasWidth: '100%',
    canvasHeight: 300,
    brushRadius: 2,
    lazyRadius: 0,
    style: { borderRadius, border: `solid 1px ${WebColors.borderPrimary}` },
    onChange: onDrawEnd,
  }

  return (
    <Container style={{ width: width ?? '100%' }}>
      <DrawingContainer>
        <CanvasDraw {...canvasProps} />
        <WidgetContainer>
          {isLoading && <LoaderSpinner />}
          <ClearButton theme={DefaultButtonTheme} onClick={onClickClear}>
            {st.inputs.drawing.clear()}
          </ClearButton>
        </WidgetContainer>
      </DrawingContainer>
    </Container>
  )
}
