export enum Resources {
  AlertSubscriptions = 'alertSubscriptions',
  AnalyzeReportJobs = 'analyzeReportJobs',
  Metrics = 'metrics',
  ApiKeys = 'apikeys',
  Charges = 'charges',
  ConnectedAccount = 'connectedAccounts',
  DataRetentionSchedules = 'dataRetentionSchedules',
  Devices = 'devices',
  Duties = 'duties',
  DutyRequestOffers = 'driverOffers',
  Estimates = 'estimates',
  Geographies = 'geographies',
  Groups = 'groups',
  GroupMemberships = 'groupMemberships',
  GroupConditions = 'groupConditions',
  FarePasses = 'farePasses',
  FarePassAllocations = 'farePassAllocations',
  FareRules = 'fareRules',
  FavoriteLocations = 'favoriteLocations',
  GlobalOptimization = 'routeOptimization/globallyOptimize',
  NotificationSettings = 'notificationSettings',
  BulkNotifications = 'bulkNotifications',
  Organizations = 'organizations',
  PaymentMethods = 'paymentMethods',
  PaymentRefunds = 'paymentRefunds',
  Requests = 'requests',
  RequestCancellations = 'requestCancellations',
  RequestRecurrences = 'requestRecurrences',
  Reviews = 'reviews',
  RiderOnboardingFlow = 'riderOnboardingFlow',
  RouteOptimization = 'routeOptimization',
  Services = 'services',
  ServiceFleets = 'serviceFleets',
  Fleets = 'fleets',
  Announcements = 'announcements',
  Slots = 'slots',
  Stops = 'stops',
  Users = 'users',
  UserAlerts = 'userAlerts',
  UserSettings = 'userSettings',
  Zones = 'zones',
  Vehicles = 'vehicles',
  VehicleLocations = 'vehicleLocations',
  Webhooks = 'webhooks',
  Promos = 'promos',
  PromoAllocations = 'promoAllocations',
  TimeRules = 'timeRules',
  FareRedemptions = 'fareRedemptions',
  DriverOperations = 'driverOperations',
  UserBans = 'userBans',
  PayCollectionCalls = 'payCollectionCalls',
  Riders = 'riders',
  Drivers = 'drivers',
  Admins = 'admins',
  Reports = 'reports',
  SupportedClients = 'supportedClients',
  MobileApps = 'mobileApps',
  MobileAppRelease = 'whitelabel/release',
  CustomFields = 'customSchemas/fields',
  ServiceMetrics = 'metrics/services',
  RiderMetrics = 'metrics/rider',
  FleetMetrics = 'metrics/fleets',
  CallCentreMetrics = 'metrics/callCentre',
  Alerts = 'alerts',
  Routes = 'routes',
  RouteAssignments = 'routeAssignments',
  Emails = 'emails',
  Sms = 'sms',
  PlaceDetails = 'places/placeDetails',
  AutocompleteSuggestions = 'places/autocomplete',
  ReverseGeocode = 'places/reverseGeocode',
  Geocode = 'places/geocode',
  BillingAccount = 'billingAccounts',
  BillingAccountAssignment = 'billingAccountAssignments',
  AuthenticationMetadata = 'auth/metadata',
  App = 'app/apps',
  AppInstallation = 'app/appInstallations',
  AppWebhook = 'app/webhooks',
  SuperAdmins = 'superAdmins',
  OAuthAuthorize = 'app/oauth/authorize',
  LyftInstallation = 'lyft/install',
  BreakPolicy = 'breakPolicies',
  GlobalUser = 'global/users',
  UserFleetAgreements = 'userFleetAgreements',
  DriverBreaks = 'driverBreaks',
  Events = 'events',
  Antivirus = 'antivirus',
  ReportTemplates = 'reportTemplates',
  MatchingOutcomes = 'matchingOutcomes',
  MatchingSimulations = 'matchingSimulations',
  TipPolicies = 'tipPolicies',
  SamplePoints = 'samplePoints',
  TriggeredOptimizations = 'triggeredOptimizations',
  NotificationAnalytics = 'notificationAnalytics',
  WalletAutoTopUp = 'paymentMethods/wallet/autoTopUp',
  PaymentMethodTypes = 'paymentMethodTypes',
  PaymentProviders = 'paymentProviders',
  RiderDriverRestrictions = 'riderDriverRestrictions',
  RiderVehicleTypeRestrictions = 'riderVehicleTypeRestrictions',
  ConstraintOverrideActions = 'constraintOverrideActions',
  RequestConstraintOverrides = 'requestConstraintOverrides',
  NotificationProviderConfiguration = 'notificationProviderConfigurations',
  Pleo = 'pleo',
  VehicleInspections = 'vehicleInspections',
  VehicleInspectionTemplates = 'vehicleInspectionTemplates',
  VehicleTypes = 'vehicleTypes',
  FeatureEntitlement = 'featureEntitlement',
  DutyConversations = 'dutyConversations',
  IvrCallMetrics = 'ivrCallMetrics',
  FeatureFlags = 'featureFlags',
  Journeys = 'journeys',
  RequestPredictions = 'requestPredictions',
  CancellationReasons = 'cancellationReasons',
  AuthorizationGroup = 'rbac/groups',
  AuthorizationPermissionSets = 'rbac/permissionSets',
  SuperAdminAuthorizationGroups = 'rbac/superAdminGroups',
  UserGroups = 'rbac/user',
  RiderAuthorization = 'rbac/rider',
  DriverAuthorization = 'rbac/driver',
  TicketInstances = 'ticketInstances',
  DataMigration = 'dataMigration',
}
