import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IAuthorizationDriverOrRiderOperationParams,
  IAuthorizationGroupListQueryParams,
  IAuthorizationGroupPatchBody,
  IAuthorizationGroupPostBody,
  IAuthorizationGroupResponse,
  Resources,
} from '../types'

export class AuthorizationGroupResource extends GenericResource<
  IAuthorizationGroupResponse,
  IAuthorizationGroupListQueryParams,
  IAuthorizationGroupPatchBody,
  IAuthorizationGroupPostBody
> {
  constructor(agent: AxiosInstance) {
    super(Resources.AuthorizationGroup, agent)
  }

  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()

  public async createDefaultGroups(): Promise<void> {
    return this.agent.post(`${this.resource}/createDefaultGroups`)
  }

  public async addDriverToOrganization(params: IAuthorizationDriverOrRiderOperationParams): Promise<void> {
    return this.agent.post(`${Resources.DriverAuthorization}/${params.userId}`)
  }

  public async addRiderToOrganization(params: IAuthorizationDriverOrRiderOperationParams): Promise<void> {
    return this.agent.post(`${Resources.RiderAuthorization}/${params.userId}`)
  }

  public async updatePermissionSets(groupId: string, permissionSetIds: string[]): Promise<void> {
    await this.agent.put(`${Resources.AuthorizationGroup}/${groupId}/permissionSets`, { permissionSetIds })
  }
}
