export class ErrorUtils {
  public static callWithErrorMetadata<T>(fn: () => T, metadata: object): T
  public static async callWithErrorMetadata<T>(fn: () => Promise<T>, metadata: object): Promise<T> {
    try {
      return await fn()
    } catch (error) {
      this.addMetadata(error as Error, metadata)
      throw error
    }
  }

  // We use type `any` here because that's the type of a caught error
  // TODO Change metadata type to object.
  public static addMetadata(error: Error & { metadata?: any }, metadata: object): void {
    if (!error.metadata) {
      error.metadata = {}
    }
    Object.assign(error.metadata, metadata)
  }
}
