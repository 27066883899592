export enum GeographyStatus {
  Ready = 'ready',
  Updating = 'updating',
  UpdateFailed = 'updateFailed',
  Archived = 'archived',
}

export interface IGeographyPostBody {
  name: string
}

export interface IGeographyPatchBody extends Partial<IGeographyPostBody> {
  status?: GeographyStatus
}

export interface IGeographyResponse extends IGeographyPostBody {
  id: string
  status: GeographyStatus
  updatedAt: number
}
