import { IModelJsonValues } from '@sparelabs/http-client-utils'

export enum TipPolicyType {
  FixedOnly = 'fixed',
  PercentageOnly = 'percentage',
  PercentageThreshold = 'threshold',
}

export interface ITipPolicyPostBody {
  name: string
  type: TipPolicyType
  switchToPercentageThreshold?: number | null
  smallestTipAmountSuggestion?: number | null
  mediumTipAmountSuggestion?: number | null
  largestTipAmountSuggestion?: number | null
  smallestTipPercentageSuggestion?: number | null
  mediumTipPercentageSuggestion?: number | null
  largestTipPercentageSuggestion?: number | null
}

export interface ITipPolicyPatchBody extends Partial<ITipPolicyPostBody> {}

export interface ITipPolicyResponse extends IModelJsonValues, Required<ITipPolicyPostBody> {}
