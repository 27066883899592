import { WebColors } from '@sparelabs/colors'
import { isString } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { Badge, IBadgeProps } from '../../../foundations'
import { INPUT_MAX_WIDTH } from '../../../util'
import { InfoTooltip } from '../../InfoTooltip'
import { CheckboxInput } from '../CheckboxInput'
import { IInputProps } from '../InputTypes'

const HintText = styled.div`
  white-space: pre-wrap;
  font-size: 13px;
  color: ${WebColors.contentTertiary};
  font-weight: 400;
  padding: 5px 1px;
  line-height: 20px;
  max-width: ${INPUT_MAX_WIDTH};
`

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 0;
`

const StyledText = styled.span`
  margin-right: 5px;
  margin-bottom: 0px;
`

const StyledCheckboxInput = styled(CheckboxInput)`
  margin-right: 5px;
`

const StyledBadge = styled.div`
  margin-left: 4px;
`

export interface IChoice<T> {
  label: string
  value: T
  hint?: string
  displayHint?: string
  badge?: IBadgeProps
  readOnly?: boolean
}

interface IProps<T> extends Omit<IInputProps<T[]>, 'onChange' | 'value'> {
  choice: IChoice<T>
  values: T[] | undefined
  containerId: string
  onChange: (value: T[]) => void
}

const parseOnChange = <T,>(checkedItem: T, values: T[] | undefined, onChange: (value: T[]) => void) => {
  const currentArray: T[] = values || []
  if (currentArray.includes(checkedItem)) {
    // Is already in array. Remove
    onChange(currentArray.filter((item) => item !== checkedItem))
  } else {
    // Is not in array. Add
    onChange([...currentArray, checkedItem])
  }
}

export const Choice = <T,>({ values, choice, containerId, onChange, readOnly, ...inputProps }: IProps<T>) => {
  const stringValue = isString(choice.value) ? choice.value : JSON.stringify(choice.value)
  const inputId = `${containerId}_${stringValue}`

  return (
    <React.Fragment key={stringValue}>
      <StyledLabel htmlFor={inputId}>
        {!readOnly && (
          <StyledCheckboxInput
            {...inputProps}
            name={stringValue}
            id={inputId}
            aria-label={choice.label}
            type='checkbox'
            readOnly={readOnly}
            checked={(values || []).includes(choice.value)}
            onChange={() => parseOnChange(choice.value, values, onChange)}
          />
        )}
        <StyledText className='multi-choice-label'>{choice.label}</StyledText>
        {choice.hint && <InfoTooltip content={choice.hint} />}
        {choice.badge && (
          <StyledBadge>
            <Badge theme={choice.badge.theme}>{choice.badge.children}</Badge>
          </StyledBadge>
        )}
      </StyledLabel>
      {choice.displayHint && <HintText> {choice.displayHint}</HintText>}
    </React.Fragment>
  )
}
