import { DiscountType } from '@sparelabs/domain-logic'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { Currency } from './CurrencyTypes'
/**
 * @deprecated use from @sparelabs/domain-logic instead
 */
export { DiscountType, IFareRedemptionStub } from '@sparelabs/domain-logic'

export interface IFareRedemptionResponse extends IModelJsonValues {
  name: string
  type: DiscountType
  amount: number
  currency: Currency
  farePassId: string | null
  promoId: string | null
}

export interface IFareRedemptionListQueryParams extends IListModelQueryParamValues {
  requestId?: string
}
