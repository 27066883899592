import { WebColors } from '@sparelabs/colors'
import { isNumber } from '@sparelabs/core'
import React from 'react'
import styled from 'styled-components'
import { InfoTooltip } from '../forms'

export interface ITitledFigureProps {
  title: string
  figure: number | undefined
  unit?: string
  description?: string
  color?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
`

const Title = styled.div`
  color: ${WebColors.contentPrimary};
  font-size: 15px;
  line-height: 20px;
  text-align: left;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  span {
    margin-left: 6px;
    display: inline-flex;
    align-items: center;
  }
`

const Figure = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 24px;
  line-height: 28px;
  height: 28px;
`

const Unit = styled.span`
  color: ${WebColors.contentPrimary};
  font-size: 15px;
`

export const TitledFigure = (props: ITitledFigureProps): JSX.Element => {
  const color = props.color ?? WebColors.interactivePrimary
  const figure = props.figure === undefined ? '-' : props.figure
  const unit = props.unit && isNumber(props.figure) ? props.unit : null

  return (
    <Container>
      <Title>
        {props.title}
        {props.description && <InfoTooltip content={props.description} />}
      </Title>
      <Figure color={color}>
        {figure}
        <Unit>{unit}</Unit>
      </Figure>
    </Container>
  )
}
