import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IListNylasOrganizationGrantQueryParams,
  INylasOrganizationGrantPatchBody,
  INylasOrganizationGrantPostBody,
  INylasOrganizationGrantResponse,
} from '../types'
import { Resources } from './ResourcesType'

export class NylasOrganizationGrantResource extends GenericResource<
  INylasOrganizationGrantResponse,
  IListNylasOrganizationGrantQueryParams,
  INylasOrganizationGrantPatchBody,
  INylasOrganizationGrantPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  constructor(agent: AxiosInstance) {
    super(Resources.NylasOrganizationGrants, agent)
  }
}
