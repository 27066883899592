import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum PromoPermissionSet {
  View = 'promo_view',
  Write = 'promo_write',
  Export = 'promo_export',
  ApplyToUser = 'promo_apply_to_user',
}

export const promoPermissionSetGrouping: IPermissionSetGrouping<PromoPermissionSet> = {
  key: PermissionSetGroupingKey.Promo,
  active: {
    [PromoPermissionSet.View]: [
      PermissionType.GET_ANY_PROMO,
      PermissionType.LIST_ANY_PROMO,
      PermissionType.GET_ANY_PROMO_ALLOCATION,
      PermissionType.POST_ANY_PROMO_ALLOCATION,
    ],
    [PromoPermissionSet.Write]: [PermissionType.WRITE_ANY_PROMO],
    [PromoPermissionSet.Export]: [PermissionType.EXPORT_ANY_PROMO_ALLOCATION],
    [PromoPermissionSet.ApplyToUser]: [PermissionType.POST_ANY_PROMO_ALLOCATION],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
