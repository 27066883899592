import { zip } from 'lodash'

export class PairIterator {
  /**
   * Return a list of consecutive pairs built from the given list
   *
   * i.e. [1, 2, 3, 4]
   * returns [ [1, 2], [2, 3], [3, 4] ]
   */
  public static pairs<T>(list: T[]): Array<[T, T]> {
    if (list.length < 2) {
      return []
    }
    return zip(list.slice(0, -1), list.slice(1)) as Array<[T, T]>
  }
}
