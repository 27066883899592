import React, { ChangeEvent, useState } from 'react'
import { InputGroup } from 'reactstrap'
import styled from 'styled-components'
import { INPUT_HEIGHT, INPUT_MAX_WIDTH } from '../../util'
import { IInputProps } from './InputTypes'
import { StyledInput } from './styled'
import { StyledUnitsDropdown } from './styled/StyledUnitsDropdown'

interface INumberInputWithUnitsProps<Unit extends number | string> extends IInputProps<number | null> {
  units: Record<string, Unit>
  onUnitChange?: (unit: string) => void
  defaultUnit?: (value: number | null | undefined) => string
  allowDecimals?: boolean
}

const StyledInputGroup = styled(InputGroup)`
  max-width: ${INPUT_MAX_WIDTH};
  height: ${INPUT_HEIGHT};
`

const UnitsStyledInput = styled(StyledInput)`
  height: ${INPUT_HEIGHT};
  border-top-right-radius: ${(props) => (props.$hasUnits ? '0 !important' : '')};
  border-bottom-right-radius: ${(props) => (props.$hasUnits ? '0 !important' : '')};
`

const convertValueToSelectedUnits = (
  value: number | null | undefined,
  unitMultiplier: number,
  allowDecimals: boolean
) => {
  if (!value) {
    return value
  }
  const result = value / unitMultiplier
  return allowDecimals ? result : Math.round(result)
}

const convertValueToBaseUnits = (value: string, unitMultiplier: number | string, allowDecimals: boolean) => {
  if (!value) {
    return undefined
  }
  const parsedValue = allowDecimals ? parseFloat(value) : parseInt(value, 10)
  const valueInBaseUnits = typeof unitMultiplier === 'number' ? parsedValue * unitMultiplier : parsedValue
  return allowDecimals ? valueInBaseUnits : Math.round(valueInBaseUnits)
}

export const NumberInputWithUnits = <Unit extends number | string = number>({
  onChange,
  onUnitChange,
  value: baseValue,
  placeholder,
  units,
  invalid,
  defaultUnit,
  id,
  allowDecimals = false,
  ...rest
}: INumberInputWithUnitsProps<Unit>): JSX.Element => {
  const unitNames = Object.keys(units)
  if (unitNames.length === 0) {
    throw new Error('Must provide at least one unit')
  }
  const [selectedUnitName, setSelectedUnitName] = useState(defaultUnit?.(baseValue) ?? unitNames[0])

  const unitMultiplier = units[selectedUnitName]
  if (!unitMultiplier || (typeof unitMultiplier === 'number' && unitMultiplier < 1)) {
    throw new Error(`Unit multiplier value of '${unitMultiplier}' for '${selectedUnitName}' must be a positive integer`)
  }

  const valueString =
    (typeof unitMultiplier === 'number'
      ? convertValueToSelectedUnits(baseValue, unitMultiplier, allowDecimals)?.toString()
      : baseValue?.toString()) ?? ''

  return (
    <StyledInputGroup>
      <UnitsStyledInput
        {...rest}
        role='textbox'
        type='number'
        value={valueString} // Our component outputs values of type number, but the reactstrap input component itself inputs/outputs strings.
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange(convertValueToBaseUnits(event.target.value, unitMultiplier, allowDecimals))
        }
        $hasUnits={Boolean(unitNames.length)}
        id={id}
      />
      <StyledUnitsDropdown
        units={unitNames}
        selectedUnit={selectedUnitName}
        onChange={(newUnitName) => {
          if (onUnitChange) {
            onUnitChange(newUnitName)
          }
          setSelectedUnitName(newUnitName)
        }}
      />
    </StyledInputGroup>
  )
}
