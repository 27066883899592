import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IGroupPatchBody, IGroupPostBody, IGroupQueryParams, IGroupResponse, Resources } from '../types'

export class GroupResource extends GenericResource<IGroupResponse, IGroupQueryParams, IGroupPatchBody, IGroupPostBody> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()
  public del = this.buildDel()

  constructor(agent: AxiosInstance) {
    super(Resources.Groups, agent)
  }
}
