import { WebColors } from '@sparelabs/colors'
import styled from 'styled-components'
import { borderRadius, INPUT_MAX_WIDTH } from '../../util'
import { FileIcon } from './FileIcon'

export const FileViewContainer = styled.div`
  border-radius: ${borderRadius};
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  max-width: ${INPUT_MAX_WIDTH};
`

export const FileViewFileNameContainer = styled.div`
  flex: 1;
  min-width: 0;
  padding-right: 8px;
`

export const FileViewFileName = styled.div`
  color: ${WebColors.contentPrimary};
  font-size: 15px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const FileViewIcon = styled(FileIcon)`
  color: ${WebColors.contentSecondary};
`

export const FileViewFailedFileName = styled.div`
  color: ${WebColors.warningPrimary};
  font-size: 15px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const FileViewFileSize = styled.div`
  color: ${WebColors.contentSecondary};
  font-size: 13px;
  line-height: 16px;
`
