import { IPermissionSetGrouping } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'
import { AdminUserPermissionSet, adminUserPermissionSetGrouping } from './AdminUserPermissions'
import { AiAgentPermissionSet, aiAgentPermissionSetGrouping } from './AiAgentPermissions'
import { AnalyticsPermissionSet, analyticsPermissionSetGrouping } from './AnalyticsPermissions'
import { AnnouncementPermissionSet, announcementPermissionSetGrouping } from './AnnouncementPermissions'
import { ApiKeyPermissionSet, apiKeyPermissionSetGrouping } from './ApiKeyPermissions'
import { AppPermissionSet, appPermissionSetGrouping } from './AppPermissions'
import {
  AuthorizationGroupPermissionSet,
  authorizationGroupPermissionSetGrouping,
} from './AuthorizationGroupPermissions'
import { BulkMessagePermissionSet, bulkMessagePermissionSetGrouping } from './BulkMessagePermissions'
import {
  CancellationReasonPermissionSet,
  cancellationReasonPermissionSetGrouping,
} from './CancellationReasonPermissions'
import { ChargePermissionSet, chargePermissionSetGrouping } from './ChargePermissions'
import { ConnectedAccountPermissionSet, connectedAccountPermissionSetGrouping } from './ConnectedAccountPermissions'
import {
  DataRetentionSchedulePermissionSet,
  dataRetentionSchedulePermissionSetGrouping,
} from './DataRetentionSchedulePermissions'
import { DevicePermissionSet, devicePermissionSetGrouping } from './DevicePermission'
import { DriverPermissionSet, driverPermissionSetGrouping } from './DriverPermissions'
import { DriverUserPermissionSet, driverUserPermissionSetGrouping } from './DriverUserPermissions'
import { DutyPermissionSet, dutyPermissionSetGrouping } from './DutyPermissions'
import { DutyRequestOfferPermissionSet, dutyRequestOfferPermissionSetGrouping } from './DutyRequestOfferPermissions'
import {
  CasePermissionSet,
  casePermissionSetGrouping,
  CaseTypePermissionSet,
  caseTypePermissionSetGrouping,
  FormPermissionSet,
  formPermissionSetGrouping,
  LetterPermissionSet,
  letterPermissionSetGrouping,
  NylasAdminGrantPermissionSet,
  nylasAdminGrantPermissionSetGrouping,
} from './EngagePermissions'
import { EngageReportingPermissionSet, engageReportingPermissionSetGrouping } from './EngageReportingPermissions'
import { EventPermissionSet, eventPermissionSetGrouping } from './EventPermissions'
import { FarePassPermissionSet, farePassPermissionSetGrouping } from './FarePassPermissions'
import { FavoriteLocationPermissionSet, favoriteLocationPermissionSetGrouping } from './FavoriteLocationPermissions'
import { GroupPermissionSet, groupPermissionSetGrouping } from './GroupPermissions'
import { IvrPermissionSet, ivrPermissionSetGrouping } from './IvrPermissions'
import { JourneyPermissionSet, journeyPermissionSetGrouping } from './JourneyPermissions'
import { LaunchPermissionSet, launchPermissionSetGrouping } from './LaunchPermissions'
import { LaunchReportingPermissionSet, launchReportingPermissionSetGrouping } from './LaunchReportingPermissions'
import { MatchingOutcomePermissionSet, matchingOutcomePermissionSetGrouping } from './MatchingOutcomePermissions'
import {
  NotificationSettingPermissionSet,
  notificationSettingPermissionSetGrouping,
} from './NotificationSettingPermissions'
import { OperationalAlertsPermissionSet, operationalAlertsPermissionSetGrouping } from './OperationalAlertsPermissions'
import { OrganizationPermissionSet, organizationPermissionSetGrouping } from './OrganizationPermissions'
import { PaymentMethodPermissionSet, paymentMethodPermissionSetGrouping } from './PaymentMethodPermissions'
import { PaymentMethodTypePermissionSet, paymentMethodTypePermissionSetGrouping } from './PaymentMethodTypePermissions'
import { PromoPermissionSet, promoPermissionSetGrouping } from './PromoPermissions'
import { RealizePermissionSet, realizePermissionSetGrouping } from './RealizePermissions'
import { RequestPermissionSet, requestPermissionSetGrouping } from './RequestPermissions'
import { ReviewPermissionSet, reviewPermissionSetGrouping } from './ReviewPermissions'
import {
  RiderDriverRestrictionPermissionSet,
  riderDriverRestrictionPermissionSetGrouping,
} from './RiderDriverRestrictionPermissions'
import {
  RiderOnboardingFlowPermissionSet,
  riderOnboardingFlowPermissionSetGrouping,
  RiderPermissionSet,
  riderPermissionSetGrouping,
} from './RiderPermissions'
import { RiderUserPermissionSet, riderUserPermissionSetGrouping } from './RiderUserPermissions'
import {
  RiderVehicleTypeRestrictionPermissionSet,
  riderVehicleTypeRestrictionPermissionSetGrouping,
} from './RiderVehicleTypeRestrictionPermissions'
import { ServicePermissionSet, servicePermissionSetGrouping } from './ServicePermissions'
import { SimulationPermissionSet, simulationPermissionSetGrouping } from './SimulationPermissions'
import { TicketPermissionSet, ticketPermissionSetGrouping } from './TicketPermissions'
import { TipPermissionSet, tipPermissionSetGrouping } from './TipPermissions'
import { UserBanPermissionSet, userBanPermissionSetGrouping } from './UserBanPermissions'
import {
  UserFleetAgreementPermissionSet,
  userFleetAgreementPermissionSetGrouping,
} from './UserFleetAgreementPermissions'
import { UserOwnPermissionSet, userOwnPermissionSetGrouping } from './UserOwnPermissions'
import { VehiclePermissionSet, vehiclePermissionSetGrouping } from './VehiclePermissions'
import { VehicleTypePermissionSet, vehicleTypePermissionSetGrouping } from './VehicleTypePermissions'
import { WebhookPermissionSet, webhookPermissionSetGrouping } from './WebhookPermissions'
import { WorkflowPermissionSet, workflowPermissionSetGrouping } from './WorkflowPermissions'

export * from './AdminUserPermissions'
export * from './AiAgentPermissions'
export * from './AnalyticsPermissions'
export * from './AnnouncementPermissions'
export * from './ApiKeyPermissions'
export * from './AppPermissions'
export * from './AuthorizationGroupPermissions'
export * from './BulkMessagePermissions'
export * from './CancellationReasonPermissions'
export * from './ChargePermissions'
export * from './ConnectedAccountPermissions'
export * from './DataRetentionSchedulePermissions'
export * from './DevicePermission'
export * from './DriverPermissions'
export * from './DriverUserPermissions'
export * from './DutyPermissions'
export * from './DutyRequestOfferPermissions'
export * from './EngagePermissions'
export * from './EngageReportingPermissions'
export * from './EventPermissions'
export * from './FarePassPermissions'
export * from './FavoriteLocationPermissions'
export * from './GroupPermissions'
export * from './IvrPermissions'
export * from './JourneyPermissions'
export * from './LaunchPermissions'
export * from './LaunchReportingPermissions'
export * from './MatchingOutcomePermissions'
export * from './NotificationSettingPermissions'
export * from './OperationalAlertsPermissions'
export * from './OrganizationPermissions'
export * from './PaymentMethodPermissions'
export * from './PaymentMethodTypePermissions'
export * from './PromoPermissions'
export * from './RealizePermissions'
export * from './RequestPermissions'
export * from './ReviewPermissions'
export * from './RiderDriverRestrictionPermissions'
export * from './RiderPermissions'
export * from './RiderUserPermissions'
export * from './RiderVehicleTypeRestrictionPermissions'
export * from './ServicePermissions'
export * from './SimulationPermissions'
export * from './TicketPermissions'
export * from './TipPermissions'
export * from './UserBanPermissions'
export * from './UserFleetAgreementPermissions'
export * from './UserOwnPermissions'
export * from './VehiclePermissions'
export * from './VehicleTypePermissions'
export * from './WebhookPermissions'
export * from './WorkflowPermissions'

export type PermissionSetType =
  | AdminUserPermissionSet
  | AiAgentPermissionSet
  | AnalyticsPermissionSet
  | AnnouncementPermissionSet
  | ApiKeyPermissionSet
  | AppPermissionSet
  | AuthorizationGroupPermissionSet
  | BulkMessagePermissionSet
  | CancellationReasonPermissionSet
  | CasePermissionSet
  | CaseTypePermissionSet
  | ChargePermissionSet
  | ConnectedAccountPermissionSet
  | DataRetentionSchedulePermissionSet
  | DevicePermissionSet
  | DriverPermissionSet
  | DriverUserPermissionSet
  | DutyPermissionSet
  | DutyRequestOfferPermissionSet
  | EngageReportingPermissionSet
  | EventPermissionSet
  | FarePassPermissionSet
  | FavoriteLocationPermissionSet
  | FormPermissionSet
  | GroupPermissionSet
  | IvrPermissionSet
  | JourneyPermissionSet
  | LaunchPermissionSet
  | LaunchReportingPermissionSet
  | LetterPermissionSet
  | NotificationSettingPermissionSet
  | MatchingOutcomePermissionSet
  | OperationalAlertsPermissionSet
  | OrganizationPermissionSet
  | PaymentMethodPermissionSet
  | PaymentMethodTypePermissionSet
  | PromoPermissionSet
  | RealizePermissionSet
  | RequestPermissionSet
  | ReviewPermissionSet
  | RiderPermissionSet
  | RiderDriverRestrictionPermissionSet
  | RiderOnboardingFlowPermissionSet
  | RiderVehicleTypeRestrictionPermissionSet
  | RiderUserPermissionSet
  | ServicePermissionSet
  | SimulationPermissionSet
  | TicketPermissionSet
  | TipPermissionSet
  | UserBanPermissionSet
  | UserFleetAgreementPermissionSet
  | UserOwnPermissionSet
  | VehiclePermissionSet
  | VehicleTypePermissionSet
  | WebhookPermissionSet
  | WorkflowPermissionSet
  | NylasAdminGrantPermissionSet

export const permissionSetGroupings: Array<IPermissionSetGrouping<string>> = [
  adminUserPermissionSetGrouping,
  aiAgentPermissionSetGrouping,
  analyticsPermissionSetGrouping,
  announcementPermissionSetGrouping,
  apiKeyPermissionSetGrouping,
  appPermissionSetGrouping,
  authorizationGroupPermissionSetGrouping,
  bulkMessagePermissionSetGrouping,
  cancellationReasonPermissionSetGrouping,
  casePermissionSetGrouping,
  caseTypePermissionSetGrouping,
  chargePermissionSetGrouping,
  connectedAccountPermissionSetGrouping,
  dataRetentionSchedulePermissionSetGrouping,
  devicePermissionSetGrouping,
  driverPermissionSetGrouping,
  driverUserPermissionSetGrouping,
  dutyPermissionSetGrouping,
  dutyRequestOfferPermissionSetGrouping,
  engageReportingPermissionSetGrouping,
  eventPermissionSetGrouping,
  farePassPermissionSetGrouping,
  favoriteLocationPermissionSetGrouping,
  formPermissionSetGrouping,
  ivrPermissionSetGrouping,
  journeyPermissionSetGrouping,
  riderPermissionSetGrouping,
  groupPermissionSetGrouping,
  launchPermissionSetGrouping,
  launchReportingPermissionSetGrouping,
  letterPermissionSetGrouping,
  matchingOutcomePermissionSetGrouping,
  notificationSettingPermissionSetGrouping,
  operationalAlertsPermissionSetGrouping,
  organizationPermissionSetGrouping,
  paymentMethodPermissionSetGrouping,
  paymentMethodTypePermissionSetGrouping,
  promoPermissionSetGrouping,
  realizePermissionSetGrouping,
  requestPermissionSetGrouping,
  reviewPermissionSetGrouping,
  riderDriverRestrictionPermissionSetGrouping,
  riderOnboardingFlowPermissionSetGrouping,
  riderVehicleTypeRestrictionPermissionSetGrouping,
  riderUserPermissionSetGrouping,
  servicePermissionSetGrouping,
  simulationPermissionSetGrouping,
  ticketPermissionSetGrouping,
  tipPermissionSetGrouping,
  userBanPermissionSetGrouping,
  userFleetAgreementPermissionSetGrouping,
  userOwnPermissionSetGrouping,
  vehiclePermissionSetGrouping,
  vehicleTypePermissionSetGrouping,
  webhookPermissionSetGrouping,
  workflowPermissionSetGrouping,
]

export const PermissionSets: Record<PermissionSetType, PermissionType[]> = {
  ...adminUserPermissionSetGrouping.active,
  ...aiAgentPermissionSetGrouping.active,
  ...analyticsPermissionSetGrouping.active,
  ...announcementPermissionSetGrouping.active,
  ...apiKeyPermissionSetGrouping.active,
  ...appPermissionSetGrouping.active,
  ...authorizationGroupPermissionSetGrouping.active,
  ...bulkMessagePermissionSetGrouping.active,
  ...cancellationReasonPermissionSetGrouping.active,
  ...casePermissionSetGrouping.active,
  ...caseTypePermissionSetGrouping.active,
  ...chargePermissionSetGrouping.active,
  ...connectedAccountPermissionSetGrouping.active,
  ...dataRetentionSchedulePermissionSetGrouping.active,
  ...devicePermissionSetGrouping.active,
  ...driverPermissionSetGrouping.active,
  ...driverUserPermissionSetGrouping.active,
  ...dutyPermissionSetGrouping.active,
  ...dutyRequestOfferPermissionSetGrouping.active,
  ...engageReportingPermissionSetGrouping.active,
  ...eventPermissionSetGrouping.active,
  ...farePassPermissionSetGrouping.active,
  ...favoriteLocationPermissionSetGrouping.active,
  ...formPermissionSetGrouping.active,
  ...ivrPermissionSetGrouping.active,
  ...journeyPermissionSetGrouping.active,
  ...riderPermissionSetGrouping.active,
  ...groupPermissionSetGrouping.active,
  ...launchPermissionSetGrouping.active,
  ...launchReportingPermissionSetGrouping.active,
  ...letterPermissionSetGrouping.active,
  ...matchingOutcomePermissionSetGrouping.active,
  ...notificationSettingPermissionSetGrouping.active,
  ...operationalAlertsPermissionSetGrouping.active,
  ...organizationPermissionSetGrouping.active,
  ...paymentMethodPermissionSetGrouping.active,
  ...paymentMethodTypePermissionSetGrouping.active,
  ...promoPermissionSetGrouping.active,
  ...realizePermissionSetGrouping.active,
  ...requestPermissionSetGrouping.active,
  ...reviewPermissionSetGrouping.active,
  ...riderDriverRestrictionPermissionSetGrouping.active,
  ...riderOnboardingFlowPermissionSetGrouping.active,
  ...riderVehicleTypeRestrictionPermissionSetGrouping.active,
  ...riderUserPermissionSetGrouping.active,
  ...servicePermissionSetGrouping.active,
  ...simulationPermissionSetGrouping.active,
  ...ticketPermissionSetGrouping.active,
  ...tipPermissionSetGrouping.active,
  ...userBanPermissionSetGrouping.active,
  ...userFleetAgreementPermissionSetGrouping.active,
  ...userOwnPermissionSetGrouping.active,
  ...vehiclePermissionSetGrouping.active,
  ...vehicleTypePermissionSetGrouping.active,
  ...webhookPermissionSetGrouping.active,
  ...workflowPermissionSetGrouping.active,
  ...nylasAdminGrantPermissionSetGrouping.active,
}

export const DeprecatedPermissionSets: PermissionSetType[] = permissionSetGroupings.reduce<PermissionSetType[]>(
  (acc, grouping: IPermissionSetGrouping<string>) => {
    acc.push(...(grouping.deprecated as PermissionSetType[]))
    return acc
  },
  []
)

/**
 * List of permission sets that should be hidden from the UI
 */
export const UnAssignablePermissionSets: PermissionSetType[] = permissionSetGroupings.reduce<PermissionSetType[]>(
  (acc, grouping: IPermissionSetGrouping<string>) => {
    acc.push(...(grouping.unAssignablePermissionSets as PermissionSetType[]))
    return acc
  },
  []
)
