import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum UserBanPermissionSet {
  View = 'user_ban_view',
  Write = 'user_ban_write',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const userBanPermissionSetGrouping: IPermissionSetGrouping<UserBanPermissionSet> = {
  key: PermissionSetGroupingKey.UserBan,
  active: {
    [UserBanPermissionSet.View]: [
      PermissionType.GET_ANY_USER_BAN,
    ],
    [UserBanPermissionSet.Write]: [
      PermissionType.WRITE_ANY_USER_BAN,
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
