import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IAuthorizationGroupListQueryParams,
  IAuthorizationGroupPostBody,
  IAuthorizationGroupResponse,
  IAuthorizationSuperAdminUserGroupPutBody,
  IAuthorizationSuperAdminUserGroupPutResponse,
  ISuperAdminAuthorizationUserGroupsResponse,
  Resources,
} from '../types'

export class SuperAdminAuthorizationGroupResource extends GenericResource<
  IAuthorizationGroupResponse,
  IAuthorizationGroupListQueryParams,
  never,
  IAuthorizationGroupPostBody
> {
  constructor(agent: AxiosInstance) {
    super(Resources.SuperAdminAuthorizationGroups, agent)
  }

  public list = this.buildList()

  public getUsersGroups = async (userId: string): Promise<ISuperAdminAuthorizationUserGroupsResponse> => {
    const res = await this.agent.get<ISuperAdminAuthorizationUserGroupsResponse>(`${this.resource}/user/${userId}`)
    return res.data
  }

  public addGroupsToUser = async (
    userId: string,
    groups: IAuthorizationSuperAdminUserGroupPutBody
  ): Promise<IAuthorizationSuperAdminUserGroupPutResponse> => {
    const res = await this.agent.put<IAuthorizationSuperAdminUserGroupPutResponse>(
      `${this.resource}/user/${userId}`,
      groups
    )

    return res.data
  }
}
