import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { ITimeRuleListQueryParams, ITimeRulePatchBody, ITimeRulePostBody, ITimeRuleResponse, Resources } from '../types'

export class TimeRuleResource extends GenericResource<
  ITimeRuleResponse,
  ITimeRuleListQueryParams,
  ITimeRulePatchBody,
  ITimeRulePostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()
  public del = this.buildDel()

  constructor(agent: AxiosInstance) {
    super(Resources.TimeRules, agent)
  }
}
