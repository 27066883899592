import { IFileScanPostBody, IFileScanResponse, IFileTempUrlResponse } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'

export class FileResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async tempUrl(id: string): Promise<IFileTempUrlResponse> {
    const res = await this.agent.post<IFileTempUrlResponse>(`files/${id}/tempUrl`)
    return res.data
  }

  public async scan(id: string, body: IFileScanPostBody): Promise<IFileScanResponse> {
    const res = await this.agent.post<IFileScanResponse>(`files/${id}/scan`, body)
    return res.data
  }
}
