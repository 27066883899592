import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IBulkNotificationChannelStatusCountsResponse,
  INotificationAnalyticsListQueryParams,
  INotificationAnalyticsResponse,
  Resources,
} from '../types'

export class NotificationAnalyticsResource extends GenericResource<
  INotificationAnalyticsResponse,
  INotificationAnalyticsListQueryParams,
  never,
  never
> {
  public get = this.buildGet()
  public list = this.buildList()

  constructor(agent: AxiosInstance) {
    super(Resources.NotificationAnalytics, agent)
  }

  public async getCounts(notificationId: string): Promise<IBulkNotificationChannelStatusCountsResponse> {
    const res = await this.agent.get<IBulkNotificationChannelStatusCountsResponse>(
      `${this.resource}/count/${notificationId}`
    )
    return res.data
  }
}
