import { IAdminResponse } from './AdminTypes'
import { IDriverResponse } from './DriverTypes'

export interface IDriverDispatchCommsToken {
  token: string
}

export enum MessageAuthor {
  Driver = 'driver',
  Dispatcher = 'dispatcher',
}

export interface IMessageAttributes {
  authorType: MessageAuthor
  author: Pick<IAdminResponse | IDriverResponse, 'id' | 'firstName' | 'lastName' | 'photoUrl'>
}
