import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum AdminUserPermissionSet {
  View = 'admin_user_view',
  New = 'admin_user_new',
  Edit = 'admin_user_edit',
  Delete = 'admin_user_delete',
  Import = 'admin_user_import',
}

export const adminUserPermissionSetGrouping: IPermissionSetGrouping<AdminUserPermissionSet> = {
  key: PermissionSetGroupingKey.AdminUser,
  active: {
    [AdminUserPermissionSet.View]: [PermissionType.GET_ANY_ADMIN],
    [AdminUserPermissionSet.New]: [PermissionType.POST_ANY_ADMIN],
    [AdminUserPermissionSet.Edit]: [
      PermissionType.PATCH_ANY_ADMIN,
      PermissionType.POST_UPLOAD_PHOTO /* Used for uploading a profile photos to users accounts */,
      PermissionType.POST_ANY_FILE /* Used for uploading files to users profiles */,
    ],
    [AdminUserPermissionSet.Delete]: [PermissionType.DELETE_ANY_ADMIN],
    [AdminUserPermissionSet.Import]: [PermissionType.IMPORT_ANY_ADMIN],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
