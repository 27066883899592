import { VerificationMethod } from './UserTypes'

/* Different scopes for access tokens */
export enum Scope {
  Full = 'full', // able to view data on all users
  Self = 'self', // able to view data relevant to self
}

export enum AuthType {
  User = 'user',
  ApiKey = 'apikey',
  Service = 'service',
  App = 'app',
}

export const RE_CAPTCHA_TOKEN_HEADER: string = 'x-spare-recaptcha-token'

export interface IAuthTokenPostBody {
  userId: string
  organizationId?: string
  scope: Scope
}

/* TODO this functionality is overloaded with simple auth token */
export interface IAuthTokenJoinOrganizationPostBody {
  userId: string
  organizationId: string
  scope: Scope
}

export interface IUserOnlyTokenResponse {
  userId: string
  token: string
}

export interface IUserOrgTokenResponse {
  organizationId: string
  token: string
}

export interface IResetPasswordRequestBody {
  email: string
}

export interface IResetPasswordConfirmBody {
  /* Reset token that is supplied to user through an email */
  token: string
  password: string
}

export interface IAuthEmailBody {
  email: string
  password: string
  scope: Scope
}

export interface IAuthUsernameBody {
  organizationKey: string
  username: string
  password: string
}

export interface IAuthTestCredentialsBody {
  username: string
  password: string
  organizationId: string
}

export interface IAuthPhoneRequestBody {
  phoneNumber: string
  organizationId: string
  verificationMethod?: VerificationMethod
}

export interface IAuthPhoneVerifyBody {
  organizationId: string
  phoneNumber: string
  code: string
}

export interface IAuthPhonePinBody {
  organizationId: string
  externalNumericId: number
  phonePin: string
}

export interface IAuthenticationMetadataResponse {
  stale: boolean
}

export enum AuthHeader {
  OrganizationId = 'organization-id',
}
