import { IEstimateOutput, IEstimateRequestCommonQueryParams } from './EstimateOperationTypes'
import { IGroupCondition } from './GroupConditionTypes'
import { IApproval } from './GroupMembershipTypes'

/**
 * The query parameters for the booking options endpoint
 *
 * If neither requestedPickupTs or requestedDropoffTs are provided, we consider this a "next available" request
 */
export interface IBookingOptionsQueryParams extends IEstimateRequestCommonQueryParams {
  requestedPickupTs?: number
  requestedDropoffTs?: number
}

export interface IBookingOptionTimeWindow {
  fromTs: number
  untilTs: number
  isDesiredWindow: boolean
  requestedTs: number
}

export enum BookingOptionResultType {
  SuccessfulEstimate = 'successfulEstimate',
  NoDriversAvailable = 'noDriversAvailable',
  OutsideOfServiceHours = 'outsideOfServiceHours',
}

interface IBookingOptionBase {
  resultType: BookingOptionResultType
  timeWindow: IBookingOptionTimeWindow
  payload: unknown
}

export interface IBookingOptionSuccessfulEstimate extends IBookingOptionBase {
  resultType: BookingOptionResultType.SuccessfulEstimate
  payload: IEstimateOutput
}

interface IBaseNDAPayload {
  estimateId: string
  canOverrideFlexibilities: boolean
  canCreateUnmatched: boolean
}

export interface ICanCreateUnmatchedPayload extends IBaseNDAPayload {
  theoreticalEstimate: IEstimateOutput
  canCreateUnmatched: true
}

export interface ICannotCreateUnmatchedPayload extends IBaseNDAPayload {
  canCreateUnmatched: false
}
export interface IBookingOptionNoDriversAvailable extends IBookingOptionBase {
  resultType: BookingOptionResultType.NoDriversAvailable
  payload: ICanCreateUnmatchedPayload | ICannotCreateUnmatchedPayload
}

export interface IBookingOptionOutsideOfServiceHours extends IBookingOptionBase {
  resultType: BookingOptionResultType.OutsideOfServiceHours
  payload: ICannotCreateUnmatchedPayload
}

export type IBookingOption =
  | IBookingOptionSuccessfulEstimate
  | IBookingOptionNoDriversAvailable
  | IBookingOptionOutsideOfServiceHours

// TODO: remove once everything including rider is moved to the new conditional eligibility model
// please use Approvals
export interface IBookingOptionsCondition<T extends IApproval = IApproval> {
  condition: T
  isMet: boolean
  groupMembershipId: string
  groupId: string
}

export interface IBookingOptionsApproval<T extends IApproval = IApproval> {
  approvalDetails: T
  isMet: boolean
  groupMembershipId: string
  groupId: string
}

export interface IGroupConditionValidationResult {
  isMet: boolean
  requiresValidation: boolean
}

export interface IBookingOptionsGroupCondition<T extends IGroupCondition = IGroupCondition>
  extends IGroupConditionValidationResult {
  condition: T
  groupMembershipId: string
  groupId: string
}

export interface IBookingOptionsResponse {
  bookingOptions: IBookingOption[]
  requestRequirements: Array<IBookingOptionsCondition<IApproval>> // TODO remove once everything including rider is moved to the new conditional eligibility model
  conditionalEligibility: {
    approvals: Array<IBookingOptionsApproval<IApproval>>
    groupConditions: Array<IBookingOptionsGroupCondition<IGroupCondition>>
  }
}
