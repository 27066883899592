import { DefaultButtonTheme, RoundedButton } from '@sparelabs/web-ui-components'
import React from 'react'
import { st } from 'src/locales/TranslationHelper'

interface IFormBackButtonProps {
  onClick: () => void
}
export const FormBackButton = ({ onClick }: IFormBackButtonProps): JSX.Element => (
  <RoundedButton theme={DefaultButtonTheme} onClick={onClick}>
    {st.common.back()}
  </RoundedButton>
)
