import { GenericResource, IListResponse } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  INotificationProviderGetResponse,
  IOrganizationConfigurationPatchBody,
  IOrganizationConfigurationResponse,
  IOrganizationListQueryParams,
  IOrganizationPatchBody,
  IOrganizationPostBody,
  IOrganizationResponse,
  IOrganizationSuperAdminListQueryParams,
  IOrganizationSuperAdminPostBody,
  IOrganizationSuperAdminResponse,
  ITwilioPatchBody,
  ITwilioPatchResponse,
  Resources,
} from '../types'

export class OrganizationResource extends GenericResource<
  IOrganizationResponse,
  IOrganizationListQueryParams,
  IOrganizationPatchBody,
  IOrganizationPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public patch = this.buildPatch()
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.Organizations, agent)
  }

  public async getConfiguration(id: string): Promise<IOrganizationConfigurationResponse> {
    const { data } = await this.agent.get<IOrganizationConfigurationResponse>(this.getConfigurationPath(id))

    return data
  }

  public async patchConfiguration(id: string, body: IOrganizationConfigurationPatchBody): Promise<void> {
    await this.agent.patch<void>(this.getConfigurationPath(id), body)
  }

  public async getNotificationProviders(id: string): Promise<INotificationProviderGetResponse> {
    const { data } = await this.agent.get<INotificationProviderGetResponse>(this.getNotificationProvidersPath(id))

    return data
  }

  public async patchTwilioConfiguration(id: string, body: ITwilioPatchBody): Promise<ITwilioPatchResponse> {
    const { data } = await this.agent.patch<ITwilioPatchResponse>(this.getNotificationProvidersTwilioPath(id), body)

    return data
  }

  private getConfigurationPath(id: string): string {
    return `${this.resource}/${id}/configuration`
  }

  private getNotificationProvidersPath(id: string): string {
    return `${this.resource}/${id}/notificationProviders`
  }

  private getNotificationProvidersTwilioPath(id: string): string {
    return `${this.resource}/${id}/notificationProviders/twilio`
  }

  public async deleteRiders(id: string): Promise<void> {
    await this.agent.delete<void>(this.getDeleteRidersPath(id))
  }

  private getDeleteRidersPath(id: string): string {
    return `${this.resource}/${id}/riders`
  }

  public async joinOrganization(id: string): Promise<void> {
    await this.agent.post<void>(`${this.resource}/${id}/join`, {})
  }

  public async getSuperAdmin(organizationId: string, superAdminId: string): Promise<IOrganizationSuperAdminResponse> {
    const { data } = await this.agent.get<IOrganizationSuperAdminResponse>(
      this.buildSuperAdminPath(organizationId, superAdminId)
    )
    return data
  }

  public async listAllSuperAdmins(organizationId: string): Promise<IOrganizationSuperAdminResponse[]> {
    return this.allPageFetcher.fetch<IOrganizationSuperAdminResponse, IOrganizationSuperAdminListQueryParams>(
      this.buildSuperAdminsPath(organizationId)
    )
  }

  public async postSuperAdmin(
    organizationId: string,
    body: IOrganizationSuperAdminPostBody
  ): Promise<IOrganizationSuperAdminResponse> {
    const { data } = await this.agent.post<IOrganizationSuperAdminResponse>(
      this.buildSuperAdminsPath(organizationId),
      body
    )
    return data
  }

  public async delSuperAdmin(organizationId: string, superAdminId: string): Promise<void> {
    await this.agent.delete<void>(this.buildSuperAdminPath(organizationId, superAdminId))
  }

  public async search(search: string): Promise<IListResponse<IOrganizationResponse>> {
    const { data } = await this.agent.get<IListResponse<IOrganizationResponse>>(this.resource, { params: { search } })

    return data
  }

  private buildSuperAdminPath(organizationId: string, superAdminId: string): string {
    return `${this.buildSuperAdminsPath(organizationId)}/${superAdminId}`
  }

  private buildSuperAdminsPath(organizationId: string): string {
    return `${this.resource}/${organizationId}/superAdmins`
  }
}
