import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IAlertPostBody, IAlertResponse, Resources } from '../types'

/**
 * AlertResource is for internal use only and not publicly accessible.
 */
export class AlertResource extends GenericResource<IAlertResponse, never, never, IAlertPostBody> {
  public post = this.buildPost()
  constructor(agent: AxiosInstance) {
    super(Resources.Alerts, agent)
  }
}
