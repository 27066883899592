import { BadArgumentsError } from '../errors'
import { IRetryDelayStrategy } from './RetryDelayStrategy'

export interface IExponentialBackoffStrategyParams {
  /**
   * Amount of time to wait before the first retry attempt, in milliseconds.
   */
  firstRetryDelayMs: number
  /**
   * Random offset to add to the delay between retry attempts, as a fraction of the delay duration (before offset).
   * Must be >= 0.
   *
   * E.g. if delay is 10s and max jitter fraction is 0.5, then max jitter will be 5 seconds
   * for total possible delay of 10 + 5 = 15s.
   * But since jitter is random, the min jitter for a particular attempt will always be 0.
   *
   * Set to 0 for no jitter.
   */
  maxJitterFraction: number
  /**
   * The exponent used in exponential backoff retries - see `calculateRetryDelay` below for exact usage
   */
  exponentialBackoffExponent: number
}

const DEFAULT_PARAMS: IExponentialBackoffStrategyParams = {
  firstRetryDelayMs: 100,
  maxJitterFraction: 0, // no jitter by default
  exponentialBackoffExponent: 2,
}

export class ExponentialBackoffStrategy implements IRetryDelayStrategy {
  private readonly params: IExponentialBackoffStrategyParams

  constructor(params: Partial<IExponentialBackoffStrategyParams> = {}) {
    this.params = {
      ...DEFAULT_PARAMS,
      ...params,
    }

    if (this.params.maxJitterFraction < 0) {
      throw new BadArgumentsError(`Max jitter fraction must be >= 0 (was ${this.params.maxJitterFraction})`)
    }
    if (this.params.exponentialBackoffExponent < 1 || this.params.exponentialBackoffExponent > 5) {
      throw new BadArgumentsError(
        `exponentialBackoffExponent must be between 1 and 5 (was ${this.params.exponentialBackoffExponent})`
      )
    }
  }

  public calculateRetryDelay(attemptNumber: number): number {
    const { firstRetryDelayMs, exponentialBackoffExponent, maxJitterFraction } = this.params
    const baseDelay = firstRetryDelayMs * Math.pow(exponentialBackoffExponent, attemptNumber - 1)
    const jitter = Math.random() * baseDelay * maxJitterFraction
    return baseDelay + jitter
  }
}
