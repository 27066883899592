import { z } from '@sparelabs/zod'
import { OrderDirectionSchema } from './QueryTypes'

export const IModelJsonValuesSchema = z.object({
  id: z.string().describe('The unique identifier for this object'),
  createdAt: z.number().describe('When this object was created, in Unix seconds'),
  updatedAt: z.number().describe('When this object was last updated, in Unix seconds'),
  deletedAt: z.number().optional().describe('When this object was deleted, in Unix seconds'),
})

export type IModelJsonValues = z.infer<typeof IModelJsonValuesSchema>

export const IListModelQueryParamValuesSchema = z.object({
  limit: z.number().optional().describe('The maximum number of items to return (page size)'),
  skip: z.number().optional().describe('The number of items to skip (used for pagination)'),
  fromCreatedAt: z.number().optional().describe('Filter by created date, greater than or equal to'),
  toCreatedAt: z.number().optional().describe('Filter by created date, less than or equal to'),
  orderBy: z.string().optional().describe('The field to order by'),
  orderDirection: OrderDirectionSchema.optional().describe('The direction to order by'),
  ids: z.array(z.string()).optional().describe('Filter by a list of ids'),
})

export type IListModelQueryParamValues = z.infer<typeof IListModelQueryParamValuesSchema>
