import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'

export enum IvrCallMetricType {
  Ivr = 'ivr',
  AiVoice = 'aiVoice',
}

export interface IIvrCallMetricsResponse extends IModelJsonValues {
  userId: string
  ivrDuration: number
  etaActionCount: number
  cancellationActionCount: number
  bookingActionCount: number
  didForwardToSupport: boolean
  mediaUrl: string | null
  externalCallId: string
  startTs: number
  endTs: number | null
  type: IvrCallMetricType
  metadata: Record<string, unknown>
}

export interface IIvrCallMetricsQueryParams extends IListModelQueryParamValues {
  userId?: string
  fromStartTs?: number
  toStartTs?: number
  type?: IvrCallMetricType
  isCompleted?: boolean
}
