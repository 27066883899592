import { GenericResource, IListModelQueryParamValues } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IPaymentProviderPatchBody,
  IPaymentProviderPostBody,
  IPaymentProviderResponse,
  IStripeGlobalConfigQueryParams,
  IStripeGlobalConfigResponse,
  Resources,
} from '../types'

export class PaymentProviderResource extends GenericResource<
  IPaymentProviderResponse,
  IListModelQueryParamValues,
  IPaymentProviderPatchBody,
  IPaymentProviderPostBody
> {
  public patch = this.buildPatch()
  public post = this.buildPost()

  constructor(agent: AxiosInstance) {
    super(Resources.PaymentProviders, agent)
  }

  public async getStripeGlobalConfig(params: IStripeGlobalConfigQueryParams): Promise<IStripeGlobalConfigResponse> {
    const { data } = await this.agent.get<IStripeGlobalConfigResponse>(`${this.resource}/stripe/globalConfig`, {
      params,
    })
    return data
  }
}
