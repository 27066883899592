import { WebColors } from '@sparelabs/colors'
import { LocalizationPhoneHelper } from '@sparelabs/localization'
import { BrowserLanguageHelper } from '@sparelabs/translate'
import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import { borderRadius, FOCUS_WIDTH } from '../../util'

interface IProps {
  onChange: (value: string) => void
  value?: string | null
  id?: string
  autoFocus?: boolean
  onSubmit?: (e: any) => void
}

export const PhoneNumberInput = ({ onChange, value, id, autoFocus, onSubmit }: IProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false)
  return (
    <PhoneInput
      country={LocalizationPhoneHelper.guessCountry(BrowserLanguageHelper.getCurrentLanguageCode())}
      value={value}
      onChange={(phone: string) => (phone ? onChange(`+${phone}`) : onChange(''))}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      inputStyle={{
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
        borderRadius,
        border: `solid 1px ${WebColors.borderPrimary}`,
        maxWidth: '500px',
        width: '100%',
        fontSize: '15px',
        ...(isFocused
          ? {
              outlineColor: WebColors.interactivePrimary,
              outlineStyle: 'solid',
              outlineWidth: FOCUS_WIDTH,
              boxShadow: 'none',
            }
          : {}),
      }}
      inputProps={{ id, autoFocus }}
      onEnterKeyPress={onSubmit}
    />
  )
}
