import React from 'react'
import { FormContainer, InfoParagraph, UpdateFormSection } from 'src/components'
import { st } from 'src/locales/TranslationHelper'
import { PostSubmitFormSection } from 'src/pages/Form/steps/FormInput/PostSubmitFormSection'
import { useFormContext } from '../FormContext'
import { FormStep } from '../FormStepTypes'

const t = st.forms.submission

export const FinishedStep = (): JSX.Element => {
  const { goToStep } = useFormContext()

  return (
    <FormContainer>
      <PostSubmitFormSection fallback={<InfoParagraph role='alert'>{t.successMessage()}</InfoParagraph>} />
      <UpdateFormSection onUpdateClick={() => goToStep(FormStep.FormInput)} />
    </FormContainer>
  )
}
