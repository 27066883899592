import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum DutyRequestOfferPermissionSet {
  View = 'duty_request_offer_view',
}

export const dutyRequestOfferPermissionSetGrouping: IPermissionSetGrouping<DutyRequestOfferPermissionSet> = {
  key: PermissionSetGroupingKey.DutyRequestOffer,
  active: {
    [DutyRequestOfferPermissionSet.View]: [PermissionType.GET_ANY_DUTY_REQUEST_OFFER],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
