import { delay } from './delay'
import { ExponentialBackoffStrategy } from './ExponentialBackoffStrategy'
import { IRetryDelayStrategy } from './RetryDelayStrategy'

export interface IRetryLoopParams {
  maxAttempts: number
  retryDelayStrategy: IRetryDelayStrategy
  isRetryable: (error: Error) => boolean
  onIntermediateError: (error: Error) => Promise<void>
  onFinalError: (error: Error) => Promise<void>
}

const DEFAULT_PARAMS: IRetryLoopParams = {
  maxAttempts: 3,
  retryDelayStrategy: new ExponentialBackoffStrategy(),
  isRetryable: () => true,
  onIntermediateError: () => Promise.resolve(),
  onFinalError: (error: Error) => Promise.reject(error),
}

export class RetryStrategy {
  private readonly params: IRetryLoopParams

  constructor(params: Partial<IRetryLoopParams> = {}) {
    this.params = {
      ...DEFAULT_PARAMS,
      ...params,
    }
  }

  public async run<T>(callback: () => Promise<T>): Promise<T> {
    let attemptNumber: number = 0

    while (true) {
      attemptNumber++
      try {
        return await callback()
      } catch (error) {
        if (!this.params.isRetryable(error as Error)) {
          throw error
        }

        if (attemptNumber < this.params.maxAttempts) {
          await this.params.onIntermediateError(error as Error)
          await delay(this.params.retryDelayStrategy.calculateRetryDelay(attemptNumber))
        } else {
          await this.params.onFinalError(error as Error)
          return Promise.reject(error)
        }
      }
    }
  }
}
