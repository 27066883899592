import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum NotificationSettingPermissionSet {
  View = 'notification_setting_view',
  Write = 'notification_setting_write',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const notificationSettingPermissionSetGrouping: IPermissionSetGrouping<NotificationSettingPermissionSet> = {
  key: PermissionSetGroupingKey.NotificationSetting,
  active: {
    [NotificationSettingPermissionSet.View]: [
      PermissionType.GET_ANY_NOTIFICATION_SETTING,
      PermissionType.POST_ANY_NOTIFICATION_SETTING_TEST,
    ],
    [NotificationSettingPermissionSet.Write]: [
      PermissionType.WRITE_ANY_NOTIFICATION_SETTING,
      PermissionType.DELETE_ANY_NOTIFICATION_SETTING,
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
