import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

interface IProps extends FontAwesomeIconProps {
  marginRight?: string
  marginTop?: string
}

/**
 * Icon to go inside a RoundedButton
 */
export const ButtonIcon = styled(({ marginRight, marginTop, ...rest }: IProps) => <FontAwesomeIcon {...rest} />)`
  width: 15px;
  height: 15px;
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
`
