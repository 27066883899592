import { IPoint } from './GeographyTypes'

export class WeightedKmeansUtils {
  public static euclideanDistanceSquared(point1: IPoint, point2: IPoint): number {
    return (
      Math.pow(point1.coordinates[0] - point2.coordinates[0], 2) +
      Math.pow(point1.coordinates[1] - point2.coordinates[1], 2)
    )
  }
}
