import React from 'react'
import { st } from '../../locales/TranslationHelper'
import { IInputProps } from './InputTypes'
import { SingleChoiceInput } from './SingleChoiceInput'

interface IToggleInputProps extends IInputProps<boolean> {
  value?: boolean
  id?: string
}

export const ToggleInput = ({ value, onChange, invalid, id, ...inputAttributes }: IToggleInputProps) => (
  <SingleChoiceInput
    {...inputAttributes}
    choices={[
      { value: true, label: st.inputs.choice.yes() },
      { value: false, label: st.inputs.choice.no() },
    ]}
    value={value}
    invalid={invalid}
    onChange={onChange}
    id={id}
  />
)
