import { AxiosInstance } from 'axios'
import { IDailyRequestCountsResponse, IGetDailyRequestCountsQueryParams } from '../types'

export class RequestDailyCountResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async getDailyRequestCounts(params: IGetDailyRequestCountsQueryParams): Promise<IDailyRequestCountsResponse> {
    const res = await this.agent.get<IDailyRequestCountsResponse>('dailyRequestCounts', {
      params,
    })
    return res.data
  }
}
