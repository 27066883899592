import { GenericResource, IPublicFileResponse } from '@sparelabs/http-client-utils'
import { AxiosError, AxiosInstance } from 'axios'
import {
  IGeographyPatchBody,
  IGeographyPostBody,
  IGeographyResponse,
  IRoutingDataPostBody,
  IRoutingDataResponse,
  Resources,
  RoutingDataVersion,
} from '../types'

export class GeographyResource extends GenericResource<
  IGeographyResponse,
  never,
  IGeographyPatchBody,
  IGeographyPostBody
> {
  public get = this.buildGet()
  public post = this.buildPost()
  public patch = this.buildPatch()

  constructor(agent: AxiosInstance) {
    super(Resources.Geographies, agent)
  }

  public async list(): Promise<IGeographyResponse[]> {
    const { data } = await this.agent.get<IGeographyResponse[]>(`${this.resource}`)
    return data
  }

  public async getRoutingData(geographyId: string, version?: RoutingDataVersion): Promise<IRoutingDataResponse | null> {
    try {
      const { data } = await this.agent.get<IRoutingDataResponse>(`${this.resource}/${geographyId}/routingData`, {
        params: { version },
      })
      return data
    } catch (error) {
      // We ignore 404 errors because geographies may not have routing data
      // when they are new and their routing data is not created yet.
      if ((error as AxiosError).response?.status === 404) {
        return null
      }

      throw error
    }
  }

  public async postRoutingData(geographyId: string, body: IRoutingDataPostBody): Promise<void> {
    await this.agent.post<never>(`${this.resource}/${geographyId}/routingData`, body)
  }

  public async uploadChangeSet(fileData: File): Promise<IPublicFileResponse> {
    const formData = new FormData()
    formData.append('upload', fileData as unknown as Blob)
    const res = await this.agent.post<IPublicFileResponse>(`${this.resource}/changeSetFile`, formData)
    return res.data
  }
}
