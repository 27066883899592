import { IPoint } from '@sparelabs/geography'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'
import { IRequestAccessibilityFeature } from './AccessibilityTypes'
import { IRequestRiderType } from './RequestRiderTypes'
import { RequestIntentType } from './RequestTypes'
import { TimeRuleDays } from './TimeRuleTypes'

export enum RequestRecurrenceStatus {
  Active = 'active',
  Deactivated = 'deactivated',
}

export interface IRequestRecurrenceDayOverride {
  date: string
  enableInbound: boolean
  enableOutbound: boolean
}

export interface IRequestRecurrenceResponse extends IModelJsonValues {
  status: RequestRecurrenceStatus

  riderId: string
  serviceId: string
  paymentMethodId: string | null
  paymentMethodTypeId: string | null

  requestedPickupAddress: string
  requestedPickupLocation: IPoint
  requestedDropoffAddress: string
  requestedDropoffLocation: IPoint
  riders: IRequestRiderType[]
  accessibilityFeatures: IRequestAccessibilityFeature[]
  notes: string | null
  requestMetadata: Record<string, unknown>

  outboundTime: number
  outboundTimeIntent: RequestIntentType
  inboundTime: number | null
  inboundTimeIntent: RequestIntentType | null
  startDate: string
  endDate: string | null
  days: TimeRuleDays[]
  dayOverrides: IRequestRecurrenceDayOverride[]

  pickupNotes: string | null
  dropoffNotes: string | null
}

export interface IRequestRecurrenceListQueryParams extends IListModelQueryParamValues {
  status?: RequestRecurrenceStatus
  riderId?: string
  serviceId?: string
}

export interface IRequestRecurrencePostBody {
  riderId: string
  serviceId: string
  requestedPickupAddress: string
  requestedPickupLocation: IPoint
  requestedDropoffAddress: string
  requestedDropoffLocation: IPoint
  riders: IRequestRiderType[]
  outboundTime: number
  outboundTimeIntent: RequestIntentType
  startDate: string
  endDate: string | null
  days: TimeRuleDays[]

  paymentMethodId?: string | null
  accessibilityFeatures?: IRequestAccessibilityFeature[]
  notes?: string | null
  requestMetadata?: Record<string, unknown>
  inboundTime?: number | null
  inboundTimeIntent?: RequestIntentType | null
  dayOverrides?: IRequestRecurrenceDayOverride[]
  pickupNotes?: string | null
  dropoffNotes?: string | null
}

export interface IRequestRecurrencePatchBody extends Partial<IRequestRecurrencePostBody> {
  status?: RequestRecurrenceStatus
}
