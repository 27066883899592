import { CustomFieldVisibility, ICustomFieldResponse } from '@sparelabs/api-client'
import { Email } from '@sparelabs/core'
import { PhoneParser } from '@sparelabs/phone-parser'
import { fromPairs } from 'lodash'
import { st } from 'src/locales/TranslationHelper'

export enum ValidateConstraintType {
  PhoneNumber = 'phoneNumber',
  Email = 'email',
}

export interface IValidateConstraint {
  type?: ValidateConstraintType
  required?: boolean
  maxStringLength?: number
}

export interface IValidateFormField {
  key: string
  label: string
  constraint: IValidateConstraint
}

export class FormValidator {
  public static isFormValid(values: Record<string, unknown>, fields: IValidateFormField[]): boolean {
    for (const field of fields) {
      if (this.validate(values[field.key], field.label, field.constraint)) {
        return false
      }
    }
    return true
  }

  public static areCustomFieldsValid(values: Record<string, unknown>, fields: ICustomFieldResponse[]): boolean {
    const fieldMap = fromPairs(fields.map((field) => [field.key, field]))
    for (const key of Object.keys(fieldMap)) {
      if (this.validateCustomField(values[key], fieldMap[key])) {
        return false
      }
    }
    return true
  }

  public static validateCustomField(value: unknown, field: ICustomFieldResponse): string | undefined {
    if (field.riderInterfaceVisibility === CustomFieldVisibility.Edit) {
      return this.validate(value, field.label, { required: field.isRequired })
    }

    return this.validate(value, field.label)
  }

  public static validate(
    value: unknown,
    label: string = st.validation.value(),
    constraint?: IValidateConstraint
  ): string | undefined {
    if (!constraint) {
      return undefined
    }
    if (constraint.required && (value === undefined || value === null || value === '')) {
      return st.validation.required({ label })
    }

    if (constraint.type) {
      switch (constraint.type) {
        case ValidateConstraintType.PhoneNumber:
          return this.isPhoneNumberValid(value) ? undefined : st.validation.invalidPhoneNumber()
        case ValidateConstraintType.Email:
          return this.isEmailValid(value) ? undefined : st.validation.invalid({ label })
      }
    }

    if (constraint.maxStringLength !== undefined) {
      return ((value as string | null | undefined) ?? '').length > constraint.maxStringLength
        ? st.validation.maxLength({ label, maxLength: constraint.maxStringLength })
        : undefined
    }
    return undefined
  }

  private static isPhoneNumberValid(value: unknown): boolean {
    return !value || PhoneParser.validate(value as string)
  }

  private static isEmailValid(value: unknown): boolean {
    return !value || Email.isValid(value as string)
  }
}
