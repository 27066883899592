import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { ITicketInstanceListQueryParams, ITicketInstancePostBody, ITicketInstanceResponse, Resources } from '../types'

export class TicketInstanceResource extends GenericResource<
  ITicketInstanceResponse,
  ITicketInstanceListQueryParams,
  never,
  ITicketInstancePostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()

  public activateTicket = async (id: string) => {
    await this.agent.post(`${this.resource}/${id}/activate`)
  }

  public cancelTicket = async (id: string) => {
    await this.agent.post(`${this.resource}/${id}/cancel`)
  }

  constructor(agent: AxiosInstance) {
    super(Resources.TicketInstances, agent)
  }
}
