import moment from 'moment-timezone'
import { dateFormat } from './DateUtils'
import { Time } from './Time'

export const LAST_TIMEZONE = 'Etc/GMT+12' // UTC -12, the timezone that would celebrate New Year's last
export const FIRST_TIMEZONE = 'Pacific/Kiritimati' // UTC +14, the timezone that would celebrate New Year's first

export class TimezoneHelper {
  /**
   * @param date Date in YYYY-MM-DD format
   * @param secondsFromMidnight Offset from 0 representing time of day in seconds
   * @param timezone
   */
  public static buildTimestamp(date: string, secondsFromMidnight: number, timezone: string): number {
    return this.buildMoment(date, secondsFromMidnight, timezone).unix()
  }

  /**
   * @param date Date in YYYY-MM-DD format
   * @param secondsFromMidnight Offset from 0 representing time of day in seconds
   * @param timezone
   * @param gmtOffset Optional. Timezone offset from GMT (e.g. "-07:00"). Only use to specify earlier or later duplicate moment during DST fall back
   */
  public static buildMoment(
    date: string,
    secondsFromMidnight: number,
    timezone: string,
    gmtOffset?: string | undefined
  ): moment.Moment {
    const duration = moment.duration(secondsFromMidnight, 'seconds')
    const hour = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()
    const offset = gmtOffset ?? ''
    return moment.tz(`${date} ${hour}:${minutes}:${seconds}${offset}`, `${dateFormat} H:m:s`, timezone)
  }

  /**
   * @param ts Timestamp to get the date of
   * @param timezone
   */
  public static parseDate(ts: number, timezone: string): string {
    return moment.unix(ts).tz(timezone).format(dateFormat)
  }

  public static getCurrentDate(timezone: string): string {
    return this.parseDate(Time.getNowTs(), timezone)
  }

  public static findMondaySomewhereOnEarth(): string | null {
    const firstTimezoneMoment = moment.tz(FIRST_TIMEZONE)
    const lastTimezoneMoment = moment.tz(LAST_TIMEZONE)
    if (firstTimezoneMoment.isoWeekday() === 1) {
      return firstTimezoneMoment.format(dateFormat)
    }
    if (lastTimezoneMoment.isoWeekday() === 1) {
      return lastTimezoneMoment.format(dateFormat)
    }
    // It is not Monday anywhere in the world. Die now
    return null
  }
}
