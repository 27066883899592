import React from 'react'
import styled from 'styled-components'
import { IInputProps } from '../../InputTypes'
import { ISingleChoice } from '../SingleChoice'
import { SingleChoiceCustomInput } from '../SingleChoiceCustomInput'

const ColorCircle = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background-color: ${(props) => props.color};
  display: inline-block;
`

export interface ISingleChoiceColorInput extends Omit<IInputProps<string>, 'onChange'> {
  colorChoices: string[]
  onChange: (value: string) => void
  id?: string
  value?: string
  disabled?: boolean
}

export const SingleChoiceColorInput = ({
  colorChoices,
  onChange,
  value,
  disabled,
  id,
  ...inputAttributes
}: ISingleChoiceColorInput): JSX.Element => {
  const renderButtonLabel = (color: string) => <ColorCircle color={color} />

  return (
    <SingleChoiceCustomInput<ISingleChoice>
      {...inputAttributes}
      choices={colorChoices.map((color) => ({
        value: color,
      }))}
      renderOption={(choice: ISingleChoice) => renderButtonLabel(choice.value)}
      onChange={(choice: ISingleChoice) => onChange(choice.value)}
      value={value}
      disabled={disabled}
      id={id}
    />
  )
}
