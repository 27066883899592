import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  INotificationProviderConfigurationListResponse,
  INotificationProviderConfigurationQueryParams,
  Resources,
} from '../types'

export class NotificationProviderConfigurationResource extends GenericResource<{}, {}, {}, {}> {
  constructor(agent: AxiosInstance) {
    super(Resources.NotificationProviderConfiguration, agent)
  }

  public async list(
    params: INotificationProviderConfigurationQueryParams
  ): Promise<INotificationProviderConfigurationListResponse> {
    const { data } = await this.agent.get<INotificationProviderConfigurationListResponse>(
      `${Resources.NotificationProviderConfiguration}`,
      {
        params,
      }
    )

    return data
  }
}
