import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IPostStripeSessionBody,
  IPostStripeSessionResponse,
  IPostStripeSetupIntentBody,
  IPostStripeSetupIntentResponse,
  Resources,
} from '../types'

export class ConnectedAccountResource extends GenericResource<never, never, never, never> {
  constructor(agent: AxiosInstance) {
    super(Resources.ConnectedAccount, agent)
  }

  public async postStripeSession(body: IPostStripeSessionBody): Promise<IPostStripeSessionResponse> {
    const { data } = await this.agent.post<IPostStripeSessionResponse>('connectedAccounts/stripe/session', body)
    return data
  }

  public async postStripeSetupIntent(body: IPostStripeSetupIntentBody): Promise<IPostStripeSetupIntentResponse> {
    const { data } = await this.agent.post<IPostStripeSetupIntentResponse>('connectedAccounts/stripe/setupIntent', body)
    return data
  }
}
