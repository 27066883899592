import { AllReportsModels } from './AnalyzeReportModelTypes'

export enum AllCasesReportRiderColumns {
  RiderId = 'riderId',
  RiderName = 'riderName',
}

export enum AllCasesReportCaseColumns {
  CaseId = 'caseId',
  Received = 'received',
  CaseTemplateId = 'caseTemplateId',
  CaseTemplateName = 'caseTemplateName',
  Status = 'status',
  AssignedToId = 'assignedToId',
  AssignedToName = 'assignedToName',
  TimerLimit = 'timerLimit',
  TimerState = 'timerState',
  TimerDaysCounted = 'timerDaysCounted',
  TimerDaysUntilLimit = 'timerDaysUntilLimit',
  TimerDeadline = 'timerDeadline',
  Notes = 'notes',
}

/**
 * Models that are included in the All Cases Report
 *
 * The order here controls the order of models on the 'Edit Columns' modal
 */
export const AllCasesReportModelNamesArray = [AllReportsModels.Rider, AllReportsModels.Case]

export type IAllCasesReportColumns = AllCasesReportRiderColumns | AllCasesReportCaseColumns

export const AllCasesReportModelAndColumns = {
  [AllReportsModels.Rider]: AllCasesReportRiderColumns,
  [AllReportsModels.Case]: AllCasesReportCaseColumns,
}

export const AllCasesReportColumnNames = Object.values<IAllCasesReportColumns>(AllCasesReportRiderColumns).concat(
  Object.values(AllCasesReportCaseColumns)
)
