import { WebColors } from '@sparelabs/colors'
import { BrowserLanguageHelper } from '@sparelabs/translate'
// eslint-disable-next-line no-restricted-imports
import 'bootstrap/dist/css/bootstrap.css'
import React from 'react'
import ReactDOM from 'react-dom'
// eslint-disable-next-line no-restricted-imports
import 'react-toastify/dist/ReactToastify.css'
import { initializeFontAwesome } from 'src/helpers/FontAwesomeHelper'
import { AllRoutes } from 'src/routes/AllRoutes'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    background-color: ${WebColors.backgroundSecondary};
  }
  .custom-toast {
    padding: 14px 16px;
    background: #222222;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: #ffffff;
    font-size: 16px;
    line-height: 20px;
    min-height: auto;
  }

  .custom-toast-body {
    margin: 0px;
  }

  // Global override to prevent zoom-in on iOS devices (including iPad Pros that go beyond our xl breakpoint)
  input, textarea {
    font-size: 16px !important;
  }
`

// Declares the language code on the html of the page. Improves a11y
document.documentElement.lang = BrowserLanguageHelper.getCurrentLanguageCode()

function init() {
  initializeFontAwesome()
  ReactDOM.render(
    <React.StrictMode>
      <GlobalStyle />
      <AllRoutes />
    </React.StrictMode>,
    document.getElementById('root')
  )
}

init()
