import { IListModelQueryParamValues } from '@sparelabs/http-client-utils'

export interface IDutyConversation {
  id: string
  dutyId: string
  organizationId: string
}

export interface IDutyConversationMessage {
  id: string
  conversationId: string
  message: string
  authorType: DutyConversationAuthorType
  authorId: string
  sentTs: number
}

export enum DutyConversationAuthorType {
  Driver = 'driver',
  Dispatch = 'dispatch',
}

export interface IDutyConversationResponse {
  id: string
  dutyId: string
  messages: IDutyConversationMessage[]
}

export interface IDutyConversationListParams extends IListModelQueryParamValues {
  dutyId: string
}
