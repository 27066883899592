import { WebColors } from '@sparelabs/colors'
import React, { useState } from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, InputGroupButtonDropdown } from 'reactstrap'
import styled, { css } from 'styled-components'
import { INPUT_HEIGHT, InputFocusTheme } from '../../../util'

interface IProps {
  units: string[]
  selectedUnit: string
  onChange: (unit: string) => void
}

const unitContainerStyle = css`
  background-color: white;
  color: ${WebColors.contentPrimary};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 0 0 1px ${WebColors.borderPrimary} inset;
  padding: 8px;
  font-size: 15px;
  border: none;
  line-height: 1;
  display: flex;
  align-items: center;
  height: ${INPUT_HEIGHT};
`

const StyledDropdownToggle = styled(DropdownToggle)`
  ${unitContainerStyle};
  :active,
  :not(disabled) {
    background-color: white !important;
    color: ${WebColors.contentPrimary} !important;
    box-shadow: 0 0 0 1px ${WebColors.borderPrimary} inset !important;
    transform: none !important;
  }
  :focus {
    ${InputFocusTheme}
  }
  :hover {
    background-color: ${WebColors.backgroundPrimaryHover} !important;
  }
  ::after {
    margin-left: 8px;
    margin-right: 2px;
    align-items: center;
  }
`

const StyledDropdownMenu = styled(DropdownMenu)`
  border: none;
  border-radius: 8px;
  padding: 0px;
  margin-top: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  min-width: 0;
`
const StyledDropdownItem = styled(DropdownItem)`
  line-height: ${INPUT_HEIGHT};
  font-size: 15px;
  padding: 0 8px;
  margin: 0;
`

const SingleUnitContainer = styled.div`
  ${unitContainerStyle}
`

export const StyledUnitsDropdown = ({ units, selectedUnit, onChange }: IProps) => {
  if (!units.length) {
    throw new Error('Must provide at least one unit')
  }
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen)

  return (
    <InputGroupButtonDropdown
      addonType='append'
      isOpen={dropdownOpen}
      toggle={toggleDropDown}
      disabled={units.length === 1}
    >
      {units.length === 1 ? (
        <SingleUnitContainer>{selectedUnit}</SingleUnitContainer>
      ) : (
        <>
          <StyledDropdownToggle caret>{selectedUnit}</StyledDropdownToggle>
          <StyledDropdownMenu>
            {units.map((unit: string) => (
              <StyledDropdownItem key={unit} onClick={() => onChange(unit)}>
                {unit}
              </StyledDropdownItem>
            ))}
          </StyledDropdownMenu>
        </>
      )}
    </InputGroupButtonDropdown>
  )
}
