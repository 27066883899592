import { IListModelQueryParamValues } from '@sparelabs/http-client-utils'
import { OAuthScope } from '@sparelabs/permissions'
import { IAppPublicResponse } from './AppTypes'

export interface IAppInstallationResponse {
  id: string
  organizationId: string
  app: IAppPublicResponse
  grantedScopes: OAuthScope[]
  createdAt: number
  updatedAt: number
}

export interface IListAppInstallationQueryParams extends IListModelQueryParamValues {
  appId?: string
}

export enum AppInstallationStatus {
  Installed = 'installed',
  updatePermissions = 'updatePermissions',
}
