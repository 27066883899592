import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum ReviewPermissionSet {
  View = 'review_view',
  New = 'review_new',
}

export const reviewPermissionSetGrouping: IPermissionSetGrouping<ReviewPermissionSet> = {
  key: PermissionSetGroupingKey.Review,
  active: {
    [ReviewPermissionSet.View]: [PermissionType.GET_ANY_REVIEW],
    [ReviewPermissionSet.New]: [PermissionType.POST_ANY_REVIEW],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
