import { MINUTE } from '@sparelabs/time'
import { DutyStatus, SlotType } from '../types'

export class DutySemantics {
  /**
   * How far in advance a duty is allowed to start before its scheduled start time (duty.startRequestedTs)
   */
  public static earliestAllowedDutyStart = 5 * MINUTE

  public static isStartingDutyTooEarly(duty: { startRequestedTs: number }, slotCompletedTs: number): boolean {
    return slotCompletedTs <= duty.startRequestedTs - this.earliestAllowedDutyStart
  }

  public static canCompleteSlot(duty: { status: DutyStatus }, slot: { type: SlotType }): boolean {
    return (
      (duty.status === DutyStatus.Scheduled && slot.type === SlotType.StartLocation) ||
      (duty.status === DutyStatus.InProgress && slot.type !== SlotType.StartLocation)
    )
  }
}
