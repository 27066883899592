import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  IFormContentPatchBody,
  IFormContentPostBody,
  IFormContentResponse,
  IListFormContentQueryParams,
} from '../types'
import { Resources } from './ResourcesType'

export class FormContentResource extends GenericResource<
  IFormContentResponse,
  IListFormContentQueryParams,
  IFormContentPatchBody,
  IFormContentPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()
  constructor(agent: AxiosInstance) {
    super(Resources.FormContents, agent)
  }
}
