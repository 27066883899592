import { IdMap } from '@sparelabs/core'
import { MetadataError } from '@sparelabs/error-types'
import { ILogger } from '@sparelabs/logging'
import { MINUTE } from '@sparelabs/time'
import { sumBy } from 'lodash'
import { RequestSemantics } from '../request'
import { ISlotBin } from '../slot'
import { AccessibilityFeature, IRequestAccessibilityFeature } from '../types'
import {
  IDriverBreakBoardingSettings,
  IRequestBoardingSettings,
  IServiceBoardingSettings,
  ISlotBoardingInfo,
} from './BoardingTimeTypes'

export class BoardingTimeCalculator {
  public static readonly DEFAULT_ACCESSIBILITY_EXTRA_BOARDING_TIME = MINUTE

  /**
   *  Boarding Time Intervals are calculated in the following way:
   *
   *  Boarding time for a bin is added to the interval between that bin and the next bin
   *
   *  Example (resulting boardingTime is specified in output):
   *
   *  Input: (L1), (L1), (L1), (L2)
   *  Output: (L1) 0
   *          (L1) 0
   *          (L1) baseBoardingTime
   *          (L2)
   */
  public static calculateBoardingTimes(
    serviceMap: IdMap<IServiceBoardingSettings>,
    bins: Array<ISlotBin<ISlotBoardingInfo>>,
    requestMap: IdMap<IRequestBoardingSettings>,
    breakMap: IdMap<IDriverBreakBoardingSettings>,
    logger: ILogger
  ): number[] {
    const boardingTimes: number[] = []

    // Since boarding time is counted as the time by which the slots after you are delayed,
    // the last bin will have boarding time of 0
    for (const bin of bins.slice(0, -1)) {
      const binBoardingTime = this.calculateBoardingTimesForBin(serviceMap, bin.slots, requestMap, breakMap, logger)
      boardingTimes.push(binBoardingTime)
    }
    boardingTimes.push(0)
    return boardingTimes
  }

  private static calculateBoardingTimesForBin(
    serviceMap: IdMap<IServiceBoardingSettings>,
    slots: ISlotBoardingInfo[],
    requestMap: IdMap<IRequestBoardingSettings>,
    breakMap: IdMap<IDriverBreakBoardingSettings>,
    logger: ILogger
  ): number {
    // only add base boarding time if there is at least one slot in this bin that should incur a boarding time
    let baseBoardingTime = 0

    const sumOfAdditionalBoardingTimes = sumBy(slots, (slot) => {
      const request = slot.requestId ? requestMap.get(slot.requestId) : undefined
      const driverBreak = slot.driverBreakId && breakMap.get(slot.driverBreakId)
      // only slots associated with valid requests are relevant to boarding time calculations
      if (request && this.incursBoardingTime(request)) {
        const serviceBoardingInfo = this.getServiceBoardingInfo(request, serviceMap)
        // base boarding time for this bin is the max from all the services incurring boarding time in this bin
        baseBoardingTime = Math.max(baseBoardingTime, serviceBoardingInfo.baseBoardingTime)
        return this.evaluateRequestExtraBoardingTime(serviceBoardingInfo, request, logger)
      }
      if (driverBreak) {
        return driverBreak.breakLength
      }
      return 0
    })
    return baseBoardingTime + sumOfAdditionalBoardingTimes
  }

  private static getServiceBoardingInfo(
    request: IRequestBoardingSettings,
    serviceMap: IdMap<IServiceBoardingSettings>
  ): IServiceBoardingSettings {
    const serviceBoardingInfo = serviceMap.get(request.serviceId)
    if (!serviceBoardingInfo) {
      throw new MetadataError(`Missing boarding info for service ${request.serviceId}`, {
        serviceId: request.serviceId,
        serviceIdWithSettings: Array.from(serviceMap.keys()),
      })
    }
    return serviceBoardingInfo
  }

  private static incursBoardingTime(request: IRequestBoardingSettings): boolean {
    /**
     * Flagdown pickup slots do not incur a boarding time because:
     *  1. They are never used to calculate ETAs, since they cannot be scheduled for a time in the future from now
     *  2. Treating flagdown pickup slots in this way allows a duty to pickup flagdowns even when it is running late
     *     (driver discretion)
     *
     * Additionally, since the addition of the "no return to dropoff" constraint, we also ignore boarding time for flagdown
     * dropoffs to avoid cases where the only feasible way to add a flagdown request would cause a "drive by dropoff".
     */
    return !RequestSemantics.isFlagDownRequest(request)
  }

  public static evaluateRequestBoardingTime(
    serviceBoardingInfo: IServiceBoardingSettings,
    request: IRequestBoardingSettings,
    logger: ILogger
  ): number {
    const extraBoardingTime = this.evaluateRequestExtraBoardingTime(serviceBoardingInfo, request, logger)
    return extraBoardingTime + serviceBoardingInfo.baseBoardingTime
  }

  public static evaluateRequestExtraBoardingTime(
    serviceBoardingInfo: IServiceBoardingSettings,
    request: IRequestBoardingSettings,
    logger: ILogger
  ): number {
    const contextMetadata = {
      serviceBoardingInfo,
      request,
    }
    const accessibilityExtraBoardingTime = sumBy(
      request.accessibilityFeatures,
      (requestFeature: IRequestAccessibilityFeature) =>
        this.getExtraBoardingTimeForAccessibilityFeature(requestFeature, serviceBoardingInfo, logger, contextMetadata) *
        requestFeature.count
    )
    return accessibilityExtraBoardingTime
  }

  private static getExtraBoardingTimeForAccessibilityFeature(
    feature: IRequestAccessibilityFeature,
    serviceBoardingInfo: IServiceBoardingSettings,
    logger: ILogger,
    contextMetadata: {}
  ): number {
    const serviceFeature = this.findAccessibilityFeature(feature, serviceBoardingInfo, logger, {
      ...contextMetadata,
      accessibilityFeatureProvider: 'service',
    })
    if (serviceFeature) {
      return serviceFeature.defaultExtraBoardingTime
    }
    /**
     * This can happen in certain edge cases e.g. if accessibility features have been removed from the service
     * since the request was created and matched.
     * TODO: Find a less hacky solution. (E.g. storing this number in the request upon creation?)
     */
    return this.DEFAULT_ACCESSIBILITY_EXTRA_BOARDING_TIME
  }

  private static findAccessibilityFeature<T extends { type: AccessibilityFeature }>(
    requiredFeature: IRequestAccessibilityFeature,
    featureProvider: { accessibilityFeatures: T[] },
    logger: ILogger,
    contextMetadata: {}
  ): T | null {
    const foundFeature = featureProvider.accessibilityFeatures.find((feature) => feature.type === requiredFeature.type)
    if (foundFeature === undefined) {
      logger.warn(`Missing accessibility feature: ${JSON.stringify(requiredFeature)}`, contextMetadata)
      return null
    }
    return foundFeature
  }
}
