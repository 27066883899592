export enum SocketServerEvents {
  Connection = 'connection',
  Disconnecting = 'disconnecting',
  Disconnect = 'disconnect',
}

export enum SocketClientEvents {
  Connect = 'connect',
  ConnectError = 'connect_error',
  ConnectTimeout = 'connect_timeout',
  Error = 'error',
  Disconnect = 'disconnect',
  Reconnect = 'reconnect',
  ReconnectAttempt = 'reconnect_attempt',
  Reconnecting = 'reconnecting',
  ReconnectError = 'reconnect_error',
  ReconnectFailed = 'reconnect_failed',
  Ping = 'ping',
  Pong = 'pong',
}

export enum AuthEvents {
  AuthenticationRequest = 'authenticationRequest',
  AuthenticationSuccess = 'authenticationSuccess',
}

export enum RoomEventsName {
  Join = 'join',
  Leave = 'leave',
}

export enum AdminSocketRoomName {
  VehicleLocations = 'vehicleLocations',
}
