import { GenericResource, IListModelQueryParamValues } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import { IAuthorizationPermissionSetResponse, Resources } from '../types'

export class AuthorizationPermissionSetResource extends GenericResource<
  IAuthorizationPermissionSetResponse,
  IListModelQueryParamValues,
  never,
  never
> {
  constructor(agent: AxiosInstance) {
    super(Resources.AuthorizationPermissionSets, agent)
  }

  public list = this.buildList()
}
