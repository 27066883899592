import { WebColors } from '@sparelabs/colors'
import React from 'react'
import styled from 'styled-components'

export interface IPopoverWithDescription {
  title: string
  description?: string
}

const PopoverDescriptionContainer = styled.div`
  text-align: start !important;
`

const Title = styled.div`
  color: ${WebColors.contentPrimary};
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 5px;
`

const Description = styled.div`
  color: ${WebColors.contentSecondary};
  font-size: 13px;
  line-height: 22.5px;
  font-weight: 350;
`

export const PopoverWithDescription = ({ title, description }: IPopoverWithDescription): JSX.Element => (
  <PopoverDescriptionContainer>
    <Title> {title}</Title>
    <Description>{description}</Description>
  </PopoverDescriptionContainer>
)
