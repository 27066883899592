import { AxiosInstance } from 'axios'
import {
  IGlobalApiRegionInformation,
  IGlobalMobileAppResponse,
  IGlobalMobileAppServerResponse,
  IGlobalOrganizationResponse,
  IGlobalOrganizationsQueryParams,
  IGlobalProxyWebhookPostBody,
} from '../types'

export class GlobalResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async getServer(mobileAppId: string): Promise<IGlobalMobileAppServerResponse> {
    const res = await this.agent.get<IGlobalMobileAppServerResponse>(`global/mobileApps/${mobileAppId}/server`)
    return res.data
  }

  public async getGlobalApp(mobileAppId: string): Promise<IGlobalMobileAppResponse> {
    const res = await this.agent.get<IGlobalMobileAppResponse>(`global/mobileApps/${mobileAppId}`)
    return res.data
  }

  public async getOrganizations(
    query: IGlobalOrganizationsQueryParams
  ): Promise<{ data: IGlobalOrganizationResponse[] }> {
    const res = await this.agent.get<{ data: IGlobalOrganizationResponse[] }>('global/organizations', { params: query })
    return res.data
  }

  public async getOrganization(organizationId: string): Promise<IGlobalOrganizationResponse> {
    const res = await this.agent.get<IGlobalOrganizationResponse>(`global/organizations/${organizationId}`)
    return res.data
  }

  public async getOrganizationByKey(key: string): Promise<IGlobalOrganizationResponse> {
    const res = await this.agent.get<IGlobalOrganizationResponse>(`global/organizations/key/${key}`)
    return res.data
  }

  public async getGlobalApiInformation(): Promise<IGlobalApiRegionInformation[]> {
    const res = await this.agent.get<IGlobalApiRegionInformation[]>('global/regions')
    return res.data
  }

  public async proxyOpenFleetWebhook(body: IGlobalProxyWebhookPostBody): Promise<void> {
    await this.agent.post<IGlobalProxyWebhookPostBody>('global/openFleetWebhook', body)
  }
}
