import { GenericResource } from '@sparelabs/http-client-utils'
import { AxiosInstance } from 'axios'
import {
  ICaseLetterDownloadBody,
  ICaseLetterPatchBody,
  ICaseLetterPostBody,
  ICaseLetterResponse,
  IListCaseLetterQueryParams,
} from '../types'
import { Resources } from './ResourcesType'

export class CaseLetterResource extends GenericResource<
  ICaseLetterResponse,
  IListCaseLetterQueryParams,
  ICaseLetterPatchBody,
  ICaseLetterPostBody
> {
  public get = this.buildGet()
  public list = this.buildList()
  public post = this.buildPost()
  public patch = this.buildPatch()

  public async download(body: ICaseLetterDownloadBody): Promise<string> {
    const response = await this.agent.post<string>(`${this.resource}/download`, body, {
      responseType: 'blob',
    })
    return response.data
  }

  constructor(agent: AxiosInstance) {
    super(Resources.CaseLetters, agent)
  }
}
