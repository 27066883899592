import { WebColors } from '@sparelabs/colors'
import type { IPublicEngageFormResponse } from '@sparelabs/engage-client'
import { RoundedButton, SubmitButtonTheme } from '@sparelabs/web-ui-components'
import React from 'react'
import { st } from 'src/locales/TranslationHelper'
import styled from 'styled-components'
import { ActionButtonsWrapper } from './Layout'

const t = st.forms.acceptTerms

const TermsWrapper = styled.div`
  color: ${WebColors.contentTertiary};
  font-size: 12px;
  flex-grow: 1;
`

export interface ITermsSectionProps {
  terms: IPublicEngageFormResponse['organizationTerms']
  continueButtonType?: string
  onContinueClick?: () => void
}

export const TermsSection = ({
  terms,
  continueButtonType = 'button',
  onContinueClick = () => null,
}: ITermsSectionProps): JSX.Element => (
  <ActionButtonsWrapper>
    <TermsWrapper>
      {t.part1()}
      <a href={terms.termsOfUseUrl} target='_blank' rel='noreferrer'>
        {t.linkTermsOfUseText()}
      </a>
      {t.part2()}
      <a href={terms.privacyPolicyUrl} target='_blank' rel='noreferrer'>
        {t.linkPrivacyPolicyText()}
      </a>
      {t.punctuation()}
    </TermsWrapper>
    <RoundedButton theme={SubmitButtonTheme} type={continueButtonType} onClick={onContinueClick}>
      {st.common.continue()}
    </RoundedButton>
  </ActionButtonsWrapper>
)
