import { AllCasesReportColumnNames, IAllCasesReportColumns } from './AnalyzeAllCasesReportColumnTypes'
import {
  CaseActivityLogReportColumnNames,
  ICaseActivityLogReportColumns,
} from './AnalyzeCaseActivityLogReportColumnTypes'
import { CaseTypeReportColumnNames, ICaseTypeReportColumns } from './AnalyzeCaseTypeReportColumnTypes'
import { DataRetentionReportColumnNames, DataRetentionReportColumnTypes } from './AnalyzeDataRetentionReportColumnTypes'
import { DriverReportColumnNames, IDriverReportColumns } from './AnalyzeDriverReportColumnTypes'
import {
  FleetNTDReportColumnNames,
  IFleetNTDReportColumns,
  IServiceNTDReportColumns,
  ServiceNTDReportColumnNames,
} from './AnalyzeNTDReportColumnTypes'
import { IRidershipReportColumns, RidershipReportColumnNames } from './AnalyzeRidershipReportColumnTypes'
import { IOpenFleetReportColumns, OpenFleetReportColumnNames } from './OpenFleetReportColumnTypes'

/**
 * The type of report determines what columns are available, and what query is used to fetch the report data
 */
export enum ReportType {
  Ridership = 'ridership',
  /**
   * This has been renamed as 'Duty' on the front-end
   */
  Driver = 'driver',
  ServiceNTD = 'serviceNTD',
  FleetNTD = 'fleetNTD',
  AllCases = 'allCases',
  CaseActivityLog = 'caseActivityLog',
  OpenFleetReport = 'openFleetReport',
  DataRetention = 'dataRetention',
  CaseType = 'caseType',
}

export enum ReportGroup {
  Service = 'service',
  Fleet = 'fleet',
  /**
   * Report templates are custom reports that are created by users
   */
  ReportTemplate = 'reportTemplate',
  DataRetention = 'dataRetention',
}

export enum ReportProduct {
  Launch = 'launch',
  Engage = 'engage',
}

export const LaunchReportTypes = [ReportType.Ridership, ReportType.Driver, ReportType.ServiceNTD, ReportType.FleetNTD]
export const EngageReportTypes = [
  ReportType.AllCases,
  ReportType.CaseActivityLog,
  ReportType.DataRetention,
  ReportType.CaseType,
]

export const ProductReportTypeMap: Record<ReportProduct, ReportType[]> = {
  [ReportProduct.Launch]: LaunchReportTypes,
  [ReportProduct.Engage]: EngageReportTypes,
}

/**
 * A mapping between the report type and the columns that are available for that report
 */
export const ReportTypesAndColumns = {
  [ReportType.Ridership]: RidershipReportColumnNames,
  [ReportType.Driver]: DriverReportColumnNames,
  [ReportType.ServiceNTD]: ServiceNTDReportColumnNames,
  [ReportType.FleetNTD]: FleetNTDReportColumnNames,
  [ReportType.AllCases]: AllCasesReportColumnNames,
  [ReportType.CaseActivityLog]: CaseActivityLogReportColumnNames,
  [ReportType.OpenFleetReport]: OpenFleetReportColumnNames,
  [ReportType.DataRetention]: DataRetentionReportColumnNames,
  [ReportType.CaseType]: CaseTypeReportColumnNames,
}

/**
 * All possible columns for all reports
 */
export type AllReportsColumns =
  | IRidershipReportColumns
  | IDriverReportColumns
  | IServiceNTDReportColumns
  | IFleetNTDReportColumns
  | IAllCasesReportColumns
  | ICaseActivityLogReportColumns
  | IOpenFleetReportColumns
  | DataRetentionReportColumnTypes
  | ICaseTypeReportColumns
