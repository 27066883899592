import { AxiosInstance } from 'axios'
import { IJourneyOptionsQueryParams, IJourneyOptionsResponse } from '../types'

export class JourneyOptionsResource {
  constructor(private readonly agent: AxiosInstance) {}

  public async list(params: IJourneyOptionsQueryParams): Promise<IJourneyOptionsResponse> {
    const { data } = await this.agent.get<IJourneyOptionsResponse>('/journeyOptions', { params })
    return data
  }
}
