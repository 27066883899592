import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum UserOwnPermissionSet {
  User = 'user_own_user',
  Rider = 'user_own_rider',
  Driver = 'user_own_driver',
  Admin = 'user_own_admin',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const userOwnPermissionSetGrouping: IPermissionSetGrouping<UserOwnPermissionSet> = {
  key: PermissionSetGroupingKey.UserOwn,
  active: {
    [UserOwnPermissionSet.User]: [
      PermissionType.GET_OWN_USER,
      PermissionType.PATCH_OWN_USER,
      PermissionType.DELETE_OWN_USER,
      PermissionType.POST_UPLOAD_PHOTO /* Used for uploading a profile photo for your own account */,
      PermissionType.POST_ANY_FILE /* Used for uploading files to users profiles */,
    ],
    [UserOwnPermissionSet.Rider]: [
      PermissionType.GET_OWN_RIDER,
      PermissionType.PATCH_OWN_RIDER,
    ],
    [UserOwnPermissionSet.Driver]: [
      PermissionType.GET_OWN_DRIVER,
      PermissionType.PATCH_OWN_DRIVER,
    ],
    [UserOwnPermissionSet.Admin]: [
      PermissionType.GET_OWN_ADMIN,
      PermissionType.PATCH_OWN_ADMIN,
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [
    UserOwnPermissionSet.Rider, // Used only in the rider app
    UserOwnPermissionSet.Driver // Used only in the driver app
  ],
}
