import { IModelJsonValues } from '@sparelabs/http-client-utils'
import { PermissionType } from '@sparelabs/permissions'

export interface IPartialUserDataResponse extends IModelJsonValues {
  email: string | null
  firstName: string | null
  lastName: string | null
  phoneNumber: string | null
  photoUrl: string | null
  rating: number
  numRatings: number
}

export interface IPartialUserDataPostBody {
  firstName?: string | null
  lastName?: string | null
  photoUrl?: string | null
  phoneNumber?: string | null
  email?: string | null
}

export interface IPartialUserDataPatchOwnBody {
  firstName?: string | null
  lastName?: string | null
  photoUrl?: string | null
  phoneNumber?: string | null
  email?: string | null
}

export interface IPartialUserDataPatchBody {
  firstName?: string | null
  lastName?: string | null
  photoUrl?: string
  phoneNumber?: string | null
  email?: string | null
}

export interface IUserStub {
  id: string
  firstName: string | null
  lastName: string | null
  phoneNumber: string | null
  email: string | null
  photoUrl: string | null
  rating: number
  numRatings: number
  lastSeenTs: number | null
}

export interface IUserMeResponse extends IPartialUserDataResponse {}

export interface IUserMePatchBody extends IPartialUserDataPatchBody {}

export enum VerificationMethod {
  Voice = 'voice',
  Sms = 'sms',
}

export interface IUserMeDeleteBody {
  organizationIds: string[]
}

export interface IUserMePermissionsResponse {
  permissions: PermissionType[]
}

export interface IUserMePhoneRequestPostBody {
  phoneNumber: string
  verificationMethod?: VerificationMethod
}

export interface IUserMePhoneVerifyPostBody {
  code: string
}
export interface IUserIntercomHashResponse {
  hash: string
}

export interface IUserSupportProviderHashResponse {
  hash: string
}
