import { IPermissionSetGrouping, PermissionSetGroupingKey } from '../PermissionSetTypes'
import { PermissionType } from '../PermissionType'

export enum AnnouncementPermissionSet {
  View = 'announcement_view',
  Write = 'announcement_write',
}

// Ignore prettier to ensure only one permission per line
// prettier-ignore
export const announcementPermissionSetGrouping: IPermissionSetGrouping<AnnouncementPermissionSet> = {
  key: PermissionSetGroupingKey.Announcement,
  active: {
    [AnnouncementPermissionSet.View]: [
      PermissionType.GET_ANY_ANNOUNCEMENT,
      PermissionType.LIST_ACTIVE_ANNOUNCEMENT,
    ],
    [AnnouncementPermissionSet.Write]: [
      PermissionType.WRITE_ANY_ANNOUNCEMENT
    ],
  },
  deprecated: [],
  unAssignablePermissionSets: [],
}
