import { IPoint } from '@sparelabs/geography'
import { IListModelQueryParamValues, IModelJsonValues } from '@sparelabs/http-client-utils'

export enum FavoriteLocationType {
  Home = 'home',
  Work = 'work',
  Community = 'community',
  Subway = 'subway',
  Heart = 'heart',
  Gym = 'gym',
  Restaurant = 'restaurant',
  Child = 'child',
  School = 'school',
  Other = 'other',
}

export interface IFavoriteLocationResponse extends IModelJsonValues {
  type: FavoriteLocationType
  address: string
  name: string | null
  location: IPoint
  userId: string
  locationNotes: string | null
}

export interface IFavoriteLocationPostBody {
  type: FavoriteLocationType
  name?: string | null
  location: IPoint
  address: string
  // If created by self scope userId is not necessary to provide
  userId?: string
  locationNotes?: string | null
}

export type IFavoriteLocationPatchBody = Partial<IFavoriteLocationPostBody>

export interface IFavoriteLocationListQueryParams extends IListModelQueryParamValues {
  userId?: string
  type?: FavoriteLocationType
}
