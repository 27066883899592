/**
 * Wraps an async function with loading state management. Does NOT handle errors.
 *
 * @param fn Async function (ex api call)
 * @param setLoadingState Hook function to set loading state
 * @returns function result
 */
export const withLoadingState =
  <T, R>(fn: (...args: T[]) => Promise<R>, setLoadingState: (val: boolean) => void) =>
  async (...args: T[]): Promise<R> => {
    setLoadingState(true)

    try {
      const result: R = await fn(...args)
      setLoadingState(false)
      return result
    } catch (error) {
      setLoadingState(false)
      throw error
    }
  }
