import { isString, uniqueId } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { INPUT_MAX_WIDTH } from '../../../util'
import { IInputProps } from '../InputTypes'
import { Choice, IChoice } from './Choice'

const Container = styled.div<{ stacked?: boolean; compact?: boolean }>`
  display: flex;
  flex-direction: ${({ stacked }) => (stacked ? 'column' : 'row')};
  row-gap: 8px;
  column-gap: ${({ compact }) => (compact ? '5px' : '16px')};
  flex-wrap: wrap;
  padding: 8px 0px;
  max-width: ${INPUT_MAX_WIDTH};
  line-height: initial;

  > * {
    margin: 0px;
  }
`

export interface IMultipleChoiceInputProps<T> extends Omit<IInputProps<T[]>, 'onChange' | 'value'> {
  choices: Array<IChoice<T>>
  groups?: Array<{ name: string; choices: T[] }>
  onChange: (value: T[]) => void
  values?: T[]
  className?: string
  id?: string
  stacked?: boolean
  compact?: boolean
}

export const MultipleChoiceInput = <T,>({
  choices,
  groups,
  values,
  className,
  id,
  stacked,
  compact,
  autoFocus,
  ...inputProps
}: IMultipleChoiceInputProps<T>): JSX.Element => {
  const containerId = isString(id) ? id : uniqueId('multiple-choice-input-')

  return (
    <Container className={className} id={containerId} stacked={stacked} compact={compact}>
      {choices.map((choice) => (
        <Choice
          {...inputProps}
          choice={choice}
          values={values}
          containerId={containerId}
          key={isString(choice.value) ? choice.value : JSON.stringify(choice.value)}
        />
      ))}
    </Container>
  )
}
