/**
 * A "safe" version of `Promise.all`. `Promise.all` is dangerous, because it allows for unbounded concurrency - e.g.
 * you can pass it an array of 1000 promises, which can overload downstream systems by making 1000 difficult calls at
 * once. `concurrently` only compiles with arrays of 2-10 promises.
 *
 * This is the function we suggest using if the number of concurrent operations you want to run is fixed. For example,
 * this is a good use case:
 *
 * ```typescript
 * const [rider, requests, paymentMethods] = await concurrently([
 *   getRider(riderId),
 *   getRecentRequests(riderId),
 *   getPaymentMethods(riderId),
 * ])
 * ```
 *
 * If you have a dynamic number of promises, you should use `asyncPool` instead (a different function in this same
 * library).
 *
 * @param values An array of promises
 * @returns A promise of a new array, containing the values of all the promises, once they all succeed. Or a rejection,
 *          if any of the promises are rejected
 */
export function concurrently<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10>(
  values: readonly [
    PromiseLike<T1>,
    PromiseLike<T2>,
    PromiseLike<T3>,
    PromiseLike<T4>,
    PromiseLike<T5>,
    PromiseLike<T6>,
    PromiseLike<T7>,
    PromiseLike<T8>,
    PromiseLike<T9>,
    PromiseLike<T10>
  ]
): Promise<[T1, T2, T3, T4, T5, T6, T7, T8, T9, T10]>

/**
 * A "safe" version of `Promise.all`. `Promise.all` is dangerous, because it allows for unbounded concurrency - e.g.
 * you can pass it an array of 1000 promises, which can overload downstream systems by making 1000 difficult calls at
 * once. `concurrently` only compiles with arrays of 2-10 promises.
 *
 * @param values An array of promises
 * @returns A promise of a new array, containing the values of all the promises, once they all succeed. Or a rejection,
 *          if any of the promises are rejected
 */
export function concurrently<T1, T2, T3, T4, T5, T6, T7, T8, T9>(
  values: readonly [
    PromiseLike<T1>,
    PromiseLike<T2>,
    PromiseLike<T3>,
    PromiseLike<T4>,
    PromiseLike<T5>,
    PromiseLike<T6>,
    PromiseLike<T7>,
    PromiseLike<T8>,
    PromiseLike<T9>
  ]
): Promise<[T1, T2, T3, T4, T5, T6, T7, T8, T9]>

/**
 * A "safe" version of `Promise.all`. `Promise.all` is dangerous, because it allows for unbounded concurrency - e.g.
 * you can pass it an array of 1000 promises, which can overload downstream systems by making 1000 difficult calls at
 * once. `concurrently` only compiles with arrays of 2-10 promises.
 *
 * @param values An array of promises
 * @returns A promise of a new array, containing the values of all the promises, once they all succeed. Or a rejection,
 *          if any of the promises are rejected
 */
export function concurrently<T1, T2, T3, T4, T5, T6, T7, T8>(
  values: readonly [
    PromiseLike<T1>,
    PromiseLike<T2>,
    PromiseLike<T3>,
    PromiseLike<T4>,
    PromiseLike<T5>,
    PromiseLike<T6>,
    PromiseLike<T7>,
    PromiseLike<T8>
  ]
): Promise<[T1, T2, T3, T4, T5, T6, T7, T8]>

/**
 * A "safe" version of `Promise.all`. `Promise.all` is dangerous, because it allows for unbounded concurrency - e.g.
 * you can pass it an array of 1000 promises, which can overload downstream systems by making 1000 difficult calls at
 * once. `concurrently` only compiles with arrays of 2-10 promises.
 *
 * @param values An array of promises
 * @returns A promise of a new array, containing the values of all the promises, once they all succeed. Or a rejection,
 *          if any of the promises are rejected
 */
export function concurrently<T1, T2, T3, T4, T5, T6, T7>(
  values: readonly [
    PromiseLike<T1>,
    PromiseLike<T2>,
    PromiseLike<T3>,
    PromiseLike<T4>,
    PromiseLike<T5>,
    PromiseLike<T6>,
    PromiseLike<T7>
  ]
): Promise<[T1, T2, T3, T4, T5, T6, T7]>

/**
 * A "safe" version of `Promise.all`. `Promise.all` is dangerous, because it allows for unbounded concurrency - e.g.
 * you can pass it an array of 1000 promises, which can overload downstream systems by making 1000 difficult calls at
 * once. `concurrently` only compiles with arrays of 2-10 promises.
 *
 * @param values An array of promises
 * @returns A promise of a new array, containing the values of all the promises, once they all succeed. Or a rejection,
 *          if any of the promises are rejected
 */
export function concurrently<T1, T2, T3, T4, T5, T6>(
  values: readonly [
    PromiseLike<T1>,
    PromiseLike<T2>,
    PromiseLike<T3>,
    PromiseLike<T4>,
    PromiseLike<T5>,
    PromiseLike<T6>
  ]
): Promise<[T1, T2, T3, T4, T5, T6]>

/**
 * A "safe" version of `Promise.all`. `Promise.all` is dangerous, because it allows for unbounded concurrency - e.g.
 * you can pass it an array of 1000 promises, which can overload downstream systems by making 1000 difficult calls at
 * once. `concurrently` only compiles with arrays of 2-10 promises.
 *
 * @param values An array of promises
 * @returns A promise of a new array, containing the values of all the promises, once they all succeed. Or a rejection,
 *          if any of the promises are rejected
 */
export function concurrently<T1, T2, T3, T4, T5>(
  values: readonly [PromiseLike<T1>, PromiseLike<T2>, PromiseLike<T3>, PromiseLike<T4>, PromiseLike<T5>]
): Promise<[T1, T2, T3, T4, T5]>

/**
 * A "safe" version of `Promise.all`. `Promise.all` is dangerous, because it allows for unbounded concurrency - e.g.
 * you can pass it an array of 1000 promises, which can overload downstream systems by making 1000 difficult calls at
 * once. `concurrently` only compiles with arrays of 2-10 promises.
 *
 * @param values An array of promises
 * @returns A promise of a new array, containing the values of all the promises, once they all succeed. Or a rejection,
 *          if any of the promises are rejected
 */
export function concurrently<T1, T2, T3, T4>(
  values: readonly [PromiseLike<T1>, PromiseLike<T2>, PromiseLike<T3>, PromiseLike<T4>]
): Promise<[T1, T2, T3, T4]>

/**
 * A "safe" version of `Promise.all`. `Promise.all` is dangerous, because it allows for unbounded concurrency - e.g.
 * you can pass it an array of 1000 promises, which can overload downstream systems by making 1000 difficult calls at
 * once. `concurrently` only compiles with arrays of 2-10 promises.
 *
 * @param values An array of promises
 * @returns A promise of a new array, containing the values of all the promises, once they all succeed. Or a rejection,
 *          if any of the promises are rejected
 */
export function concurrently<T1, T2, T3>(
  values: readonly [PromiseLike<T1>, PromiseLike<T2>, PromiseLike<T3>]
): Promise<[T1, T2, T3]>

/**
 * A "safe" version of `Promise.all`. `Promise.all` is dangerous, because it allows for unbounded concurrency - e.g.
 * you can pass it an array of 1000 promises, which can overload downstream systems by making 1000 difficult calls at
 * once. `concurrently` only compiles with arrays of 2-10 promises.
 *
 * @param values An array of promises
 * @returns A promise of a new array, containing the values of all the promises, once they all succeed. Or a rejection,
 *          if any of the promises are rejected
 */
export function concurrently<T1, T2>(values: readonly [PromiseLike<T1>, PromiseLike<T2>]): Promise<[T1, T2]>

/**
 * A "safe" version of `Promise.all`. `Promise.all` is dangerous, because it allows for unbounded concurrency - e.g.
 * you can pass it an array of 1000 promises, which can overload downstream systems by making 1000 difficult calls at
 * once. `concurrently` only compiles with arrays of 2-10 promises.
 *
 * @param values An array of promises
 * @returns A promise of a new array, containing the values of all the promises, once they all succeed. Or a rejection,
 *          if any of the promises are rejected
 */
export function concurrently<T>(values: ReadonlyArray<PromiseLike<T>>): Promise<T[]> {
  return Promise.all(values)
}
