export interface IMtiOrganizationPostBody {
  organizationId: string
  environment: MtiEnvironment
  channel: string
  username: string
  password: string
  accountNumber: string | null
  mtiHostUrl: string
  mtiFleetId: string
  estimateBufferSeconds: number
  timezone: string
}

export interface IMtiOrganizationPatchBody extends Partial<IMtiOrganizationPostBody> {}

export enum MtiEnvironment {
  Sandbox = 'sandbox',
  Production = 'production',
}
