import { PermissionType } from './PermissionType'

export enum PermissionGroupingType {
  SuperAdminPermissions = 'superAdminPermissions',
}

export const PermissionGroupings: Record<PermissionGroupingType, PermissionType[]> = {
  [PermissionGroupingType.SuperAdminPermissions]: [
    PermissionType.GET_ANY_ORGANIZATION,
    PermissionType.GET_ANY_ORGANIZATION_CONFIGURATION,
    PermissionType.GET_ANY_ORGANIZATION_SUPER_ADMIN,
    PermissionType.POST_OWN_ORGANIZATION_SUPER_ADMIN,
    PermissionType.DELETE_OWN_ORGANIZATION_SUPER_ADMIN,
    PermissionType.JOIN_OWN_ORGANIZATION,
    PermissionType.POST_ANY_ORGANIZATION,
    PermissionType.GET_SUPER_ADMIN, // migrated
    PermissionType.LIST_SUPER_ADMIN, // to delete?
    PermissionType.GET_SUPER_ADMIN_AUTHORIZATION_GROUP, // migrated
    PermissionType.WRITE_ANY_DATASET, // migrated
    PermissionType.DELETE_ANY_DATASET, // migrated
    PermissionType.POST_ANY_DATASET_POINTS, // migrated
    PermissionType.POST_ANY_DATASET_HEATMAP_POINTS, // migrated
    PermissionType.GET_ANY_MOBILE_APP,
    PermissionType.LIST_ANY_MOBILE_APP,
    PermissionType.WRITE_ANY_MOBILE_APP, // migrated
    PermissionType.DELETE_ANY_MOBILE_APP, // migrated
    PermissionType.POST_ANY_MOBILE_APP_ORGANIZATION, // migrated
    PermissionType.DELETE_ANY_MOBILE_APP_ORGANIZATION, // migrated
    PermissionType.GET_ANY_MOBILE_APP_RELEASE, // migrated
    PermissionType.WRITE_ANY_MOBILE_APP_RELEASE, // migrated
    PermissionType.DELETE_ANY_MOBILE_APP_RELEASE, // migrated
    PermissionType.GET_ANY_BILLING_ACCOUNT, // migrated
    PermissionType.LIST_ANY_BILLING_ACCOUNT, // migrated
    PermissionType.WRITE_ANY_BILLING_ACCOUNT, // migrated
    PermissionType.DELETE_ANY_BILLING_ACCOUNT, // migrated
    PermissionType.GET_ANY_BILLING_ACCOUNT_ASSIGNMENT, // migrated
    PermissionType.POST_ANY_BILLING_ACCOUNT_ASSIGNMENT, // migrated
    PermissionType.DELETE_ANY_BILLING_ACCOUNT_ASSIGNMENT, // migrated
    PermissionType.GET_ANY_SIMCITY_SIMULATION, // migrated
    PermissionType.POST_ANY_SIMCITY_SIMULATION, // migrated
    PermissionType.PUT_ANY_SIMCITY_SIMULATION, // migrated
    PermissionType.DELETE_ANY_SIMCITY_SIMULATION, // migrated
    PermissionType.GET_ANY_REPORT,
    PermissionType.PATCH_ANY_REPORT,
    PermissionType.GET_ANY_REPORT_PREVIEW, // migrated
    PermissionType.ROOT_ADMIN_ONLY,
    PermissionType.GENERATE_WORKOS_ADMIN_PORTAL_LINK, // migrated
    PermissionType.WRITE_ANY_MTI_ORGANIZATION, // migrated
    PermissionType.DELETE_ANY_MTI_ORGANIZATION, // migrated
    PermissionType.PATCH_ANY_ORGANIZATION,
    PermissionType.PATCH_ANY_ORGANIZATION_CONFIGURATION,
    PermissionType.POST_ANY_ORGANIZATION_SUPER_ADMIN,
    PermissionType.DELETE_ANY_ORGANIZATION_SUPER_ADMIN,
    PermissionType.JOIN_ANY_ORGANIZATION,
    PermissionType.PATCH_ANY_ORGANIZATION_NOTIFICATION_PROVIDERS,
    PermissionType.DELETE_ANY_ORGANIZATION,
    PermissionType.IMPORT_ANY_FEATURE_FLAG,
    PermissionType.POST_ANY_DATA_MIGRATION, // migrated
    PermissionType.WRITE_SUPER_ADMIN, // migrated
    PermissionType.DELETE_SUPER_ADMIN, // migrated
    PermissionType.UPDATE_SUPER_ADMIN_USER_AUTHORIZATION_GROUP, // migrated
    PermissionType.DELETE_ORGANIZATION_RIDERS, // migrated
    PermissionType.POST_SPARE_MOBILE_APP_ORGANIZATION, // migrated
    PermissionType.POST_ANY_TEST_ORGANIZATION,
    PermissionType.POST_ANY_TEST_MOBILE_APP_ORGANIZATION, // migrated
    PermissionType.DELETE_ANY_TEST_MOBILE_APP_ORGANIZATION, // migrated
    PermissionType.PATCH_OWN_TEST_ORGANIZATION,
    PermissionType.DELETE_OWN_TEST_ORGANIZATION,
    PermissionType.POST_TEST_ORG_PAYMENT_PROVIDER, // partially migrated
    PermissionType.POST_TEST_ORG_PAYMENT_METHOD_TYPE,
    PermissionType.PATCH_OWN_TEST_ORGANIZATION_CONFIGURATION,
    PermissionType.GET_ANY_NYLAS_ORGANIZATION_GRANT, // migrated
    PermissionType.WRITE_ANY_NYLAS_ORGANIZATION_GRANT, // migrated
  ],
}
