import { IMarkdownVariableDefinition } from '@sparelabs/markdown-utils'
import EasyMDE, { Options } from 'easymde'
// eslint-disable-next-line no-restricted-imports
import 'easymde/dist/easymde.min.css'
import React, { useMemo } from 'react'
import SimpleMdeReact from 'react-simplemde-editor'
import { MarkdownVariablePickerBuilder } from '../../markdown/MarkdownVariablePickerBuilder' // direct imports to avoid storybook errors
import { IInputProps } from './InputTypes'
import { MarkdownInputContainer } from './MarkdownInputStyles'

export interface IMarkdownInputProps extends IInputProps<string> {
  id?: string
  width?: string
  height?: string
  /**
   * Remember to memoize this object!
   */
  options?: Options
  /**
   * Remember to memoize this object!
   */
  variableDefinitions?: IMarkdownVariableDefinition[]
  usePageBreak?: boolean
}

const buildDefaultToolbar = (
  uploadImage?: boolean,
  variableDefinitions?: IMarkdownVariableDefinition[],
  usePageBreak?: boolean
) =>
  [
    'bold',
    'italic',
    'heading-1',
    'heading-2',
    'heading-3',
    '|',
    'quote',
    'ordered-list',
    'unordered-list',
    'strikethrough',
    '|',
    'horizontal-rule',
    uploadImage ? 'upload-image' : 'image',
    {
      name: 'link',
      action: EasyMDE.drawLink,
      className: 'fa fa-link',
      title: 'Create Web Link',
    },
    ...(usePageBreak
      ? [
          {
            name: 'page break',
            action: (editor: EasyMDE) => {
              const cm = editor.codemirror
              const doc = cm.getDoc()
              const cursor = doc.getCursor()
              doc.replaceRange('<br class="page-break" />\n', cursor)
              cm.focus()
            },
            className: 'fa fa-minus-square-o',
            title: 'Create a Page Break',
          },
        ]
      : []),
    ...(variableDefinitions?.length ? ['|', MarkdownVariablePickerBuilder.build(variableDefinitions)] : []),
    '|',
    {
      name: 'guide',
      action: (_editor: EasyMDE) =>
        window.open('https://help.sparelabs.com/en/articles/8955229-formatting-case-letter-templates', '_blank'),
      className: 'fa fa-question-circle',
      title: 'formatting guide',
    },
  ] as Options['toolbar']

const DEFAULT_MARKDOWN_OPTIONS: Options = {
  status: false,
  autofocus: true,
  spellChecker: false,
  shortcuts: {
    togglePreview: null,
    toggleFullScreen: null,
  },
}

/**
 * This component compute its `options` only once on load to prevent glitches while typing.
 * If you need to dynamically change the options or variables, update the `key` property of this
 * component to trigger a refresh.
 */
export const MarkdownInput = ({
  id,
  value,
  onChange,
  options,
  height,
  width,
  variableDefinitions,
  usePageBreak,
}: IMarkdownInputProps): JSX.Element => {
  const memoOptions = useMemo<Options>(() => {
    const toolbar = buildDefaultToolbar(options?.uploadImage, variableDefinitions, usePageBreak)
    return {
      ...DEFAULT_MARKDOWN_OPTIONS,
      toolbar,
      ...options,
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MarkdownInputContainer height={height} width={width}>
      <SimpleMdeReact value={value ?? undefined} onChange={onChange} options={memoOptions} id={id} />
    </MarkdownInputContainer>
  )
}
