import { library } from '@fortawesome/fontawesome-svg-core'
import { faUpload } from '@fortawesome/pro-solid-svg-icons'
import * as Announce from '@radix-ui/react-announce'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { WebColors } from '@sparelabs/colors'
import { ACCEPTED_FILE_EXTENSION_TYPES } from '@sparelabs/http-client-utils'
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useAsync } from 'react-use'
import styled from 'styled-components'
import asyncPool from 'tiny-async-pool'
import { RoundedButton } from '../../../controls'
import { st } from '../../../locales/TranslationHelper'
import { FileUploadingView } from '../../displays'
import { DropzoneErrorMessages } from './DropzoneErrorMessages'
import { AcceptedFileTypes, IStyledDropzoneProps } from './FileInputTypes'
import { getDescription, getErrorMessage, IApiError, isApiError } from './StyledDropzone.helpers'

library.add(faUpload)

const Wrapper = styled.div<{ width?: string; $coverContainer?: boolean }>`
  width: 100%;
  max-width: ${({ width }) => width ?? '100%'};
  word-break: break-word;
  height: ${({ $coverContainer }) => ($coverContainer ? '100%' : 'unset')};
`

const BorderWrapper = styled.div<{ $coverContainer?: boolean }>`
  border: solid 1px ${WebColors.borderPrimary};
  border-radius: 8px;
  height: ${({ $coverContainer }) => ($coverContainer ? '100%' : 'unset')};
`

const DropzoneWrapper = styled.div<{ hasErrors: boolean; width?: string; $coverContainer?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 8px;
  background-color: ${WebColors.backgroundPrimary};
  gap: 4px;
  height: ${({ $coverContainer }) => ($coverContainer ? '100%' : 'unset')};
`

const InputSubtext = styled.div`
  display: flex;
  justify-content: center;
`

const InputDescription = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: ${WebColors.contentSecondary};
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  line-height: 20px;
  color: ${WebColors.contentPrimary};
`

const HighlightedSpan = styled.span`
  color: ${WebColors.interactivePrimary};
  cursor: pointer;
`
const FileContainer = styled.div`
  padding: 16px 16px;
  background-color: ${WebColors.backgroundPrimary};
`

export const StyledDropzone = ({
  onDrop,
  width,
  onError,
  label,
  error,
  accept,
  maxSizeInBytes,
  multiple,
  children,
  disabled = false,
  maxFiles = 10, // Only used if multiple === true
  id,
  onStartUploading,
  coverContainer,
}: IStyledDropzoneProps): JSX.Element => {
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [internalErrors, setInternalErrors] = useState<string[]>([])
  const [errorMessages, setErrorMessages] = useState<string[]>([])

  const maxSize = maxSizeInBytes ?? 20000000
  const acceptedFileExtensions = accept.length > 0 ? accept : (ACCEPTED_FILE_EXTENSION_TYPES as AcceptedFileTypes[])
  const description = getDescription(acceptedFileExtensions, maxSize)
  const multipleFiles = multiple || false
  const maxFilesOrUnlimited = maxFiles === 'unlimited' ? undefined : maxFiles

  useEffect(() => {
    const errors = error !== undefined ? internalErrors.concat(error) : internalErrors
    setErrorMessages(errors)
  }, [error, internalErrors])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: multipleFiles ? maxFilesOrUnlimited : 1,
    multiple,
    disabled: disabled || isUploading,
    maxSize,
    // Use acceptedFileExtensions once OPEN-398 is addressed. For now, always accept all file types and let the backend reject bad types.
    accept: undefined,
    onDrop: () => {
      setInternalErrors([])
      setErrorMessages([])
    },
    onDropRejected: (files) => {
      const formattedErrorMessages = files[0].errors.map((error) => getErrorMessage(error, accept, maxSize))
      setInternalErrors(formattedErrorMessages)
    },
    onError: (error: Error) => setInternalErrors([error.message]),
  })

  useAsync(async () => {
    if (acceptedFiles.length === 1 || (multipleFiles && acceptedFiles.length > 0)) {
      setIsUploading(true)
      if (onStartUploading) {
        onStartUploading({ totalAccepted: acceptedFiles.length })
      }
      try {
        await asyncPool(10, acceptedFiles, async (file) => onDrop(file))
      } catch (error: unknown) {
        const errorObj = error as Error | IApiError
        onError(errorObj as Error)
        if (isApiError(errorObj)) {
          setInternalErrors([errorObj.response?.data.message ?? st.inputs.dropzone.genericError()])
        } else {
          setInternalErrors([errorObj.message])
        }
      }
      setIsUploading(false)
    }
  }, [acceptedFiles])

  if (isUploading && !multiple) {
    return (
      <Wrapper width={width} $coverContainer={coverContainer}>
        <BorderWrapper $coverContainer={coverContainer}>
          <FileContainer>
            <FileUploadingView />
          </FileContainer>
        </BorderWrapper>
      </Wrapper>
    )
  }

  return (
    <Wrapper width={width} $coverContainer={coverContainer}>
      <BorderWrapper $coverContainer={coverContainer}>
        <DropzoneWrapper
          {...getRootProps()}
          hasErrors={errorMessages?.length >= 1}
          role='button'
          $coverContainer={coverContainer}
        >
          {/* Work around for not being able to label divs   */}
          <VisuallyHidden>{label}</VisuallyHidden>
          {/* cspell:disable-next-line */}
          <input {...getInputProps()} id={id} data-testid={id} />
          <RoundedButton disabled={disabled} icon='upload' onClick={() => {}}>
            {multipleFiles ? st.inputs.dropzone.chooseFilesButton() : st.inputs.dropzone.chooseFileButton()}
          </RoundedButton>
          <Announce.Root>
            <TextWrapper>
              <HighlightedSpan>
                <VisuallyHidden>{st.inputs.dropzone.noFileSelected()}</VisuallyHidden>
              </HighlightedSpan>
              <InputSubtext>{st.inputs.dropzone.dragDropFile()}</InputSubtext>
              {description && <InputDescription>{description}</InputDescription>}
            </TextWrapper>
          </Announce.Root>
        </DropzoneWrapper>
        {children}
      </BorderWrapper>
      {!multipleFiles && internalErrors && <DropzoneErrorMessages errors={errorMessages} />}
    </Wrapper>
  )
}
