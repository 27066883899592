import { WebColors } from '@sparelabs/colors'
import { uniqueId } from 'lodash'
import React, { ReactElement, ReactNode, useMemo } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { st } from '../locales/TranslationHelper'
import { INPUT_BREAKPOINT_WIDTH_PX, INPUT_MAX_WIDTH, LABEL_COLUMN_WIDTH_PX, LABEL_MARGIN_RIGHT_PX } from '../util'
import { InfoTooltip } from './InfoTooltip'

const LABEL_COLUMN_WIDTH = `${LABEL_COLUMN_WIDTH_PX}px`

export interface IInputFieldProps {
  stacked?: boolean
}

export const FieldContainer = styled.div<IInputFieldProps>`
  display: flex;
  flex-direction: ${({ stacked }) => (stacked ? 'column' : 'row')};
  flex-wrap: wrap;
  font-size: 15px;
  gap: ${({ stacked }) => (stacked ? '4px' : '0px')};
`

export const InputLabel = styled.label<IInputFieldProps>`
  margin-bottom: 0px;
  font-weight: 400;
  line-height: 20px;
  color: ${WebColors.contentSecondary};
  min-width: ${LABEL_COLUMN_WIDTH};
  max-width: ${LABEL_COLUMN_WIDTH};
  margin-right: ${LABEL_MARGIN_RIGHT_PX}px;
  padding-top: ${({ stacked }) => (stacked ? '0px' : '8px')};
`

const FieldValueContainer = styled.div<IInputFieldProps>`
  flex-grow: 1;
  overflow-wrap: break-word;
  min-width: 270px;
  flex-basis: ${({ stacked }) => `${stacked ? '0px' : `${INPUT_BREAKPOINT_WIDTH_PX}px`}`};
`

const FieldHint = styled.div`
  color: ${WebColors.contentTertiary};
  font-size: 13px;
  font-weight: 400;
  white-space: pre-wrap;
  margin-top: 5px;
  max-width: ${INPUT_MAX_WIDTH};
`

const ValidationError = styled.div`
  color: ${WebColors.negativePrimary};
  font-size: 14px;
  margin-top: 5px;
  max-width: ${INPUT_MAX_WIDTH};
`

interface IFieldProps {
  renderInput: (props: { id: string }) => ReactElement<Record<string, unknown> & { id?: string }>
  label: string
  hint?: ReactNode
  tooltip?: string
  error?: string
  className?: string
  children?: ReactNode
  containerStyle?: CSSProperties
  labelStyle?: CSSProperties
  valueStyle?: CSSProperties
  isRequired?: boolean
  stacked?: boolean
  customLabel?: ReactNode
}

export const InputWithLabelComponent = ({
  label,
  children,
  hint,
  tooltip,
  error,
  className,
  renderInput,
  containerStyle,
  labelStyle,
  valueStyle,
  isRequired,
  stacked,
  customLabel,
}: IFieldProps): JSX.Element => {
  const id = useMemo(() => `form-label-${uniqueId()}`, [])
  const input = useMemo(() => renderInput({ id }), [renderInput, id])
  // runtime check is required because TS doesn't check children/component as a prop strictly. This kind of checks is used in Radix-UI too.
  if (!input.props.id) {
    throw new Error('Input must have an id!')
  }

  return (
    <FieldContainer className={className} style={containerStyle} stacked={stacked}>
      {customLabel ? (
        customLabel
      ) : (
        <InputLabel htmlFor={id} style={labelStyle} stacked={stacked}>
          {label} {isRequired && <span>{st.common.required()}</span>}
          {tooltip && <InfoTooltip content={tooltip} />}
        </InputLabel>
      )}
      <FieldValueContainer style={valueStyle} stacked={stacked}>
        {input}
        {children}
        {hint && <FieldHint>{hint}</FieldHint>}
        {error && <ValidationError role='alert'>{error}</ValidationError>}
      </FieldValueContainer>
    </FieldContainer>
  )
}

export const InputWithLabel = React.memo(InputWithLabelComponent)
